import Mediator from "./Mediator";

let mediator = new Mediator();

// Attach mediator channels to the window to allow cross app communication
if (Mediator.isBrowser()) {
  // @ts-ignore
  if (!window.mediatorChannels) {
    // @ts-ignore
    window.mediatorChannels = mediator.channels;
  } else {
    // @ts-ignore
    mediator = { channels: window.mediatorChannels };
  }
}

export default mediator.channels;
