import { Store } from "redux";
import { Dispatch as ReactDispatch, SetStateAction } from "react";
import parseCapiMessage from "@tvg/utils/capiUtils";
import formatCurrency from "@tvg/formatter/currency";
import { get } from "lodash";
import { PaymentType, FundsModalFields, PawsLimits } from "../../../../types";

export const getStateByActiveField = (
  selectedField: FundsModalFields,
  setValue: ReactDispatch<SetStateAction<string>>,
  setCVV: ReactDispatch<SetStateAction<string>>,
  value: string,
  cvv: string
) => {
  switch (selectedField) {
    case FundsModalFields.VALUE:
      return {
        setState: setValue,
        value
      };
    case FundsModalFields.CVV:
      return {
        setState: setCVV,
        value: cvv
      };
    default:
      return {
        setState: setValue,
        value
      };
  }
};

export const changeSelectedInput =
  (
    nextInput: FundsModalFields,
    cvv: string,
    cvvInvalidMessage: string,
    cvvEmptyMessage: string,
    setSelectedField: ReactDispatch<SetStateAction<FundsModalFields>>,
    setCVVError: ReactDispatch<SetStateAction<string>>
  ) =>
  () => {
    setSelectedField((prevState) => {
      if (prevState === FundsModalFields.CVV && cvv.length !== 3) {
        setCVVError(cvv.length > 0 ? cvvInvalidMessage : cvvEmptyMessage);
      }

      return nextInput;
    });
  };

export const getPawsErrorMessage =
  (capiMessageName: string) => (store: Store) =>
    parseCapiMessage(store, `capi.messages.${capiMessageName}`, {});

export const shouldRenderContent = (
  showSuccessMessage: boolean,
  showMazoomaStatus: boolean,
  enableDepositPage: boolean,
  isLoading: boolean
) =>
  (!showSuccessMessage && (!showMazoomaStatus || enableDepositPage)) ||
  isLoading;

export const getWarningFlagText = (
  value: string,
  minLimit: number,
  maxLimit: number
) =>
  +value < +minLimit
    ? `${formatCurrency(minLimit)} MINIMUM DEPOSIT`
    : `${formatCurrency(maxLimit)} MAXIMUM DEPOSIT`;

export const getMinLimit = (
  paymentType: PaymentType,
  minLimit: number,
  limits: unknown
): number =>
  get(limits, `${paymentType}.deposit.min`, 0) > minLimit
    ? get(limits, `${paymentType}.deposit.min`, 0)
    : minLimit;

export const getMaxLimit = (
  paymentType: PaymentType,
  maxLimit: number,
  limits: PawsLimits
): number =>
  (get(limits, `${paymentType}.deposit.max`, 10000000) as number) < maxLimit
    ? (get(limits, `${paymentType}.deposit.max`, 10000000) as number)
    : maxLimit;
