import { Dispatch } from "@reduxjs/toolkit";
import { toggleQuickWithdraw } from "@tvg/sh-lib-paws/redux/slices/quickWithdrawSlice";
import { History } from "@tvg/ts-types/History";
import { Location } from "react-router-dom";
import mediator from "@tvg/mediator";
import { NullaryFn } from "../../types";

export const closeModal =
  (dispatch: Dispatch, history: History, location: Location) => () => {
    if (history) {
      const { search = "", pathname = "" } = location;
      history.push(pathname + search);
    }
    dispatch(toggleQuickWithdraw(false));
  };

export const refetch = (refetchAvailableMethods: NullaryFn<void>) => () => {
  mediator.base.dispatch({
    type: "REFETCH_PENDING_WITHDRAWALS_LENGTH",
    payload: {}
  });
  refetchAvailableMethods();
};

export const gtmToggleQuickWithdraw = (dispatch: Dispatch) => () => {
  mediator.base.subscribe("OPEN_QUICK_WITHDRAWAL", () =>
    dispatch(toggleQuickWithdraw(true))
  );
  return () => {
    mediator.base.unsubscribe("OPEN_QUICK_WITHDRAWAL", () =>
      dispatch(toggleQuickWithdraw(false))
    );
  };
};
