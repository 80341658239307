import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Plus = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M22.8755 12.0042C22.8755 18.0105 18.0064 22.8797 12 22.8797C5.99363
      22.8797 1.12451 18.0105 1.12451 12.0042C1.12451 5.99782 5.99363 1.12871
      12 1.12871C18.0064 1.12871 22.8755 5.99782 22.8755 12.0042Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M12.0001 6V12M12.0001 12V18M12.0001 12H18.0001M12.0001
      12H6.00006M22.8755 12.0042C22.8755 18.0105 18.0064 22.8797 12
      22.8797C5.99363 22.8797 1.12451 18.0105 1.12451 12.0042C1.12451
      5.99782 5.99363 1.12871 12 1.12871C18.0064 1.12871 22.8755 5.99782
      22.8755 12.0042Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);
export default Plus;
