import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const EyeShow = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <Path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 4.125C5 4.125 1.125 12 1.125 12C1.125 12 5 19.875 12 19.875C19
    19.875 22.875 12 22.875 12C22.875 12 19 4.125 12 4.125ZM12 15.3807C13.8671
    15.3807 15.3806 13.8671 15.3806 12C15.3806 10.1329 13.8671 8.61937 12
    8.61937C10.1329 8.61937 8.61932 10.1329 8.61932 12C8.61932 13.8671 10.1329
    15.3807 12 15.3807Z"
    fill={backgroundColor}
    fillOpacity="1"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default EyeShow;
