// @flow
import styled, { css } from "styled-components";
import { transparentize } from "polished";
import { buildTitle } from "../../_atom/Title";
import Button from "../../_atom/Buttons/default";
import { fontBold } from "../../_static/Typography";
import { easeInOut, generateTransition } from "../../_static/Transitions";
import buildColor from "../../_static/ColorPalette";

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 8px;
  transition: ${generateTransition(easeInOut, "box-shadow")};
  background-color: ${buildColor("white", "100")};
  ${({ showBottomShadow }) =>
    showBottomShadow &&
    css`
      box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
    `};
  color: ${buildColor("grey", "900")};
  flex: 0 0 auto;
  z-index: 2;
  ${(props) =>
    props.roundedCorners &&
    css`
      border-radius: 4px 4px 0 0;
    `};
  ${(props) =>
    props.showShadow &&
    css`
      box-shadow: 0 2px 4px ${transparentize(0.94, buildColor("blue", "900"))},
        0 1px 2px ${transparentize(0.92, buildColor("blue", "900"))};
    `};
  ${(props) =>
    props.device === "tablet" &&
    css`
      padding: 16px;
    `};
`;

export const TitleHolder = styled.div`
  display: inline-flex;
  flex: 1 0 auto;
  flex-direction: column;
  ${({ isTitleCenter }) =>
    isTitleCenter &&
    css`
      text-align: center;
    `}
`;

export const Title = styled(
  buildTitle({
    color: buildColor("grey", "900"),
    fontSize: 16,
    ellipsis: true,
    uppercase: false,
    qaLabel: "modal-title"
  })
)`
  flex: 1 1 auto;
  margin: 4px 0 0 4px;
  line-height: 24px;
  font-family: ${fontBold};
  ${({ isTitleCapitalized }) =>
    isTitleCapitalized &&
    css`
      text-transform: capitalize;
    `}
`;

export const Subtitle = styled.p`
  ${({ isTitleCenter }) =>
    !isTitleCenter &&
    css`
      margin: 0 0 4px 4px;
    `};
  line-height: 18px;
  font-family: ${({ subtitleFontFamily }) => subtitleFontFamily};
  font-size: 14px;
  font-style: normal;
  text-transform: capitalize;
  color: ${({ subtitleColor }) => subtitleColor};
`;

const buttonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 0;
  background: none;
  border-radius: 50%;

  &:hover {
    background-color: ${buildColor("grey", "100")};
  }

  &:active {
    background-color: ${buildColor("grey", "200")};
  }

  @media (pointer: fine) {
    &:hover {
      background-color: ${buildColor("grey", "100")};
    }
  }

  @media (pointer: coarse) {
    &:active {
      background-color: ${buildColor("grey", "200")};
    }
  }
`;

export const BackButton = styled(Button)`
  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      position: absolute;
      left: 6px;
      top: 8px;
    `};
  ${buttonStyle}
`;

export const CloseButton = styled(Button)`
  position: absolute;
  right: 6px;
  top: 8px;
  ${buttonStyle}
`;
