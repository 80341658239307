import pro from "@tvg/api/pro";
import { attempt, isError } from "lodash";
import { PromoAcquisition } from "../types/promos";

export const fetchPromoAcquisition = async (
  accountId: string,
  promoId: number
): Promise<PromoAcquisition | null> => {
  const promosAcquisition = await attempt(async () => {
    const responseUserPromoSteps = await pro.fetchUserPromoSteps(
      accountId,
      promoId
    );
    return responseUserPromoSteps.data as PromoAcquisition;
  });

  return !isError(promosAcquisition) ? promosAcquisition : null;
};
