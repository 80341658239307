import { createElement } from "react";
import styled from "styled-components";
import {
  color,
  space,
  variant,
  compose,
  layout,
  system,
  typography
} from "styled-system";
import { HeaderProps } from "./types";

const systemProps = system({
  textTransform: {
    property: "textTransform"
  },
  whiteSpace: {
    property: "whiteSpace"
  },
  textOverflow: {
    property: "textOverflow"
  }
});

const styles = compose(
  color,
  space,
  systemProps,
  typography,
  layout,
  variant({
    prop: "tag",
    scale: "headingSizes"
  })
);

export const StyledHeader = styled(({ tag, ...props }) =>
  createElement(tag || "p", props)
).attrs<HeaderProps>(({ tag, textOverflow }) => ({
  accessibilityLevel: tag && tag.replace("h", ""),
  overflow: textOverflow === "ellipsis" ? "hidden" : "visible"
}))<HeaderProps>`
  ${styles}
`;
