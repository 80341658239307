import React, { FC } from "react";

export const NewZealand: FC = () => (
  <>
    <path d="M32 0H0V20H32V0Z" fill="#004EA3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9365 7.29436L28.1443 7.83548H28.7677L28.2597 8.1818L28.4445
      8.72293L27.9365 8.37661L27.4286 8.72293L27.6133 8.1818L27.1053
      7.83548H27.7518L27.9365 7.29436Z"
      fill="#D22839"
    />
    <path
      d="M28.6291 8.93936L27.9365 8.48481L27.2438 8.93936L27.4978
      8.20342L26.8052 7.74888H27.6594L27.9134 7.01295L28.1673
      7.74888H29.0216L28.329 8.20342L28.6291 8.93936ZM27.9365 8.29001L28.2828
      8.5281L28.1442 8.16013L28.4906 7.92204H28.0519L27.9134 7.55407L27.7749
      7.92204H27.3362L27.6825 8.16013L27.544 8.5281L27.9365 8.29001Z"
      fill="#F7FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9711 8.11684L20.2251 8.80948H21.0332L20.3636 9.24238L20.6176
      9.93502L19.9711 9.50212L19.3016 9.93502L19.5555 9.24238L18.9091
      8.80948H19.7171L19.9711 8.11684Z"
      fill="#D22839"
    />
    <path
      d="M20.7793 10.1299L19.9481 9.58878L19.1169 10.1299L19.4401 9.2641L18.609
      8.72298H19.6249L19.9481 7.85718L20.2713 8.72298H21.2872L20.456
      9.2641L20.7793 10.1299ZM19.9712 9.39397L20.456 9.71865L20.2713
      9.19917L20.7561 8.87449H20.1559L19.9712 8.35501L19.7865
      8.87449H19.1862L19.671 9.19917L19.4863 9.71865L19.9712 9.39397Z"
      fill="#F7FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.3117 4.15581L24.5657 4.87009H25.3969L24.7273 5.32464L24.9813
      6.03892L24.3117 5.58438L23.6191 6.03892L23.873 5.32464L23.2035
      4.87009H24.0347L24.3117 4.15581Z"
      fill="#D22839"
    />
    <path
      d="M25.1428 6.23377L24.2886 5.671L23.4343 6.23377L23.7575 5.34632L22.9033
      4.78355H23.9423L24.2655 3.87446L24.5887 4.78355H25.6277L24.7734
      5.34632L25.1428 6.23377ZM23.4805 4.95671L23.9884 5.28138L23.7806
      5.82251L24.2886 5.47619L24.7965 5.82251L24.5887 5.28138L25.0967
      4.95671H24.4733L24.2886 4.41558L24.1039 4.95671H23.4805Z"
      fill="#F7FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1501 13.6147L24.5194 14.6104H25.6969L24.7273 15.2381L25.0966
      16.2554L24.1501 15.6277L23.2034 16.2554L23.5729 15.2381L22.6262
      14.6104H23.7806L24.1501 13.6147Z"
      fill="#D22839"
    />
    <path
      d="M25.2814 16.4502L24.1732 15.7142L23.065 16.4502L23.5037 15.2597L22.3723
      14.5238H23.7576L24.1732 13.3333L24.5888 14.5238H25.9741L24.8428
      15.2597L25.2814 16.4502ZM22.8803 14.6969L23.6653 15.2164L23.3651
      16.0389L24.1501 15.5194L24.9351 16.0389L24.635 15.2164L25.42
      14.6969H24.4502L24.1501 13.8744L23.85 14.6969H22.8803Z"
      fill="#F7FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.64936 0H9.37375V3.24675L14.2222 0H16V1.19048L12.5137
      3.57143H16V6.38528H12.4444L16 8.85281V10H14.2222L9.37375
      6.75325V10H6.64936V6.7316L1.80088 10H0V8.85281L3.55553
      6.38528H0V3.57143H3.50937L0 1.19048V0H1.80088L6.64936 3.24675V0Z"
      fill="#F7FBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4.11255V5.8658H8.88888V10H7.11112V5.8658H0V4.11255H7.11112V0H8.88888V4.11255H16Z"
      fill="#D22839"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.735929L4.17895 3.54978H5.35641L0.0231106 0V0.735929H0ZM10.6666
      3.54978H9.48919L14.8225 0H16L10.6666 3.54978ZM16 9.24242L11.8442
      6.38528H10.6666L16 9.97835V9.24242ZM5.33335 6.40692H6.51081L1.17751
      10H0L5.33335 6.40692Z"
      fill="#D22839"
    />
  </>
);
