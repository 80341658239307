import React, { forwardRef, memo } from "react";
import { View } from "react-native";
import { BadgeCounterProps } from "../../types";
import { useQaLabel } from "../../../../hooks";
import { calculateBadgeWidth } from "../../utils";
import { BadgeContainer, BadgeText } from "./styled-components";

export const BadgeCounter = memo(
  forwardRef<View, BadgeCounterProps>(
    (
      {
        qaLabel = "badge",
        counter,
        counterLimit = 99,
        variant = "primary",
        accessibilityLabel,
        ...rest
      }: BadgeCounterProps,
      ref
    ) => {
      const viewTestProps = useQaLabel(qaLabel);
      const valueOfNotifications =
        counterLimit && counter > counterLimit ? `${counterLimit}+` : counter;

      return (
        <BadgeContainer
          {...viewTestProps}
          variant={variant}
          accessibilityLabel={
            accessibilityLabel || `${valueOfNotifications} notifications`
          }
          badgeWidth={calculateBadgeWidth(counter, counterLimit)}
          // @ts-ignore
          ref={ref}
          isCircle={String(valueOfNotifications).length === 1}
          {...rest}
        >
          <BadgeText selectable={false}>{valueOfNotifications}</BadgeText>
        </BadgeContainer>
      );
    }
  )
);
