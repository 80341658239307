import { get, has, reverse } from "lodash";
import { PromoMappedSteps, PromoStepType } from "../types/promoSteps";
import { PromoAcquisition } from "../types/promos";
import formatSteps from "../helpers/formatSteps";

const getMappedDate = (steps: PromoMappedSteps): string | null => {
  let finishedDate: string | null = null;

  Object.entries(steps).find(([key, value]) => {
    switch (key) {
      case PromoStepType.PAYOUT: {
        finishedDate = get(value, "timestamp");
        break;
      }
      case PromoStepType.WAGER: {
        finishedDate = get(value, "wagerDate");
        break;
      }
      case PromoStepType.DEPOSIT: {
        if (!has(steps, PromoStepType.WAGER)) {
          finishedDate = get(value, "depositDate");
        }
        break;
      }
      default:
        return false;
    }

    return !!finishedDate;
  });

  return finishedDate || null;
};

export const getPromoFinishedDate = (promoAcquisition: PromoAcquisition) => {
  const steps = reverse([...get(promoAcquisition, "steps", [])]);
  return steps.length ? getMappedDate(formatSteps(steps)) : null;
};
