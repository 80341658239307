import React, { FC } from "react";

export const Canada: FC = () => (
  <>
    <g clipPath="url(#clip0_18106_2868)">
      <path d="M22.1485 0H9.98993V20H22.1485V0Z" fill="#F7FBFF" />
      <path d="M9.98992 0H0V20H9.98992V0Z" fill="#D22839" />
      <path d="M32 0H22.1716V20H32V0Z" fill="#D22839" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8394 15.9692C15.8394 15.9077 15.8395 15.8462 15.8624
        15.6617C15.9083 15.0261 15.9771 13.7549 15.9312 13.3448C15.9312 13.2423
        15.8165 13.2628 15.7706 13.2628C15.3807 13.2833 14.0505 13.4473 13.3165
        13.5294C13.4771 13.1808 13.6376 12.8322 13.5688 12.6682C13.4771 12.3812
        12.3073 11.479 11 10.4744C11.2982 10.3924 11.6193 10.3103 11.6422
        10.1258C11.711 9.87978 11.5505 9.3877 11.2064 8.40354C12.0551 8.64958
        12.5138 8.7726 12.7661 8.71109C12.9266 8.64958 12.9725 8.40354 13.0183
        7.93196C13.4771 8.40354 14.1651 9.4082 14.4174 9.34669C14.7844 9.22367
        14.2569 7.35787 14.0734 6.37372C14.4633 6.61976 14.7156 6.80428 14.922
        6.80428C15.1972 6.78378 15.4954 6.12768 16 5C16.5275 6.12768 16.8028
        6.78378 17.078 6.80428C17.2844 6.80428 17.5367 6.61976 17.9266
        6.37372C17.7431 7.35787 17.2156 9.22367 17.5826 9.34669C17.8119 9.4082
        18.5 8.40354 18.9817 7.93196C19.0275 8.40354 19.0734 8.64958 19.234
        8.71109C19.5092 8.7726 19.9679 8.64958 20.7936 8.40354C20.4495 9.3877
        20.289 9.87978 20.3578 10.1258C20.4037 10.3103 20.7248 10.3924 21
        10.4744C19.6927 11.479 18.5229 12.3607 18.4312 12.6682C18.3853 12.8322
        18.5459 13.1808 18.6835 13.5294C17.9495 13.4473 16.6193 13.2833 16.2294
        13.2628C16.1835 13.2628 16.0688 13.2423 16.0688 13.3448C16.0229 13.7549
        16.1147 15.0056 16.1376 15.6617C16.1376 15.8257 16.1606 15.9077 16.1606
        15.9692C16.2294 16.0103 15.8394 16.0103 15.8394 15.9692Z"
        fill="#D22839"
      />
    </g>
    <defs>
      <clipPath id="clip0_18106_2868">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V18C32
          19.1046 31.1046 20 30 20H2C0.895431 20 0 19.1046 0 18V2Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </>
);
