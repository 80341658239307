import React, { useEffect, useState } from "react";
import { Store } from "redux";
import { get, isEmpty } from "lodash";
import tvgConf from "@tvg/conf";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import { events as AlchemerEvents } from "@urp/alchemer";
import ApolloContext from "@tvg/utils/apolloContext";
import {
  getPaymentMethodSelectorModal,
  getAccountsOnFileMethods,
  getDepositFundsModal,
  getSelectedPaymentMethod,
  getPawsContent,
  getPawsLimits,
  getCardDeclinedContent,
  getMainMethods,
  getPawsShowMZMSuccessModal,
  getIsFirstDepositDone
} from "@tvg/sh-lib-paws/redux/selectors";
import { getAccountNumber, getBalance } from "@urp/store-selectors";
import { fontCondensedNormal } from "@tvg/atomic-ui/_static/Typography";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import formatCurrency from "@tvg/formatter/currency";
import {
  matchDepositPropositions,
  getCustomAmount
} from "@tvg/sh-lib-promos-onboarding/utils/matchDepositPropositions";
import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import PromosOnboarding from "@tvg/promos-onboarding";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import PaymentMethodSelector from "@tvg/atomic-ui/_templates/PaymentMethodSelector";
import CustomKeyboard from "@tvg/atomic-ui/_molecule/CustomKeyboard";
import MoneyPills from "@tvg/atomic-ui/_molecule/MoneyPills";
import { CVV, Promo } from "@tvg/atomic-ui/_static/Icons/paws";
import { useCommonDeposit } from "@tvg/sh-lib-paws/hooks/useCommonDeposit";
import {
  useMazoomaDeposit,
  CurrentMzmTab
} from "@tvg/sh-lib-paws/hooks/useMazoomaDeposit";
import { usePrevious } from "@tvg/custom-hooks";
import { PaymentType } from "@tvg/ts-types/Payment";
import {
  getMazoomaToggle,
  isCardDeclinedToggle
} from "@tvg/sh-lib-paws/redux/toggles";
import { gtmDepositDefaultSelect } from "../../../gtm";
import Flag from "../../Flag";
import AlertMessagesModal from "../../AlertMessagesModal";
import MessagePanel from "../../MessagePanel";
import GenericErrorMessageContainer from "../../GenericErrorMessageContainer";
import InputValue from "../common/InputValue";
import InputField from "../common/InputField";
import Summary from "../common/Summary";
import Button from "../common/Button";
import {
  getCAPIMessageByType,
  getLastUsedPMByPaymentType,
  redirectOnFeeFree,
  isMobile,
  isDesktop
} from "../../../utils";
import {
  getSelectionEvent,
  paymentMethodWithoutSummary,
  getErrorMessageInitialState,
  refetchAvailableMethodsForQuickDeposits,
  onAddPeriod
} from "../common/utils";
import {
  PaymentTypeOption,
  PawsContent,
  ErrorMessage,
  FundsAmountInputChangingFrom,
  FundsModalContent,
  MainMethods,
  FundsModalFields,
  PawsLimits
} from "../../../types";
import {
  Container,
  Main,
  ValueContainer,
  PaymentMethodTitle,
  NoPaymentMethodsAddedContainer,
  FlagsContainer,
  PromosOnboardingFlagWrapper
} from "./styled-components";
import TransactionStatus from "../TransationStatus";
import { DepositProps } from "../common/types";
import {
  executeGtmOnFirstInteraction,
  handleDepositStateReset,
  onAddPaymentMethodClick,
  onAddPaymentFromWagerpad,
  onBack,
  onDepositClose,
  refetchOnModalOpen,
  shouldRenderHeader
} from "./methods/modalStateManagement";
import { onSubmitDeposit } from "./methods/submitMethods";
import { onClearValue, onFundsChangeValue } from "./methods/valueModifiers";
import { handleCloseError, handleRetry } from "./methods/errorHandling";
import {
  changeSelectedInput,
  getMaxLimit,
  getMinLimit,
  getPawsErrorMessage,
  getWarningFlagText,
  shouldRenderContent
} from "./methods/valueHandling";
import LoadingMask from "../common/LoadingMask";
import PaypalDepositBtn from "../PaypalDepositBtn";

const DepositFundsModal = ({
  isQuickDeposit,
  refetchAvailableMethods,
  onCloseCallback,
  scrollToBottom,
  availablePaymentMethodsInfos,
  history,
  isQuickDepositOpen,
  promosOnboarding,
  isUserInPromoOnboarding,
  promoOnboardingStepsNumber,
  promoOnboardingSteps,
  promoOnboardingActions,
  promoOnboardingCustomLabel = () => "",
  userHasBetPlaced
}: DepositProps) => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch();
  const location = useLocation();
  /**
   * REDUX SELECTORS
   */
  const {
    formData,
    fromCreation,
    isModalOpen: isModalOpenRedux,
    isRedirectFromMZM
  }: FundsModalContent = useSelector(getDepositFundsModal);
  const { mainMethods }: MainMethods = useSelector(getMainMethods);
  const {
    id: selectedPaymentMethodId,
    paymentType,
    minLimit: minLimitStore = 0,
    maxLimit: maxLimitStore = 10000000
  }: PaymentTypeOption = useSelector(getSelectedPaymentMethod);
  const limits = useSelector<Store, PawsLimits>(getPawsLimits);
  const accountId = useSelector<Store, string>(getAccountNumber);
  const userBalance = useSelector<Store, number>(getBalance);
  const content = useSelector<Store, PawsContent>(getPawsContent);
  const accountsOnFile: PaymentTypeOption[] = useSelector(
    getAccountsOnFileMethods
  );
  const isCardDeclinedToggleOn = useSelector(isCardDeclinedToggle);
  const cardDeclinedContent = useSelector(getCardDeclinedContent);
  const paymentMethodSelectorModalOpen: boolean = useSelector(
    getPaymentMethodSelectorModal
  );
  const isMZMToggleOn = useSelector(getMazoomaToggle);
  const isShowMZMSuccessModalToggleOn = useSelector<Store, boolean>(
    getPawsShowMZMSuccessModal
  );
  const isFirstDepositDone = useSelector<Store, boolean>(getIsFirstDepositDone);

  /**
   * CUSTOM HOOKS
   */
  const previousSelectedPaymentMethodId = usePrevious(selectedPaymentMethodId);

  /**
   * STATE
   */
  const [isDepositHoldFundsType, setIsDepositHoldFundsType] = useState(false);
  const [value, setValue] = useState("");
  const [cvv, setCVV] = useState("");
  const [cvvError, setCVVError] = useState("");
  const [isCVVInfoOpen, setIsCVVInfoOpen] = useState(false);
  const [feeValue, setFeeValue] = useState("");
  const [isFeeInfoOpen, setIsFeeInfoOpen] = useState(false);
  const [showAmountWarning, setShowAmountWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingQuickDeposit, setIsLoadingQuickDeposit] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedField, setSelectedField] = useState<FundsModalFields>(
    FundsModalFields.VALUE
  );
  const [hasError, setHasError] = useState(false);
  const [enableDepositPage, setEnableDepositPage] = useState<boolean>(false);
  const [hasLastUsed, setHasLastUsed] = useState<boolean>(true);
  const [isFeeCalculating, setFeeCalculating] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [currentMzmTab, setCurrentMzmTab] = useState<CurrentMzmTab>(null);

  /**
   * VARIABLES
   */
  const isModalOpen = isQuickDeposit ? !!isQuickDepositOpen : isModalOpenRedux;
  const minLimit = getMinLimit(paymentType, minLimitStore, limits);
  const maxLimit = getMaxLimit(paymentType, maxLimitStore, limits);

  const isWagerpad = location.pathname.includes("wagerpad");

  const capiMessageName = getCAPIMessageByType(paymentType);
  const errorMessageInitialState = getErrorMessageInitialState(
    content.paymentMessageModal.error.title,
    content.paymentMessageModal.error.description
  );
  const pawsErrorMessages = useSelector(getPawsErrorMessage(capiMessageName));
  const isFieldValueSelected = selectedField === "field-value";

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(
    errorMessageInitialState
  );
  const promoOnboardingPlace = isQuickDeposit
    ? StoryblokPlaceName.WALLET_QUICK_DEPOSIT
    : StoryblokPlaceName.WALLET_DEPOSIT_TOP;
  const isValueOutOfLimits = +value < +minLimit || +value > +maxLimit;
  const isCVVDisabled =
    (+value <= 0 && !cvv) || isLoading || isValueOutOfLimits;
  const showQuickDepositLoading =
    isQuickDepositOpen && isLoadingQuickDeposit && !showSuccessMessage;
  const showNoPaymentMethodsAddedMessage =
    isQuickDepositOpen &&
    !isLoadingQuickDeposit &&
    (!accountsOnFile.length || !hasLastUsed) &&
    !isEmpty(availablePaymentMethodsInfos);

  const promoOnboardingMatchDepositProp = matchDepositPropositions(
    promosOnboarding,
    paymentType,
    +value
  );
  const promoOnboardingCustomAmount =
    (promosOnboarding &&
      getCustomAmount(promosOnboarding, paymentType, +value)) ||
    0;

  useEffect(
    refetchOnModalOpen(
      isModalOpen,
      dispatch,
      accountId,
      errorMessageInitialState,
      isMZMToggleOn,
      isRedirectFromMZM,
      refetchAvailableMethods,
      setErrorMessage,
      setHasError,
      setHasLastUsed,
      setIsLoadingQuickDeposit,
      isQuickDepositOpen,
      availablePaymentMethodsInfos
    ),
    [
      isModalOpen,
      isQuickDepositOpen,
      JSON.stringify(availablePaymentMethodsInfos)
    ]
  );

  /**
   * Resets the interaction after the payment method has been changed.
   * This is related with the GTM Deposit start logic
   */
  useEffect(() => {
    setHasInteracted(false);
  }, [selectedPaymentMethodId]);

  useEffect(
    executeGtmOnFirstInteraction(
      isModalOpen,
      setHasInteracted,
      hasInteracted,
      accountId,
      paymentType,
      isFirstDepositDone,
      isQuickDeposit,
      isQuickDepositOpen
    ),
    [isModalOpen, isQuickDepositOpen, paymentType, hasInteracted]
  );

  const {
    setRequestMazooma,
    showMazoomaStatus,
    setMazoomaStatus,
    transactionData,
    enableMazoomaSubscription,
    currentMzmPermissions,
    setCurrentMzmPermissions
  } = useMazoomaDeposit(
    paymentType,
    value,
    setIsLoading,
    selectedPaymentMethodId,
    setValue,
    isQuickDepositOpen,
    setErrorMessage,
    setCurrentMzmTab
  );

  // Set default states if modal closes
  useEffect(() => {
    handleDepositStateReset(
      isModalOpen,
      setValue,
      setSelectedField,
      setIsDepositHoldFundsType,
      setCVV,
      setCVVError,
      setIsCVVInfoOpen,
      setFeeValue,
      setIsFeeInfoOpen,
      setShowAmountWarning,
      setIsLoading,
      setEnableDepositPage,
      setShowSuccessMessage,
      setRequestMazooma,
      setMazoomaStatus
    );
  }, [isModalOpen]);

  useCommonDeposit(
    value,
    cvv,
    setCVVError,
    errorMessage,
    onSubmitDeposit(
      isValueOutOfLimits,
      value,
      minLimit,
      maxLimit,
      fromCreation,
      paymentType,
      accountId,
      formData,
      cvv,
      selectedPaymentMethodId,
      dispatch,
      refetchAvailableMethods,
      setShowAmountWarning,
      pawsErrorMessages,
      errorMessageInitialState.description,
      isCardDeclinedToggleOn,
      cardDeclinedContent,
      !isFirstDepositDone,
      setErrorMessage,
      setRequestMazooma,
      setIsLoading,
      setIsDepositHoldFundsType,
      setShowSuccessMessage
    ),
    showAmountWarning,
    setShowAmountWarning,
    setFeeValue,
    selectedField,
    isQuickDepositOpen,
    isCVVDisabled,
    setSelectedField,
    setFeeCalculating
  );

  return (
    <>
      <ModalV2
        isOpen={isModalOpen}
        title={content.depositFundsModal.title}
        subtitle={`BALANCE ${formatCurrency(userBalance)}`}
        subtitleFontFamily={fontCondensedNormal}
        onClose={onDepositClose(
          dispatch,
          paymentMethodSelectorModalOpen,
          paymentType,
          accountId,
          onCloseCallback,
          setMazoomaStatus
        )}
        onOverlayClick={onDepositClose(
          dispatch,
          paymentMethodSelectorModalOpen,
          paymentType,
          accountId,
          onCloseCallback,
          setMazoomaStatus
        )}
        hasHeader={shouldRenderHeader(
          isLoading,
          !!showQuickDepositLoading,
          showSuccessMessage,
          showMazoomaStatus,
          enableDepositPage
        )}
        // This prop - onBack - could be removed once eCheck creation flow disappears (after the merge with MZM)
        onBack={
          fromCreation &&
          paymentType !== PaymentType.PAYPAL &&
          paymentType !== PaymentType.MAZOOMA
            ? onBack(
                paymentType,
                dispatch,
                paymentMethodSelectorModalOpen,
                accountId,
                onCloseCallback,
                setMazoomaStatus
              )
            : null
        }
        qaLabel="funds-modal"
        animation={isMobile ? "bottom" : "fade"}
        offsetLeft={isMobile ? 0 : 12}
        offsetRight={isMobile ? 0 : 12}
        fixedWidth={isMobile ? "100%" : "375px"}
        isFullWidth={isMobile}
        offsetTop={55}
        isFullHeight={isMobile}
        hasRoundedCorners={!isMobile}
        isTitleCapitalized={false}
        isTitleCenter
        useModalHeaderV3
      >
        {() => {
          if (showQuickDepositLoading) return <LoadingMask />;

          if (isQuickDeposit && !isLoadingQuickDeposit && hasError) {
            return (
              <GenericErrorMessageContainer
                onRetry={() => {
                  refetchAvailableMethodsForQuickDeposits(
                    dispatch,
                    accountId,
                    errorMessageInitialState,
                    isMZMToggleOn,
                    isRedirectFromMZM,
                    refetchAvailableMethods,
                    setErrorMessage,
                    setHasError,
                    setHasLastUsed,
                    setIsLoadingQuickDeposit
                  );
                }}
                retriesCount={errorMessage.retryCount}
                setRetryCount={(retryCount: number) =>
                  setErrorMessage((origin) => ({
                    ...origin,
                    retryCount
                  }))
                }
              />
            );
          }

          if (showNoPaymentMethodsAddedMessage) {
            return (
              <NoPaymentMethodsAddedContainer isMobile={isMobile}>
                <MessagePanel
                  title={content.noPaymentMethodModal.title}
                  description={content.noPaymentMethodModal.description}
                  variant="info"
                  actions={[
                    {
                      text: "Add Payment Method",
                      onClick: isWagerpad
                        ? onAddPaymentFromWagerpad(dispatch, location)
                        : onAddPaymentMethodClick(dispatch, location, history),
                      isStretched: true,
                      isUppercase: false,
                      qaLabel: "add-payment-method"
                    }
                  ]}
                />
              </NoPaymentMethodsAddedContainer>
            );
          }

          return (
            <>
              <Container isMobile={isMobile}>
                {shouldRenderContent(
                  showSuccessMessage,
                  showMazoomaStatus,
                  enableDepositPage,
                  isLoading
                ) && (
                  <>
                    {promosOnboarding && (
                      <PromosOnboarding
                        placeToRender={promoOnboardingPlace}
                        customMessage={promoOnboardingCustomLabel(
                          promoOnboardingCustomAmount
                        )}
                        isShown={
                          !isQuickDeposit
                            ? promoOnboardingMatchDepositProp
                            : true
                        }
                      />
                    )}
                    <Main>
                      <ValueContainer>
                        <InputValue
                          isLoading={isLoading || errorMessage.isOpen}
                          showAmountWarning={showAmountWarning}
                          value={value}
                          isDesktop={isDesktop}
                          onChangeValue={onFundsChangeValue(
                            isLoading,
                            selectedField,
                            setValue,
                            setCVV,
                            value,
                            cvv
                          )}
                          hasFocus={isFieldValueSelected && isModalOpen}
                          {...getSelectionEvent(
                            changeSelectedInput(
                              FundsModalFields.VALUE,
                              cvv,
                              content.deposit.CC.cvvInvalid,
                              content.deposit.CC.cvvEmpty,
                              setSelectedField,
                              setCVVError
                            )
                          )}
                        />
                        <FlagsContainer>
                          {!showAmountWarning && +feeValue > 0 && (
                            <Flag
                              qaLabel="fee-value"
                              className="flag"
                              text={`+ ${formatCurrency(+feeValue)} FEE`}
                              variant="fee"
                              showIcon={false}
                            />
                          )}
                          {showAmountWarning && (
                            <Flag
                              qaLabel="warning-value"
                              className="flag"
                              text={getWarningFlagText(
                                value,
                                minLimit,
                                maxLimit
                              )}
                            />
                          )}
                          {!isQuickDeposit && (
                            <PromosOnboardingFlagWrapper>
                              <PromosOnboarding
                                placeToRender={
                                  StoryblokPlaceName.WALLET_DEPOSIT_TAG
                                }
                                isShown={promoOnboardingMatchDepositProp}
                              />
                            </PromosOnboardingFlagWrapper>
                          )}
                        </FlagsContainer>
                      </ValueContainer>

                      {isDesktop && isFieldValueSelected && (
                        <MoneyPills
                          disabled={isLoading}
                          className="moneyPills"
                          onChangeValue={(keyValue: string) => {
                            onFundsChangeValue(
                              isLoading,
                              selectedField,
                              setValue,
                              setCVV,
                              value,
                              cvv
                            )(keyValue, "moneyPills");
                            gtmDepositDefaultSelect(paymentType, accountId);
                          }}
                        />
                      )}
                      {!fromCreation && (
                        <>
                          {isQuickDeposit && (
                            <PaymentMethodTitle>
                              Select Payment Method
                            </PaymentMethodTitle>
                          )}
                          <PaymentMethodSelector
                            className="payment-selector"
                            device={tvgConf().device}
                            isModal={isMobile}
                            isDisabled={!isQuickDepositOpen || isLoading}
                            isDeposit
                            scrollToBottom={scrollToBottom}
                            // Prevent event listener used for GTM "Deposit Start" to propagate
                            onMouseDown={(e: MouseEvent) => e.stopPropagation()}
                            onCloseDepositModal={onCloseCallback}
                            onChangePaymentMethod={() => {
                              setMazoomaStatus(false);
                              if (
                                previousSelectedPaymentMethodId !==
                                selectedPaymentMethodId
                              ) {
                                handleDepositStateReset(
                                  isModalOpen,
                                  setValue,
                                  setSelectedField,
                                  setIsDepositHoldFundsType,
                                  setCVV,
                                  setCVVError,
                                  setIsCVVInfoOpen,
                                  setFeeValue,
                                  setIsFeeInfoOpen,
                                  setShowAmountWarning,
                                  setIsLoading,
                                  setEnableDepositPage,
                                  setShowSuccessMessage,
                                  setRequestMazooma,
                                  setMazoomaStatus,
                                  true
                                );
                              }
                            }}
                          />
                        </>
                      )}

                      {paymentType === PaymentType.CREDIT_DEBIT_CARD && (
                        <InputField
                          label="CVV"
                          value={cvv}
                          onChangeValue={onFundsChangeValue(
                            isLoading,
                            selectedField,
                            setValue,
                            setCVV,
                            value,
                            cvv
                          )}
                          isDisabled={isCVVDisabled}
                          className="cvv-field"
                          hasFocus={
                            selectedField === FundsModalFields.CVV &&
                            isModalOpen
                          }
                          isDesktop={isDesktop}
                          onFocus={
                            +value > 0
                              ? changeSelectedInput(
                                  FundsModalFields.CVV,
                                  cvv,
                                  content.deposit.CC.cvvInvalid,
                                  content.deposit.CC.cvvEmpty,
                                  setSelectedField,
                                  setCVVError
                                )
                              : undefined
                          }
                          icon="cvv"
                          onClickIcon={() => setIsCVVInfoOpen(true)}
                          error={cvvError}
                        />
                      )}

                      {!isLoading && !isDesktop && (
                        <CustomKeyboard
                          className="custom-keyboard"
                          showPeriod={isFieldValueSelected}
                          showMoneyPills={isFieldValueSelected}
                          onDelete={onClearValue(
                            selectedField,
                            setValue,
                            setCVV,
                            value,
                            cvv
                          )}
                          onPeriod={onAddPeriod(value, setValue)}
                          onClickMoneyPills={() =>
                            gtmDepositDefaultSelect(paymentType, accountId)
                          }
                          onChangeValue={(
                            keyValue: number,
                            from: FundsAmountInputChangingFrom
                          ) =>
                            onFundsChangeValue(
                              isLoading,
                              selectedField,
                              setValue,
                              setCVV,
                              value,
                              cvv
                            )(
                              keyValue,
                              isFieldValueSelected && from === "mobile"
                                ? "mobileAmountField"
                                : from
                            )
                          }
                        />
                      )}

                      {isDesktop &&
                        !paymentMethodWithoutSummary.includes(
                          paymentType as PaymentType
                        ) && (
                          <Summary
                            value={value}
                            fee={feeValue}
                            className="summary"
                            isFeeCalculating={isFeeCalculating}
                          />
                        )}

                      {paymentType === PaymentType.PAYPAL ? (
                        <PaypalDepositBtn
                          value={value}
                          maxLimit={maxLimit}
                          minLimit={minLimit}
                          isModalOpen={isModalOpen}
                          isLoading={isLoading}
                          isLoadingQuickDeposit={isLoadingQuickDeposit}
                          setShowAmountWarning={setShowAmountWarning}
                          setErrorMessage={setErrorMessage}
                          pawsErrorMessages={pawsErrorMessages}
                          errorMessageInitialState={errorMessageInitialState}
                          setShowSuccessMessage={setShowSuccessMessage}
                          setIsLoading={setIsLoading}
                          dispatch={dispatch}
                          fromCreation={fromCreation}
                          refetchAvailableMethods={refetchAvailableMethods}
                        />
                      ) : (
                        <Button
                          text={
                            +value
                              ? `Authorize ${formatCurrency(
                                  +value + +feeValue
                                )} transaction`
                              : "Authorize transaction"
                          }
                          onClick={onSubmitDeposit(
                            isValueOutOfLimits,
                            value,
                            minLimit,
                            maxLimit,
                            fromCreation,
                            paymentType,
                            accountId,
                            formData,
                            cvv,
                            selectedPaymentMethodId,
                            dispatch,
                            refetchAvailableMethods,
                            setShowAmountWarning,
                            pawsErrorMessages,
                            errorMessageInitialState.description,
                            isCardDeclinedToggleOn,
                            cardDeclinedContent,
                            !isFirstDepositDone,
                            setErrorMessage,
                            setRequestMazooma,
                            setIsLoading,
                            setIsDepositHoldFundsType,
                            setShowSuccessMessage
                          )}
                          isLoading={isLoading}
                          hasError={
                            showAmountWarning ||
                            +value <= 0 ||
                            (cvv.length !== 3 && paymentType === "CCP")
                          }
                        />
                      )}
                    </Main>
                  </>
                )}
                {(showSuccessMessage ||
                  (showMazoomaStatus && !enableMazoomaSubscription)) && (
                  <MessagePanel
                    title={
                      content[
                        isDepositHoldFundsType
                          ? "transactionDepositFundsHoldSuccessMessage"
                          : "transactionDepositSuccessMessage"
                      ].title
                    }
                    description={[
                      replaceCAPIVariables(
                        content[
                          isDepositHoldFundsType
                            ? "transactionDepositFundsHoldSuccessMessage"
                            : "transactionDepositSuccessMessage"
                        ].description1,
                        {
                          added: `<b>${formatCurrency(
                            value || get(transactionData, "amount", 0)
                          )}</b>`,
                          balance: `<b>${formatCurrency(userBalance)}</b>`
                        }
                      ),
                      replaceCAPIVariables(
                        content[
                          isDepositHoldFundsType
                            ? "transactionDepositFundsHoldSuccessMessage"
                            : "transactionDepositSuccessMessage"
                        ].description2,
                        {
                          added: `<b>${formatCurrency(+value)}</b>`,
                          balance: `<b>${formatCurrency(userBalance)}</b>`
                        }
                      )
                    ]}
                    variant={isDepositHoldFundsType ? "warning" : "success"}
                    actions={[
                      {
                        text: "Close",
                        onClick: () => {
                          onDepositClose(
                            dispatch,
                            paymentMethodSelectorModalOpen,
                            paymentType,
                            accountId,
                            onCloseCallback,
                            setMazoomaStatus
                          )();
                          AlchemerEvents.closeDeposit();
                        },
                        isStretched: true,
                        isUppercase: false,
                        type: "primary"
                      }
                    ]}
                    className="success-message"
                    isUserInPromoOnboarding={isUserInPromoOnboarding}
                    promoOnboardingStepsNumber={promoOnboardingStepsNumber}
                    promoOnboardingSteps={promoOnboardingSteps}
                    promoOnboardingActions={
                      promoOnboardingActions
                        ? promoOnboardingActions(
                            onDepositClose(
                              dispatch,
                              paymentMethodSelectorModalOpen,
                              paymentType,
                              accountId,
                              onCloseCallback,
                              setMazoomaStatus
                            )
                          )
                        : []
                    }
                    bonusAmount={promoOnboardingCustomAmount}
                    hasMatchingProposition={promoOnboardingMatchDepositProp}
                    userHasBetPlaced={userHasBetPlaced}
                  />
                )}

                {showMazoomaStatus && enableMazoomaSubscription && (
                  <ApolloContext.Consumer>
                    {(clients: {
                      gasClient: ApolloClient<NormalizedCacheObject>;
                    }) => (
                      <TransactionStatus
                        type="deposit"
                        enableTransactionPage={(enabled: boolean) => {
                          setEnableDepositPage(enabled);
                        }}
                        paymentType={paymentType}
                        amount={get(transactionData, "amount", null)}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        onClose={onDepositClose(
                          dispatch,
                          paymentMethodSelectorModalOpen,
                          paymentType,
                          accountId,
                          () => {
                            onCloseCallback();
                            setErrorMessage((prevState) => ({
                              ...prevState,
                              isOpen: false,
                              isRetry: false
                            }));
                          },
                          setMazoomaStatus
                        )}
                        accountId={accountId}
                        transactionId={get(transactionData, "uuid", null)}
                        gasClient={clients.gasClient}
                        setErrorMessage={setErrorMessage}
                        errorMessage={errorMessage}
                        showSuccessModal={isShowMZMSuccessModalToggleOn}
                        setValue={setValue}
                        currentMzmTab={currentMzmTab}
                        setCurrentMzmTab={setCurrentMzmTab}
                        refetchAvailableMethods={refetchAvailableMethods}
                        currentMzmPermissions={currentMzmPermissions}
                        setCurrentMzmPermissions={setCurrentMzmPermissions}
                      />
                    )}
                  </ApolloContext.Consumer>
                )}
              </Container>
            </>
          );
        }}
      </ModalV2>

      {paymentType === PaymentType.CREDIT_DEBIT_CARD && isCVVInfoOpen && (
        <AlertMessagesModal
          title={content.deposit.CC.ccInfo.title}
          description={content.deposit.CC.ccInfo.description}
          icon={<CVV width="62" height="48" />}
          isOpen={isCVVInfoOpen}
          onCloseMethod={() => setIsCVVInfoOpen(false)}
          isMobile={isMobile}
          actions={[
            {
              text: content.deposit.CC.ccInfo.buttonText,
              onClick: () => setIsCVVInfoOpen(false),
              isStretched: true,
              isUppercase: false,
              qaLabel: "close-cvv-info"
            }
          ]}
        />
      )}
      <AlertMessagesModal
        title={
          content[isMZMToggleOn ? "depositMzmFeeModal" : "depositFeeModal"]
            .title
        }
        description={
          content[isMZMToggleOn ? "depositMzmFeeModal" : "depositFeeModal"]
            .description
        }
        icon={<Promo />}
        isOpen={isFeeInfoOpen}
        onCloseMethod={() => setIsFeeInfoOpen(false)}
        isMobile={isMobile}
        actions={[
          {
            text: content[
              isMZMToggleOn ? "depositMzmFeeModal" : "depositFeeModal"
            ].buttonText,
            onClick: () =>
              redirectOnFeeFree({
                dispatch,
                paymentMethod: getLastUsedPMByPaymentType(
                  accountsOnFile,
                  isMZMToggleOn ? PaymentType.MAZOOMA : PaymentType.ECHECK
                ),
                isQuickDeposit: isQuickDepositOpen,
                isDesktop,
                history,
                setIsFeeInfoOpen,
                isMZMToggleOn,
                mainMethods
              }),
            isStretched: true,
            isUppercase: false,
            qaLabel: "setup-mazooma"
          }
        ]}
      />
      {!showMazoomaStatus && (
        <AlertMessagesModal
          type={errorMessage.type || "error"}
          isOpen={errorMessage.isOpen}
          errorCode={errorMessage.errorCode}
          isCapiDefault
          isMobile={isMobile}
          onCloseMethod={handleCloseError(setErrorMessage)}
          onRetryMethod={handleRetry(setErrorMessage, errorMessage)}
          onContactMethod={() => openLiveChat()}
          retryCount={errorMessage.retryCount}
          actions={[
            {
              text: "Close",
              onClick: handleCloseError(setErrorMessage),
              isStretched: true,
              isUppercase: false,
              qaLabel: "close"
            }
          ]}
        />
      )}
    </>
  );
};

export default DepositFundsModal;
