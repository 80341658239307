import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Warning = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M2.32142 21.375H21.6775C22.0524 21.375 22.294 20.9777 22.1214 
      20.6448L12.4439 1.981C12.2573 1.62122 11.7427 1.62121 11.5561 
      1.98099L1.87755 20.6448C1.70496 20.9777 1.9465 21.375 2.32142 21.375Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M12 9V15M12 16.5V18.75M11.5561 1.98099L1.87755 20.6448C1.70496 20.9777 
      1.9465 21.375 2.32142 21.375H21.6775C22.0524 21.375 22.294 20.9777 22.1214 
      20.6448L12.4439 1.981C12.2573 1.62122 11.7427 1.62121 11.5561 1.98099Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);
export default Warning;
