// @flow

import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";

export const Container = styled.div`
  position: relative;
  display: flex;
  ${(props) =>
    props.containerHeight
      ? css`
          height: ${props.containerHeight}px;
          max-height: ${props.containerHeight}px;
        `
      : css`
          width: 100%;
          height: 100%;
        `};
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.background
      ? css`
          background-image: url("${props.background}");
          background-size: 100% 100%;
        `
      : css`
          background-color: ${buildColor("black", "100")};
        `};

  & canvas {
    ${(props) => props.hideCanvas && "display: none;"}
  }
`;

export const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
`;
