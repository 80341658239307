import styled, { css } from "styled-components/native";
import { Platform, View, Text } from "react-native";
import { compose, variant, margin } from "../../theming/styledSystem";
import { Paragraph } from "../typography";
import { TagVariant } from "./types";

const isWeb = Platform.OS === "web";

export const tagVariants = {
  info: {
    backgroundColor: "background.base",
    color: "content.neutral"
  },
  optedin: {
    backgroundColor: "background.positiveSubtle",
    color: "content.positive"
  },
  promo: {
    backgroundColor: "background.alertSubtle",
    color: "content.alert"
  },
  warning: {
    backgroundColor: "background.warningSubtle",
    color: "content.warning"
  },
  highlight: {
    backgroundColor: "background.infoSubtle",
    color: "content.info"
  },
  fav: {
    backgroundColor: "component.notification.promo.background",
    color: "content.strong"
  }
};

export const tagSizes = {
  prop: "size",
  variants: {
    s: {
      paddingX: "space-2",
      height: "16px"
    },
    m: {
      paddingX: "space-2",
      height: "20px"
    },
    l: {
      paddingX: "space-2",
      height: "24px"
    }
  }
};

const stylesTagSizes = compose(variant(tagSizes));

export const styleFitContent =
  isWeb &&
  css`
    width: fit-content;
  `;

export const TagContainer = styled(View)<{
  backgroundColor: string;
  variant: TagVariant;
}>`
  ${margin}
  ${stylesTagSizes}
  align-items: center;
  align-self: flex-start;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 99px;
  flex-direction: row;
  justify-content: center;
`;

export const StyledParagraph = styled(Paragraph)`
  text-transform: uppercase;
`;

export const StyledText = styled(Text)<{ color: string }>`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.condensedRegular};
  color: ${({ color }) => color};
`;
