import React from "react";
import { Path } from "react-native-svg";

const Rs = () => (
  <>
    <Path
      d="M20.8979 10.5487C20.8746 10.3853 20.8279 10.2687 20.7346 10.152C20.6646
      10.0587 20.5713 9.96536 20.4546 9.91869C20.2446 9.80203 19.988 9.75537
      19.6847 9.75537C19.358 9.75537 19.1014 9.80203 18.9614 9.89536C18.8214
      9.98869 18.7281 10.1053 18.7281 10.292C18.7281 10.4553 18.7981 10.5953
      18.9614 10.712C19.1247 10.8286 19.3114 10.922 19.568 11.0153C19.8247
      11.1086 20.1046 11.1786 20.408 11.2486C20.7113 11.3186 21.0379 11.4119
      21.3646 11.5053C21.6912 11.5986 22.0179 11.7152 22.3212 11.8319C22.6245
      11.9719 22.9045 12.1352 23.1611 12.3452C23.4178 12.5552 23.6044 12.8118
      23.7677 13.0918C23.9311 13.3951 24.0011 13.7451 24.0011 14.1651C24.0011
      14.7017 23.8844 15.1684 23.6744 15.565C23.4644 15.9616 23.1611 16.2883
      22.7878 16.5449C22.4145 16.8016 21.9479 16.9882 21.4346 17.1282C20.8979
      17.2682 20.338 17.3149 19.708 17.3149C19.078 17.3149 18.5647 17.2216
      18.0748 17.0582C17.5848 16.8949 17.1648 16.6616 16.8148 16.3816C16.4649
      16.1016 16.1849 15.775 15.9982 15.4017C15.7882 15.0284 15.6949 14.6317
      15.6716 14.2351H18.5403C18.6103 14.5617 18.7737 14.7951 19.0303
      14.9584C19.147 15.0284 19.287 15.0984 19.427 15.1217C19.5903 15.1684
      19.7303 15.1684 19.8703 15.1684C20.2436 15.1684 20.5469 15.1217 20.7802
      15.005C21.0135 14.8884 21.1068 14.7251 21.1068 14.4917C21.1068 14.2584
      20.9669 14.0951 20.7102 13.9551C20.4536 13.8384 20.1269 13.7218 19.7303
      13.6285C19.3336 13.5351 18.9137 13.4185 18.447 13.3018C17.9804 13.1852
      17.5604 13.0218 17.1638 12.8118C16.7671 12.6019 16.4405 12.3219 16.1838
      11.9486C15.9272 11.5986 15.7872 11.1319 15.7872 10.5253C15.7872 10.0354
      15.8805 9.61538 16.0905 9.24207C16.3005 8.86876 16.5571 8.56544 16.9071
      8.33213C17.2571 8.09881 17.6771 7.91215 18.167 7.79549C18.657 7.67883
      19.1703 7.60884 19.7536 7.60884C20.3836 7.60884 20.9435 7.67883 21.4102
      7.84216C21.8768 8.00548 22.2734 8.19213 22.5768 8.47212C22.9034 8.72877
      23.1367 9.03208 23.3234 9.40539C23.51 9.75537 23.6267 10.1287 23.6734
      10.5253H20.8979V10.5487Z"
      fill="#B1B235"
    />
    <Path
      d="M10.2798 10.8286V11.9708H11.9597L10.2798
      13.3241V14.5373H13.9652V13.3241H12.3086L13.9652 11.9942V10.8286H10.2798Z"
      fill="#B1B235"
    />
    <Path
      d="M10.2798 15.1206V17.0561C10.2798 17.0561 10.3731 18.7127 12.123
      18.7127C13.8729 18.7127 13.9662 17.0561 13.9662
      17.0561V15.1206H10.2798ZM12.9152 16.8239C12.9152 16.8239 12.8453 17.5471
      12.122 17.5471C11.3987 17.5471 11.3287 16.8239 11.3287
      16.8239V16.3339H12.9152V16.8239Z"
      fill="#B1B235"
    />
    <Path
      d="M10.2798 6V7.39991L10.6998 7.58657V8.98648L10.2798
      9.19646V10.503L13.9652 8.96315V7.65656L10.2798 6ZM11.6331
      8.58878V7.88882L12.683 8.2388L11.6331 8.58878Z"
      fill="#B1B235"
    />
    <Path
      d="M8.81732 10.7586C8.86399 8.77649 6.81184 8.19319 5.87857
      8.19319H1.44658L0 17.2661H2.49545L3.05541 13.954H3.8487L5.24861
      17.2661H8.02404L6.36748 13.7907C6.36748 13.7907 8.77066 12.9041 8.81732
      10.7586ZM5.80857 11.8552C5.57525 12.0652 5.3886 12.1586 5.08528
      12.1586H3.3354L3.66204 10.1531H4.8753C5.17861 10.1531 5.52859 10.0831
      5.80857 10.2697C6.06522 10.4331 6.18188 10.7597 6.18188 11.0397C6.18188
      11.3897 6.04189 11.6686 5.80857 11.8552Z"
      fill="#004693"
    />
  </>
);

export default Rs;
