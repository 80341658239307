import React from "react";
import { Circle, Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Cvv = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M21.375 5.625H1.125V10.8806H10.7656C10.7656 10.8806 12.5078 8.25 
      16.1914 8.25C19.875 8.25 21.375 10.7674 21.375 10.7674V10.7617V5.625Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M13.4351 16.9782H12.5152V14.0534L11.6117 14.3171V13.6212L13.3507 
      13.0205H13.4351V16.9782Z"
      fill={lineColor}
    />
    <Path
      d="M17.3947 16.9782H14.6298V16.3802L15.9034 15.0428C16.2173 14.6859 
      16.3742 14.4022 16.3742 14.192C16.3742 14.0217 16.337 13.8921 16.2626 
      13.8033C16.1882 13.7145 16.0803 13.6701 15.9388 13.6701C15.7991 13.6701 
      15.6857 13.7299 15.5986 13.8495C15.5115 13.9673 15.468 14.115 15.468 
      14.2926H14.5481C14.5481 14.0498 14.6089 13.826 14.7305 13.6212C14.852 
      13.4146 15.0208 13.2534 15.2367 13.1374C15.4526 13.0214 15.6939 12.9634 
      15.9606 12.9634C16.3887 12.9634 16.718 13.0622 16.9484 13.2597C17.1807 
      13.4572 17.2968 13.7408 17.2968 14.1105C17.2968 14.2663 17.2677 14.4186 
      17.2097 14.5672C17.1516 14.7139 17.0609 14.8689 16.9375 15.032C16.816 
      15.1932 16.6191 15.4098 16.347 15.6816L15.8354 16.2715H17.3947V16.9782Z"
      fill={lineColor}
    />
    <Path
      d="M18.6956 14.6161H19.1283C19.4712 14.6161 19.6426 14.4485 19.6426 
      14.1132C19.6426 13.9827 19.6018 13.8767 19.5202 13.7952C19.4385 13.7118 
      19.3233 13.6701 19.1745 13.6701C19.053 13.6701 18.9469 13.7055 18.8561 
      13.7762C18.7672 13.8468 18.7228 13.9347 18.7228 14.0398H17.8057C17.8057 
      13.8314 17.8637 13.6457 17.9798 13.4826C18.096 13.3195 18.2565 13.1926 
      18.4615 13.102C18.6684 13.0096 18.8952 12.9634 19.1419 12.9634C19.5828 
      12.9634 19.9293 13.064 20.1815 13.2651C20.4337 13.4663 20.5598 13.7426 
      20.5598 14.0942C20.5598 14.2645 20.5071 14.4258 20.4019 14.578C20.2985 
      14.7284 20.147 14.8508 19.9474 14.945C20.1579 15.0211 20.323 15.1371 
      20.4427 15.2929C20.5625 15.4469 20.6223 15.6381 20.6223 15.8665C20.6223 
      16.2198 20.4863 16.5025 20.2141 16.7145C19.942 16.9266 19.5846 17.0326 
      19.1419 17.0326C18.8825 17.0326 18.6412 16.9836 18.418 16.8858C18.1967 
      16.7861 18.0288 16.6493 17.9145 16.4753C17.8002 16.2996 17.7431 16.1002 
      17.7431 15.8773H18.6656C18.6656 15.9987 18.7146 16.1039 18.8126 
      16.1926C18.9106 16.2814 19.0312 16.3258 19.1745 16.3258C19.336 16.3258 
      19.4648 16.2814 19.561 16.1926C19.6571 16.102 19.7052 15.987 19.7052 
      15.8474C19.7052 15.6481 19.6553 15.5068 19.5555 15.4234C19.4558 15.34 
      19.3179 15.2984 19.1419 15.2984H18.6956V14.6161Z"
      fill={lineColor}
    />
    <Circle
      cx="16.125"
      cy="14.9999"
      r="6.75"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
    <Path
      d="M10.244 18.3739H1.125V5.62392H21.375V10.7078"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M1.13259 10.8836L10.7547 10.8836"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M4.88782 14.6246L9.37891 14.6246"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
export default Cvv;
