import { useContext } from "react";
import { get } from "lodash";
import { ThemeContext } from "styled-components/native";

export const useColorTokens = (value?: string) => {
  const useTheme = () => useContext(ThemeContext);
  const { colorTokens } = useTheme();

  if (value) {
    const tokenValue = get(colorTokens, value);

    if (!tokenValue && process.env.NODE_ENV !== "production") {
      throw new Error(`Invalid color token: ${value}`);
    }

    return tokenValue;
  }

  return colorTokens;
};
