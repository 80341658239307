import React from "react";
import { IconPathProps } from "../../types";

export const Close = ({ lineColor, strokeWidth }: IconPathProps) => (
  <path
    d="M5.25 5.25L18.75 18.75M18.75 5.25L5.25 18.75"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
