import { createSlice } from "@reduxjs/toolkit";

export type State = {
  isOpen: boolean;
};

export const initialState: State = {
  isOpen: false
};

const quickWithdrawModalSlice = createSlice({
  name: "quickWithdrawModal",
  initialState,
  reducers: {
    toggleQuickWithdraw(state, action) {
      state.isOpen = action.payload;
    }
  }
});

export const { toggleQuickWithdraw } = quickWithdrawModalSlice.actions;

export default quickWithdrawModalSlice.reducer;
