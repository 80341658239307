import React from "react";
import { get } from "styled-system";

import { Theme } from "../types";

interface IProps extends React.HTMLProps<HTMLElement> {
  theme: Theme;
}

const themeGet =
  (path: string, fallback?: string | number) =>
  ({ theme }: IProps) =>
    get(theme, path, fallback!);

export default themeGet;
