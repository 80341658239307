import { DepositProposition, WagerProposition } from "./promoPropositions";
import { PromoComponent } from "./promoOnboardingComponents";

export type PromoStepResponseType = "DepositStep" | "WagerStep" | "PayoutStep";

export enum PromoStepType {
  DEPOSIT = "DEPOSIT_STEP",
  WAGER = "WAGER_STEP",
  PAYOUT = "PAYOUT_STEP",
  UNKNOWN = ""
}

export enum PromoStepDescriptionKey {
  DEFAULT = "default",
  LOSS = "loss",
  WIN = "win",
  PROCESSING = "processing"
}

export type PromoStepDescriptions = Partial<
  Record<PromoStepDescriptionKey, string>
>;

export enum PromoStepWagerStatus {
  PLACED = "P",
  WIN = "W",
  LOST = "L"
}

export interface PromoGenericStep {
  isFulfilled?: boolean;
  places: PromoComponent;
  description: PromoStepDescriptions;
  stepType?: PromoStepType;
  "@type"?: PromoStepResponseType;
}

export interface DepositStep extends PromoGenericStep {
  stepType?: PromoStepType.DEPOSIT;
  depositDate?: string;
  depositPropositions?: Array<DepositProposition>;
  "@type"?: "DepositStep";
}

export interface WagerStep extends PromoGenericStep {
  isGreyHound?: boolean;
  raceDate?: string;
  raceNumber?: number;
  serialNumber?: string;
  stepType?: PromoStepType.WAGER;
  trackCode?: string;
  trackName?: string;
  wagerStatus?: PromoStepWagerStatus;
  wagerDate?: string;
  wagerPropositions?: Array<WagerProposition>;
  "@type"?: "WagerStep";
}

export interface PayoutStep extends PromoGenericStep {
  payoutUUID: string;
  amount: number;
  timestamp: string;
  "@type"?: "PayoutStep";
}

export type PromoOnboardingStep = WagerStep | PayoutStep | DepositStep;

export type PromoMappedSteps = Partial<
  Record<PromoStepType, PromoOnboardingStep>
>;
