import { rgba } from "polished";

import { colors } from "./colors";
import { Elevations } from "../../types";

export const elevations: Elevations = {
  low: `0px 1px 3px ${rgba(colors.blue["900"], 0.12)}`,
  medium: `0px 2px 4px ${rgba(colors.blue["900"], 0.14)}`,
  high: `0px 1px 16px ${rgba(colors.blue["900"], 0.25)}`
};
