import { useState, useEffect, FunctionComponentElement } from "react";
import { getStoryblokComponent } from "../utils/getStoryblokComponent";
import { StoryblokComponentPropsFormatted } from "../types/storyblok";
import {
  StoryblokComponentName,
  StorybookAvailableComponents
} from "../types/promoOnboardingComponents";
import { PromoOnboarding } from "../types/promoOnboarding";
import { PromoOnboardingStep } from "../types/promoSteps";
import { formatComponentWithDynamicVariables } from "../helpers/formatComponentWithDynamicVariables";

export const usePromosOnboardingComponent = (
  promoOnboarding: StoryblokComponentPropsFormatted | null,
  promo: PromoOnboarding | null,
  currentStep: PromoOnboardingStep | null,
  customMessage?: string | null
) => {
  const [promoComponent, setPromoComponent] =
    useState<FunctionComponentElement<StorybookAvailableComponents> | null>(
      null
    );

  useEffect(() => {
    const swappedMessage = (() => {
      switch (promoOnboarding?.component) {
        case StoryblokComponentName.ALERT_INLINE:
          return { message: customMessage };
        case StoryblokComponentName.TAG:
          return { label: customMessage };
        default:
          return {};
      }
    })();

    const componentWithCustomMessage = customMessage
      ? {
          ...promoOnboarding,
          props: { ...promoOnboarding?.props, ...swappedMessage }
        }
      : promoOnboarding;

    const formattedComponent =
      componentWithCustomMessage && promo && currentStep
        ? formatComponentWithDynamicVariables(
            componentWithCustomMessage as StoryblokComponentPropsFormatted,
            promo,
            currentStep
          )
        : promoOnboarding;

    setPromoComponent(
      promoOnboarding &&
        getStoryblokComponent(
          formattedComponent as StoryblokComponentPropsFormatted
        )
    );
  }, [promoOnboarding]);

  return promoComponent;
};
