import { PaymentTypeItem, PaymentTypeOption } from "@tvg/wallet/src/types";
import { isCardExpired } from "@tvg/wallet/src/utils";

export const filterAndSortAccountsOnFile = (
  accountsOnFile: PaymentTypeOption[],
  isMZMToggleOn: boolean = false
) =>
  accountsOnFile
    .filter(
      (method: PaymentTypeOption) =>
        method.paymentType !== "SLP" &&
        (isMZMToggleOn || method.paymentType !== "MZM")
    )
    .filter(
      (pm) => pm.withdrawalsAvailable && !isCardExpired(pm.expirationDate)
    )
    .filter((pm) => pm.paymentTypeAvailable)
    .sort((a, b) => {
      const dateA = new Date(a.lastUsedDateTime || 0);
      const dateB = new Date(b.lastUsedDateTime || 0);
      return dateB.getTime() - dateA.getTime();
    });

export const getChkPmOption = (otherMethods: PaymentTypeItem[]) => {
  const chk = otherMethods.find((item) => item.paymentType === "CHK");

  if (!chk || chk.paymentTypeOptions.length === 0) return null;

  return chk.paymentTypeOptions[0];
};
