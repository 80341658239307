import React, { useContext, isValidElement, FC } from "react";
import {
  SegmentTab,
  SegmentTabContent,
  SegmentText
} from "../../styled-components";
import type { TabProps } from "./types";
import { SCContext } from "../../context";

export const SegmentedControlTab: FC<TabProps> = ({
  value,
  qaLabel,
  children,
  end,
  onClick
}: TabProps): JSX.Element => {
  const { selectedValue, isDisabled } = useContext(SCContext);

  return (
    <SegmentTab
      data-qa-label={qaLabel}
      role="tab"
      aria-disabled={isDisabled}
      onClick={!isDisabled ? onClick : undefined}
      aria-selected={value === selectedValue}
    >
      <SegmentTabContent>
        {isValidElement(children) ? (
          children
        ) : (
          <SegmentText
            isSelected={value === selectedValue}
            isDisabled={isDisabled}
          >
            {children}
          </SegmentText>
        )}
        {end}
      </SegmentTabContent>
    </SegmentTab>
  );
};
