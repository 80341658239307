import {
  createGeoTokenCookie,
  createLicenseCached,
  getGeoTokenCookie,
  getLicenseCached,
  clearGeoTokenCookie,
  clearLicenseCached,
  getTemporaryRelocateUserProcessId,
  createTemporaryRelocateUserProcessId,
  clearTemporaryRelocateUserProcessId
} from "@tvg/sh-utils/sessionUtils";
import { get } from "lodash";
import {
  GeoClientTokenStatus,
  GeoComplyCacheEntries,
  GeoStorageKey
} from "./types/solus";

export const geoDataMapped: GeoComplyCacheEntries = {
  [GeoStorageKey.GEO_PACKET]: {
    saveCache: createGeoTokenCookie,
    removeCache: clearGeoTokenCookie,
    getCache: getGeoTokenCookie
  },
  [GeoStorageKey.LICENSE]: {
    saveCache: createLicenseCached,
    removeCache: clearLicenseCached,
    getCache: getLicenseCached
  },
  [GeoStorageKey.RELOCATE_ID]: {
    saveCache: createTemporaryRelocateUserProcessId,
    removeCache: clearTemporaryRelocateUserProcessId,
    getCache: getTemporaryRelocateUserProcessId
  }
};

export const setCacheGeoData = (
  storageKey: GeoStorageKey,
  storageValue: string
) => {
  const saveCache = get(geoDataMapped, `${storageKey}.saveCache`);
  if (typeof window !== "undefined" && typeof saveCache === "function") {
    saveCache(storageValue);
  }
};

export const cleanCacheGeoData = () => {
  if (typeof window !== "undefined") {
    Object.values(GeoStorageKey).forEach((geoStorageKey) => {
      const removeCache = get(geoDataMapped, `${geoStorageKey}.removeCache`);
      if (typeof removeCache === "function") {
        removeCache();
      }
    });
  }
};

export const getCacheGeoData = (storageKey: GeoStorageKey) => {
  const getCache = get(geoDataMapped, `${storageKey}.getCache`);
  let geoData = null;
  if (typeof window !== "undefined" && typeof getCache === "function") {
    geoData = getCache();
  }

  return geoData;
};

export const getPreviousTokenStatus = (tokenStatus?: boolean) => {
  if (typeof tokenStatus === "undefined") {
    return GeoClientTokenStatus.NO_TOKEN;
  }

  return tokenStatus
    ? GeoClientTokenStatus.SUCCESSFUL_TOKEN
    : GeoClientTokenStatus.FAILED_TOKEN;
};

export const getCacheGeoClientLicense = () =>
  getCacheGeoData(GeoStorageKey.LICENSE);

export const getCacheGeoPacket = () =>
  getCacheGeoData(GeoStorageKey.GEO_PACKET);

export const getGeoExpiryTime = (geolocateIn: number) => {
  const MAX_GEOLOCATE_TIME = 300;
  const MAX_GEOLOCATE_DIFF = geolocateIn - 30; // reduce by 30 seconds  // 3570
  const MIN_GEOLOCATE_DIFF = geolocateIn * 0.7; // reduce by 30% // 2499
  const buffer =
    geolocateIn >= MAX_GEOLOCATE_TIME ? MAX_GEOLOCATE_DIFF : MIN_GEOLOCATE_DIFF;

  const bufferInMilliseconds = buffer * 1000;
  return bufferInMilliseconds;
};
