import React, { useState, Fragment, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "redux";
import parseCapiMessage from "@tvg/utils/capiUtils";
import tvgConf from "@tvg/conf";
import { get } from "lodash";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import Icon from "@tvg/atomic-ui/_static/Icons";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { Warning as WarningIcon } from "@tvg/atomic-ui/_static/Icons/paws";
import { closeThin } from "@tvg/atomic-ui/_static/Icons/icons";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import {
  getCardDeclinedModal,
  getCardDeclinedModalAmount,
  getAccountsOnFileMethods
} from "@tvg/sh-lib-paws/redux/selectors";
import { useNavigate } from "@tvg/custom-hooks";
import { getPmToggles } from "../../utils";
import IconCard from "../IconCard";
import { PaymentType, PaymentTypeOption } from "../../types";
import { DataType, Props } from "./types";
import { onClose, onSubmit } from "./utils";
import {
  Container,
  CloseButton,
  InfoContainer,
  Title,
  Text,
  CardsContainer,
  DetailsContainer,
  DetailsTitle,
  DetailsText,
  StyledButton,
  ListItem
} from "./styled-components";
import { data as defaultData } from "./defaultData";

const CardDeclinedModal = ({
  isQuickDeposit,
  isDesktop,
  otherMethods = []
}: Props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const data = useSelector((store) =>
    parseCapiMessage(
      store,
      "capi.messages.pawsCardDeclinedContent",
      defaultData
    )
  );
  const pmToggles = useSelector(getPmToggles);
  const accountsOnFile: PaymentTypeOption[] = useSelector(
    getAccountsOnFileMethods
  );
  const mainMethods = useSelector((store) =>
    get(store, "paws.createPMModal.mainMethods", [])
  );
  const [selectedMethod, setSelectedMethod] = useState<PaymentType>(
    PaymentTypeEnum.ECHECK
  );
  const isMobile = tvgConf().device === "mobile";
  const isModalOpen = useSelector(getCardDeclinedModal);
  const declinedAmount = useSelector<Store, number>(getCardDeclinedModalAmount);
  const errorCode = useSelector((store) =>
    get(store, "paws.cardDeclinedModal.errorCode", null)
  );
  const paypalOnFile = accountsOnFile.filter(
    (item: PaymentTypeOption) => item.paymentType === PaymentTypeEnum.PAYPAL
  );

  const paymentTypeOptions = accountsOnFile.filter(
    (acc) => acc.paymentType === selectedMethod
  );

  const paymentOptions = useMemo(() => {
    const { errors } = data as DataType;
    const errorOptions = errors[errorCode] ? errors[errorCode] : errors.default;

    const currentErrorOption =
      declinedAmount >= 2 && Object.keys(errorOptions).length >= 2
        ? "second"
        : "first";

    // PayPal can only be on place (top or bottom) but still needs to verified
    const blockedPaymentOptions = [
      ...mainMethods,
      ...otherMethods,
      ...paypalOnFile
    ]
      .filter((item: PaymentTypeOption) => !item.depositsAvailable)
      .map((item: PaymentTypeOption) => item.paymentType);

    const options =
      errorOptions[currentErrorOption] || errors.default[currentErrorOption];

    return options
      ? options.filter(
          (po) => !blockedPaymentOptions.includes(po as PaymentType)
        )
      : [];
  }, [
    JSON.stringify(data),
    errorCode,
    declinedAmount,
    JSON.stringify(mainMethods),
    JSON.stringify(otherMethods),
    JSON.stringify(paypalOnFile)
  ]);

  const [paymentDetails, buttonContent] = useMemo(() => {
    const { content } = data as DataType;
    const { details, buttonText } = get(content, selectedMethod, {
      details: [],
      buttonText: ""
    });

    return [details, buttonText] as const;
  }, [JSON.stringify(data), selectedMethod]);

  useEffect(() => {
    if (paymentOptions && paymentOptions.length >= 1) {
      setSelectedMethod(paymentOptions[0] as PaymentType);
    }
  }, [paymentOptions]);

  return (
    <ModalV2
      isOpen={isModalOpen}
      onClose={() => onClose(dispatch)}
      qaLabel="card-declined-modal"
      animation="fade"
      hasRoundedCorners
      isContentTransparent
      isFullHeight={false}
      hasHeader={false}
      isFullWidth={isMobile}
      fixedWidth={isMobile ? "100%" : "351px"}
      offsetLeft={isMobile ? 12 : null}
      offsetRight={isMobile ? 12 : null}
      layerOffset={10}
    >
      {() => (
        <Container>
          <CloseButton
            qaLabel="modal-close-btn"
            onClick={() => onClose(dispatch)}
          >
            <Icon
              data-qa-label="modal-close-btn-icon"
              viewBoxSize={20}
              icon={closeThin}
              stroke={buildColor("grey", "900")}
              size={20}
            />
          </CloseButton>
          <InfoContainer data-qa-label="modal-info-container">
            <WarningIcon />
            <Title data-qa-label="modal-info-title">
              {get(data.errors[errorCode], "title", data.title)}
            </Title>
            <Text data-qa-label="modal-info-text">
              {get(data.errors[errorCode], "description", data.description)}
            </Text>
          </InfoContainer>
          <CardsContainer data-qa-label="cards-container">
            {paymentOptions &&
              paymentOptions.map((type, index) => {
                const { content } = data as DataType;
                const { text, tagText } = content[type];
                return (
                  <IconCard
                    key={`payment-method-${index.toString()}`}
                    paymentType={type as PaymentType}
                    text={text}
                    tagText={tagText}
                    isActive={selectedMethod === type}
                    onClick={() => setSelectedMethod(type as PaymentType)}
                  />
                );
              })}
          </CardsContainer>
          <DetailsContainer data-qa-label="selected-payment-description">
            {paymentDetails &&
              paymentDetails.map(
                ({ title, list, text, paragraphs }, contentIndex) => (
                  <Fragment key={`detail-${contentIndex.toString()}`}>
                    {text && <DetailsText mb={12}>{text}</DetailsText>}
                    {title && <DetailsTitle>{title}</DetailsTitle>}
                    <ul>
                      {list &&
                        list.map((item, listIndex) => (
                          <ListItem key={`item-${listIndex.toString()}`}>
                            <DetailsText>{item}</DetailsText>
                          </ListItem>
                        ))}
                    </ul>
                    {paragraphs &&
                      paragraphs.map(
                        (paragraph, paragraphIndex, { length }) => {
                          const isLastItem = paragraphIndex + 1 === length;
                          return (
                            <DetailsText
                              key={`paragraph-${paragraphIndex.toString()}`}
                              mb={isLastItem ? 0 : 12}
                            >
                              {paragraph}
                            </DetailsText>
                          );
                        }
                      )}
                  </Fragment>
                )
              )}
            <StyledButton
              qaLabel="submit-button"
              onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
                onSubmit(
                  dispatch,
                  history,
                  selectedMethod,
                  paymentTypeOptions,
                  pmToggles,
                  !!paypalOnFile.length,
                  // @ts-ignore
                  accountsOnFile,
                  mainMethods,
                  event,
                  isQuickDeposit,
                  isDesktop
                )
              }
            >
              {buttonContent}
            </StyledButton>
          </DetailsContainer>
        </Container>
      )}
    </ModalV2>
  );
};

export default CardDeclinedModal;
