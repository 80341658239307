import { Dispatch, SetStateAction } from "react";
import {
  gtmDepositAddressSelect,
  gtmDepositAddressConfirm,
  gtmDepositAddressCancelModal,
  gtmWithdrawAddressCancelModal,
  gtmWithdrawAddressSelect,
  gtmWithdrawAddressConfirm
} from "../../gtm";
import { MessageType } from "./types";
import { AddressSelection, NullaryFn, PaymentType, UnaryFn } from "../../types";

export const onConfirm =
  (
    paymentType: PaymentType,
    accountID: string,
    onSubmit: UnaryFn<AddressSelection, void>,
    addressSelected: AddressSelection,
    messageType: MessageType
  ) =>
  () => {
    if (messageType === MessageType.WITHDRAW) {
      gtmWithdrawAddressConfirm({ paymentType, accountID });
    } else {
      gtmDepositAddressConfirm({ paymentType, accountID });
    }
    onSubmit(addressSelected);
  };

export const onCancel =
  (
    paymentType: PaymentType,
    accountID: string,
    onCancelCb: NullaryFn<void>,
    messageType: MessageType
  ) =>
  () => {
    if (messageType === MessageType.WITHDRAW) {
      gtmWithdrawAddressCancelModal({ paymentType, accountID });
    } else {
      gtmDepositAddressCancelModal({ paymentType, accountID });
    }
    onCancelCb();
  };

export const onAddressSelectorClick =
  (
    paymentType: PaymentType,
    accountID: string,
    setAddressSelected: Dispatch<SetStateAction<AddressSelection>>,
    messageType: MessageType
  ) =>
  (value: AddressSelection) => {
    if (messageType === MessageType.WITHDRAW) {
      gtmWithdrawAddressSelect({ paymentType, accountID });
    } else {
      gtmDepositAddressSelect({ paymentType, accountID });
    }
    setAddressSelected(value);
  };
