import { Icon } from "@tvg/design-system";
import React from "react";
import MessagePanel from "../../../MessagePanel";
import { CloseButton } from "./styled-components";
import { InfoMessageProps } from "./types";

const InfoMessage = ({
  description,
  actions,
  title,
  onClose
}: InfoMessageProps) => (
  <>
    <CloseButton variant="primary" onClick={onClose} qaLabel="close-modal-btn">
      <Icon name="close" size="m" />
    </CloseButton>
    <MessagePanel
      title={title}
      bodyChild={null}
      description={description}
      variant="info"
      actions={actions}
      className="info-message"
      fullPage
    />
  </>
);

export default React.memo(InfoMessage);
