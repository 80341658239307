import React from "react";
import { IconPathProps } from "../../types";

export const Add = ({ lineColor, strokeWidth }: IconPathProps) => (
  <path
    d="M12 4.5V19.5M4.5 12H19.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
