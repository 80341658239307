import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "redux";
import {
  getIsDeleteCardModalOpen,
  getDeleteCardModalPaymentMethod,
  getPawsContent
} from "@tvg/sh-lib-paws/redux/selectors";
import { Button } from "@tvg/design-system";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import DOMPurify from "dompurify";
import { getAccountNumber } from "@urp/store-selectors";
import { ActionsContainer, Description, Container } from "./styled-components";
import {
  PaymentTypeOption,
  PaymentType,
  PawsContent,
  UnaryFn
} from "../../types";
import { getDeleteTypeByPaymentType } from "../../utils";
import { onClose, onConfirm } from "./functions";
import ErrorModal from "./ErrorModal";
import { MessageParagraph } from "../MessagePanel/styled-components";

export interface DeleteCardModalProps {
  refetchAvailableMethods: () => Promise<unknown>;
  isMobile?: boolean;
  setIsAOFileOptionsModalOpen?: UnaryFn<boolean, void>;
}

const DeleteCardModal = ({
  refetchAvailableMethods,
  isMobile = false,
  setIsAOFileOptionsModalOpen
}: DeleteCardModalProps) => {
  const dispatch = useDispatch();
  const isOpen: boolean = useSelector(getIsDeleteCardModalOpen);
  const paymentMethod: PaymentTypeOption | null = useSelector(
    getDeleteCardModalPaymentMethod
  );
  const accountId: string = useSelector(getAccountNumber);
  const paymentType = paymentMethod?.paymentType as PaymentType;
  const paymentMethodId = paymentMethod?.id as string;
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { deleteCardModal, deleteCardModalSuccess } = useSelector<
    Store,
    PawsContent
  >(getPawsContent);

  const title = useMemo(
    () =>
      replaceCAPIVariables(deleteCardModal.title, {
        type: getDeleteTypeByPaymentType(paymentType)
      }),
    [deleteCardModal, paymentType]
  );

  const description = useMemo(
    () =>
      replaceCAPIVariables(deleteCardModal.description, {
        accountType: `<span>${getDeleteTypeByPaymentType(paymentType)}</span>`,
        accountDescription: `<span>${paymentMethod?.description || ""}</span>`
      }),
    [deleteCardModal, paymentType, paymentMethod]
  );

  const successText = useMemo(
    () =>
      replaceCAPIVariables(deleteCardModalSuccess.message, {
        type: getDeleteTypeByPaymentType(paymentType)
      }),
    [deleteCardModalSuccess, paymentType]
  );
  const confirmText = useMemo(
    () =>
      replaceCAPIVariables(deleteCardModal.confirmText, {
        accountType: `<span>${getDeleteTypeByPaymentType(paymentType)}</span>`,
        accountDescription: `<span>${paymentMethod?.description || ""}</span>`
      }),
    [deleteCardModal, paymentType, paymentMethod]
  );

  const onConfirmDelete = useCallback(
    () =>
      onConfirm(
        paymentType,
        accountId,
        paymentMethodId,
        dispatch,
        refetchAvailableMethods,
        setIsLoading,
        setHasError,
        successText,
        setIsAOFileOptionsModalOpen
      ),
    [paymentType, accountId, paymentMethodId, successText]
  );

  return (
    <ModalV2
      isOpen={isOpen}
      title={title}
      qaLabel="delete-card-modal"
      onClose={() => onClose(dispatch, setIsLoading)}
      onOverlayClick={() => onClose(dispatch, setIsLoading)}
      animation={isMobile ? "bottom" : "fade"}
      offsetLeft={isMobile ? 0 : 12}
      offsetRight={isMobile ? 0 : 12}
      fixedWidth={isMobile ? "100%" : "375px"}
      isFullWidth={isMobile}
      offsetTop={55}
      isFullHeight={false}
      hasRoundedCorners={!isMobile}
      isTitleCenter={false}
      useModalHeaderV3
    >
      {() => (
        <Container isMobile={isMobile}>
          <Description
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description)
            }}
          />
          <ActionsContainer>
            <Button
              isLoading={isLoading}
              variant="danger"
              qaLabel="delete-card-modal-confirm-btn"
              onPress={onConfirmDelete}
              isStretched
            >
              <MessageParagraph
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(confirmText)
                }}
              />
            </Button>
            <Button
              isDisabled={isLoading}
              variant="secondary"
              qaLabel="delete-card-modal-cancel-btn"
              onPress={() => onClose(dispatch, setIsLoading)}
              isStretched
            >
              {deleteCardModal.cancelText}
            </Button>
          </ActionsContainer>
          <ErrorModal
            hasError={hasError}
            isMobile={isMobile}
            onRetry={onConfirmDelete}
            paymentType={paymentType}
            setHasError={setHasError}
          />
        </Container>
      )}
    </ModalV2>
  );
};

export default React.memo(DeleteCardModal);
