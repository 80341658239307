import styled from "styled-components/native";
import { View } from "react-native";
import { useQaLabel } from "../../../hooks";

export const StyledViewContainer = styled(View).attrs<{
  qaLabel?: string;
}>((props) => ({
  ...useQaLabel(props.qaLabel || "StyledViewContainer")
}))<{
  position: string;
  height: string;
  width: string;
  qaLabel?: string;
}>`
  position: absolute;
  z-index: 1;
  width: ${({ width }) => width || `44px`};
  height: ${({ height }) => height || `48px`};
  ${({ position }) =>
    position === "end" ? `right: 0` : position === "bottom" && `top: 99%`};
`;
