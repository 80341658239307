import tvgConf from "@tvg/conf";
import requester from "../requester";
import getProtocol from "../protocolSetter";
import { serviceResponse } from "../types";

const payService: string = "service.pay";

const getToken = (
  accountNumber: string,
  amount: string,
  cancelUrl: string = "emptyUrl",
  returnUrl: string = "emptyUrl"
): Promise<serviceResponse> =>
  requester()({
    method: "POST",
    url: `${getProtocol()}${tvgConf().config(
      payService
    )}/users/${accountNumber}/token`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true,
    data: {
      cancelUrl,
      orderAmount: amount,
      returnUrl
    }
  });

const finishDeposit = (accountNumber: string, token: string) =>
  requester()({
    method: "POST",
    url: `${getProtocol()}${tvgConf().config(
      payService
    )}/users/${accountNumber}/deposits/${token}`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true
  });

const postWithdraw = ({
  accountNumber,
  amount
}: {
  accountNumber: string;
  amount: number;
}) =>
  requester()({
    method: "POST",
    url: `${getProtocol()}${tvgConf().config(
      payService
    )}/users/${accountNumber}/withdrawals`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true,
    data: {
      amount
    }
  });

const PAY_SERVICE = {
  getToken,
  finishDeposit,
  postWithdraw
};

export default PAY_SERVICE;
