// @flow
import { startCase } from "lodash";
import tvgConf from "@tvg/conf";

export default (data: mixed) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push(data);
  }
};

/* This method aims to remove the dash from international tracks */
export const processTrackName = (trackName: string): string =>
  trackName.replace(" - ", " ");

export const getSiteVersion = (product: string) => {
  if (product === "touch3") {
    return "mobile_web";
  }
  if (product === "ios2") {
    return "ios_native";
  }
  return tvgConf().gaConfig().siteVersion;
};

export const getProduct = (product: string) => {
  const productVersions = {
    touch3: "MEP-01",
    ios2: "iOS",
    fdrmobile: "FDR-01",
    fdrios: "FDR-01"
  };

  return productVersions[product]
    ? productVersions[product]
    : product.toUpperCase();
};

export const setFirstLetterCapital = (tab: string) => {
  if (!tab) {
    return undefined;
  }

  const strArray = tab.toLowerCase().split("_");
  return strArray
    .map((str: string) => str[0].toUpperCase() + str.slice(1))
    .join(" ");
};

export const convertMyBetsFilterActive = (
  selectedTab: string,
  selectedSettledTab: string
) => {
  const filterActive = selectedSettledTab.toLowerCase().replace("_", " ");

  const isPastDayFilters = ["YESTERDAY", "LAST_WEEK", "LAST_MONTH"].includes(
    selectedSettledTab
  );

  return selectedTab.toUpperCase() === "ACTIVE" ||
    selectedTab.toUpperCase() === "FUTURES"
    ? "None"
    : startCase(isPastDayFilters ? `Since ${filterActive}` : filterActive);
};
