import uwtService from "@tvg/api/uwt";
import { AccountNumber, UnaryFn } from "@tvg/wallet/src/types";

export const getWithdrawalLimits = async (
  accountNumber: AccountNumber,
  setWithdrawalLimit: UnaryFn<number, void>,
  capiMaxWithdrawAmount: number | string
) => {
  try {
    const { data } = await uwtService.getWithdrawalLimit(accountNumber);

    if (data) {
      const parseCapiMaxAmount =
        typeof capiMaxWithdrawAmount === "string"
          ? parseFloat(capiMaxWithdrawAmount)
          : capiMaxWithdrawAmount;

      const parsedAvailableBalance =
        typeof data.availableBalance === "string"
          ? parseFloat(data.availableBalance)
          : data.availableBalance;

      setWithdrawalLimit(
        parseFloat(
          // @ts-ignore
          parseCapiMaxAmount < parsedAvailableBalance
            ? parseCapiMaxAmount
            : parsedAvailableBalance
        )
      );
      return data;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};
