import React, { FC, memo } from "react";
import type { TvBrandProps, TvBrandColors } from "./types";
import { StyledSvg } from "./styled-components";

export const TvBrand: FC<TvBrandProps> = memo(
  ({ brandColor, height = 96, qaLabel }) => {
    const defaultHeight = 96;
    const defaultWidth = 296;
    const brandColors = {
      grey: "#6A6F73",
      blue: "#0070EB",
      white: "#FFFFFF"
    };
    const width = (height * defaultWidth) / defaultHeight;

    return (
      <StyledSvg
        width={width}
        height={height}
        viewBox="0 0 296 96"
        fill="none"
        data-qa-label={qaLabel}
      >
        <path
          d="M151.638 44.3767H132.255V34.2582H155.486C156.056 34.2582 156.423
        34.0246 156.626 33.4934L160.04 24.1398C160.207 23.7078 159.84 23.2086
        159.371 23.2086H121.378C120.876 23.2086 120.542 23.5414 120.542
        24.0406V72.3063C120.542 72.8055 120.876 73.1383 121.378
        73.1383H131.419C131.921 73.1383 132.255 72.8055 132.255
        72.3063V55.2983H147.821C148.391 55.2983 148.757 55.0647 148.96
        54.4983L152.307 45.3111C152.474 44.879 152.107 44.3767 151.638 44.3767Z"
          fill={brandColors[brandColor as TvBrandColors]}
        />
        <path
          d="M41.4806 71.2776C41.3326 70.3112 41.0076 69.7192 40.4605
        69.1496C35.6785 64.3976 31.347 58.7271 27.7621 52.5351C31.746
        50.7271 35.3567 49.5879 39.0671 48.8711C39.7397 48.7463 40.0615 48.3495
        40.113 47.7575C40.3382 42.5351 40.911 37.3607 41.7574 32.2599C41.8314
        31.6903 41.5321 31.1943 40.8113 31.2935C34.1371 32.0871 27.3405 33.8919
        20.8916 36.8647C19.5464 32.4583 18.5263 27.8791 17.9277 23.1975C26.1949
        19.7799 35.2054 17.6999 44.4702 17.2295C45.117 17.2039 45.4903 16.9063
        45.6641 16.3143C47.2313 11.2871 49.2233 6.26308 51.6143 1.33507C51.9135
        0.717469 51.64 0.0486707 50.8677 0.0230706C33.5385 -0.34813 16.2833
        3.76068 0.946107 12.3047C0.273535 12.6759 0 13.2711 0 14.0615C0.299278
        46.9671 17.7282 78.3336 46.6102 95.8152C47.0092 96.0616 47.4308 96.0616
        47.7043 95.8152C47.9521 95.5688 47.9521 95.2712 47.7784 94.8488C44.8918
        87.2456 42.726 79.2232 41.4806 71.2776Z"
          fill={brandColors[brandColor as TvBrandColors]}
        />
        <path
          d="M96.1583 12.3022C85.1526 6.18703 72.8049 2.10063 59.7557
        0.641424C59.1089 0.567824 58.6359 0.788623 58.3623 1.33582C55.9971
        5.94063 53.9053 10.8942 52.0871 16.1422C51.9134 16.7118 52.3124 17.2302
        52.8852 17.2814C62.2465 17.7774 71.0607 19.8575 79.2024 23.1983C76.5121
        44.4655 65.3101 63.1599 49.326 75.6879C48.8047 76.0847 48.6535 76.6031
        48.8047 77.2719C49.9246 82.2991 51.495 87.4959 53.4098 92.6224C53.635
        93.192 54.2818 93.3904 54.7806 93.0447C81.1976 75.0191 96.8824 45.2111
        97.1076 14.0654C97.1269 13.2686 96.8276 12.6734 96.1583 12.3022Z"
          fill={brandColors[brandColor as TvBrandColors]}
        />
        <path
          d="M182.931 23.2086H165.09C164.588 23.2086 164.253 23.5414 164.253
        24.0406V33.4262C164.253 33.9254 164.588 34.2582 165.09
        34.2582H182.931C190.899 34.2582 196.923 40.2486 196.923 48.1719C196.923
        56.0951 190.899 62.0855 182.931 62.0855H175.967V39.8166C175.967 39.3174
        175.632 38.9846 175.13 38.9846H173.389C171.146 38.9846 168.636 40.0502
        167.031 41.647C165.257 43.4102 164.253 45.8423 164.253
        48.9687V72.3031C164.253 72.8023 164.588 73.1351 165.09
        73.1351H182.931C197.625 73.1351 208.64 62.4183 208.64 48.1719C208.64
        33.9254 197.628 23.2086 182.931 23.2086Z"
          fill={brandColors[brandColor as TvBrandColors]}
        />
        <path
          d="M253.833 23.2152H264.09C264.09 23.2152 264.784 23.1881 265.066
        24.0273L274.563 58.3977L284.06 24.0273C284.306 23.1857 285.035 23.2128
        285.035 23.2128H295.274C295.274 23.2128 296.218 23.1291 295.953
        24.0273L281.812 72.2728C281.812 72.2728 281.546 73.1366 280.831
        73.1366H268.182C267.419 73.1366 267.192 72.2704 267.192 72.2704L253.175
        24.02C252.915 23.1045 253.833 23.2152 253.833 23.2152Z"
          fill={brandColors[brandColor as TvBrandColors]}
        />
        <path
          d="M235.076 73.1366H225.199C224.256 73.1366 224.354 72.2728 224.354
        72.2728V34.2561H212.271C211.365 34.2561 211.446 33.3874 211.446
        33.3874V24.0229C211.446 23.2009 212.271 23.2107 212.271
        23.2107H245.159C245.361 23.2107 246.039 23.1959 246.287 24.0229L249.044
        33.3874C249.142 33.7147 249.149 34.2931 248.216
        34.2561H235.909V72.2728C235.904 72.2728 236.039 73.1366 235.076 73.1366Z"
          fill={brandColors[brandColor as TvBrandColors]}
        />
      </StyledSvg>
    );
  }
);

export type { TvBrandProps, TvBrandColors };
