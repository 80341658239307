import { Platform } from "react-native";

type QaLabelActiveWeb = {
  dataSet: { "qa-label": string; "qa-active": string };
};
type QaLabelActiveNative = {
  "data-qa-label": string;
  "data-qa-active": string;
};

/**
 * Web and Native require different implementations of the data-qal-label and data-qa-active, this hook encapsulates that logic
 *
 * Usage:
 * const elementTestProps = useQaLabelActive('qa-label-goes-here', 'active-label-goes-here')
 * <Element {...elementTestProps} />
 */
export const useQaLabelActive = (
  qaLabel: string,
  activeLabel: string
): QaLabelActiveWeb | QaLabelActiveNative =>
  Platform.OS === "web"
    ? { dataSet: { "qa-label": qaLabel, "qa-active": activeLabel } }
    : { "data-qa-label": qaLabel, "data-qa-active": activeLabel };

export default useQaLabelActive;
