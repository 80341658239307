import styled from "styled-components/native";
import { Platform, View, Text, Pressable } from "react-native";
import { margin } from "styled-system";

const setDefaultCursor = Platform.OS === "web" && `cursor: default;`;

export const StyledPressableContainer = styled(Pressable)<{
  isHovered: boolean;
  isDisabled: boolean;
  isInvalid: boolean;
  isChecked: boolean;
  isPressed: boolean;
}>`
  ${margin};
  height: 24px;
  width: 24px;
  background-color: ${({ theme }) => theme.colors.white[900]};
  border-radius: 99px;
  border: 1px solid ${({ theme }) => theme.colors.blue[200]};
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.elevations.low};
  ${setDefaultCursor}
  ${({ isHovered, isDisabled, theme }) =>
    isHovered &&
    `
    border: 2px solid ${
      isDisabled ? theme.colors.blue[100] : theme.colors.blue_accent[500]
    };
    box-shadow: ${theme.elevations.medium};
  `}

  ${({ isInvalid, isDisabled, theme }) =>
    isInvalid &&
    `
    border: 2px solid ${
      isDisabled ? theme.colors.blue[100] : theme.colors.orange[600]
    };
  `}

  ${({ isChecked, theme }) =>
    isChecked &&
    `
    background-color: ${theme.colors.blue_accent[500]};
    border: 2px solid ${theme.colors.blue_accent[500]}; 
    box-shadow: none;
  `}

  ${({ isPressed, theme }) =>
    isPressed &&
    `
    background-color: ${theme.colors.blue_accent[500]};
    border: 2px solid ${theme.colors.blue_accent[500]};
    box-shadow: none;
  `}

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    background-color: ${theme.colors.blue["000"]};
    border: 1px solid ${theme.colors.blue[100]}; 
    box-shadow: none;
    ${setDefaultCursor}    
  `}
`;

export const StyledViewChecker = styled(View)<{
  isChecked: boolean;
  isDisabled: boolean;
}>`
  height: 8px;
  width: 8px;
  border-radius: 99px;
  background-color: ${({ theme }) => theme.colors.white[900]};
  ${({ isChecked, isDisabled, theme }) =>
    isChecked &&
    `
    background-color: ${
      isDisabled ? theme.colors.blue[200] : theme.colors.white[900]
    };
  `}

  ${({ isDisabled, isChecked, theme }) =>
    isDisabled &&
    `
    background-color: ${
      isChecked ? theme.colors.blue[200] : theme.colors.blue["000"]
    };
  `}
`;

export const StyledLabelContainer = styled(Text)`
  display: flex;
  justify-content: center;
  padding-left: ${({ theme }) => theme.space["space-3"]};
  padding-right: ${({ theme }) => theme.space["space-6"]};
`;

export const StyledLabel = styled(Text)<{ isDisabled: boolean }>`
  ${setDefaultCursor};
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.grey[900]};
  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    color: ${theme.colors.grey[600]};
  `}
`;

export const StyledViewContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space["space-4"]};
`;

export const StyledView = styled(View)`
  flex-shrink: 1;
`;
