import React, { FC } from "react";

export const Italy: FC = () => (
  <>
    <g clipPath="url(#clip0_20811_7054)">
      <g clipPath="url(#clip1_20811_7054)">
        <path d="M32 -1H0V20.3333H32V-1Z" fill="#1BB152" />
        <path d="M32 -1H10.6667V20.3333H32V-1Z" fill="#F7FBFF" />
        <path
          d="M32 -1H21.3333V20.3333H32V-1Z"
          fill="var(--fd-colors-core-red)"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_20811_7054">
        <rect width="32" height="20" rx="2" fill="#F7FBFF" />
      </clipPath>
      <clipPath id="clip1_20811_7054">
        <rect
          width="32"
          height="21.3333"
          fill="#F7FBFF"
          transform="translate(0 -1)"
        />
      </clipPath>
    </defs>
  </>
);
