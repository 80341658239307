import React, { useContext, forwardRef, useState, useEffect } from "react";
import { View } from "react-native";
import { useQaLabel } from "../../hooks/useQaLabel";
import { CheckboxGroup, GroupContext } from "./checkboxGroup";
import { CheckboxProps, CheckboxCompoundComponent } from "./types";
import { useEvents } from "../../hooks/useEvents";
import { Checker } from "./checker";
import {
  StyledPressableContainer,
  StyledTextLabel,
  StyledViewContainer,
  StyledPressableLabelContainer
} from "./styled-components";

export const Checkbox = forwardRef<View, CheckboxProps>(
  ({ value, label, isInvalid = false, qaLabel = value, ...rest }, ref) => {
    const [isChecked, setIsChecked] = useState(false);
    const { events, eventsHandlers } = useEvents({});
    const { onChange, isDisabled = false, values } = useContext(GroupContext);

    useEffect(() => {
      setIsChecked(values.has(value));
    }, [values]);

    const pressHandler = () => {
      if (isDisabled) return;
      const currentState = !isChecked;
      setIsChecked(currentState);
      if (currentState) {
        values?.add(value);
      } else {
        values?.delete(value);
      }
      onChange(Array.from(values));
    };

    return (
      <StyledViewContainer {...useQaLabel(qaLabel)} {...rest}>
        <StyledPressableContainer
          {...rest}
          {...useQaLabel(`${qaLabel}-input`)}
          {...events}
          {...eventsHandlers}
          onPress={pressHandler}
          ref={ref}
          accessibilityRole="checkbox"
          focusable
          isInvalid={isInvalid}
          isChecked={isChecked}
          isDisabled={isDisabled}
          accessibilityState={{ checked: isChecked }}
        >
          {isChecked && <Checker isDisabled={isDisabled} />}
        </StyledPressableContainer>
        {label !== "" && (
          <StyledPressableLabelContainer
            focusable={false}
            onPress={pressHandler}
            {...events}
            {...eventsHandlers}
          >
            <StyledTextLabel
              isDisabled={isDisabled}
              {...useQaLabel(`${qaLabel}-label`)}
            >
              {label}
            </StyledTextLabel>
          </StyledPressableLabelContainer>
        )}
      </StyledViewContainer>
    );
  }
) as CheckboxCompoundComponent<typeof CheckboxGroup>;

export { CheckboxProps };
Checkbox.Group = CheckboxGroup;
export default Checkbox;
