import { get, size } from "lodash";
import { Store } from "redux";

import { StoryblokComponentPropsFormatted } from "../../types/storyblok";
import { PromoOnboarding } from "../../types/promoOnboarding";
import {
  PromoMappedSteps,
  PromoOnboardingStep,
  PromoStepType
} from "../../types/promoSteps";
import { StoryblokPlaceName } from "../../types/promoOnboardingComponents";

const REDUCER_BASE_PATH = "promosOnboarding";

export const getStoryblokPromos = (store: Store): PromoOnboarding =>
  get(store, REDUCER_BASE_PATH, {} as PromoOnboarding);

export const getPromoOnboardingAllSteps = (store: Store): PromoMappedSteps =>
  get(store, `${REDUCER_BASE_PATH}.steps`, {});

export const getPromoOnboardingCompletedSteps = (store: Store): number =>
  get(store, `${REDUCER_BASE_PATH}.completedSteps`, 0);

export const getPromoOnboardingCurrentStepType = (
  store: Store
): PromoStepType => get(store, `${REDUCER_BASE_PATH}.currentStepType`);

export const getPromoOnboardingIsPromoExpired = (store: Store): boolean =>
  get(store, `${REDUCER_BASE_PATH}.isPromoExpired`, false);

export const getPromoOnboardingNumberOfSteps = (store: Store): number =>
  get(store, `${REDUCER_BASE_PATH}.numberSteps`, 0);

export const getPromoOnboardingPromoId = (store: Store): number | undefined =>
  get(store, `${REDUCER_BASE_PATH}.promotionId`);

export const getPromoOnboardingRebateLimit = (store: Store): number =>
  get(store, `${REDUCER_BASE_PATH}.rebateLimit`, 0);

export const getPromoOnboardingRemaingDays = (store: Store): number =>
  get(store, `${REDUCER_BASE_PATH}.remainingDays`, 0);

export const getPromoOnboardingPromoRootId = (
  store: Store
): number | undefined => get(store, `${REDUCER_BASE_PATH}.rootId`);

export const hasPromoOnboardingActivated = (store: Store) => {
  const steps = getPromoOnboardingAllSteps(store);
  return !!size(steps);
};

export const getStoryblokPromoByStep = (
  store: Store,
  step: PromoStepType
): PromoOnboardingStep | null =>
  get(store, `${REDUCER_BASE_PATH}.steps.${step}`, null);

export const getStoryblokCurrentStep = (
  store: Store,
  step?: PromoStepType
): PromoOnboardingStep | null => {
  const currentStep = step || getPromoOnboardingCurrentStepType(store);
  return get(store, `${REDUCER_BASE_PATH}.steps.${currentStep}`, null);
};

export const getStoryblokPromoByPlace = (
  store: Store,
  place: StoryblokPlaceName,
  step?: PromoStepType
): StoryblokComponentPropsFormatted | null => {
  const currentStep = step || getPromoOnboardingCurrentStepType(store);
  return get(
    store,
    `${REDUCER_BASE_PATH}.steps.${currentStep}.places.${place}`,
    null
  );
};

export const getPromoOnboardingPollerTime = (store: Store): number =>
  get(store, `${REDUCER_BASE_PATH}.pollerTime`, 60);

export const getPromosOnboardingToggle = (store: Store): boolean =>
  get(store, `capi.featureToggles.promosOnboarding`, false);

export const getPromoOnboardingCustomMessage = (store: Store): boolean =>
  get(store, `capi.messages.myBetsPromoOnboardingCustomMessage`, "");

export const getPromosOnboardingWalletSteps = (store: Store): string[] => {
  const steps = get(
    store,
    "capi.messages.promoOnboardingWalletSteps",
    "$~amountToShow~ in wagering credit will be refunded if you don't win."
  );
  return steps.split("\r\n") || [];
};

export const getPromosOnboardingWalletSuccessHeaders = (
  store: Store
): string[] => {
  const headers = get(
    store,
    "capi.messages.promoOnboardingWalletSuccessHeaders",
    ""
  );
  return headers.split("\r\n") || [];
};

export const hasPromoOnboardingComponent = (
  store: Store,
  place: StoryblokPlaceName,
  step?: PromoStepType
): boolean =>
  !!get(getStoryblokPromoByPlace(store, place, step), "component", false);
