import styled, { css } from "styled-components/native";
import { Text } from "react-native";
import {
  space,
  compose,
  typography,
  system,
  color
} from "../../theming/styledSystem";
import { MtpProps } from "./types";

const transformText = system({
  whiteSpace: {
    property: "whiteSpace"
  }
});
const styledComponentUtils = compose(space, color, typography, transformText);

const baseTextStyle = css<Pick<MtpProps, "isCollapsed">>`
  font-size: ${({ theme, isCollapsed }) =>
    theme.fontSizes[isCollapsed ? "s" : "3xl"]};
  line-height: ${({ theme, isCollapsed }) =>
    theme.lineHeights[isCollapsed ? "s" : "3xl"]};
  ${typography};
`;

export const RaceOff = styled(Text)<
  Pick<MtpProps, "isCollapsed" | "hasFullText">
>`
  color: ${({ theme }) => theme.colorTokens.lhnHeader.content.positive};
  font-weight: ${({ theme, isCollapsed, hasFullText }) =>
    theme.fontWeights[isCollapsed && !hasFullText ? "normal" : "bold"]};
  ${baseTextStyle};
  ${styledComponentUtils};
`;

export const Resulted = styled(Text)<
  Pick<MtpProps, "isCollapsed" | "hasFullText"> & { defaultColor: string }
>`
  color: ${({ defaultColor }) => defaultColor};
  font-weight: ${({ theme, isCollapsed, hasFullText }) =>
    theme.fontWeights[isCollapsed && !hasFullText ? "normal" : "bold"]};
  ${baseTextStyle};
  ${styledComponentUtils};
`;

export const RaceTime = styled(Text)<
  Pick<MtpProps, "isCollapsed" | "hasFullText"> & { defaultColor: string }
>`
  color: ${({ defaultColor }) => defaultColor};
  ${({ theme, isCollapsed, hasFullText }) => {
    if (isCollapsed) {
      return css`
        ${!hasFullText &&
        css`
          width: min-content;
          text-align: center;
        `};
        font-size: ${theme.fontSizes.s};
      `;
    }

    return css`
      font-size: ${theme.fontSizes["3xl"]};
    `;
  }}
  font-weight: ${({ theme, hasFullText }) =>
    hasFullText ? theme.fontWeights.bold : theme.fontWeights.normal};
  line-height: ${({ theme, isCollapsed }) =>
    theme.lineHeights[isCollapsed ? "s" : "3xl"]};
  ${styledComponentUtils};
`;

export const MinutesText = styled(Text)<
  Pick<MtpProps, "mtp" | "isCollapsed"> & { defaultColor: string }
>`
  color: ${({ theme, mtp, defaultColor }) =>
    mtp > 5 ? defaultColor : theme.colorTokens.lhnHeader.content.negative};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  ${baseTextStyle};
  ${styledComponentUtils};
`;

export const TextDescription = styled(Text)<
  Pick<MtpProps, "mtp"> & { defaultColor: string }
>`
  color: ${({ theme, mtp, defaultColor }) =>
    mtp > 5 ? defaultColor : theme.colorTokens.lhnHeader.content.negative};
  font-family: ${({ theme }) => theme.fonts.condensedRegular};
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
`;
