import { State, ReducerActions, ReducerActionTypes } from "../types";

export const INITIAL_STATE: State = {
  isOpen: false,
  triggerLayout: {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }
};

export const ACTIONS: ReducerActionTypes = {
  OPEN_POPPER: "OPEN_POPPER",
  CLOSE_POPPER: "CLOSE_POPPER"
};

const popperReducer = (
  state: State = INITIAL_STATE,
  action: ReducerActions
) => {
  switch (action.type) {
    case ACTIONS.OPEN_POPPER:
      return {
        ...state,
        isOpen: true,
        triggerLayout: action.payload
      };
    case ACTIONS.CLOSE_POPPER:
      return {
        ...state,
        isOpen: false
      };
    default:
      return state;
  }
};

export default popperReducer;
