import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const Container = styled.div`
  background-color: ${
    buildColor(
      "white",
      "100"
    ) /* TODO: DS equivalent buildColor("white", "900") */
  };
`;
