import { DESKTOP_THEME, MOBILE_THEME } from "../themes";
import { DeviceTheme } from "./types";

export const DEFAULT_THEME_KEY: "mobile" = "mobile";

export const THEME_MAP: DeviceTheme = {
  desktop: DESKTOP_THEME,
  mobile: MOBILE_THEME,
  tablet: MOBILE_THEME
};
