import { findLast, get } from "lodash";
import {
  PromoMappedSteps,
  PromoOnboardingStep,
  PromoStepType,
  PromoStepWagerStatus
} from "../types/promoSteps";
import { checkOnboardingPromoAvailable } from "./checkOnboardingPromoAvailable";

const WAGER_FINISHED_STATUS = [
  PromoStepWagerStatus.WIN,
  PromoStepWagerStatus.LOST
];

const checkSpecialStepConditions = (formattedSteps: PromoMappedSteps) => {
  const wagerStep = get(formattedSteps, PromoStepType.WAGER);
  const wagerStatus = get(formattedSteps, `${PromoStepType.WAGER}.wagerStatus`);
  const depositDate = get(
    formattedSteps,
    `${PromoStepType.DEPOSIT}.depositDate`
  );

  if (!checkOnboardingPromoAvailable(formattedSteps)) {
    return true;
  }

  return wagerStep
    ? WAGER_FINISHED_STATUS.includes(wagerStatus)
    : !!depositDate;
};

export const getCurrentStepType = (
  currentStep: number,
  steps: Array<PromoOnboardingStep> = [],
  formattedSteps: PromoMappedSteps = {}
): PromoStepType => {
  let currentStepType = checkSpecialStepConditions(formattedSteps)
    ? PromoStepType.PAYOUT
    : get(steps, `[${currentStep}].stepType`, PromoStepType.UNKNOWN);

  // Need to get the last step active when don't recognize the Step
  if (!currentStepType || currentStepType === PromoStepType.UNKNOWN) {
    const lastStep = findLast(steps, (step) => !!step);
    currentStepType = get(lastStep, "stepType", PromoStepType.UNKNOWN);
  }

  return currentStepType;
};
