import React from "react";
import Svg, { Path } from "react-native-svg";
import { tvgColorPalette as colors } from "../../../theming/themes/common";

export const Error = () => (
  <Svg width="60" height="60" viewBox="0 0 60 60" fill="none">
    <Path
      d="M58.095 30.0108C58.095 45.5272 45.5164 58.1058 30 58.1058C14.4836 58.1058 1.90503 45.5272 1.90503 30.0108C1.90503 14.4944 14.4836 1.91583 30 1.91583C45.5164 1.91583 58.095 14.4944 58.095 30.0108Z"
      fill={colors.red[100]}
    />
    <Path
      d="M18.3752 18.375L30.0002 30M30.0002 30L41.6252 41.625M30.0002 30L18.3752 41.625M30.0002 30L41.6252 18.375M58.095 30.0108C58.095 45.5272 45.5164 58.1058 30 58.1058C14.4836 58.1058 1.90503 45.5272 1.90503 30.0108C1.90503 14.4944 14.4836 1.91583 30 1.91583C45.5164 1.91583 58.095 14.4944 58.095 30.0108Z"
      stroke={colors.red[600]}
      strokeWidth="3"
    />
  </Svg>
);
