import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Filters = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M7.875 19.875C9.53185 19.875 10.875 18.5319 10.875 16.875C10.875
      15.2181 9.53185 13.875 7.875 13.875C6.21815 13.875 4.87613 15.2181
      4.87613 16.875C4.87613 18.5319 6.21815 19.875 7.875 19.875Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M14.625 10.125C16.2819 10.125 17.625 8.78185 17.625 7.125C17.625
      5.46815 16.2819 4.125 14.625 4.125C12.9681 4.125 11.625 5.46815 11.625
      7.125C11.625 8.78185 12.9681 10.125 14.625 10.125Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M4.87613 16.875H1.5M4.87613 16.875C4.87613 18.5319 6.21815 19.875 7.875
      19.875C9.53185 19.875 10.875 18.5319 10.875 16.875M4.87613 16.875C4.87613
      15.2181 6.21815 13.875 7.875 13.875C9.53185 13.875 10.875 15.2181 10.875
      16.875M10.875 16.875H22.5M11.625 7.125H1.5M11.625 7.125C11.625 8.78185
      12.9681 10.125 14.625 10.125C16.2819 10.125 17.625 8.78185 17.625
      7.125M11.625 7.125C11.625 5.46815 12.9681 4.125 14.625 4.125C16.2819
      4.125 17.625 5.46815 17.625 7.125M17.625 7.125H22.5"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);

export default Filters;
