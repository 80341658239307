module.exports = {
  domain: {
    desktop: "4njbets-qa.us.betfair.com",
    mobile: "4njbets-qa.us.betfair.com",
    talentPicks: "4njbets-qa.us.betfair.com/talent-picks/",
    equibase: "equibase-store-qa.us.betfair.com"
  },
  service_host: "service-qa.us.betfair.com",
  recaptchaID: "6Lf-4hUqAAAAAGupa6W70kpZVsaFd7yaxz5pHD8r",
  recaptchaIdNative: "6Le9B1YqAAAAAJEWJZsQittPtHDcjz6xLNORgHyI"
};
