import React, { PropsWithChildren } from "react";
import Base from "./Base";
import Details from "./Details";
import Summary from "./Summary";
import { Container, RaceCellLink } from "./styled-components";
import { Props } from "./types";
import { buildQALabel } from "./utils";
import { RACE_CELL } from "./constants";

export const RaceCell = ({
  children,
  qaLabel = "",
  to,
  onClick,
  className
}: PropsWithChildren<
  Pick<Props, "qaLabel" | "to" | "onClick" | "className">
>) => {
  if (to) {
    return (
      <RaceCellLink to={to} onClick={onClick} className={className}>
        <Container data-qa-label={buildQALabel([qaLabel, RACE_CELL])}>
          {children}
        </Container>
      </RaceCellLink>
    );
  }
  return (
    <Container
      data-qa-label={buildQALabel([qaLabel, RACE_CELL])}
      onClick={onClick}
      className={className}
    >
      {children}
    </Container>
  );
};

RaceCell.Base = ({ to, ...props }: Props) => (
  <RaceCell qaLabel={props.qaLabel} to={to}>
    <Base {...props} />
  </RaceCell>
);

RaceCell.Summary = ({ onClick, to, className, ...props }: Props) => (
  <RaceCell
    onClick={onClick}
    qaLabel={props.qaLabel}
    to={to}
    className={className}
  >
    <Summary {...props} />
  </RaceCell>
);

RaceCell.Details = ({ onClick, className, to, ...props }: Props) => (
  <RaceCell
    onClick={onClick}
    className={className}
    to={to}
    qaLabel={props.qaLabel}
  >
    <Details {...props} />
  </RaceCell>
);

export default RaceCell;
