// @flow
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";
import PaymentSelector from "../../_molecule/PaymentSelector";

export const Container = styled.div`
  background-color: ${buildColor("white", "100")};
  overflow: hidden;
`;

export const ScrollableContainer = styled.div`
  max-height: ${({ isMobile, maxHeight = 288 }) =>
    isMobile ? `65vh` : `calc(${maxHeight}px - 70px)`};
  overflow-y: scroll;
`;

export const ButtonContainer = styled.div`
  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: 0px -1px 11px ${buildColor("blue", "100")};
      border-top: 1px solid ${buildColor("blue", "100")};
    `}
  background-color: ${buildColor("white", "100")};
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin-top: 1px;
  padding: 16px 12px;
  position: relative;
`;

export const Button = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: ${fontMedium};
  font-size: 14px;
  line-height: 18px;
  ${({ isModal }) =>
    isModal
      ? css`
          background-color: ${buildColor("white", "100")};
          border: 1px solid ${buildColor("blue", "100")};
          box-shadow: 0 1px 2px ${buildColor("blue", "000")};
          border-radius: 4px;
          color: ${buildColor("grey", "900")};
          padding: 0 12px;
          height: 44px;
          justify-content: center;

          &:active {
            background-color: ${buildColor("blue_accent", "000")};
          }
        `
      : css`
          color: ${buildColor("blue_accent", "500")};

          svg {
            margin-right: 8px;
          }
        `}
`;

export const PaymentSelectorWrapper = styled(PaymentSelector)`
  ${({ hasDescription }) =>
    !hasDescription &&
    css`
      align-items: center;

      span {
        margin-bottom: 0;
      }
    `}
`;
