import { DefaultIconsColor } from "./types";

export const defaultIconColor: Partial<DefaultIconsColor> = {
  info: {
    background: "blue_accent.200",
    line: "blue_accent.600"
  },
  exclamation: {
    background: "yellow.200",
    line: "yellow.700"
  },
  support: {
    background: "blue_accent.200",
    line: "blue_accent.600"
  },
  error: {
    background: "red.100",
    line: "red.600"
  },
  success: {
    background: "green.100",
    line: "green.600"
  },
  warning: {
    background: "orange.200",
    line: "orange.700"
  },
  changes: {
    background: "orange.400",
    line: "grey.900"
  },
  optedIn: {
    background: "green.100",
    line: "green.600"
  },
  promo: {
    background: "yellow.200",
    line: "yellow.800"
  },
  trash: {
    background: "red.100",
    line: "red.600"
  }
};
