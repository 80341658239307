import axios from "axios";
import { isUndefined } from "lodash";
import type { ApolloClient } from "@apollo/client";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import type { UnaryFn } from "@tvg/ts-types/Functional";
import type { TabItem } from "@tvg/ts-types/Common";
import type { Entry } from "@tvg/ts-types/ListItem";
import { isMobile, isXSell } from "@tvg/sh-utils/mobileUtils";

export const pluralize = (
  count: number,
  word: string,
  showCount: boolean = true,
  suffix: string = "s",
  countPrefix: string = ""
) => {
  const hasOnlyOne = count === 1;
  const wordPluralized = !hasOnlyOne ? `${word}${suffix}` : word;
  return showCount
    ? `${countPrefix}${count} ${wordPluralized}`
    : wordPluralized;
};

export const buildTabs = (
  options: Array<Entry>,
  selected: string,
  onSwitch: UnaryFn<string, void>,
  isLoading: boolean,
  className: string = ""
): TabItem[] =>
  options.map((option) => ({
    title: option.value,
    isTitleUppercase: false,
    isActive: selected === option.key,
    isDisabled: isLoading,
    onClick: () => onSwitch(option.key),
    className,
    qaLabel: `tab-item-${option.key}`
  }));

export const downloadBlob = (blob: Blob, fileName: string) => {
  const blobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", fileName);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }

  if (document.body) document.body.appendChild(tempLink);
  tempLink.click();

  // Fixes "webkit blob resource error 1"
  setTimeout(() => {
    if (document.body) document.body.removeChild(tempLink);
    if (window.URL) {
      window.URL.revokeObjectURL(blobURL);
    } else {
      window.webkitURL.revokeObjectURL(blobURL);
    }
  }, 200);
};

export const downloadUrl = (url: string, filename: string, fileType: string) =>
  isMobile(tvgConf().product)
    ? mediator.ios.dispatch({
        type: "DOWNLOAD_FILE",
        payload: {
          url,
          fileType,
          filename
        }
      })
    : axios(url, {
        responseType: "blob",
        withCredentials: true
      }).then((response) => {
        downloadBlob(response.data, filename);
      });

export const replaceAll = (str: string, find: string, replace: string) => {
  const escapedFind = find.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
  return str.replace(new RegExp(escapedFind, "g"), replace);
};

export const capitalizeFirstLetter = (string: string = ""): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const validateRcnLoad = () => {
  if (typeof window !== "undefined") {
    // @ts-ignore
    window.hasLoadedRobertStream = !!document.getElementById("rcnScript");
  }
};

// TODO: Update this later when we have tvg5 context from BE

export const isTvg5 = () => tvgConf().product === "tvg5";

export const recoverQueriesFromBackground = (
  clients: Array<ApolloClient<unknown>>
) => {
  let hidden;
  let visibilityChange = "";
  if (!isUndefined(document.hidden)) {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
    // @ts-ignore
  } else if (!isUndefined(document.msHidden)) {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
    // @ts-ignore
  } else if (!isUndefined(document.webkitHidden)) {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }
  if (!isUndefined(document.addEventListener) && !isUndefined(hidden)) {
    document.addEventListener(
      visibilityChange,
      () => {
        if (document.visibilityState === "visible") {
          (clients || []).forEach((client) => {
            if (client && client.reFetchObservableQueries) {
              client.reFetchObservableQueries(false);
            }
          });
        }
      },
      false
    );
  }
};

export const getNumberSuffix = (number: number) => {
  const suffix =
    {
      "1": "st",
      "2": "nd",
      "3": "rd"
    }[number.toString()] || "th";

  return suffix;
};

// URP is now enabled by default except for Xsell
// Cookie changed because we still need ATs to be able to perform tasks within non-URP
export const isURP = !(
  isXSell() ||
  (typeof document !== "undefined" &&
    document.cookie.includes("disableURP=true"))
);

export const isFDR = () => tvgConf().brand === "fdr";

export const getPortByBrand = () => (isFDR() ? "FDR-Generic" : "PORT-Generic");

export default pluralize;
