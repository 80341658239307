import React from "react";
import TrackDetail from "../components/TrackDetail";
import { Container } from "./styled-components";
import { Props } from "../types";
import IconGroup from "../components/IconGroup";
import { buildQALabel } from "../utils";
import { BASE, CONTAINER, RACE_CELL } from "../constants";

const Base = ({ race, qaLabel = "", ...props }: Props) => (
  <Container
    data-qa-label={buildQALabel([qaLabel, RACE_CELL, BASE, CONTAINER])}
  >
    <TrackDetail
      raceNumber={race.raceNumber}
      qaLabel={buildQALabel([qaLabel, RACE_CELL, BASE])}
    />
    <IconGroup {...props} qaLabel={buildQALabel([qaLabel, RACE_CELL, BASE])} />
  </Container>
);

export default Base;
