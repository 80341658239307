import currencyFormatter from "currency-formatter";
import numeral from "numeral";

const formatCurrency = (
  value: number,
  code: string = "USD",
  precision: number = 2
): string => currencyFormatter.format(value, { code, precision });

export const formatCurrencyShortened = (
  value: number,
  code: string = "USD",
  precision: number = 0
): string => currencyFormatter.format(value, { code, precision });

export const formatNumber = (value: number | string): string =>
  numeral(value).format("0.00");

export const formatPurse = (value: number): string => {
  if (value >= 1000) {
    return value % 1000 !== 0
      ? numeral(value).format("$0.0a")
      : numeral(value).format("$0a");
  }
  return numeral(value).format("$0");
};

export const formatPurseWithoutCurrency = (value: number): string => {
  if (value >= 1000) {
    return value % 1000 !== 0
      ? numeral(value).format("0.0a")
      : numeral(value).format("0a");
  }
  return numeral(value).format("0.00a");
};

export const formatCurrencyToNumber = (value: string): number =>
  +value.replace(/\$|,/g, "");

export default formatCurrency;
