import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type State = {
  isModalOpen: boolean;
  isWireTransferModalOpen: boolean;
};

export const initialState: State = {
  isModalOpen: false,
  isWireTransferModalOpen: false
};

const withdrawFundsModalSlice = createSlice({
  name: "withdrawFundsModal",
  initialState,
  reducers: {
    openWithdrawFundsModal(state) {
      state.isModalOpen = true;
    },
    closeWithdrawFundsModal(state) {
      state.isModalOpen = false;
    },
    setIsWireTransferFundsModalOpen(state, action: PayloadAction<boolean>) {
      state.isWireTransferModalOpen = action.payload;
    }
  }
});

export const {
  openWithdrawFundsModal,
  closeWithdrawFundsModal,
  setIsWireTransferFundsModalOpen
} = withdrawFundsModalSlice.actions;

export default withdrawFundsModalSlice.reducer;
