import styled from "styled-components/native";
import { get } from "lodash";
import { Link } from "../../../link";
import { StyledLinkProps } from "./types";
import { AlertInlineVariants } from "../../types";
import { getPressableColor } from "../../utils/getPressableColor";

export const StyledLink = styled(Link)<StyledLinkProps>`
  color: ${({ type = "floating", variant = "success", events, theme }) =>
    get(
      theme.colors,
      getPressableColor(type, variant as AlertInlineVariants, events)
    )};
  text-decoration: underline;
  text-decoration-color: ${({ type = "floating", variant, events, theme }) =>
    get(
      theme.colors,
      getPressableColor(type, variant as AlertInlineVariants, events)
    )};
  line-height: 16px;
`;
