import { combineReducers } from "redux";

import walletReducer, {
  initialState as walletInitialState,
  State as walletState
} from "./slices/walletSlice";

import paymentMethodSelectorReducer, {
  initialState as paymentMethodSelectorInitialState,
  State as paymentMethodSelectorState
} from "./slices/paymentMethodSelectorSlice";

import otherPaymentMethodsReducer, {
  initialState as otherPaymentMethodsInitialState,
  State as otherPaymentMethodsState
} from "./slices/otherPaymentMethodsSlice";

import createPMModalReducer, {
  initialState as createPMModalInitialState,
  State as createPMModalState
} from "./slices/createPMModalSlice";

import infoPanelReducer, {
  initialState as infoPanelInitialState,
  State as infoPanelState
} from "./slices/infoPanelSlice";

import depositFundsModalReducer, {
  initialState as depositFundsModalInitialState,
  State as depositFundsModalState
} from "./slices/depositFundsModalSlice";

import pendingWithdrawModalReducer, {
  initialState as pendingWithdrawInitialState,
  State as pendingWithdrawState
} from "./slices/pendingWithdrawSlice";

import quickDepositModalReducer, {
  initialState as quickDepositModalInitialState,
  State as quickDepositModalState
} from "./slices/quickDepositModalSlice";

import withdrawFundsModalReducer, {
  initialState as withdrawFundsModalInitialState,
  State as withdrawFundsModalState
} from "./slices/withdrawFundsModalSlice";

import cardDeclinedModalReducer, {
  initialState as cardDeclinedModalInitialState,
  State as cardDeclinedModalState
} from "./slices/cardDeclinedModalSlice";

import quickWithdrawReducer, {
  initialState as quickWithdrawInitialState,
  State as quickWithdrawState
} from "./slices/quickWithdrawSlice";

import deleteCardModalReducer, {
  initialState as DeleteCardModalInitialState,
  State as DeleteCardModalState
} from "./slices/deleteCardModalSlice";

export type PawsState = {
  wallet: walletState;
  paymentMethodSelector: paymentMethodSelectorState;
  otherPaymentMethods: otherPaymentMethodsState;
  createPMModal: createPMModalState;
  infoPanel: infoPanelState;
  depositFundsModal: depositFundsModalState;
  withdrawFundsModal: withdrawFundsModalState;
  cardDeclinedModal: cardDeclinedModalState;
  quickWithdraw: quickWithdrawState;
  deleteCardModal: DeleteCardModalState;
  quickDeposit: quickDepositModalState;
  pendingWithdraw: pendingWithdrawState;
};

export const initialPawsState: PawsState = {
  wallet: walletInitialState,
  paymentMethodSelector: paymentMethodSelectorInitialState,
  otherPaymentMethods: otherPaymentMethodsInitialState,
  createPMModal: createPMModalInitialState,
  infoPanel: infoPanelInitialState,
  depositFundsModal: depositFundsModalInitialState,
  withdrawFundsModal: withdrawFundsModalInitialState,
  cardDeclinedModal: cardDeclinedModalInitialState,
  quickWithdraw: quickWithdrawInitialState,
  deleteCardModal: DeleteCardModalInitialState,
  quickDeposit: quickDepositModalInitialState,
  pendingWithdraw: pendingWithdrawInitialState
};

const pawsReducer = combineReducers({
  wallet: walletReducer,
  paymentMethodSelector: paymentMethodSelectorReducer,
  otherPaymentMethods: otherPaymentMethodsReducer,
  createPMModal: createPMModalReducer,
  infoPanel: infoPanelReducer,
  depositFundsModal: depositFundsModalReducer,
  withdrawFundsModal: withdrawFundsModalReducer,
  cardDeclinedModal: cardDeclinedModalReducer,
  quickWithdraw: quickWithdrawReducer,
  deleteCardModal: deleteCardModalReducer,
  quickDeposit: quickDepositModalReducer,
  pendingWithdraw: pendingWithdrawModalReducer
});

export default pawsReducer;
