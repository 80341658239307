import {
  PaymentTypeOption,
  UnaryFn,
  PaymentType as StringPaymentType
} from "@tvg/wallet/src/types";
import { PaymentType } from "@tvg/ts-types/Payment";
import { ClickParams } from "../types";
import { isAccountUnderMaintenance, sortByLastUsed } from "../../../utils";
import { renderListItems } from "./renderer";

export const clickFunction = (
  account: PaymentTypeOption,
  onClick: UnaryFn<ClickParams, void>
) =>
  account.depositsAvailable || account.withdrawalsAvailable
    ? () =>
        onClick({
          id: account.id,
          paymentType: account.paymentType,
          title: account.title,
          description: account.description,
          depositsAvailable: account.depositsAvailable,
          withdrawalsAvailable: account.withdrawalsAvailable,
          minLimit: account.minLimit,
          maxLimit: account.maxLimit
        })
    : undefined;

export const onSort = (a: PaymentTypeOption, b: PaymentTypeOption) =>
  sortByLastUsed(a.lastUsedDateTime!, b.lastUsedDateTime!);

export const onFilter = (isMZMToggleOn: boolean) => (item: PaymentTypeOption) =>
  isMZMToggleOn ? item : item.paymentType !== PaymentType.MAZOOMA;

export const onMap =
  (
    paymentTypesUnderMaintenance: StringPaymentType[],
    onClick: UnaryFn<ClickParams, void>
  ) =>
  (account: PaymentTypeOption) => {
    const isDisabled =
      !account.depositsAvailable && !account.withdrawalsAvailable;

    const isUnderMaintenance = isAccountUnderMaintenance(
      paymentTypesUnderMaintenance,
      account
    );

    return renderListItems(
      account,
      clickFunction(account, onClick),
      isDisabled,
      isUnderMaintenance
    );
  };
