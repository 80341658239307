import React from "react";
import { Path } from "react-native-svg";

import { IconPathProps } from "../types";

const Tick = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M3.75 12L9 17.25L20.25 6"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default Tick;
