import { State, ReducerActions, ReducerActionTypes } from "./types";

export const INITIAL_STATE: State = {
  activePage: undefined,
  totalPages: undefined,
  nextPage: undefined,
  pageHeight: 0,
  isFlexible: false,
  isAnimating: false,
  isFirstRender: true
};

export const ACTIONS: ReducerActionTypes = {
  INIT: "INIT",
  SET_STATE: "SET_STATE",
  START_ANIMATION: "START_ANIMATION",
  START_CHANGE_PAGE: "START_CHANGE_PAGE",
  END_CHANGE_PAGE: "END_CHANGE_PAGE"
};

export const modalPagesReducer = (
  state: State = INITIAL_STATE,
  action: ReducerActions
) => {
  switch (action.type) {
    case ACTIONS.INIT:
      return {
        ...state,
        totalPages: action.payload.totalPages
      };
    case ACTIONS.SET_STATE:
      return {
        ...state,
        ...action.payload
      };
    case ACTIONS.START_ANIMATION:
      return {
        ...state,
        isAnimating: true
      };
    case ACTIONS.START_CHANGE_PAGE:
      return {
        ...state,
        nextPage: action.payload.nextPage
      };
    case ACTIONS.END_CHANGE_PAGE:
      return {
        ...state,
        isAnimating: false,
        activePage: state.nextPage
      };
    default:
      return state;
  }
};
