import { useEffect, useState } from "react";
import { useWindowDimensions, Platform } from "react-native";

interface ScreenInfo {
  width: number;
  height: number;
}

export const useScreenSize = (): ScreenInfo => {
  const [screenInfo, setScreenInfo] = useState<ScreenInfo>(
    useWindowDimensions()
  );

  useEffect(() => {
    const isNative = Platform.OS !== "web";

    if (!isNative && typeof window !== undefined) {
      setScreenInfo({ height: window.innerHeight, width: window.innerWidth });
    }
  }, []);

  return screenInfo;
};
