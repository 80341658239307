import React, { memo } from "react";
import { Container, TrackName } from "./styled-components";
import Text from "../Text";
import { useColorTokens } from "../../../../../src";
import { CONTAINER, RACE, TRACK, TRACK_DETAIL } from "../../constants";
import { buildQALabel } from "../../utils";
import { TrackDetailProps } from "../../types";

const TrackDetail = ({
  raceNumber,
  trackName,
  qaLabel = ""
}: TrackDetailProps) => {
  const color = useColorTokens("content.default");
  const fontWeight = 700;
  const fontFamily = "medium";

  return (
    <Container data-qa-label={buildQALabel([qaLabel, TRACK_DETAIL, CONTAINER])}>
      {trackName ? (
        <TrackName
          qaLabel={buildQALabel([qaLabel, TRACK_DETAIL, TRACK])}
          color={color}
          fontFamily={fontFamily}
          fontWeight={fontWeight}
        >
          {trackName}
        </TrackName>
      ) : null}
      <Text
        qaLabel={buildQALabel([qaLabel, TRACK_DETAIL, RACE])}
        color={color}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
      >
        <>R{raceNumber}</>
      </Text>
    </Container>
  );
};

export default memo(TrackDetail);
