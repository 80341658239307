import { compose, variant } from "../../../theming/styledSystem";

export const stylesStepBarUncompleted = compose(
  variant({
    prop: "variant",
    variants: {
      dark: {
        backgroundColor: "blue.400"
      },
      light: {
        backgroundColor: "blue.100"
      }
    }
  })
);
