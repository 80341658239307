// @flow

import { isUndefined, isDifferent } from "./utils";
import type {
  MediatorInterface,
  EventIdentifier,
  EventSubscriberFn,
  EventHandler,
  UnsubscribeHandler
} from "./Types.js.flow";

const createEventHandler = (val: *): EventHandler => ({
  subscribers: [],
  lastValue: val
});

class Mediator implements MediatorInterface {
  handlers: { [key: EventIdentifier]: EventHandler };

  constructor(): void {
    this.handlers = {};
  }

  subscribe(
    type: EventIdentifier,
    handler: EventSubscriberFn
  ): UnsubscribeHandler {
    this.handlers[type] = this.handlers[type] || createEventHandler();

    this.handlers[type].subscribers = [
      ...this.handlers[type].subscribers,
      handler
    ];

    return () => this.unsubscribe(type, handler);
  }

  subscribeTimes(
    times: number,
    type: EventIdentifier,
    handler: EventSubscriberFn
  ): UnsubscribeHandler {
    let count = 0;
    const fnTimes = (current: number, max: number, val: *): mixed => {
      count += 1;

      if (count === times) {
        this.unsubscribe(type, fnTimes);
      }

      return handler(val);
    };

    this.subscribe(type, fnTimes);

    return () => this.unsubscribe(type, fnTimes);
  }

  subscribeWithPast(
    type: EventIdentifier,
    handler: EventSubscriberFn
  ): UnsubscribeHandler {
    const unsubscribeHandler = this.subscribe(type, handler);
    const eventHandler = this.handlers[type];

    if (!isUndefined(eventHandler.lastValue)) {
      handler(eventHandler.lastValue);
    }

    return unsubscribeHandler;
  }

  unsubscribe(type: EventIdentifier, handler: EventSubscriberFn): Mediator {
    const evtHandler = this.handlers[type];

    if (evtHandler) {
      evtHandler.subscribers = evtHandler.subscribers.filter(
        isDifferent(handler)
      );
    }

    return this;
  }

  dispatch(type: EventIdentifier, value: *): Mediator {
    const handler = this.handlers[type];

    if (handler && handler.subscribers.length) {
      handler.subscribers.map((fn) => fn(value));
      this.handlers[type].lastValue = value;
    } else {
      this.handlers[type] = createEventHandler(value);
    }

    return this;
  }

  query(type: EventIdentifier): * {
    const handler = this.handlers[type];
    return handler && handler.lastValue ? handler.lastValue : undefined;
  }
}

export default Mediator;
