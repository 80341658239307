import styled from "styled-components/native";
import { rgba } from "polished";
import { View } from "react-native";

import {
  color,
  background,
  space,
  borderRadius,
  width,
  compose,
  variant
} from "../../theming/styledSystem";
import { TooltipDescriptiveWrapperProps } from "./types";

const OFFSET_SPACE: string = "space-3";

const styles = compose(
  color,
  background,
  space,
  borderRadius,
  width,
  variant({
    prop: "placement",
    variants: {
      top: { marginBottom: OFFSET_SPACE },
      bottom: { marginTop: OFFSET_SPACE },
      left: { marginRight: OFFSET_SPACE },
      right: { marginLeft: OFFSET_SPACE }
    }
  })
);

export const Wrapper = styled(View)<
  TooltipDescriptiveWrapperProps & { isSmall: boolean }
>`
  ${styles};
  box-shadow: 0 4px 4px ${({ theme }) => rgba(theme.colors.black[900], 0.14)};
  margin: ${({ theme }) => theme.space["space-1"]};
  ${({ isSmall }) => isSmall && "margin-right: 48px"};
`;
