import React from "react";
import { IconPathProps } from "../../types";

export const Badge = ({ lineColor }: IconPathProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.92624 1.66197C4.78969 1.42195 4.48443 1.33806 4.2444 1.47461L3.15791
    2.0927C2.91789 2.22924 2.834 2.53451 2.97055 2.77453L6.7395 9.3997C7.31637
    8.9217 7.95938 8.52073 8.65277 8.21257L4.92624 1.66197ZM13.906
    7.72655C13.2941 7.58186 12.656 7.50529 12 7.50529C11.8092 7.50529 11.6199
    7.51177 11.4323 7.52451L14.7674 1.66197C14.9039 1.42195 15.2092 1.33806
    15.4492 1.47461L16.5357 2.0927C16.7757 2.22924 16.8596 2.53451 16.7231
    2.77453L13.906 7.72655ZM17.2576 9.39732C16.6805 8.91959 16.0374 8.51892
    15.3438 8.21107L19.0695 1.66197C19.2061 1.42195 19.5113 1.33806 19.7513
    1.47461L20.8378 2.0927C21.0779 2.22924 21.1617 2.53451 21.0252
    2.77453L17.2576 9.39732ZM5.24999 15.7553C5.24999 19.4832 8.27206 22.5053 12
    22.5053C12.233 22.5053 12.4632 22.4935 12.6901 22.4704C16.0939 22.1248 18.75
    19.2502 18.75 15.7553C18.75 12.0274 15.7279 9.00529 12 9.00529C8.27206
    9.00529 5.24999 12.0274 5.24999 15.7553ZM16.5 15.7553C16.5 18.2406 14.4853
    20.2553 12 20.2553C9.5147 20.2553 7.49999 18.2406 7.49999 15.7553C7.49999
    13.27 9.5147 11.2553 12 11.2553C14.4853 11.2553 16.5 13.27 16.5
    15.7553ZM10.875 13.2553C10.875 12.9791 11.0988 12.7553 11.375
    12.7553H12.625C12.9011 12.7553 13.125 12.9791 13.125 13.2553V18.2553C13.125
    18.5314 12.9011 18.7553 12.625 18.7553H11.375C11.0988 18.7553 10.875 18.5314
    10.875 18.2553V13.2553ZM9.24076 1.67456C9.1047 1.43352 8.7987 1.34889
    8.55812 1.48576L7.47458 2.10216C7.23397 2.23904 7.15037 2.54538 7.28809
    2.78551L9.85452 7.26043L11.1476 5.0523L9.24076 1.67456Z"
    fill={lineColor}
  />
);
