import styled, { css } from "styled-components";

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const RowWrapper = styled.tr``;

export const ColumnWrapper = styled.td<{
  addPadding?: boolean;
}>`
  ${({ addPadding }) =>
    addPadding &&
    css`
      padding: 0 12px;
    `}
`;
