import React from "react";
import { buildColor } from "@tvg/design-system/src";
import { usePaypalDeposit } from "@tvg/sh-lib-paws/hooks/usePayPalDeposit";
import { PaymentType } from "@tvg/ts-types/Payment";
import { Container, LoadingContainer } from "./styled-components";
import SpinningWheel from "../common/SpinningWheel";
import { Props } from "./types";

const PaypalDepositBtn = ({
  value,
  maxLimit,
  minLimit,
  isModalOpen,
  isLoading,
  setShowAmountWarning,
  setErrorMessage,
  pawsErrorMessages,
  errorMessageInitialState,
  setShowSuccessMessage,
  setIsLoading,
  dispatch,
  fromCreation,
  refetchAvailableMethods
}: Props) => {
  const isButtonActive = usePaypalDeposit(
    PaymentType.PAYPAL,
    value,
    maxLimit,
    minLimit,
    isModalOpen,
    isLoading,
    setShowAmountWarning,
    setErrorMessage,
    pawsErrorMessages,
    errorMessageInitialState,
    setShowSuccessMessage,
    setIsLoading,
    dispatch,
    fromCreation,
    refetchAvailableMethods
  );

  return (
    <Container
      data-qa-label={
        isButtonActive && !isLoading
          ? `submit-button-enabled`
          : `submit-button-disabled`
      }
      id="paypal-button-container"
      isActive={isButtonActive && !isLoading}
    >
      {isLoading && (
        <LoadingContainer>
          <SpinningWheel
            stroke={buildColor("yellow", "400")}
            fill={buildColor("yellow", "800")}
          />
        </LoadingContainer>
      )}
    </Container>
  );
};

export default PaypalDepositBtn;
