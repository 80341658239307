// @flow

import React, { PureComponent, type Node } from "react";
import { type TitleEnum } from "./types";
import { buildTitle } from "./styled-components";

type Props = {
  /**
   * Selects the desired tag: 'h1', 'h2', 'h3', 'h4', 'h5' or 'h6'
   */
  tag: TitleEnum,
  /**
   * any text to be present in Title
   */
  children: Node,
  /**
   * To indicate another font-size
   */
  fontSize: number,
  /**
   * To indicate another color of text
   */
  color: string,
  /**
   * Enables the uppercase type of text
   */
  uppercase: boolean,
  /**
   * To make text ellipsis inside the wrapper
   */
  ellipsis: boolean,
  /**
   * Enable title font family secondary
   */
  fontFamilySecondary: boolean,
  /**
   * ClassName to add new style rules based on this _atom
   */

  className: string,

  qaLabel: string
};

export default class Title extends PureComponent<Props> {
  static defaultProps = {
    children: null,
    tag: "h1",
    ellipsis: false,
    uppercase: true,
    fontSize: -1,
    color: "inherit",
    fontFamilySecondary: false,
    className: "",
    qaLabel: ""
  };

  render() {
    let Tlt = buildTitle({
      tag: this.props.tag,
      fontSize: this.props.fontSize,
      color: this.props.color,
      uppercase: this.props.uppercase,
      ellipsis: this.props.ellipsis,
      fontFamilySecondary: this.props.fontFamilySecondary,
      qaLabel: this.props.qaLabel
    });

    if (this.props.tag !== "h1") {
      Tlt = Tlt.withComponent(this.props.tag);
    }

    return <Tlt className={this.props.className}>{this.props.children}</Tlt>;
  }
}

export { buildTitle };
