import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import InputV2 from "@tvg/atomic-ui/_molecule/InputV2";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { debounce } from "lodash";
import {
  getPawsContent,
  getWireTransferWithdrawModalOpen
} from "@tvg/sh-lib-paws/redux/selectors";

import { fontNormal } from "@tvg/atomic-ui/_static/Typography";
import {
  AlertInline,
  buildColor,
  TooltipDescriptive
} from "@tvg/design-system";
import {
  WireTransferWithdrawContent,
  WireTransferWithdrawProps
} from "./types";
import TermsAndConditions from "../common/TermsAndConditions";
import CallToAction from "../common/TermsAndConditions/callToAction";
import {
  checkingAccountsNumberSchema,
  nameOnAccountSchema,
  routingNumberSchema
} from "./methods/wireTransferSchemaValidations";
import {
  handleClickOutsideWireTransferForm,
  handleWireTransferFormValidation,
  validateAndSubmitWireTransferForm,
  handleWireTransferFieldBlur,
  handleOnCloseWireTransferFormModal,
  validateWireRoutingNumber
} from "./methods/handlers";
import {
  WireTransferModalWrapper,
  WireTransferContainer,
  WireTransferButtonSubmit,
  TermsAndConditionsCTA,
  TermsAndConditionsContainer,
  RoutingNumberWrapper,
  SupportIcon
} from "./styled-components";
import { isMobile } from "../../../utils";

const WireTransferFundsModal = ({
  setIsLoading,
  isLoading,
  subtitle
}: WireTransferWithdrawProps) => {
  const [isFormFilled, setFormFilled] = useState(false);
  const [isTermsOpen, setTermsOpen] = useState(false);
  const [isRoutingNumberValid, setIsRoutingNumberValid] = useState(false);
  const [routingNumberLabelWidth, setRoutingNumberLabelWidth] = useState(0);
  const formRef = useRef<FormHandles>(null);
  const formInnerContainerRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const isWireTransferModalOpen = useSelector(getWireTransferWithdrawModalOpen);
  const {
    wireTransferWithdrawContent: content
  }: { wireTransferWithdrawContent: WireTransferWithdrawContent } =
    useSelector(getPawsContent);

  useEffect(() => {
    if (isWireTransferModalOpen) {
      handleWireTransferFormValidation(formRef, content, setFormFilled);
    }
  }, [isWireTransferModalOpen]);

  useEffect(() => {
    if (!isWireTransferModalOpen) {
      setFormFilled(false);
      setIsLoading(false);
      setTermsOpen(false);
    }
  }, [isWireTransferModalOpen]);

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        !formInnerContainerRef.current ||
        formInnerContainerRef.current.contains(event.target as Node)
      ) {
        return;
      }
      handleClickOutsideWireTransferForm(event);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [formInnerContainerRef, handleClickOutsideWireTransferForm]);

  const routingNumberLabelRef = useCallback((node) => {
    if (node !== null) {
      setRoutingNumberLabelWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const termsAndConditionsContent = useMemo(
    () =>
      content.termsAndConditions.content.reduce(
        (markup: string, p: string) => `${markup}<p>${p}</p>`,
        "" as string
      ),
    [isWireTransferModalOpen]
  );

  const debouncedValidateWireRoutingNumber = useCallback(
    // eslint-disable-next-line
    debounce((value, formRef, content, setIsRoutingNumberValid) => {
      validateWireRoutingNumber(
        value,
        formRef,
        content,
        setIsRoutingNumberValid
      );
    }, 300),
    []
  );

  const closeModalProps = {
    onOverlayClick: () => handleOnCloseWireTransferFormModal(dispatch),
    onClose: () => handleOnCloseWireTransferFormModal(dispatch)
  };

  const termsAndConditionsModalProps = {
    title: content.termsAndConditions.title,
    onBack: () => setTermsOpen(false),
    subtitle: "",
    isTitleCenter: false
  };

  const modalProps = useMemo(
    () => ({
      isOpen: isWireTransferModalOpen,
      title: "Wire Transfer",
      subtitle,
      qaLabel: isTermsOpen
        ? "wiretransfer-terms-modal"
        : "wiretransfer-withdraw-modal",
      offsetLeft: 0,
      offsetRight: 0,
      offsetTop: 55,
      isTitleCapitalized: false,
      isContentTransparent: true,
      useModalHeaderV3: true,
      isTitleCenter: true,
      isFullWidth: true,
      allowClickPropagation: true,
      ...(isTermsOpen && { ...termsAndConditionsModalProps }),
      ...closeModalProps
    }),
    [isWireTransferModalOpen, isTermsOpen]
  );

  const extraModalProps = !isMobile
    ? {
        isFullHeight: false,
        animation: "fade",
        fixedWidth: "399px",
        hasRoundedCorners: true,
        isFluidWidth: true
      }
    : {
        animation: "bottom",
        hasRoundedCorners: false
      };

  return (
    <ModalV2 {...modalProps} {...extraModalProps}>
      {() => (
        <WireTransferModalWrapper data-qa-label="wiretransfer-withdraw-modal-wrapper">
          <AlertInline
            title={content.form.alert.message}
            message=""
            variant={content.form.alert.type}
            qaLabel="wiretransfer-alert"
          />
          <WireTransferContainer
            isMobile={isMobile}
            data-qa-label="wiretransfer-creation-container"
          >
            <div ref={formInnerContainerRef}>
              <Form
                data-qa-label="wiretransfer-form"
                onSubmit={() =>
                  validateAndSubmitWireTransferForm(
                    content,
                    formRef,
                    setIsLoading,
                    dispatch
                  )
                }
                ref={formRef}
              >
                <RoutingNumberWrapper>
                  <TooltipDescriptive
                    header=""
                    body={content.form.routingNumber.tooltip}
                    qaLabel="wiretransfer-routing-number-tooltip"
                  >
                    <SupportIcon
                      name="support"
                      size="s"
                      marginLeft={routingNumberLabelWidth + 8}
                    />
                  </TooltipDescriptive>

                  <InputV2
                    name="routingNumber"
                    qaLabel="wiretransfer-routing-number"
                    label={
                      <div ref={routingNumberLabelRef}>
                        {content.form.routingNumber.label}
                      </div>
                    }
                    placeholder={content.form.routingNumber.placeholder}
                    customHandleOnBlur={handleWireTransferFieldBlur(
                      "routingNumber",
                      routingNumberSchema(
                        content.form.routingNumber.validations
                      ),
                      formRef
                    )}
                    customHandleChange={(value: string) => {
                      handleWireTransferFormValidation(
                        formRef,
                        content,
                        setFormFilled
                      );
                      debouncedValidateWireRoutingNumber(
                        value,
                        formRef,
                        content,
                        setIsRoutingNumberValid
                      );
                    }}
                    marginBottom={16}
                    maxlength={9}
                    isOnlyNumber
                    autoFocus
                    clearErrorOnFocus
                    formRef
                  />
                </RoutingNumberWrapper>
                <InputV2
                  name="checkingAccountNumber"
                  qaLabel="wiretransfer-checking-account-number"
                  label={content.form.checkingAccountNumber.label}
                  placeholder={content.form.checkingAccountNumber.placeholder}
                  customHandleOnBlur={handleWireTransferFieldBlur(
                    "checkingAccountNumber",
                    checkingAccountsNumberSchema(
                      content.form.checkingAccountNumber.validations
                    ),
                    formRef
                  )}
                  customHandleChange={() =>
                    handleWireTransferFormValidation(
                      formRef,
                      content,
                      setFormFilled
                    )
                  }
                  marginBottom={16}
                  maxlength={17}
                  isOnlyNumber
                  clearErrorOnFocus
                  formRef
                />

                <InputV2
                  name="nameOnAccount"
                  qaLabel="wiretransfer-name-on-account"
                  label={content.form.nameOnAccount.label}
                  placeholder={content.form.nameOnAccount.placeholder}
                  maxlength={35}
                  schema={routingNumberSchema(
                    content.form.nameOnAccount.validations
                  )}
                  customHandleOnBlur={handleWireTransferFieldBlur(
                    "nameOnAccount",
                    nameOnAccountSchema(content.form.nameOnAccount.validations),
                    formRef
                  )}
                  customHandleChange={() =>
                    handleWireTransferFormValidation(
                      formRef,
                      content,
                      setFormFilled
                    )
                  }
                  clearErrorOnFocus
                  formRef
                />

                <WireTransferButtonSubmit
                  size="bigger"
                  hasRoundedCorners
                  isStretched
                  isUppercase={false}
                  hasShadow
                  isDisabled={
                    !isFormFilled || !isRoutingNumberValid || isLoading
                  }
                  isLoading={isLoading}
                  fontFamily={fontNormal}
                  qaLabel="wiretransfer-submit-btn"
                  loadingBorderColor={buildColor("blue_accent", "400")}
                  loadingBackgroundColor={buildColor("blue_accent", "500")}
                  removeOpacity={isLoading}
                >
                  {content.form.button.text}
                </WireTransferButtonSubmit>
              </Form>
            </div>
          </WireTransferContainer>
          <TermsAndConditionsCTA
            data-qa-label="wiretransfer-terms-text"
            isMobile={isMobile}
          >
            <CallToAction
              text={{
                modalTitle: content.termsAndConditions.title,
                bottomText: content.termsAndConditions.cta,
                clickableText: content.termsAndConditions.ctaClickable
              }}
              setTermsOpen={setTermsOpen}
              qaLabel="wiretransfer-modal-terms-clickable-text"
            />
          </TermsAndConditionsCTA>
          {isTermsOpen && (
            <TermsAndConditionsContainer data-qa-label="wiretransfer-terms-modal-container">
              <TermsAndConditions
                termsAndConditionsContent={termsAndConditionsContent}
                qaLabel="wiretransfer-modal-terms-text"
              />
            </TermsAndConditionsContainer>
          )}
        </WireTransferModalWrapper>
      )}
    </ModalV2>
  );
};

export default WireTransferFundsModal;
