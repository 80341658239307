import React from "react";
import { Path } from "react-native-svg";
import { IconPathProps } from "../types";

const ArrowBottom = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M12 2.25V20.1797M4.5 12.75L12 20.25L19.5 12.75"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default ArrowBottom;
