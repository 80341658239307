import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Popout = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M4.125 17.625V6.375C4.125 5.13236 5.13236 4.125 6.375
      4.125H17.625C18.8676 4.125 19.875 5.13236 19.875 6.375V17.625C19.875
      18.8676 18.8676 19.875 17.625 19.875H6.375C5.13236 19.875 4.125 18.8676
      4.125 17.625Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M14.25 4.125H6.375C5.13236 4.125 4.125 5.13236 4.125 6.375V17.625C4.125
      18.8676 5.13236 19.875 6.375 19.875H17.625C18.8676 19.875 19.875 18.8676
      19.875 17.625V9.75M13.5 10.5L22.4971 1.50293M16.5 1.125L22.8751
      1.12491L22.875 7.5"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
export default Popout;
