import { merge } from "lodash";
import { PromoOnboardingFormatted } from "../../types/promoOnboarding";
import { Actions as PromoOnboardingActions } from "../actions/types";

export interface PromoOnboardingState
  extends Partial<PromoOnboardingFormatted> {
  pollerTime: number;
}

export const initialState = {
  pollerTime: 60
} as PromoOnboardingState;

export default function promosOnboardingReducer(
  state: PromoOnboardingState = initialState,
  action: PromoOnboardingActions
) {
  switch (action.type) {
    case "SET_STORYBLOK_PROMOS": {
      return {
        ...action.payload,
        pollerTime: state.pollerTime
      };
    }
    case "SET_PARTIAL_STORYBLOK_PROMOS": {
      return {
        ...merge(state, action.payload)
      };
    }
    case "RESET_STORYBLOK_PROMOS": {
      const { pollerTime } = state;
      return {
        ...initialState,
        pollerTime
      };
    }
    case "SET_PROMO_ONBOARDING_POLLER_TIME": {
      return {
        ...state,
        pollerTime: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
