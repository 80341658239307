import styled, { keyframes } from "styled-components";
import { space } from "styled-system";

const PathAnimationOne = keyframes`
  0% {
    stroke-dashoffset: 67.69px;
  }

  41.3793% {
    stroke-dashoffset: 68.69px;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }

  100% {
    stroke-dashoffset: -69.69px;
  }
`;

const PathAnimationTwo = keyframes`
  0% {
    stroke-dashoffset: 68.69px;
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }

  58.6207% {
    stroke-dashoffset: -68.69px;
  }

  100% {
    stroke-dashoffset: -68.69px;
  }
`;

export const Svg = styled.svg`
  ${space}
`;

export const AnimatedCircleOne = styled.ellipse`
  animation: ${PathAnimationOne} 2.9s linear infinite both;
`;

export const AnimatedCircleTwo = styled.ellipse`
  animation: ${PathAnimationTwo} 2.9s linear infinite both;
`;

export const Circle = styled.ellipse``;
