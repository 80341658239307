import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { Store } from "redux";
import {
  MasterCardIcon,
  VisaIcon,
  OnlineBankingIcons
} from "@tvg/atomic-ui/_static/Icons/paws";
import tvgConf from "@tvg/conf";
import { Tag } from "@tvg/design-system";
import Icon from "@tvg/atomic-ui/_static/Icons";
import parseCapiMessage from "@tvg/utils/capiUtils";
import * as allIcons from "@tvg/atomic-ui/_static/Icons/icons";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import Flag from "../Flag";
import type { Props } from "./types";
import { getPawsOnlyIcons, tagMapper } from "../../utils";
import {
  Item,
  Content,
  Title,
  TitleContainer,
  TitleText,
  IconsContainer,
  Description,
  Arrow,
  FlagContainer,
  ArrowIcon
} from "./styled-components";
import { TagMapper } from "../../types";

const ListItem = ({
  title,
  iconName,
  iconColor = buildColor("blue_accent", "500"),
  description,
  isUnderMaintenance,
  onClick,
  displayBadge,
  isDisabled,
  isExpired,
  isCapitalize,
  textColor,
  showOnlineBankingIcons = false,
  tags = [],
  qaLabel
}: Props) => {
  const pawsIsNewTag = useSelector<Store, boolean>((store) =>
    get(store, "capi.featureToggles.pawsIsNewTag", false)
  );

  const underMaintenanceMessage = useSelector((store) =>
    parseCapiMessage(
      store,
      "capi.messages.pawsContent.paymentMethodUnderMaintenance",
      {
        message: "Currently under maintenance, please try it later"
      }
    )
  );

  return (
    <Item
      {...(!isDisabled && { onClick })}
      data-qa-label={qaLabel || `list-item-${title}`}
      isDisabled={!!isDisabled}
    >
      <Content>
        <Title>
          {getPawsOnlyIcons(iconName) || (
            <Icon
              icon={allIcons[iconName]}
              size={16}
              color={iconColor}
              qaLabel={`${iconName}-icon`}
            />
          )}
          <TitleContainer>
            <TitleText
              data-qa-label={`${title
                .replace(/\s+/g, "-")
                .toLowerCase()}-title`}
              isCapitalize={!!isCapitalize}
              textColor={textColor}
            >
              {isCapitalize ? title.toLowerCase() : title}
            </TitleText>
            {!!tags.length && (
              <FlagContainer>
                {tvgConf().device === "mobile" &&
                  tags
                    .filter(
                      (tag) =>
                        tag.type !== "new" ||
                        (tag.type === "new" && pawsIsNewTag)
                    )
                    .map((tag, i) => (
                      <Tag
                        key={`list-item-flag-${tag.type}-${tag.text}-${i + 1}`}
                        variant={
                          tagMapper[tag.color as keyof TagMapper] || "info"
                        }
                        qaLabel={`list-item-flag-${tag.type}`}
                        label={tag.text}
                        size="s"
                      />
                    ))}
              </FlagContainer>
            )}
          </TitleContainer>
        </Title>
        {(isUnderMaintenance || description) && (
          <Description
            data-qa-label={`${title
              .replace(/\s+/g, "-")
              .toLowerCase()}-description`}
          >
            {isUnderMaintenance ? underMaintenanceMessage.message : description}
          </Description>
        )}
        {displayBadge && (
          <IconsContainer>
            {!showOnlineBankingIcons && (
              <>
                <MasterCardIcon label="master-card-icon" />
                <VisaIcon label="visa-card-icon" />
              </>
            )}
            {showOnlineBankingIcons && (
              <OnlineBankingIcons label="online-banking-icons" />
            )}
          </IconsContainer>
        )}
      </Content>

      <Arrow
        data-qa-label={`${title.replace(/\s+/g, "-").toLowerCase()}-arrow`}
      >
        <ArrowIcon
          visible={onClick && !isDisabled}
          icon={allIcons.arrowRight}
          size={16}
          color={buildColor("blue", "300")}
        />
      </Arrow>

      {isExpired && (
        <Arrow>
          <Flag text="EXPIRED" />
        </Arrow>
      )}
    </Item>
  );
};
ListItem.defaultProps = {
  title: "",
  description: "",
  displayBadge: false,
  isDisabled: false,
  isCapitalize: false,
  isExpired: false
};

export default React.memo(ListItem);
