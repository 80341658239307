import React from "react";
import { getTrackName } from "@urp/lib-racetracks/src/utils/races";
import { Props } from "../types";
import RaceDetail from "../components/RaceDetail";
import { Container, Row } from "./styled-components";
import TrackDetail from "../components/TrackDetail";
import { Flag } from "../../Flag";
import { Countries } from "../../Flag/types";
import IconGroup from "../components/IconGroup";
import Text from "../components/Text";
import { buildQALabel, getFlagLabel } from "../utils";
import { CONTAINER, DETAILS, FLAG, RACE_CELL } from "../constants";

const Details = ({
  race,
  hasPromosAvailable,
  hasTalentPicksAvailable,
  isOptedIn,
  numberActiveBets,
  qaLabel = ""
}: Props) => (
  <Container
    data-qa-label={buildQALabel([qaLabel, RACE_CELL, DETAILS, CONTAINER])}
  >
    <RaceDetail
      video={race.video}
      mtp={race.mtp}
      postTime={race.postTime}
      status={race.status}
    />
    <TrackDetail
      raceNumber={race.raceNumber}
      trackName={getTrackName(race.track.name)}
      qaLabel={buildQALabel([qaLabel, RACE_CELL, DETAILS])}
    />
    <Row>
      <Row>
        {!!race?.track.location?.country && (
          <Flag
            country={race.track.location.country as Countries}
            size="s"
            qaLabel={buildQALabel([qaLabel, RACE_CELL, DETAILS, FLAG])}
          />
        )}
        <Text qaLabel={buildQALabel([qaLabel, RACE_CELL, DETAILS, FLAG])}>
          {getFlagLabel(race.track.location)}
        </Text>
      </Row>
      <IconGroup
        hasPromosAvailable={hasPromosAvailable}
        hasTalentPicksAvailable={hasTalentPicksAvailable}
        isOptedIn={isOptedIn}
        numberActiveBets={numberActiveBets}
        qaLabel={buildQALabel([qaLabel, RACE_CELL, DETAILS])}
      />
    </Row>
  </Container>
);

export default Details;
