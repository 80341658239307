import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const UserPlus = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M7.27033 13.4973H16.7296C17.7443 13.4973 18.6334 14.1765 18.9004
      15.1555L20.0812 19.4867C20.3413 20.4409 19.623 21.3813 18.634
      21.3813H5.36589C4.37688 21.3813 3.65857 20.4409 3.91871 19.4867L5.09956
      15.1555C5.36646 14.1765 6.25561 13.4973 7.27033 13.4973Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M15.7521 6.37019C15.7521 8.44241 14.0723 10.1223 12 10.1223C9.92781
      10.1223 8.24794 8.44241 8.24794 6.37019C8.24794 4.29797 9.92781 2.6181 12
      2.6181C14.0723 2.6181 15.7521 4.29797 15.7521 6.37019Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M12.75 21.3813H5.36594C4.37693 21.3813 3.65862 20.4409 3.91876
      19.4867L5.09961 15.1555C5.36651 14.1765 6.25567 13.4973 7.27038
      13.4973H12.75M19.4999 13.5V18M19.4999 18V22.5M19.4999 18H23.9999M19.4999
      18H14.9999M15.7521 6.37019C15.7521 8.44241 14.0723 10.1223 12
      10.1223C9.92781 10.1223 8.24794 8.44241 8.24794 6.37019C8.24794 4.29797
      9.92781 2.6181 12 2.6181C14.0723 2.6181 15.7521 4.29797 15.7521 6.37019Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);

export default UserPlus;
