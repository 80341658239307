import mediator from "@tvg/mediator";

export const promoOnboardingCTAClickGTM = (
  linkLabel: string,
  promoId?: number,
  promoName?: string
) =>
  mediator.base.dispatch({
    type: "PROMOS_ONBOARDING:CTA_CLICK",
    payload: {
      link: linkLabel,
      promoId,
      promoName
    }
  });
