import formatCurrency from "@tvg/formatter/currency";
import { ToggleStateEnum } from "@tvg/sh-lib-preferences/utils/types";
import { createElement } from "react";
import {
  PaypalIcon,
  PayNearMe,
  Bank,
  Card,
  CardPlus,
  Check
} from "@tvg/atomic-ui/_static/Icons/paws";
import { Dispatch, Store } from "redux";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { batch } from "react-redux";
import { PayloadAction } from "@reduxjs/toolkit";
import tvgConf from "@tvg/conf";
import {
  setAccountsOnFileMethods,
  setIsFirstDepositDone,
  setPaymentMethodsUnderMaintenance
} from "@tvg/sh-lib-paws/redux/slices/walletSlice";
import { openInfoPanelModal } from "@tvg/sh-lib-paws/redux/slices/infoPanelSlice";
import {
  closeCreatePMModal,
  openCreatePMModal,
  setCreateMainMethods
} from "@tvg/sh-lib-paws/redux/slices/createPMModalSlice";
import {
  DepositsPayload,
  openDepositFundsModal,
  closeDepositFundsModal
} from "@tvg/sh-lib-paws/redux/slices/depositFundsModalSlice";
import {
  SelectedPaymentMethodPayload,
  setSelectedPaymentMethod
} from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import { closeCardDeclinedModal } from "@tvg/sh-lib-paws/redux/slices/cardDeclinedModalSlice";
import { getMazoomaToggle } from "@tvg/sh-lib-paws/redux/toggles";
import { IconNames } from "@tvg/design-system/src/_static/icons/types";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit/dist/createAction";
import uwtService from "@tvg/api/uwt";
import mediator from "@tvg/mediator";
import { buildColor, Icon } from "@tvg/design-system";
import { IconSizes } from "@tvg/design-system/src/components/icon/types";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import { sortBy, get } from "lodash";

import { goToWallet } from "@tvg/utils/routeRedirectUtils";
import { toggleQuickDepositModal } from "@tvg/sh-lib-paws/redux/slices/quickDepositModalSlice";
import type {
  PaymentType,
  PawsOnlyPayments,
  PaymentTypeOption,
  PaymentTypeItem,
  AvailablePaymentMethodsInfos,
  FetchAvailablePaymentMethodsArgs,
  NullaryFn,
  ButtonAction,
  CreatePMToggles,
  FundsAmountInputChangingFrom,
  RedirectOnFeeFreeArgs,
  PaymentTypeTag,
  PawsContent
} from "./types";
import { UnaryFn, DesktopNavigationProps, TagMapper } from "./types";

type MultiSourceIcons = IconNames | "paypal";

const REDIRECT_ON_FEE_DELAY = 300;

export const sortByLastUsed = (
  lastUsedDateTimeA: string | null,
  lastUsedDateTimeB: string | null
) => {
  const dateA = new Date(lastUsedDateTimeA || 0);
  const dateB = new Date(lastUsedDateTimeB || 0);
  return dateB.getTime() - dateA.getTime();
};

export const getPaymentMethodIconName = (
  paymentType: PaymentType
): MultiSourceIcons => {
  switch (paymentType) {
    case "CASH":
      return "horse";
    case "CHK":
      return "email";
    case "WTRN":
      return "arrowRight";
    case "OPM":
      return "add";
    case "ACH":
      return "eCheck";
    case "IBC":
    case "MZM":
      return "bank";
    case "CC":
    case "CCP":
    case "DEB":
    case "MPAK":
      return "card";
    case "PPAL":
    case "PAY":
      return "paypal";
    case "SLP":
      return "cardPlus";
    case "PNM":
    case "VIWD":
    case "CFee":
    case "Coll":
    case "CNTW":
    case "RPPS":
    default:
      return "plus";
  }
};

export const getDMATab = (
  paymentType: PaymentType,
  navigationType: string
): string => {
  switch (paymentType) {
    case "ACH":
      return "ACH";
    case "CC":
    case "CCP":
    case "DEB":
      if (navigationType === "withdraw") {
        // we need to return DEB DMA withdraw won't handle CC type because it isn't allowed to withdraw from that type
        // @TODO this edge case should be removed and this logic should be fixed when we enter a full PAWS setup
        return "DEB";
      }
      return "CC";
    case "IBC":
      return "IBC";
    case "MPAK":
      return "MPAK";
    case "PNM":
      return "PNM";
    case "PAY":
    case "PPAL":
      return "PAY";
    case "SLP":
      return "SLN";
    case "CASH":
    case "CHK":
    case "VIWD":
    case "CFee":
    case "Coll":
    case "CNTW":
    case "RPPS":
    case "WTRN":
    case "OPM":
    default:
      return "OTHER";
  }
};

export const getPawsOnlyIcons = (
  paymentType: PawsOnlyPayments,
  color?: string,
  size: IconSizes = "s"
) => {
  switch (paymentType) {
    case "paypal":
      return createElement(PaypalIcon, { color });
    case "paynearme":
      return createElement(PayNearMe, { color });
    case "bank":
      return createElement(Bank, { color });
    case "card":
      return createElement(Card, { color });
    case "cardPlus":
      return createElement(CardPlus, { color });
    case "email":
      return createElement(Check, { color });
    case "trash":
      return createElement(Icon, { name: "trash", size });
    case "eCheck":
      return createElement(Icon, {
        name: "eCheck",
        size,
        lineColor: color || buildColor("blue_accent", "500")
      });
    default:
      return null;
  }
};

export const filterMainMethods = ({
  availablePaymentMethodsInfos,
  paymentTypes
}: {
  availablePaymentMethodsInfos: AvailablePaymentMethodsInfos;
  paymentTypes: PaymentTypeItem[];
}): PaymentTypeItem[] =>
  paymentTypes.filter((method) => {
    const methodInfos = availablePaymentMethodsInfos[method.paymentType];

    return (
      method.mainList &&
      methodInfos &&
      (!methodInfos.limit ||
        (method?.paymentTypeOptions?.length || 0) < methodInfos.limit ||
        !isMobile)
    );
  });

export const filterOnFileMethods = ({
  availablePaymentMethodsInfos,
  paymentTypes
}: {
  availablePaymentMethodsInfos: AvailablePaymentMethodsInfos;
  paymentTypes: PaymentTypeItem[];
}): PaymentTypeOption[] =>
  paymentTypes.reduce(
    (acc: PaymentTypeOption[], paymentType: PaymentTypeItem) => {
      const typesToExclude: string[] = [PaymentTypeEnum.CHECK];
      const typesToAddPrefix: string[] = [
        PaymentTypeEnum.ECHECK,
        PaymentTypeEnum.CREDIT_DEBIT_CARD,
        PaymentTypeEnum.CREDIT_CARD,
        PaymentTypeEnum.DEBIT_CARD,
        PaymentTypeEnum.MAZOOMA
      ];
      const typesToShowCMSTitle: string[] = [
        PaymentTypeEnum.SIGHTLINE,
        PaymentTypeEnum.PAYPAL
      ];
      if (
        paymentType.paymentTypeOptions &&
        !typesToExclude.includes(paymentType.paymentType)
      ) {
        const methodInfos =
          availablePaymentMethodsInfos[paymentType.paymentType];
        const options = paymentType.paymentTypeOptions.map((option) => {
          if (typesToShowCMSTitle.includes(paymentType.paymentType)) {
            option.title = methodInfos.title;
          }

          if (!option.id) {
            option.id = paymentType.paymentType;
          }

          if (typesToAddPrefix.includes(paymentType.paymentType)) {
            option.description = `•••• ${option.description?.slice(-4)}`;
          }

          if (
            !paymentType.depositsAvailable ||
            !paymentType.withdrawalsAvailable
          ) {
            option.depositsAvailable = get(
              paymentType,
              "depositsAvailable",
              true
            );
            option.withdrawalsAvailable = get(
              paymentType,
              "withdrawalsAvailable",
              true
            );
          }

          return {
            ...option,
            paymentType: paymentType.paymentType,
            paymentTypeAvailable: paymentType.paymentTypeAvailable
          };
        });
        acc.push(...options);
      }
      return acc;
    },
    []
  );

export const isCardExpired = (expirationDate?: string | null) => {
  if (!expirationDate) return false;

  const expDate = new Date(expirationDate);
  const todayDate = new Date();
  const expirationYear = expDate.getFullYear();
  const expirationMonth = expDate.getMonth();
  const todayYear = todayDate.getFullYear();
  const todayMonth = todayDate.getMonth();

  return (
    expirationYear < todayYear ||
    (expirationMonth < todayMonth && expirationYear <= todayYear)
  );
};

export const isPaymentTypeCard = (paymentType: PaymentType) => {
  switch (paymentType) {
    case "CC":
    case "CCS":
    case "CCP":
    case "DEB":
    case "DEBS":
      return true;
    default:
      return false;
  }
};

export const canAddNewAccount = (
  paymentType: PaymentType,
  paymentTypeOptions: PaymentTypeOption[],
  toggles: object = {}
): boolean => {
  switch (paymentType) {
    case "PAY":
    case "PPAL":
    case "SLP":
    case "SLN":
      return !(paymentTypeOptions && paymentTypeOptions.length > 0);
    case "ACH":
      return get(toggles, "allowCreateACH", false);
    default:
      return true;
  }
};

export const shouldCapitalizeTitle = (paymentType: PaymentType) => {
  switch (true) {
    case isPaymentTypeCard(paymentType):
    case paymentType === PaymentTypeEnum.ECHECK:
    case paymentType === PaymentTypeEnum.INSTANT_BET_CASH:
      return true;
    default:
      return false;
  }
};

export const fetchAvailablePaymentMethods = async ({
  setIsLoading,
  setHasError,
  accountNumber,
  availablePaymentMethodsInfos,
  setOtherMethods,
  setMainMethods,
  dispatch
}: FetchAvailablePaymentMethodsArgs) => {
  if (accountNumber) {
    try {
      if (setIsLoading) {
        setIsLoading(true);
      }

      setHasError(false);

      let {
        // eslint-disable-next-line
        data: { firstDepositDone, paymentTypes }
      } = await uwtService.getAvailablePaymentMethods(accountNumber);
      // @TODO this business logic has to go to the backend, this has to be temporary
      if (tvgConf().brand === "4njbets") {
        paymentTypes = paymentTypes.filter(
          (payment) => payment.paymentType !== "MPAK"
        );
      }

      // Set if the user has done a deposit before
      dispatch(setIsFirstDepositDone(firstDepositDone));

      // Allow only 3 MZM accounts to be displayed
      const displaybalePaymentTypes = filterMzmAccounts(paymentTypes);

      const orderedPaymentTypes = sortBy(displaybalePaymentTypes, "priority");
      const accountsOnFile = filterOnFileMethods({
        availablePaymentMethodsInfos,
        paymentTypes: orderedPaymentTypes
      });
      const paymentMethodsUnderMaintenance = paymentTypes.reduce(
        (acc: PaymentType[], paymentType: PaymentTypeItem) => {
          if (!paymentType.paymentTypeAvailable) {
            acc.push(paymentType.paymentType);
          }
          return acc;
        },
        []
      );

      dispatch(setAccountsOnFileMethods(accountsOnFile));
      dispatch(
        setPaymentMethodsUnderMaintenance(paymentMethodsUnderMaintenance)
      );

      if (setOtherMethods) {
        setOtherMethods(
          orderedPaymentTypes.filter((method) => !method.mainList)
        );
      }

      const mainMethods = filterMainMethods({
        availablePaymentMethodsInfos,
        paymentTypes: orderedPaymentTypes
      });

      if (setMainMethods) {
        setMainMethods(mainMethods);
      }

      dispatch(setCreateMainMethods(mainMethods));

      if (setIsLoading) {
        setIsLoading(false);
      }

      return {
        accountsOnFile,
        paymentMethodsUnderMaintenance
      };
    } catch {
      setHasError(true);

      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  }
  return null;
};

export const redirectToDMA = (
  activeTab: PaymentType,
  type: string = "deposit",
  paymentId: string = "",
  newPayment: boolean = true
) => {
  const path = tvgConf().buildUrl({
    app: "dma",
    path: type,
    wrapper: true,
    callback: true,
    params: {
      fullPage: true,
      initialTab: getDMATab(activeTab, type),
      ...((activeTab === PaymentTypeEnum.CREDIT_DEBIT_CARD ||
        activeTab === PaymentTypeEnum.ECHECK) &&
        newPayment && {
          cardOnFile: "newCard"
        }),
      ...(paymentId && {
        paymentId
      })
    }
  });

  // eslint-disable-next-line no-restricted-globals
  location.href = path;
  return path;
};

export const getCAPIMessageByType = (paymentType: PaymentType): string => {
  switch (paymentType) {
    case "ACH":
      return "pawsACHErrorMessages";
    case "CC":
    case "CCP":
    case "DEB":
      return "pawsCCPErrorMessages";
    case "IBC":
      return "pawsIBCErrorMessages";
    case "MPAK":
      return "pawsMPAKErrorMessages";
    case "PNM":
      return "pawsPNMErrorMessages";
    case "PAY":
    case "PPAL":
      return "pawsPAYErrorMessages";
    case "SLP":
      return "pawsSLPErrorMessages";
    case "CASH":
    case "CHK":
      return "pawsCHKErrorMessages";
    case "MZM":
      return "pawsMZMErrorMessages";
    case "VIWD":
    case "CFee":
    case "Coll":
    case "CNTW":
    case "RPPS":
    case "WTRN":
    default:
      return "";
  }
};

export const formatValue = (value: string, useFixedPoint: boolean = false) => {
  if (useFixedPoint) {
    return Number(value)
      .toFixed(value.includes(".") ? 2 : 0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const getModalCommonActions = (
  onConfirm?: NullaryFn<void>,
  onClose?: NullaryFn<void>,
  onCancel?: NullaryFn<void>,
  onRetry?: NullaryFn<void>,
  onContact?: NullaryFn<void>,
  retryCount: number = 0,
  customCancelText?: string
): Array<ButtonAction> =>
  sortBy(
    [
      {
        text: "Confirm",
        onClick: onConfirm,
        isStretched: true,
        isUppercase: false,
        type: "primary",
        qaLabel: "confirm",
        order: 1
      },
      {
        text: retryCount <= 2 ? "Retry" : "Contact Customer Service",
        onClick: onRetry,
        isStretched: true,
        isUppercase: false,
        type: retryCount <= 2 ? "primary" : "tertiary",
        qaLabel: "retry",
        order: retryCount <= 2 ? 1 : 3
      },
      {
        text: "Close",
        onClick: onClose,
        isStretched: true,
        isUppercase: false,
        type: "secondary",
        qaLabel: "close",
        order: 2
      },
      {
        text: customCancelText || "Cancel",
        onClick: onCancel,
        isStretched: true,
        isUppercase: false,
        type: "secondary",
        qaLabel: "cancel",
        order: 2
      },
      {
        text: "Contact Customer Service",
        onClick: onContact,
        isStretched: true,
        isUppercase: false,
        type: "tertiary",
        qaLabel: "customerService",
        order: 3
      }
    ].filter((button) => button.onClick) as Array<ButtonAction>,
    "order"
  );

export const filterModalCommonActions = (
  allActions: Array<ButtonAction>,
  methodActions: Array<string> = ["close"],
  retryCount: number = 0
) =>
  allActions.filter(
    (action) =>
      action.qaLabel &&
      methodActions.includes(action.qaLabel) &&
      (action.qaLabel !== "customerService" ||
        (action.qaLabel === "customerService" && retryCount <= 2))
  );

export const redirectToNewCard = (
  paymentType: PaymentType,
  toggles: CreatePMToggles,
  dispatch: UnaryFn<
    PayloadAction<
      string | DepositsPayload | SelectedPaymentMethodPayload | undefined
    >,
    void
  >,
  method: PaymentTypeItem | undefined
) => {
  if (get(toggles, `${paymentType}`, false)) {
    validateCreationPage(paymentType, dispatch, method);
  } else {
    batch(() => {
      dispatch(closeCreatePMModal());
      dispatch(closeDepositFundsModal());
      dispatch(toggleQuickDepositModal(false));
      dispatch(closeCardDeclinedModal());
    });
  }
};

export const createMzmAccount = (
  dispatch: UnaryFn<
    PayloadAction<
      string | DepositsPayload | SelectedPaymentMethodPayload | undefined
    >,
    void
  >,
  method: PaymentTypeItem | undefined
) => {
  batch(() => {
    dispatch(
      setSelectedPaymentMethod({
        id: "",
        paymentType: PaymentTypeEnum.MAZOOMA,
        title: "",
        subTitle: "",
        description: "",
        depositsAvailable: true,
        withdrawalsAvailable: false,
        minLimit: method?.minLimit || 0,
        maxLimit: method?.maxLimit || 9999999999
      })
    );
    dispatch(openDepositFundsModal(true));
  });
};

export const validateCreationPage = (
  paymentType: PaymentType,
  dispatch: UnaryFn<
    PayloadAction<
      string | DepositsPayload | SelectedPaymentMethodPayload | undefined
    >,
    void
  >,
  method: PaymentTypeItem | undefined
) => {
  if (
    paymentType !== PaymentTypeEnum.PAYPAL &&
    paymentType !== PaymentTypeEnum.MAZOOMA
  ) {
    dispatch(openCreatePMModal(paymentType));
  } else if (paymentType === PaymentTypeEnum.MAZOOMA) {
    createMzmAccount(dispatch, method);
  } else {
    dispatch(
      openDepositFundsModal(
        paymentType === PaymentTypeEnum.PAYPAL &&
          method?.paymentTypeOptions != null
          ? null
          : true
      )
    );
    if (method) {
      dispatch(
        setSelectedPaymentMethod({
          id: "",
          paymentType: method.paymentType,
          title: get(method, "title", ""),
          subTitle: get(method, "description", ""),
          description: "",
          depositsAvailable: method.depositsAvailable || false,
          withdrawalsAvailable: method.withdrawalsAvailable || false,
          minLimit: method.minLimit || 0,
          maxLimit: method.maxLimit || 9999999999
        })
      );
    }
  }
};

export const getPaymentMethod = (
  paymentType: PaymentType,
  methods: PaymentTypeItem[]
) => methods.find((method) => method.paymentType === paymentType);

export const getLastUsedPMByPaymentType = (
  methods: PaymentTypeOption[],
  paymentType: PaymentType
) => {
  const lastUsed = methods
    .filter(
      (method) => method.paymentType === paymentType && method.depositsAvailable
    )
    .sort((a: PaymentTypeOption, b: PaymentTypeOption) =>
      sortByLastUsed(a.lastUsedDateTime!, b.lastUsedDateTime!)
    );
  return lastUsed[0];
};

export const redirectOnFeeFree = ({
  dispatch,
  paymentMethod,
  isQuickDeposit,
  isDesktop,
  history,
  setIsFeeInfoOpen,
  isMZMToggleOn = false,
  mainMethods
}: RedirectOnFeeFreeArgs) => {
  if (setIsFeeInfoOpen) {
    setIsFeeInfoOpen(false);
  }

  const paymentTypeUsed = isMZMToggleOn
    ? PaymentTypeEnum.MAZOOMA
    : PaymentTypeEnum.ECHECK;

  // If the mzm toggle is on, we search for mazooma in the main methods, if not we search for Echeck
  // Then we check if its available to deposit
  const usedMethod = mainMethods.find(
    (pm) => pm.paymentType === paymentTypeUsed
  );

  // If it is not available for deposit we just redirect the user for the wallet
  if (!usedMethod?.depositsAvailable) {
    batch(() => {
      dispatch(toggleQuickDepositModal(false));
      dispatch(closeDepositFundsModal(false));
    });
    goToWallet(history, isDesktop);
  } else {
    if (paymentMethod) {
      dispatch(
        setSelectedPaymentMethod({
          ...paymentMethod,
          subTitle: paymentMethod.description,
          description: ""
        })
      );

      setTimeout(() => {
        dispatch(openDepositFundsModal(false));
      }, REDIRECT_ON_FEE_DELAY);
    } else {
      setTimeout(() => {
        validateCreationPage(paymentTypeUsed, dispatch, usedMethod);
      }, REDIRECT_ON_FEE_DELAY);
    }

    if (isQuickDeposit) {
      setTimeout(() => {
        batch(() => {
          dispatch(toggleQuickDepositModal(false));
          dispatch(openDepositFundsModal(false));
        });
      }, REDIRECT_ON_FEE_DELAY);
      goToWallet(history, isDesktop);
    }
  }
};

export const getPmToggles = (store: Store) => ({
  ACH: get(store, "capi.featureToggles.pawsACH", false) as boolean,
  PAY: get(store, "capi.featureToggles.pawsPAY", false) as boolean,
  PPAL: get(store, "capi.featureToggles.pawsPAY", false) as boolean,
  CCP: get(store, "capi.featureToggles.pawsCC", false) as boolean,
  CHK: get(store, "capi.featureToggles.pawsCHK", false) as boolean,
  MPAK: get(store, "capi.featureToggles.pawsMPAK", false) as boolean,
  MZM: getMazoomaToggle(store) as boolean,
  WTRN: get(store, "capi.featureToggles.pawsWTRN", false) as boolean,
  CASH: get(store, "capi.featureToggles.pawsCASH", false) as boolean,
  PNM: get(store, "capi.featureToggles.pawsPNM", false) as boolean
});

export const cardDeclinedErrorCodes = [
  107701,
  107501,
  306,
  "OLD_CCP_DISCOVER_CARD"
];

export const getSuccessWithdrawalActions = (
  onClose: NullaryFn<void>
): ButtonAction[] => [
  {
    text: "Close",
    onClick: onClose,
    isStretched: true,
    isUppercase: false,
    type: "primary"
  }
];

export const getNextValueFundsAmount = (
  keyValue: number | string,
  from: FundsAmountInputChangingFrom,
  value: string
) => {
  const valueString = keyValue.toString();
  const lastCharAtValueString = valueString.charAt(valueString.length - 1);
  const isLeadingZero = value === "" && (keyValue === 0 || keyValue === "0");
  const shouldReplaceLeadingZero = value === "0";
  const amountFields =
    from === "mobileAmountField" || from === "desktopAmountField";
  const mobile = from === "mobile" || from === "mobileAmountField";

  let nextValue = "";
  if (amountFields && isLeadingZero) {
    // Adding a dot when the user type a "leading zero" (also preventing zeros "to the left")
    nextValue = "0.";
  } else if (amountFields && shouldReplaceLeadingZero) {
    // Replace "leading zero" with the value inputted to prevent zeros "to the left"
    nextValue = lastCharAtValueString;
  } else if (mobile) {
    nextValue = value + valueString;
  } else {
    nextValue = valueString;
  }

  return nextValue;
};

export const checkCardLimit = (
  paymentType: PaymentType,
  paymentTypeOptions: PaymentTypeOption[] | null,
  dispatch: Dispatch<ActionCreatorWithoutPayload<string>> | undefined
) => {
  if (
    isPaymentTypeCard(paymentType) &&
    paymentTypeOptions &&
    paymentTypeOptions.length >= 5 &&
    dispatch
  ) {
    dispatch(openInfoPanelModal());
    return false;
  }
  return true;
};

export const getDeleteTypeByPaymentType = (type: PaymentType | null) => {
  if (
    type === PaymentTypeEnum.MAZOOMA ||
    type === PaymentTypeEnum.PAYPAL ||
    type === PaymentTypeEnum.ECHECK
  ) {
    return "account";
  }

  return "card";
};

export const filterMzmAccounts = (
  paymentTypes: PaymentTypeItem[]
): PaymentTypeItem[] => {
  const mzmAccounts = paymentTypes.find(
    (pt: PaymentTypeItem) => pt.paymentType === PaymentTypeEnum.MAZOOMA
  );

  if (
    mzmAccounts &&
    mzmAccounts.paymentTypeOptions &&
    mzmAccounts.paymentTypeOptions.length > 3
  ) {
    const filteredMzmAccounts = {
      ...mzmAccounts,
      paymentTypeOptions: mzmAccounts.paymentTypeOptions
        .sort((a, b) =>
          sortByLastUsed(a.lastUsedDateTime!, b.lastUsedDateTime!)
        )
        .filter((_, index: number) => index <= 2)
    };

    return paymentTypes
      .filter(
        (pt: PaymentTypeItem) => pt.paymentType !== PaymentTypeEnum.MAZOOMA
      )
      .concat(filteredMzmAccounts);
  }

  return paymentTypes;
};

export const desktopNavigation = ({
  event,
  paymentType,
  navigationType = "deposit",
  paymentId,
  newPayment = false,
  paymentTypeOptions = [],
  dispatch,
  history
}: DesktopNavigationProps) => {
  if (!checkCardLimit(paymentType, paymentTypeOptions, dispatch)) {
    return;
  }

  if (tvgConf().device === "desktop") {
    event.preventDefault();
    event.stopPropagation();

    let paramPaymentId = "";
    let paramNewPayment = "";

    if (newPayment) {
      paramNewPayment = "&cardOnFile=newCard";
    } else if (paymentId) {
      paramPaymentId = `&paymentId=${paymentId}`;
    }

    const navigationUrl = `/my-funds/${navigationType}?initialTab=${getDMATab(
      paymentType,
      navigationType
    )}${paramNewPayment}${paramPaymentId}`;

    if (isTvg5() && history) {
      history.push(navigationUrl);
    } else {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: {
          route: navigationUrl
        }
      });
    }

    mediator.base.dispatch({
      type: "PAWS:NAVIGATE_DEPOSIT_WITHDRAW_OPTIONS",
      payload: {
        selectedOption: navigationType,
        paymentType,
        destinationUrl: navigationUrl
      }
    });
  } else {
    redirectToDMA(paymentType, navigationType, paymentId, newPayment);
  }
};

export const isMobile = tvgConf().device === "mobile";
export const isDesktop = tvgConf().device === "desktop";
export const isTablet = tvgConf().device === "tablet";

export const canMethodBeDeleted = (paymentType: PaymentType) =>
  [PaymentTypeEnum.MAZOOMA, PaymentTypeEnum.CREDIT_DEBIT_CARD].includes(
    paymentType as PaymentTypeEnum
  );

export const tagMapper: TagMapper = {
  grey: "info",
  green: "optedin",
  yellow: "promo",
  orange: "warning",
  blue: "highlight"
};

export const getTagsByPaymentType = (
  tags: PaymentTypeTag[],
  paymentType: PaymentType
) =>
  (Array.isArray(tags) &&
    tags.find((tag: PaymentTypeTag) => tag.paymentType === paymentType)
      ?.tags) ||
  [];

export const getPaymentMethodDescription = (
  isDisabled: boolean,
  pawsLabels: PawsContent,
  paymentType: PaymentType,
  description?: string,
  isUnderMaintenance?: boolean
) => {
  if (isUnderMaintenance) {
    return get(
      pawsLabels,
      `paymentMethodUnderMaintenance.message`,
      "Currently under maintenance, please try it later"
    );
  }
  if (isDisabled) {
    return get(
      pawsLabels,
      `deposit.${paymentType}.disabledMessage`,
      "Deposit is not available at the moment"
    );
  }
  return description;
};

export const isAccountUnderMaintenance = (
  paymentTypesUnderMaintenance: PaymentType[],
  account: PaymentTypeOption
) =>
  paymentTypesUnderMaintenance.some(
    (paymentType) => paymentType === account.paymentType
  );

export const computeBalanceToDisplay = (
  userBalance: number | string,
  balanceIsShown: ToggleStateEnum
) => {
  if (balanceIsShown === ToggleStateEnum.OFF) {
    return undefined;
  }

  return typeof userBalance === "number"
    ? `BALANCE ${formatCurrency(userBalance)}`
    : undefined;
};
