import { PromoStepType } from "@tvg/sh-lib-promos-onboarding/types/promoSteps";

// Those vars is to make offset registration and payout step because this step is only on FE side.
export const OFFSET_REGISTRATION_STEP = 1;
export const OFFSET_PAYOUT_STEP = 2;

export const getCompletedSteps = (
  completedSteps: number,
  currentStepType: PromoStepType,
  isFulfilled: boolean
) =>
  currentStepType === PromoStepType.PAYOUT && isFulfilled
    ? completedSteps + OFFSET_PAYOUT_STEP
    : completedSteps + OFFSET_REGISTRATION_STEP;
