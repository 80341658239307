import { RefObject } from "react";
import conf from "@tvg/conf";
import achService from "@tvg/api/ach";
import { PayloadAction } from "@reduxjs/toolkit";
import { setSelectedPaymentMethod } from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import type { SelectedPaymentMethodPayload } from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import {
  DepositsPayload,
  openDepositFundsModal
} from "@tvg/sh-lib-paws/redux/slices/depositFundsModalSlice";
import { togglePMCreate } from "@tvg/sh-lib-paws/redux/slices/createPMModalSlice";
import { object } from "yup";
import { RequiredStringSchema } from "yup/lib/string";
import { get, isEmpty } from "lodash";
import { FormHandles } from "@unform/core";
import { StateType, ECheckCreationMessages, ActiveInput } from "./types";
import { PaymentTypeItem, UnaryFn, NullaryFn } from "../../../types";
import {
  accountNumberSchema,
  bankNameSchema,
  dlStateSchema,
  drivingLicenceSchema,
  routingNumberSchema
} from "./schemaValidations";

export const states = (conf().getStates() || []).map((state: StateType) => ({
  key: state.name,
  value: state.abbreviation
}));

/* eslint-disable */
export const getBankName = async (
  routingNumber: string,
  formRef: RefObject<FormHandles> | null,
  setIsValidRoutingNumber: UnaryFn<boolean, void>,
  content: ECheckCreationMessages,
  setFormFilled: UnaryFn<boolean, void>
) => {
  if (formRef && formRef.current) {
    try {
      if (routingNumber.length !== 9) {
        throw new Error("Routing number must have 9 digits");
      }

      const {
        data: { name }
      } = await achService.getBank(routingNumber);

      if (name) {
        setIsValidRoutingNumber(true);
        formRef.current.setFieldValue("bankName", name);
        formRef.current.setFieldError("bankName", "");
        handleFormValidation(formRef, content, setFormFilled);
      }
    } catch {
      setIsValidRoutingNumber(false);
      formRef.current.setFieldValue("bankName", "");
      handleFormValidation(formRef, content, setFormFilled);
    }
  }
};

export const handleFieldValidation = async (
  fieldName: string,
  value: string,
  schema: any,
  formRef: RefObject<FormHandles> | null
) => {
  if (formRef && formRef.current) {
    try {
      await schema.validate(value, {
        abortEarly: true
      });
      formRef.current.setFieldError(fieldName, "");
    } catch (err) {
      formRef.current.setFieldError(
        fieldName,
        get(err, "errors[0]", "Please fill this field")
      );
    }
  }
};

export const handleFormValidation = async (
  formRef: RefObject<FormHandles> | null,
  content?: ECheckCreationMessages,
  setFormFilled?: UnaryFn<boolean, void>
) => {
  if (
    typeof setFormFilled === "function" &&
    content &&
    !isEmpty(content) &&
    formRef &&
    formRef.current
  ) {
    try {
      const schema = object().shape({
        routingNumber: routingNumberSchema(content.routingNumber.validations),
        accountNumber: accountNumberSchema(content.accountNumber.validations),
        bankName: bankNameSchema(content.bankName.validations),
        drivingLicence: drivingLicenceSchema(
          content.drivingLicence.validations
        ),
        dlState: dlStateSchema(content.dlState.validations)
      });

      await schema.validate(formRef.current.getData(), {
        abortEarly: false
      });

      setFormFilled(true);
    } catch (err) {
      setFormFilled(false);
    }
  }
};

export const validateAndSubmit = async (
  content: ECheckCreationMessages,
  formRef: RefObject<FormHandles> | null,
  setIsLoading: UnaryFn<boolean, void>,
  dispatch: UnaryFn<
    PayloadAction<DepositsPayload | SelectedPaymentMethodPayload | undefined>,
    void
  >,
  method?: PaymentTypeItem
) => {
  setIsLoading(true);
  try {
    const schema = object().shape({
      routingNumber: routingNumberSchema(content.routingNumber.validations),
      accountNumber: accountNumberSchema(content.accountNumber.validations),
      bankName: bankNameSchema(content.bankName.validations),
      drivingLicence: drivingLicenceSchema(content.drivingLicence.validations),
      dlState: dlStateSchema(content.dlState.validations)
    });

    if (formRef && formRef.current) {
      const formData: any = formRef.current.getData();

      await schema.validate(formData, {
        abortEarly: false
      });

      // Remove all previous errors
      formRef.current.setErrors({});

      // NEED TO COMPLETE THIS ON PAWS-215

      setTimeout(() => {
        setIsLoading(false);
        dispatch(togglePMCreate());
        dispatch(
          setSelectedPaymentMethod({
            id: "",
            paymentType: "ACH",
            title: "",
            description: "",
            subTitle: "",
            depositsAvailable: get(method, "depositsAvailable", false),
            withdrawalsAvailable: get(method, "withdrawalsAvailable", false),
            minLimit: get(method, "minLimit", 99999999),
            maxLimit: get(method, "maxLimit", 0)
          })
        );
        dispatch(
          openDepositFundsModal({
            bankAccountNumber: formData.accountNumber,
            bankRoutingNumber: formData.routingNumber,
            bankName: formData.bankName,
            dlNumber: formData.drivingLicence,
            dlState: formData.dlState
          })
        );
      }, 3000);
    }
  } catch (err) {
    setIsLoading(false);
  }
};

export const handleClickOutsideForm = (event: MouseEvent) =>
  event.preventDefault();

export const handleOnCloseModal = (
  onClose: NullaryFn<void>,
  setIsValidRoutingNumber: UnaryFn<boolean, void> | null
) => {
  if (typeof setIsValidRoutingNumber === "function") {
    setIsValidRoutingNumber(false);
  }
  onClose();
};
/* eslint-enable */

export const handleBlur =
  (
    name: string,
    schema: RequiredStringSchema<string | undefined, Record<string, unknown>>,
    formRef: RefObject<FormHandles> | null,
    setActiveInput: (value: React.SetStateAction<ActiveInput>) => void
  ) =>
  (value: string) => {
    handleFieldValidation(name, value, schema, formRef);
    setActiveInput(null);
  };
