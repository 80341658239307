import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const NoVideo = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8797 12C22.8797 18.0087 18.0087 22.8797 12 22.8797C5.99133 22.8797
      1.12033 18.0087 1.12033 12C1.12033 5.99133 5.99133 1.12033 12
      1.12033C18.0087 1.12033 22.8797 5.99133 22.8797 12ZM9.00064
      16.5018V7.5L16.4992 12L9.00064 16.5018Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M1.5 1.5L6.75 6.75M15.9375 15.9375L22.5 22.5M22.8797 12C22.8797 18.0087
      18.0087 22.8797 12 22.8797C5.99133 22.8797 1.12033 18.0087 1.12033
      12C1.12033 5.99133 5.99133 1.12033 12 1.12033C18.0087 1.12033 22.8797
      5.99133 22.8797 12ZM9.00064 7.5V16.5018L16.4992 12L9.00064 7.5Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
