import styled, { css } from "styled-components/native";
import { Pressable } from "react-native";
import { FilterButtonProps, ButtonStates } from "./types";
import { Theme } from "../../../../theming/types";

const buttonSizes = {
  s: css`
    height: 25px;
    padding: 4px 7px;
  `,
  m: css`
    height: 32px;
    padding: 6px 7px;
  `,
  l: css`
    height: 44px;
    padding: 12px 15px;
  `
} as const;

const buttonStates = {
  default: css`
    background: transparent;
    border: solid 1px transparent;
  `,
  hover: css`
    background: ${({ theme }) =>
      theme.colorTokens.component.button.tertiary.background.hover};
    border: 1px solid ${({ theme }) => theme.colorTokens.border.default};
  `,
  active: css`
    background: ${({ theme }) =>
      theme.colorTokens.component.activeGroupedFilterCell.background};
    border: 1px solid
      ${({ theme }) =>
        theme.colorTokens.component.activeGroupedFilterCell.border};
    z-index: 1;
  `,
  pressed: css`
    background: ${({ theme }) =>
      theme.colorTokens.component.button.buttonLink.background.hover};
    border: 1px solid ${({ theme }) => theme.colorTokens.border.default};
  `
} as const;

export const StyledButton = styled(Pressable)<
  Pick<
    FilterButtonProps,
    "size" | "iconSide" | "minWidth" | "isStretch" | "hasWidth"
  > &
    ButtonStates
>`
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  flex-direction: ${({ iconSide }) =>
    iconSide === "left" ? "row" : "row-reverse"};
  gap: 10px;
  line-height: 18px;
  margin: 0 -1px;
  transition: all 0.2s ease-in-out;
  ${({ hasWidth }) =>
    hasWidth &&
    css`
      width: max-content;
    `}
  ${({ isStretch }) =>
    isStretch &&
    css`
      flex: 1;
    `}
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
  ${({ size }) => buttonSizes[size as FilterButtonProps["size"]]}
  ${({ isHovered, isPressed, isActive }) => {
    if (isActive) return buttonStates.active;
    if (isPressed) return buttonStates.pressed;
    if (isHovered) return buttonStates.hover;
    return buttonStates.default;
  }}
`;

export const StyledText = styled.Text<
  Pick<ButtonStates, "isActive"> &
    Pick<FilterButtonProps, "size"> & { theme: Theme }
>`
  color: ${({ theme, isActive }) =>
    isActive
      ? theme.colorTokens.component.activeGroupedFilterCell.content
      : theme.colorTokens.content.default};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${({ theme, size }) =>
    size === "s" ? theme.fontSizes.xs : theme.fontSizes.s};
`;
