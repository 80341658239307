import styled from "styled-components/native";
import { View } from "react-native";

export const StyledComponent = styled(View)`
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled(View)`
  position: absolute;
  width: ${({ theme }) => theme.space["space-7"]};
  height: ${({ theme }) => theme.space["space-7"]};
`;

export const BagdeContainer = styled(View)`
  position: absolute;
  right: 0.5px;
  top: 0.2px;
`;
