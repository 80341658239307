import styled from "styled-components";
import {
  space,
  system,
  compose,
  fontFamily,
  fontSize,
  flex,
  layout,
  typography,
  color
} from "../../../../src/theming/styledSystem";
import type { ParagraphProps } from "./types";

const lineHeightNormal = system({
  fontSize: {
    property: "lineHeight",
    scale: "lineHeights"
  }
});

const lineHeightTaller = system({
  fontSize: {
    property: "lineHeight",
    scale: "lineHeightsTaller"
  }
});

const transformText = system({
  textTransform: {
    property: "textTransform"
  },
  whiteSpace: {
    property: "whiteSpace"
  },
  textOverflow: {
    property: "textOverflow"
  }
});

const styles = compose(
  space,
  fontFamily,
  fontSize,
  transformText,
  typography,
  layout,
  color
);

export const StyledParagraph = styled.p<ParagraphProps>`
  ${({ isLineHigher }) => (isLineHigher ? lineHeightTaller : lineHeightNormal)}
  overflow: ${({ textOverflow }) =>
    textOverflow === "ellipsis" ? "hidden" : "visible"};
  ${styles};
  ${flex}
`;
