import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "@reduxjs/toolkit";
import { useNavigate } from "@tvg/custom-hooks";
import DSIcon from "@tvg/design-system/src/components/icon";
import { Withdraw, Deposit } from "@tvg/atomic-ui/_static/Icons/paws";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { openDeleteCardModal } from "@tvg/sh-lib-paws/redux/slices/deleteCardModalSlice";
import { getWireTransferAutomationToggle } from "@tvg/sh-lib-paws/redux/selectors";
import { PaymentType } from "@tvg/ts-types/Payment";
import {
  PaymentOptionTooltip,
  TooltipOption,
  TooltipText,
  TooltipSeparator
} from "./styled-components";
import {
  getPmToggles,
  getDeleteTypeByPaymentType,
  canMethodBeDeleted
} from "../../utils";
import { OptionTooltipProps } from "./types";
import { onClickDeposit, onClickWithdraw } from "./utils";
import { gtmDeletePaymentMethodButton } from "../../gtm";

const OptionTooltip = ({
  payment,
  activeOption,
  paymentType,
  onClose,
  setActiveOption
}: OptionTooltipProps) => {
  const ref = useRef<HTMLUListElement>(null);
  const dispatch = useDispatch();
  const history = useNavigate();
  const pmToggles = useSelector(getPmToggles);
  const isWireTransferAutomationToggleOn = useSelector<Store, boolean>(
    getWireTransferAutomationToggle
  );
  const otherPaymentType =
    paymentType === PaymentType.OTHER_PAYMENT
      ? payment.paymentType
      : paymentType;

  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        onClose();
      }
    };
    setTimeout(() => {
      document.addEventListener("click", clickOutside);
    }, 0);
    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  return (
    <PaymentOptionTooltip
      data-qa-label="payment-methods-selector"
      ref={ref}
      isVisible={activeOption === payment.id}
    >
      {payment.depositsAvailable && (
        <TooltipOption
          data-qa-label="payment-method-selector-deposit"
          onClick={onClickDeposit(
            pmToggles,
            otherPaymentType,
            payment,
            paymentType,
            dispatch,
            setActiveOption,
            history
          )}
        >
          <Deposit data-qa-label="payment-method-selector-icon-deposit" />
          <TooltipText data-qa-label="payment-method-selector-text-deposit">
            Deposit
          </TooltipText>
        </TooltipOption>
      )}
      {payment.withdrawalsAvailable && (
        <>
          <TooltipSeparator />
          <TooltipOption
            data-qa-label="payment-method-selector-withdraw"
            onClick={onClickWithdraw(
              isWireTransferAutomationToggleOn,
              pmToggles,
              paymentType,
              payment,
              dispatch,
              setActiveOption,
              history
            )}
          >
            <Withdraw data-qa-label="payment-method-selector-icon-withdraw" />
            <TooltipText data-qa-label="payment-method-selector-text-withdraw">
              Withdraw
            </TooltipText>
          </TooltipOption>
        </>
      )}
      {
        // TODO: Remove this condition to enable delete option for all accountsOnFile
        canMethodBeDeleted(paymentType) &&
          payment.paymentType !== PaymentType.ECHECK &&
          !!payment.id && (
            <>
              <TooltipSeparator />
              <TooltipOption
                data-qa-label="payment-method-selector-delete"
                color={buildColor("red", "500")}
                onClick={() => {
                  gtmDeletePaymentMethodButton({ paymentType });
                  dispatch(openDeleteCardModal({ ...payment, paymentType }));
                }}
              >
                <DSIcon
                  name="trash"
                  size="s"
                  backgroundColor={buildColor("red", "100")}
                  lineColor={buildColor("red", "500")}
                />
                <TooltipText>
                  Delete {getDeleteTypeByPaymentType(paymentType)}
                </TooltipText>
              </TooltipOption>
            </>
          )
      }
    </PaymentOptionTooltip>
  );
};

export default OptionTooltip;
