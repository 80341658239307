import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type State = {
  isModalOpen: boolean;
  declinedAmount: number;
  errorCode: number | string | null;
};

export const initialState: State = {
  isModalOpen: false,
  declinedAmount: 1,
  errorCode: null
};

const cardDeclinedModalSlice = createSlice({
  name: "cardDeclinedModal",
  initialState,
  reducers: {
    openCardDeclinedModal(state) {
      state.isModalOpen = true;
    },
    closeCardDeclinedModal(state) {
      state.isModalOpen = false;
    },
    setDeclinedAmountCount(state) {
      state.declinedAmount += 1;
    },
    setErrorCode(state, action: PayloadAction<number>) {
      state.errorCode = action.payload;
    }
  }
});

export const {
  openCardDeclinedModal,
  closeCardDeclinedModal,
  setDeclinedAmountCount,
  setErrorCode
} = cardDeclinedModalSlice.actions;

export default cardDeclinedModalSlice.reducer;
