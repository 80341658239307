import { FunctionComponent } from "react";
import { AlertInlineProps, TagProps } from "@tvg/design-system";

export enum StoryblokComponentName {
  ALERT_INLINE = "alert_inline",
  TAG = "tag",
  UNKNOWN = ""
}

export enum StoryblokPlaceName {
  PROGRAM_PAGE = "program_page",
  TRACKS = "tracks_page",
  BETSLIP_CONFIRMATION = "betslip_confirmation",
  BETSLIP_PLACED = "betslip_placed",
  MYBETS_WAGER_FOOTER = "bet_ticket",
  MYBETS_CANCEL = "cancel_bet",
  MYBETS_RACE_DETAILS = "mybets_race_details",
  WALLET_HOME = "wallet_home",
  WALLET_HOME_DESKTOP = "wallet_home_desktop",
  WALLET_QUICK_DEPOSIT = "wallet_quick_deposit",
  WALLET_DEPOSIT_TOP = "wallet_deposit_top",
  WALLET_DEPOSIT_TAG = "wallet_deposit_tag",
  UNKNOWN = ""
}

export interface PromoOnboardingComponent<T = unknown> {
  component: StoryblokComponentName;
  props: T;
}

export type PromoComponent<T = unknown> = Partial<
  Record<StoryblokPlaceName, PromoOnboardingComponent<T>>
>;

export interface StoryblokBlock<T> {
  component: FunctionComponent<T>;
  defaultProps: Partial<T>;
}

export interface ComponentDictionary {
  alert_inline: StoryblokBlock<AlertInlineProps>;
  tag: StoryblokBlock<TagProps>;
  unknown: Object;
}

export type StorybookAvailableComponents = Partial<AlertInlineProps | TagProps>;

export type StoryblokComponentProps = StorybookAvailableComponents & {
  component: StoryblokComponentName;
};
