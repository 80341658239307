import { BinaryFn, NullaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import {
  GeoLocationResponseRejected,
  GeoLocationResponseSuccess
} from "@fdr/types/ts/GeoLocation";
import type { Troubleshooter } from "@fdr/types/ts/GeoLocation";
import { GeoClientErrorCodes, GeoClientErrorMessages } from "./gcHtml5";

export interface GeolocationSession {
  userId: string;
  sessionId: string;
  userAuthToken?: string;
}

export interface GeoPacket {
  geopacket: string;
  state: string;
  product: string;
}

export type GeolocationCallbackSuccess = BinaryFn<
  GeoLocationResponseSuccess,
  string,
  void
>;

export type GeolocationCallbackFailure = UnaryFn<
  GeolocationFailureResponse,
  void
>;

export interface GeolocationFailureResponse {
  code: GeoClientErrorCodes;
  message: GeoClientErrorMessages;
}

export type GeolocationRejectionCallback = BinaryFn<
  GeoLocationResponseRejected,
  string,
  void
>;

export interface GeolocationCallbacks {
  onSuccess: GeolocationCallbackSuccess;
  onFailed: GeolocationCallbackFailure;
  onReject: GeolocationRejectionCallback;
  onProcess: (isProcessing?: boolean) => void;
  onRetry: (attemptsCounter?: number) => void;
  onRefresh: GeolocationCallbackSuccess;
}

export enum GeolocationReason {
  LOGIN = "Login",
  PERIODIC = "PERIODIC"
}

export interface GeolocationRejection {
  message: string;
  troubleshooter: Array<Troubleshooter>;
  state?: string;
}

export enum GeoLocationLicenseType {
  SOLUS = "SOLUS",
  GEOLOCATION = "GEO"
}

export interface GeoLocationConfig {
  state: string;
  product: "RACING";
  licenseType: GeoLocationLicenseType;
}

export enum GeoStorageKey {
  LICENSE = "X-Geo-License",
  GEO_PACKET = "X-Geo-Packet",
  RELOCATE_ID = "GEO_RELOCATE_PROCESS_ID"
}

export enum GeoClientFields {
  SESSION_ID = "session_id",
  PREVIOUS_TOKEN_STATUS = "previousTokenStatus"
}

export enum GeoClientTokenStatus {
  NO_TOKEN = "NO_TOKEN",
  SUCCESSFUL_TOKEN = "successful_token",
  FAILED_TOKEN = "failed_token"
}

export enum GeolocationCustomErrorCodes {
  USER_UNAUTHORIZED = 1401,
  INVALID_LICENSE = 1606,
  UNAUTHORIZED_LOCATION_PERMISSION = 1801
}

export enum GeolocationCustomErrorMessages {
  USER_UNAUTHORIZED = "User Unauthorized",
  INVALID_LICENSE = "Error trying to get license",
  UNAUTHORIZED_LOCATION_PERMISSION = "Browser location unauthorized"
}

export interface GeoComplyCache {
  saveCache: UnaryFn<string, void>;
  removeCache: NullaryFn<void>;
  getCache: NullaryFn<string>;
}

export type GeoComplyCacheEntries = Record<GeoStorageKey, GeoComplyCache>;

export type GeolocationRelocateUserCallback = UnaryFn<
  GeoClientTokenStatus,
  void
>;
