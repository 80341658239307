import React, { SetStateAction, useEffect, useState } from "react";
import { get, attempt, isError } from "lodash";
import {
  AccountNumber,
  PaymentType,
  PaymentTypeOption,
  ErrorMessage
} from "@tvg/wallet/src/types";
import pawsService from "@tvg/api/paws";
import { useDispatch, useSelector, batch } from "react-redux";
import { toggleQuickDepositModal } from "@tvg/sh-lib-paws/redux/slices/quickDepositModalSlice";
import { openExternalLink } from "@tvg/utils/mobileUtils";
import { AnyAction, Dispatch } from "redux";
import { PaymentTypesAvailable } from "@tvg/api/paws/types";
import { getAccountNumber } from "@urp/store-selectors";
import { openDepositFundsModal } from "../redux/slices/depositFundsModalSlice";
import { getSelectedPaymentMethod } from "../redux/selectors";
import { setSelectedPaymentMethod } from "../redux/slices/paymentMethodSelectorSlice";

type Payload =
  | { isQuickDeposit: boolean; isRedirectFromMZM?: boolean }
  | null
  | boolean;

export type CurrentMzmTab = { close: () => void; closed: boolean } | null;
export type CurrentMzmPermissions = { required: boolean; url: string } | null;

const openMazoomaPage = (
  mazoomaUrl: string,
  setTransactionData: React.Dispatch<SetStateAction<{}>>,
  dispatch: Dispatch<AnyAction>,
  setValue: React.Dispatch<SetStateAction<string>>,
  setIsLoading: React.Dispatch<boolean>,
  setMazoomaStatus: React.Dispatch<SetStateAction<boolean>>,
  setCurrentMzmTab: React.Dispatch<SetStateAction<CurrentMzmTab>>,
  setCurrentMzmPermissions: React.Dispatch<
    SetStateAction<CurrentMzmPermissions>
  >
) => {
  if (typeof window !== "undefined" && mazoomaUrl) {
    window.localStorage.setItem("isMzmTabClosed", "false");

    openExternalLink(mazoomaUrl, (tab: Window) => {
      // Browser requires the user to allow popups
      if (!tab || tab.closed || typeof tab.closed === "undefined") {
        setCurrentMzmPermissions({ required: true, url: mazoomaUrl });
      } else {
        setCurrentMzmTab(tab);
      }
    });

    const transactionData = attempt(() =>
      JSON.parse(sessionStorage.getItem("transactionData") || "")
    );

    setTransactionData(transactionData);

    const accountOnFile = attempt(() => JSON.parse(transactionData.method));

    if (accountOnFile) {
      const payload: Payload = transactionData.isQuickDeposit
        ? { isQuickDeposit: true, isRedirectFromMZM: true }
        : isError(accountOnFile.id);

      batch(() => {
        setValue(transactionData.amount);
        dispatch(setSelectedPaymentMethod(accountOnFile));
        dispatch(toggleQuickDepositModal(transactionData.isQuickDeposit));
        dispatch(openDepositFundsModal(payload));
      });
    }

    batch(() => {
      setIsLoading(true);
      setMazoomaStatus(true);
    });
  }
};

export const fetchMazoomaUrl = async (
  paymentType: PaymentType,
  accountId: string,
  value: string,
  setRequestMazooma: React.Dispatch<boolean>,
  setIsLoading: React.Dispatch<boolean>,
  accountOnFile: string | null,
  selectedMethod: PaymentTypeOption,
  isQuickDeposit: boolean = false,
  setErrorMessage: React.Dispatch<SetStateAction<ErrorMessage>>,
  setTransactionData: React.Dispatch<SetStateAction<{}>>,
  setValue: React.Dispatch<SetStateAction<string>>,
  setMazoomaStatus: React.Dispatch<SetStateAction<boolean>>,
  dispatch: Dispatch<AnyAction>,
  setCurrentMzmTab: React.Dispatch<SetStateAction<CurrentMzmTab>>,
  setCurrentMzmPermissions: React.Dispatch<
    SetStateAction<CurrentMzmPermissions>
  >
) => {
  setIsLoading(true);
  try {
    const base = get(window, "location.origin", "https://www.tvg.com");
    const pathname = get(window, "location.pathname", "/wallet");
    const search = get(window, "location.search", "");

    const returnUrl =
      base +
      pathname +
      search +
      (search ? "&" : "?") +
      "notFollowApp=true#closeTab";

    const data = {
      accountId,
      amount: value,
      returnUrl
    };

    const {
      data: { redirectUrl, transactionUUID }
    } =
      !accountOnFile || accountOnFile === paymentType
        ? await pawsService.createWithDeposit(
            paymentType as PaymentTypesAvailable,
            { ...data }
          )
        : await pawsService.postDeposit(paymentType as PaymentTypesAvailable, {
            ...data,
            accountOnFile
          });

    sessionStorage.setItem(
      "transactionData",
      JSON.stringify({
        amount: value,
        uuid: transactionUUID,
        method: JSON.stringify(selectedMethod),
        isQuickDeposit
      })
    );

    openMazoomaPage(
      redirectUrl as string,
      setTransactionData,
      dispatch,
      setValue,
      setIsLoading,
      setMazoomaStatus,
      setCurrentMzmTab,
      setCurrentMzmPermissions
    );

    setRequestMazooma(false);
  } catch (err) {
    const errorCode = get(err, "response.data.code", "");

    setErrorMessage((prevState) => ({
      ...prevState,
      isOpen: true,
      errorCode,
      isRetry: false
    }));

    setIsLoading(false);
    setRequestMazooma(false);
  }
};

export const useMazoomaDeposit = (
  paymentType: PaymentType,
  value: string,
  setIsLoading: React.Dispatch<boolean>,
  selectedPaymentMethodId: string | null,
  setValue: React.Dispatch<SetStateAction<string>>,
  isQuickDeposit: boolean = false,
  setErrorMessage: React.Dispatch<SetStateAction<ErrorMessage>>,
  setCurrentMzmTab: React.Dispatch<SetStateAction<CurrentMzmTab>>
) => {
  const [transactionData, setTransactionData] = useState({});
  const [requestMazooma, setRequestMazooma] = useState(false);
  const [showMazoomaStatus, setMazoomaStatus] = useState<boolean>(false);
  const [currentMzmPermissions, setCurrentMzmPermissions] =
    useState<CurrentMzmPermissions>(null);
  const accountId: AccountNumber = useSelector(getAccountNumber);

  const selectedMethod = useSelector(getSelectedPaymentMethod);

  const enableMazoomaSubscription: boolean = useSelector((store) =>
    get(store, "capi.featureToggles.pawsMazoomaSubscription", false)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (requestMazooma) {
      fetchMazoomaUrl(
        paymentType,
        accountId,
        value,
        setRequestMazooma,
        setIsLoading,
        selectedPaymentMethodId,
        selectedMethod,
        isQuickDeposit,
        setErrorMessage,
        setTransactionData,
        setValue,
        setMazoomaStatus,
        dispatch,
        setCurrentMzmTab,
        setCurrentMzmPermissions
      );
    }
  }, [requestMazooma]);

  return {
    setRequestMazooma,
    showMazoomaStatus,
    setMazoomaStatus,
    transactionData,
    enableMazoomaSubscription,
    currentMzmPermissions,
    setCurrentMzmPermissions
  } as const;
};
