import React from "react";

import { TermsAndConditionsText } from "./styled-components";
import { Props } from "./types";

const TermsAndConditions = ({ termsAndConditionsContent, qaLabel }: Props) => (
  <TermsAndConditionsText
    data-qa-label={qaLabel}
    dangerouslySetInnerHTML={{
      __html: termsAndConditionsContent
    }}
  />
);

export default TermsAndConditions;
