import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";
import Button from "@tvg/atomic-ui/_atom/Buttons/button";

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  background-color: ${buildColor("white", "100")};
  border-radius: 4px 4px 0 0;
`;

export const Container = styled.div<{
  isMobile: boolean;
  isTermsOpen: boolean;
}>`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: ${({ isMobile }) => (isMobile ? "12px" : "20px")};
  margin-top: ${({ isMobile, isTermsOpen }) =>
    !isMobile && isTermsOpen ? "18px" : 0};
`;

export const IlustrationWrapper = styled.div`
  padding-bottom: 24px;
  width: 100%;
`;

export const LastInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  @media screen and (max-width: 360px) {
    flex-direction: column;
  }

  & > div:last-child {
    padding-left: 4px;
    flex: 1;

    @media screen and (max-width: 360px) {
      padding-left: 0;
      margin-top: 10px;
    }
  }

  & > div:first-child {
    padding-right: 4px;
    flex: 1;
  }
`;

export const TermsAndConditionsCTA = styled.p`
  color: ${buildColor("grey", "800")};
  margin-top: 16px;
  margin-bottom: 4px;
  text-align: center;

  & > span {
    font-size: 12px;
    line-height: 17px;
    font-family: ${fontNormal};
    font-weight: 400;
    vertical-align: center;
  }
`;

// TODO: hack to remove opacity without redoing whole 'Button' component
export const ButtonSubmit = styled(Button)`
  ${({ removeOpacity }) => removeOpacity && "opacity: 1"}
`;

export const TermsAndConditionsContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${buildColor("white", "100")};
  padding: 12px;
`;
