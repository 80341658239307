import { MarginProps } from "styled-system";

export type Brand = "4njbets" | "iowa" | "tvg" | "fdr" | "fdr-iowa";

export interface LogoProps extends MarginProps {
  /**
   * Set custom height
   */
  height?: number;
  /**
   * Set TVG lettering to white
   */
  isDarkMode?: boolean;
  /**
   * Brand
   */
  brand?: Brand;
  /**
   * Show only illustration
   */
  isOnlyIllustration?: boolean;
  /**
   * Set aria-label
   */
  accessibilityLabel?: string;
  /**
   * Custom data-qa-label
   */
  qaLabel?: string;
}

export enum DEFAULTS {
  height = 18,
  widthComplete = 103,
  widthIllustration = 33
}

export enum TYPE_LOGO {
  illustration = "illustration",
  complete = "complete"
}

export type Dimension = {
  dimension: {
    viewBox: string;
    height: number;
    width: number;
  };
};
