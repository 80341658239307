export type StateType = {
  abbreviation: string;
  name: string;
  wageringState: boolean;
};

export default [
  { abbreviation: "AL", name: "Alabama", wageringState: false },
  { abbreviation: "AK", name: "Alaska", wageringState: false },
  { abbreviation: "AZ", name: "Arizona", wageringState: true },
  { abbreviation: "AR", name: "Arkansas", wageringState: true },
  { abbreviation: "CA", name: "California", wageringState: true },
  { abbreviation: "CO", name: "Colorado", wageringState: true },
  { abbreviation: "CT", name: "Connecticut", wageringState: true },
  { abbreviation: "DE", name: "Delaware", wageringState: true },
  { abbreviation: "DC", name: "District of Columbia", wageringState: false },
  { abbreviation: "FL", name: "Florida", wageringState: true },
  { abbreviation: "GA", name: "Georgia", wageringState: false },
  { abbreviation: "HI", name: "Hawaii", wageringState: false },
  { abbreviation: "ID", name: "Idaho", wageringState: true },
  { abbreviation: "IL", name: "Illinois", wageringState: true },
  { abbreviation: "IN", name: "Indiana", wageringState: true },
  { abbreviation: "IA", name: "Iowa", wageringState: true },
  { abbreviation: "KS", name: "Kansas", wageringState: false },
  { abbreviation: "KY", name: "Kentucky", wageringState: true },
  { abbreviation: "LA", name: "Louisiana", wageringState: true },
  { abbreviation: "ME", name: "Maine", wageringState: false },
  { abbreviation: "MD", name: "Maryland", wageringState: true },
  { abbreviation: "MA", name: "Massachusetts", wageringState: true },
  { abbreviation: "MI", name: "Michigan", wageringState: true },
  { abbreviation: "MN", name: "Minnesota", wageringState: true },
  { abbreviation: "MS", name: "Mississippi", wageringState: false },
  { abbreviation: "MO", name: "Missouri", wageringState: false },
  { abbreviation: "MT", name: "Montana", wageringState: true },
  { abbreviation: "NE", name: "Nebraska", wageringState: false },
  { abbreviation: "NV", name: "Nevada", wageringState: false },
  { abbreviation: "NH", name: "New Hampshire", wageringState: true },
  { abbreviation: "NJ", name: "New Jersey", wageringState: true },
  { abbreviation: "NM", name: "New Mexico", wageringState: true },
  { abbreviation: "NY", name: "New York", wageringState: true },
  { abbreviation: "NC", name: "North Carolina", wageringState: false },
  { abbreviation: "ND", name: "North Dakota", wageringState: true },
  { abbreviation: "OH", name: "Ohio", wageringState: true },
  { abbreviation: "OK", name: "Oklahoma", wageringState: false },
  { abbreviation: "OR", name: "Oregon", wageringState: true },
  { abbreviation: "PA", name: "Pennsylvania", wageringState: true },
  { abbreviation: "RI", name: "Rhode Island", wageringState: true },
  { abbreviation: "SC", name: "South Carolina", wageringState: false },
  { abbreviation: "SD", name: "South Dakota", wageringState: true },
  { abbreviation: "TN", name: "Tennessee", wageringState: true },
  { abbreviation: "TX", name: "Texas", wageringState: false },
  { abbreviation: "UT", name: "Utah", wageringState: false },
  { abbreviation: "VT", name: "Vermont", wageringState: true },
  { abbreviation: "VA", name: "Virginia", wageringState: true },
  { abbreviation: "WA", name: "Washington", wageringState: true },
  { abbreviation: "WV", name: "West Virginia", wageringState: true },
  { abbreviation: "WI", name: "Wisconsin", wageringState: false },
  { abbreviation: "WY", name: "Wyoming", wageringState: true }
];
