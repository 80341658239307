import styled, { css } from "styled-components/native";
import { StyledProps } from "./types";

export const BetTypesTouchable = styled.TouchableHighlight<StyledProps>`
  height: 100%;
  padding: ${({ theme }) =>
    `${theme.space["space-6"]} ${theme.space["space-4"]}`};
  border-top-color: ${({ theme }) => theme.colorTokens.background.surface};
  border-top-width: 2px;
  ${({ isHovered, theme }) =>
    isHovered &&
    css`
      background-color: ${theme.colorTokens.component.button.buttonLink
        .background.hover};
    `}

  ${({ isPressed, theme }) =>
    isPressed &&
    css`
      background-color: ${theme.colorTokens.component.input.pressed};
      border-bottom-color: ${theme.colorTokens.content.link};
      border-bottom-width: 2px;
    `}
  
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border-bottom-color: ${theme.colorTokens.content.link};
      border-bottom-width: 2px;
    `};
`;

export const BetTypesText = styled.Text<StyledProps>`
  color: ${({ theme }) => theme.colorTokens.content.default};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${({ theme }) => theme.fontSizes.s};
  text-align: center;
  ${({ isSelected, isPressed, theme }) =>
    (isSelected || isPressed) &&
    css`
      color: ${theme.colorTokens.content.link};
    `};
`;
