export enum BetTypeCodesEnum {
  EXACTA = "EX",
  EXACTA_KEY_BOX = "EKB",
  PLACE = "PL",
  SHOW = "SH",
  SUPERFECTA = "SU",
  SUPERHIGHFIVE = "SH5",
  TRIFECTA = "TR",
  WIN = "WN",
  WIN_PLACE = "WP",
  PLACE_SHOW = "PS",
  WIN_SHOW = "WS",
  WIN_PLACE_SHOW = "WPS"
}

export enum BetTypeCodesReversedEnum {
  EX = "Exacta",
  EKB = "Exacta/Key/Box",
  PL = "Place",
  SH = "Show",
  SU = "Superfecta",
  SH5 = "Super high five",
  TR = "Trifecta",
  WN = "Win",
  WP = "Win/Place",
  WPS = "Win/Place/Show"
}
