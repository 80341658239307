import { useEffect } from "react";
import usePrevious from "./usePrevious";
import useForceUpdate from "./useForceUpdate";

// Custom hook to handle Modal js file on each ModalV2 from MEP and FDR
export const useModalV2Resize = (isOpen: boolean) => {
  const forceUpdate = useForceUpdate();
  const previousIsOpen = usePrevious(isOpen);

  const onResize = () => {
    setTimeout(() => {
      forceUpdate();
    }, 100);
  };

  useEffect(() => {
    if (!previousIsOpen && isOpen && typeof window !== "undefined") {
      window.addEventListener("resize", onResize);
      // major hack for for resize on ios
      setTimeout(() => {
        forceUpdate();
      }, 200);
    } else if (previousIsOpen && !isOpen && typeof window !== "undefined") {
      window.removeEventListener("resize", onResize);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", onResize);
      }
    };
  }, [isOpen]);
};

export default useModalV2Resize;
