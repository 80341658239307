import React, { createContext, useContext } from "react";
import tvgConf from "@tvg/conf";
import { Devices } from "../theming/types";

export interface TvgConfProps {
  device: Devices;
  brand: string;
  product: string;
}

export interface TvgConfProviderProps extends Partial<TvgConfProps> {
  children: JSX.Element;
}

const initialState: TvgConfProps = {
  device: "mobile",
  brand: "tvg",
  product: "touch3"
};

export const TvgConfContext = createContext<TvgConfProps>(initialState);

export const TvgConfProvider = ({
  children,
  ...overrides
}: TvgConfProviderProps) => (
  <TvgConfContext.Provider
    value={{
      device: tvgConf().device,
      brand: tvgConf().brand,
      product: tvgConf().product,
      ...overrides
    }}
  >
    {children}
  </TvgConfContext.Provider>
);

export const useTvgConfContext = () => useContext(TvgConfContext);
export default TvgConfProvider;
