// @ts-ignore
import { createElement, View } from "react-native";
import styled from "styled-components/native";

import type { OptionsProps, SelectProps } from "../types";

export const SelectElement = (props: SelectProps) =>
  createElement("select", props);

export const OptionElement = (props: OptionsProps) =>
  createElement("option", props);

export const Select = styled(SelectElement)<
  Pick<SelectProps, "fonts" | "fontsizes" | "colors">
>`
  appearance: none;
  height: 42px;
  background-color: transparent;
  border: transparent;
  z-index: 1;
  padding-left: 12px;
  padding-right: 36px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ fonts }) => fonts.regular};
  font-size: ${({ fontsizes }) => fontsizes.s};
  color: ${({ disabled, colors, value, placeholder }) =>
    disabled || (!value && placeholder) ? colors.grey[700] : colors.grey[900]};
`;

export const Option = styled(OptionElement)<OptionsProps>`
  font-family: ${({ fonts }) => fonts.regular};
`;

export const SelectWrapper = styled(View)`
  margin-top: ${({ theme }) => theme.space["space-5"]};
  padding: ${({ theme }) => theme.space["space-4"]};
  width: 100%;
  box-shadow: 0 2px 2px rgba(17, 43, 68, 0.15);
  border-color: blue;
  border-radius: 2px;
`;

export const SelectIconWrapper = styled(View)<{
  isDisabled: boolean;
  isMobile?: boolean;
}>`
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 5px;
  ${({ isMobile, theme }) =>
    isMobile &&
    `
     top: ${theme.space["space-2"]};
   `};
  border-radius: 100px;
  height: ${({ theme }) => theme.space["space-8"]};
  width: ${({ theme }) => theme.space["space-8"]};
`;

export const RnSelectWrapper = styled(View)`
  align-items: center;
  justify-content: center;
  padding-left: ${({ theme }) => theme.space["space-4"]};
  flex-grow: 1;
  height: 42px;
`;
