import React from "react";
import {
  NullaryFn,
  OnFileEmptyContainer,
  PaymentTypeOption
} from "@tvg/wallet/src/types";
import {
  getPaymentMethodIconName,
  isCardExpired,
  shouldCapitalizeTitle
} from "@tvg/wallet/src/utils";
import { DialogMessage } from "@tvg/design-system";

import ListItem from "../../ListItem";
import { EmptyPaymentContainer } from "../styled-components";

export const renderListItems = (
  account: PaymentTypeOption,
  clickFunction: NullaryFn<void> | undefined,
  isDisabled: boolean,
  isUnderMaintenance: boolean
) => {
  const qaLabel = `list-item-${account.paymentType}-${account.id}`;

  return (
    <ListItem
      iconName={getPaymentMethodIconName(account.paymentType)}
      onClick={clickFunction}
      key={`saved-payment-methods-${account.id}`}
      {...account}
      isUnderMaintenance={isUnderMaintenance}
      isDisabled={
        isCardExpired(account.expirationDate) ||
        !clickFunction ||
        isDisabled ||
        isUnderMaintenance
      }
      isExpired={isCardExpired(account.expirationDate)}
      isCapitalize={shouldCapitalizeTitle(account.paymentType)}
      qaLabel={qaLabel}
    />
  );
};

export const renderEmptyState = (emptyContainer: OnFileEmptyContainer) => (
  <EmptyPaymentContainer>
    <DialogMessage
      variant="info"
      title={emptyContainer.title}
      description={emptyContainer.description}
    />
  </EmptyPaymentContainer>
);
