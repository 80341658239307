import React, { memo, forwardRef } from "react";
import { calculateBadgeWidth } from "./utils";
import { BadgeProps, BadgeCounterProps, BadgeCompoundComponent } from "./types";
import { BadgeCounter } from "./components";
import {
  WrapperContainer,
  Positioner,
  ChildrenContainer
} from "./styled-components";

export const Badge = memo(
  forwardRef<HTMLDivElement, BadgeProps>(
    (
      {
        children,
        className,
        qaLabel = "badge-container",
        ...badgeInnerProps
      }: BadgeProps,
      ref
    ) => (
      <WrapperContainer className={className} data-qa-label={qaLabel}>
        <ChildrenContainer>
          <Positioner
            badgeWidth={calculateBadgeWidth(
              badgeInnerProps.counter,
              badgeInnerProps.counterLimit
            )}
          >
            <BadgeCounter {...badgeInnerProps} ref={ref} />
          </Positioner>
          {children}
        </ChildrenContainer>
      </WrapperContainer>
    )
  )
) as BadgeCompoundComponent<typeof BadgeCounter>;

Badge.Counter = BadgeCounter;

export { BadgeProps, BadgeCounterProps };
export default Badge;
