import styled from "styled-components";

export const Container = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 28px;
`;
