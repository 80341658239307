import styled, { css } from "styled-components";
import { space } from "styled-system";

export const ButtonBarContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  padding: 2px;
  ${({ theme }) => css`
    gap: ${theme.space["space-1"]};
    background-color: ${theme.colorTokens.background.surface};
    border: 1px solid ${theme.colorTokens.border.default};
    border-radius: ${theme.radii.s};
  `}
  ${space}
`;
