// @flow

import styled, { keyframes } from "styled-components";
import buildColor from "../../_static/ColorPalette";

const ScaleAnimation = keyframes`
  0% { 
    transform: scale(0);
    opacity: 0;
  }
  
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const FadeAnimation = keyframes`
  0% { 
    opacity: 0;    
  }
  
  100% {
    opacity: 1;    
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.overlayBgColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${buildColor("white", "100")};
  font-weight: bold;
  font-size: 16px;
  animation: ${FadeAnimation} 0.5s ease-in-out;
  z-index: 9999;
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-bottom: 16px;
`;

export const Success = styled(Circle)`
  background-color: ${buildColor("green", "500")};
  animation: ${ScaleAnimation} 0.5s;
`;

export const Error = styled(Circle)`
  background-color: ${buildColor("red", "500")};
  animation: ${ScaleAnimation} 0.5s;
`;

export const Warning = styled(Circle)`
  background-color: ${buildColor("yellow", "500")};
  animation: ${ScaleAnimation} 0.5s;
`;
