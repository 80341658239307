import React from "react";
import { buildColor } from "@tvg/design-system";
import { getDeleteTypeByPaymentType, canMethodBeDeleted } from "../../utils";
import ListItem from "../ListItem";
import { Container } from "./styled-components";
import { Props } from "./types";
import { onDelete } from "./eventHandlers";

const AccountsOnFileListOptionsModal = ({
  id,
  paymentType,
  depositsAvailable,
  withdrawalsAvailable,
  paymentMethod,
  dispatch,
  onClick
}: Props) => (
  <Container>
    {depositsAvailable && (
      <ListItem
        iconName="quickDeposit"
        title="Deposit"
        onClick={() => onClick(paymentType, "deposit", id)}
      />
    )}

    {withdrawalsAvailable && (
      <ListItem
        iconName="circleWithdraw"
        title="Withdraw"
        onClick={() => onClick(paymentType, "withdraw", id)}
      />
    )}
    {
      // TODO: Remove this condition to enable delete option for all accountsOnFile
      canMethodBeDeleted(paymentType) && !!id && (
        <ListItem
          iconName="trash"
          title={`Delete ${getDeleteTypeByPaymentType(paymentType)}`}
          onClick={onDelete(dispatch, paymentType, paymentMethod)}
          iconColor={buildColor("red", "500")}
          textColor={buildColor("red", "500")}
        />
      )
    }
  </Container>
);

export default AccountsOnFileListOptionsModal;
