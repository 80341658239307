import { Dispatch } from "@reduxjs/toolkit";
import { openDeleteCardModal } from "@tvg/sh-lib-paws/redux/slices/deleteCardModalSlice";
import { gtmDeletePaymentMethodButton } from "../../gtm";
import { PaymentType, PaymentTypeOption } from "../../types";

export const onDelete =
  (
    dispatch: Dispatch,
    paymentType: PaymentType,
    paymentMethod: PaymentTypeOption
  ) =>
  () => {
    gtmDeletePaymentMethodButton({ paymentType });
    dispatch(openDeleteCardModal({ ...paymentMethod, paymentType }));
  };
