import type {
  Context,
  Brand,
  Product,
  Device,
  GraphBrand,
  GraphProduct,
  GraphDevice
} from "./types";

const productMap = {
  tvg4: "TVG4",
  tvg5: "TVG5",
  touch2: "Touch",
  touch3: "Touch3",
  ios2: "IOS2",
  ios: "ios",
  exchange: "exchange",
  login: "login",
  fdrmobile: "FDRMOBILE",
  fdrios: "FDRIOS",
  fdriosxsell: "FDRIOS",
  fdrandroid: "FDRANDROID",
  fdrandroidgps: "FDRANDROID",
  fdrandroidxsell: "FDRANDROID",
  iosnative: "iosnative",
  androidnative: "androidnative",
  androidwrapper: "ANDROIDWRAPPER",
  tvgandroid: "TVGANDROID"
};

const deviceMap = { desktop: "Desktop", mobile: "Mobile", tablet: "Mobile" };

const brandMap = {
  "4njbets": "4NJBets",
  pabets: "PABets",
  iowa: "IA",
  tvg: "TVG",
  fdr: "FDR"
};

export default (brand: Brand, product: Product, device: Device): Context => ({
  brand: brandMap[brand] as GraphBrand,
  product: productMap[product] as GraphProduct,
  device: deviceMap[device] as GraphDevice
});
