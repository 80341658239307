import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import {
  fontNormal,
  fontMedium,
  fontCondensedNormal
} from "@tvg/atomic-ui/_static/Typography";

export const Container = styled.div<{
  isMobile: boolean;
  hasPayNearMeIframe?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${buildColor("white", "100")};
  padding-bottom: ${({ isMobile }) => (isMobile ? "16px" : "20px")};
  ${({ hasPayNearMeIframe }) =>
    hasPayNearMeIframe &&
    css`
      padding-bottom: 0;
    `}

  .success-message {
    padding-bottom: 0;
  }
`;

export const Main = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  padding: 0 12px;

  .payment-selector,
  .custom-keyboard,
  .preview-withdraw,
  .cvv-field,
  .summary {
    margin-bottom: 16px;
  }

  .flag {
    margin-top: 4px;
  }

  .moneyPills {
    margin-bottom: 52px;
  }

  .code-field {
    margin-bottom: 8px;
  }

  .loader {
    margin-top: 20px;
    align-self: center;
  }
`;

export const ValueContainer = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PromosOnboardingFlagWrapper = styled.div`
  margin-top: 4px;
  margin-left: 4px;
`;

export const FlagsContainer = styled.div`
  display: flex;
`;

export const PaymentMethodTitle = styled.p`
  font-family: ${fontMedium};
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${buildColor("grey", "900")};
  padding-top: 16px;
  margin-bottom: 8px;
  border-top: 1px solid #d6e3f0;
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  box-shadow: inset 11px 0 0 ${buildColor("white", "100")},
    inset 0 -1px 0 ${buildColor("blue", "100")};
`;

export const LocationText = styled.div`
  flex: 1;
  margin: 0 8px;
`;

export const LocationLink = styled.a`
  display: flex;
`;

export const MoneyPakDescription = styled.div`
  margin-top: 20px;
  color: ${buildColor("grey", "800")};
  font-family: ${fontCondensedNormal};
  font-size: 12px;
  text-align: center;

  p {
    margin-bottom: 14px;
  }
`;

export const LogoPayNearMe = styled.img`
  display: block;
  margin: 20px auto 0;
`;

export const PayNearMeDescription = styled.p`
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 18px;
  color: ${buildColor("grey", "900")};
  font-family: ${fontNormal};
  font-size: 14px;
  text-align: center;
`;

export const PayNearMeInfos = styled.div<{ isMobile: boolean }>`
  margin: ${({ isMobile }) => (isMobile ? "35" : "20")}px 0 10px;
  line-height: 18px;
  color: ${buildColor("grey", "900")};
  font-family: ${fontNormal};
  font-size: 14px;
  text-align: center;

  p {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: ${({ isMobile }) => (isMobile ? "32" : "40")}px;
    }
  }
`;

export const PayNearMeLinkFAQ = styled.span`
  color: ${buildColor("blue_accent", "500")};
  cursor: pointer;
  vertical-align: baseline;
`;

export const PayNearMeIframe = styled.iframe<{ isLoading: boolean }>`
  flex: 1;
  min-height: 450px;
  ${({ isLoading }) =>
    isLoading &&
    css`
      display: none;
    `}
`;

export const PayNearMeFAQ = styled.div``;

export const StoresWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StoreDescription = styled.p`
  font-family: ${fontNormal};
  font-size: 14px;
  text-align: center;
  color: ${buildColor("grey", "800")};
  margin-top: 4px;
`;

export const PayNearMeFooter = styled.div`
  padding: 0 12px;
`;

export const NoPaymentMethodsAddedContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-grow: 1;
  background-color: ${buildColor("white", "100")};
  ${({ isMobile }) =>
    !isMobile &&
    css`
      height: 384px;
    `};

  & > div:first-child {
    height: calc(100% - ${({ isMobile }) => (isMobile ? "118px" : "62px")});
  }

  button {
    width: calc(100% - 24px);
    position: absolute;
    left: 12px;
    bottom: ${({ isMobile }) => (isMobile ? "76px" : "20px")};
  }
`;
