import styled from "styled-components/native";
import { View } from "react-native";
import { themeGet } from "../../theming";
import { space } from "../../theming/styledSystem";
import { BadgeWidthProps } from "./types";

export const WrapperContainer = styled(View)`
  margin: ${themeGet("space.space-3")};
  flex-direction: row;
  position: relative;
  ${space}
`;

export const ChildrenContainer = styled(View)`
  position: relative;
  width: auto;
`;

export const Positioner = styled(View)<BadgeWidthProps>`
  position: absolute;
  flex-wrap: wrap;
  top: -20%;
  right: -${(props) => props.badgeWidth * 0.5}px;
  min-width: ${(props) => props.badgeWidth}px;
  elevation: 1;
  z-index: 1;
`;
