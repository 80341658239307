import styled, { css } from "styled-components";
import { space, width } from "styled-system";
import type { SegmentSize } from "./types";

export const sizesMap = {
  s: {
    height: 32
  },
  m: {
    height: 40
  },
  l: {
    height: 52
  }
};

export const StyledComponent = styled.div<{
  isDisabled: boolean;
  size: SegmentSize;
}>`
  display: flex;
  overflow: hidden;
  position: relative;
  height: ${({ size }) => sizesMap[size].height}px;
  border-radius: 68px;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: var(
    --fd-colors-component-selection-control-background-disabled
  );
  padding: 12px;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.4;
    `}
  ${space};
  ${width};
`;

export const SegmentTab = styled.button`
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
  display: flex;
  padding: 0;
  flex: 1;
  z-index: 1;
`;

export const SegmentTabContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;

export const SegmentText = styled.p<{
  isSelected?: boolean;
  isDisabled?: boolean;
}>`
  font-size: 14px;
  font-weight: 500;
  line-height: 125%;
  color: ${({ isSelected, isDisabled }) =>
    isSelected && !isDisabled
      ? "var(--fd-colors-content-default)"
      : "var(--fd-colors-system-neutral-content-accent)"};
  user-select: none;
`;
