import React from "react";
import { IconPathProps } from "../../types";

export const DoubleChevronUp = ({ lineColor, strokeWidth }: IconPathProps) => (
  <path
    d="M4.5 12L12 4.5L19.5 12M4.5 19.5L12 12L19.5 19.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
