import { get, merge } from "lodash";
import { StoryblokDraft, StoryblokStep } from "../types/storyblok";
import { fetchStoryblok } from "./fetchStoryblok";
import formatPromoStruct from "../helpers/formatPromoStruct";
import formatStoryblokComponents from "../helpers/formatStoryblokComponents";
import { PromoAcquisition } from "../types/promos";
import { PromoOnboarding } from "../types/promoOnboarding";
import { PromoStepType } from "../types/promoSteps";

export const getPromoOnboarding = async (
  promosAcquisition: PromoAcquisition,
  previousRootId?: number,
  draft?: StoryblokDraft
): Promise<PromoOnboarding> => {
  const { promotionId } = promosAcquisition;
  const components = formatPromoStruct(promosAcquisition, previousRootId);
  const storyblokResponse = await fetchStoryblok(promotionId, draft);
  const storyblokSteps: Array<StoryblokStep> = get(
    storyblokResponse,
    "data.story.content.steps",
    []
  );
  const storyblokComponents = formatStoryblokComponents(storyblokSteps, [
    ...Object.keys(components.steps),
    PromoStepType.PAYOUT
  ]);

  return merge(components, storyblokComponents);
};
