import { get } from "lodash/fp";
import APPEventEmitter, { isAndroid } from "./EventEmitters/APP";
import DOMEventEmitter from "./EventEmitters/DOM";
import NodeEventEmitter from "./EventEmitters/Node";
import BaseEventEmitter from "./EventEmitters/Base";
import type { EventEmitterInterface } from "./Types";
import { isBrowser, isNativeApp } from "./MediatorUtils";

type Channels = {
  ios: EventEmitterInterface;
  browser: EventEmitterInterface;
  node: EventEmitterInterface;
  base: EventEmitterInterface;
};

export default class Mediator {
  channels: Channels;

  constructor() {
    this.channels = {
      ios:
        (Mediator.isAPP() && new APPEventEmitter()) || new BaseEventEmitter(),
      browser:
        (Mediator.isBrowser() && new DOMEventEmitter()) ||
        new BaseEventEmitter(),
      node:
        (Mediator.isNode() && new NodeEventEmitter()) || new BaseEventEmitter(),
      base: new BaseEventEmitter()
    };
  }

  static isNode() {
    return (
      typeof process !== "undefined" && get("release.name", process) === "node"
    );
  }

  // This function is duplicated in ./EventEmitters/Base.js
  static isBrowser() {
    // Not perfect
    return isBrowser();
  }

  // This function is duplicated in ./EventEmitters/Base.js
  static isNativeApp() {
    return isNativeApp();
  }

  static isAPP() {
    return (
      Mediator.isNativeApp() ||
      (Mediator.isBrowser() &&
        isAndroid(get("__TVG_GLOBALS__.PRODUCT", window))) ||
      (Mediator.isBrowser() &&
        !!get("webkit.messageHandlers", window) &&
        !window.navigator.userAgent.match("CriOS"))
    );
  }
}
