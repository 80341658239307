import React, { FC } from "react";

export const France: FC = () => (
  <>
    <g clipPath="url(#clip0_18106_2879)">
      <path d="M21.4097 0H10.5673V20H21.4097V0Z" fill="#F7FBFF" />
      <path
        d="M0.0458341 0H0V20H0.0458341H10.5673V0H0.0458341Z"
        fill="#004EA3"
      />
      <path d="M21.4327 0V20H32V0H21.4327Z" fill="#D22839" />
    </g>
    <defs>
      <clipPath id="clip0_18106_2879">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V18C32
          19.1046 31.1046 20 30 20H2C0.895431 20 0 19.1046 0 18V2Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </>
);
