import { PaymentTypeItem } from "../../../types";

export const hasNoBalance = (
  withdrawalLimit: number,
  capiMaxWithdrawAmount: number
) => withdrawalLimit === 0 && capiMaxWithdrawAmount === 0;

export const hasOnlyPlayableBalance = (
  withdrawalLimit: number,
  capiMaxWithdrawAmount: number
) => withdrawalLimit === 0 && capiMaxWithdrawAmount > 0;

export const hasMorePlayableBalance = (
  withdrawalLimit: number,
  capiMaxWithdrawAmount: number,
  accountsOnFile: PaymentTypeItem[]
) =>
  withdrawalLimit > 0 &&
  capiMaxWithdrawAmount > withdrawalLimit &&
  accountsOnFile.length > 0;
