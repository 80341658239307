import { attempt } from "./utils";
import type { Metadata } from "./types";
/* eslint-disable */
export default (): Metadata | false => {
  let metadata;
  if (
    typeof window !== "undefined" &&
    window.__TVG_GLOBALS__ &&
    window.__TVG_GLOBALS__.METADATA
  ) {
    const data = attempt(() =>
      JSON.parse(decodeURIComponent(window.__TVG_GLOBALS__.METADATA || ""))
    );
    if (!(data instanceof Error)) {
      metadata = data as Metadata;
    }
  }
  return metadata || false;
};
/* eslint-enable */
