import React from "react";
import { Store } from "redux";
import { useSelector } from "react-redux";
import { getPawsContent } from "@tvg/sh-lib-paws/redux/selectors";
import MessagePanel from "../MessagePanel";
import { Props } from "./types";
import { getAction } from "./utils";

import { PawsContent } from "../../types";

import { ErrorContainer } from "./styled-components";

const GenericErrorMessageContainer = ({
  onRetry,
  minHeight,
  retriesCount,
  setRetryCount
}: Props) => {
  const content = useSelector<Store, PawsContent>(getPawsContent);

  return (
    <ErrorContainer minHeight={minHeight}>
      <MessagePanel
        title={content.genericErrorMessage.title}
        description={content.genericErrorMessage.description}
        variant="error"
        actions={[getAction(retriesCount, content, onRetry, setRetryCount)]}
      />
    </ErrorContainer>
  );
};

export default GenericErrorMessageContainer;
