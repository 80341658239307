import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const CustomerSupport = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M3 11.3269L3.75 18L8.25 17.25V12L3 11.3269Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M20.254 17.9646L20.9966 11.3571L15.75 12V17.25L20.254 17.9646Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M3 11.3269C3 6.52099 7.02944 2.625 12 2.625C16.9706 2.625 21 6.52099
      21 11.3269L20.25 18L18.75 21L11.625 21.375M3 11.3269L3.75 18L8.25
      17.25V12L3 11.3269ZM15.75 17.25V12L20.9966 11.3571L20.254 17.9646L15.75
      17.25Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);

export default CustomerSupport;
