import styled, { keyframes } from "styled-components";
import { space, layout } from "styled-system";

const loadingAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledAnimatedContainer = styled.div`
  ${space}
  ${layout}
  width: max-content;
  height: max-content;
  animation: ${loadingAnimation} 1.8s ease-in-out infinite;
`;
