import { Dispatch } from "redux";
import { get } from "lodash";
import capi from "@tvg/api/capi";
import Poller from "@tvg/poller";
import { setPromoOnboardingPollerTime } from "@tvg/sh-lib-promos-onboarding/redux/actions";

export const PromoOnboardingTimerPoller = new Poller();
const POLLER_TIMER_PERIOD = 300000;

export const tryStopPromoOnboardingTimerPoller = () => {
  if (PromoOnboardingTimerPoller.isRunning()) {
    PromoOnboardingTimerPoller.stop();
  }
};

export const requestPromoOnboardingTime = (dispatch: Dispatch) =>
  capi
    .fetchPromoOnboardingPollerTime()
    .then((response: { data: { value: string } }) =>
      dispatch(setPromoOnboardingPollerTime(+get(response, "data.value", "60")))
    );

export const startPromoOnboardingTimerPoller = (dispatch: Dispatch) => {
  if (!PromoOnboardingTimerPoller.isRunning()) {
    PromoOnboardingTimerPoller.start(
      () => requestPromoOnboardingTime(dispatch),
      POLLER_TIMER_PERIOD
    );
  }
};
