import { get, replace } from "lodash";
import { PromoOnboarding } from "../types/promoOnboarding";
import { PromoOnboardingStep } from "../types/promoSteps";
import { getValueFromProposition } from "../utils/getValueFromProposition";

const variableRegex = new RegExp("({{)(\\w*)(}})", "g");

export const formatStoryblokText = (
  text: string,
  promo: PromoOnboarding,
  currentStep: PromoOnboardingStep
) => {
  let editedText = text;
  const matches = editedText.match(variableRegex);
  if (matches) {
    const variables = matches.map((match) =>
      match.replace(variableRegex, (_, __, varName) => varName)
    );
    variables.forEach((variable) => {
      const varToSearch = `{{${variable}}}`;
      const replaceRegex = new RegExp(varToSearch, "g");
      let newText = get(promo, variable);
      if (!newText) {
        // Try to find again but inside the step.
        newText = get(currentStep, variable);

        // Now try to find it again inside the propositions.
        if (!newText) {
          newText =
            getValueFromProposition(
              variable,
              get(currentStep, "depositPropositions") ||
                get(currentStep, "wagerPropositions") ||
                []
            ) || varToSearch;
        }
      }

      editedText = replace(editedText, replaceRegex, newText);
    });
  }

  return editedText;
};
