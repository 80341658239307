// @flow
import type { Feature } from "@tvg/conf/src/types";
import type { Store } from "redux";
import tvgConf from "@tvg/conf";
import { get } from "lodash";

export const getCapiFeatureToggles = (
  store: Store<*, *>,
  key: string,
  defaultValue: boolean
) => {
  if (key.includes("-beta") && tvgConf().environment === "production") {
    return false;
  }
  return get(store, `capi.featureToggles.${key}`, defaultValue);
};

const calculateFeatureOverride = (
  hasFeaturesOverrides: boolean,
  featureOverrides: { [string]: { enabled: boolean, percentage: number } },
  toggle: Feature
) => {
  const { percentage } = toggle;
  const overrides = featureOverrides;
  let { enabled } = toggle;
  let random = 0;

  // Disable feature override if toggle was disabled or percentage is 0
  if (
    featureOverrides[toggle.name] &&
    (!toggle.enabled || toggle.percentage === 0)
  ) {
    enabled = false;
    overrides[toggle.name] = { enabled, percentage };
  }

  // Calculate new feature throttle by percentage or if it was disabled and percentage was increased
  if (
    (!hasFeaturesOverrides && toggle.enabled && toggle.percentage < 100) ||
    (featureOverrides[toggle.name] &&
      !featureOverrides[toggle.name].enabled &&
      percentage > featureOverrides[toggle.name].percentage)
  ) {
    random = Math.floor(Math.random() * 100 + 1);
    enabled = toggle.percentage >= random;
    overrides[toggle.name] = { enabled, percentage };
  }

  return overrides;
};

export default calculateFeatureOverride;
