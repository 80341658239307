import type { Product } from "./types";

export const PRODUCTS_WEB = ["touch2", "touch3", "fdrmobile"];
export const PRODUCTS_IOS = [
  "ios",
  "ios2",
  "fdrios",
  "fdriosxsell",
  "iosnative"
];
export const PRODUCTS_FDR_X_SELL = ["fdriosxsell", "fdrandroidxsell"];
export const PRODUCTS_FDR = [
  "fdrmobile",
  "fdrios",
  "fdrandroid",
  "fdrandroidgps",
  ...PRODUCTS_FDR_X_SELL
];
export const PRODUCTS_ANDROID = [
  "androidnative",
  "fdrandroid",
  "androidwrapper",
  "tvgandroid",
  "fdrandroidxsell",
  "fdrandroidgps"
];
export const PRODUCTS_MOBILE = [
  ...new Set([
    ...PRODUCTS_WEB,
    ...PRODUCTS_IOS,
    ...PRODUCTS_FDR,
    ...PRODUCTS_ANDROID
  ])
];

/* eslint-disable */
export default (product: Product): Product => {
  if (
    typeof window !== "undefined" &&
    window.__TVG_GLOBALS__ &&
    window.__TVG_GLOBALS__.PRODUCT
  ) {
    return window.__TVG_GLOBALS__.PRODUCT;
  }

  return product;
};
/* eslint-enable */
