import mediator from "@tvg/mediator";
import uwtService from "@tvg/api/uwt";
import { PaymentType } from "./types";

export const gtmNewPaymentTypeSelected = (
  paymentType: string,
  module: string
) =>
  mediator.base.dispatch({
    type: "PAWS:NEW_PAYMENT_TYPE_SELECTED",
    payload: {
      module,
      paymentType
    }
  });

export const gtmNavigateDepositWithdrawOptions = (
  selectedOption: string,
  paymentType: string
) =>
  mediator.base.dispatch({
    type: "PAWS:NAVIGATE_DEPOSIT_WITHDRAW_OPTIONS",
    payload: {
      selectedOption,
      paymentType
    }
  });

export const gtmDepositWithdrawOptionsModal = (
  isOpen: boolean,
  paymentType: string
) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_WITHDRAW_OPTIONS_MODAL",
    payload: {
      isOpen,
      paymentType
    }
  });

export const gtmSelectNewOrSavedPaymentType = (
  isNewCard: boolean,
  paymentType: string
) =>
  mediator.base.dispatch({
    type: "PAWS:SELECT_PAYMENT_TYPE_OR_METHOD",
    payload: {
      isNewCard,
      paymentType
    }
  });

export const gtmAuthorizeDeposit = (
  isFirstDeposit: boolean,
  paymentType: PaymentType,
  accountID: string
) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_ATTEMPT",
    payload: {
      isFirstDeposit,
      paymentType,
      accountID
    }
  });

export const gtmAuthorizeDepositValidation = (
  isFirstDeposit: boolean,
  paymentType: PaymentType,
  accountID: string,
  validationMessage: string
) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_VALIDATION_MESSAGE",
    payload: {
      isFirstDeposit,
      paymentType,
      accountID,
      validationMessage
    }
  });

export const gtmAuthorizeDepositError = (
  isFirstDeposit: boolean,
  paymentType: PaymentType,
  accountID: string,
  errorMessage: string
) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_ERROR_MESSAGE",
    payload: {
      isFirstDeposit,
      paymentType,
      accountID,
      errorMessage
    }
  });

export const gtmAuthorizeDepositSuccess = (
  isFirstDeposit: boolean,
  paymentType: PaymentType,
  accountID: string,
  amount: string
) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_SUCCESS",
    payload: {
      isFirstDeposit,
      paymentType,
      accountID,
      amount
    }
  });

export const gtmDepositClose = (
  paymentType: PaymentType,
  isCreate: boolean,
  accountID: string
) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_CLOSE",
    payload: {
      paymentType,
      isCreate,
      accountID
    }
  });

export const gtmDepositCreationReturn = (paymentType: PaymentType) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_CREATION_RETURN",
    payload: {
      paymentType
    }
  });

export const gtmDepositDefaultSelect = (
  paymentType: PaymentType,
  accountID: string
) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_DEFAULT_SELECT",
    payload: {
      paymentType,
      accountID
    }
  });

export const gtmDepositNewPaymentMethod = (paymentType: PaymentType) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_NEW_PAYMENT_METHOD",
    payload: {
      paymentType
    }
  });

export const gtmWithdrawClose = (paymentType: PaymentType, accountID: string) =>
  mediator.base.dispatch({
    type: "PAWS:WITHDRAW_CLOSE",
    payload: {
      paymentType,
      accountID
    }
  });

export const gtmAuthorizeWithdrawSuccess = (
  paymentType: PaymentType,
  accountID: string,
  amount: string
) =>
  mediator.base.dispatch({
    type: "PAWS:WITHDRAW_SUCCESS",
    payload: {
      paymentType,
      accountID,
      amount
    }
  });

export const gtmAuthorizeWithdrawError = (
  paymentType: PaymentType,
  accountID: string,
  errorMessage: string
) =>
  mediator.base.dispatch({
    type: "PAWS:WITHDRAW_ERROR_MESSAGE",
    payload: {
      paymentType,
      accountID,
      errorMessage
    }
  });

export const gtmWithdrawAuthorize = (payload: {
  paymentType: PaymentType;
  accountID: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:WITHDRAW_AUTHORIZE_TRANSACTION",
    payload
  });

export const gtmWithdrawValidationError = (payload: {
  paymentType: PaymentType;
  accountID: string;
  errorMessage: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:WITHDRAW_VALIDATION_ERROR",
    payload
  });

export const gtmOpenPendingWithdrawals = (accountId: string) =>
  mediator.base.dispatch({
    type: "PENDING_WITHDRAWALS_OPEN",
    payload: {
      accountId
    }
  });

export const gtmWithdrawAddressOpenModal = (payload: {
  paymentType: PaymentType;
  accountID: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:WITHDRAW_ADDRESS_MODAL_VIEW",
    payload
  });

export const gtmWithdrawAddressSelect = (payload: {
  paymentType: PaymentType;
  accountID: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:WITHDRAW_ADDRESS_MODAL_USER_SELECTS_ADDRESS",
    payload
  });

export const gtmWithdrawAddressConfirm = (payload: {
  paymentType: PaymentType;
  accountID: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:WITHDRAW_ADDRESS_MODAL_USER_CONFIRMS_ADDRESS",
    payload
  });

export const gtmWithdrawAddressCancelModal = (payload: {
  paymentType: PaymentType;
  accountID: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:WITHDRAW_ADDRESS_MODAL_USER_CLICKS_CANCEL",
    payload
  });

// Deposit Addrress gtm events
export const gtmDepositAddressOpenModal = (payload: {
  paymentType: PaymentType;
  accountID: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_ADDRESS_MODAL_VIEW",
    payload
  });

export const gtmDepositAddressSelect = (payload: {
  paymentType: PaymentType;
  accountID: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_ADDRESS_MODAL_USER_SELECTS_ADDRESS",
    payload
  });

export const gtmDepositAddressConfirm = (payload: {
  paymentType: PaymentType;
  accountID: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_ADDRESS_MODAL_USER_CONFIRMS_ADDRESS",
    payload
  });

export const gtmDepositAddressCancelModal = (payload: {
  paymentType: PaymentType;
  accountID: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_ADDRESS_MODAL_USER_CLICKS_CANCEL",
    payload
  });

export const gtmDepositAddressCustomerService = (payload: {
  paymentType: PaymentType;
  accountID: string;
  linkText: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_ADDRESS_MODAL_USER_CLICKS_MESSAGE_CUSTOMER_SERVICE",
    payload
  });

export const gtmWithdrawAddressCustomerService = (payload: {
  paymentType: PaymentType;
  accountID: string;
  linkText: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:WITHDRAW_ADDRESS_MODAL_USER_CLICKS_MESSAGE_CUSTOMER_SERVICE",
    payload
  });

export const gtmOtherPaymentMethodsOpenTab = (payload: {
  paymentType: PaymentType;
}) =>
  mediator.base.dispatch({
    type: "PAWS:OTHER_PAYMENT_METHODS_OPEN_TAB",
    payload
  });

export const gtmOtherPaymentMethodsCTAClick = (payload: { linkText: string }) =>
  mediator.base.dispatch({
    type: "PAWS:OTHER_PAYMENT_METHODS_CTA_CLICK",
    payload
  });

export const gtmOtherPaymentMethodsPDFDownload = (payload: {
  linkText: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:OTHER_PAYMENT_METHODS_PDF_DOWNLOAD",
    payload
  });

export const gtmAddPaymentMethod = () =>
  mediator.base.dispatch({
    type: "PAWS:ADD_PAYMENT_METHOD"
  });

export const gtmPaymentSelectorOpen = (payload: {
  currentPaymentType: PaymentType;
}) =>
  mediator.base.dispatch({
    type: "PAWS:PAYMENT_SELECTOR_OPEN",
    payload
  });

export const gtmPaymentSelectorAddMethod = (payload: {
  currentPaymentType: PaymentType;
}) =>
  mediator.base.dispatch({
    type: "PAWS:PAYMENT_SELECTOR_ADD_METHOD",
    payload
  });

export const gtmPaymentSelectorChangeMethod = (payload: {
  currentPaymentType: PaymentType;
  selectedPaymentType: PaymentType;
}) =>
  mediator.base.dispatch({
    type: "PAWS:PAYMENT_SELECTOR_CHANGE_METHOD",
    payload
  });

export const gtmDeletePaymentMethodButton = (payload: {
  paymentType: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:DELETE_PAYMENT_METHOD_BUTTON",
    payload
  });

export const gtmDeletePaymentMethodSuccess = (payload: {
  paymentType: string;
}) =>
  mediator.base.dispatch({
    type: "PAWS:DELETE_PAYMENT_METHOD_SUCCESS",
    payload
  });

export const gtmDepositFirstInteraction = (payload: {
  accountId: string;
  paymentType: string;
  isFirstDeposit: boolean;
}) =>
  mediator.base.dispatch({
    type: "PAWS:DEPOSIT_FIRST_INTERACTION",
    payload
  });

export const sendGtmWalletPageView = (payload: {
  accountId: string;
  customerStatus: "New" | "Returning";
  privateBrowser: "Yes" | "No";
  productVersion: string;
  registrationStatus: "Registered";
  residenceState: string;
  siteVersion: string;
}) => {
  uwtService
    .getBalance(payload.accountId, false)
    .then((response: { data: { balance: { toFixed: Function } } }) =>
      gtmWalletPageView({
        ...payload,
        balance: response.data.balance.toFixed(2)
      })
    )
    .catch(() =>
      gtmWalletPageView({
        ...payload,
        balance: undefined
      })
    );
};

export const gtmWalletPageView = (payload: {
  accountId: string;
  customerStatus: "New" | "Returning";
  privateBrowser: "Yes" | "No";
  productVersion: string;
  registrationStatus: "Registered";
  residenceState: string;
  siteVersion: string;
  balance: number | undefined;
}) => {
  mediator.base.dispatch({
    type: "PAWS:WALLET_PAGEVIEW",
    payload
  });
};

export const gtmUserAccountWalletIconsClick = (payload: {
  destinationUrl: string;
  iconText: string;
}) => {
  mediator.base.dispatch({
    type: "PAWS:USER_ACCOUNT_WALLET_ICONS_CLICK",
    payload
  });
};
