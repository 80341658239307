import React, { Component } from "react";
import ReactDOM from "react-dom";
import { isEqual } from "lodash";

import ToastMessage from "./messageContainer";
import { ToastMessageProps, ToastOptions } from "./types";

export interface ToastState extends ToastOptions {
  /** If toast message is visible */
  isVisible: boolean;
}

class ToastManager extends Component<ToastMessageProps, ToastState> {
  static displayName = "ToastManager";

  static ref: ToastManager | null = null;

  static setRef(ref: ToastManager | null) {
    ToastManager.ref = ref;
  }

  static getRef() {
    return ToastManager.ref;
  }

  static clearRef() {
    ToastManager.ref = null;
  }

  static show(options: ToastMessageProps) {
    ToastManager.ref?.show(options);
  }

  static hide() {
    ToastManager.ref?.hide();
  }

  root: Element | null = null;

  el: Element | null = null;

  constructor(props: ToastMessageProps) {
    super(props);
    this.state = {
      variant: props.variant,
      message: props.message,
      isVisible: props.isVisible !== undefined ?? false
    };
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.el = document.createElement("div");
      this.root = document.body;
      this.root.appendChild(this.el);
    }
  }

  shouldComponentUpdate(nextProps: ToastMessageProps, nextState: ToastState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  componentWillUnmount = () => {
    if (this.el && this.state.isVisible) this.root?.removeChild(this.el);
  };

  show = (options: ToastOptions) => {
    this.setState({
      ...options,
      isVisible: true
    });
  };

  hide = () => {
    if (this.state.isVisible) {
      this.setState({ isVisible: false });
    }
  };

  render() {
    if (typeof window === undefined || !this.el) return null;

    return ReactDOM.createPortal(
      <ToastMessage
        {...this.props}
        {...this.state}
        onHide={this.hide}
        qaLabel="toast-portal"
        isFromDSManager
      />,
      this.el
    );
  }
}

export { ToastManager };
