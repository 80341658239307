import React from "react";
import { Circle, Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const AccountHistory = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M14.4589 2.625H3.36956C2.1274 2.625 1.12042 3.63236 1.12042 
      4.875V11.25V19.125C1.12042 20.3676 2.1274 21.375 3.36956 
      21.375H8.8718L8.67757 21.375H16.8588C13.8867 21.375 10.8809 18.9785 
      10.8809 15.3721C10.8809 12.1055 13.541 9.36914 16.8588 
      9.36914V4.875C16.8588 3.63236 15.7011 2.625 14.4589 2.625Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M5.36488 21.375H8.67757H9.62781M16.8588 6.43361V4.875C16.8588 3.63236 
      15.7011 2.625 14.4589 2.625H3.36956C2.1274 2.625 1.12042 3.63236 1.12042 
      4.875V11.25V19.125C1.12042 20.3676 2.1274 21.375 3.36956 21.375H8.8718"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <Path
      d="M4.49277 13.121L7.49277 13.121"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <Path
      d="M4.49277 8.62573L10.4905 8.62573"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <Circle
      cx="16.8588"
      cy="15.375"
      r="6"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <Path
      d="M16.6661 13.5043V16.2445L18.9205 17.2551"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </>
);
export default AccountHistory;
