import React, { FC } from "react";
import type { ParagraphProps, ParagraphFonts } from "./types";
import { StyledParagraph } from "./styled-components";
import { getColor } from "../utils";

export const Paragraph: FC<ParagraphProps> = ({
  children,
  qaLabel,
  color = "--fd-colors-content-default",
  textOverflow,
  ...rest
}) => {
  const selectedColor = getColor(color);

  return (
    <StyledParagraph
      role="paragraph"
      textOverflow={textOverflow}
      color={selectedColor}
      data-qa-label={qaLabel}
      {...rest}
    >
      {children}
    </StyledParagraph>
  );
};

export type { ParagraphProps, ParagraphFonts };
