import axios from "axios";
import { attempt } from "./utils";
import type { Messages, Product, Device, Brand } from "./types";

let cachedMessages: Record<string, string> = {};

const parseMessages = (messagesData: string): Messages | {} => {
  let messages = {};
  const data = attempt(() => JSON.parse(decodeURIComponent(messagesData))) as
    | { response: { [key: string]: string } }
    | Error;
  if (!(data instanceof Error) && typeof data === "object" && data.response) {
    messages = data.response;
  }

  return messages;
};

const readMessages = (
  namespaces: Array<string> | undefined = undefined
): Messages => {
  let messagesObject = {};

  // reads messages from server side injection stores in cache
  /* eslint-disable */
  if (
    typeof window !== "undefined" &&
    window.__TVG_GLOBALS__ &&
    window.__TVG_GLOBALS__.MESSAGES
  ) {
    messagesObject = parseMessages(window.__TVG_GLOBALS__.MESSAGES);
    cachedMessages = { ...messagesObject, ...cachedMessages };
    messagesObject = cachedMessages;
  }
  /* eslint-enable */
  if (cachedMessages && Object.keys(cachedMessages).length > 0) {
    // reads messages from cached data
    if (namespaces) {
      messagesObject = Object.assign(
        {},
        ...namespaces.map((name) => cachedMessages[name])
      );
    } else {
      const namespaceMap = Object.keys(cachedMessages);
      messagesObject = Object.assign(
        {},
        ...namespaceMap.map((name) => cachedMessages[name])
      );
    }
  }

  return messagesObject;
};

// @deprecated
const getMessages = (
  namespaces: string[],
  capiEndpoint: string,
  product: Product,
  device: Device,
  brand: Brand,
  forceFetch: boolean
): Promise<Messages | false> => {
  if (!namespaces || !(namespaces instanceof Array)) {
    return Promise.reject(new Error("No namespaces were provided"));
  }
  if (!forceFetch) {
    const messages = readMessages(namespaces);
    if (Object.keys(messages).length > 0 && messages.constructor === Object) {
      return Promise.resolve().then(() => messages);
    }
  }

  const requestOptions = {
    url: `${capiEndpoint}/messages/namespace?product=${product}&device=${device}&brand=${brand}&namespace=${namespaces.join(
      ","
    )}`,
    method: "GET",
    withCredentials: true
  };

  return axios(requestOptions).then((result) => {
    let messagesMap = [];
    let messages = {};

    if (
      !result.data ||
      !result.data.response ||
      result.data.response.length < 1
    ) {
      Promise.reject(new Error("No messages returned"));
    } else {
      messagesMap = namespaces.map((k) => result.data.response[k]);

      messages = messagesMap.reduce((cache, values, i) => {
        const msgs: Record<string, string> = {};
        msgs[namespaces[i]] = values;
        return { ...cache, ...msgs };
      }, messages);

      cachedMessages = { ...cachedMessages, ...messages };
    }

    return Object.assign({}, ...messagesMap);
  });
};

export default { readMessages, getMessages };
