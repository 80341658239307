import React from "react";
import { Path } from "react-native-svg";

import { IconPathProps } from "../types";

const ArrowLeft = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M12 4.5L4.5 12L12 19.5M21.75 12H4.57617"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default ArrowLeft;
