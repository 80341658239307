import React from "react";
import { IconPathProps } from "../../types";

export const TrackMaster = ({ lineColor }: IconPathProps) => (
  <>
    <path
      d="M12.7552 20.9722C18.6253 20.9722 23.2604 16.8559 23.2604 11.959C23.2604
    7.06213 18.6253 2.9458 12.7552 2.9458C6.88509 2.9458 2.25 7.06213 2.25
    11.959C2.25 16.8559 6.88509 20.9722 12.7552 20.9722ZM12 22.494C5.37258
    22.494 0 17.7942 0 11.9968C0 6.19936 5.37258 1.4996 12 1.4996C18.6274
    1.4996 24 6.19936 24 11.9968C24 17.7942 18.6274 22.494 12 22.494Z"
      fill={lineColor}
    />
    <path
      d="M10.9636 9.11258H8.66371L7.27981 17.0931H5.38188L6.76578
      9.11258H4.49881L4.78218 7.49802H11.247L10.9636 9.11258ZM14.069 7.49802L14.6292
      14.3978L17.5749 7.49802H20.0659L18.3987 17.0931H16.5007L16.962 14.4373L17.7924
      10.6481L14.8532 17.0931H13.5682L12.8433 10.4306L12.2963 14.5625L11.8613
      17.0931H9.97001L11.6307 7.49802H14.069Z"
      fill={lineColor}
    />
  </>
);
