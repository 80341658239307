import React, { useContext } from "react";
import { SafeAreaProvider, Metrics } from "react-native-safe-area-context";
import { TvgConfContext } from "./tvgConfProvider";

const FRAME_DEFAULT = {
  width: 1280,
  height: 720,
  x: 0,
  y: 0
};

const MOBILE_FRAME_DEFAULT = {
  width: 320,
  height: 640,
  x: 0,
  y: 0
};

const INSETS_DEFAULT = {
  left: 0,
  right: 0,
  bottom: 0,
  top: 0
};

interface TvgSafeAreaProviderProps {
  /** Children element to be rendered. Normally the TvgSafeAreaProvider should be added at the top level of an application. */
  children: JSX.Element;
  initialMetrics?: Metrics;
}

export const TvgSafeAreaProvider = (props: TvgSafeAreaProviderProps) => {
  const { device } = useContext(TvgConfContext);

  const initialMetrics = props.initialMetrics ?? {
    frame: device === "mobile" ? MOBILE_FRAME_DEFAULT : FRAME_DEFAULT,
    insets: INSETS_DEFAULT
  };

  return (
    <SafeAreaProvider initialMetrics={initialMetrics}>
      {props.children}
    </SafeAreaProvider>
  );
};

export default TvgSafeAreaProvider;
