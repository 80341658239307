import React, { useRef, useEffect, RefObject, useState, useMemo } from "react";
import { replaceAll } from "@tvg/utils/generalUtils";
import { formatValue } from "../../../../utils";
import { NullaryFn } from "../../../../types";
import {
  Container,
  Sign,
  Value,
  Input,
  HiddenSpan,
  DecimalsMask
} from "./styled-components";

type Props = {
  isLoading: boolean;
  isDesktop?: boolean;
  showAmountWarning: boolean;
  value: string;
  onChangeValue?: Function;
  onClick?: NullaryFn<void>;
  onFocus?: NullaryFn<void>;
  hasFocus?: boolean;
};

const InputValue = ({
  isLoading,
  showAmountWarning,
  value = "0",
  isDesktop,
  onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.preventDefault(),
  onClick,
  onFocus,
  hasFocus = true
}: Props) => {
  const [inputWidth, setInputWidth] = useState(0);
  const ref = useRef<HTMLInputElement | null>();
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const element = ref.current;
    if (hasFocus && element) {
      element.focus();
    }
  }, []);

  useEffect(() => {
    if (spanRef && spanRef.current) {
      setInputWidth(Math.round(spanRef.current.getBoundingClientRect().width));
    }
  }, [value]);

  const [decimalsLength, showDecimalsMask] = useMemo(() => {
    const splitDecimals = value.split(".");
    const length = splitDecimals[1] && splitDecimals[1].length;
    const mask = value.includes(".") && (length as number) <= 1;

    return [length, mask] as const;
  }, [value]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventualCommasRemoved = replaceAll(event.target.value, ",", "");
    event.preventDefault();
    onChangeValue(eventualCommasRemoved, "desktopAmountField");
  };

  // Remove dot, in case the user left the input with, for instance, "5."
  const handleOnBlur = () => {
    const lastChar = value.charAt(value.length - 1);
    if (lastChar === ".") {
      const lastCharRemoved = value.slice(0, -1);
      onChangeValue(lastCharRemoved, "desktopAmountField");
    }
  };

  return (
    <Container
      data-qa-label="value-container"
      {...(onClick && { onClick })}
      hasFocus={hasFocus}
    >
      <Sign isLoading={isLoading} showAmountWarning={showAmountWarning}>
        $
      </Sign>
      {!isDesktop && showDecimalsMask && (
        <DecimalsMask smallMask={(decimalsLength as number) >= 1} />
      )}
      {isDesktop ? (
        <>
          <HiddenSpan ref={spanRef}>{formatValue(value) || 0}</HiddenSpan>
          <Input
            ref={ref as RefObject<HTMLInputElement>}
            data-qa-label="value-text-desktop"
            type="text"
            placeholder="0"
            inputWidth={inputWidth}
            value={formatValue(value)}
            onChange={handleOnChange}
            onFocus={onFocus}
            onBlur={handleOnBlur}
            isEmpty={!value.length}
            isLoading={isLoading}
            readOnly={isLoading}
            showAmountWarning={showAmountWarning}
          />
        </>
      ) : (
        <Value
          data-qa-label="value-text"
          isEmpty={!value.length}
          isLoading={isLoading}
          showAmountWarning={showAmountWarning}
        >
          {!value ? "0" : formatValue(value, true)}
        </Value>
      )}
    </Container>
  );
};

export default InputValue;
