import React, { FC } from "react";

export const Bahrain: FC = () => (
  <>
    <path d="M0 0H32V20H0V0Z" fill="#FFFFFF" />
    <path
      d="M8 0H32V20H8L12.8 18L8 16L12.8 14L8 12L12.8 10L8 8L12.8 6L8 4L12.8 2L8 0Z"
      fill="#D22839"
    />
  </>
);
