import React, { cloneElement } from "react";

import { useQaLabel } from "../../../hooks/useQaLabel";
import { StyledItemList } from "./styled-components";
import { LinkItemListProps } from "../types";

export const LinkItemList = ({
  children,
  qaLabel,
  ...rest
}: LinkItemListProps) => {
  const listElements = React.Children.toArray(children);
  const lastElemIndex = listElements.length - 1;

  return (
    <StyledItemList accessibilityRole="menu" {...useQaLabel(qaLabel)} {...rest}>
      {listElements.map(
        (elem, index) =>
          React.isValidElement(elem) &&
          cloneElement(elem, {
            key: `list-${index}`,
            accessibilityRole: "menuitem",
            hasDividerLine: index !== lastElemIndex
          } as Partial<unknown>)
      )}
    </StyledItemList>
  );
};
