import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";
import DefaultButton from "@tvg/atomic-ui/_atom/Buttons/default";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background-color: ${buildColor("white", "100")};
  border-radius: 4px;
  font-family: ${fontNormal};
`;

export const CloseButton = styled(DefaultButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 0;
  background: none;
  flex: 0 0 24px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  margin: 12px;
`;
