import React from "react";
import { v4 as generateUUID } from "uuid";
import Svg, { Path, LinearGradient, Defs, Stop } from "react-native-svg";
import { colors } from "../../../theming/themes/common/colors";
import { ScrollFadeProps } from "./types";
import { StyledViewContainer } from "./styled-components";

const defaultGradientPosition = {
  x1: 44,
  y1: 24,
  x2: 0,
  y2: 24
};
export const Fading = ({
  position,
  isVisible,
  height = "48",
  width = "44",
  viewBox,
  preserveAspectRatio,
  gradientPosition = defaultGradientPosition,
  qaLabel
}: ScrollFadeProps) => {
  const gradientID = generateUUID();
  const pathId = `paint0_linear_${gradientID}`;

  return isVisible ? (
    <StyledViewContainer
      position={position}
      width={width}
      height={height}
      qaLabel={qaLabel}
    >
      <Svg
        height={height}
        width={width}
        style={position === "start" && { transform: [{ rotate: "180deg" }] }}
        viewBox={viewBox}
        preserveAspectRatio={preserveAspectRatio}
      >
        <Path d="M44 0H0V48H44V0Z" fill={`url(#${pathId})`} />
        <Defs>
          <LinearGradient
            id={pathId}
            {...gradientPosition}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="white" />
            <Stop offset="1" stopColor={colors.white[900]} stopOpacity="0" />
          </LinearGradient>
        </Defs>
      </Svg>
    </StyledViewContainer>
  ) : null;
};
