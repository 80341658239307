import styled from "styled-components/native";
import { View, Text, TouchableHighlight } from "react-native";
import {
  space,
  SpaceProps,
  width,
  minWidth,
  compose,
  variant,
  border
} from "../../theming/styledSystem";
import { Colors } from "../../theming";
import { useQaLabel } from "../../hooks/useQaLabel";
import { getAccessibilityRole } from "../../utils/accessibility";
import { ButtonProps, ButtonVariant, ButtonSize, IconPosition } from "./types";

export const TERNARY_COLOR_CODE = "blue_accent.500";

export const getTertiaryColor = (
  colors: Colors,
  colorCode: string,
  event?: "hover" | "press",
  isMobile?: boolean
) => {
  if (isMobile) return null;
  const eventVariantColor = {
    hover: 100,
    press: 200
  };

  const [color, variantColor] = colorCode.split(".");
  const newVariantColor = event ? eventVariantColor[event] : variantColor;
  // @ts-ignore
  return colors[color][newVariantColor];
};

export const buttonSizes = {
  s: {
    height: "25px",
    paddingX: "space-3"
  },
  m: {
    height: "32px",
    paddingX: "space-3"
  },
  l: {
    height: "44px",
    paddingX: "space-5"
  }
};

const buttonStyles = compose(
  space,
  width,
  minWidth,
  border,
  variant({ prop: "size", variants: buttonSizes })
);

export const StyledButton = styled(TouchableHighlight).attrs<{
  qaLabel: string;
  variant: string;
  tertiaryColor: string;
  isMobile?: boolean;
}>((props) => ({
  ...useQaLabel(props.qaLabel),
  accessibilityRole: "button",
  underlayColor:
    props.variant === "tertiary"
      ? getTertiaryColor(
          props.theme.colors,
          props.tertiaryColor,
          "press",
          props.isMobile
        )
      : props.theme.buttons[props.variant as ButtonVariant]?.pressed?.background
}))<
  ButtonProps & {
    isPressed: boolean;
    isHovered: boolean;
    tertiaryColor: string;
    isMobile?: boolean;
  }
>`
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radii.xs};
  ${({ theme, variant: buttonVariant }) =>
    theme.buttons[buttonVariant as ButtonVariant]?.default};
  ${({
    theme,
    variant: buttonVariant,
    isHovered,
    isPressed,
    isDisabled,
    isLoading,
    tertiaryColor,
    isMobile
  }) =>
    (!isDisabled &&
      !isLoading &&
      isPressed &&
      (buttonVariant === "tertiary"
        ? `background-color:${getTertiaryColor(
            theme.colors,
            tertiaryColor,
            "press",
            isMobile
          )}`
        : theme.buttons[buttonVariant as ButtonVariant]?.pressed)) ||
    (!isDisabled &&
      !isLoading &&
      isHovered &&
      (buttonVariant === "tertiary"
        ? `background-color:${getTertiaryColor(
            theme.colors,
            tertiaryColor,
            "hover",
            isMobile
          )}`
        : theme.buttons[buttonVariant as ButtonVariant]?.hovered))};
  ${buttonStyles};
  opacity: ${(props) => (props.isDisabled || props.isLoading ? 0.5 : 1)};
  ${(props) => (props.isStretched ? "width: 100%;" : "align-self: flex-start;")}
  flex-grow: ${(props) => (props.isStretched ? "1" : "0")};
`;

export const ButtonRow = styled(View)<{ iconPosition?: IconPosition }>`
  align-items: center;
  flex-direction: ${(props) =>
    props.iconPosition === "end" ? "row-reverse" : "row"};
`;

const textSizes = {
  s: {
    fontFamily: "medium",
    fontSize: "xs"
  },
  m: {
    fontFamily: "medium",
    fontSize: "s"
  },
  l: {
    fontFamily: "medium",
    fontSize: "s"
  }
};

const textStyles = compose(
  space,
  variant({ prop: "size", variants: textSizes })
);

export const StyledText = styled(Text).attrs<{
  color?: string;
}>(({ color }: { color?: string }) => ({
  accessibilityRole: getAccessibilityRole("span"),
  color
}))<{ variant?: ButtonVariant; size: ButtonSize; color?: string } & SpaceProps>`
  ${textStyles};
  color: ${({ color, theme }) => color || theme.colors.white[900]};
`;
