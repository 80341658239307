import { useState } from "react";
import { getFeeValue } from "./methods";

const useFee = () => {
  const [feeValue, setFeeValue] = useState<string>("");

  return [feeValue, getFeeValue(setFeeValue)] as const;
};

export default useFee;
