import { get, find } from "lodash";
import { PaymentType } from "@tvg/ts-types/Payment";
import { DepositStep } from "../types/promoSteps";

export const validatePaymentType = (
  promoPaymentType: PaymentType,
  walletPaymentType: string
): boolean =>
  promoPaymentType === PaymentType.ALL ||
  promoPaymentType === walletPaymentType;

export const validateAmount = (
  minimumDepositAmount: number,
  amount: number
): boolean => amount >= minimumDepositAmount;

export const matchDepositPropositions = (
  step: DepositStep | undefined,
  walletPaymentType: string,
  amount?: number
): boolean => {
  if (!step) {
    return false;
  }

  const propositions = get(step, "depositPropositions", []);

  const matchProposition = find(propositions, (proposition) => {
    const { paymentTypeCode, minimumDepositAmount } = proposition;

    const isPaymentTypeValid = validatePaymentType(
      paymentTypeCode,
      walletPaymentType
    );

    if (typeof amount !== "undefined") {
      const isAmountValid = validateAmount(minimumDepositAmount, amount);
      return isPaymentTypeValid && isAmountValid;
    }

    return isPaymentTypeValid;
  });

  return !!matchProposition;
};

export const getCustomAmount = (
  step: DepositStep,
  walletPaymentType: string,
  amount?: number
) => {
  const propositions = get(step, "depositPropositions", []);

  const matchProposition = find(propositions, (proposition) => {
    const { paymentTypeCode, minimumDepositAmount } = proposition;

    const isPaymentTypeValid = validatePaymentType(
      paymentTypeCode,
      walletPaymentType
    );

    if (typeof amount !== "undefined") {
      const isAmountValid = validateAmount(minimumDepositAmount, amount);
      return isPaymentTypeValid && isAmountValid;
    }

    return isPaymentTypeValid;
  });

  if (matchProposition && amount) {
    const bonusPercentage =
      +get(matchProposition, "bonusPercentage", "0 %").replace("%", "").trim() /
      100;
    const maxBonusAmount = get(matchProposition, "bonusAmount", 0);
    const biggestBonusAmount = amount + amount * bonusPercentage;

    return biggestBonusAmount <= maxBonusAmount
      ? biggestBonusAmount
      : maxBonusAmount;
  }

  return 0;
};
