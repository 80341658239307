import {
  ResetStoryblokPromosAction,
  SetPartialStoryblokPromosAction,
  SetStoryblokPromosAction,
  SetPromoOnboardingPollerTimeAction
} from "./types";
import { PromoOnboardingFormatted } from "../../types/promoOnboarding";

export const setStoryblokPromos = (
  promos: PromoOnboardingFormatted
): SetStoryblokPromosAction => ({
  type: "SET_STORYBLOK_PROMOS",
  payload: {
    ...promos
  }
});

export const setPartialStoryblokPromos = (
  promos: PromoOnboardingFormatted
): SetPartialStoryblokPromosAction => ({
  type: "SET_PARTIAL_STORYBLOK_PROMOS",
  payload: {
    ...promos
  }
});

export const resetStoryblokPromos = (): ResetStoryblokPromosAction => ({
  type: "RESET_STORYBLOK_PROMOS"
});

export const setPromoOnboardingPollerTime = (
  pollerTime: number
): SetPromoOnboardingPollerTimeAction => ({
  type: "SET_PROMO_ONBOARDING_POLLER_TIME",
  payload: pollerTime
});
