import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Store } from "redux";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import { getPawsContent } from "@tvg/sh-lib-paws/redux/selectors";
import { PaymentType, PawsContent } from "../../../types";
import { getDeleteTypeByPaymentType } from "../../../utils";
import { getAction, onContact } from "../functions";
import AlertMessagesModal from "../../AlertMessagesModal";

export interface ErrorModalProps {
  onRetry: () => void;
  paymentType: PaymentType;
  hasError: boolean;
  setHasError: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
}

const ErrorModal = ({
  paymentType,
  onRetry,
  hasError,
  setHasError,
  isMobile
}: ErrorModalProps) => {
  const [errorRetryCount, setErrorRetryCount] = useState<number>(0);
  const { deleteCardModalError } = useSelector<Store, PawsContent>(
    getPawsContent
  );

  const title = useMemo(
    () =>
      replaceCAPIVariables(deleteCardModalError.title, {
        type: getDeleteTypeByPaymentType(paymentType)
      }),
    [paymentType, deleteCardModalError]
  );

  const description = useMemo(
    () =>
      replaceCAPIVariables(deleteCardModalError.description, {
        type: `${
          paymentType === "MZM" ||
          paymentType === "PAY" ||
          paymentType === "ACH"
            ? "bank"
            : ""
        } ${getDeleteTypeByPaymentType(paymentType)} ${
          paymentType === "MZM" ||
          paymentType === "PAY" ||
          paymentType === "ACH"
            ? ""
            : "details"
        }`
      }),
    [paymentType, deleteCardModalError]
  );

  return (
    <AlertMessagesModal
      isOpen={hasError}
      isMobile={isMobile}
      type="error"
      title={title}
      description={description}
      retryCount={errorRetryCount}
      onCloseMethod={() => {
        setErrorRetryCount(0);
        setHasError(false);
      }}
      onContactMethod={onContact}
      actions={[
        getAction(
          onRetry,
          setErrorRetryCount,
          errorRetryCount,
          deleteCardModalError.retryText,
          deleteCardModalError.contactText
        )
      ]}
    />
  );
};

export default React.memo(ErrorModal);
