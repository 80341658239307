module.exports = {
  domain: {
    desktop: "4njbets-staging.us.betfair.com",
    mobile: "4njbets-staging.us.betfair.com",
    talentPicks: "4njbets-staging.us.betfair.com/talent-picks/",
    equibase: "equibase-store.us.betfair.com"
  },
  service_host: "service-staging.us.betfair.com",
  recaptchaID: "6LeIYBcqAAAAAE2wDhSub9Ic8oeidAiiUt4gYM4a",
  recaptchaIdNative: "6LfRQloqAAAAABY9v2RydlB10Lfgb5ODmvygHy3G"
};
