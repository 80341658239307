import React, { forwardRef } from "react";
import { View } from "react-native";

import { TooltipDescriptiveProps, TooltipDescriptivePlacement } from "./types";
import { useQaLabel } from "../../hooks";
import Popper from "../popper";
import Content from "./content";

const CLOSE_DELAY: number = 30000;

export const TooltipDescriptive = forwardRef<View, TooltipDescriptiveProps>(
  (
    {
      children,
      qaLabel,
      placement = "top-mid",
      isDisabled = false,
      isDarkMode = false,
      closeDelay = CLOSE_DELAY,
      forceClose = false,
      onAction = "press",
      layer = 1999,
      ...rest
    },
    ref
  ) => (
    <Popper
      on={onAction}
      placement={placement}
      isDisabled={isDisabled}
      layer={layer}
      content={
        <Content
          ref={ref}
          placement={placement}
          isDarkMode={isDarkMode}
          closeDelay={closeDelay}
          forceClose={forceClose}
          {...(qaLabel && useQaLabel(qaLabel))}
          {...rest}
        />
      }
    >
      {children}
    </Popper>
  )
);

export { TooltipDescriptiveProps, TooltipDescriptivePlacement };
export default TooltipDescriptive;
