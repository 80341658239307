import { engageApptentiveEvent } from "../utils";

// when a user logs in to TVG
export const placeABet = () => engageApptentiveEvent("Places bet");

// when a user select handicap option
export const selectHandicapOptions = () =>
  engageApptentiveEvent("Handicapping options tapped");

// when a user select the TVG Logo
export const selectTVGLogo = () => engageApptentiveEvent("TVG Logo tapped");

// when a user set a track as favorite
export const setTrackAsFavorite = () =>
  engageApptentiveEvent("Race track is favorited");

// when a user selects/uses inline pps in raace page
export const selectInlinePastPerformance = () =>
  engageApptentiveEvent("Inline past performance tapped");

// when a user selects 'More' in the race page to view pps
export const selectMoreButton = () =>
  engageApptentiveEvent(`User taps "more" in race page to view PPs`);

// when a user selects a bet type tooltip in the race page
export const selectBetTypeTooltip = () =>
  engageApptentiveEvent("Bet Type tooltip is tapped");
