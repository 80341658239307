import React, { forwardRef, isValidElement, RefObject } from "react";
import { Pressable, View } from "react-native";

import { useQaLabel } from "../../../../hooks/useQaLabel";
import { EventTypes, Layout } from "../../types";
import usePopper from "../../hooks/usePopper";
import measureLayout from "../../utils/measureLayout";

export interface Props {
  on: EventTypes;
  isDisabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

const Trigger = forwardRef<View, Props>(
  ({ children, on, isDisabled = false }, ref) => {
    const { getHandlers, isWeb } = usePopper();
    const popperQaLabel = useQaLabel("popper-trigger");
    const hasValidChildren = isValidElement(children);

    const getTriggerLayout = async (callback: (layout: Layout) => void) => {
      let scrollXOffset = 0;
      let scrollYOffset = 0;
      let topPosition = 0;

      if (isWeb) {
        scrollXOffset = document.documentElement.scrollLeft;
        scrollYOffset = document.documentElement.scrollTop;
        // @ts-ignore
        topPosition = ref.current ? ref.current.getBoundingClientRect().top : 0;
      }

      const triggerLayout = await measureLayout(ref as RefObject<View>, isWeb);

      callback({
        ...triggerLayout,
        x: Number(triggerLayout.x) + scrollXOffset,
        y: Number(topPosition || triggerLayout.y) + scrollYOffset
      });
    };

    if (!hasValidChildren) return null;

    if (isDisabled) return children;

    return on === "press" ? (
      <Pressable
        ref={ref}
        {...getHandlers(on, getTriggerLayout)}
        {...popperQaLabel}
      >
        {children}
      </Pressable>
    ) : (
      <View ref={ref} {...getHandlers(on, getTriggerLayout)} {...popperQaLabel}>
        {children}
      </View>
    );
  }
);

export default Trigger;
