import { Dispatch as ReactDispatch, SetStateAction } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import pawsDepositService from "@tvg/api/paws/deposit";
import achService from "@tvg/api/ach";
import { updateBalance } from "@tvg/shared-actions/UserActions";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { get } from "lodash";
import formatCurrency from "@tvg/formatter/currency";
import { AxiosError } from "axios";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import {
  openCardDeclinedModal,
  setErrorCode
} from "@tvg/sh-lib-paws/redux/slices/cardDeclinedModalSlice";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { PaymentTypesAvailable } from "@tvg/api/paws/types";
import { isDesktop } from "../../../../utils";
import {
  gtmAuthorizeDeposit,
  gtmAuthorizeDepositError,
  gtmAuthorizeDepositSuccess,
  gtmAuthorizeDepositValidation,
  gtmDepositNewPaymentMethod
} from "../../../../gtm";
import { ErrorMessage, FormData, PaymentType } from "../../../../types";
import { clearValue } from "./valueModifiers";

export const moneypakSubmitDeposit =
  (
    paymentType: PaymentType,
    accountId: string,
    value: string,
    isFirstDeposit: boolean,
    defaultDescription: string,
    dispatch: Dispatch,
    setErrorMessage: ReactDispatch<SetStateAction<ErrorMessage>>,
    pawsErrorMessages: ErrorMessage,
    setIsLoading: ReactDispatch<SetStateAction<boolean>>,
    setAmount: ReactDispatch<SetStateAction<string>>,
    setShowSuccessMessage: ReactDispatch<SetStateAction<boolean>>,
    refetchAvailableMethods: NullaryFn<void>
  ) =>
  async () => {
    try {
      setIsLoading(true);
      gtmAuthorizeDeposit(false, paymentType, accountId);

      const response = await pawsDepositService.postDeposit(
        paymentType as PaymentTypesAvailable,
        {
          accountId,
          cardNumber: clearValue(value)
        }
      );

      const { data } = response;

      if (data) {
        if (!isDesktop || isTvg5()) {
          dispatch(updateBalance(data.balance));
        } else {
          mediatorClassic.dispatch("BALANCE_UPDATE", data.balance);
        }
        gtmAuthorizeDepositSuccess(
          isFirstDeposit,
          paymentType,
          accountId,
          data.amount as string
        );
        setAmount(data.amount as string);
        setIsLoading(false);
        setShowSuccessMessage(true);
        refetchAvailableMethods();
      }
    } catch (err) {
      const errorCode = get(err, "response.data.code", "");

      const errorByCode =
        get(pawsErrorMessages, `${errorCode}`) ||
        get(pawsErrorMessages, "default");

      gtmAuthorizeDepositError(
        false,
        paymentType,
        accountId,
        get(errorByCode, "description", defaultDescription)
      );

      setErrorMessage((prevState) => ({
        ...prevState,
        isOpen: true,
        errorCode,
        isRetry: false
      }));

      setIsLoading(false);
    }
  };

export const onSubmitDeposit =
  (
    isValueOutOfLimits: boolean,
    value: string,
    minLimit: number,
    maxLimit: number,
    fromCreation: boolean,
    paymentType: PaymentType,
    accountId: string,
    formData: FormData,
    cvv: string,
    selectedPaymentMethodId: string,
    dispatch: Dispatch,
    refetchAvailableMethods: NullaryFn<void>,
    setShowAmountWarning: ReactDispatch<SetStateAction<boolean>>,
    pawsErrorMessages: ErrorMessage,
    defaultErrorDescription: string,
    isCardDeclinedToggleOn: boolean,
    cardDeclinedContent: unknown,
    isFirstDeposit: boolean,
    setErrorMessage: ReactDispatch<SetStateAction<ErrorMessage>>,
    setRequestMazooma: ReactDispatch<SetStateAction<boolean>>,
    setIsLoading: ReactDispatch<SetStateAction<boolean>>,
    setIsDepositHoldFundsType: ReactDispatch<SetStateAction<boolean>>,
    setShowSuccessMessage: ReactDispatch<SetStateAction<boolean>>
  ) =>
  async () => {
    if (isValueOutOfLimits) {
      const validationMessage =
        +value < +minLimit
          ? `${formatCurrency(minLimit)} MINIMUM DEPOSIT`
          : `${formatCurrency(maxLimit)} MAXIMUM DEPOSIT`;

      gtmAuthorizeDepositValidation(
        fromCreation,
        paymentType,
        accountId,
        validationMessage
      );
      setShowAmountWarning(true);
    } else {
      gtmAuthorizeDeposit(fromCreation, paymentType, accountId);

      if (paymentType === PaymentTypeEnum.MAZOOMA) {
        setRequestMazooma(true);
        return;
      }

      try {
        let response;
        const amount = Number(value).toFixed(2);
        setIsLoading(true);
        if (fromCreation) {
          response = await achService.postCreateDeposit(accountId, {
            amount,
            ...formData
          });
        } else {
          response = await pawsDepositService.postDeposit(
            paymentType as PaymentTypesAvailable,
            {
              accountId,
              paymentMethodId: +selectedPaymentMethodId,
              cardVerificationNumber: cvv,
              amount
            }
          );
        }

        const { data, status } = response;

        // 207 is a MULTISTATUS
        // We go through the data that is, in this case, an array, to search for a possible error
        if (status === 207) {
          const error = (
            data as unknown as [Record<string, string | number>]
          ).find(
            (item: Record<string, string | number>) => item.statusCode === 500
          );

          if (error && error.body)
            throw {
              response: {
                data: error.body
              }
            } as AxiosError;
        }

        if (data) {
          setIsDepositHoldFundsType((data.holdDays as number) > 0);
          if (!isDesktop || isTvg5()) {
            dispatch(updateBalance(data.balance));
          } else {
            mediatorClassic.dispatch("BALANCE_UPDATE", data.balance);
          }
          setIsLoading(false);
          setShowSuccessMessage(true);

          gtmAuthorizeDepositSuccess(
            isFirstDeposit,
            paymentType,
            accountId,
            amount
          );

          if (fromCreation) {
            gtmDepositNewPaymentMethod(paymentType);
          }

          refetchAvailableMethods();
        }
      } catch (err) {
        const errorCode = get(err, "response.data.code", "");

        const errorByCode =
          get(pawsErrorMessages, `${errorCode}`) ||
          get(pawsErrorMessages, "default");

        gtmAuthorizeDepositError(
          fromCreation,
          paymentType,
          accountId,
          get(errorByCode, "description", defaultErrorDescription)
        );

        if (
          get(cardDeclinedContent, "validErrorCodes") &&
          get(cardDeclinedContent, "validErrorCodes").includes(errorCode) &&
          isCardDeclinedToggleOn
        ) {
          batch(() => {
            dispatch(openCardDeclinedModal());
            dispatch(setErrorCode(errorCode));
          });
        } else {
          setErrorMessage((prevState) => ({
            ...prevState,
            isOpen: true,
            errorCode,
            isRetry: false
          }));
        }
        setIsLoading(false);
      }
    }
  };
