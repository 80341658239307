// @flow
import tvgConf from "@tvg/conf";
import type { styleMapType } from "./types";
import buildColor from "../../_static/ColorPalette";
import {
  info,
  infoOutline,
  exclamation,
  warningOutline,
  success,
  successOutline,
  error,
  glassyError,
  checkPromo,
  personRightArrow
} from "../../_static/Icons/icons";
import { infoOutline as infoOutlineDuotone } from "../../_static/Icons/iconsDuotone";

const styleMap: styleMapType = {
  default: {
    icon: info,
    fill: buildColor("blue_accent", "500"),
    background: buildColor("white", "0")
  },
  infoWhiteBackground: {
    icon: info,
    fill: buildColor("blue_accent", "500"),
    background: buildColor("white", "100")
  },
  info: {
    icon: info,
    fill: buildColor("blue_accent", "500"),
    background: buildColor("blue_accent", "100")
  },
  infoNoBackground: {
    icon: info,
    fill: buildColor("blue_accent", "500"),
    background: buildColor("white", "0")
  },
  warning: {
    icon: exclamation,
    fill: buildColor("yellow", "500"),
    background: buildColor("yellow", "100")
  },
  success: {
    icon: success,
    fill: buildColor("green", "600"),
    background: buildColor("green", "100")
  },
  error: {
    icon: error,
    fill: buildColor("red", "500"),
    background: buildColor("red", "100")
  },
  promos: {
    icon: checkPromo,
    fill: buildColor("green", "100"),
    background: buildColor("green", "000"),
    borderColor: buildColor("green", "200"),
    onlyBorderBottom: tvgConf().device === "desktop",
    borderRadius: tvgConf().device === "desktop" ? "4px 4px 0 0" : "4px",
    viewBoxSize: 16
  },
  referral: {
    icon: personRightArrow,
    strokeColorList: [null, null, buildColor("green", "600")],
    fill: buildColor("green", "100"),
    background: buildColor("green", "000"),
    viewBoxSize: 16,
    onlyBorderBottom: tvgConf().device === "desktop",
    borderColor: buildColor("green", "200"),
    borderRadius: tvgConf().device === "desktop" ? "4px 4px 0 0" : "4px"
  },
  promosInvalid: {
    icon: infoOutlineDuotone,
    background: buildColor("blue_accent", "000"),
    onlyBorderBottom: tvgConf().device === "desktop",
    borderColor: buildColor("blue_accent", "200"),
    borderRadius: tvgConf().device === "desktop" ? "4px 4px 0 0" : "0",
    colorList: [
      buildColor("blue_accent", "200"),
      buildColor("blue_accent", "600")
    ]
  },
  preferencesInfo: {
    icon: infoOutlineDuotone,
    background: buildColor("blue_accent", "000"),
    borderColor: buildColor("blue_accent", "200"),
    borderRadius: "4px",
    colorList: [
      buildColor("blue_accent", "200"),
      buildColor("blue_accent", "600")
    ],
    color: buildColor("blue_accent", "600")
  }
};

// todo: for now only info, warning and success have the alt style props
export const styleMapAlt: styleMapType = {
  default: {
    icon: info,
    fill: buildColor("blue_accent", "500"),
    background: buildColor("white", "0")
  },
  infoWhiteBackground: {
    icon: info,
    fill: buildColor("blue_accent", "500"),
    background: buildColor("white", "100")
  },
  info: {
    icon: infoOutline,
    fill: buildColor("blue_accent", "600"),
    iconBackground: buildColor("blue_accent", "200"),
    borderColor: buildColor("blue_accent", "200"),
    background: buildColor("blue_accent", "000")
  },
  infoNoBackground: {
    icon: info,
    fill: buildColor("blue_accent", "500"),
    background: buildColor("white", "0")
  },
  warning: {
    icon: warningOutline,
    fill: buildColor("orange", "700"),
    borderColor: buildColor("orange", "200"),
    background: buildColor("orange", "000")
  },
  success: {
    icon: successOutline,
    fill: buildColor("green", "600"),
    iconBackground: buildColor("green", "100"),
    borderColor: buildColor("green", "200"),
    background: buildColor("green", "000")
  },
  error: {
    icon: glassyError,
    fill: buildColor("red", "600"),
    borderColor: buildColor("red", "200"),
    background: buildColor("red", "000")
  },
  promos: {
    icon: checkPromo,
    fill: buildColor("green", "100"),
    background: buildColor("green", "100"),
    viewBoxSize: 16
  },
  referral: {
    icon: personRightArrow,
    strokeColorList: [null, null, buildColor("green", "600")],
    fill: buildColor("green", "100"),
    background: buildColor("green", "100"),
    viewBoxSize: 16
  },
  promosInvalid: {
    icon: infoOutlineDuotone,
    background: buildColor("blue_accent", "000"),
    colorList: [
      buildColor("blue_accent", "200"),
      buildColor("blue_accent", "600")
    ]
  },
  preferencesInfo: {
    icon: infoOutlineDuotone,
    background: buildColor("blue_accent", "000"),
    borderColor: buildColor("blue_accent", "200"),
    borderRadius: "4px",
    colorList: [
      buildColor("blue_accent", "200"),
      buildColor("blue_accent", "600")
    ],
    color: buildColor("blue_accent", "600")
  }
};

export default styleMap;
