import { createElement } from "react";
import { get } from "lodash";
import { StoryblokComponentPropsFormatted } from "../types/storyblok";
import { ONBOARDING_DICTIONARY } from "../constraints/onboardingDictionary";
import {
  StoryblokBlock,
  StorybookAvailableComponents
} from "../types/promoOnboardingComponents";

export const getStoryblokComponent = (
  storyblokComponent: StoryblokComponentPropsFormatted
) => {
  const { component: componentName, props } = storyblokComponent;
  const storyblokBlok: StoryblokBlock<StorybookAvailableComponents> = get(
    ONBOARDING_DICTIONARY,
    componentName,
    {} as StoryblokBlock<unknown>
  );
  const componentRender = get(storyblokBlok, "component", null);
  const componentProps = {
    ...get(storyblokBlok, "defaultProps", {}),
    ...props
  };

  return componentRender && createElement(componentRender, componentProps);
};
