import styled, { css } from "styled-components";
import { Paragraph } from "../Typography";

export const Card = styled.div<{ isGrouped: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 4px;
  border: 1px solid var(--border-subtle, #eaf0f6);
  background: var(--background-surface, #fff);
  ${({ isGrouped }) =>
    isGrouped &&
    css`
      flex: 0 0 auto;
      width: 343px;
    `}
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--space-space3, 12px) var(--space-space4, 16px);
  border-bottom: 1px solid var(--border-subtle, #eaf0f6);
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentSection = styled.div`
  position: relative;
  padding: var(--space-space3, 12px) var(--space-space4, 16px) 0;
  min-height: 150px;
`;

export const BodySection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const ButtonSection = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  padding: var(--space-space3, 12px) var(--space-space4, 16px);
  gap: var(--space-space3, 12px);
`;

export const BetsButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const SeeResultButton = styled.div`
  display: flex;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid var(--component-button-tertiary-border-base, #0070eb);
  box-shadow: 0 1px 3px 0 rgb(17 43 68 / 12%);
  overflow: hidden;
`;

export const RevealOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to top,
    rgb(155 155 155 / 90%) 0%,
    rgb(155 155 155 / 80%) 30%,
    rgb(155 155 155 / 60%) 60%,
    rgb(155 155 155 / 40%) 100%
  );
  cursor: pointer;
`;

export const RunnerContainer = styled.div`
  gap: var(--space-space2, 8px);
  min-height: 124px;
`;

export const PayoutsHeader = styled.div<{ showRunners: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding-bottom: var(--fd-space-space-2, 8px);
  gap: var(--fd-space-space-3, 12px);
  width: 127px;
  min-height: 26px;
  ${({ showRunners }) =>
    !showRunners &&
    css`
      opacity: 0.97;
      filter: blur(3px);
      pointer-events: none;
    `};
`;

export const PayoutHeaderValue = styled(Paragraph)`
  display: flex;
  justify-content: flex-end;
  width: 37px;
`;
