import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  height: 60px;
  width: 167px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 10px;
  align-items: center;
  gap: 4px;
`;
