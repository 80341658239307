import styled from "styled-components";

export const List = styled.ul`
  margin-top: var(--fd-space-space-3);
  border-radius: var(--fd-radii-border-radius-020);
  box-shadow: var(--fd-shadows-elevations-bottom-high);
  max-height: 300px;
  overflow: scroll;
  scroll-behavior: smooth;
`;

export const ListItem = styled.li`
  padding: var(--fd-space-space-4) var(--fd-space-space-5);
  cursor: pointer;
  background-color: var(--fd-colors-component-button-tertiary-background-base);

  &:hover {
    background-color: var(
      --fd-colors-component-button-tertiary-background-hover
    );
  }

  &[aria-selected="true"] {
    color: var(--fd-colors-component-button-tertiary-content-active);
    background-color: var(
      --fd-colors-component-button-tertiary-background-active
    );
  }
`;
