import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getWithdrawalsInformation,
  getPendingWithdrawOpen,
  getPendingWithdrawContent
} from "@tvg/sh-lib-paws/redux/selectors";
import WithdrawalsComponent from "@tvg/withdrawals";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import { usePendingWithdrawsModalState } from "@tvg/sh-lib-paws/hooks/usePendingWithdrawsModalState";
import { toggleModal } from "./utils";
import {
  PendingWithdrawContent,
  PendingWithdrawVisibilityToggle,
  WithdrawalsInfo
} from "../../types";

export const PendingWithdrawalsModal = () => {
  const dispatch = useDispatch();
  const { isOpen }: PendingWithdrawVisibilityToggle = useSelector(
    getPendingWithdrawOpen
  );

  const {
    isCancelModalOpen,
    closeCancelWithdrawal,
    showPendingWithdrawalsV2
  }: WithdrawalsInfo = useSelector(getWithdrawalsInformation);

  const { cancelModalContent }: PendingWithdrawContent = useSelector(
    getPendingWithdrawContent
  );

  usePendingWithdrawsModalState();

  const props = {
    title: isCancelModalOpen ? cancelModalContent.title : "Pending Withdrawals",
    isOpen: showPendingWithdrawalsV2 && isOpen,
    qaLabel: "modal-pendingWithdrawals",
    animation: "fade",
    fixedWidth: "375px",
    offsetTop: 0,
    isTitleCenter: !isCancelModalOpen,
    onClose: toggleModal(dispatch, false),
    onBack:
      isCancelModalOpen && closeCancelWithdrawal ? closeCancelWithdrawal : null,
    titleType: "default",
    useModalHeaderV3: true,
    showBottomShadow: false,
    hasOverlay: true,
    isFullWidth: false,
    hasRoundedCorners: true,
    isFullHeight: false
  };

  return (
    <ModalV2 {...props}>
      {() => (
        <WithdrawalsComponent
          dispatch={dispatch}
          device="tablet"
          closeModalCallback={toggleModal(dispatch, false)}
          isApp={false}
        />
      )}
    </ModalV2>
  );
};

export default memo(PendingWithdrawalsModal);
