import type { UserActions } from "../../actions/types";
import type { UserState } from "../types";

export const initialState: UserState = {
  error: false,
  hasRequested: false,
  logged: false,
  logging: false,
  user: {
    accountNumber: "",
    emailAddress: "",
    firstName: "",
    homeAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    lastName: "",
    mailingAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    primaryPhone: "",
    profile: "PORT-Generic",
    signalProviderId: "",
    status: "",
    transportId: "",
    userName: "",
    wagerStatus: 0,
    accountRoles: ["USER"]
  },
  preferences: {},
  gotPreferences: false,
  gotPromos: false,
  optedInPromos: {},
  wasLogin: false,
  balance: null,
  balancePoller: 120,
  returningUser: false
};

const userDataReducer = (
  state: UserState = initialState,
  action: UserActions
) => {
  switch (action.type) {
    case "USER_DATA_UPDATE": {
      return { ...state, ...action.payload };
    }
    case "USER_ACCOUNT_NUMBER": {
      return {
        ...state,
        user: { ...state.user, accountNumber: action.payload.accountNumber }
      };
    }
    case "USER_BALANCE": {
      return {
        ...state,
        balance: action.payload
      };
    }
    case "USER_BALANCE_SUCCESS": {
      return {
        ...state,
        balance: action.payload.balance
      };
    }

    default: {
      return state;
    }
  }
};

export default userDataReducer;
