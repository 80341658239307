export const data = {
  validErrorCodes: [
    107701,
    107501,
    306,
    107702,
    107705,
    "OLD_CCP_DISCOVER_CARD"
  ],
  title: "Card Declined",
  description:
    "Your deposit attend was declined, please select another option below",
  errors: {
    default: {
      first: ["ACH", "PAY", "CCP"],
      second: ["ACH", "PAY", "SLP"]
    },
    107501: {
      first: ["ACH", "PAY", "CCP"]
    },
    107701: {
      title: "Card Declined 107701",
      description:
        "Your deposit attend was declined, please select another option below 107701",
      first: ["ACH", "PAY", "CCP"],
      second: ["ACH", "PAY", "SLP"]
    },
    306: {
      first: ["ACH", "PAY", "CCP"]
    },
    107702: {
      first: ["ACH", "PAY", "CCP"]
    },
    107705: {
      first: ["ACH", "PAY", "CCP"]
    },
    OLD_CCP_DISCOVER_CARD: {
      first: ["ACH", "PAY", "CCP"]
    }
  },
  content: {
    ACH: {
      text: "eCheck",
      tagText: "no fees",
      details: [
        {
          title: "Why eCheck?",
          list: ["eCheck is safe, secure and even better free of deposit fees"]
        }
      ],
      buttonText: "Try with eCheck"
    },
    PAY: {
      text: "PayPal",
      details: [
        {
          title: "Existing PayPal customer?",
          list: ["Deposit quickly and securely right now!"]
        },
        {
          title: "Don’t have PayPal?",
          list: [
            "It takes just 5 minutes to set up your free, secure PayPal account"
          ]
        }
      ],
      buttonText: "Try with PayPal"
    },
    CCP: {
      text: "Another Card",
      details: [
        {
          text: "Some banks have restrictions on gaming transactions some other reassuring text that makes it seem legit",
          title: "Deposit if you have a card from one of these banks:",
          list: [
            "Chase Debit",
            "Wells Fargo Debit",
            "Citibank",
            "TD Bank",
            "PNC Bank"
          ]
        }
      ],
      buttonText: "Try with another card"
    },
    SLP: {
      text: "Pre-Paid Card",
      details: [
        {
          title: "Why Pre-paid play+?",
          paragraphs: [
            "The TVG Pre Paid Play+ is a simple, safe and secure way to fund your TVG account",
            "You’re one click away. Click below to continue using your card with TVG Pre Paid Play+"
          ]
        }
      ],
      buttonText: "Try with Pre-Paid Card"
    }
  }
};
