import styled, { css } from "styled-components";

export const RunnerListContainer = styled.ul<{ showRunners: boolean }>`
  list-style-type: none;
  padding: 0;
  margin: 0;
  ${({ showRunners }) =>
    !showRunners &&
    css`
      opacity: 0.97;
      filter: blur(3px);
      pointer-events: none;
    `};
`;
