// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("blue", "000")};
  }

  50% {
    fill: ${color("blue", "100")};
  }

  100% {
    fill: ${color("blue", "000")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 324px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export default class IppMask extends PureComponent<*> {
  render() {
    return (
      <div data-qa-label="ippMask">
        <SVG>
          <rect
            width="100%"
            height="324"
            fill={color("white", "100")}
            rx=".5"
          />
          <rect
            className="pulse"
            width="298"
            height="12"
            x="12"
            y="84"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="248"
            height="12"
            x="12"
            y="60"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="184"
            height="12"
            x="12"
            y="36"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="81"
            height="12"
            x="69"
            y="12"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="49"
            height="12"
            x="12"
            y="12"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="298"
            height="12"
            x="12"
            y="192"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="248"
            height="12"
            x="12"
            y="168"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="184"
            height="12"
            x="12"
            y="144"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="81"
            height="12"
            x="69"
            y="120"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="49"
            height="12"
            x="12"
            y="120"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="298"
            height="12"
            x="12"
            y="300"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="248"
            height="12"
            x="12"
            y="276"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="184"
            height="12"
            x="12"
            y="252"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="81"
            height="12"
            x="69"
            y="228"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            className="pulse"
            width="49"
            height="12"
            x="12"
            y="228"
            fill={color("white", "100")}
            rx="1"
          />
          <rect
            width="100%"
            height="1"
            y="107"
            fill={color("blue", "100")}
            rx=".5"
          />
          <rect width="100%" height="1" fill={color("blue", "100")} rx=".5" />
          <rect
            width="100%"
            height="1"
            y="215"
            fill={color("blue", "100")}
            rx=".5"
          />
          <rect
            width="100%"
            height="1"
            y="323"
            fill={color("blue", "100")}
            rx=".5"
          />
        </SVG>
      </div>
    );
  }
}
