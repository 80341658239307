import tvgConf from "@tvg/conf";
import { PromosWidgetPROResponse } from "@tvg/ts-types/Promos";
import getProtocol from "../protocolSetter";
import requester from "../requester";

const proService: string = "service.pro";

const fetchTopPromotions = (accountId?: string) =>
  requester()({
    method: "GET",
    url: accountId
      ? `${getProtocol()}${tvgConf().config(
          proService
        )}/users/${accountId}/top-promotions`
      : `${getProtocol()}${tvgConf().config(proService)}/top-promotions`,
    headers: {
      "x-tvg-context": tvgConf().context()
    },
    withCredentials: true
  }) as unknown as Promise<PromosWidgetPROResponse>;

const fetchUserPromoOptin = (accountId: string) =>
  requester()({
    method: "get",
    url: `${getProtocol()}${tvgConf().config(
      proService
    )}/users/${accountId}/promotions/opt-in`,
    headers: {
      "x-tvg-context": tvgConf().context(),
      "Content-Type": "application/json"
    },
    withCredentials: true
  });

const postUserPromoOptin = (accountId: string, promoId: number) =>
  requester()({
    method: "post",
    url: `${getProtocol()}${tvgConf().config(
      proService
    )}/users/${accountId}/promotions/${promoId}/opt-in`,
    headers: {
      "x-tvg-context": tvgConf().context(),
      "Content-Type": "application/json"
    },
    data: {
      source: "USER"
    },
    withCredentials: true
  });

const fetchUserPromoStatus = (accountId: string, promoId: number) =>
  requester()({
    method: "get",
    url: `${getProtocol()}${tvgConf().config(
      proService
    )}/users/${accountId}/promotions/${promoId}`,
    headers: {
      "x-tvg-context": tvgConf().context()
    },
    withCredentials: true
  });

const fetchAllEligiblePromos = (
  accountId: string,
  params: {
    promoType?: string;
    overrideContext?: boolean;
  } = {}
) =>
  requester()({
    method: "get",
    url: `${getProtocol()}${tvgConf().config(
      proService
    )}/users/${accountId}/promotions`,
    headers: {
      "x-tvg-context": tvgConf().context()
    },
    withCredentials: true,
    params
  });

const fetchAllRaces = (promoId: number) =>
  requester()({
    method: "get",
    url: `${getProtocol()}${tvgConf().config(
      proService
    )}/promotions/${promoId}/races`,
    headers: {
      "x-tvg-context": tvgConf().context()
    },
    withCredentials: true
  });

const fetchUserLeaderBoardStandings = (
  accountId: string,
  promoId: number,
  nearbyUsers: number = 2
) =>
  requester()({
    method: "get",
    url: `${getProtocol()}${tvgConf().config(
      proService
    )}/leaderboards/${promoId}/${accountId}/nearby-users?nearbyUsers=${nearbyUsers}`,
    headers: {
      "x-tvg-context": tvgConf().context()
    },
    withCredentials: true
  });

const fetchAllLeaderBoardStandings = (promoId: number, limit: number) =>
  requester()({
    method: "get",
    url: `${getProtocol()}${tvgConf().config(
      proService
    )}/leaderboards/${promoId}/top-users?limit=${limit}`,
    headers: {
      "x-tvg-context": tvgConf().context()
    }
  });

const fetchLeaderBoardStandingsByPagination = (
  promoId: number,
  round: number,
  page: number,
  pageSize: number
) =>
  requester()({
    method: "get",
    url: `${getProtocol()}${tvgConf().config(
      proService
    )}/leaderboards/${promoId}/${round}?page=${page}&pageSize=${pageSize}`,
    headers: {
      "x-tvg-context": tvgConf().context()
    }
  });

const fetchReferralStatus = (accountId: string) =>
  requester()({
    method: "get",
    url: `${getProtocol()}${tvgConf().config(
      proService
    )}/users/${accountId}/referral/status`,
    headers: {
      "x-tvg-context": tvgConf().context()
    },
    withCredentials: true
  });

const postReferralEmails = (
  accountId: string,
  referees: string[],
  referredBy: string
) =>
  requester()({
    method: "post",
    url: `${getProtocol()}${tvgConf().config(
      proService
    )}/users/${accountId}/referral`,
    headers: {
      "x-tvg-context": tvgConf().context(),
      "Content-Type": "application/json"
    },
    data: {
      referees,
      referredBy
    },
    withCredentials: true
  });

const fetchUserPromoSteps = (accountId: string, promoId: number) =>
  requester()({
    method: "get",
    url: `${getProtocol()}${tvgConf().config(
      proService
    )}/users/${accountId}/promotions/${promoId}/steps`,
    headers: {
      "x-tvg-context": tvgConf().context(),
      "Content-Type": "application/json"
    },
    withCredentials: true
  });

const PRO_SERVICE = {
  fetchTopPromotions,
  postUserPromoOptin,
  fetchUserPromoOptin,
  fetchUserPromoStatus,
  fetchAllEligiblePromos,
  fetchAllRaces,
  fetchUserLeaderBoardStandings,
  fetchAllLeaderBoardStandings,
  fetchLeaderBoardStandingsByPagination,
  fetchReferralStatus,
  postReferralEmails,
  fetchUserPromoSteps
};

export default PRO_SERVICE;
