import React, { useContext, useEffect, useRef } from "react";
import { TouchableHighlight } from "react-native";
import { useColorTokens } from "../../theming";
import { useQaLabel } from "../../hooks/useQaLabel";
import { ButtonBarProps, ButtonBarCompoundComponent } from "./types";
import { StyledTouchableHighlight, StyledText } from "./styled-components";
import { ButtonBarGroup, GroupContext } from "./buttonBarGroup";
import { useEvents } from "../../hooks/useEvents";

export const ButtonBar = (({
  children,
  isSelected = false,
  value,
  qaLabel,
  focusOnSelected = false,
  ...rest
}) => {
  const viewTestProps = useQaLabel(qaLabel);
  const colorTokens = useColorTokens();
  const { isCondensed = false } = useContext(GroupContext);
  const { events, eventsHandlers, setEvents } = useEvents({});
  const { selectedValue, onChange = () => {} } = useContext(GroupContext);
  const buttonRef = useRef<typeof TouchableHighlight | null>(null);

  useEffect(() => {
    setEvents({ ...events, isSelected });
  }, [isSelected]);

  useEffect(() => {
    setEvents({
      ...events,
      isSelected: isSelected || selectedValue === value
    });
  }, [selectedValue]);

  useEffect(() => {
    if (focusOnSelected && events.isSelected) {
      // @ts-ignore
      buttonRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center"
      });
    }
  }, [focusOnSelected, events.isSelected]);

  const pressHandler = () => {
    onChange(value);
  };

  return (
    <StyledTouchableHighlight
      // @ts-ignore
      ref={buttonRef}
      {...rest}
      {...viewTestProps}
      {...eventsHandlers}
      {...events}
      isCondensed={isCondensed}
      onPress={pressHandler}
      accessibilityRole="button"
      underlayColor={colorTokens.border.info}
      onLongPress={pressHandler}
    >
      {typeof children === "string" ? (
        <StyledText {...events} isCondensed={isCondensed}>
          {children}
        </StyledText>
      ) : (
        children
      )}
    </StyledTouchableHighlight>
  );
}) as ButtonBarCompoundComponent<typeof ButtonBarGroup>;

export { ButtonBarProps };
ButtonBar.Group = ButtonBarGroup;
export default ButtonBar;
