import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Home = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M21.375 9L12 3L2.625 9V21.375H9.375V13.5H14.625V21.375H21.375V9Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M9.375 21.375H2.625V9L12 3L21.375 9V21.375H14.625M9.375
      21.375V13.5H14.625V21.375M9.375 21.375H14.625"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
