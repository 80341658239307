import { PromoAcquisition } from "./promos";
import { PromoMappedSteps, PromoStepType } from "./promoSteps";
import {
  StoryblokComponentName,
  StoryblokComponentProps,
  StoryblokPlaceName,
  StorybookAvailableComponents
} from "./promoOnboardingComponents";

export type StoryblokComponentPropsFormatted<T = StorybookAvailableComponents> =
  {
    component: StoryblokComponentName;
    props: T;
  };

export type StoryblokMessagesFormatted = Partial<
  Record<StoryblokPlaceName, StoryblokComponentPropsFormatted>
>;

export interface StoryblokRenderPlace {
  component: string;
  places: Array<StoryblokPlaceName>;
}

export interface StoryblokMessages {
  component: string;
  promo_component: Array<StoryblokComponentProps>;
  render_place: Array<StoryblokRenderPlace>;
}

export interface StoryblokStepDescription {
  plugin: string;
  type: PromoStepType;
  description?: string;
  description_lost?: string;
  description_win?: string;
  description_processing?: string;
}

export interface StoryblokStep {
  component: string;
  messages: Array<StoryblokMessages>;
  step_config: StoryblokStepDescription;
}

export interface StoryblokContent {
  component: string;
  steps: Array<StoryblokStep>;
}

export enum StoryblokVersion {
  PUBLISHED = "published",
  DRAFT = "draft"
}

export interface StoryblokDraftConfig {
  draft?: StoryblokDraft;
  draftForceService?: PromoAcquisition;
}

export type StoryblokDraft = boolean | StoryblokVersion;

export interface StoryBlokComponentsFormatted {
  steps: PromoMappedSteps;
}

export enum StoryblokPlugin {
  TEXT = "promo-onboarding-component"
}
