import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Search = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M17.625 10.125C17.625 14.2671 14.2671 17.625 10.125 17.625C5.98286
      17.625 2.625 14.2671 2.625 10.125C2.625 5.98286 5.98286 2.625 10.125
      2.625C14.2671 2.625 17.625 5.98286 17.625 10.125Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M15.4283 15.4283L21 21M17.625 10.125C17.625 14.2671 14.2671 17.625
      10.125 17.625C5.98286 17.625 2.625 14.2671 2.625 10.125C2.625 5.98286
      5.98286 2.625 10.125 2.625C14.2671 2.625 17.625 5.98286 17.625 10.125Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);
