import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Tracks = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.741 4.12118C17.1373 4.12118 21.375 6.74507 21.375 12C21.375 17.2549
      17.1373 19.8788 13.741 19.8788H10.2592C6.97951 19.8788 2.625 17.2549 2.625
      12C2.625 6.74507 6.86288 4.12118 10.2592 4.12118H13.741ZM13.5
      8.62653H10.5C9 8.62653 7.1283 9.75 7.1283 12C7.1283 14.25 9.05151 15.3735
      10.5 15.3735H13.5C15 15.3735 16.8716 14.25 16.8716 12C16.8716 9.75 15
      8.62653 13.5 8.62653Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M13.741 4.12118C17.1373 4.12118 21.375 6.74507 21.375 12C21.375 17.2549
      17.1373 19.8788 13.741 19.8788H10.2592M13.741 4.12118H10.2592C6.86288
      4.12118 2.625 6.74507 2.625 12C2.625 17.2549 6.97951 19.8788 10.2592
      19.8788M13.741 4.12118H22.5M10.2592 19.8788H1.5M10.5 8.62653H13.5C15
      8.62653 16.8716 9.75 16.8716 12C16.8716 14.25 15 15.3735 13.5
      15.3735H10.5C9.05151 15.3735 7.1283 14.25 7.1283 12C7.1283 9.75 9
      8.62653 10.5 8.62653Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
