import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";

export const TermsAndConditionsText = styled.p`
  color: ${buildColor("grey", "900")};
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 17px;
`;

export const CallToActionClickable = styled.span`
  margin-left: 4px;
  cursor: pointer;
  color: ${buildColor("blue_accent", "500")};
`;

export const CallToActionSpan = styled.span``;
