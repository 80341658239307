import styled from "styled-components";
import { buildColor } from "@tvg/design-system/src/_static/colorPalette";

export const StatusOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${buildColor("black", "500")};
  z-index: 99999;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  h1 {
    text-align: center;
    font-size: 42px;
    line-height: 1.4;
  }
`;

export const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
