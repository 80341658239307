import { get } from "lodash";
import { DefaultColor, IconNames } from "../../_static/icons/types";
import { defaultIconColor } from "../../_static/icons/constraints";
import { Colors } from "../../theming";

export const getDefaultColor = (
  name: IconNames,
  themeColor: Colors,
  colors: Partial<DefaultColor>
): DefaultColor => {
  const defaultColor = get(defaultIconColor, name, {
    line: "grey.800",
    background: "white.000"
  });
  const line = get(themeColor, colors.line || defaultColor.line, colors.line);
  const background = get(
    themeColor,
    colors.background || defaultColor.background,
    colors.background
  );

  return {
    line,
    background
  };
};
