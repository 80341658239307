import { engageApptentiveEvent } from "../utils";

// when a user selects back to return to the home screen
export const backTapped = () =>
  engageApptentiveEvent("Back button/icon is tapped");

// when a user selects the FDR logo to go to home screen
export const fdrLogoTapped = () => engageApptentiveEvent("FDR Logo tapped");

// when a user selects home button
export const homeTapped = () => engageApptentiveEvent("Home tapped");

// when a users favorites a track
export const selectFavouriteTrack = () =>
  engageApptentiveEvent("Race track is favorited");

// when a user selects Earn $20 (RAF)
export const earnBonusTapped = () =>
  engageApptentiveEvent("Earn $20 (RAF) tapped");

// when a user selects "Tutorials" tab
export const selectTutorialsTab = () =>
  engageApptentiveEvent(`"Tutorials" is tapped`);

// when a user select "Learn horse racing"
export const selectLearnHorseRacing = () =>
  engageApptentiveEvent(`"Learn horse racing" is tapped`);
