import styled from "styled-components";
import type { OptionsProps, SelectProps } from "../types";

export const BoxWrapper = styled.div`
  position: relative;
`;

export const Select = styled.select<Pick<SelectProps, "fonts" | "fontsizes">>`
  appearance: none;
  height: 42px;
  background-color: transparent;
  border: transparent;
  z-index: 1;
  padding-left: ${({ theme }) => theme.space["space-4"]};
  padding-right: 36px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ fonts }) => fonts.regular};
  font-size: ${({ fontsizes }) => fontsizes.s};
  color: ${({ theme, disabled, value, placeholder }) =>
    disabled || (!value && placeholder)
      ? theme.colorTokens.content.disabled
      : theme.colorTokens.content.default};
`;

export const Option = styled.option<Pick<OptionsProps, "fonts">>`
  font-family: ${({ fonts }) => fonts.regular};
`;

export const SelectWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space["space-5"]};
  padding: ${({ theme }) => theme.space["space-4"]};
  width: 100%;
  box-shadow: ${({ theme }) => `0 2px 2px ${theme.elevation.low}`};
  border-color: blue;
  border-radius: 2px;
`;
