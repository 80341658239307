// @flow

import React, { PureComponent } from "react";
import styled, { keyframes, css } from "styled-components";
import type { Device } from "@tvg/types/Device";
import color from "../ColorPalette";

const SVGContainer = styled.div`
  width: 100%;
  ${(props) =>
    props.isVertical
      ? css`
          height: 192px;
          width: 224px;
        `
      : css`
          position: ${props.device === "mobile" ? "sticky" : "fixed"};
          height: 48px;
          background-color: ${color("blue", "700")};
        `};
`;

const pulseAnimation = keyframes`
  0% {
    fill: ${color("white", "10")};
  }
  
  50% {
    fill: ${color("white", "20")};
  }
  
  100% {
    fill: ${color("white", "10")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: ${(props) => (props.isVertical ? "192px" : "48px")};

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

type Props = {
  isVertical: boolean,
  device: Device
};

export default class QuickLinksMask extends PureComponent<Props> {
  static defaultProps = {
    isVertical: false,
    device: "mobile"
  };

  render() {
    return (
      <SVGContainer
        data-qa-label="quickLinksMask"
        isVertical={this.props.isVertical}
        device={this.props.device}
      >
        <SVG isVertical={this.props.isVertical}>
          {this.props.isVertical ? (
            <g fillRule="evenodd">
              <rect
                className="pulse"
                width="96"
                height="8"
                x="12"
                y="176"
                fill={color("white", "10")}
                rx="1"
              />
              <rect
                className="pulse"
                width="120"
                height="8"
                x="12"
                y="136"
                fill={color("white", "10")}
                rx="1"
              />
              <rect
                className="pulse"
                width="96"
                height="8"
                x="12"
                y="96"
                fill={color("white", "10")}
                rx="1"
              />
              <rect
                className="pulse"
                width="120"
                height="8"
                x="12"
                y="56"
                fill={color("white", "10")}
                rx="1"
              />
              <rect
                className="pulse"
                width="136"
                height="8"
                x="12"
                y="16"
                fill={color("white", "10")}
                rx="1"
              />
              <rect
                width="224"
                height="200"
                fill={color("white", "10")}
                fillRule="nonzero"
                rx="4"
              />
            </g>
          ) : (
            <g fillRule="evenodd">
              <rect
                className="pulse"
                fill={color("white", "10")}
                height="8"
                rx="1"
                width="88"
                x="12"
                y="20"
              />
              <rect
                className="pulse"
                fill={color("white", "10")}
                height="8"
                rx="1"
                width="88"
                x="236"
                y="20"
              />
              <rect
                className="pulse"
                fill={color("white", "10")}
                height="8"
                rx="1"
                width="88"
                x="124"
                y="20"
              />
              <rect
                className="pulse"
                fill={color("white", "10")}
                height="8"
                rx="1"
                width="88"
                x="348"
                y="20"
              />
              <rect
                className="pulse"
                fill={color("white", "10")}
                height="8"
                rx="1"
                width="88"
                x="460"
                y="20"
              />
            </g>
          )}
        </SVG>
      </SVGContainer>
    );
  }
}
