import React from "react";
import { IconPathProps } from "../../types";

export const ChevronUp = ({ lineColor, strokeWidth }: IconPathProps) => (
  <path
    d="M4.5 15L12 7.5L19.5 15"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
