import { Platform, View, Text } from "react-native";
import styled, { css } from "styled-components/native";

import { getAccessibilityRole } from "../../utils/accessibility";

const setDefaultCursor = Platform.OS === "web" && `cursor: default;`;

export const Toggle = styled(View)<{ on: boolean; isDisabled: boolean }>`
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radii.l};
  ${({ on, isDisabled, theme }) =>
    on
      ? css`
          background-color: ${isDisabled
            ? theme.colors.blue["000"]
            : theme.colors.blue_accent[500]};
        `
      : css`
          background-color: ${isDisabled
            ? theme.colors.blue["000"]
            : theme.colors.grey[300]};
        `};
  ${({ isDisabled }) => isDisabled && setDefaultCursor}
`;

export const Slider = styled(View)`
  width: 18px;
  height: 18px;
  border-radius: ${({ theme }) => theme.radii.m};
  background-color: ${({ theme }) => theme.colors.white[900]};
`;

export const TextArea = styled(Text).attrs(() => ({
  accessibilityRole: getAccessibilityRole("span")
}))<{ on: boolean; isDisabled: boolean }>`
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.blue[200] : theme.colors.white[900]};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.medium};
  ${(props) =>
    props.on
      ? css`
          margin-right: ${props.theme.space["space-6"]};
        `
      : css`
          margin-left: ${props.theme.space["space-6"]};
        `};
`;
