import axios from "axios";
import { get } from "lodash";
import tvgConf from "@tvg/conf";

const instance = axios.create();

function getInstance() {
  if (tvgConf().isRN) {
    if (get(global, "session.sessionId")) {
      instance.defaults.headers = {
        // @ts-ignore
        "tvg-session-id": get(global, "session.sessionId", null),
        tvg3Token: get(global, "session.tvg3Token", null)
      };
    }
  }

  return instance;
}

export default getInstance;
