import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Trash = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 6.375L5.86185 20.028C5.94033 20.7932 6.58486 21.375 7.35403
      21.375H16.646C17.4152 21.375 18.0597 20.7932 18.1382 20.028L19.5
      6.375H15.75H8.25H4.5Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M9.75 9V18.375M14.25 9V18.375M4.5 6.375H8.25M4.5 6.375H1.50008M4.5
      6.375L5.86185 20.028C5.94033 20.7932 6.58486 21.375 7.35403
      21.375H16.646C17.4152 21.375 18.0597 20.7932 18.1382 20.028L19.5
      6.375M19.5 6.375H15.75M19.5 6.375H22.5001M15.75 6.375H8.25M15.75
      6.375V4.125C15.75 3.29657 15.0784 2.625 14.25 2.625H9.75C8.92158 2.625
      8.25 3.29657 8.25 4.125V6.375"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);

export default Trash;
