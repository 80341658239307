// @flow

import React, { PureComponent } from "react";
import styled, { keyframes, css } from "styled-components";
import color from "../ColorPalette";

const SVGContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isDesktop ? "12px" : 0)};
  overflow: hidden;
`;

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "000")};
  }
  
  50% {
    fill: ${color("blue", "000")};
  }
  
  100% {
    fill: ${color("grey", "000")};
   }
`;

const SVG = styled.svg`
  ${({ isDesktop }) =>
    isDesktop
      ? css`
          width: 663px;
          height: 360px;
        `
      : css`
          width: 1032px;
          height: 352px;
        `}

  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }

  rect.stroke {
    stroke: ${color("blue", "100")};
    stroke-width: 1;
  }
`;

type Props = {
  isDesktop: boolean
};

/*
 TODO: Use buildColor to set the mask color
*/

export default class HPTalentPicksMask extends PureComponent<Props> {
  static defaultProps = {
    isDesktop: false
  };

  render() {
    return (
      <SVGContainer
        data-qa-label="HPTalentPicksMask"
        isDesktop={this.props.isDesktop}
      >
        <SVG isDesktop={this.props.isDesktop}>
          {this.props.isDesktop ? (
            <g fill="none" fillRule="evenodd" transform="translate(-53 -1526)">
              <rect width="1391" height="2692" fill="#E9EFF5" />
              <g transform="translate(53 1526)">
                <rect
                  width="663"
                  height="360"
                  fill="#FFF"
                  fillRule="nonzero"
                  rx="4"
                />
                <rect
                  width="130"
                  height="8"
                  x="12"
                  y="24"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="312"
                  height="291"
                  x="12.5"
                  y="56.5"
                  fill="#FFF"
                  fillRule="nonzero"
                  stroke="#E9EFF5"
                  rx="4"
                />
                <rect
                  width="130"
                  height="8"
                  x="24"
                  y="127"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect width="20" height="20" x="53" y="152" fill="#E9EFF5" />
                <rect width="20" height="20" x="53" y="264" fill="#E9EFF5" />
                <rect width="20" height="20" x="24" y="152" fill="#E9EFF5" />
                <rect width="20" height="20" x="24" y="180" fill="#E9EFF5" />
                <rect width="20" height="20" x="24" y="208" fill="#E9EFF5" />
                <rect width="20" height="20" x="24" y="236" fill="#E9EFF5" />
                <rect width="20" height="20" x="24" y="264" fill="#E9EFF5" />
                <rect width="20" height="20" x="53" y="236" fill="#E9EFF5" />
                <rect width="20" height="20" x="53" y="180" fill="#E9EFF5" />
                <rect width="20" height="20" x="53" y="208" fill="#E9EFF5" />
                <rect width="20" height="20" x="74" y="151" fill="#E9EFF5" />
                <rect width="20" height="20" x="74" y="152" fill="#E9EFF5" />
                <rect width="20" height="20" x="74" y="236" fill="#E9EFF5" />
                <rect width="20" height="20" x="74" y="180" fill="#E9EFF5" />
                <rect width="20" height="20" x="74" y="208" fill="#E9EFF5" />
                <rect width="20" height="20" x="95" y="152" fill="#E9EFF5" />
                <rect width="20" height="20" x="95" y="236" fill="#E9EFF5" />
                <rect width="20" height="20" x="95" y="180" fill="#E9EFF5" />
                <rect width="20" height="20" x="116" y="180" fill="#E9EFF5" />
                <rect
                  width="105"
                  height="39"
                  x="24.5"
                  y="296.5"
                  fill="#FFF"
                  stroke="#E9EFF5"
                  rx="2"
                />
                <rect
                  width="175"
                  height="40"
                  x="138"
                  y="296"
                  fill="#E9EFF5"
                  rx="2"
                />
                <path
                  fill="#FAFCFF"
                  d="M16,57 L321,57 C322.656854,57 324,58.3431458 324,60 L324,111 L13,111 L13,60 C13,58.3431458 14.3431458,57 16,57 Z"
                />
                <rect
                  width="21"
                  height="8"
                  x="25"
                  y="71"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="109"
                  height="8"
                  x="78"
                  y="71"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="85"
                  height="8"
                  x="78"
                  y="89"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect width="311" height="1" x="13" y="110" fill="#E9EFF5" />
                <rect
                  width="312"
                  height="291"
                  x="337.5"
                  y="56.5"
                  fill="#FFF"
                  fillRule="nonzero"
                  stroke="#E9EFF5"
                  rx="4"
                />
                <rect
                  width="130"
                  height="8"
                  x="349"
                  y="127"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect width="20" height="20" x="378" y="152" fill="#E9EFF5" />
                <rect width="20" height="20" x="378" y="264" fill="#E9EFF5" />
                <rect width="20" height="20" x="349" y="152" fill="#E9EFF5" />
                <rect width="20" height="20" x="349" y="180" fill="#E9EFF5" />
                <rect width="20" height="20" x="349" y="208" fill="#E9EFF5" />
                <rect width="20" height="20" x="349" y="236" fill="#E9EFF5" />
                <rect width="20" height="20" x="349" y="264" fill="#E9EFF5" />
                <rect width="20" height="20" x="378" y="236" fill="#E9EFF5" />
                <rect width="20" height="20" x="378" y="180" fill="#E9EFF5" />
                <rect width="20" height="20" x="378" y="208" fill="#E9EFF5" />
                <rect width="20" height="20" x="399" y="151" fill="#E9EFF5" />
                <rect width="20" height="20" x="399" y="152" fill="#E9EFF5" />
                <rect width="20" height="20" x="399" y="236" fill="#E9EFF5" />
                <rect width="20" height="20" x="399" y="180" fill="#E9EFF5" />
                <rect width="20" height="20" x="399" y="208" fill="#E9EFF5" />
                <rect width="20" height="20" x="420" y="152" fill="#E9EFF5" />
                <rect width="20" height="20" x="420" y="236" fill="#E9EFF5" />
                <rect width="20" height="20" x="420" y="180" fill="#E9EFF5" />
                <rect width="20" height="20" x="441" y="180" fill="#E9EFF5" />
                <rect
                  width="105"
                  height="39"
                  x="349.5"
                  y="296.5"
                  fill="#FFF"
                  stroke="#E9EFF5"
                  rx="2"
                />
                <rect
                  width="175"
                  height="40"
                  x="463"
                  y="296"
                  fill="#E9EFF5"
                  rx="2"
                />
                <path
                  fill="#FAFCFF"
                  d="M341,57 L646,57 C647.656854,57 649,58.3431458 649,60 L649,111 L338,111 L338,60 C338,58.3431458 339.343146,57 341,57 Z"
                />
                <rect
                  width="21"
                  height="8"
                  x="350"
                  y="71"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="109"
                  height="8"
                  x="403"
                  y="71"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="85"
                  height="8"
                  x="403"
                  y="89"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect width="311" height="1" x="338" y="110" fill="#E9EFF5" />
              </g>
            </g>
          ) : (
            <g fill="none" fillRule="evenodd" transform="translate(-53 -602)">
              <rect width="1391" height="2692" fill="#E9EFF5" />
              <g transform="translate(53 602)">
                <rect
                  width="1032"
                  height="352"
                  fill="#FFF"
                  fillRule="nonzero"
                />
                <rect
                  width="291"
                  height="291"
                  x="12.5"
                  y="48.5"
                  fill="#FFF"
                  fillRule="nonzero"
                  stroke="#E9EFF5"
                  rx="4"
                />
                <rect
                  width="130"
                  height="8"
                  x="24"
                  y="119"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="90"
                  height="8"
                  x="12"
                  y="21"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect width="20" height="20" x="53" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="53" y="256" fill="#E9EFF5" />
                <rect width="20" height="20" x="24" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="24" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="24" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="24" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="24" y="256" fill="#E9EFF5" />
                <rect width="20" height="20" x="53" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="53" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="53" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="74" y="143" fill="#E9EFF5" />
                <rect width="20" height="20" x="74" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="74" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="74" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="74" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="95" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="95" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="95" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="116" y="172" fill="#E9EFF5" />
                <rect
                  width="90"
                  height="39"
                  x="24.5"
                  y="288.5"
                  fill="#FFF"
                  stroke="#E9EFF5"
                  rx="2"
                />
                <rect
                  width="169"
                  height="40"
                  x="123"
                  y="288"
                  fill="#E9EFF5"
                  rx="2"
                />
                <path
                  fill="#FAFCFF"
                  d="M16,49 L300,49 C301.656854,49 303,50.3431458 303,52 L303,103 L13,103 L13,52 C13,50.3431458 14.3431458,49 16,49 Z"
                />
                <rect width="290" height="1" x="13" y="102" fill="#E9EFF5" />
                <rect
                  width="21"
                  height="8"
                  x="25"
                  y="63"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="109"
                  height="8"
                  x="78"
                  y="63"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="85"
                  height="8"
                  x="78"
                  y="81"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="291"
                  height="291"
                  x="312.5"
                  y="48.5"
                  fill="#FFF"
                  fillRule="nonzero"
                  stroke="#E9EFF5"
                  rx="4"
                />
                <rect
                  width="130"
                  height="8"
                  x="324"
                  y="119"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect width="20" height="20" x="353" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="353" y="256" fill="#E9EFF5" />
                <rect width="20" height="20" x="324" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="324" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="324" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="324" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="324" y="256" fill="#E9EFF5" />
                <rect width="20" height="20" x="353" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="353" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="353" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="374" y="143" fill="#E9EFF5" />
                <rect width="20" height="20" x="374" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="374" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="374" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="374" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="395" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="395" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="395" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="416" y="172" fill="#E9EFF5" />
                <rect
                  width="90"
                  height="39"
                  x="324.5"
                  y="288.5"
                  fill="#FFF"
                  stroke="#E9EFF5"
                  rx="2"
                />
                <rect
                  width="169"
                  height="40"
                  x="423"
                  y="288"
                  fill="#E9EFF5"
                  rx="2"
                />
                <path
                  fill="#FAFCFF"
                  d="M316,49 L600,49 C601.656854,49 603,50.3431458 603,52 L603,103 L313,103 L313,52 C313,50.3431458 314.343146,49 316,49 Z"
                />
                <rect width="290" height="1" x="313" y="102" fill="#E9EFF5" />
                <rect
                  width="21"
                  height="8"
                  x="325"
                  y="63"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="109"
                  height="8"
                  x="378"
                  y="63"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="85"
                  height="8"
                  x="378"
                  y="81"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="291"
                  height="291"
                  x="612.5"
                  y="48.5"
                  fill="#FFF"
                  fillRule="nonzero"
                  stroke="#E9EFF5"
                  rx="4"
                />
                <rect
                  width="130"
                  height="8"
                  x="624"
                  y="119"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect width="20" height="20" x="653" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="653" y="256" fill="#E9EFF5" />
                <rect width="20" height="20" x="624" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="624" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="624" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="624" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="624" y="256" fill="#E9EFF5" />
                <rect width="20" height="20" x="653" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="653" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="653" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="674" y="143" fill="#E9EFF5" />
                <rect width="20" height="20" x="674" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="674" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="674" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="674" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="695" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="695" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="695" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="716" y="172" fill="#E9EFF5" />
                <rect
                  width="90"
                  height="39"
                  x="624.5"
                  y="288.5"
                  fill="#FFF"
                  stroke="#E9EFF5"
                  rx="2"
                />
                <rect
                  width="169"
                  height="40"
                  x="723"
                  y="288"
                  fill="#E9EFF5"
                  rx="2"
                />
                <path
                  fill="#FAFCFF"
                  d="M616,49 L900,49 C901.656854,49 903,50.3431458 903,52 L903,103 L613,103 L613,52 C613,50.3431458 614.343146,49 616,49 Z"
                />
                <rect width="290" height="1" x="613" y="102" fill="#E9EFF5" />
                <rect
                  width="21"
                  height="8"
                  x="625"
                  y="63"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="109"
                  height="8"
                  x="678"
                  y="63"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="85"
                  height="8"
                  x="678"
                  y="81"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="291"
                  height="291"
                  x="912.5"
                  y="48.5"
                  fill="#FFF"
                  fillRule="nonzero"
                  stroke="#E9EFF5"
                  rx="4"
                />
                <rect
                  width="130"
                  height="8"
                  x="924"
                  y="119"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect width="20" height="20" x="953" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="953" y="256" fill="#E9EFF5" />
                <rect width="20" height="20" x="924" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="924" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="924" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="924" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="924" y="256" fill="#E9EFF5" />
                <rect width="20" height="20" x="953" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="953" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="953" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="974" y="143" fill="#E9EFF5" />
                <rect width="20" height="20" x="974" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="974" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="974" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="974" y="200" fill="#E9EFF5" />
                <rect width="20" height="20" x="995" y="144" fill="#E9EFF5" />
                <rect width="20" height="20" x="995" y="228" fill="#E9EFF5" />
                <rect width="20" height="20" x="995" y="172" fill="#E9EFF5" />
                <rect width="20" height="20" x="1016" y="172" fill="#E9EFF5" />
                <rect
                  width="90"
                  height="39"
                  x="924.5"
                  y="288.5"
                  fill="#FFF"
                  stroke="#E9EFF5"
                  rx="2"
                />
                <rect
                  width="169"
                  height="40"
                  x="1023"
                  y="288"
                  fill="#E9EFF5"
                  rx="2"
                />
                <path
                  fill="#FAFCFF"
                  d="M916,49 L1200,49 C1201.65685,49 1203,50.3431458 1203,52 L1203,103 L913,103 L913,52 C913,50.3431458 914.343146,49 916,49 Z"
                />
                <rect width="290" height="1" x="913" y="102" fill="#E9EFF5" />
                <rect
                  width="21"
                  height="8"
                  x="925"
                  y="63"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="109"
                  height="8"
                  x="978"
                  y="63"
                  fill="#E9EFF5"
                  rx="1"
                />
                <rect
                  width="85"
                  height="8"
                  x="978"
                  y="81"
                  fill="#E9EFF5"
                  rx="1"
                />
              </g>
            </g>
          )}
        </SVG>
      </SVGContainer>
    );
  }
}
