import React from "react";
import { IconPathProps } from "../../types";

export const Tick = ({ lineColor, strokeWidth }: IconPathProps) => (
  <path
    d="M3.75 12L9 17.25L20.25 6"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
