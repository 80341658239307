import { format, addMilliseconds, parseISO } from "date-fns";

const generateNewDate = (value: string) =>
  parseISO(value).toString() === "Invalid Date"
    ? new Date(value)
    : parseISO(value);

export const parseDate = (value: string | Date) =>
  typeof value === "string" ? generateNewDate(value) : value;

export const formatPostTime = (
  postTime: string | Date,
  showDate: boolean = false
): string =>
  postTime
    ? format(parseDate(postTime), showDate ? "MMM d h:mma" : "hh:mm")
    : postTime;

export const formatPeriod = (postTime: string | Date): string =>
  postTime ? format(parseDate(postTime), "a") : postTime;

export const formatRaceDate = (raceDate: string | Date): string =>
  raceDate ? format(parseDate(raceDate), "dd MMM yyyy") : raceDate;

export const formatDateWithText = (postTime: string | Date): string =>
  postTime ? format(parseDate(postTime), "MMM d ''yy") : postTime;

export const formatDateWithTextDayFirst = (postTime: string | Date): string =>
  postTime ? format(parseDate(postTime), "d MMM ''yy") : postTime;

export const formatPastRaceDate = (raceDate: string | Date): string =>
  raceDate ? format(parseDate(raceDate), "yyyy-MM-dd") : raceDate;

export const formatMonthDayDate = (postTime: string | Date): string =>
  postTime ? format(parseDate(postTime), "MMMM d") : postTime;

export const formatThreeLetterMonthDayDate = (
  postTime: string | Date
): string =>
  postTime ? format(parseDate(postTime), "MMMM").substr(0, 3) : postTime;

export const formatDayDate = (postTime: string | Date): string =>
  postTime ? format(parseDate(postTime), "d") : postTime;

export const formatSecondsToDuration = (seconds: number): string =>
  format(addMilliseconds(new Date(0), seconds * 1000), "mm:ss.SSS");

export const formatDateToMMDDYYYYhhmm = (date: string | Date): string =>
  date ? format(parseDate(date), "MM/dd/yyyy hh:mm a").toUpperCase() : date;

export const formatDateToMMDDYYYYSlashhhmm = (date: string | Date): string =>
  date ? format(parseDate(date), "MM/dd/yyyy - hh:mm a").toUpperCase() : date;

export const formatDateToMMDDYYYY = (date: string | Date): string =>
  date ? format(parseDate(date), "MM/dd/yyyy") : date;

export const formatDateTohhmm = (date: string | Date): string =>
  date ? format(parseDate(date), "hh:mm a").toUpperCase() : date;
