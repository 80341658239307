import React from "react";
import Icon from "@tvg/atomic-ui/_static/Icons";
import * as allIcons from "@tvg/atomic-ui/_static/Icons/icons";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import type { Props } from "./types";
import { getPawsOnlyIcons, getPaymentMethodIconName } from "../../utils";
import Flag from "../Flag";
import {
  Card,
  FlagContainer,
  CardIconContainer,
  CardText
} from "./styled-components";

const IconCard = ({
  text,
  isActive = false,
  onClick,
  paymentType,
  tagText
}: Props) => {
  const iconName = getPaymentMethodIconName(paymentType);
  const iconColor = isActive
    ? buildColor("blue_accent", "700")
    : buildColor("blue", "400");

  return (
    <Card
      data-qa-label="card-payment-option"
      onClick={onClick}
      isActive={isActive}
    >
      {!!tagText && (
        <FlagContainer>
          <Flag size="small" showIcon={false} text={tagText as string} />
        </FlagContainer>
      )}
      <CardIconContainer
        data-qa-label="card-icon-container"
        isActive={isActive}
      >
        {getPawsOnlyIcons(iconName, iconColor) || (
          <Icon icon={allIcons[iconName]} size={16} color={iconColor} />
        )}
      </CardIconContainer>
      <CardText data-qa-label="card-payment-option-text" isActive={isActive}>
        {text}
      </CardText>
    </Card>
  );
};

export default IconCard;
