import styled from "styled-components";
import { buildColor } from "@tvg/design-system";

export const PromoPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${buildColor("white", "900")};
`;

export const PromoPreviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
