import React from "react";
import { useColorTokens } from "../../theming/hooks/useColorTokens";
import { useQaLabel } from "../../hooks/useQaLabel";
import { Icon } from "../icon";
import { StyledText, TagContainer, tagVariants } from "./styled-components";
import { TagIcon } from "./tagIcon";
import { TagProps, TagVariant } from "./types";

export const Tag = ({
  qaLabel = "tag",
  label,
  icon,
  variant,
  ...props
}: TagProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  const bgColor = useColorTokens(
    tagVariants[variant as TagVariant].backgroundColor
  );
  const color = useColorTokens(
    tagVariants[variant as TagVariant].color
  ) as string;

  return (
    <TagContainer
      backgroundColor={bgColor}
      variant={variant}
      {...props}
      {...viewTestProps}
    >
      {icon && (
        <Icon
          name={icon}
          size="s"
          backgroundColor="transparent"
          lineColor={color}
          mr="space-1"
        />
      )}
      <StyledText color={color}>{label}</StyledText>
    </TagContainer>
  );
};

Tag.Icon = TagIcon;

export { TagProps, TagVariant };
