import constructRequest from "../codeBase";
import type { PaymentTypesAvailable } from "../types";
import { serviceResponse } from "../../types";

const getDepositLimits = (
  paymentType: PaymentTypesAvailable,
  depositData: Object
): Promise<serviceResponse> =>
  constructRequest(paymentType, "depositLimits", depositData);

const getDepositFee = (
  paymentType: PaymentTypesAvailable,
  depositData: Object
): Promise<serviceResponse> =>
  constructRequest(paymentType, "depositFee", depositData);

const postDeposit = (
  paymentType: PaymentTypesAvailable,
  depositData: Object
): Promise<serviceResponse> =>
  constructRequest(paymentType, "deposit", depositData);

const PAWS_DEPOSIT_SERVICE = {
  getDepositLimits,
  getDepositFee,
  postDeposit
};

export default PAWS_DEPOSIT_SERVICE;
