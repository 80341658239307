import React, { useContext, forwardRef } from "react";
import { View } from "react-native";
import { useQaLabel } from "../../hooks/useQaLabel";
import { RadioGroup, GroupContext } from "./radioGroup";
import { RadioProps, RadioCompoundComponent } from "./types";
import { useEvents } from "../../hooks/useEvents";
import {
  StyledPressableContainer,
  StyledViewChecker,
  StyledLabelContainer,
  StyledViewContainer,
  StyledLabel
} from "./styled-components";

export const Radio = forwardRef<View, RadioProps>(
  ({ value, label, isInvalid = false, qaLabel = value, ...rest }, ref) => {
    const {
      onChange,
      selectedValue,
      isDisabled = false
    } = useContext(GroupContext);
    const { events, eventsHandlers } = useEvents({});

    const pressHandler = () => {
      if (isDisabled) return;
      onChange(value);
    };

    return (
      <StyledViewContainer {...useQaLabel(qaLabel)}>
        <StyledPressableContainer
          {...rest}
          {...useQaLabel(`${qaLabel}-input`)}
          {...events}
          {...eventsHandlers}
          onPress={pressHandler}
          ref={ref}
          focusable
          accessibilityRole="radio"
          accessibilityState={{ selected: selectedValue === value }}
          accessibilityLabel={label}
          isInvalid={isInvalid}
          isChecked={selectedValue === value}
          isDisabled={isDisabled}
        >
          <StyledViewChecker
            isChecked={selectedValue === value}
            isDisabled={isDisabled}
          />
        </StyledPressableContainer>
        <StyledLabelContainer
          {...events}
          {...eventsHandlers}
          onPress={pressHandler}
        >
          <StyledLabel
            isDisabled={isDisabled}
            {...useQaLabel(`${qaLabel}-label`)}
          >
            {label}
          </StyledLabel>
        </StyledLabelContainer>
      </StyledViewContainer>
    );
  }
) as RadioCompoundComponent<typeof RadioGroup>;

export { RadioProps };
Radio.Group = RadioGroup;
export default Radio;
