import { createSlice } from "@reduxjs/toolkit";

export type State = {
  isOpen: boolean;
};

export const initialState: State = {
  isOpen: false
};

const quickDepositModalSlice = createSlice({
  name: "quickDepositModal",
  initialState,
  reducers: {
    toggleQuickDepositModal(state, action) {
      state.isOpen = action.payload;
    }
  }
});

export const { toggleQuickDepositModal } = quickDepositModalSlice.actions;

export default quickDepositModalSlice.reducer;
