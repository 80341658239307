import React, { forwardRef, useRef } from "react";
import { GestureResponderEvent, Linking, Platform, View } from "react-native";
import { useQaLabel } from "../../hooks/useQaLabel";
import Paragraph from "../typography/paragraph";
import { StyledPressable, StyledView } from "./styled-components";
import { LinkProps } from "./types";
import { getColor } from "./utils/getColor";
import { useEvents } from "../../hooks/useEvents";
import { useActive } from "../../hooks/webEvents";

const isWeb = Platform.OS === "web";

export const Link = forwardRef<View, LinkProps>(
  (
    {
      qaLabel,
      href,
      children,
      isDarkMode = false,
      onPress,
      style,
      target = "_self",
      ...props
    },
    ref
  ) => {
    const viewTestProps = useQaLabel(qaLabel);
    const refLink = useRef(null);
    const { events, eventsHandlers } = useEvents({});
    const isActive = useActive(refLink);

    const pressHandler = (e: GestureResponderEvent): void => {
      // Allow always to have a onPress call if function is passed
      if (typeof onPress === "function") {
        onPress();
      }

      if (!isWeb || target === "_blank") {
        Linking.openURL(href!);
        e.preventDefault();
      }
    };

    return (
      <StyledView ref={ref} {...props}>
        <StyledPressable
          accessibilityRole="link"
          // @ts-ignore
          href={isWeb && target !== "_blank" && href}
          onPress={pressHandler}
          ref={refLink}
          {...viewTestProps}
          {...eventsHandlers}
        >
          <Paragraph
            fontFamily="medium"
            color={getColor(
              events.isHovered,
              events.isPressed,
              isActive,
              isDarkMode
            )}
            qaLabel={`${qaLabel}-text`}
            style={style}
          >
            {children}
          </Paragraph>
        </StyledPressable>
      </StyledView>
    );
  }
);

export { LinkProps };
