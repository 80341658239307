import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const UserTick = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M7.27024 13.4973H16.7295C17.7442 13.4973 18.6334 14.1765 18.9003
      15.1555L20.0811 19.4867C20.3413 20.4409 19.6229 21.3813 18.6339
      21.3813H5.3658C4.37679 21.3813 3.65848 20.4409 3.91862 19.4867L5.09947
      15.1555C5.36637 14.1765 6.25552 13.4973 7.27024 13.4973Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M15.7522 6.37019C15.7522 8.44241 14.0723 10.1223 12.0001 10.1223C9.9279
      10.1223 8.24803 8.44241 8.24803 6.37019C8.24803 4.29797 9.9279 2.6181
      12.0001 2.6181C14.0723 2.6181 15.7522 4.29797 15.7522 6.37019Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M12.75 21.3813H5.36602C4.37702 21.3813 3.6587 20.4409 3.91884
      19.4867L5.09969 15.1555C5.36659 14.1765 6.25575 13.4973 7.27046
      13.4973H12.75M14.25 18L17.25 21L23.25 15M15.7522 6.37019C15.7522 8.44241
      14.0723 10.1223 12.0001 10.1223C9.9279 10.1223 8.24803 8.44241 8.24803
      6.37019C8.24803 4.29797 9.9279 2.6181 12.0001 2.6181C14.0723 2.6181
      15.7522 4.29797 15.7522 6.37019Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
