import React, { FC, memo } from "react";

import { useQaLabel } from "../../../hooks";
import { StyledHeader } from "./styled-components";
import { HeaderProps } from "./types";

const Header: FC<HeaderProps> = memo(
  ({
    children,
    fontFamily = "medium",
    textOverflow = "clip",
    whiteSpace = "normal",
    color = "grey.900",
    tag = "h1",
    qaLabel = "header",
    ...rest
  }) => (
    <StyledHeader
      accessibilityRole="header"
      fontFamily={fontFamily}
      color={color}
      tag={tag}
      textOverflow={textOverflow}
      whiteSpace={whiteSpace}
      {...useQaLabel(qaLabel)}
      {...rest}
    >
      {children}
    </StyledHeader>
  )
);

export { Header, HeaderProps };
