import { createContext } from "react";
import type { SCContextProps } from "./types";

const initialState: SCContextProps = {
  selectedValue: "",
  isDisabled: false
};

export const SCContext = createContext<SCContextProps>(initialState);
SCContext.displayName = "SCContext";
