import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontMedium } from "@tvg/atomic-ui/_static/Typography";

export const TableWrapper = styled.table`
  width: 100%;
  border-radius: 4px;
  background-color: ${buildColor("white", "100")};
  border-collapse: separate;
`;

export const BodySectionWrapper = styled.tbody`
  tr {
    &:not(:last-of-type) td {
      box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
    }

    &:hover {
      background-color: ${buildColor("blue_accent", "000")};

      td:first-of-type {
        box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")},
          inset 2px 0 0 ${buildColor("blue_accent", "500")};
      }

      &:last-of-type td:first-of-type {
        box-shadow: inset 2px 0 0 ${buildColor("blue_accent", "500")};
      }
    }

    &:last-of-type {
      border-radius: 0 0 4px 4px;

      td:first-of-type {
        border-radius: 0 0 0 4px;
      }

      td:last-of-type {
        border-radius: 0 0 4px 0;
      }
    }
  }
`;

export const HeadCellWrapper = styled.th<{
  colSize?: string;
  addShadow?: boolean;
  isPositionRelative?: boolean;
}>`
  text-align: left;
  padding: 12px;
  line-height: 17px;
  font-size: 12px;
  font-weight: 500;
  font-family: ${fontMedium};
  border-right: 1px solid ${buildColor("blue", "100")};
  color: ${buildColor("grey", "900")};
  background-color: ${buildColor("blue_accent", "000")};
  width: ${(props) => props.colSize || "auto"};
  box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")},
    0 -4px 0 ${buildColor("blue", "000")};
  background-clip: padding-box;
  ${(props) =>
    props.addShadow &&
    css`
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        bottom: -1px;
        left: 0;
        z-index: -1;
        transform: scale(1);
        box-shadow: 0px 0px 2px 1px rgba(17, 43, 68, 0.15);
      }
    `};

  &:first-of-type {
    border-radius: 4px 0 0 0;
  }

  &:last-of-type {
    border-radius: 0 4px 0 0;
    border-right: 0 none;
  }

  ${(props) =>
    props.isPositionRelative &&
    css`
      padding-bottom: 36px;
      position: relative;
    `}
`;

export const BodyCellWrapper = styled.td<{
  colSize?: string;
  isPositionRelative?: boolean;
}>`
  padding: 12px;
  vertical-align: top !important;
  width: ${(props) => props.colSize || "auto"};
  border-right: 1px solid ${buildColor("blue", "100")};
  position: relative;

  &:last-of-type {
    border-right: 0 none;
  }

  ${(props) =>
    props.isPositionRelative &&
    css`
      padding-bottom: 36px;
      position: relative;
    `}
`;

export const HeadSectionWrapper = styled.thead<{
  headerSticky?: boolean;
}>`
  ${(props) =>
    props.headerSticky &&
    css`
      th {
        position: sticky;
        top: 0;
        z-index: 4;
      }
    `};
`;

export const RowWrapper = styled.tr``;
