import type { NullaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import { AddressSelection, PaymentType } from "../../types";

export interface Props {
  onSubmit: UnaryFn<AddressSelection, void>;
  isOpen: boolean;
  onCloseMethod: NullaryFn<void>;
  paymentType: PaymentType;
  accountID: string;
  messageType?: MessageType;
  onCancelCb: NullaryFn<void>;
}

export enum MessageType {
  WITHDRAW,
  BILLING
}
