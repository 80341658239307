import styled from "styled-components/native";
import { BadgeContainerProps } from "../../types";
import { space } from "../../../../theming/styledSystem";
import { themeGet } from "../../../../theming";

export const BadgeContainer = styled.View<BadgeContainerProps>`
  background-color: ${(props) =>
    props.variant === "primary"
      ? props.theme.colors.red[500]
      : props.theme.colors.blue_accent[500]};
  border-radius: 12px;
  height: 16px;
  justify-content: center;
  max-width: ${(props) => props.badgeWidth}px;
  min-width: 16px;
  padding: ${(props) =>
    props.isCircle ? "0" : `1px ${props.theme.space["space-2"]}`};
  ${space};
`;

export const BadgeText = styled.Text`
  color: ${themeGet("colors.white.900")};
  font-family: ${themeGet("fonts.condensedRegular")};
  font-size: ${themeGet("fontSizes.xs")};
  text-align: center;
`;
