import React from "react";
import { ButtonProps, ButtonSize } from "./types";
import { StyledButton, TERNARY_COLOR_CODE } from "./styled-components";
import { ButtonContainer } from "./container";
import { useEvents } from "../../hooks/useEvents";

export const Button = ({
  variant,
  size = "l",
  children,
  onPress,
  isLoading = false,
  iconPosition = "start",
  icon,
  tertiaryColor = TERNARY_COLOR_CODE,
  isMobile = false,
  qaLabel,
  ...rest
}: ButtonProps) => {
  const { events, hoverHandlers, pressHandlers, focusHandlers } = useEvents({});
  const disabled = rest.isDisabled || isLoading;

  return (
    <StyledButton
      variant={variant}
      size={size}
      disabled={disabled}
      isLoading={isLoading}
      activeOpacity={1}
      tertiaryColor={tertiaryColor}
      isMobile={isMobile}
      onPress={onPress}
      qaLabel={qaLabel}
      {...rest}
      {...events}
      {...hoverHandlers}
      {...pressHandlers}
      {...focusHandlers}
    >
      <ButtonContainer
        iconPosition={iconPosition}
        variant={variant}
        isLoading={isLoading}
        size={size}
        icon={icon}
        onPress={onPress}
        qaLabel={qaLabel}
        tertiaryColor={tertiaryColor}
        isMobile={isMobile}
      >
        {children}
      </ButtonContainer>
    </StyledButton>
  );
};

export { ButtonProps, ButtonSize, StyledButton, TERNARY_COLOR_CODE };
