import { string } from "yup";
import type { Validations } from "../types";

export const requiredSchema = (validations: Validations) =>
  string().required(validations.required);

export const routingNumberSchema = (validations: Validations) =>
  string()
    .required(validations.required)
    .matches(/[0-9]{9}/, {
      message: validations.invalidDigits,
      excludeEmptyString: true
    });

export const checkingAccountsNumberSchema = (validations: Validations) =>
  string()
    .required(validations.required)
    .matches(/[0-9]{6,17}/, {
      message: validations.invalidDigits,
      excludeEmptyString: true
    });

export const nameOnAccountSchema = (validations: Validations) =>
  string().required(validations.required);
