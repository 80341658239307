import { Space } from "../../types";

export const space: Space = {
  "space-1": "4px",
  "space-2": "6px",
  "space-3": "8px",
  "space-4": "12px",
  "space-5": "16px",
  "space-6": "20px",
  "space-7": "24px",
  "space-8": "32px"
};
