// @flow
import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { type Dispatch } from "redux";
import { noop, get } from "lodash";
import WithdrawalsTemplate, {
  type WithdrawCancelInformation,
  type Withdrawal
} from "@tvg/atomic-ui/_templates/Withdrawals";
import PendingWithdrawsTemplate from "@tvg/atomic-ui/_templates/PendingWithdraws";
import type { NullaryFn } from "@tvg/types/Functional";
import mediator from "@tvg/mediator";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { updateBalance } from "@tvg/shared-actions/UserActions";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import { getAccountNumber } from "@urp/store-selectors";

import {
  type Actions,
  openCancelWithdrawal,
  setWithdrawals,
  errorCancellingWithdrawal,
  cancelWithdrawalLoading,
  closeCancelWithdrawal,
  resetMessages,
  clearErrorCancellingWithdrawal,
  setPendingLoading
} from "./actions";
import service from "./service";

type Props = {
  dispatch: Dispatch<Actions>,
  cancelInformation: WithdrawCancelInformation,
  withdrawals: Withdrawal[],
  showCancelSuccessfulMessage: boolean,
  isCancelModalOpen: boolean,
  customerServicesUrl: string,
  closeModalCallback: NullaryFn<mixed>,
  accountNumber: string,
  device: "mobile" | "tablet",
  isApp: boolean,
  showPendingWithdrawalsV2: boolean,
  isLogged: boolean
};

export class WithdrawalsComponent extends Component<Props> {
  static defaultProps = {
    withdrawals: [],
    cancelInformation: {
      id: "",
      date: "",
      type: "",
      amount: 0,
      isLoading: false,
      onCancelClose: noop,
      onCancelWithdraw: noop,
      hasError: false,
      errorMessage: ""
    },
    showCancelSuccessfulMessage: false,
    isCancelModalOpen: false,
    customerServicesUrl: "",
    closeModalCallback: noop,
    accountNumber: "",
    device: "mobile",
    isApp: false,
    showPendingWithdrawalsV2: false,
    isLogged: false
  };

  componentDidMount() {
    this.props.dispatch(setPendingLoading(true));
    return service
      .getWithdrawals(this.props.accountNumber)
      .then((withdrawals) => {
        const finalWithdrawals = withdrawals.map((w) => ({
          ...w,
          onCancel: () => {
            mediator.base.dispatch({
              type: "PENDING_WITHDRAWALS_CANCEL_CLICK",
              payload: {
                accountId: this.props.accountNumber,
                withdrawalAmount: w.amount,
                withdrawalType: w.type
              }
            });

            this.props.dispatch(
              openCancelWithdrawal({
                id: w.id,
                date: w.date,
                type: w.type,
                amount: w.amount,
                isLoading: false,
                hasError: false,
                errorMessage: "",
                onCancelClose: () => {
                  mediator.base.dispatch({
                    type: "PENDING_WITHDRAWALS_CLOSE_CONFIRMATION",
                    payload: {
                      accountId: this.props.accountNumber,
                      withdrawalAmount: w.amount,
                      withdrawalType: w.type
                    }
                  });

                  this.props.dispatch(closeCancelWithdrawal(false));
                },
                onCancelWithdraw: () =>
                  this.cancelPendingWithdrawal(w.id, w.amount, w.type)
              })
            );
          }
        }));

        this.props.dispatch(setWithdrawals(finalWithdrawals));
        return finalWithdrawals;
      })
      .finally(() => {
        this.props.dispatch(setPendingLoading(false));
      });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isLogged && !this.props.isLogged) {
      this.props.closeModalCallback();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetMessages());
    this.props.dispatch(closeCancelWithdrawal(false));
  }

  cancelPendingWithdrawal(
    withdrawalId: string,
    withdrawalAmount: number,
    withdrawalType: string
  ) {
    this.props.dispatch(cancelWithdrawalLoading(true));

    mediator.base.dispatch({
      type: "PENDING_WITHDRAWALS_CANCEL_ATTEMPT",
      payload: {
        accountId: this.props.accountNumber,
        withdrawalAmount,
        withdrawalType
      }
    });

    return service
      .cancelWithdrawal(this.props.accountNumber, withdrawalId)
      .then((r) => {
        if (r.status === 200) {
          mediator.base.dispatch({
            type: "PENDING_WITHDRAWALS_CANCEL_SUCCESS",
            payload: {
              accountId: this.props.accountNumber,
              withdrawalAmount,
              withdrawalType
            }
          });

          this.props.dispatch(
            setWithdrawals(
              this.props.withdrawals.filter((w) => w.id !== withdrawalId)
            )
          );
          this.props.dispatch(closeCancelWithdrawal(true));
          this.props.dispatch(updateBalance(r.data.balance));
          mediatorClassic.dispatch("BALANCE_UPDATE", r.data.balance);
          setTimeout(() => this.props.dispatch(resetMessages()), 3000);
        } else {
          mediator.base.dispatch({
            type: "PENDING_WITHDRAWALS_CANCEL_ERROR",
            payload: {
              accountId: this.props.accountNumber,
              withdrawalAmount,
              withdrawalType
            }
          });

          this.props.dispatch(
            errorCancellingWithdrawal("Error canceling withdrawal", "")
          );
        }
      })
      .catch((err) => {
        mediator.base.dispatch({
          type: "PENDING_WITHDRAWALS_CANCEL_ERROR",
          payload: {
            accountId: this.props.accountNumber,
            withdrawalAmount,
            withdrawalType
          }
        });

        this.props.dispatch(
          errorCancellingWithdrawal(
            get(err, "response.data.message", "Error canceling withdrawal"),
            get(err, "response.data.code", "")
          )
        );
      })
      .then(() => this.props.dispatch(cancelWithdrawalLoading(false)));
  }

  onCustomerService = () => {
    const { logged, user } = useSelector((store) => get(store, "userData", {}));

    mediator.base.dispatch({
      type: "PENDING_WITHDRAWALS_CALL_CS",
      payload: {
        accountId: this.props.accountNumber,
        withdrawalAmount: this.props.cancelInformation.amount,
        withdrawalType: this.props.cancelInformation.type
      }
    });
    openLiveChat();
  };

  render() {
    return this.props.showPendingWithdrawalsV2 ? (
      <PendingWithdrawsTemplate
        withdrawals={this.props.withdrawals}
        onCloseErrorCancellingModal={() => {
          this.props.dispatch(clearErrorCancellingWithdrawal());
        }}
        closeModalCallback={this.props.closeModalCallback}
      />
    ) : (
      <WithdrawalsTemplate
        withdrawals={this.props.withdrawals}
        isCancelModalOpen={this.props.isCancelModalOpen}
        customerServicesUrl={this.props.customerServicesUrl}
        customerServicesCallback={this.onCustomerService}
        closeModalCallback={this.props.closeModalCallback}
        showCancelSuccessfulMessage={this.props.showCancelSuccessfulMessage}
        cancelInformation={this.props.cancelInformation}
        device={this.props.device}
      />
    );
  }
}

export default connect(
  (store) => ({
    isLogged: get(store, "userData.logged", false),
    withdrawals: store.withdrawals.withdrawals,
    cancelInformation: store.withdrawals.cancelInformation,
    showCancelSuccessfulMessage: store.withdrawals.showCancelSuccessfulMessage,
    isCancelModalOpen: store.withdrawals.isCancelModalOpen,
    accountNumber: getAccountNumber(store),
    customerServicesUrl: get(
      store,
      "capi.messages.globalSupportLink",
      "https://support.tvg.com"
    ),
    showPendingWithdrawalsV2: get(
      store,
      "capi.featureToggles.pendingWithdrawalsV2",
      false
    )
  }),
  (dispatch: Dispatch<Actions>) => ({ dispatch })
)(WithdrawalsComponent);
