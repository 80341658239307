// @flow
import React, { Component, Fragment } from "react";
import { noop } from "lodash";
import formatCurrency from "@tvg/formatter/currency";
import type { NullaryFn } from "@tvg/types/Functional";
import withdrawMap from "@tvg/utils/withDraws";

import Icon from "../../_static/Icons";
import { trash } from "../../_static/Icons/icons";
import {
  WithdrawalsSection,
  Table,
  TheadText,
  WithdrawalsEmptyContainer,
  EmptyMessageContainer,
  EmptyMessageText,
  WithdrawDeletionInfo,
  WithdrawDeletionMessage,
  WithdrawDeletionText,
  WithdrawDeletionActions,
  CancelButton,
  CustomButton,
  CustomerSupportLink
} from "./styled-components";

import buildColor from "../../_static/ColorPalette";
import ProcessingOverlay from "../../_static/ProcessingOverlay";
import ModalV2 from "../ModalV2";
import MessageBox from "../../_molecule/MessageBox";
import Button from "../../_atom/Buttons/button";

export type Withdrawal = {
  id: string,
  date: string,
  type: string,
  amount: number,
  onCancel: NullaryFn<mixed>
};

export type WithdrawCancelInformation = {
  id: string,
  date: string,
  type: string,
  amount: number,
  isLoading: boolean,
  onCancelClose: NullaryFn<mixed>,
  onCancelWithdraw: NullaryFn<mixed>,
  hasError: boolean,
  errorMessage: string
};

type Props = {
  withdrawals: Withdrawal[],
  isCancelModalOpen: boolean,
  customerServicesUrl: string,
  customerServicesCallback: NullaryFn<mixed>,
  closeModalCallback: NullaryFn<mixed>,
  showCancelSuccessfulMessage: boolean,
  cancelInformation: WithdrawCancelInformation,
  device: "mobile" | "tablet"
};

export default class Withdrawals extends Component<Props> {
  static defaultProps = {
    withdrawals: [],
    isCancelModalOpen: false,
    customerServicesUrl: "",
    customerServicesCallback: noop,
    closeModalCallback: noop,
    showCancelSuccessfulMessage: false,
    cancelInformation: {
      id: "",
      date: "",
      type: "",
      amount: 0,
      isLoading: false,
      onCancelClose: noop,
      onCancelWithdraw: noop,
      hasError: false,
      errorMessage: ""
    },
    device: "mobile"
  };

  getEmptyStateMessage = () => (
    <EmptyMessageContainer>
      <EmptyMessageText>NO WITHDRAWALS AVAILABLE</EmptyMessageText>
      <Button type="secondary" onClick={this.props.closeModalCallback}>
        BACK TO MORE
      </Button>
    </EmptyMessageContainer>
  );

  renderWithdrawals = () => (
    <Fragment>
      <Table hasSuccessMessage={this.props.showCancelSuccessfulMessage}>
        <thead>
          <tr>
            <th>
              <TheadText>Date</TheadText>
            </th>
            <th>
              <TheadText>Type</TheadText>
            </th>
            <th colSpan={2}>
              <TheadText>Amount</TheadText>
            </th>
          </tr>
        </thead>
        {!!this.props.withdrawals.length && (
          <tbody>
            {this.props.showCancelSuccessfulMessage && (
              <tr>
                <td colSpan={4} style={{ padding: "8px 8px 0" }}>
                  <MessageBox
                    type="info"
                    message="Funds will be available to bet shortly"
                    title="Withdrawal cancelled"
                    hasIcon
                  />
                </td>
              </tr>
            )}
            {this.props.withdrawals.map((w) => (
              <tr
                key={`withdrawal-${w.id}`}
                data-qa-label="withdrawals-withdrawal"
              >
                <td data-qa-label="withdrawals-withdrawal-date">
                  <span>{w.date}</span>
                </td>
                <td data-qa-label="withdrawals-withdrawal-type">
                  <span>{withdrawMap(w.type)}</span>
                </td>
                <td data-qa-label="withdrawals-withdrawal-amount">
                  <span>{formatCurrency(w.amount)}</span>
                </td>
                <td data-qa-label="withdrawals-withdrawal-cancelButton">
                  <CancelButton onClick={w.onCancel}>
                    <Icon
                      icon={trash}
                      size={16}
                      color={buildColor("grey", "900")}
                    />
                  </CancelButton>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {this.props.withdrawals.length === 0 && (
        <WithdrawalsEmptyContainer>
          <MessageBox
            type="info"
            message={this.getEmptyStateMessage()}
            hasIcon
            isVertical
          />
        </WithdrawalsEmptyContainer>
      )}
    </Fragment>
  );

  renderWithdrawalsDeleteModal = () => {
    const baseProps = {
      title: "Cancel Withdrawal?",
      isOpen: this.props.isCancelModalOpen,
      onClose: this.props.cancelInformation.onCancelClose,
      qaLabel: "withdrawals-cancelModal",
      offsetTop: 0,
      isFullHeight: false,
      layerOffset: 1
    };

    const props =
      this.props.device === "mobile"
        ? {
            ...baseProps,
            offsetRight: 8,
            offsetLeft: 8,
            animation: "bottomFloating"
          }
        : { ...baseProps, isFullWidth: false, animation: "fade" };

    return (
      <ModalV2 {...props}>
        {() => (
          <Fragment>
            {this.props.cancelInformation.isLoading && (
              <ProcessingOverlay isRounded />
            )}
            <WithdrawDeletionInfo>
              {this.props.cancelInformation.hasError ? (
                <Fragment>
                  <WithdrawDeletionMessage>
                    <MessageBox
                      type="error"
                      message={this.props.cancelInformation.errorMessage}
                      hasIcon
                    />
                  </WithdrawDeletionMessage>
                  <WithdrawDeletionText>
                    Check your internet connection and try again.
                    <br />
                    If problems continue please contact customer services
                  </WithdrawDeletionText>
                </Fragment>
              ) : (
                <WithdrawDeletionText>
                  The funds will be put back into your account and available to
                  bet
                </WithdrawDeletionText>
              )}
            </WithdrawDeletionInfo>
            {!this.props.cancelInformation.hasError && (
              <Table isCancelTable>
                <tbody>
                  <tr data-qa-label="withdrawals-cancelWithdrawal">
                    <td data-qa-label="withdrawals-cancelWithdrawal-date">
                      <span>{this.props.cancelInformation.date}</span>
                    </td>
                    <td data-qa-label="withdrawals-cancelWithdrawal-type">
                      <span>
                        {withdrawMap(this.props.cancelInformation.type)}
                      </span>
                    </td>
                    <td data-qa-label="withdrawals-cancelWithdrawal-amount">
                      <span>
                        {formatCurrency(this.props.cancelInformation.amount)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            <WithdrawDeletionActions>
              {this.props.cancelInformation.hasError ? (
                <Fragment>
                  <CustomerSupportLink
                    target="_blank"
                    href={this.props.customerServicesUrl}
                    onClick={this.props.customerServicesCallback}
                  >
                    CONTACT CUSTOMER SERVICES
                  </CustomerSupportLink>
                  <Button
                    size="big"
                    type="primary"
                    onClick={this.props.cancelInformation.onCancelWithdraw}
                  >
                    RETRY
                  </Button>
                </Fragment>
              ) : (
                <Fragment>
                  <CustomButton
                    onClick={this.props.cancelInformation.onCancelClose}
                  >
                    KEEP IT
                  </CustomButton>
                  <Button
                    size="big"
                    type="delete"
                    onClick={this.props.cancelInformation.onCancelWithdraw}
                  >
                    CANCEL WITHDRAWAL
                  </Button>
                </Fragment>
              )}
            </WithdrawDeletionActions>
          </Fragment>
        )}
      </ModalV2>
    );
  };

  render() {
    return (
      <WithdrawalsSection data-qa-label="withdrawals">
        {this.renderWithdrawals()}
        {this.renderWithdrawalsDeleteModal()}
      </WithdrawalsSection>
    );
  }
}
