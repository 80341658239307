import React from "react";
import { useDispatch } from "react-redux";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import { get } from "lodash";
import MagnetScrollArea from "@tvg/atomic-ui/_templates/MagnetScrollArea";
import useOtherPaymentsModal from "@tvg/sh-lib-paws/hooks/useOtherPaymentsDeposit";
import { OtherPaymentsProps } from "../common/types";
import { onClose } from "./eventHandler";
import { isDesktop, isMobile } from "../../../utils";
import { OtherPaymentsInfo } from "./styled-components";
import { getContent, getTabs } from "./renderer";

const hasTouchMove = !isDesktop;

const OtherPaymentsDepositModal = ({
  otherMethods,
  onCloseCallback,
  promosOnboarding
}: OtherPaymentsProps) => {
  const dispatch = useDispatch();
  const { value, setValue, isOpen, content } =
    useOtherPaymentsModal(otherMethods);

  return (
    <ModalV2
      showBottomShadow={false}
      isOpen={isOpen}
      title={get(content, "title", "Other Payment Methods")}
      onClose={onClose(dispatch, onCloseCallback)}
      onOverlayClick={onClose}
      qaLabel="otherPayments-funds-modal"
      animation={isMobile ? "bottom" : "fade"}
      offsetLeft={isMobile ? 0 : 12}
      offsetRight={isMobile ? 0 : 12}
      fixedWidth={isMobile ? "100%" : "375px"}
      isFullWidth={isMobile}
      offsetTop={55}
      isFullHeight={isMobile}
      hasRoundedCorners={!isMobile}
      isTitleCapitalized={false}
      isTitleCenter
      useModalHeaderV3
    >
      {() => (
        <OtherPaymentsInfo>
          <MagnetScrollArea
            isLoading={false}
            isFreeScroll
            isRunnerScroll
            activeIndex={value}
            hasTouchMove={hasTouchMove}
          >
            {getTabs(content.tabs, setValue, value)}
          </MagnetScrollArea>
          {getContent(content.tabs, value, promosOnboarding)}
        </OtherPaymentsInfo>
      )}
    </ModalV2>
  );
};

export default OtherPaymentsDepositModal;
