// @flow
// $FlowFixMe
import React from "react";
import tvgConf from "@tvg/conf";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { type Dispatch } from "redux";
import { get } from "lodash";
import type { UnaryFn } from "@tvg/types/Functional";
import type { PaymentTypeItem, PaymentType } from "@tvg/wallet/src/types";
import { getPaymentMethodIconName } from "@tvg/wallet/src/utils";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import ListItem from "@tvg/wallet/src/components/ListItem";
import { getPawsContent } from "@tvg/sh-lib-paws/utils/content";
import { closeOtherPaymentMethodsModal } from "@tvg/sh-lib-paws/redux/slices/otherPaymentMethodsSlice";
import { Container } from "./styled-components";

type Props = {
  isOpen: boolean,
  otherMethods: Array<PaymentTypeItem>,
  onClick: UnaryFn<PaymentType, void>
};

export const closeModal = (dispatch: Dispatch<*>) => {
  dispatch(closeOtherPaymentMethodsModal());
};

const selectContentFromPaws = (state) => {
  const content = getPawsContent(state);
  const title = get(
    content,
    "otherPaymentMethodsModal.title",
    "Deposit with other payment methods"
  );
  return {
    title
  };
};

export const OtherPaymentMethodsModal = ({
  isOpen,
  otherMethods,
  onClick
}: Props) => {
  const dispatch = useDispatch();
  const methodsInfos = useSelector((store) =>
    parseCapiMessage(store, "capi.messages.availablePaymentMethodsInfos", {})
  );
  const { title } = useSelector(selectContentFromPaws, shallowEqual);

  const isMobile = tvgConf().device === "mobile";

  return (
    <ModalV2
      title={title}
      isOpen={isOpen}
      onClose={() => closeModal(dispatch)}
      onOverlayClick={() => closeModal(dispatch)}
      titleType="info"
      qaLabel="other-payment-methods-modal"
      animation={isMobile ? "bottom" : "bottomFloating"}
      offsetLeft={isMobile ? 0 : 12}
      offsetRight={isMobile ? 0 : 12}
      fixedWidth={isMobile ? "100%" : "375px"}
      isFullWidth={isMobile}
      offsetTop={66}
      isFullHeight={!isMobile}
    >
      {() => (
        <Container>
          {otherMethods
            .sort((a, b) => a.priority - b.priority)
            .map((method, index) => (
              <ListItem
                key={`other-payment-methods-${index.toString()}`}
                iconName={getPaymentMethodIconName(method.paymentType)}
                title={get(methodsInfos[method.paymentType], "title")}
                isDisabled={
                  !method.depositsAvailable && !method.withdrawalsAvailable
                }
                onClick={() =>
                  onClick({
                    ...method,
                    title: get(methodsInfos[method.paymentType], "title"),
                    description: get(
                      methodsInfos[method.paymentType],
                      "description"
                    )
                  })
                }
              />
            ))}
        </Container>
      )}
    </ModalV2>
  );
};

export default OtherPaymentMethodsModal;
