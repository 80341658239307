import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { attempt, get } from "lodash";

export const useDataFromStore = () => {
  const [isLoading, setIsLoading] = useState(true);

  const data = useSelector((store) => ({
    walletMessages: get(store, "capi.messages.desktopWalletMessages", "")
  }));

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  return { ...attempt(() => JSON.parse(data.walletMessages)), isLoading };
};
