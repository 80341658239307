// @flow
import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";
import { buildText } from "../../_atom/Text";
import { titleFont, fontBold } from "../../_static/Typography";

export const WithdrawalsSection = styled.section`
  background-color: ${buildColor("white", "100")};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  background-color: ${buildColor("white", "100")};
  ${(props) =>
    props.isCancelTable &&
    css`
      border-top: 1px solid ${buildColor("blue", "100")};
      table-layout: fixed;
    `};

  & > thead {
    background-color: ${buildColor("blue", "100")};
    border-bottom: 1px solid ${buildColor("grey", "300")};

    th {
      padding: 13px 8px;
    }
  }

  & > tbody {
    ${(props) =>
      !props.isCancelTable &&
      css`
        tr {
          border-bottom: 1px solid ${buildColor("blue", "100")};
        }
      `};
    ${(props) =>
      props.hasSuccessMessage &&
      css`
        tr:first-child {
          border-bottom: none;
        }
      `};

    td > span {
      padding: 16px 8px;
      font-size: 12px;
      text-transform: uppercase;
      display: block;
    }
  }
`;

export const TheadText = styled(
  buildText({
    uppercase: true,
    fontSize: 12,
    color: buildColor("grey", "900")
  })
)`
  font-family: ${titleFont};
`;

export const EmptyMessageText = buildText({
  uppercase: true,
  bold: true,
  fontSize: 12,
  color: buildColor("grey", "900")
});

export const CancelButton = styled.button`
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding-right: 8px;
  padding-left: 8px;
  outline: none;
  background: none;
`;

export const WithdrawalsEmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  flex-grow: 1;
  padding: 18px 0;
`;

export const EmptyMessageContainer = styled.div`
  & > span {
    display: block;
    margin-bottom: 8px;
  }
`;

export const WithdrawDeletionInfo = styled.div`
  padding: 12px 8px;
  background-color: ${buildColor("white", "100")};
`;

export const WithdrawDeletionText = styled.p`
  font-size: 12px;
  color: ${buildColor("grey", "900")};
`;

export const WithdrawDeletionActions = styled.div`
  background-color: ${buildColor("blue", "000")};
  border-top: 1px solid ${buildColor("grey", "300")};
  padding: 8px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
`;

export const WithdrawDeletionMessage = styled.div`
  margin-bottom: 8px;
`;

export const CustomButton = styled.button`
  height: 40px;
  font-size: 12px;
  background-color: transparent;
  color: ${buildColor("blue_accent", "500")};
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 12px 0 4px;
`;

export const CustomerSupportLink = styled.a`
  background: transparent;
  font-size: 12px;
  color: ${buildColor("blue_accent", "500")};
  font-family: ${fontBold};
  text-transform: uppercase;
  padding: 0 12px 0 4px;
  line-height: 40px;
  text-decoration: none;
`;
