import React, { memo, forwardRef } from "react";
import { View } from "react-native";
import { useQaLabel } from "../../hooks/useQaLabel";
import { calculateBadgeWidth } from "./utils";
import { BadgeProps, BadgeCounterProps, BadgeCompoundComponent } from "./types";
import { BadgeCounter } from "./components";
import {
  WrapperContainer,
  Positioner,
  ChildrenContainer
} from "./styled-components";

export const Badge = memo(
  forwardRef<View, BadgeProps>(
    ({ children, ...badgeInnerProps }: BadgeProps, ref) => {
      const containerQaProps = useQaLabel("badge-container");
      return (
        <WrapperContainer {...containerQaProps}>
          <ChildrenContainer>
            <Positioner
              badgeWidth={calculateBadgeWidth(
                badgeInnerProps.counter,
                badgeInnerProps.counterLimit
              )}
              pointerEvents="none"
            >
              <BadgeCounter {...badgeInnerProps} ref={ref} />
            </Positioner>
            {children}
          </ChildrenContainer>
        </WrapperContainer>
      );
    }
  )
) as BadgeCompoundComponent<typeof BadgeCounter>;

Badge.Counter = BadgeCounter;

export { BadgeProps, BadgeCounterProps };
export default Badge;
