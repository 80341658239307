import styled, { css } from "styled-components";

export const FilterButtonContainer = styled.button<{
  isActive?: boolean;
  disabled?: boolean;
}>`
  ${({ theme, isActive, disabled }) => css`
    padding: ${theme.space["space-3"]};
    border-radius: ${theme.radii.s};
    font-family: ${theme.fonts.regular};
    font-size: ${theme.fontSizes.s};
    background-color: ${isActive
      ? theme.colors.blue_accent["700"]
      : theme.colorTokens.component.button.tertiary.background.base};
    color: ${(disabled && theme.colorTokens.content.disabled) ||
    (isActive
      ? theme.colorTokens.content.onDark
      : theme.colorTokens.content.link)};
  `}
`;
