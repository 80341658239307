import {
  SetUserFavoriteTrack,
  SetUserFavoriteTracks
} from "@tvg/tracks/src/v2/redux/actions";
import { ChannelUFC } from "@tvg/ts-types/Alerts";
import { FavoriteTrackWithId } from "@tvg/ts-types/User";

export type UpdateBalanceActionType = {
  type: "USER_BALANCE_SUCCESS";
  payload: { balance: number };
};

export type UpdateWalletBalancesActionType = {
  type: "USER_WALLET_BALANCES_SUCCESS";
  payload: {
    balance: number;
    bonus: number;
    sharedDeposits: number;
    sharedWinnings: number;
  };
};

export type FailBalanceRequestAction = {
  type: "USER_BALANCE_FAIL";
  payload: Error;
  error: true;
};

export type UpdatePromoFundsActionType = {
  type: "USER_PROMO_FUNDS_SUCCESS";
  payload: { promoFunds: number };
};

export type SetFavoriteTrackActionType = {
  type: "SET_FAVORITE_TRACK";
  payload: {
    trackCode: string;
    favoriteId?: number;
  };
};

export type RemoveFavoriteTrackActionType = {
  type: "REMOVE_FAVORITE_TRACK";
  payload: {
    trackCode: string;
  };
};

export type SetDefaultBetPrefsAction = {
  type: "SET_DEFAULT_BET_PREFERENCES";
  payload: {};
};

export type SetNewPreferenceValue = {
  type: "SET_NEW_PREFERENCE_VALUE";
  payload: {
    preferenceKey: string;
    preferenceValue: string;
  };
};

export type SetRaceAlertsActionType = {
  type: "SET_RACE_ALERTS";
  payload: {
    channels: ChannelUFC[];
  };
};

export type SetUserFavoriteTracksWithIdActionType = {
  type: "SET_USER_FAVORITE_TRACKS_WITH_FAVORITE_ID";
  payload: {
    favoriteTracksWithId: FavoriteTrackWithId;
  };
};

export type AddFavoriteTrackWithId = {
  type: "SET_USER_FAVORITE_TRACKS_WITH_FAVORITE_ID";
  payload: {
    trackCode: string;
    favoriteId: number;
  };
};

export type SessionStartUpdate = {
  type: "USER_SESSION_START_UPDATE";
  payload: {
    sessionStartAt: Date;
  };
};

export const updateBalance = (balance: number): UpdateBalanceActionType => ({
  type: "USER_BALANCE_SUCCESS",
  payload: { balance }
});

export const failBalanceRequest = (err: Error): FailBalanceRequestAction => ({
  type: "USER_BALANCE_FAIL",
  payload: err,
  error: true
});

export const updateWalletBalances = (
  balance: number,
  bonus: number,
  sharedDeposits: number,
  sharedWinnings: number
): UpdateWalletBalancesActionType => ({
  type: "USER_WALLET_BALANCES_SUCCESS",
  payload: { balance, bonus, sharedDeposits, sharedWinnings }
});

export const updatePromoFunds = (
  promoFunds: number
): UpdatePromoFundsActionType => ({
  type: "USER_PROMO_FUNDS_SUCCESS",
  payload: { promoFunds }
});

export const setFavoriteTrack = (
  trackCode: string,
  favoriteId?: number
): SetFavoriteTrackActionType => ({
  type: "SET_FAVORITE_TRACK",
  payload: { trackCode, favoriteId }
});

export const removeFavoriteTrack = (
  trackCode: string
): RemoveFavoriteTrackActionType => ({
  type: "REMOVE_FAVORITE_TRACK",
  payload: { trackCode }
});

export const setDefaultBetPrefs =
  (preferences: {}): SetDefaultBetPrefsAction => ({
    type: "SET_DEFAULT_BET_PREFERENCES",
    payload: preferences
  });

export const setNewPreferenceValue = (
  preferenceKey: string,
  preferenceValue: string
): SetNewPreferenceValue => ({
  type: "SET_NEW_PREFERENCE_VALUE",
  payload: { preferenceKey, preferenceValue }
});

export const setRaceAlerts = (
  channels: ChannelUFC[]
): SetRaceAlertsActionType => ({
  type: "SET_RACE_ALERTS",
  payload: { channels }
});

export const setUserFavoriteTracksWithId = (
  favoriteTracksWithId: FavoriteTrackWithId
): SetUserFavoriteTracksWithIdActionType => ({
  type: "SET_USER_FAVORITE_TRACKS_WITH_FAVORITE_ID",
  payload: { favoriteTracksWithId }
});

export const setUserFavoriteTracks = (
  favoriteTracks: string[]
): SetUserFavoriteTracks => ({
  type: "SET_USER_FAVORITE_TRACKS",
  payload: { favoriteTracks }
});

export const setUserFavoriteTrack = (
  trackCode: string,
  favoriteId: number
): SetUserFavoriteTrack => ({
  type: "SET_FAVORITE_TRACK",
  payload: { trackCode, favoriteId }
});

export const sessionStartUpdate = (
  sessionStartAt: Date
): SessionStartUpdate => ({
  type: "USER_SESSION_START_UPDATE",
  payload: {
    sessionStartAt
  }
});
