import { openLiveChat } from "@tvg/utils/liveChatUtils";
import formatCurrency from "@tvg/formatter/currency";
import React from "react";
import { InfoMessageProps } from "../types";
import NoPaymentMethods from "../../messages/noPaymentMethods";
import EmptyBalance from "../../messages/emptyBalance";
import PlayableBalance from "../../messages/playableBalance";
import {
  hasNoBalance,
  hasOnlyPlayableBalance
} from "../../common/balanceUtils";

export const InfoMessage = ({
  showInfoMessage,
  withdrawalLimit,
  capiMaxWithdrawAmount,
  onClose,
  accountsOnFile,
  isQuickWithdraw
}: InfoMessageProps) => {
  if (!showInfoMessage) return null;

  if (accountsOnFile.length === 0 && withdrawalLimit === 0 && isQuickWithdraw) {
    return <NoPaymentMethods onClose={onClose} onContact={openLiveChat} />;
  }
  if (hasNoBalance(withdrawalLimit, capiMaxWithdrawAmount))
    return <EmptyBalance onClose={onClose} />;

  const pBalance = formatCurrency(capiMaxWithdrawAmount);

  if (hasOnlyPlayableBalance(withdrawalLimit, capiMaxWithdrawAmount))
    return <PlayableBalance pBalance={pBalance} onClose={onClose} />;

  return null;
};
