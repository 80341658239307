import React from "react";
import { buildColor } from "@tvg/design-system";
// import Icon from "@tvg/design-system/src/_static/icons";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { lock } from "@tvg/atomic-ui/_static/Icons/icons";
import { DesktopWalletHeaderMask } from "@tvg/atomic-ui/_static/Masks";
import { useDataFromStore } from "@tvg/sh-lib-paws/hooks/useDesktopWalletDataFromStore";
import {
  Wrapper,
  InfoContainer,
  Title,
  SubTitle,
  WalletDescription
} from "./styled-components";

const DesktopHeader = () => {
  const { title = "", description = "", isLoading } = useDataFromStore();

  return (
    <Wrapper data-qa-label="walletHeader">
      {isLoading ? (
        <DesktopWalletHeaderMask />
      ) : (
        <InfoContainer data-qa-label="walletHeaderContainer">
          <WalletDescription data-qa-label="walletHeaderContentContainer">
            <Title data-qa-label="walletHeaderTitle">{title}</Title>
            <SubTitle data-qa-label="walletHeaderDescription">
              <Icon size={16} icon={lock} color={buildColor("grey", "800")} />
              <span>{description}</span>
            </SubTitle>
          </WalletDescription>
        </InfoContainer>
      )}
    </Wrapper>
  );
};

export default DesktopHeader;
