// @flow
import React, { type ElementRef, type Node } from "react";
import {
  debounce,
  get,
  isNumber,
  noop,
  isFunction,
  isEqual,
  isUndefined,
  attempt,
  isError,
  negate
} from "lodash";
import type { NullaryFn, BinaryFn, UnaryFn } from "@tvg/types/Functional";
import type { VideoSRC, RaceStatusEnum } from "@tvg/types/Race";

import OverlayControls from "./OverlayControls";
import { Container, StyledVideo } from "./styled-components";
import StreamVideo from "./Stream";

type VideoSkippingActionType = "fwd" | "rew";

type Props = {
  /**
   * Race id
   */
  raceId: string,
  /**
   * Video source for hd and sd
   */
  src: VideoSRC,
  /**
   * Is replay video
   */
  isReplay: boolean,
  /**
   * Background image
   */
  backGroundImage: string | null,
  /**
   * Overlay over the video
   */
  renderOnOverlay?: UnaryFn<boolean, Node>,
  /**
   * Is an error present
   */
  error: boolean,
  /**
   * Callback when video stream is ready
   */
  startCallback: NullaryFn<void>,
  /**
   * Callback when video stream is ready
   */
  errorCallback: UnaryFn<?{ message: string, code: Number }, void>,
  /**
   * Makes video occupy the parents full space available
   */
  inlineVideo: boolean,
  /**
   * Function to be called when video is un/muted (is called with true if video is being muted, false otherwise)
   */
  onVideoMute: UnaryFn<boolean, void>,
  /**
   * Function to be called when video is un/set to fullscreen (is called with true if video is being set to fullscreen, false otherwise)
   */
  onVideoFullScreen: BinaryFn<boolean, boolean, void>,
  /**
   * Function to be called when user presses play
   */
  onVideoPlay: NullaryFn<void>,
  /**
   * Function to be called when user presses pause
   */
  onVideoPause: NullaryFn<void>,
  /**
   * Function to be called when video is successfully loaded
   */
  onVideoSuccess: NullaryFn<void>,
  /**
   * Function to be called when video throws an error (is called with the message displayed to the user)
   */
  onVideoError: UnaryFn<string, void>,
  /**
   * Race mtp
   */
  mtp: number,
  /**
   * Race status
   */
  raceStatus: RaceStatusEnum,
  /**
   * Flag that enables/disables videoFullscreenTilting
   */
  videoFullscreenTilt: boolean,
  /**
   * Callback to lock/unlock rotation on iOS devices to activate/deactivate rotation enabled fullscreen
   */
  deviceLockRotationToggle: UnaryFn<boolean, void>,
  /**
   * Reload streams on stream change
   */
  reloadStreams: NullaryFn<Promise<mixed>>,
  /**
   * callback when switching video type
   */
  onSwitchVideoType: UnaryFn<boolean, void>,
  /**
   * callback when navigate on video
   */
  onNavigateVideo: UnaryFn<boolean, void>,
  /**
   * activate autoPlay
   */
  isAutoPlay: boolean,
  /**
   * new stream video
   */
  hasNewStreamVideo: boolean,
  /**
   * remove fullscreen button in overlay controls
   */
  disableFullscreen: boolean
};

type event = SyntheticEvent<HTMLButtonElement>;

type State = {
  controlsVisible: boolean,
  quality: "sd" | "hd",
  playing: boolean,
  fullscreen: boolean,
  muted: boolean,
  ended: boolean,
  buffering: boolean,
  loading: boolean,
  feedSwitchLoading: boolean,
  error: boolean,
  skipping: boolean,
  live: boolean,
  raceId: string,
  videoSRC: ?string,
  src: VideoSRC,
  retry: number,
  loadingTimeout: TimeoutID,
  updateIndex: number,
  isFullUnmute: boolean,
  autoPlay: boolean
};

export const calcVideoContainerHeight = () => {
  const width = get(window, "innerWidth");
  return isNumber(width) ? Math.min(Math.round((width / 16) * 9), 284) : 180;
};

class Video extends React.Component<Props, State> {
  static defaultProps = {
    src: {
      sd: null,
      hd: null,
      replay: null
    },
    mtp: 0,
    feedSwitchLoading: false,
    raceStatus: "IC",
    error: false,
    isReplay: false,
    backGroundImage: null,
    inlineVideo: false,
    onVideoMute: noop,
    onVideoFullScreen: noop,
    onVideoPlay: noop,
    onVideoPause: noop,
    onVideoSuccess: noop,
    onVideoError: noop,
    renderOnOverlay: noop,
    startCallback: noop,
    errorCallback: noop,
    videoFullscreenTilt: false,
    deviceLockRotationToggle: noop,
    reloadStreams: () => new Promise(noop),
    onSwitchVideoType: noop,
    onNavigateVideo: noop,
    isAutoPlay: false,
    hasNewStreamVideo: false,
    disableFullscreen: false
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      controlsVisible: true,
      quality: "hd",
      playing: this.props.isAutoPlay,
      fullscreen: false,
      muted: this.props.isAutoPlay,
      isFullUnmute: true,
      ended: false,
      buffering: false,
      loading: true,
      loadingTimeout: setTimeout(() => {
        this.setState({
          loading: false,
          feedSwitchLoading: false,
          error: true
        });
      }, 10000),
      error: false,
      skipping: false,
      live: !props.isReplay,
      raceId: props.raceId,
      feedSwitchLoading: false,
      videoSRC: !this.props.isReplay
        ? this.props.src.hd
        : this.props.src.replay,
      src: {
        sd: get(this.props, "sd", null),
        hd: get(this.props, "hd", null),
        replay: get(this.props, "replay", null)
      },
      retry: 0,
      updateIndex: 0,
      autoPlay: this.props.isAutoPlay
    };

    // big hack to play videos on chrome
    if (typeof window !== "undefined" && !!window.chrome) {
      attempt(() => {
        // $FlowBug
        const element = document.getElementById("videoHack");
        // $FlowBug
        element.classList.add("videoHack");
      });
    }
  }

  componentDidMount() {
    this.props.reloadStreams();
    // $FlowBug
    document.addEventListener(
      "webkitfullscreenchange",
      this.exitFullScreenMobile,
      true
    );
    // $FlowBug
    document.addEventListener(
      "mozfullscreenchange",
      this.exitFullScreenMobile,
      true
    );

    window.addEventListener("resize", this.updateVideoSize);
    window.addEventListener("online", this.restartVideo, false);
    if (this.props.videoFullscreenTilt) {
      window.addEventListener("orientationchange", this.orientationChange);
      this.addBackgroundRunDetect();
    }

    let hidden;
    let visibilityChange;
    if (typeof document.hidden !== "undefined") {
      visibilityChange = "visibilitychange";
      // $FlowBug
    } else if (typeof document.msHidden !== "undefined") {
      visibilityChange = "msvisibilitychange";
      // $FlowBug
    } else if (typeof document.webkitHidden !== "undefined") {
      visibilityChange = "webkitvisibilitychange";
    }

    if (
      typeof document.addEventListener !== "undefined" &&
      hidden !== undefined
    ) {
      // $FlowBug
      document.addEventListener(
        visibilityChange,
        this.handleVisibilityChange,
        false
      );
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      !isEqual(nextProps.src, this.props.src) ||
      !isEqual(nextState, this.state) ||
      !isEqual(nextProps, this.props)
    );
  }

  getSnapshotBeforeUpdate(prevProps: Props, prevState: State) {
    if (prevState.videoSRC !== this.state.videoSRC) {
      attempt(() => {
        const { id } = this.player;
        this.player.cleanupConvivaSession();
        this.streamPlayer.destroy();
        setTimeout(() => {
          delete window.RCN.players[id];
        }, 1000);
      });
    }
    return null;
  }

  componentDidUpdate(nextProps: Props) {
    if (!isEqual(this.props.raceId, nextProps.raceId)) {
      this.closeControlsDebounce.cancel();
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    // When an error occurs
    if (props.error) {
      props.onVideoError("The streaming is not available");
      return {
        ...state,
        playing: false,
        controlsVisible: true,
        error: true,
        loading: false,
        feedSwitchLoading: false,
        skipping: false
      };
    }

    const srcAndRaceHasChanged = !isEqual(state.src, props.src);

    if (srcAndRaceHasChanged) {
      if (state.feedSwitchLoading && state.loadingTimeout) {
        clearTimeout(state.loadingTimeout);
      }
      if (state.raceId !== props.raceId) {
        const shouldGoToLive =
          (!state.live && state.src.replay && !props.src.replay) ||
          (state.live && !props.src.replay);
        const hasReplay = !!props.src.replay;
        const live = state.playing ? shouldGoToLive : !hasReplay;
        const newSrc = live ? props.src[state.quality] : props.src.replay;

        return {
          ...state,
          raceId: props.raceId,
          controlsVisible: true,
          live,
          ended: false,
          feedSwitchLoading:
            newSrc !== state.videoSRC ? true : state.feedSwitchLoading,
          src: props.src,
          retry: 0,
          error: false,
          videoSRC: newSrc
        };
      }

      const newSrc = state.live ? props.src[state.quality] : props.src.replay;
      return {
        ...state,
        src: props.src,
        ended: false,
        feedSwitchLoading:
          newSrc !== state.videoSRC ? true : state.feedSwitchLoading,
        videoSRC: newSrc,
        retry: 0,
        error: false
      };
    }

    return null;
  }

  componentDidCatch() {
    this.setState(
      {
        controlsVisible: true,
        playing: false,
        fullscreen: false,
        skipping: false
      },
      () => {
        this.fullscreenOff();
      }
    );
  }

  componentWillUnmount() {
    // This is an hack. browser continue to download data if we don't change the src and load.
    if (this.video || (this.streamPlayer && this.props.hasNewStreamVideo)) {
      if (this.streamPlayer && this.props.hasNewStreamVideo) {
        attempt(() => {
          this.player.destroy();
          this.player = null;
          this.streamPlayer = null;
          this.closeControlsDebounce.cancel();
        });
      } else {
        this.video.pause();
        this.video.src = "";
        this.video.load();
        this.closeControlsDebounce.cancel();
      }
      this.clearLoadingTimeout();
      // $FlowBug
      document.removeEventListener(
        "webkitfullscreenchange",
        this.exitFullScreenMobile,
        true
      );
      // $FlowBug
      document.removeEventListener(
        "mozfullscreenchange",
        this.exitFullScreenMobile,
        true
      );
      window.removeEventListener("resize", this.updateVideoSize);
      window.removeEventListener("online", this.restartVideo, false);
      if (this.props.videoFullscreenTilt) {
        window.removeEventListener("orientationchange", this.orientationChange);
        this.props.deviceLockRotationToggle(true);
      }

      let hidden;
      let visibilityChange;
      if (typeof document.hidden !== "undefined") {
        visibilityChange = "visibilitychange";
        // $FlowBug
      } else if (typeof document.msHidden !== "undefined") {
        visibilityChange = "msvisibilitychange";
        // $FlowBug
      } else if (typeof document.webkitHidden !== "undefined") {
        visibilityChange = "webkitvisibilitychange";
      }

      if (
        typeof document.addEventListener !== "undefined" &&
        hidden !== undefined
      ) {
        // $FlowBug
        document.removeEventListener(
          visibilityChange,
          this.handleVisibilityChange,
          false
        );
      }
    }

    attempt(() => {
      // $FlowBug
      const element = document.getElementById("videoHack");
      // $FlowBug
      element.classList.remove("videoHack");
    });
  }

  onPlay = () => {
    const muted: boolean = get(this.video, "muted");
    this.setState(
      {
        playing: true,
        autoPlay: true,
        controlsVisible: true,
        ended: false,
        skipping: false,
        muted
      },
      this.restartControlsDebounce
    );
  };

  onBufferOn = () => {
    this.clearLoadingTimeout();
    this.setState({
      controlsVisible: true,
      buffering: true,
      loading: false
    });
  };

  onBufferStop = () => {
    this.clearLoadingTimeout();
    if (this.state.skipping || this.state.playing) {
      this.setState(
        {
          controlsVisible: true,
          buffering: false,
          loading: false,
          autoPlay: true,
          feedSwitchLoading: false
        },
        this.restartControlsDebounce
      );
    } else {
      this.setState({
        controlsVisible: get(this, "video.paused", true),
        buffering: false,
        loading: false,
        playing: !get(this, "video.paused", true),
        feedSwitchLoading: false
      });
    }

    this.props.startCallback();
  };

  onVideoEnded = () => {
    this.setState({
      playing: false,
      controlsVisible: this.props.isReplay,
      ended: true,
      loading: false,
      skipping: false
    });
  };

  onError = () => {
    this.props.onVideoError("The streaming is not available");
    this.setState(
      {
        playing: false,
        feedSwitchLoading: false,
        controlsVisible: true,
        error: !(this.state.retry < 2),
        loading: false,
        skipping: false
      },
      () => {
        if (this.state.retry < 2) {
          this.clearLoadingTimeout();
          this.props.reloadStreams();
          this.setState({
            retry: this.state.retry + 1,
            feedSwitchLoading: true,
            loadingTimeout: setTimeout(() => {
              this.setState({ feedSwitchLoading: false, error: true });
            }, 10000)
          });
        }
      }
    );

    const error = {
      message: get(this.video, "error.message", ""),
      code: get(this.video, "error.code", -1)
    };
    this.props.errorCallback(error);
  };

  onLoadedMeta = () => {
    if (!this.state.autoPlay) {
      this.clearLoadingTimeout();
    }
    this.setState({
      controlsVisible: true,
      loading: this.state.autoPlay ? this.state.loading : false,
      feedSwitchLoading: this.state.autoPlay
        ? this.state.feedSwitchLoading
        : false
    });
  };

  onPause = () => {
    // setTimeout need for exiting fullScreen playing work
    setTimeout(() => {
      this.setState({
        playing: false,
        autoPlay: false
      });
    }, 0);
  };

  onMute = () => {
    const muted = !!(this.video && this.video.muted);
    if (this.state.muted !== muted)
      this.setState({
        muted
      });
  };

  setVideoElement: UnaryFn<ElementRef<"video">, void> = (
    video: ElementRef<"video">
  ) => {
    this.video = video;
  };

  setupPlayer: UnaryFn<any, void> = (player: any) => {
    this.player = player;
    this.streamPlayer = player.playerObj;
  };

  playToggle = (e?: event) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    // big hack for rcn v2, only allow start video if its ready
    const bigButton = document.getElementsByClassName("vjs-big-play-button");
    if (
      this.props.hasNewStreamVideo &&
      !this.props.isReplay &&
      !this.streamPlayer &&
      !bigButton.length
    ) {
      return;
    }

    this.setState(
      (prevState: State) => ({
        playing: !prevState.playing,
        autoPlay: !prevState.playing,
        controlsVisible: true,
        ended: false,
        skipping: false
      }),
      () => {
        this.props.deviceLockRotationToggle(!this.state.playing);
        if (this.state.playing) {
          this.closeControlsDebounce.flush();
          if (e) {
            this.props.onVideoPlay();
          }
          return this.playVideo();
        }
        this.closeControlsDebounce.cancel();
        if (e) {
          this.props.onVideoPause();
        }
        return this.pauseVideo();
      }
    );
  };

  playVideo = () => {
    if (
      !this.props.isReplay &&
      this.streamPlayer &&
      this.props.hasNewStreamVideo
    ) {
      this.streamPlayer.play();
    } else if (this.video) {
      this.video.play().catch(() => {
        this.setState({
          playing: false,
          controlsVisible: true,
          buffering: false,
          skipping: false
        });
      });
    }
  };

  pauseVideo = () => {
    if (
      !this.props.isReplay &&
      this.streamPlayer &&
      this.props.hasNewStreamVideo
    ) {
      this.streamPlayer.pause();
    } else if (this.video) {
      this.video.pause();
    }
  };

  addBackgroundRunDetect = () => {
    let hidden;
    let visibilityChange;
    if (!isUndefined(document.hidden)) {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
      // $FlowBug
    } else if (!isUndefined(document.msHidden)) {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
      // $FlowBug
    } else if (!isUndefined(document.webkitHidden)) {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    if (!isUndefined(document.addEventListener) && !isUndefined(hidden)) {
      // $FlowBug
      document.addEventListener(
        visibilityChange,
        this.handleVisibilityChange,
        false
      );
    }
  };

  handleVisibilityChange = (hiddenKey: string) => {
    // $FlowBug
    if (document[hiddenKey]) {
      if (this.video && !this.state.fullscreen) {
        this.setState({
          playing: false,
          fullscreen: false
        });
        this.video.pause();
        this.exitFullScreenCrossDevice();
      }
    }

    if (this.streamPlayer && this.props.hasNewStreamVideo) {
      if (document.visibilityState === "visible") {
        window.hl2functions.reloadPlayer(this.player.id);
      } else {
        this.streamPlayer.pause();
      }
    }
  };

  orientationChange = () => {
    if (
      (window.orientation === 90 || window.orientation === -90) &&
      this.state.playing
    ) {
      this.fullscreenOn(true);
    } else if (this.state.playing) {
      this.exitFullScreenCrossDevice(true);
    }
  };

  exitFullScreenCrossDevice = (tilt: boolean = false) => {
    const webkitExitFullscreen = get(this.video, "webkitExitFullscreen", null);
    if (isFunction(webkitExitFullscreen)) {
      // $FlowFixMe
      this.video.webkitExitFullscreen();
      this.exitFullScreenSafariMobile(tilt);

      // hack to prevent video resizing in safari when coming out of full screen
      this.hackToTriggerCssRepaint(
        this.video,
        "vjs-tech",
        "object-fit: cover",
        100
      );
    } else {
      this.fullscreenOff();
      this.exitFullScreenMobile(tilt);
    }
  };

  updateVideoSize = () => {
    setTimeout(() => {
      window.scroll();
      this.setState(
        {
          updateIndex: this.state.updateIndex + 1
        },
        () => {
          window.scroll();
        }
      );
    }, 300);
  };

  hackToTriggerCssRepaint(
    element: ElementRef<any>,
    className: string,
    style: string,
    timeoutDelay: number
  ) {
    const { classList } = element;
    if (classList.contains(className)) {
      // $FlowBug
      this.video.style = style;
      setTimeout(() => {
        // $FlowBug
        this.video.style = "";
      }, timeoutDelay);
    }
  }

  exitFullScreenSafariMobile = (tilt: boolean = false) => {
    // hack to prevent video resizing in safari when coming out of full screen
    this.hackToTriggerCssRepaint(
      this.video,
      "vjs-tech",
      "object-fit: cover",
      500
    );

    this.setState(
      {
        fullscreen: false,
        buffering: false
      },
      () => {
        if (this.state.playing && get(this, "video.play")) {
          setTimeout(() => {
            if (this.streamPlayer) {
              this.streamPlayer.play();
            } else {
              this.video.play().catch(console.log);
            }
            this.setState({
              playing: true,
              autoPlay: true
            });
          }, 1000);
        }
      }
    );
    // $FlowBug
    this.video.removeEventListener(
      "webkitendfullscreen",
      this.exitFullScreenSafariMobile,
      true
    );
    this.props.onVideoFullScreen(false, tilt);
  };

  muteVideo = () => {
    this.props.onVideoMute(true);
    this.video.muted = true;
  };

  unmuteVideo = () => {
    this.props.onVideoMute(false);
    this.video.muted = false;
  };

  skipToggleHandler = (skipType: VideoSkippingActionType) => (e: event) => {
    this.props.onNavigateVideo(skipType === "fwd");
    const videoDuration = this.video.duration;
    const bufferedVideo = this.video.buffered.length;
    const incrementValue =
      this.state.buffering && bufferedVideo < videoDuration
        ? bufferedVideo
        : 15;

    const increment = skipType === "fwd" ? incrementValue : -15;

    const timeIsOverDuration =
      skipType === "fwd" && this.video.currentTime + increment >= videoDuration;
    const timeIsBellowZero =
      skipType === "rew" && this.video.currentTime + increment <= 0;

    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    this.setState({ controlsVisible: true, skipping: true }, () => {
      if (isFunction(this.closeControlsDebounce.cancel)) {
        this.closeControlsDebounce.cancel();
      }
      this.closeControlsDebounce = debounce(() => this.closeControls(), 3000);
    });

    if (timeIsOverDuration) {
      this.video.currentTime = videoDuration - 0.01;
    } else if (timeIsBellowZero) {
      this.video.currentTime = 0;
    } else {
      this.video.currentTime += increment;
    }
  };

  exitFullScreenMobile = (tilt: boolean = false) => {
    const isInFullScreen =
      // $FlowBug
      document.fullscreenElement ||
      // $FlowBug
      document.webkitFullscreenElement ||
      // $FlowBug
      document.mozFullScreenElement ||
      // $FlowBug
      document.msFullscreenElement;
    if (!isInFullScreen) {
      this.setState(
        {
          fullscreen: false
        },
        () => {
          if (this.state.playing && get(this, "video.play")) {
            setTimeout(() => {
              if (this.streamPlayer) {
                this.streamPlayer.play();
              } else {
                this.video.play().catch(console.log);
              }
              this.setState({
                playing: true,
                autoPlay: true
              });
            }, 1000);
          }
        }
      );
      this.props.onVideoFullScreen(false, tilt);
    }
  };

  fullscreenOn = (tilt: boolean = false) => {
    let fullScreenState = this.state.fullscreen;
    if (get(this.video, "webkitSupportsFullscreen")) {
      // $FlowBug
      this.video.webkitEnterFullscreen();
      // $FlowBug
      this.video.addEventListener(
        "webkitendfullscreen",
        this.exitFullScreenSafariMobile,
        true
      );
      fullScreenState = true;
      // $FlowBug
    } else if (this.container.requestFullscreen) {
      // $FlowBug
      this.container.requestFullscreen();
      fullScreenState = true;
      // $FlowBug
    } else if (this.container.webkitRequestFullscreen) {
      // $FlowBug
      this.container.webkitRequestFullscreen();
      fullScreenState = true;
      // $FlowBug
    } else if (this.container.mozRequestFullScreen) {
      // $FlowBug
      this.container.mozRequestFullScreen();
      fullScreenState = true;
      // $FlowBug
    } else if (this.container.msRequestFullscreen) {
      // $FlowBug
      this.container.msRequestFullscreen();
      fullScreenState = true;
    }

    if (get(window, "screen.orientation.lock")) {
      setTimeout(() => {
        window.screen.orientation.lock("landscape-primary");
      }, 100);
    }

    this.setState({
      fullscreen: fullScreenState
    });

    this.props.onVideoFullScreen(true, tilt);
    // This can be added on future to lock landscape on fullscreenMode. Remember safari do not have this
    // screen.orientation.lock('landscape');
  };

  fullscreenOff = () => {
    let fullScreenState = this.state.fullscreen;

    attempt(() => {
      // $FlowBug
      if (document.exitFullscreen) {
        // $FlowBug
        document.exitFullscreen();
        fullScreenState = false;
        // $FlowBug
      } else if (document.webkitExitFullscreen) {
        // $FlowBug
        document.webkitExitFullscreen();
        fullScreenState = false;
        // $FlowBug
      } else if (document.mozCancelFullScreen) {
        // $FlowBug
        document.mozCancelFullScreen();
        fullScreenState = false;
        // $FlowBug
      } else if (document.msExitFullscreen) {
        // $FlowBug
        document.msExitFullscreen();
        fullScreenState = false;
      }
      this.setState({
        fullscreen: fullScreenState
      });
    });
  };

  muteToggle = (e?: event) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    if (this.video) {
      this.setState(
        (prevState: State) => ({
          muted: !prevState.muted,
          isFullUnmute: false
        }),
        () => {
          if (this.state.muted) {
            return this.muteVideo();
          }

          return this.unmuteVideo();
        }
      );
    }
  };

  fullscreenToggle = (e?: event) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    return this.fullscreenOn();
  };

  closeControls = () => {
    this.setState({
      controlsVisible: false,
      skipping: false,
      isFullUnmute: false
    });
  };

  closeControlsDebounce = debounce(() => this.closeControls(), 3000);

  restartControlsDebounce = () => {
    if (isFunction(this.closeControlsDebounce.cancel)) {
      this.closeControlsDebounce.cancel();
    }
    this.closeControlsDebounce();
  };

  showControls = (overrideSkipping: boolean = false) => {
    // Controls are visible and skipping is not active, get controls to hide
    if (
      this.state.controlsVisible &&
      (!this.state.skipping || overrideSkipping)
    ) {
      this.setState(
        {
          controlsVisible: false
        },
        () => {
          this.closeControlsDebounce.flush();
        }
      );
      return;
    }
    // Otherwise, controls are not visible, get them to show
    this.setState(
      {
        controlsVisible: true
      },
      () => {
        if (!this.state.playing) {
          this.closeControlsDebounce.cancel();
          return;
        }
        this.closeControlsDebounce();
      }
    );
  };

  liveButtonCallback = (e: event): void => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    const live = !this.state.live;
    this.props.onSwitchVideoType(live);
    this.props.reloadStreams().then(() => {
      this.setState({
        playing: false,
        autoPlay: this.state.playing,
        retry: 0,
        controlsVisible: true,
        ended: false,
        error: false,
        feedSwitchLoading: true,
        loadingTimeout: setTimeout(() => {
          this.setState({ feedSwitchLoading: false, error: true });
        }, 10000),
        live,
        videoSRC: live
          ? this.props.src[this.state.quality]
          : this.props.src.replay
      });
    });
    this.clearLoadingTimeout();
  };

  rewButtonIsVisible = (): boolean =>
    this.video && this.state.playing ? this.video.currentTime >= 0 : false;

  fwdButtonIsVisible = (): boolean =>
    this.video && this.state.playing
      ? this.video.currentTime <= this.video.duration
      : false;

  restartVideo = () => {
    if (
      (this.state.playing && this.state.buffering) ||
      this.state.error ||
      this.state.feedSwitchLoading
    ) {
      this.props.reloadStreams();
      this.clearLoadingTimeout();
      this.setState({
        loading: false,
        error: false,
        buffering: false,
        feedSwitchLoading: true,
        loadingTimeout: setTimeout(() => {
          this.setState({ feedSwitchLoading: false });
        }, 10000),
        controlsVisible: true,
        playing: false,
        autoPlay: false
      });
    }
  };

  clearLoadingTimeout = () => {
    if (this.state.loadingTimeout) {
      clearTimeout(this.state.loadingTimeout);
    }
  };

  player: any;

  streamPlayer: any;

  video: ElementRef<"video">;

  container: ElementRef<"div">;

  isVideoReplay = (hasReplaySrc: boolean) => !this.state.live && hasReplaySrc;

  hasVideoReplaySrc = () => !!this.props.src.replay;

  renderLiveVideo = () => {
    let streamHash = "";
    let streamTimestamp = "";
    let dataStream = "";
    let hd = "";
    const url: string = get(
      this,
      `props.src[${get(this, "state.quality", "")}]`,
      ""
    );

    if (url) {
      const streamUrl = attempt((urlArg) => new URL(urlArg), url);
      if (negate(isError)(streamUrl)) {
        streamHash = streamUrl.searchParams.get("h");
        streamTimestamp = streamUrl.searchParams.get("t");
        dataStream = streamUrl.searchParams.get("stream");
        hd = streamUrl.searchParams.get("hd");
      } else {
        console.log("ERROR IN URL:", streamUrl);
      }
    }

    return (
      <StreamVideo
        dataHash={streamHash}
        dataTimestamp={streamTimestamp}
        dataStream={dataStream}
        dataQuality={hd}
        onPlay={this.onPlay}
        setVideoElement={this.setVideoElement}
        setupPlayer={this.setupPlayer}
      />
    );
  };

  render() {
    const hasLiveSrc = !!this.props.src[this.state.quality];
    const hasReplaySrc = this.hasVideoReplaySrc();
    const isReplay = this.isVideoReplay(hasReplaySrc);

    return (
      <Container
        background={this.state.error && this.props.backGroundImage}
        fullscreen={this.state.fullscreen}
        hideCanvas={this.state.controlsVisible && !this.state.playing}
        containerHeight={!this.props.inlineVideo && calcVideoContainerHeight()}
        ref={(c) => {
          if (c) {
            this.container = c;
          }
        }}
      >
        {!!this.state.videoSRC &&
          (!this.props.hasNewStreamVideo || isReplay ? (
            <StyledVideo
              fullscreen={this.state.fullscreen}
              ref={(c) => {
                if (c) {
                  this.video = c;
                }
              }}
              src={this.state.videoSRC}
              onEnded={this.onVideoEnded}
              onWaiting={this.onBufferOn}
              onError={this.onError}
              onPause={this.onPause}
              onLoadedData={this.props.onVideoSuccess}
              onLoadedMetadata={this.onLoadedMeta}
              onVolumeChange={this.onMute}
              onPlaying={this.onBufferStop}
              onCanPlay={this.onBufferStop}
              playsInline
              autoPlay={this.state.autoPlay}
              muted={this.state.muted}
              preload="metadata"
            />
          ) : (
            this.renderLiveVideo()
          ))}
        <OverlayControls
          showControls={this.state.controlsVisible}
          loading={this.state.feedSwitchLoading || this.state.loading}
          clickHandler={this.showControls}
          playing={this.state.playing}
          playHandler={this.playToggle}
          muted={this.state.muted}
          isFullUnmute={this.props.isAutoPlay && this.state.isFullUnmute}
          muteHandler={this.muteToggle}
          fullscreen={this.state.fullscreen}
          fullscreenHandler={this.fullscreenToggle}
          skipToggleHandler={this.skipToggleHandler}
          ended={this.state.ended}
          buffering={this.state.buffering}
          renderOnOverlay={this.props.renderOnOverlay}
          error={
            this.state.error || (!this.state.videoSRC && !this.state.loading)
          }
          isReplay={isReplay}
          hasReplayStream={hasReplaySrc}
          hasLiveStream={hasLiveSrc}
          liveButtonCallback={this.liveButtonCallback}
          rewButtonIsVisible={this.rewButtonIsVisible()}
          fwdButtonIsVisible={this.fwdButtonIsVisible()}
          hasNewStreamVideo={this.props.hasNewStreamVideo}
          disableFullscreen={this.props.disableFullscreen}
        />
      </Container>
    );
  }
}

export default Video;
