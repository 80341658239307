import tvgConf from "@tvg/conf";
import type { TutorialListFromService } from "@tvg/ts-types/OnBoarding";
import requester from "../requester";
import getProtocol from "../protocolSetter";

const capiService: string = "service.capi";

export const tvgHeaders = () => ({
  "content-type": "application/json",
  "x-clientapp": tvgConf().brand,
  "x-tvg-context": tvgConf().context(tvgConf().product, true),
  "x-requested-with": "XMLHttpRequest"
});

const fetchMessagesByNamespaces = (namespaces: string) => {
  const tvgConfObject = tvgConf();
  return requester()({
    method: "GET",
    url: `${getProtocol()}${tvgConfObject.config(
      capiService
    )}/messages/namespace?product=${tvgConfObject.product}&device=${
      tvgConfObject.device
    }&brand=${tvgConfObject.brand}&namespace=${namespaces}`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: false
  });
};

const fetchFeatureToggles = () => {
  const tvgConfObject = tvgConf();
  return requester()({
    method: "GET",
    url: `${getProtocol()}${tvgConfObject.config(capiService)}/featureToggles/${
      tvgConfObject.product
    }/${tvgConfObject.device}/${tvgConfObject.brand}`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true
  });
};

const fetchBalancePollerTime = () =>
  requester()({
    method: "get",
    url: `${getProtocol()}${
      tvgConf().config().service.capi
    }/configs/balance-polling-time`,
    headers: tvgHeaders(),
    withCredentials: true
  });

const fetchBrazePollerTime = () =>
  requester()({
    method: "get",
    url: `${getProtocol()}${
      tvgConf().config().service.capi
    }/configs/inbox-polling-time`,
    headers: tvgHeaders(),
    withCredentials: true
  });

const fetchTvProvidersByState = (residentialStateAbbr: string) =>
  requester()({
    method: "get",
    url: `${getProtocol()}${
      tvgConf().config().service.capi
    }/signalProviders/search/findByState?state=${residentialStateAbbr}`,
    headers: tvgHeaders(),
    withCredentials: false
  });

const fetchPromoOnboardingPollerTime = () =>
  requester()({
    method: "get",
    url: `${getProtocol()}${
      tvgConf().config().service.capi
    }/configs/promo-steps-polling-time`,
    headers: tvgHeaders(),
    withCredentials: true
  });

const fetchFreePastPerfomances = () => {
  const tvgConfObject = tvgConf();
  return requester()({
    method: "get",
    url: `${getProtocol()}${
      tvgConf().config().service.capi
    }/files/handicapping/available?brand=${tvgConfObject.brand}`,
    headers: tvgHeaders(),
    withCredentials: true
  });
};

/**
 * Gets the list of routes with available tutorials
 * @returns {Promise<TutorialListFromService>}
 */
const fetchTutorialList = (): Promise<TutorialListFromService[]> => {
  const conf = tvgConf();
  const { product } = conf;
  const url = `${conf.config().service.capi}/tutorial`;

  const requestOptions = {
    method: "GET",
    url,
    headers: {
      "content-type": "application/json",
      "x-tvg-context": conf.context(product, true),
      "x-requested-with": "XMLHttpRequest"
    },
    params: {
      isMinimized: true,
      isActive: true
    },
    withCredentials: true
  };

  return requester()(requestOptions)
    .then(({ data }) => data)
    .catch((err: Error): Error => err);
};

const CAPI_SERVICES = {
  fetchMessagesByNamespaces,
  fetchFeatureToggles,
  fetchBalancePollerTime,
  fetchBrazePollerTime,
  fetchTvProvidersByState,
  fetchPromoOnboardingPollerTime,
  fetchTutorialList,
  fetchFreePastPerfomances
};

export default CAPI_SERVICES;
