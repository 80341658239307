import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Promo = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M19.8728 2.62793H12.6518C12.2343 2.62793 11.8358 2.80192 11.5519
    3.10805L3.02409 12.3051C2.4758 12.8965 2.49314 13.8155 3.06336
    14.3857L9.61501 20.9374C10.1852 21.5076 11.1042 21.5249 11.6956
    20.9766L20.8927 12.4488C21.1988 12.165 21.3728 11.7664 21.3728
    11.3489V4.12793C21.3728 3.2995 20.7012 2.62793 19.8728 2.62793ZM15.749
    9.37308C16.3698 9.37308 16.8731 8.86983 16.8731 8.24904C16.8731 7.62825
    16.3698 7.125 15.749 7.125C15.1282 7.125 14.625 7.62825 14.625
    8.24904C14.625 8.86983 15.1282 9.37308 15.749 9.37308Z"
    fill={backgroundColor}
    fillOpacity="1"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
