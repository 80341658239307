import styled from "styled-components";

export const WarningPortalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  pointer-events: none;
`;

export const WarningPortalContent = styled.div<{ top: number; left: number }>`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  pointer-events: auto;
`;
