import { Dispatch, RefObject, SetStateAction } from "react";
import { isDesktop } from "../../../../utils";

export const handleChange =
  (
    onChangeValue: Function,
    setUnformatedValue: Dispatch<SetStateAction<string>>,
    inputRef: RefObject<HTMLInputElement>,
    maxValidLimit: number | undefined,
    setFixedSelectionRange: Dispatch<SetStateAction<boolean>>,
    setIsDeleting: Dispatch<SetStateAction<boolean>>,
    setStart: Dispatch<SetStateAction<number>>,
    setEnd: Dispatch<SetStateAction<number>>,
    value: string
  ) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value: newValue } = event.target;
    onChangeValue(newValue, "desktop");
    setUnformatedValue(newValue);

    if (isDesktop && inputRef?.current && maxValidLimit) {
      const { length } = newValue;
      const { selectionStart = length, selectionEnd = length } =
        Object(inputRef).current;
      const hasLimitOverflow = newValue.length >= maxValidLimit;
      setFixedSelectionRange(true);
      setIsDeleting(length < value.length);
      setStart(hasLimitOverflow ? maxValidLimit : selectionStart);
      setEnd(hasLimitOverflow ? maxValidLimit : selectionEnd);
    }
  };

export const pasteHandler =
  (onPasteHandler: Function | undefined) =>
  (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (typeof onPasteHandler === "function") {
      onPasteHandler(event.clipboardData.getData("text"));
      event.preventDefault();
    }
  };
