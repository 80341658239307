import { toggleQuickDepositModal } from "@tvg/sh-lib-paws/redux/slices/quickDepositModalSlice";
import { setSelectedPaymentMethod } from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { Location } from "react-router-dom";
import { History } from "@tvg/ts-types/History";

export const closeQuickDepositModal =
  (dispatch: Dispatch, location: Location, history?: History) => () => {
    if (history) {
      const { search = "", pathname = "" } = location;
      history.push(pathname + search);
    }
    dispatch(toggleQuickDepositModal(false));
    dispatch(
      setSelectedPaymentMethod({
        id: "",
        paymentType: "",
        title: "",
        subTitle: "",
        description: "",
        depositsAvailable: false,
        withdrawalsAvailable: false,
        minLimit: 0,
        maxLimit: 99999
      })
    );
  };
