// @flow

import React, { PureComponent, type Node } from "react";
import { noop, isFunction, get } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import type { ScrollableRef } from "@tvg/types/Modal";
import type { Device } from "@tvg/types/Device";
import {
  BackButton,
  CloseButton,
  Subtitle,
  Title,
  TitleContainer,
  TitleHolder
} from "./styled-components";
import { arrowBack, close } from "../../_static/Icons/icons";
import { fontNormal } from "../../_static/Typography";

import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";

type Props = {
  /**
   * Title for the modal
   */
  title: string | Node | null,
  /**
   * Title for the modal
   */
  subtitle: string | Node | null,
  /**
   * CallBack for close the modal
   */
  onClose: NullaryFn<mixed>,
  /**
   * CallBack for close the modal
   */
  onBack: ?NullaryFn<mixed>,
  /**
   * should have round corners
   */
  hasRoundedCorners: boolean,
  /**
   * Show close button
   */
  hasCloseButton: boolean,
  /**
   * Scrollable parent ref
   */
  scrollableRef: ScrollableRef,
  /**
   * Identifies the device in use
   */
  device: Device,
  className: string,
  isTitleCenter?: boolean,
  subtitleColor?: string,
  subtitleFontFamily?: string,
  isTitleCapitalized: boolean,
  showBottomShadow: boolean
};

type State = {
  isAtTop: boolean
};

export default class ModalHeader extends PureComponent<Props, State> {
  static defaultProps = {
    title: null,
    subtitle: null,
    onClose: noop,
    onBack: null,
    hasRoundedCorners: true,
    hasCloseButton: true,
    scrollableRef: {
      current: null
    },
    className: "",
    device: "mobile",
    isTitleCenter: false,
    subtitleColor: buildColor("grey", "800"),
    subtitleFontFamily: fontNormal,
    isTitleCapitalized: true,
    showBottomShadow: true
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isAtTop: true
    };
  }

  componentDidMount() {
    this.addEventListener(this.props.scrollableRef.current);
  }

  componentDidUpdate(prevProps: Props) {
    this.removeEventListener(prevProps.scrollableRef.current);
    this.addEventListener(this.props.scrollableRef.current);
  }

  componentWillUnmount() {
    this.removeEventListener(this.props.scrollableRef.current);
  }

  setIsAtTop = () => {
    if (get(this.props, "scrollableRef.current.scrollTop") === 0) {
      this.setState({
        isAtTop: true
      });
    } else {
      this.setState({
        isAtTop: false
      });
    }
  };

  addEventListener = (element: null | HTMLDivElement) => {
    if (element) {
      element.addEventListener("scroll", this.setIsAtTop, {
        capture: true
      });
    }
  };

  removeEventListener = (element: null | HTMLDivElement) => {
    if (element) {
      element.removeEventListener("scroll", this.setIsAtTop);
    }
  };

  render() {
    const hasBack = this.props.onBack && isFunction(this.props.onBack);
    const hasClose = this.props.hasCloseButton;

    return (
      <TitleContainer
        className={this.props.className}
        data-qa-label="modal-header"
        roundedCorners={this.props.hasRoundedCorners}
        showShadow={!this.state.isAtTop && this.props.showBottomShadow}
        hasBack={hasBack}
        device={this.props.device}
        showBottomShadow={this.props.showBottomShadow}
      >
        {hasBack && (
          <BackButton
            qaLabel="modal-backBtn"
            onClick={this.props.onBack}
            isAbsolute={this.props.isTitleCenter}
          >
            <Icon
              icon={arrowBack}
              color={buildColor("grey", "900")}
              size={16}
            />
          </BackButton>
        )}

        {this.props.subtitle ? (
          <TitleHolder isTitleCenter={this.props.isTitleCenter}>
            <Title isTitleCapitalized={this.props.isTitleCapitalized}>
              {this.props.title}
            </Title>
            <Subtitle
              isUsingMargin={!this.props.isTitleCenter}
              subtitleColor={this.props.subtitleColor}
              subtitleFontFamily={this.props.subtitleFontFamily}
              data-qa-label="modal-subtitle"
            >
              {this.props.subtitle}
            </Subtitle>
          </TitleHolder>
        ) : (
          <TitleHolder isTitleCenter={this.props.isTitleCenter}>
            <Title isTitleCapitalized={this.props.isTitleCapitalized}>
              {this.props.title}
            </Title>
          </TitleHolder>
        )}

        {hasClose && (
          <CloseButton qaLabel="modal-closeBtn" onClick={this.props.onClose}>
            <Icon icon={close} color={buildColor("grey", "900")} size={16} />
          </CloseButton>
        )}
      </TitleContainer>
    );
  }
}
