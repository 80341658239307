import constructRequest from "../codeBase";
import type { PaymentTypesAvailable } from "../types";
import { serviceResponse } from "../../types";

const getWithdrawLimits = (
  paymentType: PaymentTypesAvailable,
  depositData: Record<string, unknown>
): Promise<serviceResponse> =>
  constructRequest(paymentType, "withdrawLimits", depositData);

const postWithdraw = (
  paymentType: PaymentTypesAvailable,
  depositData: Record<string, unknown>
): Promise<serviceResponse> =>
  constructRequest(paymentType, "withdraw", depositData);

const PAWS_WITHDRAW_SERVICE = {
  getWithdrawLimits,
  postWithdraw
};

export default PAWS_WITHDRAW_SERVICE;
