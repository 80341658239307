import styled from "styled-components/native";
import { View } from "react-native";
import { compose, space, border } from "../../../theming/styledSystem";

export const borderStyles = {
  borderWidth: "1px",
  borderColor: "blue.100",
  borderRadius: "s"
};

export const getListElemDividerStyles = (index: number, listLength: number) => {
  let styles = {};

  const isFirstElement = index === 0;
  const isLastElement = index === listLength - 1;

  if (isFirstElement) {
    styles = {
      borderTopLeftRadius: borderStyles.borderRadius,
      borderTopRightRadius: borderStyles.borderRadius
    };
  } else {
    styles = {
      borderTopWidth: borderStyles.borderWidth,
      borderTopColor: borderStyles.borderColor
    };
  }

  if (isLastElement) {
    styles = {
      ...styles,
      borderBottomLeftRadius: borderStyles.borderRadius,
      borderBottomRightRadius: borderStyles.borderRadius
    };
  }

  return styles;
};

const StyledCardListUtils = compose(space, border);
export const StyledCardList = styled(View)(StyledCardListUtils);
