import { buildColor } from "@tvg/design-system";
import styled, { keyframes } from "styled-components";

export type SvgColor = "green" | "blue";

export const getPulseAnimation = (fillColor: SvgColor = "blue") => keyframes`
  0% {
    fill: ${buildColor(fillColor, "000")};
  }

  50% {
    fill: ${buildColor(fillColor, "100")};
  }

  100% {
    fill: ${buildColor(fillColor, "000")};
  }
`;

export const Svg = styled.svg<{ fillColor: SvgColor }>`
  rect {
    animation: ${({ fillColor }) => getPulseAnimation(fillColor)} 1.25s infinite;
  }
`;

export const Container = styled.div`
  background-color: ${buildColor("white", "900")};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  padding-top: 13px;
  padding-bottom: 17px;
  box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
`;

export const Body = styled.div`
  padding: 0 20px;
  padding-bottom: 16px;
`;

export const AmountDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 52px 0;
  gap: 53px;
`;

const BorderedBox = styled.div`
  display: flex;
  border: 1px solid ${buildColor("blue", "100")};
  border-radius: 4px;
`;

export const PillsBox = styled(BorderedBox)`
  padding: 15px 20px;
  gap: 42px;
`;

export const Total = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 16px;
  border-top: 1px solid ${buildColor("blue", "100")};
  border-bottom: 1px solid ${buildColor("blue", "100")};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SelectorBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 8px;
`;

export const Selector = styled(BorderedBox)`
  padding: 13px 12px;
  display: flex;
  gap: 12px;
`;

export const Button = styled.div`
  margin: 16px;
`;
