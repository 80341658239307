import styled from "styled-components";
import { Theme } from "../../theming/types";

export const SeoContainer = styled.div<{ theme: Theme }>`
  background-color: ${({ theme }) => theme.colors.white[900]};
  padding: 15px 12px 20px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.blue[100]};

  a {
    vertical-align: baseline;
    color: ${({ theme }) => theme.colors.blue_accent[800]};
    cursor: pointer;
  }

  p {
    padding-top: 12px;
  }
`;
