// @flow

import type {
  EventIdentifier,
  EventSubscriberFn,
  EventHandler,
  UnsubscribeHandler,
  MediatorInterface
} from "./Types.js.flow";

class MediatorNoop implements MediatorInterface {
  handlers: { [key: EventIdentifier]: EventHandler };

  constructor(): void {
    this.handlers = {};
  }

  subscribe(
    type: EventIdentifier,
    handler: EventSubscriberFn
  ): UnsubscribeHandler {
    return () => this.unsubscribe(type, handler);
  }

  subscribeTimes(
    times: number,
    type: EventIdentifier,
    handler: EventSubscriberFn
  ): UnsubscribeHandler {
    return () => this.unsubscribe(type, handler);
  }

  subscribeWithPast(
    type: EventIdentifier,
    handler: EventSubscriberFn
  ): UnsubscribeHandler {
    return () => this.unsubscribe(type, handler);
  }

  unsubscribe(
    type: EventIdentifier,
    handler: EventSubscriberFn // eslint-disable-line no-unused-vars
  ): MediatorInterface {
    return this;
  }

  // eslint-disable-next-line no-unused-vars
  dispatch(type: EventIdentifier, value: *): MediatorInterface {
    return this;
  }

  query(type: EventIdentifier): * {
    const handler = this.handlers[type];
    return handler && handler.lastValue ? handler.lastValue : undefined;
  }
}

export default MediatorNoop;
