import React, { FC, memo } from "react";
import type { IconSizeVariants } from "../../theme";
import { useColorTokens, useTheme } from "../../hooks";
import * as Icons from "./components/IconPaths";
import { Svg } from "./styled-components";

import type { IconNames, IconProps } from "./types";

export const Icon: FC<IconProps> = memo(
  ({
    name,
    lineColor = "content.link",
    size,
    backgroundColor = "transparent",
    qaLabel,
    ...rest
  }) => {
    const { iconSizes } = useTheme();
    const { iconSize, strokeWidth } = iconSizes[size as IconSizeVariants];
    const IconContent = Icons[name as IconNames];

    // TODO: delete when color tokens for every use case are implemented
    // Checking if color has #, so we use the color instead of token
    const filterColor = (color: string | undefined) =>
      color && (color.includes("#") || color.includes("rgba"))
        ? color
        : useColorTokens(color);

    const checkIsTransparent = (color: string) =>
      backgroundColor === "transparent" ? "transparent" : filterColor(color);

    const selectedLineColor = lineColor.startsWith("--")
      ? `var(${lineColor})`
      : filterColor(lineColor);

    const selectedBackgroundColor = backgroundColor.startsWith("--")
      ? `var(${backgroundColor})`
      : checkIsTransparent(backgroundColor);

    return (
      <Svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-qa-label={qaLabel || name}
        {...rest}
      >
        <IconContent
          lineColor={selectedLineColor}
          backgroundColor={selectedBackgroundColor}
          strokeWidth={strokeWidth}
        />
      </Svg>
    );
  }
);

export type { IconProps, IconNames };
