import { get, attempt, first, isError, orderBy } from "lodash";
import { addDays, isBefore } from "date-fns";
import pro from "@tvg/api/pro";
import { PromoEligible } from "../types/promos";

const MAX_OPTION_DATE_TO_FINISH = 35;

const filterValidEligiblePromotions = (eligiblePromotion: PromoEligible) =>
  eligiblePromotion.costCategoryGroup === "Acquisition" &&
  eligiblePromotion.eligibilityStatusName === "Elgibile" &&
  !!eligiblePromotion.optInDate &&
  isBefore(
    new Date(),
    addDays(new Date(eligiblePromotion.optInDate), MAX_OPTION_DATE_TO_FINISH)
  ) &&
  eligiblePromotion.promoActiveStatus === "Active" &&
  (!eligiblePromotion.optInEndDate ||
    isBefore(new Date(), addDays(new Date(eligiblePromotion.optInEndDate), 1))); // Shifting one day only to get the same day or before.

export const fetchPromosEligible = async (
  accountId: string
): Promise<PromoEligible | null> => {
  const promosEligible = await attempt(async () => {
    const responseAllEligiblePromos = await pro.fetchUserPromoOptin(accountId);
    const eligiblePromotions = get(responseAllEligiblePromos, "data", []);
    const eligiblePromotionsFiltered = (eligiblePromotions || []).filter(
      filterValidEligiblePromotions
    );

    return orderBy(eligiblePromotionsFiltered, ["optInDate"], ["asc"]);
  });

  if (isError(promosEligible)) {
    return null;
  }

  return first(promosEligible) || null;
};
