// @flow
// $FlowFixMe
import React from "react";
import { createPortal } from "react-dom";
import { useModalV2Core } from "@tvg/custom-hooks";
import { closeModalWrapper } from "@tvg/utils/modalV2";
import Modal from "./Modal";
import type { ModalV2Props } from "./types";
import { ModalV2DefaultProps } from "./defaultProps";

const ModalV2 = (props: ModalV2Props) => {
  const {
    isOpen,
    setIsOpen,
    bodyPositionY,
    setBodyPositionY,
    readyToRender,
    element
  } = useModalV2Core(
    props.isOpen,
    props.useFakeInput,
    props.isLastModal,
    props.device,
    props.onOpen
  );

  if (typeof window === "undefined" || !readyToRender) {
    return null;
  }

  const mixedProps = {
    ...props,
    onClose: () => {
      closeModalWrapper(props.onClose, {
        useFakeInput: props.useFakeInput,
        device: props.device,
        isLastModal: props.isLastModal,
        setIsOpen,
        bodyPositionY,
        setBodyPositionY
      });
    },
    isOpen,
    bodyPositionY
  };

  const component = <Modal {...mixedProps} />;
  return createPortal(component, element);
};

ModalV2.defaultProps = ModalV2DefaultProps;

export default ModalV2;
