module.exports = {
  domain: {
    desktop: "ia.tvg.com",
    mobile: "ia.tvg.com",
    talentPicks: "ia.tvg.com/talent-picks/",
    equibase: "equibase-store.tvg.com"
  },
  recaptchaID: "6LdrNBgqAAAAAKKs_ZP23RQvTYPkSFdZQ9KK_j6K",
  recaptchaIdNative: "6LfSQloqAAAAAFKuurdHLnObQ-r2CGo0TuuYdNq-"
};
