import styled from "styled-components";
import { buildColor } from "@tvg/design-system";

export const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${buildColor("white", "900")};
  padding: ${({ isMobile }) => (isMobile ? "16px" : "20px")};
`;

export const ActionsContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;

  span {
    font-weight: bold;
    vertical-align: baseline;
  }
`;

export const Description = styled.p`
  line-height: 18px;
  margin-bottom: 16px;
  min-height: 55px;
  color: ${buildColor("grey", "800")};

  span {
    font-weight: bold;
    vertical-align: baseline;
  }
`;
