import React from "react";
import { Path } from "react-native-svg";

import { IconPathProps } from "../types";

const SortDescending = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M6 3V19.4297M1.5 15L6 19.5L10.5 15M13.5 5.625H22.5M13.5 12H19.5M13.5
    18.375H16.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default SortDescending;
