import styled from "styled-components";
import { space, width } from "styled-system";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  ${width}
  ${space}
`;
