import React, { forwardRef, useContext } from "react";
import type { StepProps } from "../../types";
import { PBContext } from "../../context";
import {
  StyledStepBarContainer,
  StyledStepBar,
  StepParagraphContainer
} from "./styled-components";
import { Paragraph } from "../../../Typography";

export const Step = forwardRef<HTMLDivElement, StepProps>(
  (
    {
      qaLabel = "progress-bar-step",
      stepNumber = 1,
      stepDescription = null,
      ...rest
    },
    ref
  ) => {
    const { completedSteps } = useContext(PBContext);

    return (
      <StyledStepBarContainer ref={ref} {...rest} data-qa-label={qaLabel}>
        <StyledStepBar isCompleted={stepNumber <= completedSteps} />
        {stepDescription ? (
          <StepParagraphContainer>
            <Paragraph qaLabel="step-description" color="#1C1D1D" mt="space-1">
              {stepDescription}
            </Paragraph>
          </StepParagraphContainer>
        ) : null}
      </StyledStepBarContainer>
    );
  }
);
