import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PaymentTypeItem, PaymentTypeOption } from "@tvg/wallet/src/types";
import { fetchAvailablePaymentMethods } from "@tvg/wallet/src/utils";
import { noop, isEmpty } from "lodash";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import { getAccountNumber } from "@urp/store-selectors";
import { getAccountsOnFileMethods } from "../redux/selectors";
import { getMazoomaToggle } from "../redux/toggles";
import { setSelectedPaymentMethod } from "../redux/slices/paymentMethodSelectorSlice";
import {
  filterAndSortAccountsOnFile,
  getChkPmOption
} from "../utils/functions";

export const getPaymentMethod = (
  isOpen: boolean,
  accountsOnFile: PaymentTypeOption[],
  otherMethods: PaymentTypeItem[],
  isMZMToggleOn: boolean = false
) => {
  const filtered = filterAndSortAccountsOnFile(accountsOnFile, isMZMToggleOn);

  if (isOpen && filtered && filtered.length > 0) {
    if (filtered[0]) {
      return {
        ...filtered[0],
        description: "",
        subTitle: filtered[0].description
      };
    }
  }

  if (isOpen && filtered.length === 0) {
    const option = getChkPmOption(otherMethods);

    if (option) {
      return {
        ...option,
        description: "",
        title: "Check",
        paymentType: "CHK",
        subTitle: ""
      };
    }
  }
  return null;
};

const useQuickWithdrawSetPM = (isOpen: boolean) => {
  const dispatch = useDispatch();
  const isMZMToggleOn = useSelector(getMazoomaToggle);

  const availablePaymentMethodsInfos = useSelector((store) =>
    parseCAPIMessage(store, "capi.messages.availablePaymentMethodsInfos", {})
  );
  const accountsOnFile: PaymentTypeOption[] = useSelector(
    getAccountsOnFileMethods
  );
  const accountNumber: string = useSelector(getAccountNumber);
  const [otherMethods, setOtherMethods] = useState<PaymentTypeItem[]>([]);

  useEffect(() => {
    const method = getPaymentMethod(
      isOpen,
      accountsOnFile,
      otherMethods,
      isMZMToggleOn
    );

    if (method) {
      dispatch(setSelectedPaymentMethod(method));
    }
  }, [isOpen, JSON.stringify(otherMethods), JSON.stringify(accountsOnFile)]);

  useEffect(() => {
    if (accountNumber && !isEmpty(availablePaymentMethodsInfos)) {
      refetchAvailableMethods();
    }
  }, [accountNumber, JSON.stringify(availablePaymentMethodsInfos)]);

  const refetchAvailableMethods = useCallback(
    () =>
      fetchAvailablePaymentMethods({
        setOtherMethods,
        setMainMethods: noop,
        setIsLoading: noop,
        setHasError: noop,
        availablePaymentMethodsInfos,
        accountNumber,
        dispatch
      }),
    [accountNumber, JSON.stringify(availablePaymentMethodsInfos)]
  );

  return {
    refetchAvailableMethods,
    otherMethods
  };
};

export default useQuickWithdrawSetPM;
