import pawsWithdrawService from "@tvg/api/paws/withdraw";
import { updateBalance } from "@tvg/shared-actions/UserActions";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { getWithdrawalLimits } from "@tvg/sh-lib-paws/hooks/withdrawalLimits";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { PaymentType } from "@tvg/ts-types/Payment";
import { get } from "lodash";
import { PaymentTypesAvailable } from "@tvg/api/paws/types";
import { isDesktop } from "../../../../utils";
import {
  gtmAuthorizeWithdrawError,
  gtmAuthorizeWithdrawSuccess,
  gtmWithdrawAuthorize,
  gtmWithdrawValidationError
} from "../../../../gtm";
import { OnSubmitWithdrawArgs } from "../types";
import { insufficientFundsErrorMessage } from "./errorHandling";

export const onSubmitWithdraw = async ({
  value,
  minWithdrawAmount,
  maxWithdrawAmount,
  paymentType,
  selectedPaymentMethodId,
  accountId,
  setIsLoading,
  setShowAmountWarning,
  dispatch,
  setShowSuccessMessage,
  setErrorMessage,
  pawsErrorMessages,
  requestBody,
  setWithdrawalLimit,
  capiMaxWithdrawAmount,
  refetchAvailableMethods,
  setTransactionID,
  setEnableDepositPage,
  setSuccessMessagePmType
}: OnSubmitWithdrawArgs) => {
  setEnableDepositPage(false);
  setShowSuccessMessage(false);
  if (+value < +minWithdrawAmount || +value > maxWithdrawAmount) {
    setShowAmountWarning(true);
    gtmWithdrawValidationError({
      paymentType,
      accountID: accountId,
      errorMessage: insufficientFundsErrorMessage({
        minWithdrawAmount,
        value,
        userBalance: maxWithdrawAmount
      })
    });
  } else {
    try {
      gtmWithdrawAuthorize({
        paymentType,
        accountID: accountId
      });
      setIsLoading(true);
      const { data } = await pawsWithdrawService.postWithdraw(
        paymentType as PaymentTypesAvailable,
        {
          amount: +value,
          accountId,
          paymentMethodId: +selectedPaymentMethodId,
          achId: +selectedPaymentMethodId,
          ...requestBody
        }
      );

      if (data) {
        gtmAuthorizeWithdrawSuccess(paymentType, accountId, value);
        if ((!isDesktop || isTvg5()) && data.balance) {
          dispatch(updateBalance(data.balance));
        } else if (data.balance) {
          mediatorClassic.dispatch("BALANCE_UPDATE", data.balance);
        }
        if (data.transactionUUID) {
          setTransactionID(data.transactionUUID as string);
        }

        await getWithdrawalLimits(
          accountId,
          setWithdrawalLimit,
          capiMaxWithdrawAmount
        );
        if (
          !data.transactionUUID ||
          (data.transactionUUID && paymentType !== PaymentType.MAZOOMA)
        ) {
          setIsLoading(false);
        }
        if (paymentType) {
          setSuccessMessagePmType(paymentType);
        }
        setShowSuccessMessage(true);

        refetchAvailableMethods();
      }
    } catch (err) {
      const errorCode = get(err, "response.data.code", "");
      const errorByCode =
        get(pawsErrorMessages, `${errorCode}`) ||
        get(pawsErrorMessages, "default");
      gtmAuthorizeWithdrawError(
        paymentType,
        accountId,
        errorByCode.description
      );

      setErrorMessage((prevState) => ({
        ...prevState,
        isOpen: true,
        errorCode,
        isRetry: false
      }));
      setShowSuccessMessage(false);

      setIsLoading(false);
    }
  }
};
