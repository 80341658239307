import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { fetchStoryblok } from "@tvg/sh-lib-promos-onboarding/utils/fetchStoryblok";
import {
  StoryblokVersion,
  StoryblokStep
} from "@tvg/sh-lib-promos-onboarding/types/storyblok";
import PromoPreviewTemplate from "@tvg/atomic-ui/_templates/PromoPreview";
import { ComponentPreview } from "./types";
import { formatPreviewComponents } from "./helpers/formatPreviewComponents";
import { PreviewProps } from "../sharedComponentTypes";

export const PromoPreviewComponent = ({
  previewContentId = ""
}: PreviewProps) => {
  const [shouldFetchStoryblok, setShouldFetchStoryblok] = useState(true);
  const [previewSteps, setPreviewSteps] = useState<StoryblokStep[]>([]);
  const [previewComponents, setPreviewComponents] = useState<
    ComponentPreview[][]
  >([]);

  useEffect(() => {
    if (shouldFetchStoryblok) {
      fetchStoryblok(+previewContentId, StoryblokVersion.DRAFT)
        .then((response) => {
          const steps: Array<StoryblokStep> = get(
            response,
            "data.story.content.steps",
            []
          );
          setPreviewSteps(steps);
          setShouldFetchStoryblok(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }, [previewContentId, shouldFetchStoryblok]);

  useEffect(() => {
    const formattedPreviewComponents = formatPreviewComponents(previewSteps);
    setPreviewComponents(formattedPreviewComponents);
  }, [previewSteps]);

  return (
    <PromoPreviewTemplate
      steps={previewSteps}
      components={previewComponents}
      refetchStoryblok={() => {
        setShouldFetchStoryblok(true);
      }}
    />
  );
};

export default PromoPreviewComponent;
