import React, {
  cloneElement,
  isValidElement,
  useRef,
  MutableRefObject,
  useEffect,
  forwardRef
} from "react";
import { useWindowDimensions, View } from "react-native";

import { TooltipDescriptiveContent } from "./types";
import { usePopper } from "../popper";
import { Header, Paragraph } from "../typography";
import { Wrapper as StyledWrapper } from "./styled-components";
import breakpoints from "./utils/breakpoints";

const Content = forwardRef<View, TooltipDescriptiveContent>(
  (
    {
      placement,
      header,
      body,
      actionLink,
      isDarkMode,
      closeDelay,
      forceClose,
      widthContent = "284px",
      ...rest
    },
    ref
  ) => {
    const timeoutRef: MutableRefObject<number | undefined> = useRef();
    const { isOpen, closeContent } = usePopper();
    const { width } = useWindowDimensions();

    useEffect(() => {
      if (forceClose) {
        closeContent();
      }

      if (!timeoutRef.current) {
        // @ts-ignore
        timeoutRef.current = setTimeout(closeContent, closeDelay);
      }

      if (!isOpen && timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, [isOpen, forceClose]);

    return (
      <StyledWrapper
        isSmall={width <= breakpoints.SM}
        ref={ref}
        placement={placement}
        backgroundColor={isDarkMode ? "white.900" : "blue.800"}
        borderRadius="s"
        p="space-4"
        width={widthContent}
        {...rest}
      >
        {header &&
          (isValidElement(header) ? (
            cloneElement(header)
          ) : (
            <Header
              qaLabel="tooltip-descriptive-header"
              tag="h3"
              marginBottom="space-3"
              color={isDarkMode ? "grey.900" : "white.900"}
            >
              {header}
            </Header>
          ))}
        {isValidElement(body) ? (
          cloneElement(body)
        ) : (
          <Paragraph
            qaLabel="tooltip-descriptive-body"
            color={isDarkMode ? "grey.800" : "white.900"}
          >
            {body}
          </Paragraph>
        )}
        {actionLink && isValidElement(actionLink) && cloneElement(actionLink)}
      </StyledWrapper>
    );
  }
);

export default Content;
