import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Repeat = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.741 4.1212C17.1373 4.1212 21.375 6.74509 21.375 12C21.375 17.2549
      17.1373 19.8788 13.741 19.8788H10.2592C6.97951 19.8788 2.625 17.2549 2.625
      12C2.625 6.74509 6.86288 4.1212 10.2592 4.1212H13.741Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M6 4.12119L9.31742 0.803741M6 4.12119L9.31742 7.43861M6
      4.12119H13.7408C17.1371 4.12119 21.375 6.74508 21.375 12C21.375 13.1214
      21.1767 14.1231 20.8328 15.0049M18 19.8788L14.6826 16.5614M18
      19.8788L14.6826 23.1963M18 19.8788H10.259C6.8627 19.8788 2.625 17.2549
      2.625 12C2.625 10.8842 2.81608 9.88694 3.1496 9.00828"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);

export default Repeat;
