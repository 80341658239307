import { useState, useEffect, useRef, Ref } from "react";
import { Animated, View, Platform } from "react-native";
import { easeExpInOut } from "d3-ease";

const useNativeDriver = Platform.OS !== "web";

const useToastAnimation = (
  toastRef: Ref<View>,
  duration: number,
  autoHide: boolean,
  onHide?: () => void
) => {
  const opacity = useRef(new Animated.Value(0)).current;

  const [isAnimating, setIsAnimating] = useState(false);
  const [showTimeout, setShowTimeout] = useState(0);
  const [hideTimeout, setHideTimeout] = useState(0);

  useEffect(
    () => () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    },
    [showTimeout, hideTimeout]
  );

  const show = () => {
    clearTimeout(showTimeout);

    if (isAnimating) return;

    clearTimeout(hideTimeout);
    setIsAnimating(true);
    if (
      typeof toastRef !== "function" &&
      typeof toastRef?.current?.setNativeProps === "function"
    ) {
      toastRef?.current?.setNativeProps({
        pointerEvents: "auto"
      });
    }

    Animated.timing(opacity, {
      useNativeDriver,
      toValue: 1,
      duration: 300,
      easing: easeExpInOut
    }).start(({ finished }) => {
      if (finished) {
        setIsAnimating(false);
        // @ts-ignore
        if (autoHide) setHideTimeout(setTimeout(() => hide(), duration));
      }
    });
  };

  const hide = () => {
    clearTimeout(showTimeout);
    clearTimeout(hideTimeout);

    if (isAnimating) return;

    if (
      typeof toastRef !== "function" &&
      typeof toastRef?.current?.setNativeProps === "function"
    ) {
      toastRef?.current?.setNativeProps({
        pointerEvents: "none"
      });
    }

    Animated.timing(opacity, {
      useNativeDriver,
      toValue: 0,
      duration: 500,
      easing: easeExpInOut
    }).start(({ finished }) => {
      if (finished) {
        setIsAnimating(false);
        if (typeof onHide === "function") onHide();
      }
    });
  };

  return {
    opacity,
    show,
    hide,
    isAnimating,
    setIsAnimating,
    setShowTimeout,
    setHideTimeout
  };
};

export default useToastAnimation;
