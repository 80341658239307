import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Alert = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 15.75H9.00004H4.43728L5.26194 13.7711C5.74689 12.6074 5.92322
      11.3428 6.01799 10.0856C6.21719 7.44307 7.25731 3 12 3C16.8143 3 17.8134
      7.57825 17.9905 10.2041C18.0701 11.3854 18.2201 12.5741 18.6438
      13.6797L19.4373 15.75H15Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M15 15.75H19.4373L18.6438 13.6797C18.2201 12.5741 18.0701 11.3854
      17.9905 10.2041C17.8134 7.57825 16.8143 3 12 3C7.25731 3 6.21719 7.44307
      6.01799 10.0856C5.92322 11.3428 5.74689 12.6074 5.26194 13.7711L4.43728
      15.75H9.00004M15 15.75V16.5C15 18.1569 13.6569 19.5 12 19.5C10.3432 19.5
      9.00004 18.1569 9.00004 16.5V15.75M15 15.75H9.00004"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
