import { View } from "react-native";
import React, { createContext, forwardRef } from "react";
import { useQaLabel } from "../../../hooks/useQaLabel";
import { RadioGroupProps, RadioGroupContextProps } from "./types";
import { StyledViewContainer, StyledTextLabel } from "./styled-components";

const initialState = { selectedValue: "", onChange: () => {} };

export const GroupContext = createContext<RadioGroupContextProps>(initialState);

export const RadioGroup = forwardRef<View, RadioGroupProps>(
  (
    {
      label,
      children,
      selectedValue,
      qaLabel,
      onChange,
      isDisabled = false,
      ...rest
    },
    ref
  ) => {
    const viewTestProps = useQaLabel(qaLabel);
    return (
      <StyledViewContainer
        // @ts-ignore
        ref={ref}
        {...rest}
        {...viewTestProps}
        accessibilityRole="radiogroup"
      >
        <GroupContext.Provider value={{ selectedValue, onChange, isDisabled }}>
          {label && (
            <View>
              <StyledTextLabel accessibilityLabel={label}>
                {label}
              </StyledTextLabel>
            </View>
          )}
          <View>{children}</View>
        </GroupContext.Provider>
      </StyledViewContainer>
    );
  }
);
