import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type State = {
  isModalOpen: boolean;
  type: string;
  selectedMethod: {
    id: string;
    paymentType: string;
    title: string;
    subTitle: string;
    description: string;
    depositsAvailable: boolean;
    withdrawalsAvailable: boolean;
  };
};

export const initialState: State = {
  isModalOpen: false,
  type: "deposits",
  selectedMethod: {
    id: "",
    paymentType: "",
    title: "",
    subTitle: "",
    description: "",
    depositsAvailable: false,
    withdrawalsAvailable: false
  }
};

export type PaymentMethodSelectorTypePayload = {
  type: string;
};

export type SelectedPaymentMethodPayload = {
  id: string;
  paymentType: string;
  title: string;
  description: string;
  subTitle: string;
  depositsAvailable: boolean;
  withdrawalsAvailable: boolean;
  minLimit: number;
  maxLimit: number;
};

const paymentMethodSelectorSlice = createSlice({
  name: "paws",
  initialState,
  reducers: {
    openPaymentMethodSelectorModal(state) {
      state.isModalOpen = true;
    },
    closePaymentMethodSelectorModal(state) {
      state.isModalOpen = false;
    },
    setPaymentMethodSelectorType(
      state,
      action: PayloadAction<PaymentMethodSelectorTypePayload>
    ) {
      state.type = action.payload.type;
    },
    setSelectedPaymentMethod(
      state,
      action: PayloadAction<SelectedPaymentMethodPayload>
    ) {
      state.selectedMethod = action.payload;
    }
  }
});

export const {
  openPaymentMethodSelectorModal,
  closePaymentMethodSelectorModal,
  setPaymentMethodSelectorType,
  setSelectedPaymentMethod
} = paymentMethodSelectorSlice.actions;

export default paymentMethodSelectorSlice.reducer;
