import React from "react";
import { useQaLabel } from "../../../hooks";
import { Icon } from "../../icon";
import { TagIconProps } from "./types";
import { TagVariant } from "../types";
import { tagVariants } from "../styled-components";
import { TagCircleContainer } from "./styled-components";
import { useColorTokens } from "../../../theming/hooks/useColorTokens";

export const TagIcon = ({
  qaLabel = "tag-icon",
  label,
  icon,
  variant,
  ...props
}: TagIconProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  const bgColor = useColorTokens(
    tagVariants[variant as TagVariant].backgroundColor
  );
  const color = useColorTokens(
    tagVariants[variant as TagVariant].color
  ) as string;

  return (
    <>
      <TagCircleContainer
        accessibilityLabel={label}
        backgroundColor={bgColor}
        {...props}
        {...viewTestProps}
      >
        <Icon
          size="s"
          name={icon}
          backgroundColor="transparent"
          lineColor={color}
        />
      </TagCircleContainer>
    </>
  );
};

export { TagIconProps };
export default TagIcon;
