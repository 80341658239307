import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const FileDownload = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M4.125 4.125V19.875C4.125 20.7034 4.79657 21.375 5.625
      21.375H18.375C19.2034 21.375 19.875 20.7034 19.875
      19.875V7.875H14.625V2.625H5.625C4.79657 2.625 4.125 3.29657 4.125 4.125Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M19.5 14.25V22.0538M23.2031 18.4219L19.5 22.125L15.7969 18.4219M19.875
      12V7.875L14.625 2.625H5.625C4.79657 2.625 4.125 3.29657 4.125
      4.125V19.875C4.125 20.7034 4.79657 21.375 5.625 21.375H12M14.625
      2.70509V7.875H19.8018"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
