import React, { PureComponent } from "react";
// @ts-ignore
import { Route, Routes, RouteComponentProps } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import { connect } from "react-redux";
import { TvgSafeAreaProvider } from "@tvg/design-system/src/utils/tvgSafeAreaProvider";
import type { Dispatch } from "redux";
import { attempt, get, isEmpty } from "lodash";

import tvgConf from "@tvg/conf";
import calculateFeatureOverride from "@tvg/utils/featuresUtils";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import { toggleQuickDepositModal } from "@tvg/sh-lib-paws/redux/slices/quickDepositModalSlice";
import QuickWithdraw from "@tvg/wallet/src/components/QuickWithdraw";
import QuickDeposit from "@tvg/wallet/src/components/QuickDeposit";
import PendingWithdrawals from "@tvg/wallet/src/components/PendingWithdrawals";

import {
  failFeaturesRequest,
  failMessagesRequest,
  successFeaturesRequest,
  successMessagesRequest
} from "../actions/capi";
import {
  getUserData,
  setUserAccountNumber,
  setUserBalance
} from "../actions/user";
import MicroApp from "./components/MicroApp";
import {
  Messages,
  Feature,
  MapFeatureToggles,
  FeatureToggles,
  UserData
} from "../actions/types";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
  features: Feature[];
  featureToggles: MapFeatureToggles;
  messages: Messages;
  location: RouteComponentProps["location"];
  history: RouteComponentProps["history"];
  isBeta: boolean;
};

// this closes the window if it was opened programmatically
// in this case its the return url from MZM deposit
if (typeof window !== "undefined" && window.location.hash === "#closeTab") {
  window.localStorage.setItem("isMzmTabClosed", "true");
  window.close();
}

export class Main extends PureComponent<Props> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tvg: any = tvgConf();

  componentDidMount() {
    if (typeof window !== "undefined") {
      if (!isEmpty(this.props.features)) {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        this.props.features.forEach((toggle: Feature) => {
          let { enabled } = toggle;
          featureOverrides = calculateFeatureOverride(
            hasFeaturesOverrides,
            featureOverrides,
            toggle
          );
          if (get(featureOverrides, toggle.name)) {
            // @ts-ignore
            enabled = featureOverrides[toggle.name].enabled; // eslint-disable-line
          }

          // @ts-ignore
          featureToggles[toggle.name] = enabled;
        });

        if (typeof window !== "undefined" && !isEmpty(featureOverrides)) {
          this.setFeaturesOverrides(featureOverrides);
        }
        this.props.dispatch(successFeaturesRequest(featureToggles));
      } else {
        this.getFeatures();
      }

      this.getMessages();

      mediatorClassic.subscribe("UPDATE_ROUTER", () => {
        const pathWithParameters = window.location.href.replace(
          window.location.origin,
          ""
        );

        if (this.props.location.pathname !== pathWithParameters) {
          this.props.history.replace(pathWithParameters);
        }
      });

      mediator.base.subscribe("OPEN_QUICK_DEPOSIT", () => {
        this.props.dispatch(toggleQuickDepositModal(true));
      });
      mediator.base.subscribe(
        "TVG4_NAVIGATION",
        (data: { payload: object }) => {
          mediatorClassic.dispatch(
            "TVG4_NAVIGATION",
            data && data.payload ? data.payload : {}
          );
        }
      );

      // tries to get the user id (accountNumber) from session storage and dispatch it to user data
      // this is useful because every component initialized by tvg-mobile will know if the user is logged, plus it's
      // account number beforehand
      attempt(() => {
        if (window.sessionStorage.getItem("userId")) {
          this.props.dispatch(
            setUserAccountNumber(
              window.sessionStorage.getItem("userId") as string
            )
          );
        }
      });
      this.subscribeBalance();
      this.subscribeToUserLogin();
    }
  }

  componentWillUnmount(): void {
    mediator.base.unsubscribe(
      "TVG4_NAVIGATION",
      (data: { payload: object }) => {
        mediatorClassic.dispatch(
          "TVG4_NAVIGATION",
          data && data.payload ? data.payload : {}
        );
      }
    );

    mediatorClassic.unsubscribe("UPDATE_ROUTER", () => {
      const pathWithParameters = window.location.href.replace(
        window.location.origin,
        ""
      );
      if (this.props.location.pathname !== pathWithParameters) {
        this.props.history.replace(pathWithParameters);
      }
    });
  }

  getFeaturesOverrides = () =>
    attempt(
      // @ts-ignore
      () => JSON.parse(window.localStorage.getItem("featureOverrides")),
      false
    );

  setFeaturesOverrides = (features: Feature) =>
    attempt(() =>
      window.localStorage.setItem("featureOverrides", JSON.stringify(features))
    );

  getFeatures = () =>
    typeof window !== "undefined" &&
    this.tvg
      .getFeatures()
      .then((response: FeatureToggles | false) => {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        if (response && Array.isArray(response.featureToggles)) {
          response.featureToggles.forEach((toggle: Feature) => {
            let { enabled } = toggle;
            featureOverrides = calculateFeatureOverride(
              hasFeaturesOverrides,
              featureOverrides,
              toggle
            );
            if (get(featureOverrides, toggle.name)) {
              // @ts-ignore
              enabled = featureOverrides[toggle.name].enabled; // eslint-disable-line
            }

            // @ts-ignore
            featureToggles[toggle.name] = enabled;
          });
        }

        if (!isEmpty(featureOverrides)) {
          this.setFeaturesOverrides(featureOverrides);
        }

        this.props.dispatch(successFeaturesRequest(featureToggles));
      })
      .catch((err: Error) => this.props.dispatch(failFeaturesRequest(err)));

  getMessages = () => {
    // TODO: check namespaces
    const messageNamespaces = ["Login", "InformationalPages", "Global"];

    return (
      this.tvg
        .getMessages(messageNamespaces)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) =>
          this.props.dispatch(successMessagesRequest(response))
        )
        .catch((err: Error) => this.props.dispatch(failMessagesRequest(err)))
    );
  };

  subscribeToUserLogin = () =>
    mediatorClassic.subscribeWithPast(
      "TVG_LOGIN:USER_SESSION_UPDATE",
      (data: UserData) => {
        this.props.dispatch(getUserData(data));
      }
    );

  subscribeBalance = () =>
    mediatorClassic.subscribeWithPast("BALANCE_UPDATE", (data: number) => {
      this.props.dispatch(setUserBalance(data));
    });

  render() {
    return (
      <main>
        <TvgSafeAreaProvider>
          <>
            <Routes>
              <Route path="/wallet" element={<MicroApp />} />
            </Routes>
            <QuickDeposit />
            <QuickWithdraw />
            <PendingWithdrawals />
          </>
        </TvgSafeAreaProvider>
      </main>
    );
  }
}

export default connect(
  (store) => ({
    featureToggles: get(store, "capi.featureToggles"),
    features: get(store, "capi.features"),
    // @ts-ignore
    messages: store.capi.messages
  }),
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (dispatch: Dispatch<any>) => ({ dispatch })
  // @ts-ignore
)(withRouter(Main));
