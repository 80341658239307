import axios from "axios";
import { StoryblokDraft } from "../types/storyblok";
import {
  STORYBLOK_CONTENT_PATH,
  STORYBLOK_TOKEN,
  STORYBLOK_URL
} from "../constraints/config";
import { getStoryblokVersion } from "./getStoryblokVersion";
import { fetchStoryBlokCacheVersion } from "./fetchStoryBlokCacheVersion";

export const fetchStoryblok = async (
  promoId: number,
  draft?: StoryblokDraft
) => {
  const storyblokUrl = `${STORYBLOK_URL}/${STORYBLOK_CONTENT_PATH}/${promoId}`;
  const cacheVersion = await fetchStoryBlokCacheVersion(draft);
  return axios({
    method: "GET",
    url: storyblokUrl,
    headers: {
      "content-type": "application/json"
    },
    params: {
      version: getStoryblokVersion(draft),
      token: STORYBLOK_TOKEN,
      resolve_relations: "global_ref.reference",
      cv: cacheVersion
    }
  });
};
