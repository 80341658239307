import { History } from "@tvg/ts-types/History";
import { goToWallet } from "@tvg/utils/routeRedirectUtils";
import { PromoCTA } from "../../types";

export const getDepositCTA = (
  isFulfilled: boolean,
  history: History,
  isDesktop: boolean
) =>
  ({
    CTALabel: "Deposit Now",
    onCTAPress: () => {
      goToWallet(history, isDesktop);
    },
    CTAShow: !isFulfilled
  } as PromoCTA);
