import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const User = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M15.7521 6.37018C15.7521 8.4424 14.0723 10.1223 12 10.1223C9.92781
      10.1223 8.24794 8.4424 8.24794 6.37018C8.24794 4.29796 9.92781 2.61809 12
      2.61809C14.0723 2.61809 15.7521 4.29796 15.7521 6.37018Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M18.8997 15.1584C18.6327 14.1795 17.7436 13.5004 16.729
      13.5004H7.27011C6.25547 13.5004 5.36636 14.1795 5.09939 15.1584L3.91897
      19.4866C3.65873 20.4408 4.37706 21.3813 5.36612 21.3813H18.633C19.622
      21.3813 20.3404 20.4408 20.0801 19.4866L18.8997 15.1584Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M15.7521 6.37018C15.7521 8.4424 14.0723 10.1223 12 10.1223C9.92781
      10.1223 8.24794 8.4424 8.24794 6.37018C8.24794 4.29796 9.92781 2.61809 12
      2.61809C14.0723 2.61809 15.7521 4.29796 15.7521 6.37018Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <path
      d="M18.8997 15.1584C18.6327 14.1795 17.7436 13.5004 16.729
      13.5004H7.27011C6.25547 13.5004 5.36636 14.1795 5.09939 15.1584L3.91897
      19.4866C3.65873 20.4408 4.37706 21.3813 5.36612 21.3813H18.633C19.622
      21.3813 20.3404 20.4408 20.0801 19.4866L18.8997 15.1584Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
