import { Colors } from "../types";

export const colors: Colors = {
  blue_accent: {
    "000": "#FAFCFF",
    "100": "#EBF5FF",
    "200": "#CDDFFA",
    "300": "#AFCCFA",
    "400": "#87B2F5",
    "500": "#5390ED",
    "600": "#3574D4",
    "700": "#2F67BD",
    "800": "#2959A3",
    "900": "#1C3D70"
  },
  green: {
    "000": "#F5FFF7",
    "100": "#CEEBD4",
    "200": "#B1DEBA",
    "300": "#90D19D",
    "400": "#69BF7A",
    "500": "#38AB4F",
    "600": "#329A47",
    "700": "#2D873F",
    "800": "#246F33",
    "900": "#195024"
  },
  yellow: {
    "000": "#FFF6E0",
    "100": "#FFECBF",
    "200": "#FFE099",
    "300": "#FFD470",
    "400": "#FFC642",
    "500": "#FFB80C",
    "600": "#E6A40A",
    "700": "#CA9008",
    "800": "#A87707",
    "900": "#795605"
  },
  blue: {
    "000": "#E9EFF5",
    "100": "#D6E3F0",
    "200": "#B7CCE1",
    "300": "#92B0CE",
    "400": "#668FB8",
    "500": "#27619B",
    "600": "#23568A",
    "700": "#1E4B79",
    "800": "#193D62",
    "900": "#112B44"
  },
  orange: {
    "000": "#FFF3E6",
    "100": "#FFE6CC",
    "200": "#FAD5AF",
    "300": "#F5C089",
    "400": "#F0A556",
    "500": "#ED850C",
    "600": "#D67A11",
    "700": "#BD6909",
    "800": "#AD6008",
    "900": "#854A06"
  },
  red: {
    "000": "#FCEBEB",
    "100": "#F7D5D5",
    "200": "#F5BCBC",
    "300": "#F09E9E",
    "400": "#E87676",
    "500": "#DE3131",
    "600": "#C92C2C",
    "700": "#B02727",
    "800": "#912020",
    "900": "#661616"
  },
  grey: {
    "000": "#F8F8F9",
    "100": "#ECEDEE",
    "200": "#DEE1E3",
    "300": "#CFD2D6",
    "400": "#BFC4C8",
    "500": "#ADB3B9",
    "600": "#99A0A7",
    "700": "#818A92",
    "800": "#626D78",
    "900": "#384048"
  },
  black: {
    "000": "rgba(0, 0, 0, 0.1)",
    "100": "rgba(0, 0, 0, 0.2)",
    "200": "rgba(0, 0, 0, 0.3)",
    "300": "rgba(0, 0, 0, 0.4)",
    "400": "rgba(0, 0, 0, 0.5)",
    "500": "rgba(0, 0, 0, 0.6)",
    "600": "rgba(0, 0, 0, 0.7)",
    "700": "rgba(0, 0, 0, 0.8)",
    "800": "rgba(0, 0, 0, 0.9)",
    "900": "#000000"
  },
  white: {
    "000": "rgba(255, 255, 255, 0.1)",
    "100": "rgba(255, 255, 255, 0.2)",
    "200": "rgba(255, 255, 255, 0.3)",
    "300": "rgba(255, 255, 255, 0.4)",
    "400": "rgba(255, 255, 255, 0.5)",
    "500": "rgba(255, 255, 255, 0.6)",
    "600": "rgba(255, 255, 255, 0.7)",
    "700": "rgba(255, 255, 255, 0.8)",
    "800": "rgba(255, 255, 255, 0.9)",
    "900": "#FFFFFF"
  },
  tint: {
    blue_tint: "#1A4066",
    green_tint: "#194546",
    yellow_tint: "#414739"
  }
};
