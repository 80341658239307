import React from "react";
import Svg, { Path } from "react-native-svg";
import { tvgColorPalette as colors } from "../../../theming/themes/common";

export const Success = () => (
  <Svg width="60" height="60" viewBox="0 0 60 60" fill="none">
    <Path
      d="M58.095 30.0108C58.095 45.5272 45.5165 58.1058 30 58.1058C14.4836 58.1058 1.90504 45.5272 1.90504 30.0108C1.90504 14.4944 14.4836 1.91583 30 1.91583C45.5165 1.91583 58.095 14.4944 58.095 30.0108Z"
      fill={colors.green[100]}
    />
    <Path
      d="M16.4377 30L26.1252 39.6875L45.5002 20.3125M58.095 30.0108C58.095 45.5272 45.5165 58.1058 30 58.1058C14.4836 58.1058 1.90504 45.5272 1.90504 30.0108C1.90504 14.4944 14.4836 1.91583 30 1.91583C45.5165 1.91583 58.095 14.4944 58.095 30.0108Z"
      stroke={colors.green[600]}
      strokeWidth="3"
    />
  </Svg>
);
