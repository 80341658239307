import { get } from "lodash";
import { PromoStepType } from "@tvg/sh-lib-promos-onboarding/types/promoSteps";

const formattedStepName: Record<PromoStepType, string> = {
  [PromoStepType.DEPOSIT]: "Deposit Step",
  [PromoStepType.WAGER]: "Wager Step",
  [PromoStepType.PAYOUT]: "Payout Step",
  [PromoStepType.UNKNOWN]: ""
};

export const formatStepName = (step: PromoStepType): string =>
  get(formattedStepName, step, "");
