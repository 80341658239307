import React from "react";
import { get } from "lodash";
import { Store } from "redux";
import { useSelector } from "react-redux";
import {
  MasterCardIcon,
  VisaIcon,
  OnlineBankingIcons
} from "@tvg/atomic-ui/_static/Icons/paws";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { Tag } from "@tvg/design-system";
import { getPawsContent } from "@tvg/sh-lib-paws/redux/selectors";
import * as allIcons from "@tvg/atomic-ui/_static/Icons/icons";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";

import {
  getPaymentMethodIconName,
  getPawsOnlyIcons,
  tagMapper,
  getPaymentMethodDescription
} from "../../utils";
import type { PawsContent, TagMapper } from "../../types";
import type { Props } from "./types";

import {
  Item,
  Content,
  Title,
  TitleText,
  CardsIcons,
  Description,
  FlagContainer,
  IconsContainer
} from "./styled-components";

const DesktopListItem = ({
  title,
  paymentType,
  isUnderMaintenance,
  isDisabled,
  description,
  tags = []
}: Props) => {
  const iconName = getPaymentMethodIconName(paymentType);
  const pawsLabels = useSelector<Store, PawsContent>(getPawsContent);
  const pawsIsNewTag = useSelector<Store, boolean>((store) =>
    get(store, "capi.featureToggles.pawsIsNewTag", false)
  );

  const shouldBeDisable = isDisabled || isUnderMaintenance;

  return (
    // @ts-ignore
    <Item data-qa-label={`new-payment-methods-${paymentType}`}>
      <Content>
        <Title data-qa-label="new-payment-methods-title">
          {getPawsOnlyIcons(iconName) || (
            <Icon
              icon={allIcons[iconName]}
              size={16}
              color={
                shouldBeDisable
                  ? buildColor("blue", "300")
                  : buildColor("blue_accent", "500")
              }
            />
          )}
          <TitleText
            isDisabled={shouldBeDisable}
            data-qa-label="new-payment-methods-subTitle"
          >
            {title}
          </TitleText>
        </Title>
        {!!tags.length && (
          <FlagContainer>
            {tags
              .filter(
                (tag) =>
                  tag.type !== "new" || (tag.type === "new" && pawsIsNewTag)
              )
              .map((tag, i) => (
                <Tag
                  key={`new-payment-methods-flag-${tag.type}-${tag.text}-${
                    i + 1
                  }`}
                  variant={tagMapper[tag.color as keyof TagMapper] || "info"}
                  qaLabel={`new-payment-methods-flag-${tag.type}`}
                  label={tag.text}
                  size="s"
                />
              ))}
          </FlagContainer>
        )}

        <Description
          isDisabled={shouldBeDisable}
          data-qa-label="new-payment-methods-description"
          hasMarginBottom={
            paymentType !== PaymentTypeEnum.MAZOOMA &&
            paymentType !== PaymentTypeEnum.CREDIT_DEBIT_CARD
          }
        >
          {getPaymentMethodDescription(
            shouldBeDisable,
            pawsLabels,
            paymentType,
            description,
            isUnderMaintenance
          )}
        </Description>
        <IconsContainer>
          {paymentType === "MZM" && (
            <OnlineBankingIcons label="new-online-banking-icons" />
          )}
          {paymentType === "CCP" && (
            <CardsIcons data-qa-label="new-payment-methods-icons">
              <MasterCardIcon />
              <VisaIcon />
            </CardsIcons>
          )}
        </IconsContainer>
      </Content>
    </Item>
  );
};

DesktopListItem.defaultProps = {
  title: "",
  paymentType: "",
  description: "",
  isDisabled: false
};

export default React.memo(DesktopListItem);
