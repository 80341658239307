import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { buildColor } from "@tvg/design-system";
import { Device } from "@tvg/ts-types/Device";

const getPromoProgressContainerStyle = (
  device: Device,
  isHomePageSection: boolean
): FlattenSimpleInterpolation => {
  let styles: FlattenSimpleInterpolation;
  if (device === Device.MOBILE) {
    styles = css`
      flex-direction: column;
      padding: 12px;
    `;
  } else {
    styles = css`
      flex-direction: row;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
    `;
  }

  if (isHomePageSection) {
    styles =
      device === Device.DESKTOP
        ? css`
            ${styles};
            border-bottom: 1px solid ${buildColor("blue", "100")};
          `
        : css`
            ${styles};
            margin-bottom: 8px;
          `;
  }

  return styles;
};

export const PromoProgressContainer = styled.div<{
  device: Device;
  isDarkMode?: boolean;
  isHomePageSection?: boolean;
}>`
  display: flex;
  flex: 1;
  background-color: ${({ isDarkMode }) =>
    isDarkMode ? buildColor("blue", "800") : buildColor("blue_accent", "000")};
  ${({ device, isHomePageSection }) =>
    getPromoProgressContainerStyle(device, !!isHomePageSection)};
`;

export const InfoContainer = styled.div<{
  device?: Device;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ device }) =>
    device !== Device.MOBILE &&
    css`
      flex-direction: column;
      margin-right: 20px;
      width: 25%;
    `}
`;

export const HeaderContainer = styled.div<{
  device?: Device;
}>`
  ${({ device }) =>
    device !== Device.MOBILE
      ? css`
          flex: 0;
          order: 0;
        `
      : css`
          flex: 50%;
        `};
`;

export const DaysContainer = styled.div<{
  device?: Device;
}>`
  display: flex;
  ${({ device }) =>
    device !== Device.MOBILE
      ? css`
          flex: 0;
          order: 2;
          margin-top: 8px;
          justify-content: start;
        `
      : css`
          flex: 50%;
          justify-content: flex-end;
        `}
`;

export const DetailsContainer = styled.div<{
  device?: Device;
}>`
  margin-top: 2px;
  flex: 100%;
  ${({ device }) =>
    device !== Device.MOBILE &&
    css`
      order: 1;
    `}
`;

export const ProgressBarContainer = styled.div<{
  device?: Device;
}>`
  flex: 1;
  ${({ device }) =>
    device === Device.MOBILE &&
    css`
      margin-bottom: 12px;
    `}
`;

export const ButtonContainer = styled.div<{
  device?: Device;
}>`
  ${({ device }) =>
    device !== Device.MOBILE &&
    css`
      min-width: 112px;
      margin-left: 20px;
    `}
`;
