import styled, { css } from "styled-components/native";
import { View, Text } from "react-native";
import {
  compose,
  variant,
  space,
  SpaceProps
} from "../../../../theming/styledSystem";
import { stylesStepBarUncompleted } from "../styles";
import { ProgressBarVariant } from "../../types";

export const stylesStepBarDescription = compose(
  variant({
    prop: "variant",
    variants: {
      dark: {
        color: "white.900"
      },
      light: {
        color: "grey.900"
      }
    }
  })
);

export const StyledStepBarContainer = styled(View)`
  flex: 1;
`;

export const StyledStepBar = styled(View)<{
  isCompleted?: boolean;
  variant: ProgressBarVariant;
}>`
  height: 4px;
  ${({ theme, isCompleted }) =>
    isCompleted
      ? css`
          background-color: ${theme.colors.green[500]};
        `
      : stylesStepBarUncompleted}
`;

export const StyledStepBarDescription = styled(Text)<
  {
    variant: ProgressBarVariant;
  } & SpaceProps
>`
  text-align: center;
  ${space}
  ${stylesStepBarDescription}
  ${({ theme }) => css`
    font-family: ${theme.fonts.regular};
    font-size: ${theme.fontSizes.xs};
    font-weight: ${theme.fontWeights.normal};
  `}
`;
