import React, {
  useState,
  useEffect,
  useRef,
  memo,
  isValidElement,
  cloneElement,
  forwardRef
} from "react";
import { View, Animated, Platform, Pressable } from "react-native";
import { AlertInlineProps, AlertInlineCompoundComponent } from "./types";
import {
  AlertInlineContainer,
  AlertInlineMessage,
  AlertInlineTitle,
  AlertInlineIconWrapper,
  AlertInlineInfo,
  AlertInlineActions
} from "./styled-components";
import { Icon as AlertInlineIcon, Link as AlertInlineLink } from "./components";
import { useEvents } from "../../hooks/useEvents";
import { useQaLabel } from "../../hooks/useQaLabel";

const isWeb = Platform.OS === "web";
export const AlertInline = memo(
  forwardRef<View, AlertInlineProps>(
    (
      {
        message,
        title,
        qaLabel,
        variant = "informational",
        type = "floating",
        hasDismissButton = false,
        hasDismissAnimation = true,
        onDismissPress,
        actionLink,
        icon,
        hasRoundedStyle,
        isFullWidth = true,
        ...styledSystemProps
      }: AlertInlineProps,
      ref
    ) => {
      const [isVisible, setVisibility] = useState(true);
      const [hasAnimationEnded, setAnimationEnded] = useState(false);
      const opacity = useRef(new Animated.Value(1)).current;
      const hasTitleAndMessage = !!(title && message);
      const hasOnDismissPress = !!(
        hasDismissButton ||
        (onDismissPress && typeof onDismissPress === "function")
      );
      const hasLink = !!(actionLink && isValidElement(actionLink));

      const { events, eventsHandlers } = useEvents({});
      const dismissTestProps = useQaLabel("alert-inline-dismiss");

      useEffect(() => {
        if (hasDismissAnimation && !isVisible) {
          Animated.timing(opacity, {
            toValue: 0,
            duration: 400,
            useNativeDriver: !isWeb
          }).start(() => setAnimationEnded(true));
        }
      }, [isVisible, hasDismissAnimation]);

      const display = hasAnimationEnded ? "none" : "flex";

      return (
        <Animated.View style={{ opacity, display }}>
          <AlertInlineContainer
            variant={variant}
            type={type}
            hasOnDismissPress={hasOnDismissPress}
            hasRoundedStyle={hasRoundedStyle}
            isFullWidth={isFullWidth}
            ref={ref}
            qaLabel={qaLabel}
            {...styledSystemProps}
          >
            <AlertInlineIconWrapper hasRoundedStyle={hasRoundedStyle}>
              <AlertInlineIcon variant={variant} type={type} icon={icon} />
            </AlertInlineIconWrapper>
            <AlertInlineInfo
              hasRoundedStyle={hasRoundedStyle}
              hasLink={hasLink}
              hasTitleAndMessage={hasTitleAndMessage}
            >
              {typeof title === "string" && title !== "" && (
                <AlertInlineTitle
                  variant={variant}
                  type={type}
                  hasRoundedStyle={hasRoundedStyle}
                  hasLink={hasLink}
                >
                  {isWeb ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: title
                      }}
                    />
                  ) : (
                    title
                  )}
                </AlertInlineTitle>
              )}
              <AlertInlineMessage
                variant={variant}
                type={type}
                hasLink={hasLink}
                hasRoundedStyle={hasRoundedStyle}
                hasTitleAndMessage={hasTitleAndMessage}
                qaLabel="alert-inline-message"
              >
                {isWeb ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: message
                    }}
                  />
                ) : (
                  message
                )}
              </AlertInlineMessage>
              {hasLink &&
                cloneElement(actionLink, { variant, type } as Partial<unknown>)}
            </AlertInlineInfo>
            {hasOnDismissPress && (
              <AlertInlineActions hasRoundedStyle={hasRoundedStyle}>
                <Pressable
                  onPress={(e) => {
                    if (typeof onDismissPress === "function") onDismissPress(e);
                    setVisibility(false);
                  }}
                  accessibilityRole="button"
                  {...eventsHandlers}
                  {...dismissTestProps}
                >
                  <AlertInlineIcon
                    p="space-4"
                    type={type}
                    variant={variant}
                    events={events}
                    isClose
                  />
                </Pressable>
              </AlertInlineActions>
            )}
          </AlertInlineContainer>
        </Animated.View>
      );
    }
  )
) as AlertInlineCompoundComponent<typeof AlertInlineLink>;

export { AlertInlineProps };
AlertInline.Link = AlertInlineLink;
export default AlertInline;
