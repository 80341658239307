import React from "react";
import {
  BalanceContainer,
  UserBalance,
  Description
} from "./styled-components";

export const Balance = ({
  balance,
  description
}: {
  balance: string;
  description: string;
}) => (
  <BalanceContainer>
    <UserBalance>{balance}</UserBalance>
    <Description>{description}</Description>
  </BalanceContainer>
);
