import { AlertInline, Tag } from "@tvg/design-system/src/index";
import { ComponentDictionary } from "../types/promoOnboardingComponents";

export const ONBOARDING_DICTIONARY: ComponentDictionary = {
  alert_inline: {
    component: AlertInline,
    defaultProps: {
      qaLabel: "promoOnboardingAlertInline",
      type: "fixed",
      hasDismissButton: false
    }
  },
  tag: {
    component: Tag,
    defaultProps: {
      qaLabel: "promoOnboardingTag",
      size: "m",
      icon: "optedIn",
      variant: "promo",
      label: ""
    }
  },
  unknown: {
    component: null,
    defaultProps: {}
  }
};
