import axios from "axios";
import { get } from "lodash";
import { getTime } from "date-fns";
import { getStoryblokVersion } from "./getStoryblokVersion";
import { StoryblokDraft } from "../types/storyblok";
import { STORYBLOK_SPACES_URL, STORYBLOK_TOKEN } from "../constraints/config";

export const fetchStoryBlokCacheVersion = async (
  draft?: StoryblokDraft
): Promise<number | null> => {
  const cacheTimestamp = getTime(Date.now());

  try {
    const response = await axios({
      method: "GET",
      url: STORYBLOK_SPACES_URL,
      headers: {
        "content-type": "application/json"
      },
      params: {
        version: getStoryblokVersion(draft),
        token: STORYBLOK_TOKEN
      }
    });
    return get(response, "data.space.version", cacheTimestamp);
  } catch (error: unknown) {
    console.error("Error trying to get cache version in storyblok", error);
    return cacheTimestamp;
  }
};

export default fetchStoryBlokCacheVersion;
