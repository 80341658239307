import React from "react";
import Illustration from "./Illustration";
import { ActiveInput } from "./types";

export const getIllustration = (activeInput: ActiveInput) => {
  switch (activeInput) {
    case "accountNumber":
      return (
        <Illustration
          isAccountNumberFocused
          qaLabel="echeck-accountNumber-ilustration"
        />
      );
    case "routingNumber":
      return (
        <Illustration
          isRoutingNumberFocused
          qaLabel="echeck-routingNumber-ilustration"
        />
      );
    default:
      return <Illustration />;
  }
};
