import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Email = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M21.375 5.625H2.625V18.375H21.375V5.625Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M2.69998 5.69998L12 13.5L21.3 5.69998M2.625
      5.625H21.375V18.375H2.625V5.625Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
