import styled from "styled-components/native";
import { Text } from "react-native";

import {
  space,
  color,
  system,
  compose,
  fontFamily,
  fontSize,
  flex,
  layout,
  typography
} from "../../../theming/styledSystem";
import { useQaLabel } from "../../../hooks/useQaLabel";
import { getAccessibilityRole } from "../../../utils/accessibility";
import { ParagraphProps } from "./types";

const lineHeightNormal = system({
  fontSize: {
    property: "lineHeight",
    scale: "lineHeights"
  }
});

const lineHeightTaller = system({
  fontSize: {
    property: "lineHeight",
    scale: "lineHeightsTaller"
  }
});

const transformText = system({
  textTransform: {
    property: "textTransform"
  },
  whiteSpace: {
    property: "whiteSpace"
  },
  textOverflow: {
    property: "textOverflow"
  }
});

const styles = compose(
  space,
  color,
  fontFamily,
  fontSize,
  transformText,
  typography,
  layout
);

export const Paragraph = styled(Text).attrs<ParagraphProps>((props) => ({
  ref: props.ref,
  accessibilityRole: getAccessibilityRole("paragraph"),
  overflow: props.textOverflow === "ellipsis" ? "hidden" : "visible",
  width: props.textOverflow === "ellipsis" ? "100%" : "auto",
  ...useQaLabel(props.qaLabel)
}))<ParagraphProps>`
  ${(props) => (props.isLineHigher ? lineHeightTaller : lineHeightNormal)}
  ${styles};
  ${flex}
`;

Paragraph.defaultProps = {
  fontFamily: "regular",
  fontSize: "s",
  isLineHigher: false,
  color: "grey.900"
};
