import React from "react";
import { Path } from "react-native-svg";
import { IconPathProps } from "../types";

const Add = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M12 4.5V19.5M4.5 12H19.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default Add;
