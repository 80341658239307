import React, { useEffect, useMemo, useState, useCallback } from "react";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import withdrawMap from "@tvg/utils/withDraws";
import formatCurrency from "@tvg/formatter/currency";
import tvgConf from "@tvg/conf";
import {
  Icon,
  Paragraph,
  Loading,
  IconButton,
  DialogMessage,
  Button,
  ToastManager,
  AlertInline
} from "@tvg/design-system";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import {
  Column,
  Container,
  Wrapper,
  StyledHeader,
  StyledRow,
  HeaderTitle,
  StyledIconButton,
  ModalBody,
  CancelModalInfoContainer,
  CancelButtonsContainer,
  CancelButtonWrapper,
  LoadingContainer,
  IconButtonContainer,
  CustomerServiceLink,
  EmptyContainer,
  StyledFooter,
  EmptyDialogContainer
} from "./styled-components";
import {
  RowProps,
  Props,
  Withdrawal,
  CancelInformation,
  CancelModalContent,
  CancelInformationMapped
} from "./types";

const isMobile = tvgConf().device === "mobile";

const P = ({ text }: { text: string }) => (
  <Paragraph
    fontFamily="regular"
    fontSize="s"
    color="grey.900"
    qaLabel="table-column-text"
  >
    {text}
  </Paragraph>
);

const Header = ({ hideTopBorder }: { hideTopBorder?: boolean }) => {
  const { header } = useSelector((store) =>
    parseCapiMessage(store, "capi.messages.pendingWithdraws", [])
  );
  return (
    <StyledHeader hideTopBorder={hideTopBorder} data-qa-label="table-header">
      {header &&
        header.map((item: string, index: number) => (
          <Column
            key={`header-item-${index.toString()}`}
            data-qa-label="table-header-column"
          >
            <HeaderTitle data-qa-label="table-column-title">{item}</HeaderTitle>
          </Column>
        ))}
    </StyledHeader>
  );
};

const Footer = ({ closeModalCallback }: { closeModalCallback: () => void }) => {
  const { footer } = useSelector((store) =>
    parseCapiMessage(store, "capi.messages.pendingWithdraws", [])
  );

  return (
    <StyledFooter>
      <Button
        variant="primary"
        isStretched
        size="l"
        qaLabel="pending-withdrawals-button-footer"
        onPress={closeModalCallback}
      >
        {footer}
      </Button>
    </StyledFooter>
  );
};

export const Row = ({
  item,
  isLastRow = false,
  showDelete = true
}: RowProps) => (
  <StyledRow
    key={item.id}
    data-qa-label="table-row"
    isLastRow={isLastRow}
    showDelete={showDelete}
  >
    <Column data-qa-label="table-column">
      <P text={item.date} />
    </Column>
    <Column data-qa-label="table-column">
      <P text={item?.title || withdrawMap(item.type)} />
    </Column>
    <Column data-qa-label="table-column">
      <P text={formatCurrency(item.amount)} />
    </Column>
    {showDelete && (
      <StyledIconButton data-qa-label="cancel-button" onClick={item.onCancel}>
        <Icon size="s" qaLabel="trash-icon" name="trash" />
      </StyledIconButton>
    )}
  </StyledRow>
);

export const getMappedWithdrawals = (
  withdrawals: Withdrawal[],
  paymentMethodsInfo: Record<string, Record<string, string>>
): Withdrawal[] =>
  withdrawals.map((item) => ({
    ...item,
    title: paymentMethodsInfo[withdrawMap(item.type)]?.title
  }));

export const renderWithdrawalRows = (mappedWithdrawals: Withdrawal[]) =>
  mappedWithdrawals.map((item, index, { length }) => (
    <Row item={item} isLastRow={index + 1 === length} key={item.id} />
  ));

const renderCancelWithdrawal = (
  mappedCancelInformation: CancelInformationMapped,
  cancelModalContent: CancelModalContent,
  cancelInformation: CancelInformation
) => (
  <>
    <Header />
    <Row item={mappedCancelInformation} showDelete={false} />
    <CancelModalInfoContainer>
      <Paragraph
        qaLabel="cancel-modal-description"
        fontFamily="regular"
        fontSize="s"
        color="grey.800"
      >
        {get(cancelModalContent, "text")}
      </Paragraph>
      {cancelInformation.isLoading ? (
        <LoadingContainer data-qa-label="loading-container">
          <Loading
            qaLabel="loading-spinner"
            color="blue.900"
            bgColor="blue_accent.400"
            size="xl"
          />
        </LoadingContainer>
      ) : (
        <CancelButtonsContainer>
          <CancelButtonWrapper>
            <Button
              qaLabel="close-cancel-withdrawal-button"
              onPress={cancelInformation.onCancelClose}
              variant="secondary"
              size="l"
              isStretched
              px="4px"
            >
              {get(cancelModalContent, "buttons.secondary", "Never mind")}
            </Button>
          </CancelButtonWrapper>
          <CancelButtonWrapper>
            <Button
              qaLabel="cancel-withdrawal-button"
              variant="primary"
              onPress={cancelInformation.onCancelWithdraw}
              size="l"
              isStretched
              px="4px"
            >
              {get(
                cancelModalContent,
                "buttons.primary",
                "Yes, cancel withdrawal"
              )}
            </Button>
          </CancelButtonWrapper>
        </CancelButtonsContainer>
      )}
    </CancelModalInfoContainer>
  </>
);

const PendingWithdraws = ({
  withdrawals,
  onCloseErrorCancellingModal,
  closeModalCallback
}: Props) => {
  const {
    cancelModalContent,
    successMessage,
    errorModalContent,
    emptyMessageContent
  } = useSelector((store) =>
    parseCapiMessage(store, "capi.messages.pendingWithdraws", {})
  );
  const isCancelModalOpen = useSelector((store) =>
    get(store, "withdrawals.isCancelModalOpen", false)
  );
  const cancelInformation = useSelector((store) =>
    get(store, "withdrawals.cancelInformation", {})
  );
  const showCancelSuccessfulMessage = useSelector((store) =>
    get(store, "withdrawals.showCancelSuccessfulMessage", false)
  );
  const availablePaymentMethodsInfos = useSelector((store) =>
    parseCapiMessage(store, "capi.messages.availablePaymentMethodsInfos", {})
  );
  const isPendingLoading = useSelector((store) =>
    get(store, "withdrawals.isPendingLoading", false)
  );
  const [showErrCancellingModal, setShowErrCancellingModal] = useState(false);

  const sharedModalProps = {
    isFullHeight: false,
    layerOffset: 1,
    offsetRight: 12,
    offsetLeft: 12,
    animation: "bottomFloating",
    useModalHeaderV3: true,
    showBottomShadow: false,
    fixedWidth: isMobile ? "100%" : "375px",
    isTitleCenter: true
  };

  useEffect(() => {
    if (cancelInformation.hasError) {
      setShowErrCancellingModal(true);
      cancelInformation.onCancelClose();
    }
  }, [cancelInformation]);

  useEffect(() => {
    if (showCancelSuccessfulMessage && isMobile) {
      ToastManager.show({
        variant: "success",
        message: successMessage,
        hasDismissButton: true
      });
    }
  }, [showCancelSuccessfulMessage]);

  const mappedWithdrawalsTitle = useMemo(
    () => getMappedWithdrawals(withdrawals, availablePaymentMethodsInfos),
    [withdrawals, availablePaymentMethodsInfos]
  );

  const renderWithdrawals = useMemo(
    () => renderWithdrawalRows(mappedWithdrawalsTitle),
    [mappedWithdrawalsTitle]
  );

  const mappedCancelInformation = useMemo(
    () => ({
      ...cancelInformation,
      title:
        availablePaymentMethodsInfos[withdrawMap(cancelInformation.type)]?.title
    }),
    [cancelInformation, availablePaymentMethodsInfos]
  );

  const closeErrorModal = useCallback(() => {
    onCloseErrorCancellingModal();
    setShowErrCancellingModal(false);
  }, []);

  const onRenderInlineMessage = useCallback(
    () =>
      showCancelSuccessfulMessage &&
      !isCancelModalOpen &&
      !isMobile && (
        <AlertInline
          qaLabel="cancel-success-message"
          message={successMessage}
          variant="success"
          hasDismissButton
          hasDismissAnimation
        />
      ),
    [showCancelSuccessfulMessage, isMobile, successMessage, isCancelModalOpen]
  );

  const onRenderEmptyMessage = useCallback(
    () => (
      <>
        {onRenderInlineMessage()}
        <EmptyContainer data-qa-label="empty-container">
          <EmptyDialogContainer>
            <DialogMessage
              qaLabel="empty-message"
              variant="info"
              title={get(
                emptyMessageContent,
                "title",
                "No withdrawals available"
              )}
              description={get(
                emptyMessageContent,
                "text",
                "You don't currently have any withdrawals available."
              )}
            />
          </EmptyDialogContainer>
          <div>
            <Button
              variant="primary"
              onPress={closeModalCallback}
              qaLabel="empty-message-button"
              isStretched
              mt="space-6"
            >
              {get(emptyMessageContent, "button", "Back to more")}
            </Button>
          </div>
        </EmptyContainer>
      </>
    ),
    [emptyMessageContent]
  );

  const onRenderContent = useCallback(
    () => (
      <>
        <Container
          isMobile={isMobile}
          data-qa-label="pending-withdraws-container"
        >
          <Wrapper>
            {onRenderInlineMessage()}
            {isCancelModalOpen && !isMobile ? (
              renderCancelWithdrawal(
                mappedCancelInformation,
                cancelModalContent,
                cancelInformation
              )
            ) : (
              <>
                <Header
                  hideTopBorder={!isMobile && showCancelSuccessfulMessage}
                />
                {renderWithdrawals}
                {withdrawals && !isMobile && (
                  <Footer closeModalCallback={closeModalCallback} />
                )}
              </>
            )}
          </Wrapper>
        </Container>

        {isMobile && (
          <ModalV2
            qaLabel="cancel-modal"
            isOpen={isCancelModalOpen}
            title={get(cancelModalContent, "title", "Cancel")}
            onClose={cancelInformation.onCancelClose}
            {...sharedModalProps}
          >
            {() => (
              <ModalBody hasPadding={false}>
                {renderCancelWithdrawal(
                  mappedCancelInformation,
                  cancelModalContent,
                  cancelInformation
                )}
              </ModalBody>
            )}
          </ModalV2>
        )}

        <ModalV2
          qaLabel="error-cancelling-modal"
          isOpen={showErrCancellingModal}
          hasHeader={false}
          onClose={closeErrorModal}
          {...sharedModalProps}
        >
          {() => (
            <ModalBody hasPadding>
              <IconButtonContainer>
                <IconButton
                  qaLabel="icon-button"
                  variant="tertiary"
                  tertiaryColor="grey.900"
                  iconName="close"
                  size="s"
                  onPress={closeErrorModal}
                />
              </IconButtonContainer>
              <DialogMessage
                variant="error"
                title={get(
                  errorModalContent[cancelInformation.errorCode],
                  "title",
                  errorModalContent.title
                )}
                description={get(
                  errorModalContent[cancelInformation.errorCode],
                  "text",
                  errorModalContent.text
                )}
              />
              <CustomerServiceLink>
                <Button
                  variant="primary"
                  onPress={closeErrorModal}
                  qaLabel="customer-service-button"
                  isStretched
                  mt="space-4"
                >
                  {get(
                    errorModalContent[cancelInformation.errorCode],
                    "button",
                    errorModalContent.button
                  )}
                </Button>
              </CustomerServiceLink>
            </ModalBody>
          )}
        </ModalV2>
      </>
    ),
    [
      isMobile,
      cancelInformation,
      errorModalContent,
      sharedModalProps,
      showErrCancellingModal,
      mappedCancelInformation,
      isCancelModalOpen,
      cancelModalContent,
      showCancelSuccessfulMessage
    ]
  );

  if (isPendingLoading || isEmpty(availablePaymentMethodsInfos)) {
    return (
      <Container
        isMobile={isMobile}
        data-qa-label="pending-withdraws-container"
      >
        <Wrapper>
          <LoadingContainer data-qa-label="loading-container">
            <Loading
              qaLabel="loading-spinner"
              color="blue.900"
              bgColor="blue_accent.400"
              size="xl"
            />
          </LoadingContainer>
        </Wrapper>
      </Container>
    );
  }

  return (
    <>
      {!withdrawals.length ? onRenderEmptyMessage() : onRenderContent()}
      {isMobile && (
        <ToastManager
          qaLabel="cancel-success-message"
          variant="success"
          message="We've successfully canceled your withdrawal."
          ref={(ref) => ToastManager.setRef(ref)}
        />
      )}
    </>
  );
};

export default PendingWithdraws;
