export const mathPercentage = (total: number, done: number) =>
  total > 0 ? (100 * done) / total : 0;

export const mathClamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

export const mathPercentageFormatted = (
  total: number,
  done: number,
  fractionDigits: number = 0
) => {
  const percentage = mathClamp(mathPercentage(total, done), 0, 100);
  const percentageFixed =
    fractionDigits > 0
      ? percentage.toFixed(fractionDigits)
      : Math.floor(percentage);
  return `${percentageFixed}%`;
};
