import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontMedium, fontNormal } from "@tvg/atomic-ui/_static/Typography";

export const Container = styled.div`
  width: 100%;
`;

export const Label = styled.div<{
  showAboveInput?: boolean;
}>`
  margin-right: 12px;
  line-height: 22px;
  font-family: ${fontMedium};
  font-weight: 500;
  font-size: 14px;
  color: ${buildColor("grey", "900")};
  ${({ showAboveInput }) =>
    showAboveInput &&
    css`
      position: absolute;
      top: -27px;
      left: 0px;
    `}
`;

export const Input = styled.input`
  flex: 1;
  height: 22px;
  font-family: ${fontNormal};
  font-weight: 400;
  font-size: 14px;
  color: ${buildColor("grey", "900")};
  background-color: transparent;
  outline: none;
`;

export const Field = styled.label<{
  isDisabled?: boolean;
  hasFocus: boolean;
  type: string;
}>`
  display: flex;
  align-items: center;
  min-height: 44px;
  padding: 8px 12px;
  background-color: ${buildColor("white", "100")};
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 1px 2px ${buildColor("blue", "000")};
  border-radius: 4px;
  position: relative;
  width: 100%;
  ${({ hasFocus }) =>
    hasFocus &&
    css`
      border: 2px solid ${buildColor("blue_accent", "500")};
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${buildColor("blue", "000")};
      border: 1px solid ${buildColor("blue", "100")};
      pointer-events: none;

      ${Input} {
        color: ${buildColor("grey", "800")};
      }
    `}

  ${({ type }) =>
    type === "big" &&
    css`
      padding: 12px;

      ${Input} {
        width: 100%;
        height: 30px;
        text-align: center;
        font-size: 28px;

        & ::placeholder {
          color: ${buildColor("grey", "700")};
        }

        @media (max-width: 370px) and (min-width: 350px) {
          font-size: 26px;
        }

        @media (max-width: 349px) and (min-width: 330px) {
          font-size: 24px;
        }

        @media (max-width: 329px) and (min-width: 310px) {
          font-size: 22px;
        }

        @media (max-width: 309px) and (min-width: 290px) {
          font-size: 20px;
        }

        @media (max-width: 289px) {
          font-size: 18px;
        }
      }
    `}

  .cvv-icon {
    margin-left: 12px;
  }
`;

export const Error = styled.p`
  display: flex;
  align-items: flex-start;
  font-family: ${fontNormal};
  font-weight: 400;
  font-size: 14px;
  color: ${buildColor("orange", "600")};
  margin-top: 8px;

  svg {
    margin-right: 6px;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 14px;

  svg {
    display: block;
  }
`;
