import MobileDetect from "mobile-detect";

import type { Device } from "./types";
import { PRODUCTS_MOBILE } from "./product";

export default (userAgent?: string, product?: string): Device => {
  const uaInfo = new MobileDetect(userAgent || "");
  let device = "desktop";
  device = (uaInfo.mobile() && "mobile") || device;
  device = (uaInfo.tablet() && "tablet") || device;

  // tablet exception
  if (device === "desktop") {
    return PRODUCTS_MOBILE.includes(product || "") ? "tablet" : device;
  }

  return device as Device;
};
