import { engageApptentiveEvent } from "../utils";

// when a user receives referral bonus inbox/email
export const refferalBonusDeposited = () =>
  engageApptentiveEvent("Refferal bonus is deposited to user account");

// when a user selects any video
export const selectEducationVideo = () =>
  engageApptentiveEvent("Education videos is tapped");

// when a user selects 'Watch Live'
export const selectWatchLiveButton = () =>
  engageApptentiveEvent("Watch Live is tapped");
