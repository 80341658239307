import tvgConf from "@tvg/conf";
import { replace, toPairs, escapeRegExp } from "lodash";
import requester from "../requester";
import getProtocol from "../protocolSetter";
import type { PaymentTypesAvailable, Operations } from "./types";

type Variables = {
  [key: string]: string | number;
};

const replaceVariables = (message: string, variables: Variables) => {
  const TOKEN_DELIMITER = "(?:~)";
  let modifiedMessage = message;
  toPairs(variables).forEach(([key, value]) => {
    modifiedMessage = replace(
      modifiedMessage,
      new RegExp(TOKEN_DELIMITER + escapeRegExp(key) + TOKEN_DELIMITER, "g"),
      value || value === 0 ? `${value}` : ""
    );
  });

  return modifiedMessage;
};

const achInfo = {
  ach: {
    deposit: {
      url: `/users/~accountId~/bankAccount/~paymentMethodId~/deposits`,
      method: "post",
      service: "service.ach"
    },
    depositLimits: {
      url: `/users/~accountId~/deposits/limits`,
      method: "get",
      service: "service.ach"
    },
    depositFee: {
      url: `/users/~accountId~/deposits/fee?depositAmount=~depositAmount~`,
      method: "get",
      service: "service.ach"
    },
    getBank: {
      url: `/bank/~bankRoutingNumber~`,
      method: "get",
      service: "service.ach"
    },
    withdrawLimits: {
      url: "/users/~accountId~/withdrawal/balance",
      method: "get",
      service: "service.uwt"
    },
    withdraw: {
      url: "/users/~accountId~/withdrawals",
      method: "post",
      service: "service.ach"
    }
  }
};

const ccInfo = {
  ccp: {
    deposit: {
      url: `/users/~accountId~/bankCard/~paymentMethodId~/deposit`,
      method: "post",
      service: "service.ccp"
    },
    withdraw: {
      url: "/users/~accountId~/bankCard/~paymentMethodId~/withdrawal",
      method: "post",
      service: "service.ccp"
    },
    depositFee: {
      url: `/users/~accountId~/deposits/fee?depositAmount=~depositAmount~`,
      method: "get",
      service: "service.ccp"
    },
    createWithDeposit: {
      url: `/users/~accountId~/deposit`,
      method: "post",
      service: "service.ccp"
    },
    disable: {
      url: "/users/~accountId~/bankCards/~cardId~",
      method: "delete",
      service: "service.ccp"
    }
  }
};

const payInfo = {
  pay: {
    withdraw: {
      url: "/users/~accountId~/withdrawals",
      method: "post",
      service: "service.pay"
    },
    depositFee: {
      url: `/users/~accountId~/deposits/fee?amount=~depositAmount~`,
      method: "get",
      service: "service.pay"
    }
  }
};

const moneyPakInfo = {
  mpak: {
    deposit: {
      url: `/users/~accountId~/deposit`,
      method: "post",
      service: "service.mpak"
    }
  }
};

const chkInfo = {
  chk: {
    withdraw: {
      url: "/users/~accountId~/withdrawal",
      method: "post",
      service: "service.chk"
    }
  }
};

const payNearMeInfo = {
  pnm: {
    getId: {
      url: `/users/~accountId~/id`,
      method: "get",
      service: "service.pnm"
    },
    createId: {
      url: `/users/~accountId~/id`,
      method: "post",
      service: "service.pnm"
    }
  }
};

const mazoomaInfo = {
  mzm: {
    createWithDeposit: {
      url: `/users/~accountId~/deposits`,
      method: "post",
      service: "service.mzm",
      data: ["amount", "returnUrl"]
    },
    deposit: {
      url: `/users/~accountId~/bank-account/~accountOnFile~/deposits`,
      method: "post",
      service: "service.mzm",
      data: ["amount", "returnUrl"]
    },
    withdraw: {
      url: "/users/~accountId~/bank-account/~paymentMethodId~/withdrawals",
      method: "post",
      service: "service.mzm",
      data: ["amount"]
    },
    disable: {
      url: "/users/~accountId~/bank-account/~accountOnFileId~/status",
      method: "put",
      service: "service.mzm",
      data: ["enabled"]
    }
  }
};

const paymentsInformation = {
  ...achInfo,
  ...ccInfo,
  ...payInfo,
  ...moneyPakInfo,
  ...chkInfo,
  ...payNearMeInfo,
  ...mazoomaInfo
};

export const constructRequest = (
  paymentType: PaymentTypesAvailable,
  operation: Operations,
  depositData: Object
) => {
  const paymentInformation =
    // @ts-ignore - really hard to make the matrix work for this
    paymentsInformation[paymentType.toLowerCase()][operation];
  const httpMethod: string = paymentInformation.method;

  const body = paymentInformation.data
    ? Object.fromEntries(
        Object.entries(depositData).filter(([key]) =>
          paymentInformation.data.includes(key)
        )
      )
    : depositData;

  return requester()({
    url: `${getProtocol()}${tvgConf().config(
      paymentInformation.service
    )}${replaceVariables(paymentInformation.url, depositData as Variables)}`,
    method: httpMethod,
    withCredentials: true,
    headers: {
      "x-tvg-context": tvgConf().context()
    },
    ...(httpMethod === "post" || httpMethod === "put" ? { data: body } : {})
  });
};

export default constructRequest;
