import React from "react";
import { useTransition, config, animated } from "@react-spring/native";
import { useQaLabel } from "../../../../../hooks/useQaLabel";
import { useEvents } from "../../../../../hooks/useEvents";
import {
  Container,
  ContentContainer,
  Dropdown,
  ListItem
} from "./styled-components";
import { AnimatedDropdownProps } from "./types";
import { DropdownSelection } from "../dropdownSelection";

const AnimatedContainer = animated(ContentContainer);

export const AnimatedDropdownNative: React.FC<AnimatedDropdownProps> = ({
  isDropdownVisible,
  onSelectValue,
  values,
  selectedValue,
  qaLabel,
  isTop,
  top,
  left,
  width
}) => {
  const viewTestProps = useQaLabel(qaLabel);

  const { events, eventsHandlers, hoverHandlers } = useEvents({});

  const dropdownTransition = useTransition(isDropdownVisible, {
    config: isDropdownVisible ? { ...config.stiff } : { duration: 150 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return (
    <>
      {dropdownTransition(
        (styles, isOpen) =>
          isOpen &&
          values.length > 0 && (
            // @ts-ignore
            <Container
              {...viewTestProps}
              {...eventsHandlers}
              {...events}
              {...hoverHandlers}
              isTop={isTop}
              top={top}
              left={left}
              width={width}
            >
              <AnimatedContainer style={styles}>
                <Dropdown>
                  {values.map((item, index) => (
                    <ListItem key={index.toString()}>
                      <DropdownSelection
                        item={item}
                        onSelectValue={onSelectValue}
                        selectedValue={selectedValue}
                        isParentHovered={events.isHovered}
                        qaLabel={qaLabel}
                      />
                    </ListItem>
                  ))}
                </Dropdown>
              </AnimatedContainer>
            </Container>
          )
      )}
    </>
  );
};
