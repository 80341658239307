import React, { useEffect, useState, useMemo } from "react";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import { get, isEqual } from "lodash";
import { useSelector } from "react-redux";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import {
  getAddressConfirmationMessage,
  getUserAddress
} from "@tvg/sh-lib-paws/redux/selectors";
import {
  Address,
  AddressConfirmationMessage,
  AddressSelection
} from "../../types";
import MessagePanel from "../MessagePanel";
import { filterModalCommonActions, getModalCommonActions } from "../../utils";
import { AddressSelector } from "../AddressSelector";
import {
  gtmDepositAddressOpenModal,
  gtmWithdrawAddressOpenModal
} from "../../gtm";
import { Props, MessageType } from "./types";
import { MAILING, RESIDENTIAL } from "../../enums";
import { onAddressSelectorClick, onCancel, onConfirm } from "./eventHandlers";

export const AddressConfirmationModal = ({
  onSubmit,
  isOpen,
  onCloseMethod,
  paymentType,
  accountID,
  messageType = MessageType.WITHDRAW,
  onCancelCb
}: Props) => {
  const [addressSelected, setAddressSelected] =
    useState<AddressSelection>(RESIDENTIAL);

  const addressConfirmationMessage: {
    [key in PaymentTypeEnum | "default"]: AddressConfirmationMessage;
  } = useSelector(getAddressConfirmationMessage);

  const {
    title,
    description,
    type,
    actions,
    customerService
  }: AddressConfirmationMessage = get(
    addressConfirmationMessage,
    `${paymentType}`,
    addressConfirmationMessage.default
  );

  const {
    homeAddress,
    mailingAddress
  }: { homeAddress: Address; mailingAddress: Address } =
    useSelector(getUserAddress);

  let mergedArrays: Address[] = [];
  if (messageType === MessageType.BILLING) {
    mergedArrays = mailingAddress
      ? [{ ...mailingAddress, type: MAILING }]
      : [{ ...homeAddress, type: RESIDENTIAL }];
  } else {
    mergedArrays = isEqual(homeAddress, mailingAddress)
      ? [{ ...homeAddress, type: RESIDENTIAL }]
      : [
          { ...homeAddress, type: RESIDENTIAL },
          { ...mailingAddress, type: MAILING }
        ];
  }

  const allActions = useMemo(
    () =>
      getModalCommonActions(
        onConfirm(
          paymentType,
          accountID,
          onSubmit,
          addressSelected,
          messageType
        ),
        () => ({}),
        onCancel(paymentType, accountID, onCancelCb, messageType),
        () => ({}),
        () => ({}),
        0,
        messageType === MessageType.BILLING ? "Use another card" : undefined
      ),
    [paymentType, accountID, addressSelected]
  );

  useEffect(() => {
    if (!isOpen) {
      setAddressSelected(RESIDENTIAL);
    } else {
      if (messageType === MessageType.WITHDRAW) {
        gtmWithdrawAddressOpenModal({ paymentType, accountID });
      }
      gtmDepositAddressOpenModal({ paymentType, accountID });
    }
  }, [isOpen]);

  return (
    <ModalV2
      isOpen={isOpen}
      onClose={onCloseMethod}
      qaLabel="AddressConfirmationModal"
      animation="fade"
      hasRoundedCorners
      isContentTransparent
      isFullHeight={false}
      hasHeader={false}
      isFullWidth={false}
      fixedWidth="351px"
    >
      {() => (
        <MessagePanel
          variant={type}
          title={title}
          bodyChild={
            <AddressSelector
              messageType={messageType}
              customerService={customerService}
              addresses={mergedArrays}
              active={addressSelected}
              onClick={onAddressSelectorClick(
                paymentType,
                accountID,
                setAddressSelected,
                messageType
              )}
              paymentType={paymentType}
              accountID={accountID}
            />
          }
          description={description}
          actions={filterModalCommonActions(allActions, actions)}
        />
      )}
    </ModalV2>
  );
};
