import axios from "axios";
import { attempt } from "./utils";
import type {
  ServiceConfig,
  FeatureToggles,
  Product,
  Device,
  Brand
} from "./types";

const parseFeatures = (featuresData: string): FeatureToggles | false => {
  let features;
  const data = attempt(() => JSON.parse(decodeURIComponent(featuresData)));
  if (!(data instanceof Error) && typeof data === "object") {
    features = data as FeatureToggles;
  }

  return features || false;
};

const readFeatures = (): FeatureToggles | false => {
  let featuresObject;
  /* eslint-disable */
  if (
    typeof window !== "undefined" &&
    window.__TVG_GLOBALS__ &&
    window.__TVG_GLOBALS__.FEATURE_TOGGLES
  ) {
    featuresObject = parseFeatures(
      window.__TVG_GLOBALS__.FEATURE_TOGGLES
    ) as FeatureToggles;
  }
  /* eslint-enable */
  return featuresObject || false;
};

const getFeatures = (
  service: ServiceConfig,
  product: Product,
  device: Device,
  brand: Brand,
  serverSide: boolean
): Promise<FeatureToggles | false> => {
  const features = readFeatures();
  if (features || (typeof window === "undefined" && !serverSide)) {
    return Promise.resolve().then(() => features);
  }
  const requestOptions = {
    url: `${service.capi}/featureToggles/${product}/${device}/${brand}`,
    method: "GET",
    withCredentials: true
  };
  return axios(requestOptions).then((result) =>
    result.data
      ? result.data
      : Promise.reject(new Error("No feature toggles returned"))
  );
};

export default { readFeatures, getFeatures };
