import { Race, RaceStatusEnum } from "@tvg/ts-types/Race";
import React from "react";
import { Container } from "./styled-components";
import { Mtp } from "../../../Mtp";
import { renderIcon } from "./renderer";

const RaceDetail = ({
  mtp,
  postTime,
  video,
  status
}: Pick<Race, "mtp" | "postTime" | "status" | "video">) => (
  <Container>
    <Mtp
      qaLabel="mtp"
      isCollapsed
      mtp={mtp}
      postTime={postTime}
      status={status.code}
      fontWeight="medium"
      hasFullText={
        ![RaceStatusEnum.OPEN, RaceStatusEnum.UP_NEXT].includes(status.code)
      }
      whiteSpace="nowrap"
    />
    {renderIcon(video, status)}
  </Container>
);

export default RaceDetail;
