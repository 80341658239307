import React, { FC } from "react";

export const Germany: FC = () => (
  <>
    <path d="M32 0H0V6.62337H32V0Z" fill="#0A0A0A" />
    <path d="M32 6.62338H0V13.3117H32V6.62338Z" fill="#D22839" />
    <path d="M32 13.3117H0V20H32V13.3117Z" fill="#EAC300" />
  </>
);

export default Germany;
