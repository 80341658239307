import { AlertInlineIconVariants } from "./types";

export const alertInlineIconVariants: AlertInlineIconVariants = {
  success: {
    backgroundColor: "green.100",
    lineColor: "green.700",
    name: "success"
  },
  informational: {
    backgroundColor: "blue_accent.200",
    lineColor: "blue_accent.600",
    name: "info"
  },
  error: {
    backgroundColor: "red.100",
    lineColor: "red.600",
    name: "exclamation"
  },
  warning: {
    backgroundColor: "orange.200",
    lineColor: "orange.700",
    name: "warning"
  },
  promo: {
    backgroundColor: "yellow.200",
    lineColor: "yellow.800",
    name: "promo"
  }
};

export const alertToastIconVariants: AlertInlineIconVariants = {
  success: {
    lineColor: "white.900",
    name: "success"
  },
  informational: {
    lineColor: "white.900",
    name: "info"
  },
  error: {
    lineColor: "white.900",
    name: "exclamation"
  },
  warning: {
    lineColor: "white.900",
    name: "warning"
  },
  promo: {
    lineColor: "grey.900",
    name: "promo"
  }
};
