import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentTypeOption, PaymentType } from "@tvg/wallet/src/types";

export type State = {
  accountsOnFile: PaymentTypeOption[];
  isFirstDepositDone: boolean;
  paymentTypesUnderMaintenance: PaymentType[];
};

export const initialState: State = {
  accountsOnFile: [],
  isFirstDepositDone: false,
  paymentTypesUnderMaintenance: []
};

const walletSlice = createSlice({
  name: "paws",
  initialState,
  reducers: {
    setAccountsOnFileMethods(
      state,
      action: PayloadAction<PaymentTypeOption[]>
    ) {
      state.accountsOnFile = action.payload;
    },
    setIsFirstDepositDone(state, action: PayloadAction<boolean>) {
      state.isFirstDepositDone = action.payload;
    },
    setPaymentMethodsUnderMaintenance(
      state,
      action: PayloadAction<PaymentType[]>
    ) {
      state.paymentTypesUnderMaintenance = action.payload;
    }
  }
});

export const {
  setAccountsOnFileMethods,
  setIsFirstDepositDone,
  setPaymentMethodsUnderMaintenance
} = walletSlice.actions;

export default walletSlice.reducer;
