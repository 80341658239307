import {
  PromoMappedSteps,
  PromoStepType
} from "@tvg/sh-lib-promos-onboarding/types/promoSteps";

export const getPromoStepsLabel = (
  promoSteps: PromoMappedSteps
): Array<string> =>
  (Object.keys(promoSteps) as Array<PromoStepType>).reduce((steps, step) => {
    let label = "";
    switch (step) {
      case PromoStepType.DEPOSIT:
        label = "Deposit";
        break;
      case PromoStepType.WAGER:
        label = "Wager";
        break;
      default:
        return steps;
    }

    return [...steps, label];
  }, [] as Array<string>);
