import React from "react";
import { Platform } from "react-native";
import { Path } from "react-native-svg";

import { IconPathProps } from "../types";

const ChevronRight = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M7.5 4.5L15 12L7.5 19.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
    {...(Platform.OS === "web" ? { focusable: false } : {})}
  />
);

export default ChevronRight;
