import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Replay = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8755 12.0042C22.8755 18.0105 18.0064 22.8797 12 22.8797C5.99366
      22.8797 1.12454 18.0105 1.12454 12.0042C1.12454 5.99782 5.99366 1.12871
      12 1.12871C18.0064 1.12871 22.8755 5.99782 22.8755 12.0042ZM16.5 12L9
      16.5V7.5L16.5 12Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M1.12033 12C1.12033 18.0087 5.99132 22.8797 12 22.8797C18.0087 22.8797
      22.8797 18.0087 22.8797 12C22.8797 5.99132 18.0087 1.12033 12
      1.12033C9.00162 1.12033 6.28654 2.33325 4.31864 4.29519L1.16629
      7.44754M1.11914 2.61914V7.5H6M9.00064 7.5V16.5019L16.4992 12L9.00064 7.5Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
