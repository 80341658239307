import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontNormal, fontMedium } from "@tvg/atomic-ui/_static/Typography";
import Button from "@tvg/atomic-ui/_atom/Buttons/button";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  background-color: ${buildColor("white", "100")};
`;

export const Description = styled.p`
  margin-bottom: 12px;
  line-height: 24px;
  font-family: ${fontNormal};
  color: ${buildColor("grey", "800")};
`;

export const Limits = styled.ul`
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid ${buildColor("blue", "100")};
  overflow: hidden;
`;

export const LimitValue = styled.p`
  flex: 1;
  padding: 10px;
  text-align: center;
  color: ${buildColor("grey", "800")};
  font-size: 12px;
  border-right: 1px solid ${buildColor("blue", "100")};

  &:last-child {
    border: 0;
  }
`;

export const Limit = styled.li`
  display: flex;
  border-bottom: 1px solid ${buildColor("blue", "100")};

  &:first-child {
    background-color: ${buildColor("blue_accent", "000")};

    ${LimitValue} {
      color: ${buildColor("grey", "900")};
      font-family: ${fontMedium};
      font-size: 14px;
    }
  }

  &:last-child {
    border: 0;
  }
`;

export const ButtonStyled = styled(Button)<{ type: string }>`
  height: auto;
  margin-bottom: 8px;
  padding: 14px;
  line-height: 18px;
  font-size: 14px;
  border-radius: 4px;
`;
