import {
  Platform,
  AccessibilityRole as NativeAccessibilityRole
} from "react-native";

export type WebOnlyAccessibilityRole =
  | "paragraph"
  | "span"
  | "list"
  | "listitem"
  | "dialog";

export type AccessibilityRole =
  | NativeAccessibilityRole
  | WebOnlyAccessibilityRole;

const accessibilityRoleToNativeRole: {
  [key in WebOnlyAccessibilityRole]: NativeAccessibilityRole;
} = {
  paragraph: "text",
  span: "text",
  list: "menu",
  listitem: "menuitem",
  dialog: "alert"
};

export const getAccessibilityRole = (accessibilityRole: AccessibilityRole) =>
  // @ts-ignore
  (Platform.OS !== "web" && accessibilityRoleToNativeRole[accessibilityRole]) ||
  accessibilityRole;

export default getAccessibilityRole;
