import React, { memo, useState } from "react";
import BetUtils from "@tvg/utils/betSelection";
import { ButtonGroup, Container } from "./styled-components";
import {
  BetButtonsProps,
  BetTypeCodesEnum,
  BetTypeCodesReversedEnum
} from "./types";
import { Button } from "./components";

const getIcon = (
  columnCount: number,
  isActive: boolean,
  isKey: boolean,
  position: number
) => {
  if (isKey) {
    const keyVariantsIcon = position === 0 ? "key" : "plus";
    return isActive ? "tick" : keyVariantsIcon;
  }

  if (columnCount > 1) {
    return undefined;
  }

  return isActive ? "tick" : "plus";
};

export const BetButtons = memo(
  ({ wagerType, onPress, activeButtons }: BetButtonsProps) => {
    if (!wagerType) {
      return null;
    }
    const [isHoveringAll, setIsHoveringAll] = useState(false);
    const { isKey = false, specialGroup = false } = wagerType || {};
    const buttonNames = BetUtils.getWagerTypesNames(wagerType);
    const columnCount = wagerType?.columnCount || 1;
    const buttonLabel = columnCount > 1 && !specialGroup ? "exotic" : "simple";

    return (
      <Container>
        <ButtonGroup
          hasMaxWidth={columnCount !== 1}
          isSimpleBetButtons={specialGroup}
        >
          {Array(columnCount)
            .fill(null)
            .map((_, i) => (
              <Button
                key={i.toString()}
                text={buttonNames[i]}
                icon={getIcon(columnCount, activeButtons[i], isKey, i)}
                onPress={() => onPress(buttonNames[i], activeButtons[i], i)}
                qaLabel={`bet-button-${buttonLabel}-${buttonNames[
                  i
                ]?.toLowerCase()}`}
                isSelected={activeButtons[i]}
                isFullWidth={columnCount !== 1}
                isFirstChild={i === 0}
                isLastChild={i === columnCount - 1}
                isHoveringAll={isHoveringAll}
              />
            ))}
        </ButtonGroup>
        {columnCount > 1 && specialGroup && (
          <Button
            ml="space-4"
            text="Across the board"
            onPress={() =>
              onPress(
                "Across the board",
                activeButtons.every((isActive) => isActive)
              )
            }
            qaLabel="bet-button-across-the-board"
            isSelected={activeButtons.every((isActive) => isActive)}
            isLastChild
            isFirstChild
            hasShadow
            onMouseEnter={() => setIsHoveringAll(true)}
            onMouseLeave={() => setIsHoveringAll(false)}
          />
        )}
      </Container>
    );
  }
);

export { BetButtonsProps, BetTypeCodesEnum, BetTypeCodesReversedEnum };
