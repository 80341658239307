import React, { useMemo } from "react";
import { ButtonAction } from "@tvg/wallet/src/types";
import { useSelector } from "react-redux";
import { getPawsContent } from "@tvg/sh-lib-paws/redux/selectors";
import InfoMessage from "../info";
import { NoPaymentMethodProps } from "../../common/types";

export interface NoPaymentMethodsProps {
  onClose: () => void;
  onContact: () => void;
}

const NoPaymentMethods = ({ onClose, onContact }: NoPaymentMethodsProps) => {
  const { noPaymentMethodModal }: NoPaymentMethodProps =
    useSelector(getPawsContent);

  const { title, description, contactSupportText, okText } =
    noPaymentMethodModal;

  const actions: ButtonAction[] = useMemo(
    () => [
      {
        text: okText,
        onClick: () => {
          onClose();
        },
        isStreched: true,
        isUppercase: false,
        qaLabel: "ok-btn"
      },
      {
        text: contactSupportText,
        onClick: onContact,
        isStreched: true,
        isUppercase: false,
        qaLabel: "contact-btn",
        type: "tertiary"
      }
    ],
    []
  );

  return (
    <InfoMessage
      actions={actions}
      description={description}
      title={title}
      onClose={onClose}
    />
  );
};

export default React.memo(NoPaymentMethods);
