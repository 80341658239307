import styled, { css } from "styled-components";
import { fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const KeyboardContainer = styled.div`
  display: flex;
  background-color: ${buildColor("white", "100")};
`;

const sharedButtonStyles = css`
  border: none;
  background-color: transparent;
  outline: none;
  font-family: ${fontNormal};
  font-size: 20px;
  line-height: 23px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    background-color: ${buildColor("blue", "100")};
  }
`;

export const NumericButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const NumericButton = styled.button`
  ${sharedButtonStyles};
  flex: 1 0 33.33%;
  color: ${buildColor("grey", "900")};

  &:disabled {
    color: ${buildColor("grey", "700")};
  }
`;

export const MoneyPillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95px;
  border-left: 1px solid ${buildColor("blue", "100")};
`;

export const MoneyPillButton = styled.button`
  ${sharedButtonStyles};
  color: ${buildColor("blue_accent", "500")};

  span {
    min-width: 45px;
    text-align: end;
  }
`;
