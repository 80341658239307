import React, { forwardRef, useContext } from "react";
import { View } from "react-native";
import { TvgConfContext } from "../../../../utils/tvgConfProvider";
import { ProgressBarAchievementStepProps } from "../../types";
import { useQaLabel } from "../../../../hooks";
import { PBContext } from "../../context";
import {
  StyledAchievementStepContainer,
  StyledAchievementStepText
} from "./styles";

export const AchievementStep = forwardRef<
  View,
  ProgressBarAchievementStepProps
>(
  (
    {
      qaLabel = "progress-bar-achievement-step",
      icon,
      totalSteps = 0,
      isDarkMode = false,
      ...rest
    },
    ref
  ) => {
    const viewTestProps = useQaLabel(qaLabel);
    const { completedSteps } = useContext(PBContext);
    const { device } = useContext(TvgConfContext);
    const isAchievementStep = completedSteps > totalSteps;
    return (
      <StyledAchievementStepContainer
        isCompleted={isAchievementStep}
        variant={isDarkMode ? "dark" : "light"}
        isMobile={device === "mobile"}
        // @ts-ignore
        ref={ref}
        {...rest}
        {...viewTestProps}
      >
        {typeof icon === "string" ? (
          <StyledAchievementStepText
            variant={isDarkMode ? "dark" : "light"}
            isCompleted={isAchievementStep}
            isMobile={device === "mobile"}
          >
            {icon}
          </StyledAchievementStepText>
        ) : (
          icon
        )}
      </StyledAchievementStepContainer>
    );
  }
);

export default AchievementStep;
