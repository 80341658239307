import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Location = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <path
    d="M21.375 2.625L2.625 8.875L10.9583 13.0417L15.125 21.375L21.375 2.625Z"
    fill={backgroundColor}
    fillOpacity="1"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
