// @flow
import { noop } from "lodash";
import type {
  Withdrawal,
  WithdrawCancelInformation
} from "@tvg/atomic-ui/_templates/Withdrawals";

import { type Actions } from "./actions";

export type State = {
  withdrawals: Withdrawal[],
  isCancelModalOpen: boolean,
  showCancelSuccessfulMessage: boolean,
  cancelInformation: WithdrawCancelInformation,
  isPendingLoading: boolean
};

export const initialState = {
  withdrawals: [],
  isCancelModalOpen: false,
  showCancelSuccessfulMessage: false,
  isPendingLoading: false,
  cancelInformation: {
    id: "",
    date: "",
    type: "",
    amount: 0,
    isLoading: false,
    onCancelClose: noop,
    onCancelWithdraw: noop,
    hasError: false,
    errorMessage: "",
    errorCode: ""
  }
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "WITHDRAWALS_SET_WITHDRAWS": {
      return { ...state, withdrawals: action.payload.withdrawals };
    }
    case "WITHDRAWALS_OPEN_CANCEL": {
      return {
        ...state,
        isCancelModalOpen: true,
        cancelInformation: action.payload.cancelInformation
      };
    }
    case "WITHDRAWALS_CLOSE_CANCEL": {
      return {
        ...state,
        isCancelModalOpen: false,
        showCancelSuccessfulMessage: action.payload.successfulCancel
      };
    }
    case "WITHDRAWALS_RESET_MESSAGES": {
      return { ...state, showCancelSuccessfulMessage: false };
    }
    case "WITHDRAWALS_CANCEL_ERROR": {
      return {
        ...state,
        cancelInformation: {
          ...state.cancelInformation,
          hasError: true,
          errorMessage: action.payload.message,
          errorCode: action.payload.code
        }
      };
    }
    case "WITHDRAWALS_CLEAR_ERROR": {
      return {
        ...state,
        cancelInformation: {
          ...state.cancelInformation,
          hasError: false
        }
      };
    }
    case "WITHDRAWALS_CANCEL_LOADING": {
      return {
        ...state,
        cancelInformation: {
          ...state.cancelInformation,
          isLoading: action.payload.isLoading
        }
      };
    }
    case "WITHDRAWALS_FETCH_LOADING": {
      return {
        ...state,
        isPendingLoading: action.payload.isPendingLoading
      };
    }
    default: {
      return state;
    }
  }
}
