import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Card = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M22.875 5.625H1.125V10.1693L22.875 10.1693V5.625Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M4.15997 14.2534H13.5867M1.125 10.1693L22.875 10.1693M1.125
      5.625H22.875V18.375H1.125V5.625Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
