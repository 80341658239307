import { Dispatch, SetStateAction } from "react";
import {
  CardDeclined,
  ErrorMessage,
  FundsAmountInputChangingFrom,
  NullaryFn,
  PaymentType
} from "@tvg/wallet/src/types";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { batch } from "react-redux";
import {
  gtmDepositClose,
  gtmDepositNewPaymentMethod,
  gtmAuthorizeDepositSuccess
} from "@tvg/wallet/src/gtm";
import {
  openCardDeclinedModal,
  setErrorCode
} from "@tvg/sh-lib-paws/redux/slices/cardDeclinedModalSlice";
import { Dispatch as ReduxDispatch } from "@reduxjs/toolkit";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { updateBalance } from "@tvg/shared-actions/UserActions";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { setSelectedPaymentMethod } from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import createService from "@tvg/api/paws/create";
import { get } from "lodash";
import { desktopEditCardField } from "./methods";
import {
  ACTION_CARD_EXP,
  ACTION_CARD_NUM,
  ACTION_CARD_TYPE,
  ACTION_IS_CVV_INFO_OPEN,
  ACTION_IS_DEPOSIT_HOLD_FUNDS_TYPE,
  ACTION_IS_FEE_INFO_OPEN,
  ACTION_IS_LOADING,
  ACTION_SHOW_SUCCESS_MESSAGE,
  ActiveFieldState,
  FormDispatch
} from "./types";
import {
  cardNumberOrExpireSpecialCase,
  numCardFormatter,
  numExpFormatter
} from "./schemaValidations";
import { getNextValueFundsAmount, isDesktop } from "../../../utils";

export const handleOnClose =
  (
    onCloseCallback: NullaryFn<void>,
    activeCreatePM: PaymentType,
    accountId: string
  ) =>
  () => {
    onCloseCallback();
    gtmDepositClose(activeCreatePM, true, accountId);
  };

export const getUpdateFieldOnFocus =
  (
    fieldName: string,
    isLoading: boolean,
    setSelectedField: Dispatch<SetStateAction<string>>,
    addVisitedField: UnaryFn<string, void>
  ) =>
  () => {
    if (!isLoading) {
      setSelectedField((selected) => {
        addVisitedField(selected);
        return `${fieldName}-field`;
      });
    }
  };

export const desktopEditCardNumber =
  (cardNum: string, isLoading: boolean, formDispatch: FormDispatch) =>
  (originalText: string) => {
    desktopEditCardField(
      originalText,
      cardNum,
      ACTION_CARD_NUM,
      numCardFormatter,
      isLoading,
      formDispatch
    );
  };

export const desktopEditCardExpiration =
  (cardExp: string, isLoading: boolean, formDispatch: FormDispatch) =>
  (originalText: string) => {
    desktopEditCardField(
      originalText,
      cardExp,
      ACTION_CARD_EXP,
      numExpFormatter,
      isLoading,
      formDispatch
    );
  };

export const onSubmitHandler =
  (
    showAmountWarning: boolean,
    formDispatch: FormDispatch,
    dispatch: ReduxDispatch,
    accountId: string,
    amount: string,
    cardExp: string,
    cardNum: string,
    cardCVV: string,
    cardType: string,
    refetchAvailableMethods: NullaryFn<void>,
    cardDeclinedContent: CardDeclined,
    isCardDeclinedToggleOn: boolean,
    setErrorMessage: Dispatch<SetStateAction<ErrorMessage>>,
    isFirstDeposit: boolean
  ) =>
  async () => {
    if (showAmountWarning) {
      return;
    }

    try {
      formDispatch({
        type: ACTION_IS_LOADING,
        payload: { status: true }
      });

      dispatch(
        setSelectedPaymentMethod({
          id: "",
          paymentType: "CCP",
          title: "",
          description: "",
          subTitle: "",
          depositsAvailable: false,
          withdrawalsAvailable: false,
          minLimit: 99999999,
          maxLimit: 0
        })
      );

      const [expirationMonth, expirationYear] = cardExp.split("/");
      const response = await createService.createWithDeposit("CCP", {
        accountId,
        amount: +amount,
        cardNumber: cardNum.replace(/\s/g, ""),
        cardVerificationNumber: cardCVV,
        cardType,
        expirationMonth,
        expirationYear
      });
      const { data } = response;

      if (data) {
        gtmDepositNewPaymentMethod("CCP" as PaymentType);
        gtmAuthorizeDepositSuccess(isFirstDeposit, "CCP", accountId, amount);

        formDispatch({
          type: ACTION_IS_DEPOSIT_HOLD_FUNDS_TYPE,
          payload: { status: (data.holdDays as number) > 0 }
        });
        if (!isDesktop || isTvg5()) {
          dispatch(updateBalance(data.balance));
        } else {
          mediatorClassic.dispatch("BALANCE_UPDATE", data.balance);
        }
        formDispatch({
          type: ACTION_SHOW_SUCCESS_MESSAGE,
          payload: { status: true }
        });
        refetchAvailableMethods();
      }
    } catch (err) {
      const errorCode = get(err, "response.data.code", "");
      if (
        cardDeclinedContent.validErrorCodes &&
        cardDeclinedContent.validErrorCodes.includes(errorCode) &&
        isCardDeclinedToggleOn
      ) {
        batch(() => {
          dispatch(openCardDeclinedModal());
          dispatch(setErrorCode(errorCode));
        });
      } else {
        setErrorMessage((prevState) => ({
          ...prevState,
          isOpen: true,
          errorCode,
          isRetry: false
        }));
      }
    } finally {
      formDispatch({
        type: ACTION_IS_LOADING,
        payload: { status: false }
      });
    }
  };

export const onChangeValue =
  (isLoading: boolean, activeFieldState: ActiveFieldState) =>
  (keyValue: number | string, from: FundsAmountInputChangingFrom) => {
    if (isLoading) {
      return;
    }
    const { value, setState, validator, formatter } = activeFieldState;

    let nextValue = getNextValueFundsAmount(keyValue, from, value);
    const { length: nextLength } = nextValue;
    const isDeletingOnDesktop = isDesktop && value.length > nextLength;
    if (isDeletingOnDesktop && cardNumberOrExpireSpecialCase.test(value)) {
      nextValue = nextValue.slice(0, nextLength - 1);
    } else {
      if (validator && !validator(nextValue)) {
        return;
      }
      if (formatter) {
        nextValue = formatter(nextValue);
      }
    }

    setState(nextValue);
  };

export const onFeeFlagClick =
  (isLoading: boolean, formDispatch: FormDispatch) => () => {
    if (!isLoading) {
      formDispatch({
        type: ACTION_IS_FEE_INFO_OPEN,
        payload: { status: true }
      });
    }
  };

export const onCardTypeClick = (formDispatch: FormDispatch) => (type: string) =>
  formDispatch({
    type: ACTION_CARD_TYPE,
    payload: { value: type }
  });

export const onIconClick = (formDispatch: FormDispatch) => () =>
  formDispatch({
    type: ACTION_IS_CVV_INFO_OPEN,
    payload: { status: true }
  });

export const onFeeModalClick = (formDispatch: FormDispatch) => () =>
  formDispatch({
    type: ACTION_IS_FEE_INFO_OPEN,
    payload: { status: false }
  });
