import { IconSizes } from "../../types";

export const iconSizes: IconSizes = {
  xs: {
    iconSize: "8",
    strokeWidth: "2.4"
  },
  s: {
    iconSize: "16",
    strokeWidth: "2.3"
  },
  m: {
    iconSize: "20",
    strokeWidth: "2.2"
  },
  l: {
    iconSize: "24",
    strokeWidth: "2"
  },
  xl: {
    iconSize: "32",
    strokeWidth: "1.6"
  }
};
