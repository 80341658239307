import { string } from "yup";

import {
  Validations,
  FieldType,
  FieldValidator,
  FieldFormatter,
  CardFieldValidator
} from "./types";

export const currentDate = new Date();

export const getStringYear = (date: Date, size: number) =>
  String(date.getFullYear()).slice(-size);

export const isValidMonth = (month: string) => /^(0[1-9]|1[0-2])/.test(month);

export const isPastMonth = (month: string, year: string, digits = 2) => {
  const currentYear = parseInt(getStringYear(currentDate, digits), 10);
  const currentMonth = currentDate.getMonth() + 1;
  return (
    !isValidYear(year, digits) ||
    (currentYear === +year && +month < currentMonth)
  );
};

export const isValidYear = (year: string, digits = 2, range = 10) => {
  const currentYear = parseInt(getStringYear(currentDate, digits), 10);
  return (
    /\d{2}$/.test(year) && +year >= currentYear && +year <= currentYear + range
  );
};

export const cardNumberSchema = (validation: Validations) =>
  string()
    .required(validation.required)
    .matches(/^(\d{4}\s{1}){3}\d{4}$/, validation.invalid);

export const cardCVVSchema = (validation: Validations) =>
  string().required(validation.required).matches(/\d{3}/, validation.invalid);

export const cardDateSchema = (validation: Validations) =>
  string()
    .required(validation.required)
    .matches(/(^\d{2}\/\d{2})/, validation.invalidDate);

export const cardNumberOrExpireSpecialCase = /\d{2}\/{1}$|\d{4}\s{1}$/;

const onlyNumbers = /^\d*$/;
const cardValidatorFactory: CardFieldValidator =
  (limit, separator) => (value: string) =>
    value.length < limit &&
    onlyNumbers.test(separator ? value.replace(separator, "") : value);
const amountValidator: FieldValidator = (value: string) =>
  value === "."
    ? true
    : RegExp("^$|^[0-9]{0,7}$|^[0-9]{1,7}[.][0-9]{0,2}$").test(value);

export const getFieldValidators = (
  field: FieldType
): FieldValidator | undefined => {
  let validator: FieldValidator | undefined;
  if (field === "amount") {
    validator = amountValidator;
  } else if (field === "number") {
    validator = cardValidatorFactory(20, /\s/g);
  } else if (field === "expire") {
    validator = cardValidatorFactory(6, /\//);
  } else if (field === "cvv") {
    validator = cardValidatorFactory(4);
  }
  return validator;
};

const applyCardNumberFormatter = (cardNumber: string) =>
  cardNumber.replace(/.{4}(?=.)/g, "$& ").slice(0, 19);

export const getFieldFormatters = (
  field: FieldType
): FieldFormatter | undefined => {
  let formatter;
  if (field === "amount") {
    formatter = (value: string) => {
      const newValue = value.charAt(0) === "." ? "0." : value;
      return newValue.replace(",", ".");
    };
  } else if (field === "number") {
    formatter = (value: string) =>
      applyCardNumberFormatter(value.replace(/\s/g, ""));
  } else if (field === "expire") {
    formatter = (value: string) => {
      let formatted = /^[2-9]/.test(value) ? "0" + value : value;
      if (formatted.length > 1 && !value.includes("/")) {
        formatted = formatted.slice(0, 2) + "/";
      }
      return formatted;
    };
  }
  return formatter;
};

export const numCardFormatter = (incValue: string, prevValue = "") => {
  const newValue = (prevValue + incValue).replace(/\s/g, "");
  if (Number.isNaN(+newValue)) {
    return prevValue;
  }
  return applyCardNumberFormatter(
    Number.isNaN(+newValue) ? prevValue : newValue
  );
};

export const numExpFormatter = (incValue: string, prevValue = "") => {
  const newValue = prevValue + incValue;
  const month = newValue.slice(0, 2);
  const year =
    newValue[2] === "/" ? newValue.slice(3, 5) : newValue.slice(2, 4);
  const len = newValue.length;
  if (Number.isNaN(+month) || Number.isNaN(+year)) {
    return prevValue;
  }
  return len > 1 ? `${month}/${year}` : newValue;
};
