import { get } from "lodash";
import { PromoOnboarding } from "../types/promoOnboarding";
import { StorybookAvailableComponents } from "../types/promoOnboardingComponents";
import { PromoOnboardingStep } from "../types/promoSteps";
import { StoryblokComponentPropsFormatted } from "../types/storyblok";
import { formatStoryblokText } from "./formatStoryblokText";

export const formatComponentWithDynamicVariables = (
  promoOnboardingComponent: StoryblokComponentPropsFormatted,
  promo: PromoOnboarding,
  currentStep: PromoOnboardingStep
): StoryblokComponentPropsFormatted => {
  const formattedProps = Object.entries(
    get(promoOnboardingComponent, "props", {})
  ).reduce((acc: StorybookAvailableComponents, [key, value]) => {
    if (typeof value === "string") {
      return {
        ...acc,
        [key]: formatStoryblokText(value, promo, currentStep)
      };
    }

    return { ...acc };
  }, {});

  return {
    ...promoOnboardingComponent,
    props: formattedProps
  } as StoryblokComponentPropsFormatted;
};
