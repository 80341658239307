import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const PaymentOptionTooltip = styled.ul<{ isVisible: boolean }>`
  display: flex;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: ${({ isVisible }) => (isVisible ? "all" : "none")};
  flex-direction: column;
  align-items: flex-start;
  width: 241px;
  position: absolute;
  left: -24px;
  top: calc(100% + 6px);
  background-color: ${buildColor("white", "100")};
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);

  svg {
    margin-left: 12px;
  }
`;

export const TooltipOption = styled.li`
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 16px;
  color: ${({ color }) => color || buildColor("grey", "900")};
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    background-color: ${buildColor("blue_accent", "000")};
  }

  &:active {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const TooltipText = styled.span`
  margin-left: 8px;
`;

export const TooltipSeparator = styled.div`
  border-bottom: 1px solid ${buildColor("blue", "100")};
  width: 100%;
  margin-left: 12px;
`;
