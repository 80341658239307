import { PromoMappedSteps } from "../types/promoSteps";
import { checkOnboardingPromoAvailable } from "../utils/checkOnboardingPromoAvailable";

export const formatCompletedSteps = (
  currentStep: number,
  numberSteps: number,
  steps: PromoMappedSteps
) => (!checkOnboardingPromoAvailable(steps) ? numberSteps + 1 : currentStep);

export default formatCompletedSteps;
