// @flow

import React, { PureComponent, type Node } from "react";
import { noop } from "lodash";
import { type ButtonSize, type ButtonType } from "./Types";
import { ButtonComp } from "./styled-components";
import { getSize, getType } from "./styles";
import CircularLoader from "../../_static/CircularLoader";

type Props = {
  /**
   * children of the Text component
   */
  children: Node,
  /**
   * Classname to style the component
   */
  className: string,
  /**
   * Callback to be triggered by the button
   */
  onClick: () => mixed,
  /**
   * Button size 'default' | 'small' | 'big'
   */
  size: ButtonSize,
  /**
   * Button type 'primary' | 'secondary' | 'secondary_alt' | 'tertiary' | 'tertiary_alt' | 'bet' | 'marketing'
   */
  type: ButtonType,
  /**
   * Disables the button
   */
  isDisabled: boolean,
  /**
   * Mark the button as selected - not the same as the pseudo active state (this is will persist instead)
   */
  isSelected: boolean,
  /**
   * Button with border radius
   */
  hasRoundedCorners: boolean,
  /**
   * Button with full width
   */
  isStretched: boolean,
  /**
   * Button with text uppercase
   */
  isUppercase: boolean,
  /**
   * Button default text transform
   */
  textTransform: string,
  /**
   * Button with text bold
   */
  isBold: boolean,
  /**
   * Button with shadow
   */
  hasShadow: boolean,
  /**
   * qaLabel for test
   */
  qaLabel: string,
  /**
   * Font family
   */
  fontFamily: string,
  /**
   * Is Loading input
   */
  isLoading: boolean,
  /**
   * Loading border color
   */
  loadingBorderColor?: string,
  /**
   * Loading background color
   */
  loadingBackgroundColor?: string,
  /**
   * Optional data
   */
  optionalData: ?string,
  /**
   * Custom Margin bottom
   */
  customMarginBottom?: number
};

export default class Button extends PureComponent<Props> {
  static defaultProps = {
    children: null,
    className: "",
    onClick: noop,
    size: "default",
    type: "primary",
    isDisabled: false,
    isSelected: false,
    hasRoundedCorners: true,
    isStretched: false,
    isUppercase: true,
    textTransform: "normal",
    isBold: true,
    hasShadow: false,
    qaLabel: "",
    fontFamily: "inherit",
    isLoading: false,
    loadingBorderColor: "",
    loadingBackgroundColor: "",
    optionalData: null,
    customMarginBottom: 0
  };

  render() {
    const sizeSpecs = getSize(this.props.size);
    const typeSpecs = getType(this.props.type);

    const compProps = {
      $sizeSpecs: sizeSpecs,
      $typeSpecs: typeSpecs,
      isSelected: this.props.isSelected,
      $isStretched: this.props.isStretched,
      isUppercase: this.props.isUppercase,
      textTransform: this.props.textTransform,
      isBold: this.props.isBold,
      $hasShadow: this.props.hasShadow,
      $isDisabled: this.props.isDisabled,
      $hasRoundedCorners: this.props.hasRoundedCorners,
      ...(this.props.qaLabel !== ""
        ? {
            qaLabel: this.props.qaLabel
          }
        : {}),
      fontFamily: this.props.fontFamily,
      isLoading: this.props.isLoading,
      optionalData: this.props.optionalData
    };

    return (
      <ButtonComp
        {...compProps}
        className={this.props.className}
        isDisabled={this.props.isDisabled}
        customMarginBottom={this.props.customMarginBottom}
        onClick={this.props.onClick}
      >
        {this.props.isLoading ? (
          <span>
            <CircularLoader
              borderColor={this.props.loadingBorderColor}
              backgroundColor={this.props.loadingBackgroundColor}
            />
          </span>
        ) : (
          <span
            {...(compProps.optionalData
              ? { "data-optional": compProps.optionalData }
              : {})}
          >
            {this.props.children}
          </span>
        )}
      </ButtonComp>
    );
  }
}
