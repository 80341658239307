import React, { memo, FC, useState, useCallback } from "react";
import { useSpring } from "@react-spring/web";
import { Icon } from "../Icon";
import {
  AnimatedWrapper,
  AlertInlineContainer,
  AlertInlineInfo,
  AlertInlineCloseButton,
  Title,
  Message,
  Link
} from "./styled-components";
import { IconNames } from "../Icon/types";
import { AlertInlineProps, AlertInlineVariants } from "./types";
import { alertInlineContentMap } from "./constants";

export const AlertInLine: FC<AlertInlineProps> = memo(
  ({
    variant,
    title,
    message,
    hasDismissButton,
    hasDismissAnimation,
    onDismissPress,
    type = "floating",
    linkLabel,
    onLinkClick,
    qaLabel,
    ...rest
  }) => {
    const [isVisible, setIsVisible] = useState(true);
    const { name, lineColor, backgroundColor } =
      alertInlineContentMap[variant as AlertInlineVariants].icon;
    const [animatedStyle, api] = useSpring(() => ({
      from: {
        opacity: 1
      }
    }));

    const handleDismiss = useCallback((e) => {
      onDismissPress?.(e);

      if (hasDismissAnimation) {
        api.start({
          from: { opacity: 1 },
          to: {
            opacity: 0
          },
          onRest: () => {
            setIsVisible(false);
          }
        });
      } else {
        setIsVisible(false);
      }
    }, []);

    return isVisible ? (
      <AnimatedWrapper style={{ ...animatedStyle }}>
        <AlertInlineContainer
          variant={variant}
          type={type}
          data-qa-label={qaLabel}
          {...rest}
        >
          <Icon
            name={name as IconNames}
            lineColor={lineColor}
            backgroundColor={backgroundColor}
            size="s"
            qaLabel={`alert-inline-icon-${name}`}
          />
          <AlertInlineInfo flexDirection={title ? "column" : "row"}>
            {title && (
              <Title
                data-qa-label="alert-inline-title"
                color={
                  alertInlineContentMap[variant as AlertInlineVariants].text
                }
              >
                {title}
              </Title>
            )}
            <Message
              data-qa-label="alert-inline-message"
              color={alertInlineContentMap[variant as AlertInlineVariants].text}
            >
              {message}
            </Message>
            {linkLabel && (
              <Link
                role="link"
                data-qa-label="alert-inline-link"
                onClick={(e) => {
                  onLinkClick?.(e);
                }}
                color={
                  alertInlineContentMap[variant as AlertInlineVariants].text
                }
              >
                {linkLabel}
              </Link>
            )}
          </AlertInlineInfo>
          {hasDismissButton && (
            <AlertInlineCloseButton
              onClick={handleDismiss}
              data-qa-label="alert-inline-close-button"
            >
              <Icon
                name="close"
                lineColor={lineColor}
                backgroundColor={backgroundColor}
                size="s"
                qaLabel="alert-inline-icon-close"
              />
            </AlertInlineCloseButton>
          )}
        </AlertInlineContainer>
      </AnimatedWrapper>
    ) : null;
  }
);

export { AlertInlineProps };
