import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const scheduledRaces = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M16.8732 21.375C20.1856 21.375 22.8709 18.6887 22.8709 15.375C22.8709 
      12.0613 20.1856 9.375 16.8732 9.375C13.5607 9.375 10.8755 12.0613 10.8755 
      15.375C10.8755 18.6887 13.5607 21.375 16.8732 21.375Z"
      fill={backgroundColor}
    />
    <Path d="M16.6651 13.5V16.2512L18.9142 17.25" fill={backgroundColor} />
    <Path
      d="M17.6651 13.5C17.6651 12.9477 17.2173 12.5 16.6651 12.5C16.1128 12.5 
      15.6651 12.9477 15.6651 13.5H17.6651ZM16.6651 16.2512H15.6651C15.6651 
      16.6465 15.8979 17.0047 16.2592 17.1651L16.6651 16.2512ZM18.5083 
      18.1639C19.0131 18.3881 19.604 18.1606 19.8281 17.6559C20.0523 17.1511 
      19.8248 16.5602 19.3201 16.3361L18.5083 18.1639ZM3.625 
      19.875V8.625H1.625V19.875H3.625ZM3.625 
      8.625V4.875H1.625V8.625H3.625ZM20.375 
      4.875V7.50586H22.375V4.875H20.375ZM4.125 20.375C3.84886 20.375 
      3.625 20.1511 3.625 19.875H1.625C1.625 21.2557 2.74429 22.375 4.125 
      22.375V20.375ZM19.125 3.625C19.8154 3.625 20.375 4.18464 20.375 
      4.875H22.375C22.375 3.08007 20.9199 1.625 19.125 1.625V3.625ZM4.875 
      1.625C3.08007 1.625 1.625 3.08007 1.625 4.875H3.625C3.625 4.18464 
      4.18464 3.625 4.875 3.625V1.625ZM4.875 
      3.625H8.625V1.625H4.875V3.625ZM8.625 
      3.625H15.375V1.625H8.625V3.625ZM15.375 
      3.625H19.125V1.625H15.375V3.625ZM14.375 0V2.625H16.375V0H14.375ZM14.375 
      2.625V6H16.375V2.625H14.375ZM7.625 0V2.625H9.625V0H7.625ZM7.625 
      2.625L7.625 6L9.625 6L9.625 2.625L7.625 2.625ZM2.625 
      9.625H9.75V7.625H2.625V9.625ZM9.75 
      20.375H4.125V22.375H9.75V20.375ZM21.8709 15.375C21.8709 18.1368 19.633 
      20.375 16.8732 20.375V22.375C20.7383 22.375 23.8709 19.2406 23.8709 
      15.375H21.8709ZM16.8732 20.375C14.1134 20.375 11.8755 18.1368 11.8755 
      15.375H9.87546C9.87546 19.2406 13.0081 22.375 16.8732 
      22.375V20.375ZM11.8755 15.375C11.8755 12.6132 14.1134 10.375 16.8732 
      10.375V8.375C13.0081 8.375 9.87546 11.5094 9.87546 15.375H11.8755ZM16.8732 
      10.375C19.633 10.375 21.8709 12.6132 21.8709 15.375H23.8709C23.8709 
      11.5094 20.7383 8.375 16.8732 8.375V10.375ZM15.6651 
      13.5V16.2512H17.6651V13.5H15.6651ZM16.2592 17.1651L18.5083 
      18.1639L19.3201 16.3361L17.0709 15.3372L16.2592 17.1651Z"
      fill={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);

export default scheduledRaces;
