import styled from "styled-components/native";
import { View } from "react-native";
import { css } from "styled-components";
import { Paragraph } from "../typography";

export const Selections = styled(View)`
  flex-flow: row wrap;
  row-gap: ${({ theme }) => theme.space["space-1"]};
`;

export const IconContainer = styled(View)`
  align-items: center;
  flex-direction: row;
  height: ${({ theme }) => theme.space["space-6"]};
  margin-right: ${({ theme }) => theme.space["space-1"]};
  width: ${({ theme }) => theme.space["space-6"]};
`;

export const SaddleSelectionContainer = styled(View)<{
  isDetailView: boolean;
  hasThirdColumn: boolean;
}>`
  ${({ isDetailView }) =>
    isDetailView ? "flex-direction: column" : "flex-direction: row"};
  flex-wrap: wrap;
  row-gap: 6px;
  width: 100%;
`;

export const RowContainer = styled(View)<{
  isDetailView: boolean;
  showReplacedRunners?: boolean;
}>`
  ${({ isDetailView, theme }) =>
    isDetailView
      ? `display: grid;
  column-gap: ${theme.space["space-3"]};
  row-gap: ${theme.space["space-4"]};
  grid-template-columns: min-content 1fr min-content;`
      : "display: flex; flex-direction: row;"}

  ${({ isDetailView, showReplacedRunners }) =>
    isDetailView &&
    showReplacedRunners &&
    css`
      display: flex;
      flex-direction: row;
      column-gap: 0;
    `}
`;

export const SaddleDetailsParagraph = styled(Paragraph)<{ scratched: boolean }>`
  ${({ scratched }) => scratched && "text-decoration: line-through;"}
  font-size: 14px;
  color: ${({ theme }) => theme.colorTokens.content.default};
`;

export const WillPaysContainer = styled(View)`
  font-size: 14px;
`;
