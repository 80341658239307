import type {
  UserData,
  UserSessionSuccessAction,
  SetUserAccountNumber,
  UserBalance
} from "./types";

export const getUserData = (userData: UserData): UserSessionSuccessAction => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const setUserBalance = (balance: number): UserBalance => ({
  type: "USER_BALANCE",
  payload: balance
});

export const setUserAccountNumber = (
  accountNumber: string
): SetUserAccountNumber => ({
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber
  }
});
