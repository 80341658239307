import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Edit = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M2.625 16.875V21.375H7.125L19.125 9.375L14.625 4.875L2.625 16.875Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M14.625 4.875L2.625 16.875V21.375H7.125L19.125 9.375M14.625
      4.875L19.125 9.375M14.625 4.875L16.409 3.09099C17.2877 2.21231 18.7123
      2.21231 19.591 3.09099L20.909 4.40901C21.7877 5.28769 21.7877 6.71231
      20.909 7.59099L19.125 9.375"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);

export default Edit;
