import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import { Device } from "@tvg/ts-types/Device";

export interface Props {
  placeToRender: StoryblokPlaceName;
  componentName: InternalComponentNames;
  isInternalComponent: boolean;
  isDarkMode: boolean;
  shouldUpdate: boolean;
  device: Device;
  isShown: boolean;
  isHomePageSection: boolean;
  previewContentId: string;
  customMessage?: string;
}

export enum InternalComponentNames {
  PROGRESS = "progress",
  PREVIEW = "preview",
  UNKNOWN = ""
}
