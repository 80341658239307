import { get } from "lodash";

export const isBrowser = () =>
  typeof window !== "undefined" &&
  Object.prototype.toString.call(window) === "[object Window]";

export const isNativeApp = () =>
  isBrowser() &&
  ["iosnative", "androidnative"].includes(
    get(window, "__TVG_GLOBALS__.PRODUCT")
  );
