import React, { forwardRef, memo } from "react";
import { StyledContainer } from "./styled-components";
import { Step } from "./components";
import { PBContext } from "./context";
import type {
  ProgressBarProps,
  StepProps,
  ProgressBarCompoundComponent
} from "./types";

export const ProgressBar = memo(
  forwardRef<HTMLDivElement, ProgressBarProps>(
    (
      { qaLabel = "progress-bar", completedSteps = 0, children, ...rest },
      ref
    ) => (
      <StyledContainer ref={ref} {...rest} data-qa-label={qaLabel}>
        <PBContext.Provider value={{ completedSteps }}>
          {children.map((child, index) => {
            const stepNumber = index + 1;
            return React.cloneElement(child, {
              key: stepNumber,
              stepNumber
            });
          })}
        </PBContext.Provider>
      </StyledContainer>
    )
  )
) as ProgressBarCompoundComponent<typeof Step>;

ProgressBar.Step = Step;

export type { ProgressBarProps, StepProps };
