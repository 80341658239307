import React from "react";
import Svg, { Path } from "react-native-svg";
import { useTheme } from "../../theming";
import { CheckerProps } from "./types";

export const Checker = ({ isDisabled }: CheckerProps) => {
  const { colors } = useTheme();
  return (
    <Svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      focusable={false}
    >
      <Path
        d="M1.5 5L5 8.5L12.5 1"
        stroke={isDisabled ? colors.blue[200] : colors.white[900]}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
