import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Horse = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <path
    d="M12.75 6C9 6.75 3.75 10.5 3.75 17.25L12.75 21.75L12 12.75C12.6 15.75 16
    16 17.25 15.75L19.5 17.25C20.7 16.65 20.5 15 20.25 14.25C19.5 14 18.75 12
    18 9.75C17.5257 8.32697 16 6.5 15 5.25V3L12.75 6Z"
    fill={backgroundColor}
    fillOpacity="1"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
