import React, { PropsWithChildren } from "react";
import { Paragraph } from "../../../Typography";
import { buildQALabel } from "../../utils";
import { TEXT } from "../../constants";
import { TextProps } from "../../types";

const Text = ({
  children,
  color,
  fontWeight = 400,
  fontFamily = "regular",
  qaLabel = "",
  className
}: PropsWithChildren<TextProps>) => (
  <Paragraph
    qaLabel={buildQALabel([qaLabel, TEXT])}
    fontWeight={fontWeight}
    color={color}
    fontFamily={fontFamily}
    className={className}
    lineHeight="18px"
  >
    <>{children}</>
  </Paragraph>
);

export default Text;
