import styled from "styled-components/native";
import { View } from "react-native";

export const PositionsGrid = styled(View)<{ isLeg: boolean | undefined }>`
  display: grid;
  grid-template-columns: ${({ isLeg }) => (isLeg ? "58px" : "25px")} auto;
  column-gap: ${({ theme }) => theme.space["space-3"]};
  row-gap: ${({ theme }) => theme.space["space-4"]};
`;

export const Content = styled(View)`
  width: 65px;
  justify-content: center;
`;
