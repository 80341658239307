import styled from "styled-components";
import { fontMedium, fontNormal } from "@tvg/atomic-ui/_static/Typography";
import { buildColor, Icon } from "@tvg/design-system";
import Button from "@tvg/atomic-ui/_atom/Buttons/button";

import { getDisabledColor } from "../common/utils";

export const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${buildColor("white", "900")};
  padding-bottom: ${({ isMobile }) => (isMobile ? "16px" : "20px")};

  .success-message {
    padding-bottom: 0;
  }
`;

export const Main = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  padding: 0 12px;

  .payment-selector,
  .custom-keyboard,
  .preview-withdraw {
    margin-bottom: 16px;
  }

  .flag {
    margin-top: 4px;
  }

  .moneyPills {
    margin-bottom: 52px;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  padding: 52px 12px 32px;
`;

export const PaymentMethodTitle = styled.p`
  font-family: ${fontMedium};
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${buildColor("grey", "900")};
  padding-top: 16px;
  margin-bottom: 8px;
  border-top: 1px solid #d6e3f0;
`;

export const BalanceContainer = styled.div`
  display: flex;
  padding-top: 16px;
`;

export const ClickableHeader = styled.span<{ isLoading: boolean }>`
  border: none;
  background: transparent;
  color: ${({ isLoading }) => getDisabledColor(isLoading, "600")};
  cursor: ${({ isLoading }) => (isLoading ? "initial" : "pointer")};
  display: flex;
  align-items: center;
  margin: auto;
  width: fit-content;

  & > div {
    margin-right: 6px;
  }
`;

export const WireTransferTerms = styled.div`
  padding: 16px 0;
  border-top: 1px solid ${buildColor("blue", "100")};
`;

export const WireTransferTermsParagraph = styled.p`
  font-size: 12px;
  color: ${buildColor("grey", "800")};

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const CancelButtonWrapper = styled.div`
  margin-top: 12px;
`;

export const WireTransferModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  background-color: ${buildColor("white", "900")};
  border-radius: 4px 4px 0 0;
`;

export const WireTransferContainer = styled.div<{
  isMobile: boolean;
}>`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: ${({ isMobile }) => (isMobile ? "12px" : "20px")};
  margin-top: ${({ isMobile }) => (!isMobile ? "18px" : 0)};
`;

export const WireTransferButtonSubmit = styled(Button)<{
  removeOpacity: boolean;
}>`
  margin-top: 20px;
  ${({ removeOpacity }) => removeOpacity && "opacity: 1"}
`;

export const TermsAndConditionsCTA = styled.p<{
  isMobile: boolean;
}>`
  color: ${buildColor("grey", "800")};
  margin-top: 16px;
  margin-bottom: ${({ isMobile }) => (!isMobile ? "18px" : "4px")};
  text-align: center;

  & > span {
    font-size: 12px;
    line-height: 17px;
    font-family: ${fontNormal};
    font-weight: 400;
    vertical-align: center;
  }
`;

export const TermsAndConditionsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${buildColor("white", "900")};
  padding: 12px;
`;

export const RoutingNumberWrapper = styled.div``;

export const SupportIcon = styled(Icon)`
  position: absolute;
`;
