import styled, { css } from "styled-components";
import { space } from "styled-system";
import { animated } from "@react-spring/web";
import { useColorTokens } from "../../hooks";
import {
  AlertInlineProps,
  AlertInlineVariants,
  AlertInlineTypes
} from "./types";

const alertInlineVariants = {
  success: css`
    background-color: ${({ theme }) =>
      theme.colorTokens.component.notification.positive.backgroundSubtle};
    border-color: ${({ theme }) =>
      theme.colorTokens.component.notification.positive.border};
  `,
  informational: css`
    background-color: ${({ theme }) =>
      theme.colorTokens.component.notification.info.backgroundSubtle};
    border-color: ${({ theme }) =>
      theme.colorTokens.component.notification.info.border};
  `,
  error: css`
    background-color: ${({ theme }) =>
      theme.colorTokens.component.notification.negative.backgroundSubtle};
    border-color: ${({ theme }) =>
      theme.colorTokens.component.notification.negative.border};
  `,
  warning: css`
    background-color: ${({ theme }) =>
      theme.colorTokens.component.notification.warning.backgroundSubtle};
    border-color: ${({ theme }) =>
      theme.colorTokens.component.notification.warning.border};
  `,
  promo: css`
    background-color: ${({ theme }) =>
      theme.colorTokens.component.notification.promo.backgroundSubtle};
    border-color: ${({ theme }) =>
      theme.colorTokens.component.notification.promo.border};
  `
};

const alertInlineTypes = {
  floating: css`
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
  `,
  fixed: css`
    border-radius: 0;
    border-style: solid;
    border-width: 1px 0;
  `
};

export const AnimatedWrapper = styled(animated.div)`
  width: 100%;
  height: max-content;
`;

export const AlertInlineContainer = styled.div<
  Pick<AlertInlineProps, "variant" | "type">
>`
  box-sizing: content-box;
  display: flex;
  gap: ${({ theme }) => theme.space["space-4"]};
  padding: ${({ theme }) => theme.space["space-4"]};
  ${({ type }) => alertInlineTypes[type as AlertInlineTypes]}
  ${({ variant }) => alertInlineVariants[variant as AlertInlineVariants]}
  ${space}
`;

export const AlertInlineInfo = styled.div<{ flexDirection: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex: 1;
  gap: ${({ theme }) => theme.space["space-1"]};
  justify-content: space-between;
`;

export const AlertInlineCloseButton = styled.button`
  background: transparent;
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
`;

// TODO: The text components below should be deleted when the typography component is merged.
export const Title = styled.p<{ color: string }>`
  color: ${({ color }) => useColorTokens(color)};
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
`;

export const Message = styled.p<{ color: string }>`
  color: ${({ color }) => useColorTokens(color)};
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

export const Link = styled.a<{ color: string }>`
  color: ${({ color }) => useColorTokens(color)};
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration-line: underline;
`;
