import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const withRouter = (Component) => {
  return (props) => {
    const location = useLocation();
    const history = useNavigate();
    const match = { params: useParams() };

    const customProps = {
      history: {
        back: () => history(-1),
        goBack: () => history(-1),
        location,
        push: (url, state) => history(url, { state }),
        replace: (url, state) =>
          history(url, {
            replace: true,
            state
          })
      },
      location,
      match
    };

    return <Component {...customProps} {...props} />;
  };
};

export default withRouter;
