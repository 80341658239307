import { Buttons, IconButtons } from "../../types";
import { colors } from "./colors";
import { elevations } from "./elevations";

export const buttons: Buttons = {
  primary: {
    default: {
      background: colors.blue_accent["500"],
      border: "none",
      boxShadow: elevations.medium,
      color: colors.white["900"]
    },
    hovered: {
      background: colors.blue_accent["600"],
      boxShadow: elevations.high
    },
    focused: { background: colors.blue_accent["700"], boxShadow: "0 0 0" },
    pressed: { background: colors.blue_accent["700"], boxShadow: "0 0 0" },
    loading: { background: colors.white["200"] }
  },
  secondary: {
    default: {
      background: colors.white["900"],
      border: `1px solid ${colors.blue["100"]}`,
      boxShadow: elevations.low,
      color: colors.grey["900"]
    },
    hovered: {
      background: colors.blue_accent["000"],
      boxShadow: elevations.medium
    },
    focused: { background: colors.blue_accent["100"], boxShadow: "0 0 0" },
    pressed: { background: colors.blue_accent["100"], boxShadow: "0 0 0" },
    loading: { background: colors.grey["200"] }
  },
  secondary_dark: {
    default: {
      background: colors.white["000"],
      border: "none",
      color: colors.white["900"]
    },
    hovered: { background: colors.white["100"] },
    focused: { background: colors.white["200"] },
    pressed: { background: colors.white["200"] },
    loading: { background: colors.white["200"] }
  },
  tertiary: {
    default: {
      background: "transparent",
      border: "none",
      color: colors.blue_accent["500"]
    },
    hovered: {
      background: colors.blue_accent["100"]
    },
    focused: { background: colors.blue_accent["200"] },
    pressed: { background: colors.blue_accent["200"] },
    loading: { background: colors.blue_accent["200"] }
  },
  tertiary_dark: {
    default: {
      background: "transparent",
      border: "none",
      color: colors.white["900"]
    },
    hovered: {
      background: colors.white["000"]
    },
    focused: { background: colors.white["200"] },
    pressed: { background: colors.white["200"] },
    loading: { background: colors.white["200"] }
  },
  betting: {
    default: {
      background: colors.green["500"],
      border: "none",
      boxShadow: elevations.medium,
      color: colors.white["900"]
    },
    hovered: {
      background: colors.green["600"],
      boxShadow: elevations.high
    },
    focused: { background: colors.green["700"], boxShadow: "0 0 0" },
    pressed: { background: colors.green["700"], boxShadow: "0 0 0" },
    loading: { background: colors.white["200"] }
  },
  marketing: {
    default: {
      background: colors.yellow["500"],
      border: "none",
      boxShadow: elevations.medium,
      color: colors.grey["900"]
    },
    hovered: {
      background: colors.yellow["600"],
      boxShadow: elevations.high
    },
    focused: { background: colors.yellow["700"], boxShadow: "0 0 0" },
    pressed: { background: colors.yellow["700"], boxShadow: "0 0 0" },
    loading: { background: colors.grey["200"] }
  },
  danger: {
    default: {
      background: colors.red["500"],
      border: "none",
      boxShadow: elevations.medium,
      color: colors.white["900"]
    },
    hovered: {
      background: colors.red["600"],
      boxShadow: elevations.high
    },
    focused: { background: colors.red["700"], boxShadow: "0 0 0" },
    pressed: { background: colors.red["700"], boxShadow: "0 0 0" },
    loading: { background: colors.white["200"] }
  }
};

export const iconButtons: IconButtons = {
  primary: {
    ...buttons.primary
  },
  secondary: {
    ...buttons.secondary
  },
  secondary_dark: {
    ...buttons.secondary_dark
  },
  tertiary: {
    default: {
      background: "transparent",
      border: "none",
      color: colors.grey["900"]
    },
    hovered: {
      background: colors.grey["100"]
    },
    focused: { background: colors.grey["200"] },
    pressed: { background: colors.grey["200"] }
  },
  tertiary_dark: {
    ...buttons.tertiary_dark
  },
  betting: {
    ...buttons.betting
  },
  danger: {
    ...buttons.danger
  }
};
