import { Platform } from "react-native";

type QaLabelWeb = { dataSet: { "qa-label": string } };
type QaLabelNative = { "data-qa-label": string };

/**
 * Web and Native require different implementations of the data-qa-label this hook encapsulates that logic
 *
 * Usage:
 * const elementTestProps = useQaLabel('element-label-goes-here')
 * <Element {...elementTestProps} />
 */
export const useQaLabel = (label: string): QaLabelWeb | QaLabelNative =>
  Platform.OS === "web"
    ? { dataSet: { "qa-label": label } }
    : { "data-qa-label": label };

export default useQaLabel;
