import styled, { css } from "styled-components";
import { fontCondensedNormal } from "@tvg/atomic-ui/_static/Typography";
import { Size } from "./types";

export const Container = styled.div<{ bgColor: string; size: Size }>`
  display: flex;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  padding: 0 6px;

  svg {
    margin-right: 4px;
  }

  ${({ size }) =>
    size === "small"
      ? css`
          height: 16px;
          border-radius: 8px;
        `
      : css`
          height: 24px;
          border-radius: 12px;
        `}
`;

export const Text = styled.h3<{ color: string; size: Size }>`
  color: ${({ color }) => color};
  font-family: ${fontCondensedNormal};
  font-weight: normal;
  text-transform: uppercase;
  ${({ size }) =>
    size === "small"
      ? css`
          font-size: 12px;
          line-height: 14px;
        `
      : css`
          font-size: 14px;
          line-height: 16px;
        `}
`;
