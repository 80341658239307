import { PromoAcquisition } from "../types/promos";
import { PromoOnboardingFormatted } from "../types/promoOnboarding";
import formatSteps from "./formatSteps";
import formatCompletedSteps from "./formatCompletedSteps";
import { getCurrentStepType } from "../utils/getCurrentStep";

export const formatPromoStruct = (
  promoAcquisition: PromoAcquisition,
  previousRootId?: number
): PromoOnboardingFormatted => {
  const {
    promotionId,
    promoName,
    currentStep,
    isPromoExpired,
    remainingDays,
    rebateLimit,
    numberSteps,
    steps
  } = promoAcquisition;
  const formattedSteps = formatSteps(steps);
  const completedSteps = formatCompletedSteps(
    currentStep,
    numberSteps,
    formattedSteps
  );

  const currentStepType = getCurrentStepType(
    currentStep,
    steps,
    formattedSteps
  );

  return {
    rootId: previousRootId,
    promotionId,
    promoName,
    completedSteps,
    currentStepType,
    isPromoExpired,
    numberSteps,
    remainingDays,
    rebateLimit,
    steps: formattedSteps
  };
};

export default formatPromoStruct;
