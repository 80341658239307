import { Dispatch as ReactDispatch, SetStateAction } from "react";
import { FundsAmountInputChangingFrom } from "../../../../types";
import { getNextValueFundsAmount } from "../../../../utils";

export const parseMaxWithdrawAmountToFloat = (
  capiMaxWithdrawAmount: string | null
) => {
  const defaultValue = 0;

  if (capiMaxWithdrawAmount === null) return defaultValue;

  const result = parseFloat(capiMaxWithdrawAmount);

  return !Number.isNaN(result) ? result : defaultValue;
};

export const onWithdrawChangeValue =
  (
    isLoading: boolean,
    value: string,
    setValue: ReactDispatch<SetStateAction<string>>
  ) =>
  (keyValue: number | string, from: FundsAmountInputChangingFrom) => {
    if (isLoading) {
      return;
    }

    let nextValue = getNextValueFundsAmount(keyValue, from, value);

    nextValue = nextValue.charAt(0) === "." ? `0.` : nextValue;

    // TODO optimize this regex
    if (RegExp("^[0-9]{0,7}$|^[0-9]{1,7}[.][0-9]{0,2}$").test(nextValue)) {
      setValue(nextValue);
    }
  };

export const onWithdrawClearValue =
  (setValue: ReactDispatch<SetStateAction<string>>) => () => {
    setValue((prevValue: string) => prevValue.slice(0, -1));
  };
