import { History } from "@tvg/ts-types/History";
import {
  PromoStepWagerStatus,
  WagerStep
} from "@tvg/sh-lib-promos-onboarding/types/promoSteps";
import { goToProgramPage } from "@tvg/utils/routeRedirectUtils";
import { PromoCTA } from "../../types";

export const getWatchLiveCTA = (
  history: History,
  promoOnboardingStep: WagerStep,
  isDesktop: boolean
) => {
  const { trackCode, trackName, raceNumber, isGreyHound, wagerStatus } =
    promoOnboardingStep;
  return {
    CTALabel: "Watch Live",
    onCTAPress: () => {
      if (trackCode && trackName && raceNumber) {
        goToProgramPage(
          history,
          trackCode,
          trackName,
          raceNumber,
          !!isGreyHound,
          isDesktop
        );
      }
    },
    CTAShow: wagerStatus === PromoStepWagerStatus.PLACED
  } as PromoCTA;
};
