import styled from "styled-components/native";
import { margin } from "styled-system";
import { Theme } from "../../../theming/types";

export const StyledViewContainer = styled.View`
  ${margin}
  border-width: 1px;
  border-color: transparent;
`;

export const StyledTextLabel = styled.Text<{ theme: Theme }>`
  font-family: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.colors.grey[900]};
  margin-bottom: ${({ theme }) => theme.space["space-4"]};
`;
