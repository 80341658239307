import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const DesktopContainer = styled.section<{
  isTablet: boolean;
  isTvg5: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${({ isTablet }) => (isTablet ? "56px" : "265px")});
  ${({ isTablet }) => isTablet && "margin-bottom: -48px;"}
  ${({ isTvg5 }) => isTvg5 && "width: 100%;"}
  background-color: ${buildColor("blue", "000")};
`;

export const PromosOnboardingWrapper = styled.div<{
  isDesktop?: boolean;
}>`
  width: 100%;
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      margin: 0 auto;
      padding-top: 12px;
      max-width: 976px;
    `}
`;
