import { buildColor } from "@tvg/design-system";

const colors = {
  border: {
    active: buildColor("blue_accent", "500"),
    normal: buildColor("blue", "100")
  },
  text: {
    active: buildColor("blue_accent", "500"),
    normal: buildColor("blue", "300")
  }
};

export const getTextColor = (isFocused: boolean) =>
  isFocused ? colors.text.active : colors.text.normal;

export const getBorderColor = (isFocused: boolean) =>
  isFocused ? colors.border.active : colors.border.normal;
