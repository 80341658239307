import React, { useMemo } from "react";
import { ButtonAction } from "@tvg/wallet/src/types";
import { useSelector } from "react-redux";
import { getPawsContent } from "@tvg/sh-lib-paws/redux/selectors";
import InfoMessage from "../info";
import { WithdrawalBalanceModalProps } from "../../common/types";

export interface EmptyBalanceProps {
  onClose: () => void;
}

const EmptyBalance = ({ onClose }: EmptyBalanceProps) => {
  const { withdrawalBalanceModal }: WithdrawalBalanceModalProps =
    useSelector(getPawsContent);

  const actions: ButtonAction[] = useMemo(
    () => [
      {
        text: withdrawalBalanceModal.okBtnText,
        onClick: onClose,
        isStreched: true,
        isUppercase: false,
        qaLabel: "ok-btn"
      }
    ],
    []
  );

  return (
    <InfoMessage
      actions={actions}
      description={withdrawalBalanceModal.noBalanceDescription}
      title={withdrawalBalanceModal.noBalanceTitle}
      onClose={onClose}
    />
  );
};

export default React.memo(EmptyBalance);
