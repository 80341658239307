import { createSlice } from "@reduxjs/toolkit";

export type State = {
  isOpen: boolean;
};

export const initialState: State = {
  isOpen: false
};

const pendingWithdrawModalSlice = createSlice({
  name: "pendingWithdrawModal",
  initialState,
  reducers: {
    togglePendingWithdraw(state, action) {
      state.isOpen = action.payload;
    }
  }
});

export const { togglePendingWithdraw } = pendingWithdrawModalSlice.actions;

export default pendingWithdrawModalSlice.reducer;
