// This file is here to replace Lodash.
// Currently we need to import 70Kb (after optimizations) from lodash just to use 6 functions.
// This is not worth it. If the uses cases for lodash increase, we should get
// rid of this.

export const isUndefined = (val) => typeof val === "undefined";

export const curry = (fn) =>
  function cf(...args) {
    return args.length >= fn.length
      ? fn(...args)
      : (...a) => cf(...[...args, ...a]);
  };

export const defaultTo = curry((defaults, value) =>
  value === undefined || value === null || Number.isNaN(value)
    ? defaults
    : value
);

export const isDifferent = curry((a, b) => a !== b);
