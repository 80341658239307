import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Stable = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M21.375 21.375V11.9819L19.41 6.27321L12 3L4.56661 6.27321L2.625
      11.9819V21.375H6.375V13.5H17.625V21.375H21.375Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M6.375 21.375H2.625V11.9819L4.56661 6.27321L12 3L19.41 6.27321L21.375
      11.9819V21.375H17.625M6.375 21.375V13.5H17.625V21.375M6.375
      21.375H17.625M17.625 21.375L6.4043 13.5293"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
