// @flow
// $FlowBug
import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";

import { get } from "lodash";
import {
  StyledSelect,
  SelectLabel,
  SelectWrapper,
  IconWrapper,
  SelectContainer
} from "./styled-components";
import { arrowDown, warning } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { ErrorText } from "../InputV2/styled-components";

type Props = {
  handleCustomChange: Function | void,
  options: { key: string, value: string }[],
  name: string,
  label?: string,
  qaLabel: string,
  defaultValue: string,
  hasMarginBottom?: boolean,
  autoCompleteValue?: string,
  optionSelected?: string,
  placeholder?: string,
  customHandleOnBlur?: Function | void,
  shouldGreyFirst?: boolean,
  clearErrorOnFocus?: boolean
};

export const SelectMolecule = ({
  handleCustomChange,
  customHandleOnBlur,
  options,
  name,
  label,
  qaLabel,
  defaultValue,
  hasMarginBottom,
  autoCompleteValue = "off",
  optionSelected,
  shouldGreyFirst,
  placeholder,
  clearErrorOnFocus
}: Props) => {
  const selectRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("");
  const { fieldName, registerField, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: "value"
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (optionSelected) {
      selectRef.current.value = optionSelected;
    }
  }, []);

  return (
    <SelectContainer data-qa-label={qaLabel} hasMarginBottom={hasMarginBottom}>
      {!!label && <SelectLabel>{label}</SelectLabel>}
      <SelectWrapper>
        <StyledSelect
          name={name}
          defaultValue={defaultValue}
          data-qa-label={fieldName}
          autoComplete={autoCompleteValue}
          ref={selectRef}
          error={error}
          onChange={(e) => {
            const value = get(e, "target.value");
            const index = get(e, "target.selectedIndex");
            const selectOptions = get(e, "target.options");
            if (selectRef.current) {
              selectRef.current.value = value;
              setSelectedOption(value);
            }
            if (typeof handleCustomChange === "function") {
              handleCustomChange({
                selectOptions,
                value,
                index
              });
            }
          }}
          isGreyed={
            shouldGreyFirst &&
            selectRef.current &&
            selectRef.current.value === ""
          }
          onBlur={(event) => {
            const { value } = event.target;
            if (typeof customHandleOnBlur === "function") {
              customHandleOnBlur(value);
            }
          }}
          onFocus={() => {
            if (clearErrorOnFocus) {
              clearError();
            }
          }}
        >
          {defaultValue && (
            <option value="" disabled>
              {defaultValue}
            </option>
          )}
          {placeholder && (
            <option value="" disabled selected>
              {placeholder}
            </option>
          )}
          {options.map((opt) => (
            <option {...opt}>{opt.key}</option>
          ))}
        </StyledSelect>
        <IconWrapper>
          <Icon color={buildColor("blue_accent", "500")} icon={arrowDown} />
        </IconWrapper>
      </SelectWrapper>
      {error && (
        <ErrorText>
          <Icon icon={warning} color={buildColor("orange", "600")} />
          {error}
        </ErrorText>
      )}
    </SelectContainer>
  );
};

SelectMolecule.defaultProps = {
  hasMarginBottom: true,
  shouldGreyFirst: false
};

export default SelectMolecule;
