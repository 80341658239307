import { Dispatch } from "@reduxjs/toolkit";
import { closeOtherPaymentDepositModal } from "@tvg/sh-lib-paws/redux/slices/depositFundsModalSlice";
import { NullaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import { openExternalLink } from "@tvg/utils/mobileUtils";
import { PaymentType } from "@tvg/ts-types/Payment";
import React, { MouseEvent } from "react";
import {
  gtmOtherPaymentMethodsCTAClick,
  gtmOtherPaymentMethodsOpenTab,
  gtmOtherPaymentMethodsPDFDownload
} from "../../../gtm";
import { LinkItem, OtherPaymentTab } from "../common/types";

export const onClose =
  (dispatch: Dispatch, onCloseCallback: NullaryFn<void> | undefined) => () => {
    dispatch(closeOtherPaymentDepositModal());
    if (typeof onCloseCallback === "function") {
      onCloseCallback();
    }
  };

export const onTabClick =
  (index: number, tab: OtherPaymentTab, setValue: UnaryFn<number, void>) =>
  () => {
    setValue(index);
    gtmOtherPaymentMethodsOpenTab({ paymentType: tab.method });
  };

export const onLinkClick =
  (listItem: LinkItem, method: PaymentType) =>
  (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    openExternalLink(listItem.url);
    if (method === PaymentType.WIRE_TRANSFER) {
      gtmOtherPaymentMethodsPDFDownload({ linkText: listItem.text });
    } else {
      gtmOtherPaymentMethodsCTAClick({ linkText: listItem.text });
    }
  };

export const onMessageClick = () => (e: MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  openLiveChat();
  gtmOtherPaymentMethodsCTAClick({
    linkText: "message our customer service"
  });
};
