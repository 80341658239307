import styled from "styled-components";
import DefaultButton from "@tvg/atomic-ui/_atom/Buttons/default";

export const CloseButton = styled(DefaultButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 0;
  background: none;
  flex: 0 0 24px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  margin: 12px;
`;
