export const getColor = (
  isHovered: boolean,
  isPressed: boolean,
  isActive: boolean,
  isLight: boolean
): string => {
  const baseColor = "blue_accent";
  const baseVariation = isLight ? 400 : 500;
  if (isActive || isPressed) return `${baseColor}.${baseVariation + 200}`;
  if (isHovered) return `${baseColor}.${baseVariation + 100}`;
  return `${baseColor}.${baseVariation}`;
};
