import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentTypeOption } from "@tvg/wallet/src/types";

export type State = {
  isOpen: boolean;
  paymentMethod: PaymentTypeOption | null;
};

export const initialState: State = {
  isOpen: false,
  paymentMethod: null
};

const deleteCardModalSlice = createSlice({
  name: "deleteCardModal",
  initialState,
  reducers: {
    openDeleteCardModal(state, action: PayloadAction<PaymentTypeOption>) {
      state.isOpen = true;
      state.paymentMethod = action.payload;
    },
    closeDeleteCardModal(state) {
      state.isOpen = false;
    }
  }
});

export const { openDeleteCardModal, closeDeleteCardModal } =
  deleteCardModalSlice.actions;

export default deleteCardModalSlice.reducer;
