import React from "react";

import Icon from "@tvg/atomic-ui/_static/Icons";
import * as allIcons from "@tvg/atomic-ui/_static/Icons/icons";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

import {
  getPawsOnlyIcons,
  getPaymentMethodIconName,
  shouldCapitalizeTitle
} from "../../utils";
import type { Props } from "./types";

import {
  ModalContainer,
  ModalTextContainer,
  ModalTitleText,
  ModalSubtitleText
} from "./styled-components";

const ModalTitleWithIcon = ({ title, subtitle, paymentType }: Props) => {
  const paymentIcon = getPaymentMethodIconName(paymentType);
  const isCapitalize = shouldCapitalizeTitle(paymentType);
  return (
    <ModalContainer>
      {getPawsOnlyIcons(paymentIcon) || (
        <Icon
          icon={allIcons[paymentIcon]}
          size={16}
          color={buildColor("blue_accent", "500")}
        />
      )}
      <ModalTextContainer>
        <ModalTitleText isCapitalize={isCapitalize}>
          {isCapitalize ? title.toLowerCase() : title}
        </ModalTitleText>

        <ModalSubtitleText>{subtitle}</ModalSubtitleText>
      </ModalTextContainer>
    </ModalContainer>
  );
};

ModalTitleWithIcon.defaultProps = {};

export default ModalTitleWithIcon;
