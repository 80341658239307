import React from "react";
import { IconPathProps } from "../../types";

export const Share = ({ lineColor, strokeWidth }: IconPathProps) => (
  <path
    d="M7.50414 7.87555H6.38028C5.13764 7.87555 4.13028 8.88348 4.13028
    10.1268V17.6311C4.13028 18.8745 5.13764 19.8824 6.38028
    19.8824H17.625C18.8676 19.8824 19.875 18.8745 19.875 17.6311V10.1268C19.875
    8.88348 18.8676 7.87555 17.625 7.87555H16.5011M8.33356 4.80098L12
    1.13242L15.6665 4.80098M12 13.5747L12 1.66733"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
