// @flow
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import ButtonDefault from "./default";
import {
  type TagType,
  type ButtonSize,
  type ButtonSizeItem,
  type ButtonType,
  type ButtonTypeItem
} from "./Types";
import { getSize, getType } from "./styles";
import buildColor from "../../_static/ColorPalette";

// Button
export const ButtonComp = styled(ButtonDefault)`
  height: ${(props) => props.$sizeSpecs.height};
  width: ${(props) => props.$isStretched && "100%"};
  padding: ${(props) => props.$sizeSpecs.padding};
  font-size: ${(props) => props.$sizeSpecs.fontSize};
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "inherit")};

  && {
    background-color: ${(props) =>
      props.isSelected && !props.$isDisabled
        ? props.$typeSpecs.selected
        : props.$typeSpecs.background};
    background-color: ${(props) =>
      props.isLoading && props.$typeSpecs.loadingBackground};
  }

  color: ${(props) => props.$typeSpecs.text};
  border: ${(props) => props.$typeSpecs.border};
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
  text-transform: ${(props) =>
    props.isUppercase ? "uppercase" : props.textTransform};
  border-radius: ${(props) => (props.$hasRoundedCorners ? "2px" : 0)};
  ${(props) =>
    props.$hasShadow && `box-shadow: 0px 2px 2px rgba(17, 43, 68, 0.15);`}
  ${({ customMarginBottom }) =>
    customMarginBottom > 0 ? `margin-bottom: ${customMarginBottom}px;` : ""}

  /* only add hover state for fine pointers, meaning that only on desktops using a mouse the hover state will work */
  @media (pointer: fine) {
    &:hover {
      background-color: ${(props) =>
        !props.$isDisabled && props.$typeSpecs.hover};
    }
  }

  &:active {
    background-color: ${(props) =>
      !props.$isDisabled && props.$typeSpecs.active};

    /* on touch devices (coarse), the active color should be the hover spec according to the style guide on zeplin */
    @media (pointer: coarse) {
      background-color: ${(props) =>
        !props.$isDisabled && props.$typeSpecs.hover};
    }
  }

  & > span {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }
`;

export const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.$isStretched && "100%"};
  height: ${(props) => props.$sizeSpecs.height};
  padding: ${(props) => props.$sizeSpecs.padding};
  font-size: ${(props) => props.$sizeSpecs.fontSize};
  background-color: ${(props) => props.$typeSpecs.background};
  color: ${(props) => props.$typeSpecs.text};
  opacity: ${(props) => (props.$isDisabled ? 0.3 : 1)};
  border: ${(props) => props.$typeSpecs.border};
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
  text-decoration: none;
  text-transform: ${(props) => (props.isUppercase ? "uppercase" : "normal")};
  border-radius: ${(props) => (props.$hasRoundedCorners ? "2px" : 0)};
  line-height: 1;
  ${(props) =>
    props.$hasShadow && `box-shadow: 0px 2px 2px rgba(17, 43, 68, 0.15);`}

  /* only add hover state for fine pointers, meaning that only on desktops using a mouse the hover state will work */
  @media (pointer: fine) {
    &:hover {
      background-color: ${(props) =>
        !props.$isDisabled && props.$typeSpecs.hover};
    }
  }

  &:active {
    background-color: ${(props) =>
      !props.$isDisabled && props.$typeSpecs.active};

    /* on touch devices (coarse), the active color should be the hover spec according to the style guide on zeplin */
    @media (pointer: coarse) {
      background-color: ${(props) =>
        !props.$isDisabled && props.$typeSpecs.hover};
    }
  }
`;

export const StyledRef = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.$isStretched && "100%"};
  height: ${(props) => props.$sizeSpecs.height};
  padding: ${(props) => props.$sizeSpecs.padding};
  font-size: ${(props) => props.$sizeSpecs.fontSize};
  background-color: ${(props) => props.$typeSpecs.background};
  color: ${(props) => props.$typeSpecs.text};
  opacity: ${(props) => (props.$isDisabled ? 0.3 : 1)};
  border: ${(props) => props.$typeSpecs.border};
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
  text-decoration: none;
  text-transform: ${(props) => (props.isUppercase ? "uppercase" : "normal")};
  border-radius: ${(props) => (props.$hasRoundedCorners ? "2px" : 0)};
  line-height: 1;
  ${(props) =>
    props.$hasShadow && `box-shadow: 0px 2px 2px rgba(17, 43, 68, 0.15);`}

  /* only add hover state for fine pointers, meaning that only on desktops using a mouse the hover state will work */
  @media (pointer: fine) {
    &:hover {
      background-color: ${(props) =>
        !props.$isDisabled && props.$typeSpecs.hover};
    }
  }

  &:active {
    background-color: ${(props) =>
      !props.$isDisabled && props.$typeSpecs.active};

    /* on touch devices (coarse), the active color should be the hover spec according to the style guide on zeplin */
    @media (pointer: coarse) {
      background-color: ${(props) =>
        !props.$isDisabled && props.$typeSpecs.hover};
    }
  }
`;

const appendHref = ({ component, isExternal }) =>
  isExternal ? styled.a.attrs((props) => ({ href: props.to })) : component;

// Link
export const buildLink = (
  tag: TagType,
  type: ButtonType,
  size: ButtonSize,
  isStretched: boolean,
  isDisabled: boolean,
  hasRoundedCorners: boolean,
  isExternal?: boolean
) => {
  const sizeSpecs: ButtonSizeItem = getSize(size);
  const typeSpecs: ButtonTypeItem = getType(type);

  const StyledComp = appendHref({ component: styled(Link), isExternal })`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${isStretched && "100%"};
    height: ${sizeSpecs.height};
    padding: ${sizeSpecs.padding};
    font-size: ${sizeSpecs.fontSize};
    background-color: ${typeSpecs.background};
    color: ${typeSpecs.text};
    opacity: ${isDisabled ? 0.3 : 1};
    border: ${typeSpecs.border};
    font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
    text-decoration: none;
    text-transform: ${(props) => (props.isUppercase ? "uppercase" : "normal")};
    border-radius: ${hasRoundedCorners ? "2px" : 0};
    line-height: 1;

    /* only add hover state for fine pointers, meaning that only on desktops using a mouse the hover state will work */
    @media (pointer: fine) {
      &:hover {
        background-color: ${!isDisabled && typeSpecs.hover};
      }
    }

    &:active {
      background-color: ${!isDisabled && typeSpecs.active};

      /* on touch devices (coarse), the active color should be the hover spec according to the style guide on zeplin */
      @media (pointer: coarse) {
        background-color: ${!isDisabled && typeSpecs.hover};
      }
    }
  `;

  return tag === "link"
    ? StyledComp
    : // $FlowFixMe
      StyledComp.withComponent(tag);
};

// Promos
const OverwriteStyles = css`
  width: 56px;
  padding: 0;
  font-size: 10px;
  display: flex;
  flex-direction: column;
`;

export const Promos = styled(
  buildLink("link", "marketing", "big", false, false, true)
)`
  ${OverwriteStyles};
`;

export const PromosExternal = styled(
  buildLink("a", "marketing", "big", false, false, true, true)
)`
  ${OverwriteStyles};
`;

export const OptedInAlt = styled(
  buildLink("link", "secondary_alt", "big", false, false, true)
)`
  ${OverwriteStyles};
`;

export const OptedIn = styled(
  buildLink("link", "secondary", "big", false, false, true)
)`
  ${OverwriteStyles};
`;

// MyBets
export const RoundBtnComp = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  height: 44px;
  width: 44px;
  background-color: ${buildColor("white", "100")};
  border-radius: 50%;
  border: 1px solid ${buildColor("blue", "100")};
  z-index: 20;
  text-align: center;
  pointer-events: all;

  &:active {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;
