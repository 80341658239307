import constructRequest from "../codeBase";
import type { PaymentTypesAvailable } from "../types";
import { serviceResponse } from "../../types";

const getBank = (
  paymentType: PaymentTypesAvailable,
  depositData: Object
): Promise<serviceResponse> =>
  constructRequest(paymentType, "getBank", depositData);

const createWithDeposit = (
  paymentType: PaymentTypesAvailable,
  depositData: Object
): Promise<serviceResponse> =>
  constructRequest(paymentType, "createWithDeposit", depositData);

const getPayNearMeId = (
  paymentType: PaymentTypesAvailable,
  depositData: Object
): Promise<serviceResponse> =>
  constructRequest(paymentType, "getId", depositData);

const createPayNearMeId = (
  paymentType: PaymentTypesAvailable,
  depositData: Object
): Promise<serviceResponse> =>
  constructRequest(paymentType, "createId", depositData);

const disablePayment = (
  paymentType: PaymentTypesAvailable,
  depositData: Object
): Promise<serviceResponse> =>
  constructRequest(paymentType, "disable", depositData);

const PAWS_CREATE_SERVICE = {
  getBank,
  createWithDeposit,
  getPayNearMeId,
  createPayNearMeId,
  disablePayment
};

export default PAWS_CREATE_SERVICE;
