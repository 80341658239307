import { useEffect } from "react";
import { Dispatch } from "redux";
import {
  startPromoOnboardingPoller,
  tryStopPromoOnboardingPoller
} from "../utils/promoOnboardingPoller";
import {
  startPromoOnboardingTimerPoller,
  tryStopPromoOnboardingTimerPoller
} from "../utils/promoOnboardingPollerTime";
import { resetStoryblokPromos } from "../redux/actions";
import { usePromoEligible } from "./usePromoEligible";

export const usePromoOnboardingPoller = (
  dispatch: Dispatch,
  promoOnboardingToggle: boolean,
  pollerTime: number,
  isLogged?: boolean,
  accountId?: string
) => {
  const promoEligible = usePromoEligible(
    isLogged,
    accountId,
    promoOnboardingToggle
  );

  useEffect(() => {
    if (promoOnboardingToggle) {
      startPromoOnboardingTimerPoller(dispatch);
    } else {
      tryStopPromoOnboardingTimerPoller();
    }

    return () => {
      tryStopPromoOnboardingTimerPoller();
    };
  }, [promoOnboardingToggle]);

  useEffect(() => {
    if (promoOnboardingToggle && promoEligible) {
      startPromoOnboardingPoller(
        dispatch,
        pollerTime,
        isLogged,
        accountId,
        promoEligible
      );
    } else {
      tryStopPromoOnboardingPoller();
    }

    return () => {
      tryStopPromoOnboardingPoller();
      dispatch(resetStoryblokPromos());
    };
  }, [pollerTime, isLogged, accountId, promoEligible]);
};
