import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system";
import { fontCondensedNormal } from "@tvg/atomic-ui/_static/Typography";

const fullHeightColumn = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Container = styled.div<{ isMobile: boolean }>`
  ${fullHeightColumn};
  position: relative;
  ${({ isMobile }) =>
    !isMobile &&
    css`
      max-height: 500px;
    `};
  background-color: ${buildColor("blue", "000")};
`;

export const Wrapper = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${buildColor("white", "900")};
  box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
`;

export const StyledHeader = styled.header<{ hideTopBorder?: boolean }>`
  position: sticky;
  ${({ hideTopBorder }) =>
    !hideTopBorder &&
    css`
      border-top: 1px solid ${buildColor("blue", "100")};
    `};
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid ${buildColor("blue", "100")};
  background-color: ${buildColor("blue_accent", "000")};
  padding: 0 44px 0 12px;
`;

export const StyledFooter = styled.footer`
  position: sticky;
  left: 0;
  bottom: 0;
  padding: 16px 12px;
  border-top: 1px solid ${buildColor("blue", "100")};
  background-color: ${buildColor("white", "900")};
`;

export const HeaderTitle = styled.h3`
  font-family: ${fontCondensedNormal};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${buildColor("grey", "800")};
`;

export const StyledRow = styled.div<{
  isLastRow: boolean;
  showDelete: boolean;
}>`
  position: relative;
  display: flex;
  height: 42px;
  align-items: center;
  ${({ showDelete }) =>
    showDelete
      ? css`
          padding: 0 12px;
        `
      : css`
          padding: 0 44px 0 12px;
        `}

  ${({ isLastRow }) =>
    !isLastRow &&
    css`
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 12px;
        right: 0;
        height: 1px;
        background-color: ${buildColor("blue", "100")};
      }
    `}
`;

export const Column = styled.div`
  flex: 1 0 0;

  &:first-child {
    flex: 1.3 0 0;
  }
`;

export const StyledIconButton = styled.button`
  background-color: transparent;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:active {
    background-color: ${buildColor("red", "100")};
  }
`;

export const ModalBody = styled.div<{ hasPadding: boolean }>`
  position: relative;
  background-color: ${buildColor("white", "900")};
  ${({ hasPadding }) =>
    hasPadding &&
    css`
      padding: 22px 12px 12px 12px;
    `}
`;

export const CancelModalInfoContainer = styled.div`
  padding: 18px 12px 12px 12px;
`;

export const CancelButtonsContainer = styled.div`
  display: flex;
  margin-top: 18px;
  min-height: 44px;
`;

export const CancelButtonWrapper = styled.div`
  flex: 1 0 0;

  &:last-child {
    margin-left: 12px;

    @media screen and (max-width: 360px) {
      margin-left: 10px;
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  height: 44px;
`;

export const IconButtonContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 8px;
  z-index: 1;
`;

export const CustomerServiceLink = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

export const EmptyContainer = styled.div`
  ${fullHeightColumn};
  justify-content: center;
  background-color: ${buildColor("white", "900")};
  padding: 12px;
`;

export const EmptyDialogContainer = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
