import React, { FC } from "react";

export const SouthKorea: FC = () => (
  <>
    <path d="M32 0H0V20H32V0Z" fill="#F7FBFF" />
    <path
      d="M11.1822 8.29281C12.0697 5.70887 15.2203 4.4169 17.794 5.55294C20.3233
      6.66671 21.5435 9.87436 20.3233 12.1464C20.1236 11.946 20.168 11.7009
      20.168 11.4559C20.1458 10.4981 19.5467 9.60705 18.6592 9.31747C17.9049
      9.07244 16.7955 9.13927 16.1077 9.98573C15.908 10.2308 15.7084 10.4535
      15.5087 10.6763C14.6878 11.4782 13.4453 11.6787 12.4469 11.0772C11.6038
      10.5872 10.8051 9.54022 11.1822 8.29281Z"
      fill="#D22839"
    />
    <path
      d="M11.1822 8.29272C11.2044 9.80744 11.7591 10.743 12.824 11.1217C14.0443
      11.5449 14.954 11.2108 15.9302 9.98564C16.5958 9.16146 17.7717 8.82732
      18.748 9.18373C19.8795 9.60696 20.3898 10.5202 20.3233 12.1463C19.4358
      14.5744 16.4406 15.7995 13.9112 14.7748C11.3597 13.7502 10.1173 10.7653
      11.1822 8.29272Z"
      fill="#103469"
    />
    <path
      d="M8.08082 2.12122C8.33479 2.29438 8.51949 2.42425 8.77346 2.57577C6.9495
      5.12988 7.71137 4.04763 5.91051 6.5801C5.65654 6.42858 5.47183 6.29871
      5.21786 6.1472C6.16447 4.8052 7.11112 3.48486 8.08082 2.12122ZM9.83547
      3.24676C9.58151 3.09525 9.3968 2.94373 9.14283 2.79222C8.17314 4.15585
      7.22655 5.4762 6.27994 6.83984C6.53391 6.99135 6.7417 7.12122 6.97258
      7.27274C7.94228 5.93074 8.86578 4.6104 9.83547 3.24676ZM7.36506
      7.53248C7.61903 7.68399 7.82685 7.81386 8.08082 7.94373C9.05051 6.60174
      9.97401 5.28139 10.9437 3.91776C10.7128 3.76624 10.505 3.63637 10.2511
      3.48486C9.25828 4.84849 8.33476 6.16884 7.36506 7.53248Z"
      fill="black"
    />
    <path
      d="M8.75036 17.4244C8.49639 17.5975 8.31169 17.7274 8.05772
      17.8789C7.08802 16.5153 6.16452 15.1949 5.19482 13.8529C5.4257 13.7014
      5.61039 13.5715 5.86436 13.3984C6.85714 14.7404 7.78066 16.0607 8.75036
      17.4244ZM8.05772 12.0564C7.80375 12.2079 7.59596 12.3378 7.36508
      12.4893C8.33478 13.8529 9.28142 15.1733 10.228 16.5153C10.4589 16.3638
      10.6667 16.2339 10.9207 16.0824C9.97406 14.7404 9.02742 13.42 8.05772
      12.0564ZM7.5729 15.0001C7.80378 14.8486 8.03467 14.7187 8.26555
      14.5456C7.82687 13.9395 7.41127 13.3335 6.97259 12.7274C6.71862 12.8789
      6.51083 13.0088 6.27995 13.1603C6.71862 13.788 7.13423 14.3724 7.5729
      15.0001ZM9.16596 17.2079C9.44301 17.0348 9.65081 16.9049 9.8586
      16.7534C9.41993 16.1257 8.98126 15.5196 8.54259 14.9135C8.28862 15.0867
      8.08083 15.1949 7.84995 15.3681C8.28862 15.9958 8.7042 16.5802 9.16596
      17.2079Z"
      fill="black"
    />
    <path
      d="M25.72 6.83994C25.4891 6.99145 25.3044 7.12132 25.0274 7.29448C24.0808
      5.95249 23.1342 4.63215 22.1645 3.29015C22.3954 3.13864 22.58 2.98712
      22.834 2.81396C23.8037 4.15596 24.7503 5.4763 25.72 6.83994ZM25.2352
      3.96115C24.7965 3.33345 24.3809 2.74903 23.9191 2.14297C23.6652 2.31613
      23.4574 2.46764 23.2496 2.61916C23.6883 3.24686 24.1039 3.80964 24.5426
      4.43734C24.7965 4.24254 25.0043 4.11267 25.2352 3.96115ZM26.1125
      6.60184C26.3665 6.42868 26.5743 6.29881 26.8051 6.1473C26.3434 5.51959
      25.9278 4.93517 25.4891 4.30747C25.2352 4.48063 25.0505 4.6105 24.7965
      4.76202C25.2582 5.38972 25.6738 5.97414 26.1125 6.60184ZM23.3419
      5.67111C23.088 5.82262 22.8802 5.95249 22.6493 6.10401C23.088 6.73171
      23.5036 7.31613 23.9422 7.94383C24.1962 7.79232 24.3809 7.68409 24.658
      7.51093C24.1962 6.88323 23.7806 6.29881 23.3419 5.67111ZM23.088
      5.30314C22.6262 4.65379 22.2107 4.06938 21.772 3.46331C21.4949 3.63648
      21.3102 3.76634 21.0793 3.91786C21.518 4.52392 21.9336 5.12998 22.3954
      5.75769C22.6724 5.58452 22.8571 5.45466 23.088 5.30314Z"
      fill="black"
    />
    <path
      d="M25.4892 15.6928C25.2352 15.5196 25.0505 15.4114 24.7965
      15.2599C25.2352 14.6538 25.6508 14.0477 26.0895 13.42C26.3434 13.5715
      26.5512 13.7014 26.8052 13.8529C26.3665 14.4806 25.9509 15.0651 25.4892
      15.6928ZM24.5426 15.6062C24.1039 16.2339 23.6883 16.8183 23.2496
      17.446C23.5036 17.5975 23.7114 17.749 23.9423 17.9006C24.404 17.2728
      24.7966 16.6884 25.2352 16.0607C25.0044 15.8876 24.7735 15.7577 24.5426
      15.6062ZM25.0274 12.7274C24.5887 13.3335 24.127 13.9612 23.7114
      14.5456C24.0577 14.7837 24.1963 14.8486 24.4272 15.0001C24.8427 14.3941
      25.2814 13.8096 25.7432 13.1603C25.4892 13.0088 25.2814 12.8789 25.0274
      12.7274ZM24.658 12.4893C24.4041 12.3378 24.1962 12.2079 23.9423
      12.0564C23.5036 12.6841 23.088 13.2685 22.6493 13.8962C22.8802 14.0477
      23.088 14.1776 23.342 14.3291C23.7806 13.7014 24.1963 13.117 24.658
      12.4893ZM22.1645 16.7534C22.3954 16.9049 22.6032 17.0564 22.8572
      17.2079C23.2958 16.5802 23.7345 15.9741 24.1732 15.3681C23.9423 15.1949
      23.7114 15.0867 23.4805 14.9352C23.0188 15.5412 22.6032 16.1257 22.1645
      16.7534ZM23.0649 14.6754C22.811 14.5239 22.6032 14.3941 22.3723
      14.2425C21.9336 14.8703 21.4949 15.4547 21.0563 16.0607C21.2872 16.2122
      21.4949 16.3421 21.7489 16.4936C22.2338 15.8876 22.6493 15.3032 23.0649
      14.6754Z"
      fill="black"
    />
  </>
);
