import { SetStateAction, Dispatch as ReactDispatch } from "react";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import mediator from "@tvg/mediator";
import { PaymentType } from "@tvg/ts-types/Payment";
import { Dispatch } from "@reduxjs/toolkit";
import { setSelectedPaymentMethod } from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import { buildColor } from "@tvg/design-system";
import {
  isCardExpired,
  isDesktop,
  sortByLastUsed,
  isAccountUnderMaintenance
} from "@tvg/wallet/src/utils";
import {
  ErrorMessage,
  ButtonAction,
  PaymentTypeOption,
  PaymentType as PaymentTypeStrings
} from "../../../types";
import { refetchAvailableMethodsArgs } from "./types";

export const paymentMethodWithoutSummary = [
  PaymentType.ECHECK,
  PaymentType.MAZOOMA
];

export const getDisabledColor = (
  isDisabled: boolean,
  variation: "200" | "600"
) =>
  isDisabled
    ? buildColor("grey", variation)
    : buildColor("blue_accent", variation);

export const doLogout = () => {
  mediator.base.dispatch({ type: "TVG_LOGIN:DO_LOGOUT" });
};

export const replaceStringTokenByComponent = (
  string: string,
  token: string,
  component: React.Component | JSX.Element | string
) => {
  if (string.includes(token)) {
    const textParts = string.split(token);
    return [textParts[0], component, textParts[1]];
  }
  return string;
};

export const getSelectionEvent = (callback: () => void) => {
  if (isDesktop) {
    return { onFocus: () => callback() };
  }
  return { onClick: () => callback() };
};

export const openLivePerson = (shouldLogout?: boolean) => {
  if (shouldLogout) {
    doLogout();
  }
  openLiveChat();
};

export const getErrorMessageInitialState = (
  title: string,
  description: string
) => ({
  isOpen: false,
  errorCode: "",
  type: "error" as "success" | "error" | "warning" | "info",
  title,
  description,
  actions: [] as Array<ButtonAction>,
  isRetry: false,
  retryCount: 0,
  paypalRetryMethod: () => ({})
});

export const refetchAvailableMethodsForQuickDeposits = async (
  dispatch: Dispatch,
  accountId: string,
  errorMessageInitialState: ErrorMessage,
  isMZMToggleOn: boolean,
  isRedirectFromMZM: boolean,
  refetchAvailableMethods: (arg?: refetchAvailableMethodsArgs) => Promise<{
    accountsOnFile: PaymentTypeOption[];
    paymentMethodsUnderMaintenance: PaymentTypeStrings[];
  } | null>,
  setErrorMessage: ReactDispatch<SetStateAction<ErrorMessage>>,
  setHasError: ReactDispatch<SetStateAction<boolean>>,
  setHasLastUsed: ReactDispatch<SetStateAction<boolean>>,
  setIsLoadingQuickDeposit: ReactDispatch<SetStateAction<boolean>>
) => {
  const response = await refetchAvailableMethods({
    accountNumber: accountId,
    setIsLoading: setIsLoadingQuickDeposit,
    setHasError
  });

  if (
    response &&
    response.accountsOnFile &&
    response.accountsOnFile.length > 0 &&
    response.paymentMethodsUnderMaintenance
  ) {
    setErrorMessage(errorMessageInitialState);

    const [lastUsed] = response.accountsOnFile
      .filter(
        (method: PaymentTypeOption) =>
          !isAccountUnderMaintenance(
            response.paymentMethodsUnderMaintenance,
            method
          )
      )
      .filter(
        (method: PaymentTypeOption) =>
          method.paymentType !== PaymentType.SIGHTLINE &&
          (isMZMToggleOn || method.paymentType !== PaymentType.MAZOOMA)
      )
      .filter(
        (acc) => acc.depositsAvailable && !isCardExpired(acc.expirationDate)
      )
      .sort((a: PaymentTypeOption, b: PaymentTypeOption) =>
        sortByLastUsed(a.lastUsedDateTime!, b.lastUsedDateTime!)
      );

    if (!lastUsed) {
      setHasLastUsed(false);
    }

    if (!isRedirectFromMZM) {
      const payload = lastUsed
        ? {
            id: lastUsed.id,
            paymentType: lastUsed.paymentType,
            title: lastUsed.title,
            subTitle: lastUsed.description,
            description: "",
            depositsAvailable: lastUsed.depositsAvailable,
            withdrawalsAvailable: lastUsed.withdrawalsAvailable,
            maxLimit: lastUsed.maxLimit,
            minLimit: lastUsed.minLimit
          }
        : {
            id: "",
            paymentType: "",
            title: "",
            subTitle: "",
            description: "",
            depositsAvailable: false,
            withdrawalsAvailable: false,
            minLimit: 0,
            maxLimit: 99999
          };

      dispatch(setSelectedPaymentMethod(payload));
    }
  }
};

export const getParsedDescription = (
  description: string,
  key: string,
  value: string | number
) =>
  replaceCAPIVariables(description, {
    [key]: `<strong>${value}</strong>`
  });

export const onAddPeriod =
  (value: string, setValue: ReactDispatch<SetStateAction<string>>) => () => {
    if (!value.includes(".")) {
      setValue((prevValue: string) =>
        value.charAt(0) === "" ? prevValue.concat("0.") : prevValue.concat(".")
      );
    }
  };
