import { FontWeights, Fonts, FontSizes, LineHeights } from "../../types";

export const fonts: Fonts = {
  condensedRegular: "RobotoCondensed-Regular",
  regular: "Roboto-Regular",
  medium: "Roboto-Medium",
  bold: "Roboto-Bold"
};

export const fontSizes: FontSizes = {
  xs: "12px",
  s: "14px",
  m: "16px",
  l: "18px",
  xl: "20px",
  "2xl": "24px",
  "3xl": "28px",
  "4xl": "32px"
};

export const lineHeights: LineHeights = {
  xs: "15px",
  s: "18px",
  m: "20px",
  l: "22px",
  xl: "25px",
  "2xl": "30px",
  "3xl": "35px",
  "4xl": "40px"
};

export const lineHeightsShorter = {
  xs: "13px",
  s: "15px"
};

export const lineHeightsTaller = {
  xs: "18px",
  s: "21px"
};

export const fontWeights: FontWeights = {
  normal: 400,
  medium: 500,
  bold: 700
};
