import React from "react";
import { Container } from "./styled-components";
import { Props } from "../types";
import Base from "../Base";
import RaceDetail from "../components/RaceDetail";
import { buildQALabel } from "../utils";
import { CONTAINER, RACE_CELL, SUMMARY } from "../constants";

const Summary = ({ race, qaLabel = "", ...props }: Props) => (
  <Container
    data-qa-label={buildQALabel([qaLabel, RACE_CELL, SUMMARY, CONTAINER])}
  >
    <RaceDetail
      video={race.video}
      mtp={race.mtp}
      postTime={race.postTime}
      status={race.status}
    />
    <Base race={race} {...props} />
  </Container>
);

export default Summary;
