import { useEffect, useState } from "react";
import { get } from "lodash";
import { FundsModalContent, PaymentTypeItem } from "@tvg/wallet/src/types";
import { PaymentType } from "@tvg/ts-types/Payment";
import { OtherPaymentTab } from "@tvg/wallet/src/components/FundsModal/common/types";
import { useSelector } from "react-redux";
import {
  getDepositFundsModal,
  getOtherPaymentsContent
} from "@tvg/sh-lib-paws/redux/selectors";
import { Store } from "@reduxjs/toolkit";

interface OtherPaymentsContent {
  title: string;
  tabs: OtherPaymentTab[];
}

export const useOtherPaymentsModal = (otherMethods: PaymentTypeItem[]) => {
  const content: OtherPaymentsContent = useSelector(getOtherPaymentsContent);
  const allTabsContent: OtherPaymentTab[] = get(content, "tabs", []);

  const {
    isOtherPaymentDepositModalOpen,
    otherDepositsPaymentType
  }: {
    isOtherPaymentDepositModalOpen: boolean;
    otherDepositsPaymentType: PaymentType;
  } = useSelector<Store, FundsModalContent>(getDepositFundsModal);

  const [value, setValue] = useState<number>(0);
  const [tabsContent, setTabsContent] =
    useState<OtherPaymentTab[]>(allTabsContent);

  const getFilteredTabsContent: () => OtherPaymentTab[] = () => {
    const otherPaymentMethodsWithDepositsAvailable = otherMethods.filter(
      ({ paymentType, depositsAvailable, withdrawalsAvailable }) =>
        (depositsAvailable || withdrawalsAvailable) &&
        (paymentType === "CHK" ||
          paymentType === "WTRN" ||
          paymentType === "CASH")
    );

    return allTabsContent.reduce((filtered, tab) => {
      const tabContentFoundOnOtherPM =
        otherPaymentMethodsWithDepositsAvailable.find(
          ({ paymentType }) => paymentType === tab.method
        );
      const priority = otherPaymentMethodsWithDepositsAvailable.find(
        ({ paymentType }) => paymentType === tab.method
      )?.priority;

      if (tabContentFoundOnOtherPM) {
        filtered.push({
          ...tab,
          priority: priority || 9999
        });
      }

      return filtered.sort((a, b) => (a.priority > b.priority ? 1 : -1));
    }, [] as OtherPaymentTab[]);
  };

  // since the component is always rendered for because of the modal we need this useEffect because the useState was already initialized with the default
  useEffect(() => {
    const filteredTabsContent = getFilteredTabsContent();
    const index = filteredTabsContent.findIndex(
      (item) => item.method === otherDepositsPaymentType
    );

    setValue(index);
    setTabsContent(filteredTabsContent);
  }, [otherDepositsPaymentType, otherMethods, isOtherPaymentDepositModalOpen]);

  return {
    value,
    setValue,
    isOpen: isOtherPaymentDepositModalOpen,
    content: {
      ...content,
      tabs: tabsContent
    }
  };
};

export default useOtherPaymentsModal;
