import React from "react";
import { Path } from "react-native-svg";
import { useTheme } from "../../theming";

export const PathIllustration = () => {
  const { colors } = useTheme();

  return (
    <>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.54017 0.576019L0 17.3625L27.7691 17.364L32.31 0.578247L4.54017 0.576019Z"
        fill={colors.green[500]}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8947 2.43442L23.1079 3.43632L22.9995 3.46009C11.6449 5.9719 5.17597 15.3151 5.11284 15.4094L5.04674 15.506L4.17777 14.8539L4.2409 14.7655C11.717 4.34093 22.6801 2.46932 22.79 2.4515L22.8947 2.43442ZM20.6704 10.4827C21.073 10.9261 21.6671 11.137 22.4254 11.1206L22.5376 11.1177L22.5561 12.0156L22.4544 12.026C22.441 12.0275 22.3593 12.0356 22.2293 12.0356C21.7971 12.0356 20.8338 11.9473 20.0963 11.232C19.3937 10.5525 19.0899 9.4919 19.1939 8.08077L19.2028 7.96713L20.074 8.04957L20.0599 8.16321L20.0598 8.16435C20.0545 8.21268 19.8999 9.63513 20.6704 10.4827ZM21.984 4.46323L21.34 5.14131L26.7692 11.1342L25.4583 12.9842L26.178 13.5643L27.965 11.0569L21.984 4.46323ZM2.14371 9.55649H6.79826V10.5079H1.88674L2.14371 9.55649ZM13.1147 4.53061H3.50626L3.25003 5.46715H13.1147V4.53061ZM2.82164 7.05112H9.61296V7.98766H2.56838L2.82164 7.05112Z"
        fill={colors.white[900]}
      />
    </>
  );
};
