import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import {
  fontNormal,
  fontBold,
  fontMedium
} from "@tvg/atomic-ui/_static/Typography";
import DefaultButton from "@tvg/atomic-ui/_atom/Buttons/default";
import Button from "@tvg/atomic-ui/_atom/Buttons/button";

const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const sharedFontStyles = css`
  font-size: 14px;
  line-height: 18px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${buildColor("white", "100")};
  border-radius: 4px;
  position: relative;
  padding: 12px 0;
`;

export const CloseButton = styled(DefaultButton)`
  ${flexCenter};
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  border: none;
  background: transparent;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4px 12px 0 12px;
`;

export const Title = styled.h1`
  font-family: ${fontBold};
  color: ${buildColor("grey", "900")};
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
`;

export const Text = styled.span`
  ${sharedFontStyles};
  font-family: ${fontNormal};
  color: ${buildColor("grey", "800")};
  margin-top: 8px;
`;

export const CardsContainer = styled.div`
  padding: 28px 12px 16px 12px;
  display: flex;

  & > button {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const DetailsContainer = styled.div`
  border-top: 1px solid ${buildColor("blue", "100")};
  padding: 16px 12px 0 12px;
`;

export const ListItem = styled.li`
  list-style-type: initial;
  list-style-position: outside;
  margin-left: 24px;
  color: ${buildColor("grey", "800")};
`;

export const DetailsTitle = styled.p`
  ${sharedFontStyles};
  font-family: ${fontMedium};
  color: ${buildColor("grey", "900")};
  margin-bottom: 8px;

  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const DetailsText = styled.p<{ mb?: number }>`
  ${sharedFontStyles};
  font-family: ${fontNormal};
  color: ${buildColor("grey", "800")};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : 0)};
`;

export const StyledButton = styled(Button)`
  ${sharedFontStyles};
  ${flexCenter};
  height: 44px;
  width: 100%;
  padding: 0 14px;
  box-shadow: 0 2px 4px rgba(17, 43, 68, 0.14);
  text-transform: initial;
  margin-top: 16px;
`;
