import tvgConf from "@tvg/conf";

export const STORYBLOK_CONTENT_PATH = "promo-onboarding";
export const STORYBLOK_URL = "https://api.storyblok.com/v1/cdn/stories";
export const STORYBLOK_SPACES_URL = `https://api.storyblok.com/v1/cdn/spaces/${STORYBLOK_CONTENT_PATH}`;

export const {
  storyblokToken: STORYBLOK_TOKEN,
  storyblokVersion: STORYBLOK_VERSION
} = tvgConf().config();
