// @flow
// $FlowFixMe
import { useCallback, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  closePaymentMethodSelectorModal,
  setSelectedPaymentMethod
} from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import { closeDepositFundsModal } from "@tvg/sh-lib-paws/redux/slices/depositFundsModalSlice";
import { getSelectedPaymentMethod } from "@tvg/sh-lib-paws/redux/selectors";
import type { NullaryFn } from "@tvg/types/Functional";
import { PaymentTypeOption } from "@tvg/wallet/src/types";
import {
  gtmPaymentSelectorAddMethod,
  gtmPaymentSelectorChangeMethod
} from "@tvg/wallet/src/gtm";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import { toggleQuickDepositModal } from "@tvg/sh-lib-paws/redux/slices/quickDepositModalSlice";
import { getIdentifier } from "./functions";

const closePMSelector = (dispatch) => {
  dispatch(closePaymentMethodSelectorModal());
};

const useActions = (
  isDeposit: boolean,
  scrollToBottom?: NullaryFn<void>,
  onCloseDepositModal?: NullaryFn<void>,
  onChangePaymentMethod?: NullaryFn<void>
) => {
  const dispatch = useDispatch();
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod);
  const [selectedMethod, setSelectedMethod] = useState(
    selectedPaymentMethod.title
      ? getIdentifier(
          selectedPaymentMethod.title,
          selectedPaymentMethod.subTitle
        )
      : null
  );

  const returnToWallet = useCallback(() => {
    const isDesktop = tvgConf().device === "desktop";
    const route = "/wallet";

    batch(() => {
      dispatch(closePaymentMethodSelectorModal());
      dispatch(toggleQuickDepositModal(false));
      dispatch(closeDepositFundsModal());
    });

    if (typeof scrollToBottom === "function") {
      scrollToBottom();
    }

    if (typeof onCloseDepositModal === "function") {
      onCloseDepositModal();
    }

    if (
      isDesktop &&
      typeof window !== "undefined" &&
      window.location.pathname !== "/wallet"
    ) {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route, event: "Wallet" }
      });
    }
  }, []);

  const handleAddPaymentMethod = useCallback(() => {
    gtmPaymentSelectorAddMethod({
      currentPaymentType: selectedPaymentMethod.paymentType
    });
    returnToWallet();
  }, [selectedPaymentMethod.paymentType]);

  const handleAddPaymentFromWagerpad = useCallback(() => {
    gtmPaymentSelectorAddMethod({
      currentPaymentType: selectedPaymentMethod.paymentType
    });
    batch(() => {
      dispatch(closePaymentMethodSelectorModal());
      dispatch(toggleQuickDepositModal(false));
      dispatch(closeDepositFundsModal());
    });
    if (window !== "undefined") {
      window.open(`${window.location.origin}/wallet`, "noopener, noreferrer");
    }
  }, [selectedPaymentMethod.paymentType]);

  const onClick = useCallback(
    (method: PaymentTypeOption, identifier: string) => {
      gtmPaymentSelectorChangeMethod({
        currentPaymentType: selectedPaymentMethod.paymentType,
        selectedPaymentType: method.paymentType
      });
      const isDesktop = tvgConf().device === "desktop";
      const path = isDeposit ? "deposit" : "withdraw";
      // TODO: Remove this when SLP is available on PAWS Wallet
      if (method.paymentType === "SLP") {
        location.href = tvgConf().buildUrl({
          app: isDesktop ? "tvg4" : "dma",
          path: isDesktop ? `/my-funds/${path}` : path,
          wrapper: !isDesktop,
          callback: !isDesktop,
          params: {
            ...(!isDesktop ? { fullPage: true } : {}),
            initialTab: "SLN",
            paymentId: method.id
          }
        });
      } else {
        dispatch(
          setSelectedPaymentMethod({
            ...method,
            description: "",
            subTitle: method.description
          })
        );
        setSelectedMethod(identifier);
        if (typeof onChangePaymentMethod === "function") {
          onChangePaymentMethod();
        }
      }
      closePMSelector(dispatch);
    },
    [isDeposit]
  );

  return {
    onClick,
    returnToWallet,
    selectedMethod,
    handleAddPaymentMethod,
    handleAddPaymentFromWagerpad
  };
};

export default useActions;
