import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontMedium } from "@tvg/atomic-ui/_static/Typography";

export const Button = styled.button<{
  isLoading?: boolean;
  bgColor?: string;
  isDisabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
  height: 46px;
  background-color: ${({ bgColor }) => bgColor || buildColor("green", "500")};
  box-shadow: 0 2px 4px rgba(17, 43, 68, 0.06), 0 1px 2px rgba(17, 43, 68, 0.08);
  border-radius: 4px;
  color: ${buildColor("white", "100")};
  font-size: 14px;
  line-height: 18px;
  font-family: ${fontMedium};

  &:active {
    ${({ isDisabled, bgColor }) =>
      isDisabled
        ? ""
        : css`
            background-color: ${bgColor || buildColor("green", "300")};
          `}
  }

  &:disabled {
    opacity: 0.4;
  }

  ${({ isLoading, bgColor }) =>
    isLoading &&
    css`
      background-color: ${bgColor || buildColor("green", "300")};
    `}
`;
