import styled, { css } from "styled-components";

export const Column = styled.div<{ addMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ addMargin }) =>
    addMargin &&
    css`
      margin-right: ${({ theme }) => theme.space["space-3"]};
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 18px;
`;

export const CanceledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WinnerRow = styled.div<{ center?: boolean }>`
  display: flex;
  flex-direction: row;
  width: min-content;
  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `}
`;

export const Row = styled.div<{ center?: boolean }>`
  display: flex;
  flex-direction: row;
  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `}
`;
