// @flow
import React, { Fragment, type Node } from "react";
import { noop } from "lodash";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import {
  Container,
  ExpandButton,
  VolumeButton,
  FullUnmuteButton,
  PlayButton,
  FeedBackContainer,
  LeftCornerOverlay,
  PlayButtonLabel,
  InnerPlayButtonWrapper,
  SkipButton,
  WatchButton
} from "./styled-components";
import Icon from "../../../_static/Icons";

import buildColor from "../../../_static/ColorPalette";
import {
  fullscreen,
  fullscreenExit,
  soundMute,
  soundPlay,
  info,
  play,
  videoReplay,
  refresh,
  pause,
  doubleArrowLeft,
  doubleArrowRight
} from "../../../_static/Icons/icons";
import Text from "../../../_atom/Text";
import FeedbackOverlay from "../../../_atom/FeedbackOverlay";

type VideoSkippingActionType = "fwd" | "rew";

type Props = {
  /**
   * Show video controlers
   */
  showControls: boolean,
  /**
   * Handle click over video container
   */
  clickHandler: UnaryFn<boolean, mixed>,
  /**
   * Is video playing
   */
  playing: boolean,
  /**
   * Click on play video
   */
  playHandler: NullaryFn<mixed>,
  /**
   * Click on skip video (fwd or rew)
   */
  skipToggleHandler: UnaryFn<VideoSkippingActionType, mixed>,
  /**
   * Flag to signal if forward skipping button is visible
   */
  fwdButtonIsVisible: boolean,
  /**
   * Flag to signal if rewind skipping button is visible
   */
  rewButtonIsVisible: boolean,
  /**
   * Is video muted
   */
  muted: boolean,
  /**
   * Is video has full unmute button
   */
  isFullUnmute: boolean,
  /**
   * Click on mute video button
   */
  muteHandler: NullaryFn<mixed>,
  /**
   * Is video fullscreen
   */
  fullscreen: boolean,
  /**
   * Handle fullscreen click
   */
  fullscreenHandler: NullaryFn<mixed>,
  /**
   * Is video ended
   */
  ended: boolean,
  /**
   * Initial load of video
   */
  loading: boolean,
  /**
   * Is video buffering
   */
  buffering: boolean,
  /**
   * Overlay over the video
   */
  renderOnOverlay: UnaryFn<boolean, Node>,
  /**
   * Is an error present
   */
  error: boolean,
  /**
   *
   */
  liveButtonCallback: UnaryFn<SyntheticEvent<HTMLButtonElement>, void>,
  /**
   * Is replay video
   */
  isReplay?: boolean,
  /**
   * Has live stream
   */
  hasLiveStream?: boolean,
  /**
   * Has replay stream
   */
  hasReplayStream?: boolean,
  /**
   * new stream video
   */
  hasNewStreamVideo: boolean,
  /**
   * remove fullscreen button in overlay controls
   */
  disableFullscreen: boolean
};

class OverlayControls extends React.Component<Props> {
  static defaultProps = {
    renderOnOverlay: noop,
    skipToggleHandler: noop,
    liveButtonCallback: noop,
    showControls: true,
    loading: false,
    isReplay: false,
    hasLiveStream: false,
    hasReplayStream: false,
    isFullUnmute: false,
    fwdButtonIsVisible: false,
    rewButtonIsVisible: false,
    hasNewStreamVideo: false,
    disableFullscreen: false
  };

  playIcon = () => {
    let returnIcon = play;

    if (!this.props.playing && this.props.isReplay && this.props.ended) {
      returnIcon = refresh;
    } else if (!this.props.playing && this.props.isReplay) {
      returnIcon = videoReplay;
    } else if (this.props.playing) {
      returnIcon = pause;
    }

    return returnIcon;
  };

  playButtonLabel = () => (this.props.ended ? "RESTART" : "REPLAY");

  renderPlayButton = () => {
    const isEndOfReplay = this.props.ended && this.props.isReplay;
    const hasntEnded = !this.props.ended;
    const hasNoErrorsLoading = !this.props.loading && !this.props.error;
    const showPlayButton =
      (isEndOfReplay || hasntEnded) &&
      (hasNoErrorsLoading ||
        (!this.props.isReplay && this.props.hasNewStreamVideo));
    return (
      showPlayButton && (
        <PlayButton type="shadow" onClick={this.props.playHandler}>
          <InnerPlayButtonWrapper>
            <Icon
              size={this.props.isReplay && !this.props.playing ? 16 : 24}
              icon={this.playIcon()}
              color={buildColor("white", "100")}
            />
            {this.props.isReplay && !this.props.playing && (
              <PlayButtonLabel>{this.playButtonLabel()}</PlayButtonLabel>
            )}
          </InnerPlayButtonWrapper>
        </PlayButton>
      )
    );
  };

  renderWatchButton = () => {
    const buttonLabel = this.props.isReplay ? "WATCH LIVE" : "WATCH REPLAY";
    return (
      <WatchButton
        className={this.props.isReplay ? "replay" : ""}
        onClick={this.props.liveButtonCallback}
      >
        {buttonLabel}
      </WatchButton>
    );
  };

  renderSkipButtonLabel = (actionType: VideoSkippingActionType): Node =>
    actionType === "fwd" ? (
      <Fragment>
        <span>15s</span>
        <Icon
          size={16}
          icon={doubleArrowRight}
          color={buildColor("white", "100")}
        />
      </Fragment>
    ) : (
      <Fragment>
        <Icon
          size={16}
          icon={doubleArrowLeft}
          color={buildColor("white", "100")}
        />
        <span>15s</span>
      </Fragment>
    );

  renderVideoOptions = () => (
    <Fragment>
      <LeftCornerOverlay>
        {this.props.isFullUnmute &&
        !this.props.loading &&
        this.props.playing ? (
          <FullUnmuteButton
            type="secondary"
            qaLabel="full-unMute-btn"
            onClick={this.props.muteHandler}
          >
            <Icon size={16} icon={soundMute} />
            <span>tap to unmute</span>
          </FullUnmuteButton>
        ) : (
          <VolumeButton type="shadow" onClick={this.props.muteHandler}>
            <Icon
              size={16}
              icon={this.props.muted ? soundMute : soundPlay}
              color={buildColor("white", "100")}
            />
          </VolumeButton>
        )}
        {!this.props.disableFullscreen && (
          <ExpandButton type="shadow" onClick={this.props.fullscreenHandler}>
            <Icon
              size={16}
              icon={this.props.fullscreen ? fullscreenExit : fullscreen}
              color={buildColor("white", "100")}
            />
          </ExpandButton>
        )}
      </LeftCornerOverlay>
      {/* this need to be replaced by feedback component */}
      {((!this.props.isReplay && this.props.ended) ||
        (this.props.error &&
          (this.props.isReplay || !this.props.hasNewStreamVideo))) && (
        <FeedBackContainer>
          <Icon icon={info} color={buildColor("white", "100")} size={16} />
          <Text fontSize={12} color={buildColor("white", "100")}>
            The streaming is not available
          </Text>
        </FeedBackContainer>
      )}
      {this.props.hasLiveStream &&
        this.props.hasReplayStream &&
        this.renderWatchButton()}
      {this.renderPlayButton()}
      {this.props.isReplay && (
        <Fragment>
          {this.props.rewButtonIsVisible && (
            <SkipButton
              className="rewButton"
              qaLabel="videoRewButton"
              onClick={this.props.skipToggleHandler("rew")}
            >
              {this.renderSkipButtonLabel("rew")}
            </SkipButton>
          )}

          {this.props.fwdButtonIsVisible && (
            <SkipButton
              className="fwdButton"
              qaLabel="videoFwdButton"
              onClick={this.props.skipToggleHandler("fwd")}
            >
              {this.renderSkipButtonLabel("fwd")}
            </SkipButton>
          )}
        </Fragment>
      )}
    </Fragment>
  );

  render() {
    const showOverLay =
      (this.props.buffering && !this.props.error) || this.props.loading;
    return (
      <Container
        onClick={() => this.props.clickHandler(true)}
        isVisible={this.props.showControls}
      >
        {this.props.showControls && this.renderVideoOptions()}
        {showOverLay &&
          (this.props.isReplay || !this.props.hasNewStreamVideo) && (
            <FeedbackOverlay />
          )}
        {this.props.renderOnOverlay(this.props.showControls)}
      </Container>
    );
  }
}

export default OverlayControls;
