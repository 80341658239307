import { useEffect, useRef } from "react";

export const usePreviousValue = (
  value: React.ReactNode[] | JSX.Element[]
): React.ReactNode[] | JSX.Element[] => {
  const ref = useRef<React.ReactNode[] | JSX.Element[]>([]);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
