import styled, { css } from "styled-components";
import {
  space,
  width,
  color,
  borderColor as bdColor,
  compose
} from "../../../src/theming/styledSystem";
import { Theme } from "../../../src/theming/types";
import { StyledInputProps } from "./types";

const backgroundColors = (theme: Theme) => ({
  def: theme.colorTokens.component.input.surface,
  disabled: theme.colorTokens.component.input.disabled
});

const borderColors = (theme: Theme) => ({
  def: theme.colorTokens.component.input.border,
  focused: theme.colorTokens.component.input.active,
  invalid: theme.colorTokens.background.warning
});

const borderDefault = (theme: Theme) => ({
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "2px",
  borderColor: borderColors(theme).def
});

const cssBorder = ({
  theme,
  isInvalid,
  isDisabled,
  isFocused
}: {
  theme: Theme;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isFocused?: boolean;
}) => {
  const { borderWidth, borderStyle, borderColor, borderRadius } =
    borderDefault(theme);
  const { invalid, focused } = borderColors(theme);
  const borderDefaultStyle = css`
    border: ${borderWidth} ${borderStyle} ${borderColor};
    border-radius: ${borderRadius};
  `;

  if (isDisabled) {
    return borderDefaultStyle;
  }

  if (isInvalid) {
    return css`
      ${borderDefaultStyle}
      border-color: ${invalid};
      border-width: 2px;

      &:focus {
        border-color: ${focused};
      }

      ${isFocused &&
      css`
        border-color: ${borderColors(theme).focused};
      `}
    `;
  }

  return css`
    ${borderDefaultStyle}

    &:focus {
      border-width: 2px;
      border-color: ${focused};
    }

    ${isFocused &&
    css`
      && {
        border-width: 2px;
        border-color: ${borderColors(theme).focused};
      }
    `}
  `;
};

const cssBoxShadow = ({
  theme,
  isDisabled
}: {
  theme: Theme;
  isDisabled?: boolean;
}) => {
  if (isDisabled) {
    return css`
      box-shadow: none;
    `;
  }
  return css`
    box-shadow: ${theme.elevations.low};
    &:hover {
      box-shadow: ${theme.elevations.medium};
    }
    &:active {
      box-shadow: none;
    }
  `;
};

const cssBackground = ({
  theme,
  isDisabled
}: {
  theme: Theme;
  isDisabled?: boolean;
}) => {
  const { disabled, def } = backgroundColors(theme);
  if (isDisabled) {
    return css`
      background-color: ${disabled};
    `;
  }

  return css`
    background-color: ${def};
  `;
};

const cssPlaceholder = ({
  theme,
  isDisabled
}: {
  theme: Theme;
  isDisabled: boolean;
}) => {
  if (isDisabled) {
    return css`
      &::placeholder {
        color: ${theme.colorTokens.content.disabled};
      }
    `;
  }

  return css`
    &::placeholder {
      color: ${theme.colorTokens.content.subtle};
    }
  `;
};

const styledUtils = compose(space, width, color, bdColor);

export const InputBox = styled.div<{
  isDisabled?: boolean;
  hasBackground?: boolean;
  hasBorder?: boolean;
  isInvalid?: boolean;
  isFocused?: boolean;
}>`
  display: flex;
  flex-grow: 1;
  width: auto;
  height: 44px;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
  ${({ theme, isDisabled }) => cssBoxShadow({ theme, isDisabled })};
  ${({ theme, hasBorder, isDisabled, isInvalid, isFocused }) =>
    hasBorder && cssBorder({ theme, isInvalid, isDisabled, isFocused })};
  ${({ theme, hasBackground, isDisabled }) =>
    hasBackground && cssBackground({ theme, isDisabled })};
  ${styledUtils};
`;

export const StyledInput = styled.input<StyledInputProps>`
  outline: none;
  flex-grow: 1;
  width: 100%;
  height: ${({ isNested }) => (isNested ? "100%" : "42px")};
  ${({ theme, isInvalid, isDisabled }) =>
    cssBorder({ theme, isInvalid, isDisabled })};
  ${({ theme, isDisabled }) => cssBackground({ theme, isDisabled })};
  ${({ theme, isDisabled }) => cssPlaceholder({ theme, isDisabled })};
  ${({ theme, isDisabled }) => css`
    padding: ${theme.space["space-4"]};
    font-family: ${theme.fonts?.regular};
    font-size: ${theme.fontSizes?.s};
    color: ${isDisabled
      ? theme.colorTokens.content.disabled
      : theme.colorTokens.content.default};
  `}
`;

export const StyledPressable = styled.div`
  align-items: stretch;
  height: 42px;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
`;

export const EndWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.space["space-2"]};
  top: 50%;
  transform: translateY(-50%);
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: center;
`;

export const StyledLabel = styled.span`
  ${space};
  ${({ theme }) => css`
    font-family: ${theme.fonts?.medium};
    font-size: ${theme.fontSizes?.s};
    color: ${theme.colors?.grey[900]};
  `}
`;

export const StyledOptionalTag = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.space["space-1"]};
    font-family: ${theme.fonts?.medium};
    font-size: ${theme.fontSizes?.xs};
    color: ${theme.colors?.grey[700]};
  `}
`;

export const InputWrapper = styled.div(compose(space, width));

export const IconWrapper = styled.div`
  margin-left: ${({ theme }) => theme.space["space-1"]};
`;

export const MessageWrapper = styled.div`
  ${space};
  margin-top: ${({ theme }) => theme.space["space-3"]};
  display: flex;
`;

export const SelectIconWrapper = styled.div<{
  isDisabled?: boolean;
}>`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: ${({ theme }) => theme.space["space-2"]};
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  height: 32px;
  width: 32px;
`;
