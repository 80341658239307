import { openLiveChat } from "@tvg/utils/liveChatUtils";
import { NullaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import { ButtonType, PawsContent } from "../../types";

export const getAction = (
  retriesCount: number,
  { genericErrorMessage }: PawsContent,
  onRetry: NullaryFn<void>,
  setRetryCount: UnaryFn<number, void>
) =>
  retriesCount < 3
    ? {
        text: genericErrorMessage.retryBtn,
        onClick: () => {
          onRetry();
          setRetryCount(retriesCount + 1);
        },
        isStretched: true,
        isUppercase: false,
        qaLabel: "retry",
        type: "primary" as ButtonType
      }
    : {
        text: genericErrorMessage.contactBtn,
        onClick: () => {
          openLiveChat();
          setRetryCount(0);
        },
        isStretched: true,
        isUppercase: false,
        qaLabel: "contactSupport",
        type: "tertiary" as ButtonType
      };
