import { MutableRefObject, useCallback, useEffect, useRef } from "react";

const useDebounce = () => {
  const timeoutRef: MutableRefObject<number> = useRef(0);

  const debounce = useCallback(
    (fn: () => void, delay: number = 100) =>
      () => {
        clearTimeout(timeoutRef.current);
        // @ts-ignore
        timeoutRef.current = setTimeout(() => {
          fn();
        }, delay);
      },
    []
  );

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    []
  );

  return debounce;
};

export default useDebounce;
