import {
  useNavigate as useRouterNavigate,
  useLocation
} from "react-router-dom";

const useNavigate = () => ({
  push: useRouterNavigate(),
  location: useLocation()
});

export default useNavigate;
