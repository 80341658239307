import React from "react";

export const NumberFire = () => (
  <>
    <g clipPath="url(#clip0_20346_7102)">
      <path
        d="M4.7619 20.7286C3.37143 19.1762 2.51428 17.1857 2.51428
  14.9952C2.51428 11.5 4.50476 8.00476 7.00952 7.00476C7.50476
  8.50952 8.26667 10.519 11.4952 10.5C8 3.50952 13.4952 0.0142822
  14.4952 0.0142822C14.4952 3.28095 16.5714 5.5 18.4571 7.9L13.1238
  17.4619L9.02857 13.0809L4.7619 20.7286ZM13.4952 20.4905L9.4
  16.1095L6.12381 21.9857C7.81376 23.2721 9.87619 23.9741 12
  23.9857C16.9905 23.9857 21.4857 19.9857 21.4857 14.9952C21.4857
  12.8048 20.6857 11.0619 19.6286 9.49047L13.4952 20.4905Z"
        fill="url(#paint0_linear_20346_7102)"
      />
      <path
        d="M4.7619 20.7286C3.37143 19.1762 2.51428 17.1857 2.51428
    14.9952C2.51428 11.5 4.50476 8.00476 7.00952 7.00476C7.50476
    8.50952 8.26667 10.519 11.4952 10.5C8 3.50952 13.4952 0.0142822
    14.4952 0.0142822C14.4952 3.28095 16.5714 5.5 18.4571 7.9L13.1238
    17.4619L9.02857 13.0809L4.7619 20.7286ZM13.4952 20.4905L9.4
    16.1095L6.12381 21.9857C7.81376 23.2721 9.87619 23.9741 12
    23.9857C16.9905 23.9857 21.4857 19.9857 21.4857 14.9952C21.4857
    12.8048 20.6857 11.0619 19.6286 9.49047L13.4952 20.4905Z"
        fill="url(#paint1_linear_20346_7102)"
      />
      <path
        d="M8.15238 14.6429L4.7619 20.7286C3.37143 19.1762 2.51428
    17.1857 2.51428 14.9952C2.51428 11.5 4.50476 8.00476 7.00952
    7.00476C5.71428 9.15714 5.91428 11.6714 8.15238 14.6429ZM9.4
    16.1095L6.12381 21.9857L6.35238 22.1571C10.6095 25.1381 13.419
    20.4143 9.4 16.1095V16.1095Z"
        fill="url(#paint2_linear_20346_7102)"
      />
      <path
        d="M19.6286 9.49047C20.6857 11.0619 21.4857 12.8048 21.4857
    14.9952C21.4857 19.9857 16.9905 23.9857 12 23.9857C10.6329 23.9805
    9.28249 23.6851 8.03809 23.119C11.4667 24.6238 13.0857 23.1095
    13.5048 20.4714L19.6286 9.49047ZM18.4571 7.9C16.5714 5.5 14.4952
    3.28095 14.4952 0.0142822C13.4952 0.0142822 7.99999 3.50952 11.4952
    10.5C12.4846 12.4997 13.15 14.6439 13.4667 16.8524L18.4571 7.9Z"
        fill="url(#paint3_linear_20346_7102)"
      />
      <path
        d="M19.6286 9.49047C20.6857 11.0619 21.4857 12.8048 21.4857
    14.9952C21.4857 19.0048 18.581 22.3762 14.8571 23.5476C18.2857 22.4048
    20.6095 17.8429 17.5333 13.2429L19.6286 9.49047ZM16.3524 11.6714L18.4571
    7.9C16.5714 5.5 14.4952 3.28095 14.4952 0.0142822C13.0571 1.92857 11.7619
    5.5 16.3524 11.6714V11.6714Z"
        fill="url(#paint4_linear_20346_7102)"
      />
      <path
        d="M8.15238 14.6429L4.7619 20.7286C3.37143 19.1762 2.51428 17.1857
    2.51428 14.9952C2.51428 11.5 4.50476 8.00476 7.00952 7.00476C5.71428
    9.15714 5.91428 11.6714 8.15238 14.6429ZM9.4 16.1095L6.12381 21.9857L6.35238
    22.1571C10.6095 25.1381 13.419 20.4143 9.4 16.1095V16.1095Z"
        fill="url(#paint5_linear_20346_7102)"
      />
      <path
        d="M19.6286 9.49047C20.6857 11.0619 21.4857 12.8048 21.4857
    14.9952C21.4857 19.9857 16.9905 23.9857 12 23.9857C10.6329 23.9805 9.28249
    23.6851 8.03809 23.119C11.4667 24.6238 13.0857 23.1095 13.5048 20.4714L19.6286
    9.49047ZM18.4571 7.9C16.5714 5.5 14.4952 3.28095 14.4952 0.0142822C13.4952
    0.0142822 7.99999 3.50952 11.4952 10.5C12.4846 12.4997 13.15 14.6439
    13.4667 16.8524L18.4571 7.9Z"
        fill="url(#paint6_linear_20346_7102)"
      />
      <path
        d="M19.6286 9.49047C20.6857 11.0619 21.4857 12.8048 21.4857 14.9952C21.4857
    19.0048 18.581 22.3762 14.8571 23.5476C18.2857 22.4048 20.6095 17.8429 17.5333
    13.2429L19.6286 9.49047ZM16.3524 11.6714L18.4571 7.9C16.5714 5.5 14.4952
    3.28095 14.4952 0.0142822C13.0571 1.92857 11.7619 5.5 16.3524 11.6714V11.6714Z"
        fill="url(#paint7_linear_20346_7102)"
      />
      <path
        d="M21.4857 6.48096C21.4857 6.85226 21.3382 7.20835 21.0757 7.4709C20.8131
    7.73346 20.457 7.88096 20.0857 7.88096C19.7144 7.88096 19.3583 7.73346 19.0958
    7.4709C18.8332 7.20835 18.6857 6.85226 18.6857 6.48096C18.6857 5.48096 19.5905
    5.48096 19.0857 3.98572C21.0857 4.49048 21.4857 5.7381 21.4857 6.48096Z"
        fill="url(#paint8_linear_20346_7102)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_20346_7102"
        x1="14.3971"
        y1="11.979"
        x2="4.91809"
        y2="17.339"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#FFE187" />
        <stop offset="0.24" stopColor="#FFD986" />
        <stop offset="0.33" stopColor="#FFC384" />
        <stop offset="0.44" stopColor="#FFA081" />
        <stop offset="0.55" stopColor="#FF737D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_20346_7102"
        x1="20.2667"
        y1="13.3105"
        x2="9.55333"
        y2="11.4219"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.24" stopColor="#FFE187" />
        <stop offset="0.28" stopColor="#FFE187" stopOpacity="0.95" />
        <stop offset="0.36" stopColor="#FFE187" stopOpacity="0.83" />
        <stop offset="0.46" stopColor="#FFE187" stopOpacity="0.62" />
        <stop offset="0.58" stopColor="#FFE187" stopOpacity="0.34" />
        <stop offset="0.72" stopColor="#FFE187" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_20346_7102"
        x1="6.92666"
        y1="7.00667"
        x2="6.92666"
        y2="23.0914"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.14" stopColor="#FFE187" />
        <stop offset="0.35" stopColor="#FFC084" />
        <stop offset="0.78" stopColor="#FF737D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_20346_7102"
        x1="13.1248"
        y1="0.13333"
        x2="15.1905"
        y2="23.7419"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.25" stopColor="#FFE187" />
        <stop offset="0.33" stopColor="#FFD286" />
        <stop offset="0.67" stopColor="#FF8E7F" />
        <stop offset="0.83" stopColor="#FF737D" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_20346_7102"
        x1="20.44"
        y1="10.1286"
        x2="11.3733"
        y2="12.7286"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.04" stopColor="#FFE187" />
        <stop offset="13%" stopColor="#FFD686" />
        <stop offset="0.29" stopColor="#FFBA83" />
        <stop offset="0.49" stopColor="#FF8B7F" />
        <stop offset="0.59" stopColor="#FF737D" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_20346_7102"
        x1="10.4086"
        y1="13.9848"
        x2="2.87524"
        y2="17.659"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE187" />
        <stop offset="0.03" stopColor="#FFE187" stopOpacity="0.92" />
        <stop offset="0.12" stopColor="#FFE187" stopOpacity="0.71" />
        <stop offset="0.21" stopColor="#FFE187" stopOpacity="0.52" />
        <stop offset="0.31" stopColor="#FFE187" stopOpacity="0.36" />
        <stop offset="0.4" stopColor="#FFE187" stopOpacity="0.23" />
        <stop offset="0.5" stopColor="#FFE187" stopOpacity="0.13" />
        <stop offset="0.6" stopColor="#FFE187" stopOpacity="0.06" />
        <stop offset="0.71" stopColor="#FFE187" stopOpacity="0.01" />
        <stop offset="0.83" stopColor="#FFE187" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_20346_7102"
        x1="20.8686"
        y1="10.7248"
        x2="6.30666"
        y2="13.2924"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#FFE187" />
        <stop offset="0.15" stopColor="#FFE187" stopOpacity="0.8" />
        <stop offset="0.19" stopColor="#FFE187" stopOpacity="0.62" />
        <stop offset="0.23" stopColor="#FFE187" stopOpacity="0.45" />
        <stop offset="0.27" stopColor="#FFE187" stopOpacity="0.31" />
        <stop offset="0.32" stopColor="#FFE187" stopOpacity="0.2" />
        <stop offset="0.37" stopColor="#FFE187" stopOpacity="0.11" />
        <stop offset="0.42" stopColor="#FFE187" stopOpacity="0.05" />
        <stop offset="0.48" stopColor="#FFE187" stopOpacity="0.01" />
        <stop offset="0.57" stopColor="#FFE187" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_20346_7102"
        x1="19.5819"
        y1="21.6152"
        x2="14.5743"
        y2="10.099"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.41" stopColor="#FF737D" />
        <stop offset="0.46" stopColor="#FF737D" stopOpacity="0.95" />
        <stop offset="0.54" stopColor="#FF737D" stopOpacity="0.83" />
        <stop offset="0.65" stopColor="#FF737D" stopOpacity="0.62" />
        <stop offset="0.79" stopColor="#FF737D" stopOpacity="0.34" />
        <stop offset="0.93" stopColor="#FF737D" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_20346_7102"
        x1="18.4324"
        y1="4.64477"
        x2="21.9419"
        y2="8.15429"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#FFE187" />
        <stop offset="0.35" stopColor="#FFC084" />
        <stop offset="0.84" stopColor="#FF737D" />
      </linearGradient>
      <clipPath id="clip0_20346_7102">
        <rect
          width="18.9714"
          height="23.9714"
          fill="white"
          transform="translate(2.51428 0.0142822)"
        />
      </clipPath>
    </defs>
  </>
);

export default NumberFire;
