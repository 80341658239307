import React, { FC } from "react";

export const UnitedKingdom: FC = () => (
  <>
    <path d="M32 0H0V19.9784H32V0Z" fill="#F7FBFF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 8.32433V11.6757H17.57V19.9784H14.43V11.6757H0V8.32433H14.43V0H17.57V8.32433H32Z"
      fill="#D22839"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.95525 0H13.6219V6.59459L2.95525 0ZM18.3781 0H29.0447L18.3781
      6.59459V0ZM32 2.05405L23.3189 7.41622H32V2.05405ZM23.3189
      12.5838H32V17.9459L23.3189 12.5838ZM29.0447 19.9784L18.3781
      13.3838V19.9784H29.0447ZM0 12.5838H8.68109L0 17.9459V12.5838ZM13.6219
      13.4054L2.95525 20H13.6219V13.4054ZM8.68109 7.41622L0
      2.05405V7.41622H8.68109Z"
      fill="#004EA3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.83549 7.41627L0 1.3406V0.0865402L11.8441 7.41627H9.83549Z"
      fill="#D22839"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6219 12.7352L1.87012 19.9784H0V19.9135L11.8672
      12.5838H13.6219V12.7352Z"
      fill="#D22839"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 0.151351L20.2482 7.41622H18.3781V7.30811L30.2453 0H32V0.151351Z"
      fill="#D22839"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 19.8271L20.2482 12.5838H22.2568L32 18.5946V19.8271Z"
      fill="#D22839"
    />
  </>
);
