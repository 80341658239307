import { createContext, useContext, useReducer, useMemo } from "react";

import { Context, State } from "./types";

import { modalPagesReducer, INITIAL_STATE } from "./modalPagesReducer";

export const ModalPagesContext = createContext<Context>([
  INITIAL_STATE,
  () => {}
]);
ModalPagesContext.displayName = "ModalPagesContext";

export const useModalPagesReducer = (initialState?: Partial<State>) => {
  const [state, dispatch] = useReducer(
    modalPagesReducer,
    { ...INITIAL_STATE, ...initialState } ?? INITIAL_STATE
  );
  const context: Context = useMemo(() => [state, dispatch], [state, dispatch]);

  return context;
};

export const useModalPagesContext = () => useContext(ModalPagesContext);
