import { History } from "@tvg/ts-types/History";
import {
  PromoOnboardingStep,
  PromoStepType,
  WagerStep
} from "@tvg/sh-lib-promos-onboarding/types/promoSteps";

import { PromoCTA } from "../types";
import { defaultCTA } from "../constraints/promoCTA";
import { getDepositCTA } from "./CTA/getDepositCTA";
import { getBetCTA } from "./CTA/getBetCTA";
import { getWatchLiveCTA } from "./CTA/getWatchLiveCTA";

export const getCTA = (
  stepType: PromoStepType,
  isFulfilled: boolean,
  promoOnboardingStep: PromoOnboardingStep,
  history: History,
  isDesktop: boolean
): PromoCTA => {
  switch (stepType) {
    case PromoStepType.DEPOSIT:
      return getDepositCTA(isFulfilled, history, isDesktop);
    case PromoStepType.WAGER:
      if (isFulfilled) {
        return getWatchLiveCTA(
          history,
          promoOnboardingStep as WagerStep,
          isDesktop
        );
      }
      return getBetCTA(history, isDesktop);
    default:
      return defaultCTA;
  }
};
