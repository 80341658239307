import React from "react";
import type { NullaryFn } from "../../../../types";
import SpinningWheel from "../SpinningWheel";
import { Button } from "./styled-components";

type Props = {
  text: string | JSX.Element;
  onClick: NullaryFn<void>;
  isLoading: boolean;
  hasError: boolean;
  bgColor?: string;
  qaLabel?: string;
};

const ButtonComp = ({
  text,
  onClick,
  isLoading,
  hasError,
  bgColor,
  qaLabel = "submit-button"
}: Props) =>
  isLoading ? (
    <Button bgColor={bgColor} isLoading data-qa-label={`${qaLabel}-loading`}>
      <SpinningWheel />
    </Button>
  ) : (
    <Button
      onClick={onClick}
      disabled={hasError}
      data-qa-label={qaLabel}
      bgColor={bgColor}
      isDisabled={hasError}
    >
      {text}
    </Button>
  );

export default ButtonComp;
