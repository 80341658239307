import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { noop, get } from "lodash";
import type { NullaryFn, FormData } from "@tvg/wallet/src/types";
import { PaymentType } from "@tvg/wallet/src/types";

export type State = {
  isModalOpen: boolean;
  isMoneyPakModalOpen: boolean;
  isOtherPaymentDepositModalOpen: boolean;
  isPayNearMeModalOpen: boolean;
  formData?:
    | FormData
    | boolean
    | null
    | { isQuickDeposit: boolean; isRedirectFromMZM?: boolean };
  onBack?: NullaryFn<void>;
  fromCreation: boolean;
  otherDepositsPaymentType: PaymentType;
  isQuickDeposit: boolean;
  isRedirectFromMZM: boolean;
};

export type DepositsPayload =
  | FormData
  | boolean
  | null
  | { isQuickDeposit: boolean; isRedirectFromMZM?: boolean };

export const initialState: State = {
  isModalOpen: false,
  isMoneyPakModalOpen: false,
  isPayNearMeModalOpen: false,
  isOtherPaymentDepositModalOpen: false,
  formData: null,
  onBack: noop,
  fromCreation: false,
  otherDepositsPaymentType: "CASH",
  isQuickDeposit: false,
  isRedirectFromMZM: false
};

export type ShouldEraseData = boolean;

const depositFundsModalSlice = createSlice({
  name: "depositFundsModal",
  initialState,
  reducers: {
    openDepositFundsModal(state, action: PayloadAction<DepositsPayload>) {
      state.isModalOpen = true;
      if (action && action.payload) {
        if (get(action, "payload.isQuickDeposit", false)) {
          state.isQuickDeposit = true;
          state.isRedirectFromMZM = get(
            action,
            "payload.isRedirectFromMZM",
            false
          );
          return;
        }
        if (
          typeof action.payload !== "boolean" &&
          get(action.payload, "isQuickDeposit") === undefined
        ) {
          state.formData = action.payload;
        }
        state.fromCreation = true;
      }
    },
    closeDepositFundsModal(
      state,
      action: PayloadAction<ShouldEraseData | undefined>
    ) {
      state.isModalOpen = false;
      if (action && action.payload) {
        state.formData = null;
      }
      state.isQuickDeposit = false;
      state.isRedirectFromMZM = false;
      state.fromCreation = false;
    },
    openMoneyPakFundsModal(state) {
      state.isMoneyPakModalOpen = true;
    },
    closeMoneyPakFundsModal(state) {
      state.isMoneyPakModalOpen = false;
    },
    openOtherPaymentDepositModal(state, action: PayloadAction<PaymentType>) {
      state.isOtherPaymentDepositModalOpen = true;
      state.otherDepositsPaymentType = action.payload;
    },
    closeOtherPaymentDepositModal(state) {
      state.isOtherPaymentDepositModalOpen = false;
    },
    openPayNearMeFundsModal(state) {
      state.isPayNearMeModalOpen = true;
    },
    closePayNearMeFundsModal(state) {
      state.isPayNearMeModalOpen = false;
    }
  }
});

export const {
  openDepositFundsModal,
  closeDepositFundsModal,
  openMoneyPakFundsModal,
  closeMoneyPakFundsModal,
  openOtherPaymentDepositModal,
  closeOtherPaymentDepositModal,
  openPayNearMeFundsModal,
  closePayNearMeFundsModal
} = depositFundsModalSlice.actions;

export default depositFundsModalSlice.reducer;
