import React from "react";
import { Path } from "react-native-svg";

import { IconPathProps } from "../types";

const Hamburger = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M1.5 12H22.5M22.5 4.875H1.5M22.5 19.125H1.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
  />
);

export default Hamburger;
