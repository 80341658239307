// @flow
// $FlowFixMe
import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { get } from "lodash";
import type { Device } from "@tvg/types/Device";
import type { NullaryFn, PaymentTypeItem } from "@tvg/wallet/src/types";
import { getPawsContent } from "@tvg/sh-lib-paws/utils/content";
import { closePaymentMethodSelectorModal } from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import PaymentMethodsList from "../PaymentMethodsList";
import ModalV2 from "../ModalV2";

type Props = {
  device: Device,
  isOpen: boolean,
  isModal?: boolean,
  isDeposit?: boolean,
  scrollToBottom?: NullaryFn<void>,
  onChangePaymentMethod?: NullaryFn<void>,
  otherMethods: PaymentTypeItem[]
};

const selectTitleFromPaws = (state) => {
  const content = getPawsContent(state);
  return get(
    content,
    "paymentMethodSelectorModal.title",
    "Select Payment Method"
  );
};

const PaymentMethodModal = ({
  device,
  isOpen,
  isDeposit = false,
  scrollToBottom,
  onChangePaymentMethod,
  otherMethods
}: Props) => {
  const dispatch = useDispatch();
  const title = useSelector(selectTitleFromPaws, shallowEqual);

  const isMobile = device === "mobile";

  const closeModal = () => {
    dispatch(closePaymentMethodSelectorModal());
  };

  return (
    <ModalV2
      title={title}
      isOpen={isOpen}
      onClose={() => closeModal()}
      onOverlayClick={() => closeModal()}
      titleType="info"
      qaLabel="payment-method-modal"
      animation="fade"
      offsetLeft={isMobile ? 0 : 12}
      offsetRight={isMobile ? 0 : 12}
      fixedWidth={isMobile ? "95vw" : "375px"}
      isFullWidth={false}
      isFullHeight={false}
      offsetTop={isMobile ? 0 : 66}
    >
      {() => (
        <PaymentMethodsList
          device={device}
          isModal
          isMobile
          isDeposit={isDeposit}
          scrollToBottom={scrollToBottom}
          onChangePaymentMethod={onChangePaymentMethod}
          otherMethods={otherMethods}
        />
      )}
    </ModalV2>
  );
};

export default PaymentMethodModal;
