import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const EyeHide = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.125C5 4.125 1.5 12 1.5 12C1.5 12 5 19.875 12 19.875C19 19.875
      22.5 12 22.5 12C22.5 12 19 4.125 12 4.125ZM12 15.3807C13.8671 15.3807
      15.3806 13.8671 15.3806 12C15.3806 10.1329 13.8671 8.61937 12
      8.61937C10.1329 8.61937 8.61932 10.1329 8.61932 12C8.61932 13.8671
      10.1329 15.3807 12 15.3807Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M3.86012 15.6319C2.28671 13.7701 1.125 12 1.125 12C1.125 12 4.99997
      4.125 12 4.125C13.0424 4.125 14.0072 4.29964 14.8944 4.5969M6.16037
      17.8286C7.71696 18.9976 9.66349 19.875 12 19.875C19 19.875 22.875 12
      22.875 12C22.875 12 20.9409 8.49212 17.8228 6.1588M22.5 1.5L14.3905
      9.60954M1.5 22.5L14.3905 9.60954M8.61932 12C8.61932 10.1329 10.1329
      8.61937 12 8.61937M9.60744 14.3884C10.2194 15.0014 11.0654 15.3807 12
      15.3807C13.867 15.3807 15.3806 13.8671 15.3806 12C15.3806 11.0665 15.0022
      10.2213 14.3905 9.60954"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
