import { get } from "lodash";
import MobileDetect from "mobile-detect";

import type { TvgConf, Product } from "./types";
import tvgBrand, { BRAND_FDR } from "./brand";
import tvgEnvironment from "./environment";
import content from "./content";
import tvgDevice from "./device";
import getBrazeDevice from "./brazeDevice";
import tvgProduct, {
  PRODUCTS_IOS,
  PRODUCTS_FDR,
  PRODUCTS_FDR_X_SELL
} from "./product";
import tvgLocation from "./location";
import tvgFeatures from "./features";
import tvgMessages from "./messages";
import tvgMetadata from "./metadata";
import buildUrl from "./buildurl";
import states from "./states";
import getGraphContext from "./graphContext";
import { isBrowser, isRN as isRNFunc } from "./utils";

const OS_IOS = "iOS";

let hostname: string;
let userAgent: string;
let setProduct = "tvg4" as Product;

const getBrand = (product: string, hostnameParam: string) => {
  if (!!hostnameParam && hostnameParam.includes("racing")) {
    return BRAND_FDR;
  }

  return PRODUCTS_FDR.includes(product) ? BRAND_FDR : tvgBrand(hostnameParam);
};

const getAmplitudeConfig = (product: string, brand: string) => {
  if (product === "tvg5" && brand === "fdr") {
    return "tvg5fdr";
  }

  return product;
};

// appConf
// appConf is set on the react native app and contains the necessary info
// to instatiate tvgConf

const tvgConf = (
  givenHostname: string | undefined = hostname,
  givenUserAgent: string | undefined = userAgent,
  givenProduct: Product | undefined = setProduct
): TvgConf => {
  if (!givenHostname && typeof window !== "undefined" && window.location) {
    // eslint-disable-next-line
    hostname = window.location.hostname;
  } else {
    hostname = get(global, "appConf.hostname", null) || givenHostname;
  }

  if (!givenUserAgent && typeof window !== "undefined" && window.navigator) {
    // eslint-disable-next-line
    userAgent = window.navigator.userAgent;
  } else {
    userAgent = get(global, "appConf.userAgent", null) || givenUserAgent;
  }

  setProduct =
    get(global, "appConf.product", null) || givenProduct || setProduct;

  const uaInfo = new MobileDetect(userAgent);

  const environment =
    get(global, "appConf.environment", null) || tvgEnvironment(hostname);
  const product = tvgProduct(setProduct);
  const device =
    get(global, "appConf.device", null) || tvgDevice(userAgent, product);

  const os = PRODUCTS_IOS.includes(product)
    ? OS_IOS
    : // @ts-ignore
      (uaInfo.os(userAgent) as OS);
  const features = tvgFeatures.readFeatures();
  const messages = tvgMessages.readMessages();
  const metadata = tvgMetadata();
  const configs = content(environment);
  const brand = getBrand(product, hostname);
  const brandConfig = configs[brand];
  const location = tvgLocation(brand);
  const isRN = isRNFunc();
  const gaContext = () => {
    let finalProduct: string = product;
    if (product === "androidwrapper" && brand === "fdr") {
      finalProduct = "fdrandroidwrapper";
    } else if (product === "tvg5" && brand === "fdr") {
      finalProduct = "fdrdesktop";
    }
    return `google.${finalProduct}`;
  };

  const getForcedProductName = (forcedProduct: Product): Product => {
    let finalProduct = forcedProduct;
    if (PRODUCTS_FDR_X_SELL.includes(forcedProduct)) {
      finalProduct = forcedProduct.replace("xsell", "") as Product; // Forcing cast to use Product type
    } else if (forcedProduct === "fdrandroidgps") {
      // FDR Google play store to side-loaded
      finalProduct = "fdrandroid";
    }

    return finalProduct;
  };

  const amplitudeContext = `amplitude.${getAmplitudeConfig(product, brand)}`;
  const brazeDevice = getBrazeDevice(os, product);
  return {
    brand,
    device,
    environment,
    product,
    features,
    messages,
    metadata,
    os,
    brazeDevice,
    context: (productContext = product, forceStandalone = false) => {
      const finalProduct = forceStandalone
        ? getForcedProductName(productContext)
        : product;
      return `${finalProduct}-${brand}`;
    },
    // @ts-ignore
    config: (configPath) => get(brandConfig, configPath, brandConfig),
    gaConfig: () => get(brandConfig, gaContext(), brandConfig),
    amplitudeConfig: () => get(brandConfig, amplitudeContext, brandConfig),
    getFeatures: (localDevice = device, serverSide = false) =>
      tvgFeatures.getFeatures(
        brandConfig.service,
        getForcedProductName(product),
        localDevice,
        brand,
        serverSide
      ),
    getMessages: (namespaces, forceFetch = false) =>
      tvgMessages.getMessages(
        namespaces,
        brandConfig.service.capi,
        getForcedProductName(product),
        device,
        brand,
        forceFetch
      ),
    getStates: () => states,
    getDomains: (name = "desktop") => {
      const brandMap = Object.keys(configs);
      // @ts-ignore
      return brandMap.map((configBrand) => configs[configBrand].domain[name]);
    },
    getClientId: () =>
      isBrowser()
        ? btoa(`${brandConfig.clientId}:`)
        : Buffer.from(`${brandConfig.clientId}:`).toString("base64"),
    getExternalDomain: () => get(brandConfig, "externalDomain", {}),
    buildUrl: (options) => {
      const {
        app = product,
        wrapper = false,
        callback = false,
        path = "",
        params = null,
        relative = false
      } = options;
      return buildUrl(
        app,
        wrapper,
        callback,
        path,
        params,
        relative
      )(
        brandConfig.domain,
        brand === BRAND_FDR ? BRAND_FDR : location,
        product,
        brazeDevice
      );
    },
    graphContext: () => getGraphContext(brand, product, device),
    isRN,
    isBrowser
  };
};

export default tvgConf;
