// @flow
import buildColor from "../../_static/ColorPalette";

import {
  type ButtonSizeMap,
  type ButtonTypeMap,
  type ButtonType,
  type ButtonSize
} from "./Types";

const SIZES: ButtonSizeMap = {
  default: { height: "32px", fontSize: "11px", padding: "0 8px" },
  small: { height: "24px", fontSize: "10px", padding: "0 8px" },
  micro: { height: "16px", fontSize: "10px", padding: "2px" },
  big: { height: "40px", fontSize: "12px", padding: "0 16px" },
  bigger: { height: "44px", fontSize: "14px", padding: "0 16px" },
  huge: { height: "48px", fontSize: "14px", padding: "0 24px" },
  autoSized: { height: "auto", fontSize: "auto", padding: "0" }
};

const TYPES: ButtonTypeMap = {
  primary: {
    background: buildColor("blue_accent", "500"),
    hover: buildColor("blue_accent", "600"),
    active: buildColor("blue_accent", "700"),
    selected: buildColor("blue_accent", "600"),
    text: buildColor("white", "100"),
    loadingBackground: buildColor("blue_accent", "200"),
    border: "none"
  },
  secondary: {
    background: buildColor("white", "100"),
    hover: buildColor("blue", "000"),
    active: buildColor("blue", "100"),
    selected: buildColor("blue", "100"),
    text: buildColor("grey", "900"),
    border: `1px solid ${buildColor("blue", "100")}`
  },
  secondary_alt: {
    background: buildColor("white", "20"),
    hover: buildColor("white", "50"),
    active: buildColor("white", "10"),
    selected: buildColor("black", "50"),
    text: buildColor("white", "100"),
    border: "none"
  },
  tertiary: {
    background: "transparent",
    hover: buildColor("blue_accent", "100"),
    active: buildColor("blue", "200"),
    selected: buildColor("blue", "100"),
    text: buildColor("blue_accent", "500"),
    loadingBackground: buildColor("blue", "100"),
    border: "none"
  },
  tertiary_alt: {
    background: "transparent",
    hover: buildColor("white", "10"),
    active: buildColor("white", "20"),
    selected: buildColor("white", "20"),
    text: buildColor("white", "100"),
    border: "none"
  },
  bet: {
    background: buildColor("green", "500"),
    hover: buildColor("green", "600"),
    active: buildColor("green", "700"),
    selected: buildColor("green", "600"),
    text: buildColor("white", "100"),
    loadingBackground: buildColor("green", "100"),
    border: "none"
  },
  marketing: {
    background: buildColor("yellow", "500"),
    hover: buildColor("yellow", "600"),
    active: buildColor("yellow", "700"),
    selected: buildColor("yellow", "600"),
    text: buildColor("grey", "900"),
    loadingBackground: buildColor("yellow", "100"),
    border: "none"
  },
  delete: {
    background: buildColor("red", "500"),
    hover: buildColor("red", "400"),
    active: buildColor("red", "600"),
    selected: buildColor("red", "600"),
    text: buildColor("white", "100"),
    loadingBackground: buildColor("red", "100"),
    border: "none"
  },
  shadow: {
    background: buildColor("black", "50"),
    hover: buildColor("grey", "900"),
    active: buildColor("black", "70"),
    selected: buildColor("black", "70"),
    text: buildColor("white", "100"),
    border: "none"
  },
  // $FlowFixMe
  non_styled: {}
};

export const getType = (type: ButtonType) => TYPES[type];

export const getSize = (size: ButtonSize) => SIZES[size];
