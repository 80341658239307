import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontMedium } from "@tvg/atomic-ui/_static/Typography";

export const Card = styled.button<{ isActive: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  box-shadow: 0 1px 3px rgba(17, 43, 68, 0.12);
  border-radius: 4px;
  padding: 12px 4px;
  text-align: center;
  cursor: pointer;
  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid ${buildColor("blue_accent", "500")};
          background: ${buildColor("blue_accent", "100")};
        `
      : css`
          border: 1px solid ${buildColor("blue", "100")};
          background: ${buildColor("blue_accent", "000")};
        `}
`;

export const FlagContainer = styled.div`
  position: absolute;
  top: -6px;
  right: -4px;
`;

export const CardIconContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: ${({ isActive }) =>
    buildColor("blue_accent", isActive ? "200" : "100")};
`;

export const CardText = styled.span<{ isActive: boolean }>`
  font-family: ${fontMedium};
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
  ${({ isActive }) =>
    isActive
      ? css`
          color: ${buildColor("blue_accent", "600")};
        `
      : css`
          color: ${buildColor("grey", "900")};
        `}
`;
