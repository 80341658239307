import styled from "styled-components/native";
import { StyledButton } from "../button";

import {
  color,
  background,
  space,
  borderRadius,
  width,
  compose
} from "../../theming/styledSystem";

const styles = compose(color, background, space, borderRadius, width);

export const SortButton = styled(StyledButton)`
  flex-direction: row;
  justify-content: start;
  ${styles};
`;

export const Container = styled.View`
  position: relative;
`;
