import { createGlobalStyle } from "styled-components";

// TODO: THIS IS TEMPORARY, WAITING UNTIL WE REMOVE WEBPACK FROM THE PROJECT
export const FormationTokens = createGlobalStyle`
  :root {
    --fd-text-theme-name: Sports;
    --fd-text-mode-name: Light;
    --fd-space-space-base: 4px;
    --fd-sizes-max-content-width: 1400px;
    --fd-text-decoration-text-decoration-line-through: line-through;
    --fd-text-decoration-text-decoration-underline: underline;
    --fd-text-decoration-text-decoration-none: none;
    --fd-text-case-text-case-none: none;
    --fd-text-case-text-case-lowercase: lowercase;
    --fd-text-case-text-case-capitalize: capitalize;
    --fd-text-case-text-case-uppercase: uppercase;
    --fd-letter-spacings-letter-spacing-030: 1px;
    --fd-letter-spacings-letter-spacing-020: 0.5px;
    --fd-letter-spacings-letter-spacing-010: 0;
    --fd-font-sizes-font-size-150: 60px;
    --fd-font-sizes-font-size-140: 48px;
    --fd-font-sizes-font-size-130: 40px;
    --fd-font-sizes-font-size-120: 36px;
    --fd-font-sizes-font-size-110: 32px;
    --fd-font-sizes-font-size-100: 28px;
    --fd-font-sizes-font-size-090: 24px;
    --fd-font-sizes-font-size-080: 22px;
    --fd-font-sizes-font-size-070: 20px;
    --fd-font-sizes-font-size-060: 18px;
    --fd-font-sizes-font-size-050: 16px;
    --fd-font-sizes-font-size-040: 14px;
    --fd-font-sizes-font-size-030: 12px;
    --fd-font-sizes-font-size-020: 10px;
    --fd-font-sizes-font-size-010: 8px;
    --fd-line-heights-line-height-040: 1.5;
    --fd-line-heights-line-height-030: 1.25;
    --fd-line-heights-line-height-020: 1.1;
    --fd-line-heights-line-height-010: 1;
    --fd-font-weights-font-weight-090: 900;
    --fd-font-weights-font-weight-080: 800;
    --fd-font-weights-font-weight-070: 800;
    --fd-font-weights-font-weight-060: 700;
    --fd-font-weights-font-weight-050: 700;
    --fd-font-weights-font-weight-040: 600;
    --fd-font-weights-font-weight-030: 500;
    --fd-font-weights-font-weight-020: 400;
    --fd-font-weights-font-weight-010: 300;
    --fd-fonts-font-family-030: Proxima Nova Condensed;
    --fd-fonts-font-family-020: Shentox;
    --fd-fonts-font-family-010: Proxima Nova;
    --fd-colors-core-transparent-black: #0a0a0a00;
    --fd-colors-core-transparent-white: #ffffff00;
    --fd-colors-core-white-tint-090: #ffffffe6;
    --fd-colors-core-white-tint-080: #ffffffcc;
    --fd-colors-core-white-tint-070: #ffffffb3;
    --fd-colors-core-white-tint-060: #ffffff99;
    --fd-colors-core-white-tint-050: #ffffff80;
    --fd-colors-core-white-tint-040: #ffffff66;
    --fd-colors-core-white-tint-030: #ffffff4d;
    --fd-colors-core-white-tint-020: #ffffff33;
    --fd-colors-core-white-tint-010: #ffffff1a;
    --fd-colors-core-white-tint-005: #ffffff0d;
    --fd-colors-core-black-tint-090: #0a0a0ae6;
    --fd-colors-core-black-tint-080: #0a0a0acc;
    --fd-colors-core-black-tint-070: #0a0a0ab3;
    --fd-colors-core-black-tint-060: #0a0a0a99;
    --fd-colors-core-black-tint-050: #0a0a0a80;
    --fd-colors-core-black-tint-040: #0a0a0a66;
    --fd-colors-core-black-tint-030: #0a0a0a4d;
    --fd-colors-core-black-tint-020: #0a0a0a33;
    --fd-colors-core-black-tint-010: #0a0a0a1a;
    --fd-colors-core-black-tint-005: #0a0a0a0d;
    --fd-colors-core-black: #0a0a0a;
    --fd-colors-core-grey-d-4: #1C1D1D;
    --fd-colors-core-grey-d-3: #2B2D2E;
    --fd-colors-core-grey-d-2: #3C3E40;
    --fd-colors-core-grey-d-1: #4D5153;
    --fd-colors-core-grey: #6A6F73;
    --fd-colors-core-grey-l-1: #969DA3;
    --fd-colors-core-grey-l-2: #B0B7BF;
    --fd-colors-core-grey-l-3: #CED4DB;
    --fd-colors-core-grey-l-4: #EAF0F6;
    --fd-colors-core-grey-l-5: #F7FBFF;
    --fd-colors-core-white: #ffffff;
    --fd-colors-core-teal-d-4: #003328;
    --fd-colors-core-teal-d-3: #004435;
    --fd-colors-core-teal-d-2: #005F48;
    --fd-colors-core-teal-d-1: #00735A;
    --fd-colors-core-teal: #008568;
    --fd-colors-core-teal-l-1: #00C898;
    --fd-colors-core-teal-l-2: #00E5B4;
    --fd-colors-core-teal-l-3: #75EBD2;
    --fd-colors-core-teal-l-4: #9CF7E4;
    --fd-colors-core-teal-l-5: #D4FAF2;
    --fd-colors-core-purple-d-4: #36005B;
    --fd-colors-core-purple-d-3: #61019B;
    --fd-colors-core-purple-d-2: #7401B7;
    --fd-colors-core-purple-d-1: #8F02E1;
    --fd-colors-core-purple: #A530F3;
    --fd-colors-core-purple-l-1: #B265FE;
    --fd-colors-core-purple-l-2: #C18FFE;
    --fd-colors-core-purple-l-3: #D4B6FE;
    --fd-colors-core-purple-l-4: #E6D6FE;
    --fd-colors-core-purple-l-5: #F6F1FE;
    --fd-colors-core-yellow-d-4: #2E2600;
    --fd-colors-core-yellow-d-3: #4B3F00;
    --fd-colors-core-yellow-d-2: #5F4F00;
    --fd-colors-core-yellow-d-1: #756100;
    --fd-colors-core-yellow: #8A7000;
    --fd-colors-core-yellow-l-1: #A98E00;
    --fd-colors-core-yellow-l-2: #C9A800;
    --fd-colors-core-yellow-l-3: #EAC300;
    --fd-colors-core-yellow-l-4: #FFDC2E;
    --fd-colors-core-yellow-l-5: #FFF6BD;
    --fd-colors-core-orange-d-4: #401D00;
    --fd-colors-core-orange-d-3: #692E00;
    --fd-colors-core-orange-d-2: #843B00;
    --fd-colors-core-orange-d-1: #A44800;
    --fd-colors-core-orange: #C15400;
    --fd-colors-core-orange-l-1: #F87A1E;
    --fd-colors-core-orange-l-2: #FF8C31;
    --fd-colors-core-orange-l-3: #FFB77E;
    --fd-colors-core-orange-l-4: #FFD8B9;
    --fd-colors-core-orange-l-5: #FFF1E6;
    --fd-colors-core-red-d-4: #40020D;
    --fd-colors-core-red-d-3: #83001C;
    --fd-colors-core-red-d-2: #A40023;
    --fd-colors-core-red-d-1: #C8002B;
    --fd-colors-core-red: #D22839;
    --fd-colors-core-red-l-1: #EB4758;
    --fd-colors-core-red-l-2: #EA6875;
    --fd-colors-core-red-l-3: #F198A1;
    --fd-colors-core-red-l-4: #F9C9CE;
    --fd-colors-core-red-l-5: #FDECED;
    --fd-colors-core-navy-d-4: #011638;
    --fd-colors-core-navy-d-3: #05285A;
    --fd-colors-core-navy-d-2: #103469;
    --fd-colors-core-navy-d-1: #14417A;
    --fd-colors-core-navy: #184C8B;
    --fd-colors-core-navy-l-1: #6682A2;
    --fd-colors-core-navy-l-2: #819CBB;
    --fd-colors-core-navy-l-3: #9CB1CB;
    --fd-colors-core-navy-l-4: #C6D3E1;
    --fd-colors-core-navy-l-5: #EBF2FA;
    --fd-colors-core-blue-d-4: #002650;
    --fd-colors-core-blue-d-3: #003D81;
    --fd-colors-core-blue-d-2: #004EA3;
    --fd-colors-core-blue-d-1: #005FC8;
    --fd-colors-core-blue: #0070EB;
    --fd-colors-core-blue-l-1: #2B90FF;
    --fd-colors-core-blue-l-2: #64AEFF;
    --fd-colors-core-blue-l-3: #99CAFF;
    --fd-colors-core-blue-l-4: #C8E2FF;
    --fd-colors-core-blue-l-5: #EAF4FF;
    --fd-colors-core-green-d-4: #002E11;
    --fd-colors-core-green-d-3: #00491C;
    --fd-colors-core-green-d-2: #005D23;
    --fd-colors-core-green-d-1: #00732C;
    --fd-colors-core-green: #128000;
    --fd-colors-core-green-l-1: #1BB152;
    --fd-colors-core-green-l-2: #31C268;
    --fd-colors-core-green-l-3: #7FD9A1;
    --fd-colors-core-green-l-4: #BCEBCE;
    --fd-colors-core-green-l-5: #E9F8EF;
    --fd-border-styles-border-style-dashed-style: dashed;
    --fd-border-styles-border-style-solid-style: solid;
    --fd-radii-border-radius-050: 24px;
    --fd-radii-border-radius-040: 12px;
    --fd-radii-border-radius-030: 8px;
    --fd-radii-border-radius-020: 4px;
    --fd-radii-border-radius-010: 2px;
    --fd-radii-border-radius-circle: 9999px;
    --fd-radii-border-radius-sharp: 0;
    --fd-border-widths-border-width-040: 8px;
    --fd-border-widths-border-width-030: 4px;
    --fd-border-widths-border-width-020: 2px;
    --fd-border-widths-border-width-010: 1px;
    --fd-border-widths-border-width-000: 0;
    --fd-z-indices-high: 3;
    --fd-z-indices-mid: 2;
    --fd-z-indices-low: 1;
    --fd-z-indices-none: 0;
    --fd-transitions-acceleration: all 250ms cubic-bezier(0.4, 0, 1, 1) 0ms;
    --fd-transitions-deceleration: all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    --fd-transitions-standard: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    --fd-breakpoints-desktop: 1024;
    --fd-breakpoints-tablet-landscape: 960;
    --fd-breakpoints-tablet-portrait: 640;
    --fd-breakpoints-mobile: 375;
    --fd-colors-brand-gradient-color-stops-end: var(--fd-colors-core-blue-d-3);
    --fd-colors-brand-gradient-color-stops-start: var(--fd-colors-core-blue-d-1);
    --fd-colors-brand-tertiary-shade: var(--fd-colors-core-yellow);
    --fd-colors-brand-tertiary-tint: var(--fd-colors-core-yellow-l-5);
    --fd-colors-brand-tertiary-default: var(--fd-colors-core-yellow-l-4);
    --fd-colors-brand-secondary-shade: var(--fd-colors-core-navy-d-4);
    --fd-colors-brand-secondary-tint: var(--fd-colors-core-navy-l-4);
    --fd-colors-brand-secondary-default: var(--fd-colors-core-navy);
    --fd-colors-brand-primary-shade: var(--fd-colors-core-blue-d-2);
    --fd-colors-brand-primary-tint: var(--fd-colors-core-blue-l-4);
    --fd-colors-brand-primary-default: var(--fd-colors-core-blue);
    --fd-colors-system-verification-content-accent: var(--fd-colors-core-purple-d-2);
    --fd-colors-system-verification-border-default: var(--fd-colors-core-purple-d-1);
    --fd-colors-system-verification-background-subtle: var(--fd-colors-core-purple-l-5);
    --fd-colors-system-verification-background-default: var(--fd-colors-core-purple-d-1);
    --fd-colors-system-neutral-content-accent: var(--fd-colors-core-grey);
    --fd-colors-system-neutral-border-default: var(--fd-colors-core-grey-l-2);
    --fd-colors-system-neutral-background-subtle: var(--fd-colors-core-grey-l-4);
    --fd-colors-system-neutral-background-default: var(--fd-colors-core-grey-l-2);
    --fd-colors-system-important-content-accent: var(--fd-colors-core-red-d-1);
    --fd-colors-system-important-border-default: var(--fd-colors-core-red);
    --fd-colors-system-important-background-subtle: var(--fd-colors-core-red-l-5);
    --fd-colors-system-important-background-default: var(--fd-colors-core-red);
    --fd-colors-system-warning-content-accent: var(--fd-colors-core-orange-d-1);
    --fd-colors-system-warning-border-default: var(--fd-colors-core-orange-l-2);
    --fd-colors-system-warning-background-subtle: var(--fd-colors-core-orange-l-5);
    --fd-colors-system-warning-background-default: var(--fd-colors-core-orange-l-2);
    --fd-colors-system-alert-content-accent: var(--fd-colors-core-yellow-d-1);
    --fd-colors-system-alert-border-default: var(--fd-colors-core-yellow-l-4);
    --fd-colors-system-alert-background-subtle: var(--fd-colors-core-yellow-l-5);
    --fd-colors-system-alert-background-default: var(--fd-colors-core-yellow-l-4);
    --fd-colors-system-positive-content-accent: var(--fd-colors-core-green-d-1);
    --fd-colors-system-positive-border-default: var(--fd-colors-core-green);
    --fd-colors-system-positive-background-subtle: var(--fd-colors-core-green-l-5);
    --fd-colors-system-positive-background-default: var(--fd-colors-core-green);
    --fd-colors-system-info-content-accent: var(--fd-colors-core-blue-d-2);
    --fd-colors-system-info-border-default: var(--fd-colors-core-blue-d-1);
    --fd-colors-system-info-background-subtle: var(--fd-colors-core-blue-l-5);
    --fd-colors-system-info-background-default: var(--fd-colors-core-blue-d-1);
    --fd-colors-link-inverse-destructive-hover: var(--fd-colors-core-red);
    --fd-colors-link-inverse-destructive-base: var(--fd-colors-core-red-l-2);
    --fd-colors-link-inverse-default-hover: var(--fd-colors-core-blue-l-4);
    --fd-colors-link-inverse-default-base: var(--fd-colors-core-white);
    --fd-colors-link-destructive-hover: var(--fd-colors-core-red-d-2);
    --fd-colors-link-destructive-base: var(--fd-colors-core-red);
    --fd-colors-link-default-hover: var(--fd-colors-core-blue-d-2);
    --fd-colors-link-default-base: var(--fd-colors-core-blue);
    --fd-colors-border-inverse: var(--fd-colors-core-white);
    --fd-colors-border-disabled: var(--fd-colors-core-grey-l-4);
    --fd-colors-border-active: var(--fd-colors-core-blue);
    --fd-colors-border-subtle: var(--fd-colors-core-navy-l-4);
    --fd-colors-border-default: var(--fd-colors-core-grey-l-2);
    --fd-colors-background-secondary: var(--fd-colors-core-navy);
    --fd-colors-background-promotional: var(--fd-colors-core-navy-d-4);
    --fd-colors-background-primary: var(--fd-colors-core-blue);
    --fd-colors-background-transparent: var(--fd-colors-core-transparent-white);
    --fd-colors-background-hover: var(--fd-colors-core-blue-l-5);
    --fd-colors-background-accent: var(--fd-colors-core-black-tint-005);
    --fd-colors-background-layer: var(--fd-colors-core-grey-l-5);
    --fd-colors-background-surface: var(--fd-colors-core-white);
    --fd-colors-background-base: var(--fd-colors-core-grey-l-4);
    --fd-colors-content-link-active: var(--fd-colors-core-blue-d-2);
    --fd-colors-content-link: var(--fd-colors-core-blue);
    --fd-colors-content-loading-inverse: var(--fd-colors-core-white-tint-010);
    --fd-colors-content-loading-default: var(--fd-colors-core-black-tint-005);
    --fd-colors-content-on-dark: var(--fd-colors-core-white);
    --fd-colors-content-on-light: var(--fd-colors-core-navy-d-3);
    --fd-colors-content-disabled: var(--fd-colors-core-grey-l-3);
    --fd-colors-content-subtle: var(--fd-colors-core-grey);
    --fd-colors-content-default: var(--fd-colors-core-grey-d-4);
    --fd-colors-content-strong: var(--fd-colors-core-navy-d-3);
    --fd-colors-component-pill-inverse-background-disabled: var(--fd-colors-core-grey-l-4);
    --fd-colors-component-pill-inverse-background-active: var(--fd-colors-core-blue-d-1);
    --fd-colors-component-pill-inverse-background-selected: var(--fd-colors-core-blue);
    --fd-colors-component-pill-inverse-background-hover: var(--fd-colors-core-grey-l-3);
    --fd-colors-component-pill-inverse-content-disabled: var(--fd-colors-core-grey-l-2);
    --fd-colors-component-pill-inverse-content-active: var(--fd-colors-core-white);
    --fd-colors-component-pill-inverse-content-selected: var(--fd-colors-core-white);
    --fd-colors-component-pill-inverse-content-hover: var(--fd-colors-core-navy-d-4);
    --fd-colors-component-pill-inverse-content-base: var(--fd-colors-core-white);
    --fd-colors-component-pill-primary-background-disabled: var(--fd-colors-core-grey-l-4);
    --fd-colors-component-pill-primary-background-active: var(--fd-colors-core-blue-d-1);
    --fd-colors-component-pill-primary-background-selected: var(--fd-colors-core-blue);
    --fd-colors-component-pill-primary-background-hover: var(--fd-colors-core-grey-l-3);
    --fd-colors-component-pill-primary-background-base: var(--fd-colors-core-grey-l-4);
    --fd-colors-component-pill-primary-content-disabled: var(--fd-colors-core-grey-l-2);
    --fd-colors-component-pill-primary-content-active: var(--fd-colors-core-white);
    --fd-colors-component-pill-primary-content-selected: var(--fd-colors-core-white);
    --fd-colors-component-pill-primary-content-hover: var(--fd-colors-core-navy-d-4);
    --fd-colors-component-pill-primary-content-base: var(--fd-colors-core-navy-d-4);
    --fd-colors-component-data-input-background-disabled: var(--fd-colors-core-grey-l-4);
    --fd-colors-component-selection-control-border-unselected: var(--fd-colors-core-grey-l-3);
    --fd-colors-component-selection-control-border-selected: var(--fd-colors-core-blue-d-1);
    --fd-colors-component-selection-control-border-active: var(--fd-colors-core-blue);
    --fd-colors-component-selection-control-border-hover: var(--fd-colors-core-blue);
    --fd-colors-component-selection-control-background-disabled: var(--fd-colors-core-grey-l-4);
    --fd-colors-component-selection-control-background-unselected: var(--fd-colors-core-grey-l-3);
    --fd-colors-component-selection-control-background-selected: var(--fd-colors-core-blue-d-1);
    --fd-colors-component-selection-control-background-active: var(--fd-colors-core-blue);
    --fd-colors-component-selection-control-background-hover: var(--fd-colors-core-blue-l-5);
    --fd-colors-component-button-button-link-border-active: var(--fd-colors-core-transparent-white);
    --fd-colors-component-button-button-link-border-hover: var(--fd-colors-core-transparent-white);
    --fd-colors-component-button-button-link-border-base: var(--fd-colors-core-transparent-white);
    --fd-colors-component-button-button-link-background-disabled: var(--fd-colors-core-transparent-white);
    --fd-colors-component-button-button-link-background-active: var(--fd-colors-core-blue-d-1);
    --fd-colors-component-button-button-link-background-hover: var(--fd-colors-core-blue-l-5);
    --fd-colors-component-button-button-link-background-base: var(--fd-colors-core-transparent-white);
    --fd-colors-component-button-button-link-content-disabled: var(--fd-colors-core-grey-l-2);
    --fd-colors-component-button-button-link-content-active: var(--fd-colors-core-white);
    --fd-colors-component-button-button-link-content-hover: var(--fd-colors-core-blue-d-2);
    --fd-colors-component-button-button-link-content-base: var(--fd-colors-core-blue);
    --fd-colors-component-button-destructive-background-disabled: var(--fd-colors-core-grey-l-4);
    --fd-colors-component-button-destructive-background-active: var(--fd-colors-core-red-d-2);
    --fd-colors-component-button-destructive-background-hover: var(--fd-colors-core-red-d-1);
    --fd-colors-component-button-destructive-background-base: var(--fd-colors-core-red);
    --fd-colors-component-button-destructive-content-disabled: var(--fd-colors-core-grey-l-2);
    --fd-colors-component-button-destructive-content-active: var(--fd-colors-core-white);
    --fd-colors-component-button-destructive-content-hover: var(--fd-colors-core-white);
    --fd-colors-component-button-destructive-content-base: var(--fd-colors-core-white);
    --fd-colors-component-button-transparent-border-disabled: var(--fd-colors-core-navy);
    --fd-colors-component-button-transparent-border-active: var(--fd-colors-core-transparent-white);
    --fd-colors-component-button-transparent-border-hover: var(--fd-colors-core-white);
    --fd-colors-component-button-transparent-border-base: var(--fd-colors-core-white);
    --fd-colors-component-button-transparent-background-disabled: var(--fd-colors-core-transparent-white);
    --fd-colors-component-button-transparent-background-active: var(--fd-colors-core-blue);
    --fd-colors-component-button-transparent-background-hover: var(--fd-colors-core-white-tint-010);
    --fd-colors-component-button-transparent-background-base: var(--fd-colors-core-transparent-white);
    --fd-colors-component-button-transparent-content-disabled: var(--fd-colors-core-navy);
    --fd-colors-component-button-transparent-content-active: var(--fd-colors-core-white);
    --fd-colors-component-button-transparent-content-hover: var(--fd-colors-core-white);
    --fd-colors-component-button-transparent-content-base: var(--fd-colors-core-white);
    --fd-colors-component-button-tertiary-border-active: var(--fd-colors-core-blue);
    --fd-colors-component-button-tertiary-border-hover: var(--fd-colors-core-blue);
    --fd-colors-component-button-tertiary-border-base: var(--fd-colors-core-blue);
    --fd-colors-component-button-tertiary-background-disabled: var(--fd-colors-core-transparent-white);
    --fd-colors-component-button-tertiary-background-active: var(--fd-colors-core-blue);
    --fd-colors-component-button-tertiary-background-hover: var(--fd-colors-core-blue-l-5);
    --fd-colors-component-button-tertiary-background-base: var(--fd-colors-core-transparent-white);
    --fd-colors-component-button-tertiary-content-disabled: var(--fd-colors-core-grey-l-2);
    --fd-colors-component-button-tertiary-content-active: var(--fd-colors-core-white);
    --fd-colors-component-button-tertiary-content-hover: var(--fd-colors-core-blue);
    --fd-colors-component-button-tertiary-content-base: var(--fd-colors-core-blue);
    --fd-colors-component-button-secondary-background-disabled: var(--fd-colors-core-grey-l-4);
    --fd-colors-component-button-secondary-background-active: var(--fd-colors-core-blue-d-2);
    --fd-colors-component-button-secondary-background-hover: var(--fd-colors-core-blue-d-1);
    --fd-colors-component-button-secondary-background-base: var(--fd-colors-core-blue);
    --fd-colors-component-button-secondary-content-disabled: var(--fd-colors-core-grey-l-2);
    --fd-colors-component-button-secondary-content-active: var(--fd-colors-core-white);
    --fd-colors-component-button-secondary-content-hover: var(--fd-colors-core-white);
    --fd-colors-component-button-secondary-content-base: var(--fd-colors-core-white);
    --fd-colors-component-button-primary-background-disabled: var(--fd-colors-core-grey-l-4);
    --fd-colors-component-button-primary-background-active: var(--fd-colors-core-green-d-2);
    --fd-colors-component-button-primary-background-hover: var(--fd-colors-core-green-d-1);
    --fd-colors-component-button-primary-background-base: var(--fd-colors-core-green);
    --fd-colors-component-button-primary-content-disabled: var(--fd-colors-core-grey-l-2);
    --fd-colors-component-button-primary-content-active: var(--fd-colors-core-white);
    --fd-colors-component-button-primary-content-hover: var(--fd-colors-core-white);
    --fd-colors-component-button-primary-content-base: var(--fd-colors-core-white);
    --fd-text-decoration-label-x-small: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-label-x-small: var(--fd-text-case-text-case-uppercase);
    --fd-letter-spacings-label-x-small: var(--fd-letter-spacings-letter-spacing-030);
    --fd-font-sizes-label-x-small: var(--fd-font-sizes-font-size-010);
    --fd-line-heights-label-x-small: var(--fd-line-heights-line-height-030);
    --fd-font-weights-label-x-small: var(--fd-font-weights-font-weight-020);
    --fd-fonts-label-x-small: var(--fd-fonts-font-family-030);
    --fd-text-decoration-label-small: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-label-small: var(--fd-text-case-text-case-uppercase);
    --fd-letter-spacings-label-small: var(--fd-letter-spacings-letter-spacing-030);
    --fd-font-sizes-label-small: var(--fd-font-sizes-font-size-020);
    --fd-line-heights-label-small: var(--fd-line-heights-line-height-030);
    --fd-font-weights-label-small: var(--fd-font-weights-font-weight-020);
    --fd-fonts-label-small: var(--fd-fonts-font-family-030);
    --fd-text-decoration-label-medium: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-label-medium: var(--fd-text-case-text-case-uppercase);
    --fd-letter-spacings-label-medium: var(--fd-letter-spacings-letter-spacing-030);
    --fd-font-sizes-label-medium: var(--fd-font-sizes-font-size-030);
    --fd-line-heights-label-medium: var(--fd-line-heights-line-height-030);
    --fd-font-weights-label-medium: var(--fd-font-weights-font-weight-020);
    --fd-fonts-label-medium: var(--fd-fonts-font-family-030);
    --fd-text-decoration-metadata-small: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-metadata-small: var(--fd-text-case-text-case-uppercase);
    --fd-letter-spacings-metadata-small: var(--fd-letter-spacings-letter-spacing-030);
    --fd-font-sizes-metadata-small: var(--fd-font-sizes-font-size-020);
    --fd-line-heights-metadata-small: var(--fd-line-heights-line-height-030);
    --fd-font-weights-metadata-small: var(--fd-font-weights-font-weight-020);
    --fd-fonts-metadata-small: var(--fd-fonts-font-family-030);
    --fd-text-decoration-metadata-medium: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-metadata-medium: var(--fd-text-case-text-case-uppercase);
    --fd-letter-spacings-metadata-medium: var(--fd-letter-spacings-letter-spacing-030);
    --fd-font-sizes-metadata-medium: var(--fd-font-sizes-font-size-030);
    --fd-line-heights-metadata-medium: var(--fd-line-heights-line-height-030);
    --fd-font-weights-metadata-medium: var(--fd-font-weights-font-weight-020);
    --fd-fonts-metadata-medium: var(--fd-fonts-font-family-030);
    --fd-text-decoration-button-x-small-strong: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-button-x-small-strong: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-button-x-small-strong: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-button-x-small-strong: var(--fd-font-sizes-font-size-020);
    --fd-line-heights-button-x-small-strong: var(--fd-line-heights-line-height-030);
    --fd-font-weights-button-x-small-strong: var(--fd-font-weights-font-weight-050);
    --fd-fonts-button-x-small-strong: var(--fd-fonts-font-family-010);
    --fd-text-decoration-button-x-small-regular: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-button-x-small-regular: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-button-x-small-regular: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-button-x-small-regular: var(--fd-font-sizes-font-size-020);
    --fd-line-heights-button-x-small-regular: var(--fd-line-heights-line-height-030);
    --fd-font-weights-button-x-small-regular: var(--fd-font-weights-font-weight-020);
    --fd-fonts-button-x-small-regular: var(--fd-fonts-font-family-010);
    --fd-text-decoration-button-small-strong: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-button-small-strong: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-button-small-strong: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-button-small-strong: var(--fd-font-sizes-font-size-030);
    --fd-line-heights-button-small-strong: var(--fd-line-heights-line-height-030);
    --fd-font-weights-button-small-strong: var(--fd-font-weights-font-weight-050);
    --fd-fonts-button-small-strong: var(--fd-fonts-font-family-010);
    --fd-text-decoration-button-small-regular: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-button-small-regular: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-button-small-regular: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-button-small-regular: var(--fd-font-sizes-font-size-030);
    --fd-line-heights-button-small-regular: var(--fd-line-heights-line-height-030);
    --fd-font-weights-button-small-regular: var(--fd-font-weights-font-weight-020);
    --fd-fonts-button-small-regular: var(--fd-fonts-font-family-010);
    --fd-text-decoration-button-medium-strong: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-button-medium-strong: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-button-medium-strong: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-button-medium-strong: var(--fd-font-sizes-font-size-040);
    --fd-line-heights-button-medium-strong: var(--fd-line-heights-line-height-030);
    --fd-font-weights-button-medium-strong: var(--fd-font-weights-font-weight-050);
    --fd-fonts-button-medium-strong: var(--fd-fonts-font-family-010);
    --fd-text-decoration-button-medium-regular: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-button-medium-regular: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-button-medium-regular: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-button-medium-regular: var(--fd-font-sizes-font-size-040);
    --fd-line-heights-button-medium-regular: var(--fd-line-heights-line-height-030);
    --fd-font-weights-button-medium-regular: var(--fd-font-weights-font-weight-020);
    --fd-fonts-button-medium-regular: var(--fd-fonts-font-family-010);
    --fd-text-decoration-button-large-strong: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-button-large-strong: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-button-large-strong: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-button-large-strong: var(--fd-font-sizes-font-size-050);
    --fd-line-heights-button-large-strong: var(--fd-line-heights-line-height-030);
    --fd-font-weights-button-large-strong: var(--fd-font-weights-font-weight-050);
    --fd-fonts-button-large-strong: var(--fd-fonts-font-family-010);
    --fd-text-decoration-button-large-regular: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-button-large-regular: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-button-large-regular: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-button-large-regular: var(--fd-font-sizes-font-size-050);
    --fd-line-heights-button-large-regular: var(--fd-line-heights-line-height-030);
    --fd-font-weights-button-large-regular: var(--fd-font-weights-font-weight-020);
    --fd-fonts-button-large-regular: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-x-small-strong-strikethrough: var(--fd-text-decoration-text-decoration-line-through);
    --fd-text-case-body-x-small-strong-strikethrough: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-x-small-strong-strikethrough: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-x-small-strong-strikethrough: var(--fd-font-sizes-font-size-020);
    --fd-line-heights-body-x-small-strong-strikethrough: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-x-small-strong-strikethrough: var(--fd-font-weights-font-weight-050);
    --fd-fonts-body-x-small-strong-strikethrough: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-x-small-regular-strikethrough: var(--fd-text-decoration-text-decoration-line-through);
    --fd-text-case-body-x-small-regular-strikethrough: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-x-small-regular-strikethrough: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-x-small-regular-strikethrough: var(--fd-font-sizes-font-size-020);
    --fd-line-heights-body-x-small-regular-strikethrough: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-x-small-regular-strikethrough: var(--fd-font-weights-font-weight-020);
    --fd-fonts-body-x-small-regular-strikethrough: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-x-small-strong: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-body-x-small-strong: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-x-small-strong: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-x-small-strong: var(--fd-font-sizes-font-size-020);
    --fd-line-heights-body-x-small-strong: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-x-small-strong: var(--fd-font-weights-font-weight-050);
    --fd-fonts-body-x-small-strong: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-x-small-regular: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-body-x-small-regular: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-x-small-regular: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-x-small-regular: var(--fd-font-sizes-font-size-020);
    --fd-line-heights-body-x-small-regular: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-x-small-regular: var(--fd-font-weights-font-weight-020);
    --fd-fonts-body-x-small-regular: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-small-strong-strikethrough: var(--fd-text-decoration-text-decoration-line-through);
    --fd-text-case-body-small-strong-strikethrough: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-small-strong-strikethrough: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-small-strong-strikethrough: var(--fd-font-sizes-font-size-030);
    --fd-line-heights-body-small-strong-strikethrough: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-small-strong-strikethrough: var(--fd-font-weights-font-weight-050);
    --fd-fonts-body-small-strong-strikethrough: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-small-regular-strikethrough: var(--fd-text-decoration-text-decoration-line-through);
    --fd-text-case-body-small-regular-strikethrough: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-small-regular-strikethrough: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-small-regular-strikethrough: var(--fd-font-sizes-font-size-030);
    --fd-line-heights-body-small-regular-strikethrough: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-small-regular-strikethrough: var(--fd-font-weights-font-weight-020);
    --fd-fonts-body-small-regular-strikethrough: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-small-strong: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-body-small-strong: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-small-strong: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-small-strong: var(--fd-font-sizes-font-size-030);
    --fd-line-heights-body-small-strong: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-small-strong: var(--fd-font-weights-font-weight-050);
    --fd-fonts-body-small-strong: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-small-regular: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-body-small-regular: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-small-regular: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-small-regular: var(--fd-font-sizes-font-size-030);
    --fd-line-heights-body-small-regular: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-small-regular: var(--fd-font-weights-font-weight-020);
    --fd-fonts-body-small-regular: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-medium-strong-strikethrough: var(--fd-text-decoration-text-decoration-line-through);
    --fd-text-case-body-medium-strong-strikethrough: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-medium-strong-strikethrough: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-medium-strong-strikethrough: var(--fd-font-sizes-font-size-040);
    --fd-line-heights-body-medium-strong-strikethrough: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-medium-strong-strikethrough: var(--fd-font-weights-font-weight-050);
    --fd-fonts-body-medium-strong-strikethrough: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-medium-regular-strikethrough: var(--fd-text-decoration-text-decoration-line-through);
    --fd-text-case-body-medium-regular-strikethrough: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-medium-regular-strikethrough: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-medium-regular-strikethrough: var(--fd-font-sizes-font-size-040);
    --fd-line-heights-body-medium-regular-strikethrough: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-medium-regular-strikethrough: var(--fd-font-weights-font-weight-020);
    --fd-fonts-body-medium-regular-strikethrough: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-medium-strong: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-body-medium-strong: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-medium-strong: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-medium-strong: var(--fd-font-sizes-font-size-040);
    --fd-line-heights-body-medium-strong: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-medium-strong: var(--fd-font-weights-font-weight-050);
    --fd-fonts-body-medium-strong: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-medium-regular: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-body-medium-regular: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-medium-regular: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-medium-regular: var(--fd-font-sizes-font-size-040);
    --fd-line-heights-body-medium-regular: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-medium-regular: var(--fd-font-weights-font-weight-020);
    --fd-fonts-body-medium-regular: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-large-strong-strikethrough: var(--fd-text-decoration-text-decoration-line-through);
    --fd-text-case-body-large-strong-strikethrough: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-large-strong-strikethrough: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-large-strong-strikethrough: var(--fd-font-sizes-font-size-050);
    --fd-line-heights-body-large-strong-strikethrough: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-large-strong-strikethrough: var(--fd-font-weights-font-weight-050);
    --fd-fonts-body-large-strong-strikethrough: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-large-regular-strikethrough: var(--fd-text-decoration-text-decoration-line-through);
    --fd-text-case-body-large-regular-strikethrough: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-large-regular-strikethrough: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-large-regular-strikethrough: var(--fd-font-sizes-font-size-050);
    --fd-line-heights-body-large-regular-strikethrough: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-large-regular-strikethrough: var(--fd-font-weights-font-weight-020);
    --fd-fonts-body-large-regular-strikethrough: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-large-strong: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-body-large-strong: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-large-strong: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-large-strong: var(--fd-font-sizes-font-size-050);
    --fd-line-heights-body-large-strong: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-large-strong: var(--fd-font-weights-font-weight-050);
    --fd-fonts-body-large-strong: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-large-regular: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-body-large-regular: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-large-regular: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-large-regular: var(--fd-font-sizes-font-size-050);
    --fd-line-heights-body-large-regular: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-large-regular: var(--fd-font-weights-font-weight-020);
    --fd-fonts-body-large-regular: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-x-large-strong-strikethrough: var(--fd-text-decoration-text-decoration-line-through);
    --fd-text-case-body-x-large-strong-strikethrough: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-x-large-strong-strikethrough: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-x-large-strong-strikethrough: var(--fd-font-sizes-font-size-060);
    --fd-line-heights-body-x-large-strong-strikethrough: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-x-large-strong-strikethrough: var(--fd-font-weights-font-weight-050);
    --fd-fonts-body-x-large-strong-strikethrough: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-x-large-regular-strikethrough: var(--fd-text-decoration-text-decoration-line-through);
    --fd-text-case-body-x-large-regular-strikethrough: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-x-large-regular-strikethrough: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-x-large-regular-strikethrough: var(--fd-font-sizes-font-size-060);
    --fd-line-heights-body-x-large-regular-strikethrough: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-x-large-regular-strikethrough: var(--fd-font-weights-font-weight-020);
    --fd-fonts-body-x-large-regular-strikethrough: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-x-large-strong: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-body-x-large-strong: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-x-large-strong: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-x-large-strong: var(--fd-font-sizes-font-size-060);
    --fd-line-heights-body-x-large-strong: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-x-large-strong: var(--fd-font-weights-font-weight-050);
    --fd-fonts-body-x-large-strong: var(--fd-fonts-font-family-010);
    --fd-text-decoration-body-x-large-regular: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-body-x-large-regular: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-body-x-large-regular: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-body-x-large-regular: var(--fd-font-sizes-font-size-060);
    --fd-line-heights-body-x-large-regular: var(--fd-line-heights-line-height-030);
    --fd-font-weights-body-x-large-regular: var(--fd-font-weights-font-weight-020);
    --fd-fonts-body-x-large-regular: var(--fd-fonts-font-family-010);
    --fd-text-decoration-heading-x-small: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-heading-x-small: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-heading-x-small: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-heading-x-small: var(--fd-font-sizes-font-size-030);
    --fd-line-heights-heading-x-small: var(--fd-line-heights-line-height-030);
    --fd-font-weights-heading-x-small: var(--fd-font-weights-font-weight-050);
    --fd-fonts-heading-x-small: var(--fd-fonts-font-family-010);
    --fd-text-decoration-heading-small: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-heading-small: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-heading-small: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-heading-small: var(--fd-font-sizes-font-size-040);
    --fd-line-heights-heading-small: var(--fd-line-heights-line-height-030);
    --fd-font-weights-heading-small: var(--fd-font-weights-font-weight-050);
    --fd-fonts-heading-small: var(--fd-fonts-font-family-010);
    --fd-text-decoration-heading-medium: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-heading-medium: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-heading-medium: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-heading-medium: var(--fd-font-sizes-font-size-050);
    --fd-line-heights-heading-medium: var(--fd-line-heights-line-height-030);
    --fd-font-weights-heading-medium: var(--fd-font-weights-font-weight-050);
    --fd-fonts-heading-medium: var(--fd-fonts-font-family-010);
    --fd-text-decoration-heading-large: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-heading-large: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-heading-large: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-heading-large: var(--fd-font-sizes-font-size-060);
    --fd-line-heights-heading-large: var(--fd-line-heights-line-height-030);
    --fd-font-weights-heading-large: var(--fd-font-weights-font-weight-050);
    --fd-fonts-heading-large: var(--fd-fonts-font-family-010);
    --fd-text-decoration-heading-x-large: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-heading-x-large: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-heading-x-large: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-heading-x-large: var(--fd-font-sizes-font-size-070);
    --fd-line-heights-heading-x-large: var(--fd-line-heights-line-height-030);
    --fd-font-weights-heading-x-large: var(--fd-font-weights-font-weight-050);
    --fd-fonts-heading-x-large: var(--fd-fonts-font-family-010);
    --fd-text-decoration-heading-xx-large: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-heading-xx-large: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-heading-xx-large: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-heading-xx-large: var(--fd-font-sizes-font-size-080);
    --fd-line-heights-heading-xx-large: var(--fd-line-heights-line-height-030);
    --fd-font-weights-heading-xx-large: var(--fd-font-weights-font-weight-050);
    --fd-fonts-heading-xx-large: var(--fd-fonts-font-family-010);
    --fd-text-decoration-jumbo-small: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-jumbo-small: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-jumbo-small: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-jumbo-small: var(--fd-font-sizes-font-size-090);
    --fd-line-heights-jumbo-small: var(--fd-line-heights-line-height-030);
    --fd-font-weights-jumbo-small: var(--fd-font-weights-font-weight-050);
    --fd-fonts-jumbo-small: var(--fd-fonts-font-family-010);
    --fd-text-decoration-jumbo-medium: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-jumbo-medium: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-jumbo-medium: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-jumbo-medium: var(--fd-font-sizes-font-size-100);
    --fd-line-heights-jumbo-medium: var(--fd-line-heights-line-height-030);
    --fd-font-weights-jumbo-medium: var(--fd-font-weights-font-weight-050);
    --fd-fonts-jumbo-medium: var(--fd-fonts-font-family-010);
    --fd-text-decoration-jumbo-large: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-jumbo-large: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-jumbo-large: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-jumbo-large: var(--fd-font-sizes-font-size-110);
    --fd-line-heights-jumbo-large: var(--fd-line-heights-line-height-030);
    --fd-font-weights-jumbo-large: var(--fd-font-weights-font-weight-050);
    --fd-fonts-jumbo-large: var(--fd-fonts-font-family-010);
    --fd-text-decoration-jumbo-x-large: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-jumbo-x-large: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-jumbo-x-large: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-jumbo-x-large: var(--fd-font-sizes-font-size-130);
    --fd-line-heights-jumbo-x-large: var(--fd-line-heights-line-height-020);
    --fd-font-weights-jumbo-x-large: var(--fd-font-weights-font-weight-050);
    --fd-fonts-jumbo-x-large: var(--fd-fonts-font-family-010);
    --fd-text-decoration-jumbo-xx-large: var(--fd-text-decoration-text-decoration-none);
    --fd-text-case-jumbo-xx-large: var(--fd-text-case-text-case-none);
    --fd-letter-spacings-jumbo-xx-large: var(--fd-letter-spacings-letter-spacing-010);
    --fd-font-sizes-jumbo-xx-large: var(--fd-font-sizes-font-size-140);
    --fd-line-heights-jumbo-xx-large: var(--fd-line-heights-line-height-020);
    --fd-font-weights-jumbo-xx-large: var(--fd-font-weights-font-weight-050);
    --fd-fonts-jumbo-xx-large: var(--fd-fonts-font-family-010);
    --fd-space-space-40: calc(var(--fd-space-space-base) * 40);
    --fd-space-space-30: calc(var(--fd-space-space-base) * 30);
    --fd-space-space-20: calc(var(--fd-space-space-base) * 20);
    --fd-space-space-16: calc(var(--fd-space-space-base) * 16);
    --fd-space-space-12: calc(var(--fd-space-space-base) * 12);
    --fd-space-space-10: calc(var(--fd-space-space-base) * 10);
    --fd-space-space-8: calc(var(--fd-space-space-base) * 8);
    --fd-space-space-6: calc(var(--fd-space-space-base) * 6);
    --fd-space-space-5: calc(var(--fd-space-space-base) * 5);
    --fd-space-space-4: calc(var(--fd-space-space-base) * 4);
    --fd-space-space-3: calc(var(--fd-space-space-base) * 3);
    --fd-space-space-2: calc(var(--fd-space-space-base) * 2);
    --fd-space-space-1: calc(var(--fd-space-space-base) * 1);
    --fd-space-space-05: calc(var(--fd-space-space-base) * 0.5);
    --fd-space-space-025: calc(var(--fd-space-space-base) * 0.25);
    --fd-space-space-0: calc(var(--fd-space-space-base) * 0);
    --fd-shadows-elevations-all-around-high: 0 0 16px 2px var(--fd-colors-core-black-tint-030);
    --fd-shadows-elevations-all-around-mid: 0 0 8px 2px var(--fd-colors-core-black-tint-020);
    --fd-shadows-elevations-all-around-low: 0 0 4px 2px var(--fd-colors-core-black-tint-010);
    --fd-shadows-elevations-bottom-high: 0 8px 16px 0 var(--fd-colors-core-black-tint-030);
    --fd-shadows-elevations-bottom-mid: 0 4px 8px 0 var(--fd-colors-core-black-tint-020);
    --fd-shadows-elevations-bottom-low: 0 2px 4px 0 var(--fd-colors-core-black-tint-010);
    --fd-shadows-elevations-top-high: 0 -8px 16px 0 var(--fd-colors-core-black-tint-030);
    --fd-shadows-elevations-top-mid: 0 -4px 8px 0 var(--fd-colors-core-black-tint-020);
    --fd-shadows-elevations-top-low: 0 -2px 4px 0 var(--fd-colors-core-black-tint-010);
    --fd-colors-product-gradients-faceoff: linear-gradient(125deg, var(--fd-colors-core-teal-l-2) 0%, var(--fd-colors-core-blue) 100%);
    --fd-colors-product-gradients-casino: linear-gradient(125deg, var(--fd-colors-core-purple-d-3) 0%, var(--fd-colors-core-blue-d-1) 100%);
    --fd-colors-product-gradients-sports: linear-gradient(125deg, var(--fd-colors-core-blue-d-1) 0%, var(--fd-colors-core-blue-d-3) 100%);
    --fd-radii-border-radius-default: var(--fd-radii-border-radius-020);
    --fd-border-widths-border-width-default: var(--fd-border-widths-border-width-010);
    --fd-colors-brand-gradient-default: linear-gradient(125deg, var(--fd-colors-brand-gradient-color-stops-start) 0%, var(--fd-colors-brand-gradient-color-stops-end) 100%);
    --fd-colors-system-verification-link-on-subtle: var(--fd-colors-link-default-base);
    --fd-colors-system-verification-link-on-default: var(--fd-colors-content-on-dark);
    --fd-colors-system-verification-content-on-subtle: var(--fd-colors-content-on-light);
    --fd-colors-system-verification-content-on-default: var(--fd-colors-content-on-dark);
    --fd-colors-system-neutral-link-on-subtle: var(--fd-colors-link-default-base);
    --fd-colors-system-neutral-link-on-default: var(--fd-colors-content-on-light);
    --fd-colors-system-neutral-content-on-subtle: var(--fd-colors-content-on-light);
    --fd-colors-system-neutral-content-on-default: var(--fd-colors-content-on-light);
    --fd-colors-system-important-link-on-subtle: var(--fd-colors-link-default-base);
    --fd-colors-system-important-link-on-default: var(--fd-colors-content-on-dark);
    --fd-colors-system-important-content-on-subtle: var(--fd-colors-content-on-light);
    --fd-colors-system-important-content-on-default: var(--fd-colors-content-on-dark);
    --fd-colors-system-warning-link-on-subtle: var(--fd-colors-link-default-base);
    --fd-colors-system-warning-link-on-default: var(--fd-colors-content-on-light);
    --fd-colors-system-warning-content-on-subtle: var(--fd-colors-content-on-light);
    --fd-colors-system-warning-content-on-default: var(--fd-colors-content-on-light);
    --fd-colors-system-alert-link-on-subtle: var(--fd-colors-link-default-base);
    --fd-colors-system-alert-link-on-default: var(--fd-colors-content-on-light);
    --fd-colors-system-alert-content-on-subtle: var(--fd-colors-content-on-light);
    --fd-colors-system-alert-content-on-default: var(--fd-colors-content-on-light);
    --fd-colors-system-positive-link-on-subtle: var(--fd-colors-link-default-base);
    --fd-colors-system-positive-link-on-default: var(--fd-colors-content-on-dark);
    --fd-colors-system-positive-content-on-subtle: var(--fd-colors-content-on-light);
    --fd-colors-system-positive-content-on-default: var(--fd-colors-content-on-dark);
    --fd-colors-system-info-link-on-subtle: var(--fd-colors-link-default-base);
    --fd-colors-system-info-link-on-default: var(--fd-colors-content-on-dark);
    --fd-colors-system-info-content-on-subtle: var(--fd-colors-content-on-light);
    --fd-colors-system-info-content-on-default: var(--fd-colors-content-on-dark);
    --fd-colors-border-brand: var(--fd-colors-brand-primary-default);
    --fd-colors-background-gradient-color-stops-end: var(--fd-colors-background-surface);
    --fd-colors-background-gradient-color-stops-start: var(--fd-colors-background-transparent);
    --fd-colors-background-brand: var(--fd-colors-brand-primary-default);
    --fd-colors-content-brand-secondary: var(--fd-colors-brand-secondary-default);
    --fd-colors-content-brand-primary: var(--fd-colors-brand-primary-default);
    --fd-colors-component-pill-inverse-background-base: var(--fd-colors-background-transparent);
    --fd-colors-component-data-input-icon-disabled: var(--fd-colors-content-disabled);
    --fd-colors-component-data-input-icon-error: var(--fd-colors-system-important-content-accent);
    --fd-colors-component-data-input-icon-active: var(--fd-colors-system-info-content-accent);
    --fd-colors-component-data-input-icon-base: var(--fd-colors-content-subtle);
    --fd-colors-component-data-input-password-meter-great: var(--fd-colors-system-positive-background-default);
    --fd-colors-component-data-input-password-meter-good: var(--fd-colors-system-info-background-default);
    --fd-colors-component-data-input-password-meter-fair: var(--fd-colors-system-warning-background-default);
    --fd-colors-component-data-input-password-meter-weak: var(--fd-colors-system-important-background-default);
    --fd-colors-component-data-input-password-meter-empty: var(--fd-colors-system-neutral-background-default);
    --fd-colors-component-data-input-label-disabled: var(--fd-colors-content-disabled);
    --fd-colors-component-data-input-label-error: var(--fd-colors-system-important-content-accent);
    --fd-colors-component-data-input-label-active: var(--fd-colors-system-info-content-accent);
    --fd-colors-component-data-input-label-base: var(--fd-colors-content-strong);
    --fd-colors-component-data-input-content-disabled: var(--fd-colors-content-disabled);
    --fd-colors-component-data-input-content-error: var(--fd-colors-system-important-content-accent);
    --fd-colors-component-data-input-content-hover: var(--fd-colors-system-info-content-accent);
    --fd-colors-component-data-input-content-subtle: var(--fd-colors-content-subtle);
    --fd-colors-component-data-input-content-base: var(--fd-colors-content-default);
    --fd-colors-component-data-input-border-disabled: var(--fd-colors-border-disabled);
    --fd-colors-component-data-input-border-error: var(--fd-colors-system-important-border-default);
    --fd-colors-component-data-input-border-active: var(--fd-colors-system-info-border-default);
    --fd-colors-component-data-input-border-base: var(--fd-colors-border-default);
    --fd-colors-component-data-input-background-error: var(--fd-colors-system-important-background-subtle);
    --fd-colors-component-data-input-background-hover: var(--fd-colors-background-hover);
    --fd-colors-component-data-input-background-layer: var(--fd-colors-background-layer);
    --fd-colors-component-data-input-background-base: var(--fd-colors-background-surface);
    --fd-colors-component-selection-control-border-error: var(--fd-colors-system-important-border-default);
    --fd-colors-component-selection-control-border-success: var(--fd-colors-system-positive-border-default);
    --fd-colors-component-selection-control-border-disabled: var(--fd-colors-border-disabled);
    --fd-colors-component-selection-control-border-base: var(--fd-colors-border-default);
    --fd-colors-component-selection-control-background-error: var(--fd-colors-system-important-background-default);
    --fd-colors-component-selection-control-background-success: var(--fd-colors-system-positive-background-default);
    --fd-colors-component-selection-control-background-base: var(--fd-colors-background-surface);
    --fd-colors-component-button-button-link-border-disabled: var(--fd-colors-border-disabled);
    --fd-colors-component-button-tertiary-border-disabled: var(--fd-colors-border-disabled);
    --fd-colors-background-gradient-surface-fade-bottom: linear-gradient(180deg, var(--fd-colors-background-gradient-color-stops-start) 0%, var(--fd-colors-background-gradient-color-stops-end) 100%);
    --fd-colors-background-gradient-surface-fade-end: linear-gradient(90deg, var(--fd-colors-background-gradient-color-stops-start) 0%, var(--fd-colors-background-gradient-color-stops-end) 100%);
  }
                           
  :root[data-mode="dark"] {
    --fd-value-test: blue;
  }
`;
