import { Platform, View, Pressable, TextInput, Text } from "react-native";
import styled, { css } from "styled-components/native";
import {
  space,
  width,
  color,
  borderColor,
  compose
} from "../../theming/styledSystem";
import { InputBoxProps, StyledInputProps } from "./types";

const backgroundColors = {
  default: "white.900",
  disabled: "blue.000",
  pressed: "blue_accent.100",
  hover: "blue_accent.000"
};

const borderColors = {
  default: "blue.100",
  hovered: "blue_accent.300",
  focused: "blue_accent.500",
  invalid: "orange.600"
};

const borderDefault = {
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "2px",
  borderColor: borderColors.default
};

const cssBorder = ({ isInvalid, isFocused }: InputBoxProps) => ({
  ...borderDefault,
  borderWidth: isFocused || isInvalid ? "2px" : borderDefault.borderWidth
});

const cssBoxShadow = ({
  isPressed,
  isDisabled,
  isHovered,
  theme
}: InputBoxProps) => {
  if (isDisabled || isPressed) {
    return { boxShadow: "none" };
  }

  return isHovered
    ? { boxShadow: theme.elevations.medium }
    : { boxShadow: theme.elevations.low };
};

const styledUtils = compose(space, width, color, borderColor);

export const InputBox = styled(View).attrs<InputBoxProps>((props) => ({
  bg:
    ((props.isDisabled || !props.editable) && backgroundColors.disabled) ||
    (props.isPressed && backgroundColors.pressed) ||
    (props.isHovered && backgroundColors.hover) ||
    backgroundColors.default,
  borderColor:
    (props.isFocused && borderColors.focused) ||
    (props.isInvalid && borderColors.invalid) ||
    (props.isHovered && borderColors.hovered) ||
    borderColors.default
}))<InputBoxProps>`
  flex-grow: 1;
  width: auto;
  height: 42px;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
  ${cssBoxShadow}
  ${cssBorder};
  ${styledUtils};
  ${({ isSelect }) =>
    isSelect &&
    css`
      margin-left: 0;
      margin-right: 0;
    `}
`;

export const StyledInput = styled(TextInput).attrs<{
  isDisabled: boolean;
}>((props) => ({
  placeholderTextColor: props.isDisabled
    ? props.theme.colors?.blue[300]
    : props.theme.colors?.grey[700]
}))<StyledInputProps>`
  ${Platform.OS === "web" &&
  css`
    outline-style: none;
  `};
  flex-grow: 1;
  width: auto;
  height: ${({ isNested }) => (isNested ? "100%" : "42px")};
  ${({ theme, isDisabled }) => css`
    padding: ${theme.space["space-4"]};
    font-family: ${theme.fonts?.regular};
    font-size: ${theme.fontSizes?.s};
    color: ${isDisabled ? theme.colors?.blue[300] : theme.colors?.grey[900]};
  `}
`;

export const StyledPressable = styled(Pressable)`
  align-items: stretch;
  height: 42px;
  flex-grow: 1;
  flex-shrink: 1;
`;

export const TopWrapper = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

export const LabelWrapper = styled(View)`
  flex-direction: row;
  align-content: flex-start;
  align-items: center;
`;

export const StyledLabel = styled(Text)`
  ${space};
  ${({ theme }) => css`
    font-family: ${theme.fonts?.medium};
    font-size: ${theme.fontSizes?.s};
    color: ${theme.colors?.grey[900]};
  `}
`;

export const StyledOptionalTag = styled(Text)`
  ${({ theme }) => css`
    margin-left: ${theme.space["space-1"]};
    font-family: ${theme.fonts?.medium};
    font-size: ${theme.fontSizes?.xs};
    color: ${theme.colors?.grey[700]};
  `}
`;

export const InputWrapper = styled(View)(compose(space, width));

export const IconWrapper = styled(View)`
  margin-left: ${({ theme }) => theme.space["space-1"]};
`;

export const MessageWrapper = styled(View)`
  ${space};
  flex-direction: row;
`;
