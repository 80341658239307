import { Store } from "redux";
import { get } from "lodash";

export const getMazoomaToggle = (store: Store) => {
  const pawsMZMToggle = get(store, "capi.featureToggles.pawsMZM", false);
  if (get(store, "capi.featureToggles.enablePawsMZMAbTesting", false)) {
    return pawsMZMToggle && get(store, "capi.abTesting.pawsMZM", false);
  }
  return pawsMZMToggle;
};

export const getMZMHideActionsToggle = (store: Store) =>
  get(store, "capi.featureToggles.pawsHideMZMActions", false);

export const isCardDeclinedToggle = (store: Store) =>
  get(store, "capi.featureToggles.pawsCardDeclined", false);

export const getPaymentSelectorToggle = (store: Store) =>
  get(store, "capi.featureToggles.pawsPaymentSelector", false);

export const getMazoomaSubscriptionToggle = (store: Store) =>
  get(store, "capi.featureToggles.pawsMazoomaSubscription", false);
