import React, { memo } from "react";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { isMobile } from "../../utils";
import Button from "../FundsModal/common/Button";
import type { Props } from "./types";

import {
  Container,
  Description,
  Limits,
  Limit,
  LimitValue
} from "./styled-components";

const MoneypakLimitsModal = ({
  title,
  description,
  tableData,
  buttonText = "Got it",
  isOpen,
  onClose
}: Props) => (
  <ModalV2
    isOpen={isOpen}
    onClose={onClose}
    title={title}
    animation="fade"
    hasRoundedCorners
    isContentTransparent
    isFullHeight={false}
    hasHeader={true}
    isFullWidth={isMobile}
    fixedWidth="351px"
    qaLabel="moneypak-limits-modal"
  >
    {() => (
      <Container>
        <Description data-qa-label="moneypak-limits-description">
          {description}
        </Description>
        {tableData && (
          <Limits data-qa-label="moneypak-limits-table">
            {tableData?.map((limit, index) => (
              <Limit
                data-qa-label="moneypak-limits-row"
                key={`Limit-${index + 1}`}
              >
                <LimitValue data-qa-label="moneypak-limits-value-time">
                  {limit.time}
                </LimitValue>
                <LimitValue data-qa-label="moneypak-limits-value-deposits">
                  {limit.deposits}
                </LimitValue>
                <LimitValue data-qa-label="moneypak-limits-value-amount">
                  {limit.amount}
                </LimitValue>
              </Limit>
            ))}
          </Limits>
        )}
        <Button
          text={buttonText}
          onClick={onClose}
          isLoading={false}
          hasError={false}
          qaLabel="close-moneypak-limits"
          bgColor={buildColor("blue_accent", "500")}
        />
      </Container>
    )}
  </ModalV2>
);

export default memo(MoneypakLimitsModal);
