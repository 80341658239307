import { useState } from "react";
import { GestureResponderEvent, NativeSyntheticEvent } from "react-native";

export interface EventsState {
  isHovered?: boolean;
  isPressed?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
}

export const useEvents = <T>(
  {
    isHovered = false,
    isPressed = false,
    isFocused = false,
    isSelected = false
  }: EventsState,
  enable = true
) => {
  const [events, setEvents] = useState<Required<EventsState>>({
    isHovered,
    isPressed,
    isFocused,
    isSelected
  });

  const mouseEnterHandler = (event: GestureResponderEvent) => {
    event.persist();
    if (enable) setEvents({ ...events, isHovered: true });
  };

  const mouseLeaveHandler = (event: GestureResponderEvent) => {
    event.persist();
    if (enable) setEvents({ ...events, isHovered: false, isPressed: false });
  };

  const pressInHandler = (event: GestureResponderEvent) => {
    event.persist();
    if (enable) setEvents({ ...events, isPressed: true });
  };

  const pressOutHandler = () => {
    if (enable) setEvents({ ...events, isPressed: false });
  };

  const focusHandler = (
    event: GestureResponderEvent | NativeSyntheticEvent<T>
  ) => {
    event.persist();
    if (enable) setEvents({ ...events, isFocused: true });
  };

  const blurHandler = (event: NativeSyntheticEvent<T>) => {
    event.persist();
    if (enable) setEvents({ ...events, isFocused: false });
  };

  const hoverHandlers = {
    onMouseEnter: mouseEnterHandler,
    onMouseLeave: mouseLeaveHandler
  };

  const pressHandlers = {
    onMouseDown: pressInHandler,
    onMouseUp: pressOutHandler,
    onPressIn: pressInHandler,
    onPressOut: pressOutHandler,
    onTouchStart: pressInHandler,
    onTouchEnd: pressOutHandler
  };

  const focusHandlers = {
    onFocus: focusHandler,
    onBlur: blurHandler
  };

  const eventsHandlers = {
    ...hoverHandlers,
    ...pressHandlers,
    ...focusHandlers
  };

  return {
    events,
    setEvents,
    eventsHandlers,
    hoverHandlers,
    pressHandlers,
    focusHandlers
  };
};
