// @flow
import mediatorChannels from "@tvg/mediator";
import { get, capitalize } from "lodash";
import tvgConf from "@tvg/conf";
import { PaymentType } from "@tvg/wallet/src/types";
import pushToDataLayer, { getSiteVersion } from "../gtmUtils";

type SelectPaymentTypeOrMethod = {
  payload: {
    isNewCard?: boolean,
    paymentType: string
  }
};

type DepositWithdrawOptionsModal = {
  payload: {
    isOpen: boolean,
    paymentType: string
  }
};

type NavigateDepositWithdrawOptions = {
  payload: {
    selectedOption: string,
    paymentType: string
  }
};

type NewPaymentTypeSelected = {
  payload: {
    module: string,
    paymentType: string
  }
};

type DepositNewPaymentMethod = {
  payload: {
    paymentType: string
  }
};

type WithdrawAddressModal = {
  payload: {
    paymentType: string,
    accountID: string
  }
};

type DepositAddressModal = {
  payload: {
    paymentType: string,
    accountID: string
  }
};

type WithdrawAddressModalCS = {
  payload: {
    paymentType: string,
    accountID: string,
    linkText: string
  }
};

type OtherPaymentMethods = {
  payload: {
    linkText: string
  }
};

type OtherPaymentMethodsOpenTab = {
  payload: {
    paymentType: string
  }
};

type PaymentMethodSelector = {
  payload: {
    currentPaymentType: PaymentType
  }
};

type PaymentMethodSelectorChange = {
  payload: {
    currentPaymentType: PaymentType,
    selectedPaymentType: PaymentType
  }
};
type DeletePaymentMethod = {
  payload: {
    paymentType: string
  }
};

type FirstDepositInteraction = {
  payload: {
    accountId: string,
    paymentType: string,
    isFirstDeposit: boolean
  }
};
type WalletPageView = {
  accountId: string,
  customerStatus: "New" | "Returning",
  privateBrowser: "Yes" | "No",
  productVersion: string,
  registrationStatus: "Registered",
  residenceState: string,
  siteVersion: string,
  balance: number
};
type UserAccountWalletIconsClick = {
  destinationUrl: string,
  iconText: string
};

type DepositSuccess = {
  isFirstDeposit: boolean,
  paymentType: string,
  accountID: string,
  amount: string
};

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);

  mediatorChannels.base.subscribe(
    "PAWS:WITHDRAW_VALIDATION_ERROR",
    (data: SelectPaymentTypeOrMethod) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventAction: "Withdrawal Error",
        gaEventCategory: "Withdrawal",
        gaEventLabel: get(data, "payload.errorMessage", ""),
        depositType: get(data, "payload.paymentType", ""),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Withdrawal"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:WITHDRAW_AUTHORIZE_TRANSACTION",
    (data: SelectPaymentTypeOrMethod) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventAction: "Withdrawal Attempt",
        gaEventCategory: "Withdrawal",
        gaEventLabel: undefined,
        depositType: get(data, "payload.paymentType", ""),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Withdrawal"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:SELECT_PAYMENT_TYPE_OR_METHOD",
    (data: SelectPaymentTypeOrMethod) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: get(data, "payload.isNewCard", false)
          ? "New Payment Type Selected"
          : "Saved Payment Type Selected",
        gaEventCategory: "Wallet",
        gaEventLabel: get(data, "payload.paymentType", ""),
        module: "Wallet"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:NEW_PAYMENT_TYPE_SELECTED",
    (data: NewPaymentTypeSelected) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "New Payment Type Selected",
        gaEventCategory: "Wallet",
        gaEventLabel: get(data, "payload.paymentType", ""),
        module: get(data, "payload.module", "")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:OPEN_PAYMENT_TYPE_MODAL",
    (data: SelectPaymentTypeOrMethod) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "Modal Opened",
        gaEventCategory: "Wallet",
        gaEventLabel: "Deposit",
        module: get(data, "payload.paymentType")
      });
    }
  );

  mediatorChannels.base.subscribe("PAWS:WALLET_GO_BACK", () => {
    pushToDataLayer({
      event: "wallet",
      gaEventAction: "Back Arrow Clicked",
      gaEventCategory: "Wallet",
      gaEventLabel: undefined,
      module: "Wallet"
    });
  });

  // Open or Close deposit/withdraw options modal
  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_WITHDRAW_OPTIONS_MODAL",
    (data: DepositWithdrawOptionsModal) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: get(data, "payload.isOpen")
          ? "Payment Draw Opened"
          : "Payment Draw Closed",
        gaEventCategory: "Wallet",
        gaEventLabel: get(data, "payload.paymentType", ""),
        module: "Wallet"
      });
    }
  );

  // When user navigates to deposit or withdraw from the wallet
  mediatorChannels.base.subscribe(
    "PAWS:NAVIGATE_DEPOSIT_WITHDRAW_OPTIONS",
    (data: NavigateDepositWithdrawOptions) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "Navigated To",
        gaEventCategory: "Wallet",
        gaEventLabel: capitalize(get(data, "payload.selectedOption")), // "Deposit" or "Withdraw"
        module: get(data, "payload.paymentType", "")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_ATTEMPT",
    (data: NavigateDepositWithdrawOptions) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "Deposit Attempt",
        gaEventCategory: "Deposit",
        gaEventLabel: undefined,
        customerStatus: get(data, "payload.isFirstDeposit")
          ? "New"
          : "Returning", // pass depending on first deposit or not
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Deposit"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_VALIDATION_MESSAGE",
    (data: NavigateDepositWithdrawOptions) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "Deposit Error",
        gaEventCategory: "Deposit",
        gaEventLabel: get(data, "payload.validationMessage"),
        customerStatus: get(data, "payload.isFirstDeposit")
          ? "New"
          : "Returning", // pass depending on first deposit or not
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Deposit"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_ERROR_MESSAGE",
    (data: NavigateDepositWithdrawOptions) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "Deposit Failed",
        gaEventCategory: "Deposit",
        gaEventLabel: get(data, "payload.errorMessage"),
        customerStatus: get(data, "payload.isFirstDeposit")
          ? "New"
          : "Returning", // pass depending on first deposit or not
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Deposit"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_SUCCESS",
    (data: DepositSuccess) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "Deposit Success",
        gaEventCategory: "Deposit",
        gaEventLabel: undefined,
        customerStatus: get(data, "payload.isFirstDeposit")
          ? "New"
          : "Returning", // pass depending on first deposit or not
        depositType: get(data, "payload.paymentType"),
        module: "Deposit",
        depositAmount: get(data, "payload.amount"),
        siteVersion,
        accountId: get(data, "payload.accountID")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_CLOSE",
    (data: NavigateDepositWithdrawOptions) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "Modal Closed",
        gaEventCategory: "Deposit",
        gaEventLabel: "Deposit Modal",
        module: get(data, "payload.isCreate") ? "Create" : "Deposit",
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_CREATION_RETURN",
    (data: NavigateDepositWithdrawOptions) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "Back Arrow Clicked",
        gaEventCategory: "Deposit",
        gaEventLabel: "Return to creation modal",
        module: "Deposit",
        depositType: get(data, "payload.paymentType")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_DEFAULT_SELECT",
    (data: NavigateDepositWithdrawOptions) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "Default Amount Selected",
        gaEventCategory: "Deposit",
        gaEventLabel: "Deposit",
        module: "Deposit",
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_NEW_PAYMENT_METHOD",
    (data: DepositNewPaymentMethod) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "New Payment Method Success",
        gaEventCategory: "Wallet",
        gaEventLabel: get(data, "payload.paymentType"),
        module: "wallet"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:WITHDRAW_CLOSE",
    (data: NavigateDepositWithdrawOptions) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventAction: "Modal Closed",
        gaEventCategory: "Withdrawal",
        gaEventLabel: "Withdrawal",
        module: "Withdrawal",
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:WITHDRAW_SUCCESS",
    (data: NavigateDepositWithdrawOptions) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventAction: "Withdrawal Success",
        gaEventCategory: "Withdrawal",
        gaEventLabel: undefined,
        depositType: get(data, "payload.paymentType"),
        module: "Withdrawal",
        depositAmount: get(data, "payload.amount"),
        siteVersion,
        accountId: get(data, "payload.accountID")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:WITHDRAW_ERROR_MESSAGE",
    (data: NavigateDepositWithdrawOptions) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventAction: "Withdrawal Failed",
        gaEventCategory: "Withdrawal",
        gaEventLabel: get(data, "payload.errorMessage"),
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Withdrawal"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:WITHDRAW_ADDRESS_MODAL_VIEW",
    (data: WithdrawAddressModal) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventAction: "Modal Opened",
        gaEventCategory: "Withdrawal",
        gaEventLabel: "Address Confirmation",
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: get(data, "payload.paymentType")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:WITHDRAW_ADDRESS_MODAL_USER_SELECTS_ADDRESS",
    (data: WithdrawAddressModal) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventAction: "Withdrawal Address Selected",
        gaEventCategory: "Withdrawal",
        gaEventLabel: undefined,
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Address Confirmation Modal"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:WITHDRAW_ADDRESS_MODAL_USER_CONFIRMS_ADDRESS",
    (data: WithdrawAddressModal) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventAction: "CTA Clicked",
        gaEventCategory: "Withdrawal",
        gaEventLabel: "Confirm",
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Address Confirmation Modal"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:WITHDRAW_ADDRESS_MODAL_USER_CLICKS_CANCEL",
    (data: WithdrawAddressModal) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventAction: "CTA Clicked",
        gaEventCategory: "Withdrawal",
        gaEventLabel: "Cancel",
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Address Confirmation Modal"
      });
    }
  );

  // Deposit Addrress gtm events
  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_ADDRESS_MODAL_VIEW",
    (data: DepositAddressModal) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "Modal Opened",
        gaEventCategory: "Deposit",
        gaEventLabel: "Address Confirmation",
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: get(data, "payload.paymentType")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_ADDRESS_MODAL_USER_SELECTS_ADDRESS",
    (data: WithdrawAddressModal) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "Deposit Address Selected",
        gaEventCategory: "Deposit",
        gaEventLabel: undefined,
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Address Confirmation Modal"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_ADDRESS_MODAL_USER_CONFIRMS_ADDRESS",
    (data: DepositAddressModal) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "CTA Clicked",
        gaEventCategory: "Deposit",
        gaEventLabel: "Confirm",
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Address Confirmation Modal"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_ADDRESS_MODAL_USER_CLICKS_CANCEL",
    (data: DepositAddressModal) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "CTA Clicked",
        gaEventCategory: "Deposit",
        gaEventLabel: "Cancel",
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Address Confirmation Modal"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_ADDRESS_MODAL_USER_CLICKS_MESSAGE_CUSTOMER_SERVICE",
    (data: WithdrawAddressModalCS) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "CTA Clicked",
        gaEventCategory: "Deposit",
        gaEventLabel: get(data, "payload.linkText"),
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Address Confirmation Modal"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:WITHDRAW_ADDRESS_MODAL_USER_CLICKS_MESSAGE_CUSTOMER_SERVICE",
    (data: WithdrawAddressModalCS) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventAction: "CTA Clicked",
        gaEventCategory: "Withdrawal",
        gaEventLabel: get(data, "payload.linkText"),
        depositType: get(data, "payload.paymentType"),
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountID"),
        module: "Address Confirmation Modal"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:OTHER_PAYMENT_METHODS_OPEN_TAB",
    (data: OtherPaymentMethodsOpenTab) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "Tab Opened",
        gaEventCategory: "Wallet",
        gaEventLabel: get(data, "payload.paymentType"),
        module: "Other Payment Methods"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:OTHER_PAYMENT_METHODS_CTA_CLICK",
    (data: OtherPaymentMethods) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "CTA Clicked",
        gaEventCategory: "Wallet",
        gaEventLabel: get(data, "payload.linkText"),
        module: "Other Payment Methods"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:OTHER_PAYMENT_METHODS_PDF_DOWNLOAD",
    (data: OtherPaymentMethods) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "PDF Downloaded",
        gaEventCategory: "Wallet",
        gaEventLabel: get(data, "payload.linkText"),
        module: "Other Payment Methods"
      });
    }
  );

  mediatorChannels.base.subscribe("PAWS:ADD_PAYMENT_METHOD", () => {
    pushToDataLayer({
      event: "wallet",
      gaEventAction: "CTA Clicked",
      gaEventCategory: "Wallet",
      gaEventLabel: "Add Payment Method",
      module: "Wallet"
    });
  });

  mediatorChannels.base.subscribe(
    "PAWS:PAYMENT_SELECTOR_OPEN",
    (data: PaymentMethodSelector) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "Modal Opened",
        gaEventCategory: "Wallet",
        gaEventLabel: "Change Payment Method",
        module: get(data, "payload.currentPaymentType", "")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:PAYMENT_SELECTOR_ADD_METHOD",
    (data: PaymentMethodSelector) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "CTA Clicked",
        gaEventCategory: "Wallet",
        gaEventLabel: "Add Payment Method",
        module: get(data, "payload.currentPaymentType", "")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAWS:PAYMENT_SELECTOR_CHANGE_METHOD",
    (data: PaymentMethodSelectorChange) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "Change Payment Method Selected",
        gaEventCategory: "Wallet",
        gaEventLabel: get(data, "payload.selectedPaymentType", ""),
        module: get(data, "payload.currentPaymentType", "")
      });
    }
  );

  // When a user clicks the delete payment method button:
  mediatorChannels.base.subscribe(
    "PAWS:DELETE_PAYMENT_METHOD_BUTTON",
    (data: DeletePaymentMethod) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "Delete Payment Method Clicked",
        gaEventCategory: "Wallet",
        gaEventLabel: get(data, "payload.paymentType"),
        module: "Wallet"
      });
    }
  );

  // When a user successfully deletes a payment method:
  mediatorChannels.base.subscribe(
    "PAWS:DELETE_PAYMENT_METHOD_SUCCESS",
    (data: DeletePaymentMethod) => {
      pushToDataLayer({
        event: "wallet",
        gaEventAction: "Payment Method Deleted",
        gaEventCategory: "Wallet",
        gaEventLabel: get(data, "payload.paymentType"),
        module: "Wallet"
      });
    }
  );

  // On first click interaction on a deposit modal when it opens:
  mediatorChannels.base.subscribe(
    "PAWS:DEPOSIT_FIRST_INTERACTION",
    (data: FirstDepositInteraction) => {
      pushToDataLayer({
        event: "deposit",
        gaEventAction: "Deposit Start",
        gaEventCategory: "Deposit",
        gaEventLabel: undefined,
        customerStatus: get(data, "payload.isFirstDeposit")
          ? "New"
          : "Returning", // pass depending on first deposit or not
        depositType: get(data, "payload.paymentType"),
        module: "Deposit",
        depositAmount: undefined,
        siteVersion,
        accountId: get(data, "payload.accountId")
      });
    }
  );

  // When a user loads the wallet
  mediatorChannels.base.subscribe(
    "PAWS:WALLET_PAGEVIEW",
    (data: WalletPageView) => {
      pushToDataLayer({
        event: "ga_pageload",
        accountId: get(data, "payload.accountId"),
        customerStatus: get(data, "payload.customerStatus"),
        graphVersion: "v2",
        loginStatus: "Logged In",
        page: "/wallet",
        privateBrowser: get(data, "payload.privateBrowser"),
        productVersion: get(data, "payload.productVersion"),
        registrationStatus: "Registered",
        residenceState: get(data, "payload.residenceState"),
        screenName: "Wallet",
        sectionName: "PAWS",
        siteVersion: get(data, "payload.siteVersion"),
        balance: get(data, "payload.balance")
      });
    }
  );

  // When a user clicks either on Deposit, Withdraw or Wallet icons on the Account Pannel
  mediatorChannels.base.subscribe(
    "PAWS:USER_ACCOUNT_WALLET_ICONS_CLICK",
    (data: UserAccountWalletIconsClick) => {
      pushToDataLayer({
        event: "navigation",
        destinationUrl: get(data, "payload.destinationUrl"),
        gaEventAction: "Navigated To",
        gaEventCategory: "Navigation",
        gaEventLabel: get(data, "payload.iconText"),
        menu: undefined,
        module: "Header",
        tag: undefined
      });
    }
  );
};
