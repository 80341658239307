import React from "react";
import formatCurrency from "@tvg/formatter/currency";
import { Container, Row, Value } from "./styled-components";
import FeeMask from "../../../Masks/feeMask";

type Props = {
  className?: string;
  value: string;
  fee: string;
  isFeeCalculating?: boolean;
  showBorderBottom?: boolean;
};

const Summary = ({
  value,
  fee,
  className,
  isFeeCalculating = false,
  showBorderBottom = false
}: Props) => (
  <Container className={className} showBorderBottom={showBorderBottom}>
    <Row>
      <Value data-qa-label="summary-subtotal-label">Subtotal</Value>
      <Value data-qa-label="summary-subtotal-value">
        {formatCurrency(+value)}
      </Value>
    </Row>
    <Row>
      <Value data-qa-label="summary-fee-label">Fees </Value>
      <Value data-qa-label="summary-fee-value">
        {isFeeCalculating ? <FeeMask /> : formatCurrency(+fee)}
      </Value>
    </Row>
    <Row>
      <Value bold data-qa-label="summary-total-label">
        Total
      </Value>
      <Value bold data-qa-label="summary-total-value">
        {formatCurrency(+value + +fee)}
      </Value>
    </Row>
  </Container>
);

export default Summary;
