import React from "react";
import { noop, get } from "lodash";
import { Store } from "redux";
import { useSelector, useDispatch } from "react-redux";
import tvgConf from "@tvg/conf";
import {
  getPawsContent,
  getInfoPanelModal,
  getPawsACHAllowAddToggle
} from "@tvg/sh-lib-paws/redux/selectors";
import { getMazoomaToggle } from "@tvg/sh-lib-paws/redux/toggles";
import { closeInfoPanelModal } from "@tvg/sh-lib-paws/redux/slices/infoPanelSlice";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { PawsContent, PaymentTypeOption } from "../../types";
import { PaymentMethods, PaymentsList, Title } from "./styled-components";
import AlertMessagesModal from "../AlertMessagesModal";
// eslint-disable-next-line jest/no-mocks-import
import { methodsInfos } from "../../../__mocks__/methods.mock";
import type {
  PaymentType,
  PaymentTypeInfo,
  PaymentTypeItem,
  UnaryFn,
  NullaryFn,
  PaymentTypeTag
} from "../../types";
import {
  getPaymentMethodIconName,
  getTagsByPaymentType,
  getPaymentMethodDescription
} from "../../utils";
import ListItem from "../ListItem";

type Props = {
  methods: PaymentTypeItem[];
  methodsInfos: { [key in PaymentType]: PaymentTypeInfo };
  onClickMethods: UnaryFn<PaymentTypeOption, void>;
  hasOtherMethods: boolean;
  otherMethodTitle: string;
  otherMethodDescription: string;
  onClickOtherMethod: NullaryFn<void>;
};

export const isCC = ({ paymentType }: { paymentType: PaymentType }) =>
  paymentType === "CC" ||
  paymentType === "CCP" ||
  paymentType === "CCS" ||
  paymentType === "DEB" ||
  paymentType === "DEBS";

const NewPaymentMethodsList = (props: Props) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(closeInfoPanelModal());
  };

  const pawsLabels = useSelector<Store, PawsContent>(getPawsContent);
  const cardLimitsLabels = get(pawsLabels, "paymentMessageModal.cardLimit", {});

  const isMZMToggleOn = useSelector<Store, boolean>(getMazoomaToggle);
  const allowCreateACH = useSelector<Store, boolean>(getPawsACHAllowAddToggle);

  const isInfoPanelModalOpen = useSelector(getInfoPanelModal);
  const paymentTypeTags: PaymentTypeTag[] = useSelector((store) =>
    parseCapiMessage(store, "capi.messages.pawsPaymentTypeTags", {})
  );

  return (
    <>
      <AlertMessagesModal
        isMobile={tvgConf().device === "mobile"}
        isOpen={!!isInfoPanelModalOpen}
        qaLabel="alert-messages"
        title={cardLimitsLabels.title}
        description={cardLimitsLabels.description}
        type="warning"
        onCloseMethod={closeModal}
        actions={[
          {
            text: cardLimitsLabels.primaryButton,
            onClick: closeModal,
            isStretched: true,
            isUppercase: false,
            qaLabel: "close"
          }
        ]}
      />

      <PaymentMethods data-qa-label="new-payment-methods-container">
        <Title data-qa-label="new-payment-methods-title">
          Deposit with a new payment method
        </Title>
        <PaymentsList data-qa-label="new-payment-methods-list">
          {props.methods
            .filter(
              (item) =>
                (isMZMToggleOn ? true : item.paymentType !== "MZM") &&
                (allowCreateACH ? true : item.paymentType !== "ACH")
            )
            .map((method: PaymentTypeItem, index: number) => (
              <ListItem
                iconName={getPaymentMethodIconName(method.paymentType)}
                onClick={() => {
                  props.onClickMethods({
                    id: "",
                    depositsAvailable: method.depositsAvailable,
                    withdrawalsAvailable: method.withdrawalsAvailable,
                    minLimit: method.minLimit,
                    maxLimit: method.maxLimit,
                    paymentType: method.paymentType,
                    title: get(props.methodsInfos[method.paymentType], "title"),
                    description: get(
                      props.methodsInfos[method.paymentType],
                      "description"
                    )
                  } as PaymentTypeOption);
                }}
                key={`new-payment-methods-${index.toString()}`}
                displayBadge={
                  method.paymentType === "CCP" || method.paymentType === "MZM"
                }
                tags={getTagsByPaymentType(paymentTypeTags, method.paymentType)}
                isDisabled={
                  !method.depositsAvailable || !method.paymentTypeAvailable
                }
                {...props.methodsInfos[method.paymentType]}
                description={getPaymentMethodDescription(
                  !method.depositsAvailable,
                  pawsLabels,
                  method.paymentType,
                  props.methodsInfos[method.paymentType].description,
                  !method.paymentTypeAvailable
                )}
                showOnlineBankingIcons={method.paymentType === "MZM"}
              />
            ))}
          {props.hasOtherMethods && (
            <ListItem
              iconName="add"
              onClick={props.onClickOtherMethod}
              title={props.otherMethodTitle}
              description={props.otherMethodDescription}
            />
          )}
        </PaymentsList>
      </PaymentMethods>
    </>
  );
};
NewPaymentMethodsList.defaultProps = {
  onClickMethods: noop,
  methods: [],
  methodsInfos
};

export default NewPaymentMethodsList;
