import styled from "styled-components";

export const OpenButtonWrapper = styled.div`
  button {
    border: 1px solid
      ${({ theme }) => theme.colorTokens.component.button.tertiary.border.base};
  }
`;

export const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 318px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  border-radius: ${({ theme }) => theme.radii.s};
  z-index: 1;
`;

export const SelectorContainer = styled.div`
  position: relative;
`;

export const SelectorHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 54px;
  box-shadow: ${({ theme }) => theme.elevations.low};
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  z-index: 1;

  &::after {
    content: "";
    flex: 1;
  }
`;

export const CloseButtonWrapper = styled.div`
  width: 18px;
  display: flex;
  align-items: center;
  flex: 1;
`;

export const SelectorTitle = styled.span`
  align-self: center;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;
  color: ${({ theme }) => theme.colorTokens.content.strong};
`;

export const YearWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const YearLabel = styled.div`
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.space["space-3"]} ${theme.space["space-5"]}`};
  background-color: ${({ theme }) => theme.colorTokens.background.base};
  color: ${({ theme }) => theme.colorTokens.content.strong};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
`;

export const PickerWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.space["space-3"]} ${theme.space["space-4"]}`};
`;

export const PickerButtonWrapper = styled.div<{
  isSelectedMonth: boolean;
}>`
  width: 33.3%;
  button {
    width: 100%;
  }
`;
