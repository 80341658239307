import React, { createContext, useState, useEffect } from "react";
import { StyledContainer } from "./styled-components";
import type { CheckboxGroupContextProps, CheckboxGroupProps } from "./types";

const initialState = {
  selectedValues: [],
  onChange: () => {},
  values: new Set<string>()
};

export const GroupContext = createContext<
  CheckboxGroupContextProps & {
    values: Set<string>;
  }
>(initialState);

export const CheckboxGroup = ({
  children,
  selectedValues,
  qaLabel,
  isDisabled = false,
  onChange,
  ...rest
}: CheckboxGroupProps) => {
  const [values, setValues] = useState<Set<string>>(new Set([]));

  useEffect(() => {
    setValues(new Set(selectedValues));
  }, [String(selectedValues)]);

  return (
    <StyledContainer {...rest} data-qa-label={qaLabel}>
      <GroupContext.Provider
        value={{ values, selectedValues, onChange, isDisabled }}
      >
        <div>{children}</div>
      </GroupContext.Provider>
    </StyledContainer>
  );
};
