import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Error = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M22.8755 12.0042C22.8755 18.0105 18.0063 22.8797 12 22.8797C5.99362
      22.8797 1.1245 18.0105 1.1245 12.0042C1.1245 5.99782 5.99362 1.12871 12
      1.12871C18.0063 1.12871 22.8755 5.99782 22.8755 12.0042Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M7.50005 7.5L12.0001 12M12.0001 12L16.5001 16.5M12.0001 12L7.50005
      16.5M12.0001 12L16.5001 7.5M22.8755 12.0042C22.8755 18.0105 18.0063
      22.8797 12 22.8797C5.99362 22.8797 1.1245 18.0105 1.1245 12.0042C1.1245
      5.99782 5.99362 1.12871 12 1.12871C18.0063 1.12871 22.8755 5.99782
      22.8755 12.0042Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);
