import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontMedium } from "@tvg/atomic-ui/_static/Typography";

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

const flexColumnFullHeight = css`
  ${flexColumn};
  flex: 1;
`;

export const Divider = styled.div`
  padding: 16px 0 0 0;
  border-top: 1px solid ${buildColor("blue", "100")};
  margin: 0 4px 0 4px;
`;

export const ModalWrapper = styled.div`
  ${flexColumnFullHeight}
  background-color: ${buildColor("white", "100")};
`;

export const Container = styled.div`
  ${flexColumnFullHeight}

  .success-message {
    padding-bottom: 0;
  }

  .summary {
    margin: 0 20px;
  }

  .flag {
    margin-top: 4px;
  }

  .moneyPills {
    margin-bottom: 52px;
  }
`;

export const ValueContainer = styled.div`
  ${flexColumnFullHeight}
  align-items: center;
  justify-content: center;
  padding: 52px 0 32px;
`;

export const PromosOnboardingFlagWrapper = styled.div`
  margin-top: 4px;
  margin-left: 4px;
`;

export const FlagsContainer = styled.div`
  display: flex;
`;

export const FormCard = styled.div`
  ${flexColumn};
  margin: 16px 12px;
`;

export const Row = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 41px;
  }
`;

export const Col = styled.div<{ inline?: boolean }>`
  display: flex;
  min-width: 0;
  ${({ inline }) => !inline && "flex-direction: column;"};
  flex: 1;
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const ButtonContainer = styled.div`
  padding: 16px 20px 20px;
`;

export const CardTypeLabel = styled.div`
  margin-bottom: 4px;
  line-height: 22px;
  font-family: ${fontMedium};
  font-size: 14px;
  color: ${buildColor("grey", "900")};
`;
