import {
  StoryBlokComponentsFormatted,
  StoryblokStep
} from "../types/storyblok";
import formatStoryblokMessages from "./formatStoryblokMessages";

export const formatStoryblokComponents = (
  storyblokSteps: Array<StoryblokStep>,
  addSteps: Array<string>
): StoryBlokComponentsFormatted =>
  storyblokSteps.reduce((mappedStep, step) => {
    const { step_config, messages } = step;

    const {
      type,
      description,
      description_win,
      description_lost,
      description_processing
    } = step_config;

    if (!addSteps.includes(type)) {
      return mappedStep;
    }

    const messagesToRender = formatStoryblokMessages(messages);

    return {
      ...mappedStep,
      steps: {
        ...mappedStep.steps,
        [type]: {
          places: messagesToRender,
          description: {
            default: description,
            loss: description_lost,
            win: description_win,
            processing: description_processing
          }
        }
      }
    };
  }, {} as StoryBlokComponentsFormatted);

export default formatStoryblokComponents;
