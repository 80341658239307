import React from "react";
import { IconPathProps } from "../../types";

export const ChevronRight = ({ lineColor, strokeWidth }: IconPathProps) => (
  <path
    d="M7.5 4.5L15 12L7.5 19.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
