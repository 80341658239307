import React, { FC } from "react";

export const Sweden: FC = () => (
  <>
    <g clipPath="url(#clip0_18106_3006)">
      <path d="M32 0H0V20H32V0Z" fill="#005FC8" />
      <path d="M32 7.83553H0V12.1645H32V7.83553Z" fill="#FFDC2E" />
      <path d="M13.4141 0H8.72723V20H13.4141V0Z" fill="#FFDC2E" />
    </g>
    <defs>
      <clipPath id="clip0_18106_3006">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V18C32
          19.1046 31.1046 20 30 20H2C0.895431 20 0 19.1046 0 18V2Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </>
);
