// @flow
// $FlowFixMe
import React, { useRef, useState, Fragment } from "react";
import { CSSTransition } from "react-transition-group";
import type { ScrollableRef } from "@tvg/types/Modal";
import { useModalV2Resize } from "@tvg/custom-hooks";
import { noop } from "lodash";
import ModalHeaderV3 from "../../_organism/ModalHeaderV3";
import ModalHeader from "../../_organism/ModalHeader";
import { calcVideoContainerHeight } from "../../_organism/Video";
import type { ModalProps, TransitionState } from "./types";
import {
  Container,
  Content,
  ContentScroll,
  Overlay,
  Transitions
} from "./styled-components";
import { ModalDefaultProps } from "./defaultProps";
import { stopTouchEvent } from "./utils";

const Modal = (props: ModalProps) => {
  const [transition, setTransition] = useState<TransitionState>("exited");
  const contentRef = useRef<ScrollableRef>(null);

  useModalV2Resize(props.isOpen);

  const renderOverlay = () =>
    transition !== "exited" ? (
      <Overlay
        qaLabel={props.qaLabel && `${props.qaLabel}-overlay`}
        onTouchStart={stopTouchEvent}
        onTouchCancel={stopTouchEvent}
        onTouchEnd={stopTouchEvent}
        onTouchMove={stopTouchEvent}
        layerOffset={props.layerOffset}
        {...(typeof props.onOverlayClick === "function"
          ? {
              onClick: props.onOverlayClick
            }
          : {
              onClick: props.onClose
            })}
        opening={transition === "enter"}
        exiting={transition === "exiting"}
        exited={transition === "exited"}
      />
    ) : null;

  const renderTransparentOverlay = () =>
    transition !== "exited" ? (
      <Overlay
        qaLabel={props.qaLabel && `${props.qaLabel}-overlay`}
        onTouchStart={stopTouchEvent}
        onTouchCancel={stopTouchEvent}
        onTouchEnd={stopTouchEvent}
        onTouchMove={stopTouchEvent}
        {...(typeof props.onOverlayClick === "function"
          ? {
              onClick: props.onOverlayClick
            }
          : {
              onClick: props.onClose
            })}
        opening={transition === "enter"}
        exiting={transition === "exiting"}
        exited={transition === "exited"}
        isTransparent
      />
    ) : null;

  const renderTopChildren = () => {
    if (props.modalTopChildren !== noop) {
      return props.modalTopChildren();
    }
    return null;
  };

  const renderTitle = () => {
    if (props.useModalHeaderV3 && props.hasHeader) {
      return (
        <ModalHeaderV3
          title={props.title}
          isTitleCapitalized={props.isTitleCapitalized}
          subtitle={props.subtitle}
          isTitleCenter={props.isTitleCenter}
          subtitleColor={props.subtitleColor}
          subtitleFontFamily={props.subtitleFontFamily}
          onClose={props.onClose}
          onBack={props.onBack}
          hasRoundedCorners={!(props.isFullHeight && props.offsetTop === 0)}
          hasCloseButton={props.hasCloseButton}
          scrollableRef={contentRef}
          device={props.device}
          className={props.className ? `${props.className}-modal-header` : ""}
        />
      );
    }

    if (props.hasCloseWithoutHeader || (props.hasHeader && props.title)) {
      return (
        <ModalHeader
          title={props.title}
          subtitle={props.subtitle}
          titleType={props.titleType}
          onClose={props.onClose}
          onBack={props.onBack}
          hasRoundedCorners={props.hasRoundedCorners}
          hasCloseWithoutHeader={props.hasCloseWithoutHeader}
          hasIcon={props.hasHeaderIcon}
          hasCloseButton={props.hasCloseButton}
          hasHeaderBorder={props.hasHeaderBorder}
          myBetsStandaloneToggle={props.myBetsStandaloneToggle}
          scrollableRef={props.scrollableRef || contentRef}
          hasBiggerHeader={props.hasBiggerHeader}
          device={props.device}
          shouldRenderWhiteTitle={props.shouldRenderWhiteTitle}
          headerChildren={props.headerChildren}
          headerTitleChildren={props.headerTitleChildren}
          isTitleCenter={props.isTitleCenter}
        />
      );
    }

    return null;
  };

  const renderContainer = (transitionState: TransitionState) => {
    const modalProps = props.useModalHeaderV3
      ? {
          // $FlowFixMe
          ref: contentRef,
          roundedCorners: props.hasRoundedCorners,
          contentRoundedCorners: props.offsetTop > 0 && !props.hasHeader
        }
      : {
          // $FlowFixMe
          ref: props.setScrollableRef || contentRef,
          onScroll: props.onScrollFn,
          isContentTransparent: props.isContentTransparent,
          isNavigation: props.titleType === "navigation",
          roundedCorners:
            (props.animation === "fade" ||
              props.animation === "bottomFloating") &&
            props.hasRoundedCorners
        };

    const renderChildren =
      typeof props.children === "function" && props.children(transitionState);

    return (
      <Container
        onTouchStart={stopTouchEvent}
        onTouchCancel={stopTouchEvent}
        onTouchEnd={stopTouchEvent}
        onTouchMove={stopTouchEvent}
        role="presentation"
        isFullWidth={props.isFullWidth}
        fixedWidth={props.fixedWidth}
        minWidth={props.minWidth}
        offset={{ top: props.offsetTop, bottom: props.offsetBottom }}
        {...(props.allowClickPropagation
          ? {}
          : {
              onClick: (e) => {
                e.stopPropagation();
              }
            })}
        {...(props.qaLabel ? { qaLabel: props.qaLabel } : {})}
      >
        {renderTopChildren()}
        {renderTitle()}
        <Content {...modalProps} data-qa-label="modal-content">
          {props.useModalHeaderV3 ? (
            <ContentScroll data-qa-label="modal-content-v3">
              {renderChildren}
            </ContentScroll>
          ) : (
            renderChildren
          )}
        </Content>
      </Container>
    );
  };

  const transitionProps = {
    appear: true,
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: 300,
    in: props.isOpen
  };

  const transitionStates = {
    onEnter: () => setTransition("enter"),
    onEntered: () => props.onOpenAnimationEnd(),
    onExiting: () => setTransition("exiting"),
    onExited: () => {
      setTransition("exited");
      props.onCloseAnimationEnd();
    }
  };

  const Transition = Transitions[props.animation];

  return (
    <Fragment>
      {props.hasOverlay && renderOverlay()}
      {!props.hasOverlay && renderTransparentOverlay()}
      <CSSTransition
        {...transitionProps}
        {...transitionStates}
        classNames={props.animation}
      >
        {(state) => (
          <Transition
            type={props.animation}
            isFullWidth={props.isFullWidth && !props.isFluidWidth}
            isFluidWidth={props.isFluidWidth && !props.isFullWidth}
            isFullHeight={props.isFullHeight}
            offset={{
              top: props.hasVideoOffset
                ? calcVideoContainerHeight()
                : props.offsetTop,
              bottom: props.offsetBottom,
              left: props.offsetLeft,
              right: props.offsetRight
            }}
            fixIosKeyboard={props.fixIosKeyboard}
            device={props.device}
            hasContentMaxHeight={props.hasContentMaxHeight}
            contentMaxHeight={props.contentMaxHeight}
            paddings={props.marginsForMaxHeight}
            hasContainerFixedWidth={props.fixedWidth !== "100%"}
          >
            {renderContainer(state)}
          </Transition>
        )}
      </CSSTransition>
    </Fragment>
  );
};

Modal.defaultProps = ModalDefaultProps;

export default Modal;
