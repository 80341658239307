import { Store } from "redux";
import { get, isObject } from "lodash";

// Default content for CAPI key "pawsContent"
export const pawsContentDefault = {
  moneyPills: [50, 100, 200, 300],
  paymentMethodSelectorModal: {
    title: "Select Payment Method",
    buttonText: "Add Payment Method"
  },
  availablePaymentMethodList: {
    title: "Deposit with a new payment method"
  },
  otherPaymentMethodsModal: {
    title: "Deposit with other payment methods"
  },
  accountsOnFile: {
    title: "Accounts on file",
    emptyTitle: "No saved payment methods",
    emptyDescription:
      "TVG offers you multiple ways to deposit \n pick one of them below."
  }
};

export const getPawsContent = (store: Store) => {
  const data = get(
    store,
    "capi.messages.Global.pawsContent",
    pawsContentDefault
  );
  return isObject(data) ? data : JSON.parse(data);
};
