import React, { useState, useEffect, useRef, useMemo } from "react";
import { Store } from "redux";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "@tvg/custom-hooks";
import { get, isEmpty, noop, has, attempt, isError } from "lodash";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { setSelectedPaymentMethod } from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import {
  closeDepositFundsModal,
  openDepositFundsModal,
  openMoneyPakFundsModal,
  openOtherPaymentDepositModal,
  openPayNearMeFundsModal
} from "@tvg/sh-lib-paws/redux/slices/depositFundsModalSlice";
import {
  closeWithdrawFundsModal,
  openWithdrawFundsModal,
  setIsWireTransferFundsModalOpen
} from "@tvg/sh-lib-paws/redux/slices/withdrawFundsModalSlice";
import {
  openOtherPaymentMethodsModal,
  closeOtherPaymentMethodsModal
} from "@tvg/sh-lib-paws/redux/slices/otherPaymentMethodsSlice";
import {
  closeCreatePMModal,
  openCreatePMModal
} from "@tvg/sh-lib-paws/redux/slices/createPMModalSlice";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import {
  getAccountsOnFileMethods,
  getOtherPaymentMethodsModal,
  getActivePMCreate,
  getAvailablePaymentMethodsInfos,
  isDepositFundsModalOpen,
  getQuickDepositStateOpen,
  getIsFirstDepositDone,
  getPaymentTypesUnderMaintenance,
  getWireTransferAutomationToggle
} from "@tvg/sh-lib-paws/redux/selectors";
import OtherPaymentMethodsModal from "@tvg/atomic-ui/_templates/OtherPaymentMethodsModal";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import { ToastManager } from "@tvg/design-system";
import { getMZMHideActionsToggle } from "@tvg/sh-lib-paws/redux/toggles";
import {
  getStoryblokPromos,
  getStoryblokPromoByStep,
  hasPromoOnboardingComponent,
  getPromosOnboardingWalletSteps
} from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import {
  DepositStep,
  PromoStepType
} from "@tvg/sh-lib-promos-onboarding/types/promoSteps";
import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import PromosOnboarding from "@tvg/promos-onboarding";
import { Device } from "@tvg/ts-types/Device";
import { getAccountNumber, getResidenceState } from "@urp/store-selectors";
import type {
  PaymentType,
  PaymentTypeOption,
  PaymentTypeItem,
  AvailablePaymentMethodsInfos,
  CreateModalProps,
  OnNewMethodArgs,
  AccountNumber,
  IsOtherPaymentMethodsModalOpen,
  ButtonAction
} from "./types";
import WalletManagementMask from "./components/Masks/walletManagementMask";
import AccountsOnFileList from "./components/AccountsOnFileList";
import AccountsOnFileListOptionsModal from "./components/AccountsOnFileListOptionsModal";
import NewPaymentMethodsList from "./components/NewPaymentMethodsList";
import ModalTitleWithIcon from "./components/ModalTitleWithIcon";
import {
  fetchAvailablePaymentMethods,
  redirectToDMA,
  redirectToNewCard,
  getPaymentMethod,
  validateCreationPage,
  getPmToggles
} from "./utils";
import DesktopWallet from "./components/DesktopPaymentList";
import DesktopHeader from "./components/DesktopHeader";
import DepositFundsModal from "./components/FundsModal/Deposit";
import MoneyPakFundsModal from "./components/FundsModal/Deposit/moneyPak";
import PayNearMeFundsModal from "./components/FundsModal/Deposit/payNearMe";
import WithdrawFundsModal from "./components/FundsModal/Withdraw";
import GenericErrorMessageContainer from "./components/GenericErrorMessageContainer";
import { DesktopContainer, PromosOnboardingWrapper } from "./styled-components";
import {
  gtmDepositClose,
  gtmDepositWithdrawOptionsModal,
  gtmNavigateDepositWithdrawOptions,
  gtmNewPaymentTypeSelected,
  gtmSelectNewOrSavedPaymentType,
  gtmOtherPaymentMethodsOpenTab,
  sendGtmWalletPageView
} from "./gtm";
import CardDeclinedModal from "./components/CardDeclinedModal";
import {
  CreateACHModal,
  CreateCCPModal
} from "./components/CreatePaymentTypes";
import OtherPaymentsDepositModal from "./components/FundsModal/OtherPayments";
import DeleteCardModal from "./components/DeleteCardModal";

const PROMO_ONBOARDING_PLACE = StoryblokPlaceName.WALLET_HOME;
const PROMO_ONBOARDING_PLACE_DESKTOP = StoryblokPlaceName.WALLET_HOME_DESKTOP;

export const WalletComp = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const tvg = tvgConf();
  const isMobile = tvg.device === Device.MOBILE;
  const isTablet = tvg.device === Device.TABLET;
  const isDesktop = tvg.device === Device.DESKTOP;

  const isOtherPaymentMethodsModalOpen: IsOtherPaymentMethodsModalOpen =
    useSelector(getOtherPaymentMethodsModal);
  const accountNumber: AccountNumber = useSelector(getAccountNumber);
  const availablePaymentMethodsInfos: AvailablePaymentMethodsInfos =
    useSelector(getAvailablePaymentMethodsInfos);
  const pawsHideMZMActions = useSelector(getMZMHideActionsToggle);

  const [activeOption, setActiveOption] = useState<string | null>(null);
  const pmToggles = useSelector(getPmToggles);
  const accountsOnFile: PaymentTypeOption[] = useSelector(
    getAccountsOnFileMethods
  );

  const otherMethodTitle = get(availablePaymentMethodsInfos, "OPM.title");
  const otherMethodDescription = get(
    availablePaymentMethodsInfos,
    "OPM.description"
  );

  const { activeCreatePM, isCreateModalOpen }: CreateModalProps =
    useSelector(getActivePMCreate);

  const isDepositModalOpen = useSelector(isDepositFundsModalOpen);
  const isQuickDeposit = useSelector(getQuickDepositStateOpen);
  const isQuickDepositOpen = isDepositModalOpen && isQuickDeposit;

  const promosOnboardingStep = useSelector<Store, DepositStep>(
    (store) =>
      getStoryblokPromoByStep(store, PromoStepType.DEPOSIT) as DepositStep
  );

  const hasPromoOnboarding = useSelector<Store, boolean>((store) =>
    hasPromoOnboardingComponent(store, PROMO_ONBOARDING_PLACE)
  );

  const promoOnboardingDetails = useSelector(getStoryblokPromos);

  const promoOnboardingSteps = useSelector(getPromosOnboardingWalletSteps);

  const isFirstDepositDone = useSelector(getIsFirstDepositDone);
  const userResidenceState: string = useSelector(getResidenceState);

  const paymentTypesUnderMaintenance: PaymentType[] = useSelector(
    getPaymentTypesUnderMaintenance
  );

  const isWireTransferAutomationToggleOn = useSelector<Store, boolean>(
    getWireTransferAutomationToggle
  );

  const isUserInPromoOnboarding = has(
    promoOnboardingDetails,
    "currentStepType"
  );

  const promoOnboardingStepsNumber = get(
    promoOnboardingDetails,
    "numberSteps",
    0
  );

  const userHasBetPlaced = !!get(
    promoOnboardingDetails,
    "steps.WAGER_STEP.serialNumber",
    ""
  );

  const promoOnboardingCustomLabel = (customAmount: number) =>
    `+$${customAmount} CREDIT`;

  const promoOnboardingActions = (onClickAction: Function) =>
    [
      {
        text: "Bet Now",
        onClick: () => {
          onClickAction();

          if (isDesktop) {
            setTimeout(() => {
              mediator.base.dispatch({
                type: "TVG4_NAVIGATION",
                payload: { route: "/races" }
              });
            }, 0);
          } else {
            history.push("/races");
          }
        },
        type: "primary"
      },
      {
        text: "Close",
        onClick: onClickAction,
        type: "secondary"
      }
    ] as Array<ButtonAction>;

  const [fromCreation, setFromCreation] = useState(false);
  const [mainMethods, setMainMethods] = useState([] as PaymentTypeItem[]);
  const [otherMethods, setOtherMethods] = useState([] as PaymentTypeItem[]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isAOFileOptionsModalOpen, setIsAOFileOptionsModalOpen] =
    useState(false);
  const [AOFileOptionsModalProps, setAOFileOptionsModalProps] = useState({
    id: "",
    mainList: true,
    paymentType: "" as PaymentType,
    title: "Payment method options" as unknown,
    depositsAvailable: false,
    withdrawalsAvailable: false,
    method: {} as PaymentTypeOption
  });
  const [errorRetryCount, setErrorRetryCount] = useState<number>(0);

  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (isDesktop && userResidenceState) {
      let privateBrowser = attempt(() =>
        localStorage.getItem("privateMode") === "true" ? "Yes" : "No"
      );

      if (isError(privateBrowser)) {
        privateBrowser = "No";
      }

      sendGtmWalletPageView({
        accountId: accountNumber,
        customerStatus: isFirstDepositDone ? "Returning" : "New",
        privateBrowser: privateBrowser as "Yes" | "No",
        productVersion: tvgConf().gaConfig().productVersion,
        registrationStatus: "Registered",
        residenceState: userResidenceState,
        siteVersion: tvgConf().gaConfig().siteVersion
      });
    }
  }, [userResidenceState]);

  useEffect(() => {
    if (!accountNumber || isEmpty(availablePaymentMethodsInfos)) return;
    fetchAvailablePaymentMethods({
      setOtherMethods,
      setMainMethods,
      setIsLoading,
      setHasError,
      availablePaymentMethodsInfos,
      accountNumber,
      dispatch
    });
  }, [accountNumber, JSON.stringify(availablePaymentMethodsInfos)]);

  const renderToastManager = useMemo(
    () => (
      <ToastManager
        qaLabel="toast-manager"
        variant="informational"
        message=""
        ref={(ref) => ToastManager.setRef(ref)}
      />
    ),
    []
  );

  const renderDeleteCardModal = useMemo(
    () => (
      <DeleteCardModal
        refetchAvailableMethods={() =>
          fetchAvailablePaymentMethods({
            setOtherMethods,
            setMainMethods,
            setIsLoading: noop,
            setHasError,
            availablePaymentMethodsInfos,
            accountNumber,
            dispatch
          })
        }
        isMobile={isMobile}
        {...(isMobile && { setIsAOFileOptionsModalOpen })}
      />
    ),
    [availablePaymentMethodsInfos, accountNumber]
  );

  const openAOFileOptionsModal = (
    method: PaymentTypeOption,
    mainList: boolean = true,
    mzmFromCreation: boolean = false
  ) => {
    const modalTitle = (
      <ModalTitleWithIcon
        title={method.title}
        subtitle={method.description}
        paymentType={method.paymentType}
      />
    );
    setAOFileOptionsModalProps({
      id: method.id,
      mainList,
      paymentType: method.paymentType,
      title: modalTitle,
      depositsAvailable: method.depositsAvailable,
      withdrawalsAvailable: method.withdrawalsAvailable,
      method
    });
    dispatch(
      setSelectedPaymentMethod({
        id: method.id,
        paymentType: method.paymentType,
        title: method.title,
        subTitle: method.description,
        description: "",
        depositsAvailable: method.depositsAvailable,
        withdrawalsAvailable: method.withdrawalsAvailable,
        minLimit: method.minLimit,
        maxLimit: method.maxLimit
      })
    );

    setFromCreation(mzmFromCreation);
    setIsAOFileOptionsModalOpen(true);
    gtmSelectNewOrSavedPaymentType(false, method.paymentType);
    gtmDepositWithdrawOptionsModal(true, method.paymentType);
  };

  const openOPMDepositModal = (method: PaymentTypeOption) => {
    dispatch(
      setSelectedPaymentMethod({
        id: method.id,
        paymentType: method.paymentType,
        title: method.title,
        subTitle: method.description,
        description: "",
        depositsAvailable: method.depositsAvailable,
        withdrawalsAvailable: method.withdrawalsAvailable,
        minLimit: method.minLimit,
        maxLimit: method.maxLimit
      })
    );

    if (method.paymentType === PaymentTypeEnum.MONEY_PAK) {
      dispatch(openMoneyPakFundsModal());
    } else if (method.paymentType === PaymentTypeEnum.PAY_NEAR_ME) {
      dispatch(openPayNearMeFundsModal());
    } else if (
      method.paymentType === PaymentTypeEnum.MAZOOMA &&
      pawsHideMZMActions
    ) {
      dispatch(openDepositFundsModal(true));
    } else {
      openAOFileOptionsModal(method, false);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (containerRef && containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          ...(isMobile && { block: "end" })
        });
      }
    }, 300);
  };

  if (isLoading && isMobile) {
    return <WalletManagementMask />;
  }

  if (!isLoading && hasError) {
    return (
      <GenericErrorMessageContainer
        retriesCount={errorRetryCount}
        setRetryCount={setErrorRetryCount}
        onRetry={() => {
          fetchAvailablePaymentMethods({
            accountNumber,
            availablePaymentMethodsInfos,
            setHasError,
            setIsLoading,
            setMainMethods,
            setOtherMethods,
            dispatch
          });
        }}
        minHeight="calc(100vh - 56px)"
      />
    );
  }

  const { minLimit = 2, maxLimit = 999999 } =
    (getPaymentMethod(activeCreatePM, [
      ...mainMethods,
      ...otherMethods
    ]) as PaymentTypeItem) || {};

  return isMobile ? (
    <section ref={containerRef}>
      {promosOnboardingStep && hasPromoOnboarding ? (
        <PromosOnboarding placeToRender={PROMO_ONBOARDING_PLACE} isShown />
      ) : (
        <AccountsOnFileList
          paymentTypesUnderMaintenance={paymentTypesUnderMaintenance}
          accountsOnFile={accountsOnFile}
          emptyContainer={{
            title: "No saved payment methods",
            description:
              "TVG offers you multiple ways to deposit \n pick one of them below. "
          }}
          onClick={openAOFileOptionsModal}
        />
      )}
      <NewPaymentMethodsList
        methods={mainMethods}
        methodsInfos={availablePaymentMethodsInfos}
        onClickMethods={(method: PaymentTypeOption) => {
          if (
            method.paymentType === PaymentTypeEnum.MAZOOMA &&
            !pawsHideMZMActions
          ) {
            openAOFileOptionsModal(method, true, true);
          } else {
            redirectToNewCard(
              method.paymentType,
              pmToggles,
              dispatch,
              getPaymentMethod(method.paymentType, mainMethods)
            );
          }
        }}
        hasOtherMethods={!!otherMethods.length}
        otherMethodTitle={otherMethodTitle}
        otherMethodDescription={otherMethodDescription}
        onClickOtherMethod={() => {
          gtmNewPaymentTypeSelected(
            "Other Payment Methods",
            "Other Payment Methods"
          );
          dispatch(openOtherPaymentMethodsModal());
        }}
      />
      <OtherPaymentMethodsModal
        isOpen={isOtherPaymentMethodsModalOpen}
        otherMethods={otherMethods}
        onClick={(method: PaymentTypeOption) => {
          dispatch(closeOtherPaymentMethodsModal());
          if (get(pmToggles, `${method.paymentType}`, false)) {
            openOPMDepositModal(method);
          } else {
            openAOFileOptionsModal(method, false);
          }
        }}
      />
      <CreateACHModal
        promosOnboarding={promosOnboardingStep}
        isOpen={
          !!getPaymentMethod(PaymentTypeEnum.ECHECK, mainMethods)
            ?.depositsAvailable &&
          activeCreatePM === PaymentTypeEnum.ECHECK &&
          isCreateModalOpen
        }
        method={getPaymentMethod(PaymentTypeEnum.ECHECK, mainMethods)}
        onClose={() => {
          gtmDepositClose(activeCreatePM, true, accountNumber);
          dispatch(closeCreatePMModal());
          dispatch(closeDepositFundsModal(true));
        }}
      />
      <CreateCCPModal
        promosOnboarding={promosOnboardingStep}
        isUserInPromoOnboarding={isUserInPromoOnboarding}
        promoOnboardingStepsNumber={promoOnboardingStepsNumber}
        promoOnboardingSteps={promoOnboardingSteps}
        promoOnboardingActions={promoOnboardingActions}
        userHasBetPlaced={userHasBetPlaced}
        promoOnboardingCustomLabel={promoOnboardingCustomLabel}
        isOpen={
          activeCreatePM === PaymentTypeEnum.CREDIT_DEBIT_CARD &&
          isCreateModalOpen
        }
        maxLimit={maxLimit}
        minLimit={minLimit}
        onCloseCallback={() => {
          dispatch(closeCreatePMModal());
        }}
        refetchAvailableMethods={() =>
          fetchAvailablePaymentMethods({
            setOtherMethods,
            setMainMethods,
            setIsLoading: noop,
            setHasError,
            availablePaymentMethodsInfos,
            accountNumber,
            dispatch
          })
        }
      />
      <ModalV2
        title={AOFileOptionsModalProps.title}
        isOpen={isAOFileOptionsModalOpen}
        onClose={() => {
          setIsAOFileOptionsModalOpen(false);
          if (!AOFileOptionsModalProps.mainList) {
            dispatch(openOtherPaymentMethodsModal());
          }
          gtmDepositWithdrawOptionsModal(
            false,
            AOFileOptionsModalProps.paymentType
          );
        }}
        animation="bottom"
        isFullHeight={false}
        qaLabel="transaction-options-modal"
      >
        {() => (
          <AccountsOnFileListOptionsModal
            id={AOFileOptionsModalProps.id}
            paymentType={AOFileOptionsModalProps.paymentType}
            depositsAvailable={AOFileOptionsModalProps.depositsAvailable}
            withdrawalsAvailable={AOFileOptionsModalProps.withdrawalsAvailable}
            dispatch={dispatch}
            paymentMethod={AOFileOptionsModalProps.method}
            onClick={(paymentType: PaymentType, type, paymentId) => {
              if (
                get(pmToggles, `${paymentType}`, false) &&
                type === "deposit"
              ) {
                if (
                  paymentType === PaymentTypeEnum.CHECK ||
                  paymentType === PaymentTypeEnum.WIRE_TRANSFER ||
                  paymentType === PaymentTypeEnum.CASH
                ) {
                  dispatch(openOtherPaymentDepositModal(paymentType));
                  gtmOtherPaymentMethodsOpenTab({ paymentType });
                } else {
                  dispatch(openDepositFundsModal(fromCreation));
                  setFromCreation(false);
                }
                setIsAOFileOptionsModalOpen(false);
              } else if (
                get(pmToggles, `${paymentType}`, false) &&
                type === "withdraw"
              ) {
                if (
                  paymentType === PaymentTypeEnum.CASH ||
                  (!isWireTransferAutomationToggleOn &&
                    paymentType === PaymentTypeEnum.WIRE_TRANSFER)
                ) {
                  dispatch(openOtherPaymentDepositModal(paymentType));
                  gtmOtherPaymentMethodsOpenTab({ paymentType });
                } else if (
                  isWireTransferAutomationToggleOn &&
                  paymentType === PaymentTypeEnum.WIRE_TRANSFER
                ) {
                  batch(() => {
                    dispatch(openWithdrawFundsModal());
                    dispatch(setIsWireTransferFundsModalOpen(true));
                  });
                } else {
                  dispatch(openWithdrawFundsModal());
                }
                setIsAOFileOptionsModalOpen(false);
              } else {
                gtmNavigateDepositWithdrawOptions(type, paymentType);
                redirectToDMA(paymentType, type, paymentId, false);
              }
            }}
          />
        )}
      </ModalV2>
      <DepositFundsModal
        promosOnboarding={promosOnboardingStep}
        isUserInPromoOnboarding={isUserInPromoOnboarding}
        promoOnboardingStepsNumber={promoOnboardingStepsNumber}
        promoOnboardingSteps={promoOnboardingSteps}
        promoOnboardingActions={promoOnboardingActions}
        userHasBetPlaced={userHasBetPlaced}
        promoOnboardingCustomLabel={promoOnboardingCustomLabel}
        scrollToBottom={scrollToBottom}
        history={history}
        onCloseCallback={() => {
          setActiveOption(null);
          dispatch(
            setSelectedPaymentMethod({
              id: "",
              paymentType: "",
              title: "",
              subTitle: "",
              description: "",
              depositsAvailable: false,
              withdrawalsAvailable: false,
              minLimit: 0,
              maxLimit: 999999
            })
          );
        }}
        refetchAvailableMethods={() =>
          fetchAvailablePaymentMethods({
            setOtherMethods,
            setMainMethods,
            setIsLoading: noop,
            setHasError,
            availablePaymentMethodsInfos,
            accountNumber,
            dispatch
          })
        }
      />
      <OtherPaymentsDepositModal
        otherMethods={otherMethods}
        promosOnboarding={promosOnboardingStep}
      />
      <MoneyPakFundsModal
        promosOnboarding={promosOnboardingStep}
        isUserInPromoOnboarding={isUserInPromoOnboarding}
        promoOnboardingStepsNumber={promoOnboardingStepsNumber}
        promoOnboardingSteps={promoOnboardingSteps}
        promoOnboardingActions={promoOnboardingActions}
        userHasBetPlaced={userHasBetPlaced}
        promoOnboardingCustomLabel={promoOnboardingCustomLabel}
        onCloseCallback={() => {
          setActiveOption(null);
        }}
        refetchAvailableMethods={() =>
          fetchAvailablePaymentMethods({
            setOtherMethods,
            setMainMethods,
            setIsLoading: noop,
            setHasError,
            availablePaymentMethodsInfos,
            accountNumber,
            dispatch
          })
        }
      />
      <PayNearMeFundsModal
        promosOnboarding={promosOnboardingStep}
        onCloseCallback={() => {
          setActiveOption(null);
        }}
      />

      <WithdrawFundsModal
        onCloseCallback={() => {
          setActiveOption(null);
          dispatch(closeWithdrawFundsModal());
        }}
        refetchAvailableMethods={() =>
          fetchAvailablePaymentMethods({
            setOtherMethods,
            setMainMethods,
            setIsLoading: noop,
            setHasError,
            availablePaymentMethodsInfos,
            accountNumber,
            dispatch
          })
        }
        otherMethods={otherMethods}
      />
      <CardDeclinedModal otherMethods={otherMethods} />
      {renderDeleteCardModal}
      {!isTvg5() && renderToastManager}
    </section>
  ) : (
    <DesktopContainer ref={containerRef} isTablet={isTablet} isTvg5={isTvg5()}>
      {isDesktop && <DesktopHeader />}
      {!!(promosOnboardingStep && hasPromoOnboarding) && (
        <PromosOnboardingWrapper isDesktop={isDesktop}>
          <PromosOnboarding
            placeToRender={
              isDesktop
                ? PROMO_ONBOARDING_PLACE_DESKTOP
                : PROMO_ONBOARDING_PLACE
            }
            isShown
          />
        </PromosOnboardingWrapper>
      )}
      <DesktopWallet
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        isLoading={isLoading}
        methodsInfos={availablePaymentMethodsInfos}
        methods={mainMethods}
        otherMethods={otherMethods}
        onNewPaymentMethod={({
          paymentType,
          toggleOffHandler
        }: OnNewMethodArgs) => {
          if (get(pmToggles, `${paymentType}`, false)) {
            validateCreationPage(
              paymentType,
              dispatch,
              getPaymentMethod(paymentType, mainMethods)
            );
            dispatch(openCreatePMModal(paymentType));
          } else {
            toggleOffHandler();
          }
        }}
      />
      {!isQuickDepositOpen && (
        <DepositFundsModal
          promosOnboarding={promosOnboardingStep}
          isUserInPromoOnboarding={isUserInPromoOnboarding}
          promoOnboardingStepsNumber={promoOnboardingStepsNumber}
          promoOnboardingSteps={promoOnboardingSteps}
          promoOnboardingActions={promoOnboardingActions}
          userHasBetPlaced={userHasBetPlaced}
          promoOnboardingCustomLabel={promoOnboardingCustomLabel}
          scrollToBottom={scrollToBottom}
          onCloseCallback={() => {
            setActiveOption(null);
            dispatch(
              setSelectedPaymentMethod({
                id: "",
                paymentType: "",
                title: "",
                subTitle: "",
                description: "",
                depositsAvailable: false,
                withdrawalsAvailable: false,
                minLimit: 0,
                maxLimit: 999999
              })
            );
          }}
          history={history}
          refetchAvailableMethods={() =>
            fetchAvailablePaymentMethods({
              setOtherMethods,
              setMainMethods,
              setIsLoading: noop,
              setHasError,
              availablePaymentMethodsInfos,
              accountNumber,
              dispatch
            })
          }
        />
      )}

      <OtherPaymentsDepositModal
        otherMethods={otherMethods}
        promosOnboarding={promosOnboardingStep}
        onCloseCallback={() => {
          setActiveOption(null);
        }}
      />
      <MoneyPakFundsModal
        promosOnboarding={promosOnboardingStep}
        isUserInPromoOnboarding={isUserInPromoOnboarding}
        promoOnboardingStepsNumber={promoOnboardingStepsNumber}
        promoOnboardingSteps={promoOnboardingSteps}
        promoOnboardingActions={promoOnboardingActions}
        userHasBetPlaced={userHasBetPlaced}
        promoOnboardingCustomLabel={promoOnboardingCustomLabel}
        onCloseCallback={() => {
          setActiveOption(null);
        }}
        refetchAvailableMethods={() =>
          fetchAvailablePaymentMethods({
            setOtherMethods,
            setMainMethods,
            setIsLoading: noop,
            setHasError,
            availablePaymentMethodsInfos,
            accountNumber,
            dispatch
          })
        }
      />
      <PayNearMeFundsModal
        promosOnboarding={promosOnboardingStep}
        onCloseCallback={() => {
          setActiveOption(null);
        }}
      />
      <WithdrawFundsModal
        onCloseCallback={() => {
          setActiveOption(null);
          dispatch(closeWithdrawFundsModal());
        }}
        refetchAvailableMethods={() =>
          fetchAvailablePaymentMethods({
            setOtherMethods,
            setMainMethods,
            setIsLoading: noop,
            setHasError,
            availablePaymentMethodsInfos,
            accountNumber,
            dispatch
          })
        }
        otherMethods={otherMethods}
      />
      <CreateACHModal
        promosOnboarding={promosOnboardingStep}
        isOpen={
          !!getPaymentMethod(PaymentTypeEnum.ECHECK, mainMethods)
            ?.depositsAvailable &&
          activeCreatePM === PaymentTypeEnum.ECHECK &&
          isCreateModalOpen
        }
        method={getPaymentMethod(PaymentTypeEnum.ECHECK, mainMethods)}
        onClose={() => {
          gtmDepositClose(activeCreatePM, true, accountNumber);
          dispatch(closeCreatePMModal());
          dispatch(closeDepositFundsModal(true));
        }}
      />

      <CreateCCPModal
        promosOnboarding={promosOnboardingStep}
        isUserInPromoOnboarding={isUserInPromoOnboarding}
        promoOnboardingStepsNumber={promoOnboardingStepsNumber}
        promoOnboardingSteps={promoOnboardingSteps}
        promoOnboardingActions={promoOnboardingActions}
        userHasBetPlaced={userHasBetPlaced}
        promoOnboardingCustomLabel={promoOnboardingCustomLabel}
        isOpen={
          activeCreatePM === PaymentTypeEnum.CREDIT_DEBIT_CARD &&
          isCreateModalOpen
        }
        maxLimit={maxLimit}
        minLimit={minLimit}
        onCloseCallback={() => {
          dispatch(closeCreatePMModal());
        }}
        refetchAvailableMethods={() =>
          fetchAvailablePaymentMethods({
            setOtherMethods,
            setMainMethods,
            setIsLoading: noop,
            setHasError,
            availablePaymentMethodsInfos,
            accountNumber,
            dispatch
          })
        }
      />
      <CardDeclinedModal otherMethods={otherMethods} />
      {renderDeleteCardModal}
      {!isTvg5() && renderToastManager}
    </DesktopContainer>
  );
};

export default WalletComp;
