import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Success = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M22.8755 12.0042C22.8755 18.0105 18.0063 22.8797 12 22.8797C5.99363
      22.8797 1.12451 18.0105 1.12451 12.0042C1.12451 5.99782 5.99363 1.12871
      12 1.12871C18.0063 1.12871 22.8755 5.99782 22.8755 12.0042Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M6.75006 12L10.5001 15.75L18.0001 8.25M22.8755 12.0042C22.8755 18.0105
      18.0063 22.8797 12 22.8797C5.99363 22.8797 1.12451 18.0105 1.12451
      12.0042C1.12451 5.99782 5.99363 1.12871 12 1.12871C18.0063 1.12871 22.8755
      5.99782 22.8755 12.0042Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);
