import { createSlice } from "@reduxjs/toolkit";

export type State = {
  isModalOpen: boolean;
};

export const initialState: State = {
  isModalOpen: false
};

const infoPanelSlice = createSlice({
  name: "infoPanel",
  initialState,
  reducers: {
    openInfoPanelModal(state) {
      state.isModalOpen = true;
    },
    closeInfoPanelModal(state) {
      state.isModalOpen = false;
    }
  }
});

export const { openInfoPanelModal, closeInfoPanelModal } =
  infoPanelSlice.actions;

export default infoPanelSlice.reducer;
