import { flatten, get } from "lodash";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import {
  PaymentType,
  PaymentTypeInfo,
  PaymentTypeItem,
  PaymentTypeOption
} from "../../types";

export const mapAllPaymentOptions = (methods: PaymentTypeItem[]) =>
  flatten(
    methods.map((method) =>
      (method.paymentTypeOptions || []).map((option) => ({
        ...option,
        paymentType: option.paymentType || method.paymentType
      }))
    )
  );

// @MazoomaMerge The next line is part of the temporary merge
export const mergeMazoomaWithEcheck = (
  payment: PaymentTypeItem,
  showMZMPm: boolean
) =>
  payment.paymentType !== PaymentTypeEnum.ECHECK &&
  (showMZMPm ? payment : payment.paymentType !== PaymentTypeEnum.MAZOOMA);

export const buildOtherMethods = (
  otherMethods: PaymentTypeItem[],
  methodsInfos: { [key in PaymentType]: PaymentTypeInfo }
): PaymentTypeItem[] => {
  let otherMethodsArray = [] as PaymentTypeOption[];

  if (otherMethods && otherMethods.length > 0) {
    otherMethodsArray = otherMethods.reduce(
      (accumulator: PaymentTypeOption[], otherMethod: PaymentTypeItem) => {
        const paymentMethod = {
          id: otherMethod.paymentType,
          title: get(methodsInfos[otherMethod.paymentType], "title"),
          paymentType: otherMethod.paymentType,
          description: "",
          depositsAvailable: otherMethod.depositsAvailable || false,
          withdrawalsAvailable: otherMethod.withdrawalsAvailable || false,
          minLimit: otherMethod.minLimit || 0,
          maxLimit: otherMethod.maxLimit || 999999,
          priority: otherMethod.priority,
          paymentTypeAvailable: otherMethod.paymentTypeAvailable
        };
        accumulator.push(paymentMethod);
        return accumulator;
      },
      []
    );
  }

  return [
    {
      paymentType: "OPM",
      paymentTypeOptions: otherMethodsArray as PaymentTypeOption[],
      mainList: false,
      depositsEnabled: true,
      priority: 5,
      withdrawalsEnabled: true
    }
  ];
};
