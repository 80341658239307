import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import PromoProgress from "@tvg/atomic-ui/_molecule/PromoProgress";
import { getStoryblokPromos } from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { useNavigate } from "@tvg/custom-hooks";
import { Device } from "@tvg/ts-types/Device";
import { promoOnboardingCTAClickGTM } from "../../utils/gtm";
import { useCurrentProgress } from "./hooks/useCurrentProgress";
import { ProgressProps } from "../sharedComponentTypes";

export const PromoProgressComponent = ({
  device = Device.MOBILE,
  isDarkMode = false,
  isHomePageSection = false
}: ProgressProps) => {
  const history = useNavigate();
  const storyblokPromos = useSelector(getStoryblokPromos);
  const promoCurrentStatus = useCurrentProgress(history, storyblokPromos);
  const handleCTA = useCallback(() => {
    if (typeof promoCurrentStatus?.onCTAPress === "function") {
      promoCurrentStatus.onCTAPress();
      promoOnboardingCTAClickGTM(
        promoCurrentStatus.CTALabel,
        storyblokPromos.promotionId,
        storyblokPromos.promoName
      );
    }
  }, [promoCurrentStatus, storyblokPromos]);

  return useMemo(
    () =>
      promoCurrentStatus && (
        <PromoProgress
          {...promoCurrentStatus}
          device={device}
          onCTAPress={handleCTA}
          isDarkMode={isDarkMode}
          isHomePageSection={isHomePageSection}
        />
      ),
    [promoCurrentStatus, storyblokPromos]
  );
};

export default PromoProgressComponent;
