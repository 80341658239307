import React from "react";
import { View } from "react-native";
import { useEvents } from "../../../../hooks/useEvents";
import { AlertInlineLinkProps } from "../../types";
import { StyledLink } from "./styled-components";

export const AlertInlineLink = ({
  type,
  variant,
  ...props
}: AlertInlineLinkProps) => {
  const { events, eventsHandlers } = useEvents({});

  return (
    <View {...eventsHandlers}>
      <StyledLink {...props} variant={variant} type={type} events={events} />
    </View>
  );
};

export { AlertInlineLinkProps };
export default AlertInlineLink;
