import {
  PaymentTypeItem,
  PaymentType,
  PaymentTypeInfo,
  PaymentTypeOption
} from "../src/types";

export const methods: PaymentTypeItem[] = [
  {
    paymentType: "ACH",
    priority: 1,
    mainList: true,
    paymentTypeOptions: [
      {
        paymentType: "ACH",
        id: "1",
        title: "WELLS FARGO AND COMPANY",
        description: "5167",
        expirationDate: null,
        lastUsedDateTime: "2021-06-25T13:31:34.254101Z",
        depositsAvailable: true,
        withdrawalsAvailable: true,
        minLimit: 5,
        maxLimit: 1000
      }
    ],
    depositsEnabled: true,
    withdrawalsEnabled: true,
    depositsAvailable: true,
    withdrawalsAvailable: true
  },
  {
    paymentType: "CCP",
    priority: 2,
    mainList: true,
    paymentTypeOptions: [
      {
        paymentType: "CCP",
        id: "2",
        title: "Visa",
        description: "****0007",
        expirationDate: "2025-10-01",
        lastUsedDateTime: "2021-06-25T13:31:34.254116Z",
        depositsAvailable: true,
        withdrawalsAvailable: false,
        minLimit: 5,
        maxLimit: 1000
      },
      {
        paymentType: "CCP",
        id: "3",
        title: "Mastercard",
        description: "****0267",
        expirationDate: "2026-10-01",
        lastUsedDateTime: "2021-06-25T13:31:34.254117Z",
        depositsAvailable: true,
        withdrawalsAvailable: true,
        minLimit: 5,
        maxLimit: 1000
      }
    ],
    depositsEnabled: true,
    withdrawalsEnabled: true,
    depositsAvailable: true,
    withdrawalsAvailable: true
  },
  {
    paymentType: "PAY",
    priority: 4,
    mainList: false,
    paymentTypeOptions: [
      {
        paymentType: "PAY",
        id: "4",
        title: "Paypal",
        description: "sb-ejvkf3049723@personal.example.com",
        expirationDate: null,
        lastUsedDateTime: "2021-06-25T13:31:34.254121Z",
        depositsAvailable: true,
        withdrawalsAvailable: true,
        minLimit: 5,
        maxLimit: 1000
      }
    ],
    depositsEnabled: true,
    withdrawalsEnabled: true,
    depositsAvailable: true,
    withdrawalsAvailable: true
  },
  {
    paymentType: "SLP",
    priority: 3,
    mainList: false,
    paymentTypeOptions: [
      {
        paymentType: "SLP",
        id: "5",
        title: "",
        description: "•••• 5392",
        expirationDate: null,
        lastUsedDateTime: "2021-06-25T13:31:34.254123Z",
        depositsAvailable: true,
        withdrawalsAvailable: false,
        minLimit: 5,
        maxLimit: 1000
      }
    ],
    depositsEnabled: true,
    withdrawalsEnabled: true,
    depositsAvailable: true,
    withdrawalsAvailable: true
  },
  {
    paymentType: "MZM",
    priority: 1,
    mainList: true,
    paymentTypeOptions: [
      {
        paymentType: "MZM",
        id: "138",
        title: "WELLS FARGO",
        description: "************4444",
        expirationDate: null,
        lastUsedDateTime: "2022-02-24T17:22:48.23Z",
        depositsAvailable: true,
        withdrawalsAvailable: true,
        minLimit: 0.11,
        maxLimit: 2000
      },
      {
        paymentType: "MZM",
        id: "47",
        title: "WELLS FARGO",
        description: "************0000",
        expirationDate: null,
        lastUsedDateTime: "2022-02-24T17:21:24.537Z",
        depositsAvailable: true,
        withdrawalsAvailable: true,
        minLimit: 0.11,
        maxLimit: 2000
      },
      {
        paymentType: "MZM",
        id: "141",
        title: "WELLS FARGO",
        description: "************0000",
        expirationDate: null,
        lastUsedDateTime: "2022-02-23T11:55:41.587Z",
        depositsAvailable: true,
        withdrawalsAvailable: true,
        minLimit: 0.11,
        maxLimit: 2000
      }
    ],
    depositsEnabled: true,
    withdrawalsEnabled: true,
    depositsAvailable: true,
    withdrawalsAvailable: true
  }
];

export const otherMethods: PaymentTypeItem[] = [
  {
    paymentType: "PAY",
    priority: 3,
    mainList: false,
    paymentTypeOptions: [
      {
        id: "",
        title: "Paypal",
        paymentType: "OPM",
        description: "sb-ejvkf3049723@personal.example.com",
        expirationDate: null,
        lastUsedDateTime: "2021-06-25T13:31:34.254121Z",
        depositsAvailable: true,
        withdrawalsAvailable: true,
        minLimit: 0,
        maxLimit: 0
      }
    ],
    depositsEnabled: true,
    withdrawalsEnabled: true
  },
  {
    paymentType: "SLP",
    priority: 4,
    mainList: false,
    paymentTypeOptions: [
      {
        id: "",
        title: "",
        paymentType: "OPM",
        description: "•••• 5392",
        expirationDate: null,
        lastUsedDateTime: "2021-06-25T13:31:34.254123Z",
        depositsAvailable: true,
        withdrawalsAvailable: false,
        minLimit: 0,
        maxLimit: 0
      }
    ],
    depositsEnabled: true,
    withdrawalsEnabled: true
  }
];

export const otherMethodsBuilt: PaymentTypeItem[] = [
  {
    depositsEnabled: true,
    mainList: false,
    paymentType: "OPM",
    paymentTypeOptions: [
      {
        id: "PAY",
        depositsAvailable: false,
        title: "PayPal",
        withdrawalsAvailable: false,
        paymentType: "PAY",
        // @ts-ignore TODO: figure out PaymentTypeItem vs PaymentTypeOption
        priority: 3,
        description: "",
        minLimit: 0,
        maxLimit: 999999
      },
      {
        id: "SLP",
        depositsAvailable: false,
        title: "TVG Pre Paid",
        withdrawalsAvailable: false,
        paymentType: "SLP",
        // @ts-ignore TODO: figure out PaymentTypeItem vs PaymentTypeOption
        priority: 4,
        description: "",
        minLimit: 0,
        maxLimit: 999999
      }
    ],
    priority: 5,
    withdrawalsEnabled: true
  }
];

export const paymentTypeOptions: PaymentTypeOption[] = [
  {
    id: "1",
    title: "Visa",
    paymentType: "CC",
    description: "****0007",
    expirationDate: "2025-10-01",
    lastUsedDateTime: "2021-06-25T13:31:34.254116Z",
    depositsAvailable: true,
    withdrawalsAvailable: false,
    minLimit: 5,
    maxLimit: 1000
  },
  {
    id: "2",
    title: "Mastercard",
    paymentType: "CC",
    description: "****0267",
    expirationDate: "2026-10-01",
    lastUsedDateTime: "2021-06-25T13:31:34.254117Z",
    depositsAvailable: true,
    withdrawalsAvailable: true,
    minLimit: 5,
    maxLimit: 1000
  },
  {
    id: "3",
    title: "Visa",
    paymentType: "CCP",
    description: "****0207",
    expirationDate: "2026-10-01",
    lastUsedDateTime: "2021-06-25T13:31:34.254117Z",
    depositsAvailable: true,
    withdrawalsAvailable: true,
    minLimit: 5,
    maxLimit: 1000
  }
];

export const otherPaymentMethodOptions: PaymentTypeOption[] = [
  {
    id: "",
    title: "PayPal",
    paymentType: "OPM",
    description: "****0267",
    expirationDate: "2026-10-01",
    lastUsedDateTime: "2021-06-25T13:31:34.254117Z",
    depositsAvailable: true,
    withdrawalsAvailable: true,
    minLimit: 5,
    maxLimit: 1000
  },
  {
    id: "",
    title: "TVG Pre Paid",
    paymentType: "OPM",
    description: "****0267",
    expirationDate: "2026-10-01",
    lastUsedDateTime: "2021-06-25T13:31:34.254117Z",
    depositsAvailable: true,
    withdrawalsAvailable: true,
    minLimit: 5,
    maxLimit: 1000
  },
  {
    id: "",
    title: "MoneyPak",
    paymentType: "OPM",
    description: "****0267",
    expirationDate: "2026-10-01",
    lastUsedDateTime: "2021-06-25T13:31:34.254117Z",
    depositsAvailable: true,
    withdrawalsAvailable: true,
    minLimit: 5,
    maxLimit: 1000
  }
];

export const methodsInfos: { [key in PaymentType]: PaymentTypeInfo } = {
  ACH: {
    title: "eCheck",
    description: "eCheck description"
  },
  CASH: {
    title: "Cash",
    description: "Cash description"
  },
  CC: {
    title: "Credit Card",
    description: "Credit Card description",
    limit: 5
  },
  CCS: {
    title: "Credit/Debit Card",
    description: "Credit/Debit Card description",
    limit: 5
  },
  CCP: {
    title: "Credit/Debit Card",
    description: "Credit/Debit Card description",
    limit: 5
  },
  CHK: {
    title: "Check",
    description: "Check description"
  },
  DEB: {
    title: "Debit Card",
    description: "Debit Card description",
    limit: 5
  },
  DEBS: {
    title: "Debit Card",
    description: "Debit Card description",
    limit: 5
  },
  IBC: {
    title: "eCheck",
    description: "eCheck description"
  },
  MPAK: {
    title: "MoneyPak",
    description: "MoneyPak description"
  },
  PAY: {
    title: "PayPal",
    description: "PayPal description",
    limit: 1
  },
  PNM: {
    title: "PayNearMe",
    description: "PayNearMe description"
  },
  PPAL: {
    title: "PayPal",
    description: "PayPal description"
  },
  SLP: {
    title: "TVG Pre Paid",
    description: "TVG Pre Paid description",
    limit: 1
  },
  SLN: {
    title: "Old TVG Pre Paid",
    description: "Old TVG Pre Paid description",
    limit: 1
  },
  VIWD: {
    title: "Card Withdrawal",
    description: "Card Withdrawal description"
  },
  CFee: {
    title: "Collection Fee",
    description: "Collection Fee description"
  },
  Coll: {
    title: "Collection",
    description: "Collection description"
  },
  CNTW: {
    title: "Contest Winnings",
    description: "Contest Winnings description"
  },
  RPPS: {
    title: "Remote Payment Processing System",
    description: "Remote Payment Processing Syste descriptionm"
  },
  WTRN: {
    title: "WTRN",
    description: "Wire Transfer description"
  },
  OPM: {
    title: "OPM",
    description: "Other Payment description"
  },
  MZM: {
    title: "MZM",
    description: "MZM description"
  }
};

export const accountsOnFile: PaymentTypeOption[] = [
  {
    paymentType: "ACH",
    id: "1",
    title: "WELLS FARGO AND COMPANY",
    description: "5167",
    expirationDate: null,
    lastUsedDateTime: "2021-06-25T13:31:34.254101Z",
    depositsAvailable: true,
    withdrawalsAvailable: true,
    minLimit: 5,
    maxLimit: 1000
  },
  {
    paymentType: "CCP",
    id: "2",
    title: "Visa",
    description: "****0007",
    expirationDate: "2025-10-01",
    lastUsedDateTime: "2021-06-25T13:31:34.254116Z",
    depositsAvailable: true,
    withdrawalsAvailable: false,
    minLimit: 5,
    maxLimit: 1000
  },
  {
    paymentType: "CCP",
    id: "3",
    title: "Mastercard",
    description: "****0267",
    expirationDate: "2026-10-01",
    lastUsedDateTime: "2021-06-25T13:31:34.254117Z",
    depositsAvailable: true,
    withdrawalsAvailable: true,
    minLimit: 5,
    maxLimit: 1000
  },
  {
    paymentType: "PAY",
    id: "4",
    title: "Paypal",
    description: "sb-ejvkf3049723@personal.example.com",
    expirationDate: null,
    lastUsedDateTime: "2021-06-25T13:31:34.254121Z",
    depositsAvailable: true,
    withdrawalsAvailable: true,
    minLimit: 5,
    maxLimit: 1000
  },
  {
    paymentType: "SLP",
    id: "5",
    title: "",
    description: "•••• 5392",
    expirationDate: null,
    lastUsedDateTime: "2021-07-25T13:31:34.254123Z",
    depositsAvailable: true,
    withdrawalsAvailable: false,
    minLimit: 5,
    maxLimit: 1000
  }
];

export const paymentTypeOption: PaymentTypeOption = {
  paymentType: "ACH",
  id: "1",
  title: "WELLS FARGO AND COMPANY",
  description: "•••• 5392",
  expirationDate: "2026-10-01",
  lastUsedDateTime: "2021-06-25T13:31:34.254121Z",
  depositsAvailable: true,
  withdrawalsAvailable: true,
  minLimit: 5,
  maxLimit: 1000
};
