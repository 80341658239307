import React, { useEffect, FC } from "react";
import { useSpring, useSpringRef, easings, animated } from "@react-spring/web";
import type { SCPillProps } from "./types";
import { Slider } from "./styled-components";

const AnimatedSlider = animated(Slider);

export const SegmentedControlPill: FC<SCPillProps> = ({
  segmentWidth,
  selectedIndex,
  isDisabled,
  qaLabel = "segmented-control-pill"
}: SCPillProps) => {
  const api = useSpringRef();
  const springs = useSpring({
    ref: api,
    from: {
      translateX: segmentWidth * selectedIndex
    }
  });

  useEffect(() => {
    api.start({
      to: {
        translateX: segmentWidth * selectedIndex
      },
      config: {
        duration: 300,
        easing: easings.easeInOutSine,
        decay: 300
      }
    });
  }, [api, segmentWidth, selectedIndex]);

  return selectedIndex != null && segmentWidth ? (
    <AnimatedSlider
      style={{ ...springs, width: `${segmentWidth - 8}px` }}
      segmentWidth={segmentWidth}
      data-qa-label={qaLabel}
      isDisabled={isDisabled}
    />
  ) : null;
};
