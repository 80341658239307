export const getMTPSufix = (collapsed: boolean, hasFText: boolean): string => {
  if (collapsed && hasFText) {
    return " MTP";
  }

  if (collapsed && !hasFText) {
    return "m";
  }

  return " ";
};
