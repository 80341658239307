import React, { forwardRef, ReactNode } from "react";
import {
  View,
  StyleSheet,
  AccessibilityRole,
  LayoutChangeEvent,
  useWindowDimensions
} from "react-native";

import { EventTypes, Placement } from "../../types";
import { useGetCoordinates, usePopper } from "../../hooks";
import { StyledView } from "./styled-components";

export interface Props {
  placement: Placement;
  children: ReactNode;
  on?: EventTypes;
}

const Content = forwardRef<View, Props>(
  ({ children, placement, on = "hover" }, ref) => {
    const { isWeb, triggerLayout } = usePopper();
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    const { style, isReady, updateCoordinates } = useGetCoordinates({
      triggerLayout,
      placement,
      windowWidth,
      windowHeight
    });

    const handleOnLayout = ({
      nativeEvent: {
        layout: { width, height }
      }
    }: LayoutChangeEvent) => {
      updateCoordinates(width, height);
    };

    return (
      <StyledView
        accessibilityRole={isWeb ? ("dialog" as AccessibilityRole) : "none"}
        pointerEvents={on === "hover" ? "none" : "auto"}
        ref={ref}
        style={[styles.box, { ...style, opacity: +isReady }]}
        onLayout={handleOnLayout}
      >
        {children}
      </StyledView>
    );
  }
);

const styles = StyleSheet.create({
  box: {
    position: "absolute",
    zIndex: 1
  }
});

export default Content;
