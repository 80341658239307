import { useEffect, useState, useCallback } from "react";
import { DocumentNode } from "graphql";
import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
  OperationVariables
} from "@apollo/client";

const useSubscription = (
  graph: ApolloClient<NormalizedCacheObject>,
  variables: OperationVariables | null,
  query: DocumentNode,
  isTabClosed = false
) => {
  const [subscriptionData, setData] = useState(null);
  const [subscriptionHelper, setSubscription] = useState<{
    unsubscribe: Function;
  }>();

  const onCleanSubscription = useCallback(() => {
    if (subscriptionHelper) {
      graph.stop();
      setData(null);
      if (typeof subscriptionHelper.unsubscribe === "function") {
        subscriptionHelper.unsubscribe();
      }
    }
  }, [subscriptionHelper]);

  useEffect(() => {
    if (variables && isTabClosed) {
      const observer = graph.subscribe({
        query,
        fetchPolicy: "cache-and-network" as FetchPolicy,
        variables
      });

      const subscription = observer.subscribe({
        next(result) {
          setData(result.data);
        }
      });

      setSubscription(subscription);
    }
  }, [JSON.stringify(variables), isTabClosed]);

  return {
    data: subscriptionData,
    subscriptionHelper,
    onCleanSubscription
  };
};

export default useSubscription;
