import { View } from "react-native";
import React, { createContext, forwardRef, useState, useEffect } from "react";
import { useQaLabel } from "../../../hooks/useQaLabel";
import { CheckboxGroupProps, CheckboxGroupContextProps } from "./types";
import { StyledViewContainer, StyledTextLabel } from "./styled-components";

const initialState = {
  selectedValues: [],
  onChange: () => {},
  values: new Set<string>()
};

export const GroupContext = createContext<
  CheckboxGroupContextProps & {
    values: Set<string>;
  }
>(initialState);

export const CheckboxGroup = forwardRef<View, CheckboxGroupProps>(
  (
    {
      label = "",
      children,
      selectedValues,
      qaLabel,
      isDisabled = false,
      onChange,
      ...rest
    },
    ref
  ) => {
    const viewTestProps = useQaLabel(qaLabel);
    const [values, setValues] = useState<Set<string>>(new Set([]));

    useEffect(() => {
      setValues(new Set(selectedValues));
    }, [String(selectedValues)]);

    return (
      // @ts-ignore
      <StyledViewContainer ref={ref} {...rest} {...viewTestProps}>
        <GroupContext.Provider
          value={{ values, selectedValues, onChange, isDisabled }}
        >
          {label !== "" && (
            <View>
              <StyledTextLabel accessibilityLabel={label}>
                {label}
              </StyledTextLabel>
            </View>
          )}
          <View>{children}</View>
        </GroupContext.Provider>
      </StyledViewContainer>
    );
  }
);
