import TvgConfig from "@tvg/conf";
import mediator from "@tvg/mediator";
import { BinaryFn } from "@tvg/ts-types/Functional";
import { PRODUCTS_FDR_X_SELL, PRODUCTS_FDR } from "@tvg/conf/src/product";
import {
  GeolocationFailureError,
  GeolocationRejectedError
} from "@tvg/sh-geolocation/src/types";
import { cleanCacheGeoData } from "@tvg/sh-geolocation/src/solus/utils";
import xSellBridge from "./xSellBridge";
import hybridBridge from "./hybridBridge";
import { GeolocationVerified, UpdatedToken } from "./types/generalTypes";

export const isXSell = (product?: string) =>
  PRODUCTS_FDR_X_SELL.includes(product || TvgConfig().product);

export const isMobile = (product?: string) =>
  ["fdrios", "fdrandroid", "fdrandroidgps", "androidwrapper"].includes(
    product || TvgConfig().product
  ) || isXSell(product);

export const isIos = (product?: string) =>
  ["fdrios", "fdriosxsell"].includes(product || TvgConfig().product);

export const isAndroid = (product?: string) =>
  ["fdrandroid", "androidwrapper", "fdrandroidxsell", "fdrandroidgps"].includes(
    product || TvgConfig().product
  );

export const isMobileServerSide = () => {
  if (typeof window === "undefined") {
    return PRODUCTS_FDR.some((product) => product === TvgConfig().product);
  }
  return false;
};

export const isFdrProduct = () =>
  PRODUCTS_FDR.some((product) => product === TvgConfig().product);

export const getAppBridge = () => {
  if (!isMobile()) return null;

  return isXSell() ? xSellBridge : hybridBridge;
};

export const buildUrl = (url: string) => {
  const fullUrlPattern = /^https?:\/\//;
  const relativeUrlPattern = /^\/.+/;
  const emailOrTelPattern = /^(mailto|tel|sms):.+/;

  let fullUrl = url;
  if (relativeUrlPattern.test(url) && typeof window !== "undefined") {
    fullUrl = `${window.location.protocol}//${window.location.host}${url}`;
  } else if (!fullUrlPattern.test(url) && !emailOrTelPattern.test(url)) {
    fullUrl = `http://${url}`;
  }

  return fullUrl;
};

export const openExternalLink = (link: string) => {
  const appBridge = getAppBridge();
  if (appBridge) {
    appBridge.openExternalLink(buildUrl(link));
  } else if (window) {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const tab = window.open(link, "_blank");
    tab?.focus();
  }
};

export const askLocation = (reason: string = "") => {
  const appBridge = getAppBridge();
  if (!appBridge) {
    console.error("[askLocation]: App bridge not in use!");
  } else {
    appBridge.askLocation(reason);
  }
};

export const onTriggerGeolocation = (reason: string) => {
  const appBridge = getAppBridge();
  if (!appBridge) {
    cleanCacheGeoData();
    mediator.base.dispatch({
      type: "GEOCOMPLY_RETRY"
    });
  } else {
    appBridge.onTriggerGeolocation(reason);
  }
};

export const subscribeGeolocationUpdate = (callback: Function) => {
  const appBridge = getAppBridge();
  if (!appBridge) {
    mediator.base.subscribe(
      "GEOCOMPLY_UPDATE",
      (data: { payload: UpdatedToken }) => {
        const token = data.payload.geolocationData?.geoToken;
        return callback(token);
      }
    );
  } else {
    appBridge.subscribeGeolocationUpdate(callback);
  }
};

export const subscribeGeolocationRequest = (callback: Function) => {
  const appBridge = getAppBridge();
  if (appBridge) {
    appBridge.subscribeGeolocationRequest(callback);
  } else {
    mediator.base.subscribe("GEOCOMPLY_REQUEST", () => {
      callback();
    });
  }
};

export const subscribeGeolocationVerified = (callback: Function) => {
  if (isXSell()) {
    return;
  }

  if (isMobile()) {
    hybridBridge.subscribeGeolocationVerified(callback);
  } else {
    mediator.base.subscribe(
      "GEOCOMPLY_VERIFIED",
      (data: { payload: GeolocationVerified }) => {
        const region = data.payload?.region;
        const token = data.payload?.geoToken;
        callback(token, region);
      }
    );
  }
};

export const subscribeLocationFailure = (callback: Function) => {
  if (isXSell()) {
    return;
  }

  if (isMobile()) {
    hybridBridge.subscribeLocationFailure(callback);
  } else {
    mediator.base.subscribe(
      "GEOCOMPLY_FAILURE",
      (data: { payload: GeolocationFailureError }) => {
        callback(data.payload?.geoErrorName);
      }
    );
  }
};

export const subscribeLocationRejected = (callback: Function) => {
  if (isXSell()) {
    return;
  }

  if (isMobile()) {
    hybridBridge.subscribeLocationRejected(callback);
  } else {
    mediator.base.subscribe(
      "GEOCOMPLY_REJECT",
      (data: { payload: GeolocationRejectedError }) => {
        const troubleshooters = data.payload?.troubleshooters;
        const region = data.payload?.region;
        callback(troubleshooters, region);
      }
    );
  }
};

export const subscribeGeolocationRetry = (callback: Function) => {
  if (!isMobile()) {
    mediator.base.subscribe("GEOCOMPLY_RETRY", () => {
      callback();
    });
  }
};

export const subscribeLaunchInRegion = (
  callback: BinaryFn<string | undefined, string, void>
) => {
  const appBridge = getAppBridge();

  if (appBridge) {
    appBridge.subscribeLaunchInRegion(callback);
  }
};
