import React from "react";
import { get } from "lodash";
import { formatStepName } from "@tvg/promos-onboarding/src/components/preview/helpers/formatStepName";
import { Header } from "@tvg/design-system";
import Table from "../../_molecule/Table";
import { StepWrapper, RowWrapper, ColumnWrapper } from "./styled-components";
import { Props } from "./types";

const theadData = [
  { title: "Render Places", size: "150px" },
  { title: "Component Preview", size: "300px" }
];

export const PromoPreviewStep = ({ step, components }: Props) => (
  <StepWrapper key={get(step, "_uid", "")}>
    <Header tag="h2" marginBottom="space-4" qaLabel="PromoStep-Heading">
      {formatStepName(get(step, "step_config.type", ""))}
    </Header>
    <Table theadData={theadData}>
      {!!get(components, "length", 0) &&
        components.map((component, index) => (
          <RowWrapper key={get(step, `messages[${index}]._uid`, "")}>
            <ColumnWrapper addPadding>
              {get(component, "renderPlaces", "")}
            </ColumnWrapper>
            <ColumnWrapper>{get(component, "component", "")}</ColumnWrapper>
          </RowWrapper>
        ))}
    </Table>
  </StepWrapper>
);

export default PromoPreviewStep;
