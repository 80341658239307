import React from "react";
import { Svg, SvgColor } from "./styled-components";

interface BaseSvgProps {
  width: string;
  height: string;
  fillColor?: SvgColor;
}

const BaseSvg = ({ width, height, fillColor = "blue" }: BaseSvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width.replace("%", "")} ${height}`}
    fillColor={fillColor}
  >
    <rect width={width} height={height} />
  </Svg>
);

export default React.memo(BaseSvg);
