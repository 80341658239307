import styled, { css } from "styled-components";
import { space, compose, typography, system, color } from "styled-system";
import { MtpProps } from "./types";

const transformText = system({
  whiteSpace: {
    property: "whiteSpace"
  }
});
const styledComponentUtils = compose(space, color, typography, transformText);

const baseTextStyle = css<Pick<MtpProps, "isCollapsed">>`
  font-size: ${({ theme, isCollapsed }) =>
    theme.fontSizes[isCollapsed ? "s" : "3xl"]};
  line-height: ${({ theme, isCollapsed }) =>
    theme.lineHeights[isCollapsed ? "l" : "3xl"]};
  ${typography};
`;

export const RaceOff = styled.p<Pick<MtpProps, "isCollapsed" | "hasFullText">>`
  color: ${({ theme }) => theme.colorTokens.content.positive};
  font-weight: ${({ theme, isCollapsed, hasFullText }) =>
    theme.fontWeights[isCollapsed && !hasFullText ? "normal" : "bold"]};
  ${baseTextStyle};
  ${styledComponentUtils};
`;

export const Resulted = styled.p<Pick<MtpProps, "isCollapsed" | "hasFullText">>`
  color: ${({ theme }) => theme.colorTokens.content.mtp};
  font-weight: ${({ theme, isCollapsed, hasFullText }) =>
    theme.fontWeights[isCollapsed && !hasFullText ? "normal" : "bold"]};
  ${baseTextStyle};
  ${styledComponentUtils};
`;

export const RaceTime = styled.p<Pick<MtpProps, "isCollapsed" | "hasFullText">>`
  color: ${({ theme }) => theme.colorTokens.content.brandSecondary};
  ${({ theme, isCollapsed, hasFullText }) => {
    if (isCollapsed) {
      return css`
        ${!hasFullText &&
        css`
          width: min-content;
          text-align: center;
        `};
        font-size: ${theme.fontSizes.s};
      `;
    }

    return css`
      font-size: ${theme.fontSizes["3xl"]};
    `;
  }}
  font-weight: ${({ theme, hasFullText }) =>
    hasFullText ? theme.fontWeights.bold : theme.fontWeights.normal};
  line-height: ${({ theme, isCollapsed }) =>
    theme.lineHeights[isCollapsed ? "s" : "3xl"]};
  ${styledComponentUtils};
`;

export const MinutesText = styled.p<
  Pick<MtpProps, "mtp" | "isCollapsed" | "isInline">
>`
  color: ${({ theme, mtp }) =>
    mtp > 5
      ? theme.colorTokens.content.mtp
      : theme.colorTokens.background.negative};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  ${({ isInline }) =>
    isInline &&
    css`
      display: flex;
      align-items: baseline;
    `};
  ${baseTextStyle};
  ${styledComponentUtils};
`;

export const TextDescription = styled.span<Pick<MtpProps, "mtp" | "isInline">>`
  line-height: 16px;
  margin-bottom: 5px;
  ${({ theme, mtp, isInline }) => css`
    ${isInline
      ? css`
          color: ${mtp > 5
            ? theme.colorTokens.content.mtp
            : theme.colorTokens.background.negative};
          font-weight: ${theme.fontWeights.normal};
        `
      : css`
          color: ${mtp > 5
            ? theme.colorTokens.content.brandSecondary
            : theme.colorTokens.content.negative};
          font-family: ${theme.fonts.condensedRegular};
          font-size: ${theme.fontSizes.m};
          text-transform: uppercase;
        `}
  `};
`;
