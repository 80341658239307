import React from "react";
import formatCurrency from "@tvg/formatter/currency";
import { BalanceContainer } from "../styled-components";
import { Balance } from "../../../Common/Balance";

export const getSucessMessageBodyChild = (
  userBalance: number,
  withdrawalLimit: number
) => (
  <BalanceContainer>
    <Balance
      balance={formatCurrency(userBalance) as string}
      description="Playable Balance"
    />
    {+withdrawalLimit !== +userBalance && (
      <Balance
        balance={formatCurrency(withdrawalLimit) as string}
        description="Withdrawable Balance"
      />
    )}
  </BalanceContainer>
);
