import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Lock = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <rect
      x="4.125"
      y="11.6043"
      width="15.75"
      height="9.77069"
      rx="1"
      fill={backgroundColor}
      fillOpacity="1"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
    <path d="M12 15V18" stroke={lineColor} strokeWidth={strokeWidth} />
    <path
      d="M16.875 11.6043V7.11465C16.875 7.11465 16.875 2.625 12 2.625C7.125
      2.625 7.125 7.11465 7.125 7.11465V11.6043"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);
