// @flow
// $FlowFixMe
import React, { useEffect, useRef, useState, ReactElement } from "react";
import { noop } from "lodash";
import { useDebouncedStringDiff } from "@tvg/custom-hooks";
import { useField } from "@unform/core";
import type { NullaryFn, BinaryFn, UnaryFn } from "@tvg/types/Functional";
import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import { warning } from "../../_static/Icons/icons";
import {
  Container,
  Label,
  OptionalLabel,
  InputWrapper,
  Input,
  IconWrapper,
  ErrorText
} from "./styled-components";

type Props = {
  /**
   * Name (ID) for the input. This needs to be unique in order to get, set values within unform api
   */
  name: string,
  /**
   * Label for input
   */
  label: string | ReactElement,
  /**
   * Label if want to tell that is optional
   */
  optionalLabel?: string,
  /**
   * the QA label for automation
   */
  qaLabel: string,
  /**
   * Input placeholder
   */
  placeholder?: string,
  /**
   * Type of input
   */
  type?: *,
  /**
   * Input mode
   */
  inputMode?: *,
  /**
   * Custom handler
   */
  customHandleChange?: BinaryFn<string, string, void>,
  /**
   * Custom handler
   */
  customHandleOnBlur?: UnaryFn<string, Promise<void>>,
  /**
   * Amount in px for margin top
   */
  marginTop?: number,
  /**
   * Amount in px for margin bottom
   */
  marginBottom?: number,
  /**
   * Pass an icon to be rendered in the right
   */
  icon?: any,
  /**
   * Pass an handler for the icon
   */
  handleIconClick?: NullaryFn<void>,
  /**
   * Pass an handler for input focus
   */
  onFocus?: NullaryFn<void>,
  /**
   * Attribute value to assist autofill on/off or custom per
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
   */
  autoCompleteValue?: string,
  /**
   * Content is transformed to lower case
   */
  isLowerCase?: boolean,
  /**
   * Auto focus input on load
   */
  autoFocus?: boolean,
  /**
   * limits the number of characters the input can have
   */
  maxlength?: number,
  /**
   * should only use Numbers
   */
  isOnlyNumber: boolean,
  /**
   * Function to create a debounced call when changing input value
   */
  debouncedValueCallback?: UnaryFn<string, any>,
  /**
   * Toggle to control the debounced call when changing input value execution
   */
  hasPreloadedInputs?: boolean,
  /**
   * Disable the input, turning it unusable
   */
  isDisabled?: boolean,
  /**
   * Clear errors when the field is focused
   */
  clearErrorOnFocus?: boolean
};

const keysEnum = {
  BACKSPACE: 8,
  TAB: 9,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  DELETE: 46,
  PASTE: 86,
  COMMAND_BUTTON: 91,
  MENU: 93
};

const allowedKeys = [
  keysEnum.BACKSPACE,
  keysEnum.TAB,
  keysEnum.LEFT_ARROW,
  keysEnum.RIGHT_ARROW,
  keysEnum.DELETE,
  keysEnum.PASTE,
  keysEnum.COMMAND_BUTTON,
  keysEnum.MENU
];

const isOnlyNumberObj = {
  pattern: "[0-9]*",
  onKeyDown: (event) => {
    const testNumber = allowedKeys.includes(event.keyCode)
      ? true
      : !isNaN(Number(event.key));

    if (!testNumber) {
      event.preventDefault();
    }
  },
  onWheel: (event) => event.currentTarget.blur()
};

const InputV2 = ({
  name,
  label,
  optionalLabel,
  qaLabel,
  placeholder,
  type,
  inputMode,
  customHandleChange,
  customHandleOnBlur,
  marginTop,
  marginBottom,
  icon,
  handleIconClick,
  onFocus,
  autoCompleteValue,
  isLowerCase,
  autoFocus,
  maxlength,
  isOnlyNumber,
  debouncedValueCallback,
  hasPreloadedInputs,
  isDisabled,
  clearErrorOnFocus
}: Props) => {
  const [trackOfInputValue, setTrackOfInputValue] = useState("");
  const inputRef = useRef(null);
  const isOnlyNumberProps = isOnlyNumber ? isOnlyNumberObj : {};

  useDebouncedStringDiff(
    trackOfInputValue,
    debouncedValueCallback,
    hasPreloadedInputs
  );

  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        if (ref.current.value !== value) {
          // eslint-disable-next-line no-param-reassign
          ref.current.value = value;
        }

        setTrackOfInputValue(value);
      },
      clearValue: (ref) => {
        // eslint-disable-next-line no-param-reassign
        ref.current.value = "";
        setTrackOfInputValue("");
      }
    });
  }, [fieldName, registerField]);

  return (
    <Container
      data-qa-label={qaLabel}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <Label htmlFor={fieldName}>
        {label}
        {optionalLabel && (
          <OptionalLabel data-qa-label="optional-label">
            ({optionalLabel})
          </OptionalLabel>
        )}
      </Label>
      <InputWrapper>
        <Input
          id={fieldName}
          data-qa-label={fieldName}
          name={name}
          onBlur={(event) => {
            const { value } = event.target;
            if (typeof customHandleOnBlur === "function") {
              customHandleOnBlur(value);
            }
          }}
          autoComplete={autoCompleteValue}
          ref={inputRef}
          placeholder={placeholder}
          type={type}
          inputMode={inputMode}
          defaultValue={defaultValue}
          onChange={(event) => {
            const { value } = event.target;
            if (typeof customHandleChange === "function") {
              customHandleChange(value, fieldName);
            }
            if (inputRef.current && value.length <= maxlength) {
              setTrackOfInputValue(value);
            }
          }}
          error={error}
          hasIcon={icon}
          onFocus={() => {
            if (onFocus) {
              onFocus();
            }
            if (clearErrorOnFocus) {
              clearError();
            }
          }}
          isLowerCase={isLowerCase}
          autoFocus={autoFocus}
          disabled={isDisabled}
          maxLength={maxlength}
          {...isOnlyNumberProps}
        />
        {icon && (
          <IconWrapper
            onClick={handleIconClick}
            type="button"
            data-qa-label="icon"
          >
            {icon}
          </IconWrapper>
        )}
      </InputWrapper>
      {error && (
        <ErrorText>
          <Icon icon={warning} color={buildColor("orange", "600")} />
          {error}
        </ErrorText>
      )}
    </Container>
  );
};

InputV2.defaultProps = {
  placeholder: "",
  type: "text",
  inputMode: "",
  customHandleChange: noop,
  optionalLabel: "",
  marginTop: 0,
  marginBottom: 0,
  icon: null,
  handleIconClick: noop,
  autoCompleteValue: "off",
  isLowerCase: false,
  autoFocus: false,
  maxlength: 999999,
  isOnlyNumber: false,
  isDisabled: false
};

export default InputV2;
