import styled, { css } from "styled-components";
import { space, layout, compose } from "styled-system";
import { Theme } from "@tvg/design-system";

const styledUtils = compose(space, layout);

export const Container = styled.div<{
  showOnlySelections?: boolean;
  theme: Theme;
}>`
  overflow: hidden;
  ${styledUtils}
  border-radius: ${({ theme }) => theme.radii.xs};
  ${({ showOnlySelections }) =>
    !showOnlySelections &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.blue["100"]};
    `}

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space["space-3"]};
  }
`;
export const Header = styled.div<{
  theme: Theme;
}>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  background-color: ${({ theme }) => theme.colors.blue_accent["000"]};
  border-bottom-color: ${({ theme }) => theme.colors.blue["100"]};
  padding: ${({ theme }) =>
    `${theme.space["space-2"]} ${theme.space["space-4"]}`};
`;

export const ContentActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Content = styled.div<{
  hasButtons: boolean;
  theme: Theme;
  isMyBets: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.white["900"]};
  padding: ${({ theme }) => theme.space["space-4"]};
  max-width: ${({ hasButtons }) => (hasButtons ? "calc(100% - 72px)" : "100%")};
  ${({ isMyBets }) =>
    isMyBets &&
    css`
      width: 100%;
    `};
`;

export const Actions = styled.div<{
  theme: Theme;
}>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.space["space-3"]};
  margin: ${({ theme }) =>
    `${theme.space["space-2"]} ${theme.space["space-4"]} ${theme.space["space-2"]} 0`};
`;

export const VerticalParagraphLeft = styled.div`
  text-align: left;
`;

export const VerticalParagraphRight = styled.div`
  text-align: right;
`;
