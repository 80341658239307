const core = {
  greenL5: "#E9F8EF",
  greenL4: "#BCEBCE",
  greenL3: "#7FD9A1",
  greenL2: "#31C268",
  greenL1: "#1BB152",
  green: "#128000",
  greenD1: "#00732C",
  greenD2: "#005D23",
  greenD3: "#00491C",
  greenD4: "#002E11",
  blueL5: "#EAF4FF",
  blueL4: "#C8E2FF",
  blueL3: "#99CAFF",
  blueL2: "#64AEFF",
  blueL1: "#2B90FF",
  blue: "#0070EB",
  blueD1: "#005FC8",
  blueD2: "#004EA3",
  blueD3: "#003D81",
  blueD4: "#002650",
  navyL5: "#EDEFF2",
  navyL4: "#C6D3E1",
  navyL3: "#9CB1CB",
  navyL2: "#819CBB",
  navyL1: "#6682A2",
  navy: "#184C8B",
  navyD1: "#14417A",
  navyD2: "#103469",
  navyD3: "#05285A",
  navyD4: "#011638",
  redL5: "#FDECED",
  redL4: "#F9C9CE",
  redL3: "#F198A1",
  redL2: "#EA6875",
  redL1: "#EB4758",
  red: "#D22839",
  redD1: "#C8002B",
  redD2: "#A40023",
  redD3: "#83001C",
  redD4: "#40020D",
  orangeL5: "#FFF1E6",
  orangeL4: "#FFD8B9",
  orangeL3: "#FFB77E",
  orangeL2: "#FF8C31",
  orangeL1: "#F87A1E",
  orange: "#C15400",
  orangeD1: "#A44800",
  orangeD2: "#843B00",
  orangeD3: "#692E00",
  orangeD4: "#401D00",
  yellowL5: "#FFF6BD",
  yellowL4: "#FFDC2E",
  yellowL3: "#EAC300",
  yellowL2: "#C9A800",
  yellowL1: "#A98E00",
  yellow: "#8A7000",
  yellowD1: "#756100",
  yellowD2: "#5F4F00",
  yellowD3: "#4B3F00",
  yellowD4: "#2E2600",
  purpleL5: "#F6F1FE",
  purpleL4: "#E6D6FE",
  purpleL3: "#D4B6FE",
  purpleL2: "#C18FFE",
  purpleL1: "#B265FE",
  purple: "#A530F3",
  purpleD1: "#8F02E1",
  purpleD2: "#7401B7",
  purpleD3: "#61019B",
  purpleD4: "#36005B",
  tealL5: "#CAFDFF",
  tealL4: "#2AF8FF",
  tealL3: "#00DCE3",
  tealL2: "#00BDC3",
  tealL1: "#00A0A5",
  teal: "#008085",
  tealD1: "#006E72",
  tealD2: "#005A5C",
  tealD3: "#004649",
  tealD4: "#002C2E",
  white: "#FFFFFF",
  greyL5: "#F7FBFF",
  greyL4: "#EAF0F6",
  greyL3: "#C9D1DB",
  greyL2: "#B0B7BF",
  greyL1: "#969DA3",
  grey: "#7F848A",
  greyD1: "#6A6F73",
  greyD2: "#2D2F30",
  greyD3: "#1D1E1F",
  greyD4: "#131314",
  black: "#0A0A0A",
  transparentBlack: "rgba(10, 10, 10, 0)",
  blackTint005: "rgba(10, 10, 10, 0.05)",
  blackTint010: "rgba(10, 10, 10, 0.10)",
  blackTint020: "rgba(10, 10, 10, 0.20)",
  blackTint030: "rgba(10, 10, 10, 0.30)",
  blackTint040: "rgba(10, 10, 10, 0.40)",
  blackTint050: "rgba(10, 10, 10, 0.50)",
  blackTint060: "rgba(10, 10, 10, 0.60)",
  blackTint070: "rgba(10, 10, 10, 0.70)",
  blackTint080: "rgba(10, 10, 10, 0.80)",
  blackTint090: "rgba(10, 10, 10, 0.90)",
  transparentWhite: "rgba(255, 255, 255, 0)",
  whiteTint005: "rgba(255, 255, 255, 0.05)",
  whiteTint010: "rgba(255, 255, 255, 0.10)",
  whiteTint020: "rgba(255, 255, 255, 0.20)",
  whiteTint030: "rgba(255, 255, 255, 0.30)",
  whiteTint040: "rgba(255, 255, 255, 0.40)",
  whiteTint050: "rgba(255, 255, 255, 0.50)",
  whiteTint060: "rgba(255, 255, 255, 0.60)",
  whiteTint070: "rgba(255, 255, 255, 0.70)",
  whiteTint080: "rgba(255, 255, 255, 0.80)",
  whiteTint090: "rgba(255, 255, 255, 0.90)"
} as const;

const brand = {
  primary: {
    default: core.blue,
    tint: core.blueL4,
    shade: core.blueD2
  },
  secondary: {
    default: core.navyD3,
    tint: core.navyL4,
    shade: core.navyD4
  },
  tertiary: {
    default: core.yellowL4,
    tint: core.yellowL5,
    shade: core.yellow
  },
  decorative: {
    sgp: core.yellowL4,
    bonus: core.navyD3
  },
  gradient: {
    sports: "linear-gradient(125deg, #005FC8 0%, #003D81 100%)",
    gaming: "linear-gradient(125deg, #61019B 0%, #005FC8 100%)"
  }
};

export const fanduelColorPalette = {
  core,
  brand
} as const;
