// @flow

import { curry } from "./utils";
import Mediator from "./Mediator";
import MediatorNoop from "./MediatorNoop";

import type {
  MediatorInterface,
  EventIdentifier,
  EventSubscriberFn
} from "./Types.js.flow";

const createMediator = (): MediatorInterface => {
  if (typeof window !== "undefined" && !window.mediator) {
    window.mediator = new Mediator();
    return window.mediator;
  }

  if (typeof window !== "undefined" && window.mediator) {
    return window.mediator;
  }

  return new MediatorNoop();
};

const instance: MediatorInterface = createMediator();

export const subscribe = curry(
  (name: EventIdentifier, handler: EventSubscriberFn) =>
    instance.subscribe(name, handler)
);

export const subscribeWithPast = curry(
  (name: EventIdentifier, handler: EventSubscriberFn) =>
    instance.subscribeWithPast(name, handler)
);

export const subscribeTimes = curry(
  (count: number, name: EventIdentifier, handler: EventSubscriberFn) =>
    instance.subscribeTimes(count, name, handler)
);

export const subscribeOnce = subscribeTimes(1);
export const subscribeTwice = subscribeTimes(2);
export const subscribeThrice = subscribeTimes(3);

export const unsubscribe = curry(
  (name: EventIdentifier, handler: EventSubscriberFn) =>
    instance.unsubscribe(name, handler)
);

export const dispatch = curry((type: EventIdentifier, value: *) =>
  instance.dispatch(type, value)
);

export const query = (type: EventIdentifier) => instance.query(type);
