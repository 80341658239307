// @flow

// $FlowFixMe
import React, { useEffect, useCallback, type ElementRef } from "react";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import { noop, get } from "lodash";
import StyledStream from "./styled-components";

type Props = {
  dataHash: string,
  dataTimestamp: string,
  dataStream: string,
  dataQuality: string,
  onPlay: NullaryFn<void>,
  setVideoElement: UnaryFn<ElementRef<"video">, void>,
  setupPlayer: UnaryFn<any, void>
};

const StreamVideo = (props: Props) => {
  const bindVideoRef = () => {
    const videoElem = document.querySelector("video");
    if (videoElem) {
      props.setVideoElement(videoElem);
      const playerId = videoElem.id
        .replace("h5live-player_", "")
        .replace("_html5_api", "")
        .replace("player_", "");
      const player = window.RCN.players[playerId];
      if (player) {
        props.setupPlayer(player);
      }
    }
  };

  useEffect(() => {
    if (window.RCN) {
      window.RCN.addRCNEventHandler("h2livePlay", props.onPlay);
      window.RCN.addRCNEventHandler("h2liveReady", bindVideoRef);
    }

    return () => {
      if (window.RCN) {
        window.RCN.removeRCNEventHandler("h2livePlay", props.onPlay);
        window.RCN.removeRCNEventHandler("h2liveReady", bindVideoRef);
      }
    };
  }, []);

  const refCallback = useCallback((node) => {
    if (node) {
      bindVideoRef();
    }
  });

  const isIosTablet =
    get(window, "__TVG_GLOBALS__.PRODUCT") === "ios2" &&
    get(window, "__TVG_GLOBALS__.DEVICE") === "tablet";

  return (
    <StyledStream
      data-referer="TVG"
      data-hash={props.dataHash}
      data-timestamp={props.dataTimestamp}
      data-stream={props.dataStream}
      data-autoplay="1"
      data-muted="1"
      data-hd={props.dataQuality}
      data-speed={props.dataQuality === "1" ? "771" : "400"}
      data-hidecontrols="1"
      data-poster="none"
      data-forcetech={isIosTablet ? "iosh2live" : undefined}
      ref={refCallback}
    />
  );
};

StreamVideo.defaultProps = {
  dataHash: "",
  dataTimestamp: "",
  dataStream: "",
  dataQuality: "",
  onPlay: noop,
  setVideoElement: noop,
  setupPlayer: noop
};

export default StreamVideo;
