import styled, { css } from "styled-components";
import {
  fontMedium,
  fontNormal,
  fontCondensedNormal
} from "@tvg/atomic-ui/_static/Typography";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const CardsList = styled.ul`
  list-style: none;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  background-color: ${
    buildColor(
      "white",
      "100"
    ) /* TODO: DS equivalent buildColor("white", "900") */
  };
  flex-wrap: wrap;
  padding: 14px;
`;

export const PaymentOption = styled.li<{
  isMainMethod: boolean;
  isDisabled: boolean;
  isExpired: boolean;
}>`
  width: 196px;
  ${({ isMainMethod }) =>
    isMainMethod &&
    css`
      height: 70px;
    `};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${buildColor("blue", "100")};
  padding: 12px;
  margin: 6px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 1px 2px ${buildColor("blue", "000")};
  background-color: ${buildColor("white", "100")};
  cursor: pointer;

  & > svg {
    flex-shrink: 0;
  }

  ${({ isDisabled, isExpired }) =>
    isDisabled || isExpired
      ? css`
          background-color: ${buildColor("blue", "000")};
          box-shadow: none;
          cursor: pointer;
        `
      : css`
          &:hover {
            background-color: ${buildColor("blue_accent", "000")};
          }

          &:active {
            background-color: ${buildColor("blue_accent", "100")};
          }
        `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      svg {
        filter: grayscale(1);
      }
    `}
`;

interface TextStyleProps {
  isDisabled: boolean;
}

const disableTextStyle = css<TextStyleProps>`
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${buildColor("grey", "800")};
    `}
`;

export const Title = styled.h4<TextStyleProps & { isCapitalize: boolean }>`
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${disableTextStyle}
  ${({ isCapitalize }) => isCapitalize && "text-transform: capitalize;"};
`;

export const Description = styled.span<TextStyleProps>`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${disableTextStyle}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    flex-shrink: 0;
  }
`;

export const AddPaymentText = styled.span`
  margin-left: 16px;
  display: flex;
  font-family: ${fontMedium};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${buildColor("blue_accent", "500")};
  background-color: ${
    buildColor(
      "white",
      "100"
    ) /* TODO: DS equivalent buildColor("white", "900") */
  };
  cursor: pointer;

  & > svg {
    margin-right: 8px;
  }
`;

export const AddPaymentIcon = styled.li`
  height: 70px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 14px;
`;

export const PaymentTypeDescription = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ExpiredTag = styled.span`
  text-transform: uppercase;
  color: ${buildColor("orange", "800")};
  font-family: ${fontCondensedNormal};
  font-size: 14px;
  line-height: 16px;
  margin-left: 4px;
`;

export const WarningDisableCreate = styled.span`
  display: inline-flex;
  color: ${buildColor("grey", "600")};
  font-family: ${fontMedium};
  margin-left: 16px;
  > svg {
    margin-right: 9px;
  }
`;
