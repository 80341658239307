import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const LiveVideo = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19.875H6.5625H3.375C2.13236 19.875 1.125 18.8676 1.125
      17.625V6.375C1.125 5.13236 2.13236 4.125 3.375 4.125H20.625C21.8676 4.125
      22.875 5.13236 22.875 6.375V17.625C22.875 18.8676 21.8676 19.875 20.625
      19.875H17.4375H12ZM9 16.5V7.5L16.5 12L9 16.5Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M6.5625 19.875H12H17.4375M6.5625 19.875L3 23.25M6.5625
      19.875H3.375C2.13236 19.875 1.125 18.8676 1.125 17.625V6.375C1.125
      5.13236 2.13236 4.125 3.375 4.125H20.625C21.8676 4.125 22.875 5.13236
      22.875 6.375V17.625C22.875 18.8676 21.8676 19.875 20.625
      19.875H17.4375M17.4375 19.875L21 23.25M9 7.5V16.5L16.5 12L9 7.5Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
