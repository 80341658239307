import { Animated, View, TouchableOpacity, Pressable } from "react-native";
import styled, { css } from "styled-components/native";
import { zIndex, ZIndexProps } from "../../../../theming/styledSystem";

const Overlay = (isFixed: boolean) => css`
  ${zIndex};
  position: ${isFixed ? "fixed" : "absolute"};
  cursor: default;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const StyledView = styled(Animated.View)<ZIndexProps>`
  ${Overlay(false)};
`;

export const StyledPressable = styled(Pressable)<ZIndexProps>`
  ${Overlay(true)};
`;

export const StyledContent = styled(View)<ZIndexProps>`
  ${Overlay(false)};
`;
export const StyledTouchableOpacity = styled(TouchableOpacity)<ZIndexProps>`
  ${zIndex};
`;
