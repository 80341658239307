import { Dispatch, SetStateAction } from "react";
import { capitalize, get } from "lodash";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import formatCurrency from "@tvg/formatter/currency";
import type { UnaryFn } from "@tvg/ts-types/Functional";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { StatusMessages, TransactionStatusType } from "./types";
import {
  ErrorMessage,
  MzmTimeoutMessage,
  PawsContent,
  PaymentType
} from "../../../types";
import { handleCloseError } from "../Deposit/methods/errorHandling";

const getWithdrawSuccessMessages = (
  content: PawsContent,
  paymentType: PaymentType
) => {
  const paymentTypeSuccessMessage = get(
    content,
    `${paymentType}WithdrawSuccessMessage`,
    ""
  );

  const defaultSuccessMessage = get(
    content,
    "transactionWithdrawSuccessMessage",
    ""
  );

  return paymentTypeSuccessMessage || defaultSuccessMessage;
};

export const handleTransactionStatusRequest = (
  type: "withdraw" | "deposit",
  paymentType: PaymentType,
  transactionStatus: TransactionStatusType,
  content: PawsContent,
  setIsLoading: UnaryFn<boolean, void>,
  amount: number,
  setSuccessMessages: Dispatch<SetStateAction<StatusMessages>>,
  setShowSuccessMessage: Dispatch<SetStateAction<boolean>>,
  setErrorMessage: Dispatch<SetStateAction<ErrorMessage>>,
  enableTransactionPage: (value: boolean) => void,
  isSkipMZMErrorModalsToggleOn: boolean,
  errorCodesToSkipMZMErrorModals: string[],
  onClose: NullaryFn<void>,
  refetchAvailableMethods: NullaryFn<void>,
  showSuccessModal?: boolean,
  setValue?: Dispatch<SetStateAction<string>>,
  onClean?: NullaryFn<void>
) => {
  const status = get(transactionStatus, "status", "");

  if (status === "IN_PROGRESS") {
    setIsLoading(true);
    enableTransactionPage(false);
    setShowSuccessMessage(false);
  } else if (status === "SUCCESS") {
    setIsLoading(false);
    enableTransactionPage(false);

    if (showSuccessModal) {
      setShowSuccessMessage(true);
    } else if (typeof onClose === "function") {
      onClose();
    }

    if (typeof setValue === "function") {
      setValue("");
    }

    if (typeof onClean === "function") {
      onClean();
    }

    setErrorMessage((state) => ({ ...state, isOpen: false }));

    refetchAvailableMethods();

    const successMessages =
      type === "deposit"
        ? content.transactionDepositSuccessMessage
        : getWithdrawSuccessMessages(content, paymentType);

    setSuccessMessages({
      title: successMessages.title,
      description:
        type === "deposit"
          ? [
              replaceCAPIVariables(successMessages.description1, {
                added: `<b>${formatCurrency(amount)}</b>`,
                balance: `<b>${formatCurrency(
                  get(transactionStatus, "balance")
                )}</b>`
              }),
              replaceCAPIVariables(successMessages.description2, {
                added: `<b>${formatCurrency(amount)}</b>`,
                balance: `<b>${formatCurrency(
                  get(transactionStatus, "balance")
                )}</b>`
              })
            ]
          : [
              replaceCAPIVariables(successMessages.description1, {
                removed: `<b>${formatCurrency(amount)}</b>`
              }),
              replaceCAPIVariables(successMessages.description2, {
                balance: `<b>${formatCurrency(
                  get(transactionStatus, "balance")
                )}</b>`
              })
            ]
    });
  } else {
    setIsLoading(false);
    enableTransactionPage(true);
    setShowSuccessMessage(false);
    const errorCode = get(transactionStatus, "errorCode", "default");

    if (
      !isSkipMZMErrorModalsToggleOn ||
      (isSkipMZMErrorModalsToggleOn &&
        !errorCodesToSkipMZMErrorModals.includes(errorCode.toString()))
    ) {
      setErrorMessage((prevState) => ({
        ...prevState,
        isOpen: true,
        errorCode,
        isRetry: false
      }));
    }

    if (typeof setValue === "function") {
      // Fill the amount in order to help the user retrying the process
      setValue(amount.toString());
    }

    if (typeof onClean === "function") {
      onClean();
    }
  }
};

export const toggleTimeoutMessage = (
  isOpen: boolean,
  mzmTimeoutContent: MzmTimeoutMessage,
  setErrorMessage: Dispatch<SetStateAction<ErrorMessage>>,
  onClose: () => void
) =>
  setErrorMessage({
    isOpen,
    title: mzmTimeoutContent.default.title,
    description: mzmTimeoutContent.default.description,
    actions: [
      {
        onClick: () => {
          handleCloseError(setErrorMessage);
          onClose();
        },
        text: capitalize(mzmTimeoutContent.default.actions[0]),
        isStretched: true,
        isUppercase: false,
        qaLabel: "mzm-timeout-close-btn"
      }
    ],
    errorCode: "",
    isRetry: false,
    retryCount: 0,
    type: mzmTimeoutContent.default.type
  });

export const successMessageInitialState = {
  title: "You made a successful withdrawal",
  description: "You made a withdrawal of ~removed~ from your wallet"
};
