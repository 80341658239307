import { EventsState } from "../../../hooks/useEvents";
import {
  alertInlinePressableVariants,
  alertToastPressableVariants
} from "../styled-components";
import { AlertInlineVariants, AlertInlineTypes } from "../types";

export const getPressableColor = (
  type: AlertInlineTypes,
  variant: AlertInlineVariants,
  events?: EventsState
): string => {
  const variantStyle =
    type === "toast"
      ? alertToastPressableVariants[variant]
      : alertInlinePressableVariants[variant];

  if (events?.isPressed || events?.isFocused) return variantStyle.active;
  if (events?.isHovered) return variantStyle.hover;
  return variantStyle.base;
};
