import React from "react";
import { IconPathProps } from "../../types";

export const ArrowLeft = ({ lineColor, strokeWidth }: IconPathProps) => (
  <path
    d="M12 4.5L4.5 12L12 19.5M21.75 12H4.57617"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
