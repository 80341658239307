import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentType, PaymentTypeItem } from "@tvg/wallet/src/types";

export type State = {
  activeCreatePM: PaymentType | null;
  isModalOpen: boolean;
  mainMethods: PaymentTypeItem[];
};

export const initialState: State = {
  activeCreatePM: null,
  isModalOpen: false,
  mainMethods: []
};

const createPMModalSlice = createSlice({
  name: "paws",
  initialState,
  reducers: {
    openCreatePMModal(state, action: PayloadAction<string>) {
      // @ts-ignore
      state.activeCreatePM = action.payload;
      state.isModalOpen = true;
    },
    closeCreatePMModal(state) {
      state.isModalOpen = false;
    },
    togglePMCreate(state) {
      state.isModalOpen = !state.isModalOpen;
    },
    setCreateMainMethods(state, action: PayloadAction<PaymentTypeItem[]>) {
      state.mainMethods = action.payload;
    }
  }
});

export const {
  openCreatePMModal,
  closeCreatePMModal,
  togglePMCreate,
  setCreateMainMethods
} = createPMModalSlice.actions;

export default createPMModalSlice.reducer;
