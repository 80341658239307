import styled from "styled-components/native";
import { View, Text } from "react-native";
import { BetSelectionProps } from "./types";

export const BetSelectionContainer = styled(View)<
  Pick<BetSelectionProps, "isDark">
>`
  align-items: center;
  background-color: ${({ theme, isDark }) =>
    isDark
      ? theme.colorTokens.component.betslip.backgroundExpanded
      : "transparent"};
  border: 1px solid
    ${({ theme, isDark }) =>
      isDark
        ? theme.colorTokens.component.betslip.backgroundExpanded
        : theme.colors.blue[100]};
  border-radius: ${({ theme }) => theme.space["space-1"]};
  flex-direction: row;
  height: 42px;
  padding: 0 11px;
`;

export const BetText = styled(Text)<Pick<BetSelectionProps, "isDark">>`
  color: ${({ theme, isDark }) =>
    isDark ? theme.colorTokens.content.onDark : theme.colors.black[900]};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding-right: ${({ theme }) => theme.space["space-2"]};
`;
