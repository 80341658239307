import React from "react";
import { ExclamationFilled } from "@tvg/atomic-ui/_static/Icons/paws";
import { Dispatch, Store } from "redux";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { arrowUp, arrowDown } from "@tvg/atomic-ui/_static/Icons/icons";
import * as allIcons from "@tvg/atomic-ui/_static/Icons/icons";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { get, orderBy } from "lodash";
import mediator from "@tvg/mediator";
import { getPawsContent } from "@tvg/sh-lib-paws/redux/selectors";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import OptionTooltip from "../TooltipOption";
import type {
  PawsContent,
  PaymentTypeOption,
  UnaryFn,
  OnNewMethodArgs,
  PaymentTypeItem,
  PaymentType
} from "../../types";
import { isCardExpired, shouldCapitalizeTitle } from "../../utils";
import Flag from "../Flag";
import {
  CardsList,
  PaymentOption,
  Title,
  Description,
  AddPaymentText,
  AddPaymentIcon,
  PaymentTypeDescription,
  TitleContainer,
  WarningDisableCreate
} from "./styled-components";
import type { Props } from "./types";
import { clickAccountOnFile, clickAddPaymentMethod } from "./utils";

const renderPaymentOptions = (
  method: PaymentTypeItem,
  displayAddMethod: boolean,
  allPaymentMethodOptions: PaymentTypeOption[] | null,
  setActiveOption: UnaryFn<string | null, void> = () => {},
  activeOption?: string | null,
  hasTooltips: boolean = false,
  dispatch?: Dispatch,
  onNewPaymentMethod: UnaryFn<OnNewMethodArgs, void> = () => null,
  disableChildrenMethods: boolean = false
) => {
  const { paymentType, paymentTypeOptions, mainList } = method;
  const isOPM = method.paymentType === PaymentTypeEnum.OTHER_PAYMENT;

  return (
    <>
      {orderBy(
        paymentTypeOptions,
        (paymentMethod) => get(paymentMethod, "lastUsedDateTime", ""),
        ["desc"]
      ).map((payment: PaymentTypeOption) => {
        const isExpired = isCardExpired(get(payment, "expirationDate", ""));
        const isDisabled =
          disableChildrenMethods ||
          (!payment.depositsAvailable && !payment.withdrawalsAvailable) ||
          (isOPM && !payment.paymentTypeAvailable);

        const isCapitalize = shouldCapitalizeTitle(paymentType);

        const qaLabel = `paymentOption-${paymentType}-${payment.id || ""}`;

        return (
          <PaymentOption
            data-qa-label={qaLabel}
            isMainMethod={mainList}
            key={payment.id}
            isDisabled={isDisabled}
            isExpired={isExpired}
            onClick={() =>
              clickAccountOnFile(
                isExpired,
                isDisabled,
                payment,
                allPaymentMethodOptions,
                get(payment, "paymentType", paymentType),
                setActiveOption,
                dispatch,
                activeOption
              )
            }
          >
            <PaymentTypeDescription>
              <TitleContainer>
                <Title
                  isDisabled={isDisabled || isExpired}
                  isCapitalize={isCapitalize}
                  data-qa-label={`${qaLabel}-title`}
                >
                  {isCapitalize ? payment.title?.toLowerCase() : payment.title}
                </Title>
              </TitleContainer>
              {payment.description && (
                <Description
                  isDisabled={isDisabled || isExpired}
                  data-qa-label={`${qaLabel}-description`}
                >
                  {payment.description}
                </Description>
              )}
            </PaymentTypeDescription>
            {isExpired && <Flag text="expired" qaLabel="card-expired-flag" />}

            {hasTooltips && !isExpired && !isDisabled && (
              <Icon
                qaLabel={"arrow-button-" + paymentType + "-" + payment.id}
                icon={
                  activeOption && activeOption === payment.id
                    ? arrowUp
                    : arrowDown
                }
                size={16}
                color={buildColor("blue_accent", "500")}
              />
            )}

            {!isDisabled &&
              hasTooltips &&
              activeOption &&
              activeOption === payment.id && (
                <OptionTooltip
                  payment={payment}
                  activeOption={activeOption}
                  setActiveOption={setActiveOption}
                  paymentType={
                    paymentType !== PaymentTypeEnum.OTHER_PAYMENT
                      ? paymentType
                      : payment.paymentType
                  }
                  onClose={() => {
                    mediator.base.dispatch({
                      type: "PAWS:DEPOSIT_WITHDRAW_OPTIONS_MODAL",
                      payload: {
                        paymentType:
                          paymentType !== PaymentTypeEnum.OTHER_PAYMENT
                            ? paymentType
                            : payment.paymentType,
                        isOpen: false
                      }
                    });
                    setActiveOption(null);
                  }}
                />
              )}
          </PaymentOption>
        );
      })}
      {displayAddMethod && (
        <AddPaymentIcon
          data-qa-label={"cards-on-file-add-" + paymentType}
          onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            clickAddPaymentMethod(
              event,
              paymentType,
              onNewPaymentMethod,
              paymentTypeOptions,
              dispatch
            );
          }}
        >
          <Icon
            icon={allIcons.plus}
            size={16}
            color={buildColor("blue_accent", "500")}
          />
        </AddPaymentIcon>
      )}
    </>
  );
};

const renderEmptyState = (
  paymentType: PaymentType,
  addMethodText: string,
  onNewPaymentMethod: UnaryFn<OnNewMethodArgs, void> = () => null,
  paymentTypeOptions: PaymentTypeOption[] | null,
  dispatch?: Dispatch,
  disable: boolean = false
) => {
  const pawsLabels = useSelector<Store, PawsContent>(getPawsContent);

  return disable ? (
    <WarningDisableCreate data-qa-label={`depositBlocked-${paymentType}`}>
      <ExclamationFilled width="16" height="15" />{" "}
      {get(
        pawsLabels,
        `deposit.${paymentType}.disabledCardOnFileMessage`,
        "Deposit is not available at the moment"
      )}
    </WarningDisableCreate>
  ) : (
    <AddPaymentText
      data-qa-label={`addPaymentText-${paymentType}`}
      onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
        clickAddPaymentMethod(
          event,
          paymentType,
          onNewPaymentMethod,
          paymentTypeOptions,
          dispatch
        )
      }
    >
      <Icon
        icon={allIcons.plus}
        size={16}
        color={buildColor("blue_accent", "500")}
      />
      {addMethodText}
    </AddPaymentText>
  );
};

const DesktopCardsOnFile = (props: Props) => {
  const {
    method,
    addMethodText,
    displayAddMethod,
    activeOption,
    setActiveOption,
    hasTooltips,
    onNewPaymentMethod,
    allPaymentMethodOptions,
    disableAddPM,
    disableChildrenMethods
  } = props;
  const { paymentTypeOptions, paymentType } = method;

  const dispatch = useDispatch();

  return (
    <CardsList>
      {paymentTypeOptions && paymentTypeOptions.length > 0
        ? renderPaymentOptions(
            method,
            displayAddMethod,
            allPaymentMethodOptions,
            setActiveOption,
            activeOption,
            hasTooltips,
            dispatch,
            onNewPaymentMethod,
            disableChildrenMethods
          )
        : renderEmptyState(
            paymentType,
            addMethodText,
            onNewPaymentMethod,
            paymentTypeOptions,
            dispatch,
            disableAddPM
          )}
    </CardsList>
  );
};

export default DesktopCardsOnFile;
