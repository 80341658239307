import { get } from "lodash";
import type { Product } from "./types";

const deviceMapped = {
  touch3: {
    iOS: "web_ios",
    AndroidOS: "web_android"
  },
  ios2: {
    iOS: "iOS",
    AndroidOS: "Android"
  },
  tvg4: {
    iOS: "desktop",
    AndroidOS: "desktop"
  },
  fdrmobile: {
    iOS: "web_ios",
    AndroidOS: "web_android"
  },
  fdrios: {
    iOS: "iOS",
    AndroidOS: "Android"
  },
  fdrandroid: {
    iOS: "iOS",
    AndroidOS: "Android"
  },
  androidwrapper: {
    iOS: "iOS",
    AndroidOS: "Android"
  }
};

export default (os: string, product: Product) =>
  get(deviceMapped, `${product}.${os}`, "web_ios");
