import styled from "styled-components";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const ModalContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  svg {
    margin-right: 8px;
    height: 18px;
  }
`;
export const ModalTextContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
`;

export const ModalTitleText = styled.p<{ isCapitalize: boolean }>`
  line-height: 18px;
  color: ${buildColor("grey", "900")};
  font-family: ${fontNormal};
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ isCapitalize }) => isCapitalize && "text-transform: capitalize;"};
`;

export const ModalSubtitleText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${buildColor("grey", "800")};
  font-family: "Roboto-Regular", sans-serif;
  text-transform: none;
`;
