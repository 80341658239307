import { RefObject } from "react";
import { View } from "react-native";

import { Layout } from "../types";

const measureLayout = (ref: RefObject<View>, isWeb?: boolean) =>
  new Promise<Layout>((resolve) => {
    if (ref.current) {
      if (isWeb) {
        // @ts-ignore
        const { x, y, width, height } = ref.current.getBoundingClientRect();
        resolve({ x, y, width, height });
      } else {
        ref.current.measureInWindow(
          (x: number, y: number, width: number, height: number) => {
            resolve({ x, y, width, height });
          }
        );
      }
    }
  });

export default measureLayout;
