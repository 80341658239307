import styled, { css } from "styled-components";
import { Theme } from "styled-system";

export const PickBetsContainer = styled.div<{
  hasRoundBottomBorder: boolean;
  theme: Theme;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.space["space-3"]} ${theme.space["space-4"]}`};
  background-color: ${({ theme }) => theme.colors.white["900"]};
  border-top: 1px solid ${({ theme }) => theme.colors.blue["100"]};
  ${({ hasRoundBottomBorder }) =>
    hasRoundBottomBorder &&
    css`
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
`;

export const PickBetsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const PickBetsLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  text-decoration: none;
`;
