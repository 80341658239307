import styled from "styled-components";
import { buildColor } from "@tvg/design-system";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const Link = styled.a`
  margin-left: 4px;
  color: ${buildColor("blue_accent", "500")};
  text-decoration: none;
  font-weight: 500;
  line-height: 125%;
  font-size: 14px;
  font-family: ${fontNormal};
  vertical-align: baseline;
  white-space: nowrap;
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${buildColor("blue", "100")};
  margin: 20px -12px;
`;

export const AlertContainer = styled.div`
  margin-bottom: 20px;
`;
