import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const ButtonContainer = styled(Link)`
  outline: none;
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: ${buildColor("white", "100")};
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 1px 2px ${buildColor("blue", "000")};
  border-radius: 4px;
  width: 100%;

  &:active {
    background-color: ${buildColor("blue", "000")};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${buildColor("blue", "000")};
    `}
`;

export const TextContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
  margin-right: auto;
  overflow: hidden;
`;

const sharedButtonStyles = css`
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 18px;
`;

export const ButtonTitle = styled.span`
  ${sharedButtonStyles};
  display: flex;
  align-items: center;
  color: ${buildColor("grey", "900")};
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${buildColor("grey", "800")};
    `}
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonSubTitle = styled.span`
  ${sharedButtonStyles};
  margin-left: 12px;
  color: ${buildColor("grey", "800")};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonDescription = styled.span`
  ${sharedButtonStyles};
  color: ${buildColor("grey", "800")};
`;

export const ArrowIconContainer = styled.span`
  display: inline-flex;
  transform: rotate(${({ rotateArrow }) => rotateArrow || 0}deg);
`;
