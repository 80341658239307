import React, { FC } from "react";

export const Singapore: FC = () => (
  <>
    <g clipPath="url(#clip0_18106_2992)">
      <path d="M32 0H0V10H32V0Z" fill="#D22839" />
      <path d="M32 10H0V20H32V10Z" fill="#F7FBFF" />
      <path
        d="M6.99783 1.23379C7.46127 1.23379 7.90155 1.29872 8.29547
        1.42859C6.27954 1.60175 4.70387 3.13855 4.70387 4.97838C4.70387 6.8182
        6.27954 8.33335 8.29547 8.52816C7.87838 8.65803 7.46127 8.72297 6.99783
        8.72297C4.79653 8.72297 2.98914 7.03465 2.98914 4.97838C2.98914 2.9221
        4.77336 1.23379 6.99783 1.23379ZM9.10647 1.92643L9.31499
        2.55414H10.0101L9.45405 2.94374L9.66257 3.57145L9.10647 3.18184L8.55032
        3.57145L8.7589 2.94374L8.20275 2.55414H8.89789L9.10647 1.92643ZM11.2382
        3.46323L11.4468 4.09093H12.1419L11.5858 4.48054L11.7943 5.10825L11.2382
        4.71864L10.6822 5.10825L10.8907 4.48054L10.3346 4.09093H11.0297L11.2382
        3.46323ZM6.9747 3.46323L7.18322 4.09093H7.87837L7.32227 4.48054L7.53079
        5.10825L6.9747 4.71864L6.41854 5.10825L6.62712 4.48054L6.07097
        4.09093H6.76612L6.9747 3.46323ZM7.76251 5.82253L7.97103
        6.45024H8.66618L8.11008 6.83985L8.3186 7.46755L7.76251 7.07794L7.20641
        7.46755L7.41493 6.83985L6.85884 6.45024H7.55399L7.76251 5.82253ZM10.4504
        5.82253L10.659 6.45024H11.3541L10.798 6.83985L11.0065 7.46755L10.4504
        7.07794L9.89428 7.46755L10.1029 6.83985L9.54671 6.45024H10.2419L10.4504
        5.82253Z"
        fill="#F7FBFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_18106_2992">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V18C32
          19.1046 31.1046 20 30 20H2C0.895431 20 0 19.1046 0 18V2Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </>
);
