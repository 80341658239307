import styled from "styled-components/native";
import { AnimatedDropdownProps } from "./types";
import { Theme } from "../../../../../theming/types";

export const Container = styled.View<
  Pick<AnimatedDropdownProps, "top" | "left" | "width" | "isTop">
>`
  display: flex;
  left: ${({ left }) => left}px;
  padding-top: 8px;
  position: ${({ isTop }) => (isTop ? "fixed" : "absolute")};
  top: ${({ top }) => top}px;
  width: ${({ width }) => width}px;
`;

export const ContentContainer = styled.View`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
`;

export const Dropdown = styled.View<{ theme: Theme }>`
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  border-color: ${({ theme }) => theme.colorTokens.border.default};
  border-radius: 4px;
  border-width: 1px;
`;

export const ListItem = styled.View`
  display: flex;
`;
