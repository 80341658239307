import mediator from "@tvg/mediator";
import { AlchemerFeedbackWidget } from "./types";

export const sendOpenFeedbackWidgetEvent = () =>
  mediator.base.dispatch({
    type: AlchemerFeedbackWidget.OPEN
  });

export const sendSubmitFeedbackWidgetEvent = () =>
  mediator.base.dispatch({
    type: AlchemerFeedbackWidget.SUBMIT
  });
