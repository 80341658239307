import React from "react";
import { get } from "lodash";
import { Header, Button } from "@tvg/design-system";
import { PromoPreviewWrapper, PromoPreviewHeader } from "./styled-components";
import { Props } from "./types";
import PromoPreviewStep from "../../_organism/PromoPreviewStep";

export const PromoPreviewTemplate = ({
  steps,
  components,
  refetchStoryblok
}: Props) => (
  <PromoPreviewWrapper>
    <PromoPreviewHeader>
      <Header tag="h1" marginBottom="space-7" qaLabel="PromoPreview-Heading">
        Steps
      </Header>
      <Button
        variant="primary"
        icon="refresh"
        onPress={refetchStoryblok}
        qaLabel="PromoPreview-RefetchButton"
      >
        Refresh preview
      </Button>
    </PromoPreviewHeader>

    {steps.map((step, index) => (
      <PromoPreviewStep
        key={get(step, "_uid", "")}
        step={step}
        components={components[+index]}
      />
    ))}
  </PromoPreviewWrapper>
);

export default PromoPreviewTemplate;
