import React from "react";
import Svg, { Path } from "react-native-svg";
import { tvgColorPalette as colors } from "../../../theming/themes/common";

export const Info = () => (
  <Svg width="60" height="60" viewBox="0 0 60 60" fill="none">
    <Path
      d="M58.095 30.0108C58.095 45.5272 45.5165 58.1058 30 58.1058C14.4836 58.1058 1.90504 45.5272 1.90504 30.0108C1.90504 14.4944 14.4836 1.91583 30 1.91583C45.5165 1.91583 58.095 14.4944 58.095 30.0108Z"
      fill={colors.blue_accent[200]}
    />
    <Path
      d="M30 45.5V26.125M30 20.3125V14.5M58.095 30.0108C58.095 45.5272 45.5165 58.1058 30 58.1058C14.4836 58.1058 1.90504 45.5272 1.90504 30.0108C1.90504 14.4944 14.4836 1.91583 30 1.91583C45.5165 1.91583 58.095 14.4944 58.095 30.0108Z"
      stroke={colors.blue_accent[600]}
      strokeWidth="3"
    />
  </Svg>
);
