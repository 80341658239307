import React, { useRef, useEffect, useCallback } from "react";
import {
  Animated,
  Platform,
  Dimensions,
  LayoutChangeEvent
} from "react-native";
import { easeBackInOut } from "d3-ease";
import { useQaLabel } from "../../../../hooks/useQaLabel";
import { ModalPageProps } from "./types";
import { Page } from "./styled-components";
import { useModalPagesContext } from "../../context/modalPagesContext";
import { ACTIONS } from "../../context/modalPagesReducer";

const useNativeDriver = Platform.OS !== "web";

type AnimationDirection = "IN" | "OUT";
const AnimatedPage = Animated.createAnimatedComponent(Page);

export const ModalPage = ({ children, page, qaLabel }: ModalPageProps) => {
  const [{ activePage, nextPage, isAnimating }, dispatch] =
    useModalPagesContext();
  const viewTestProps = useQaLabel(qaLabel ?? "modalPage" + page);
  const sideAnimation = useRef(
    new Animated.Value(page === activePage ? 1 : 0)
  ).current;
  const opacityAnimation = useRef(
    new Animated.Value(page === activePage ? 1 : 0)
  ).current;

  useEffect(() => {
    // skip animation
    if (
      page === activePage ||
      (nextPage && activePage && nextPage < activePage && page === nextPage)
    ) {
      sideAnimation.setValue(1);
      opacityAnimation.setValue(1);
    }

    // wait for animations to end until re-running
    if (isAnimating || activePage === nextPage) return;

    if (typeof page === "number" && page === nextPage) {
      runChangePageAnimated("IN");
    } else if (
      typeof page === "number" &&
      page === activePage &&
      nextPage &&
      page > nextPage
    ) {
      runChangePageAnimated("OUT");
    }
  }, [activePage, nextPage, page]);

  const runChangePageAnimated = useCallback((direction: AnimationDirection) => {
    dispatch({ type: ACTIONS.START_ANIMATION });

    const animatedConfig = {
      toValue: direction === "IN" ? 1 : 0,
      duration: 300,
      useNativeDriver
    };

    Animated.parallel([
      Animated.timing(sideAnimation, {
        ...animatedConfig,
        easing: easeBackInOut?.overshoot(0.8)
      }),
      Animated.timing(opacityAnimation, animatedConfig)
    ]).start(() => {
      dispatch({
        type: ACTIONS.END_CHANGE_PAGE
      });
    });
  }, []);

  const handleLayoutChange = useCallback((event: LayoutChangeEvent) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload: {
        pageHeight: event.nativeEvent.layout.height
      }
    });
  }, []);

  return page === activePage || page === nextPage ? (
    <AnimatedPage
      {...viewTestProps}
      onLayout={handleLayoutChange}
      style={{
        opacity: opacityAnimation,
        transform: [
          {
            translateX: sideAnimation.interpolate({
              inputRange: [0, 1],
              outputRange: [Dimensions.get("window").width / 2, 0]
            })
          }
        ]
      }}
    >
      {children}
    </AnimatedPage>
  ) : null;
};

export { ModalPageProps };
