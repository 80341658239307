import { Dispatch } from "redux";
import React, { SetStateAction } from "react";
import {
  ActionCreatorWithoutPayload,
  PayloadAction
} from "@reduxjs/toolkit/dist/createAction";
import { DepositsPayload } from "@tvg/sh-lib-paws/redux/slices/depositFundsModalSlice";
import { SelectedPaymentMethodPayload } from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import { History } from "@tvg/ts-types/History";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";

export type NullaryFn<R> = () => R;
export type UnaryFn<A, R> = (a: A) => R;
export type BinaryFn<A, B, R> = (a: A, b: B) => R;
export type TernaryFn<A, B, C, R> = (a: A, b: B, c: C) => R;
export type QuadFn<A, B, C, D, R> = (a: A, b: B, c: C, d: D) => R;
export type QuinFn<A, B, C, D, F, R> = (a: A, b: B, c: C, d: D, f: F) => R;

// export type MultiSourceIcons = IconNames | "paypal";
export type MultiSourceIcons = string;

export type PaymentType =
  | "ACH"
  | "CASH"
  | "CC"
  | "CCP"
  | "CCS"
  | "CHK"
  | "DEB"
  | "DEBS"
  | "IBC"
  | "MPAK"
  | "PAY"
  | "PNM"
  | "PPAL"
  | "SLP"
  | "SLN"
  | "VIWD"
  | "CFee"
  | "Coll"
  | "CNTW"
  | "RPPS"
  | "WTRN"
  | "OPM"
  | "MZM";

export type PawsOnlyPayments =
  | "mastercard"
  | "visa"
  | "paypal"
  | "paynearme"
  | string;

export type PaymentTypeOption = {
  id: string;
  paymentType: PaymentType;
  title: string;
  description: string;
  expirationDate?: string | null;
  lastUsedDateTime?: string | null;
  depositsAvailable: boolean;
  withdrawalsAvailable: boolean;
  minLimit: number;
  maxLimit: number;
  paymentTypeAvailable?: boolean;
};

export type PaymentTypeItem = {
  paymentType: PaymentType;
  depositsEnabled: boolean;
  depositsAvailable?: boolean;
  mainList: boolean;
  paymentTypeAvailable?: boolean;
  paymentTypeOptions: PaymentTypeOption[];
  priority: number;
  withdrawalsEnabled: boolean;
  withdrawalsAvailable?: boolean;
  minLimit?: number;
  maxLimit?: number;
};

export type PaymentTypeInfo = {
  title: string;
  description: string;
  limit?: number;
};

export type OnFileEmptyContainer = {
  title: string;
  description: string;
};

export type AvailablePaymentMethodsInfos = {
  [key in PaymentType]: PaymentTypeInfo;
};

export type FetchAvailablePaymentMethodsArgs = {
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  accountNumber: string;
  availablePaymentMethodsInfos: AvailablePaymentMethodsInfos;
  setOtherMethods?: React.Dispatch<React.SetStateAction<PaymentTypeItem[]>>;
  setMainMethods?: React.Dispatch<React.SetStateAction<PaymentTypeItem[]>>;
  dispatch: Dispatch;
  isQuickDeposit?: boolean;
};

export type ButtonType =
  | "primary"
  | "secondary"
  | "secondary_alt"
  | "tertiary"
  | "tertiary_alt"
  | "marketing"
  | "delete"
  | "shadow"
  | "non_styled";

export type ButtonAction = {
  text: string;
  onClick: () => void;
  isStretched?: boolean;
  isUppercase?: boolean;
  type?: ButtonType;
  qaLabel?: string;
  order?: number;
};

export type CreatePMToggles = {
  [key in PaymentType]?: boolean;
};

export type CreateModalProps = {
  activeCreatePM: PaymentType;
  isCreateModalOpen: boolean;
};

export type OnNewMethodArgs = {
  paymentType: PaymentType;
  toggleOffHandler: Function;
  checkLimit: () => boolean;
};

export type FormData = {
  bankAccountNumber: string;
  bankRoutingNumber: string;
  bankName: string;
  dlNumber: string;
  dlState: string;
};

export type PawsContent = {
  depositFundsModal: { title: string };
  transactionDepositSuccessMessage: {
    title: string;
    description1: string;
    description2: string;
  };
  transactionDepositFundsHoldSuccessMessage: {
    title: string;
    description1: string;
    description2: string;
  };
  paymentMessageModal: {
    error: {
      title: string;
      description: string;
    };
  };
  depositFeeModal: {
    title: string;
    description: string;
    buttonText: string;
  };
  depositMzmFeeModal: {
    title: string;
    description: string;
    buttonText: string;
  };
  deposit: {
    CC: {
      cvvEmpty: string;
      cvvInvalid: string;
      ccInfo: {
        title: string;
        description: string;
        buttonText: string;
      };
      feeInfo: {
        title: string;
        description: string;
        buttonText: string;
      };
    };
    MPAK: {
      locationText: string;
      locationLink: string;
      limitsButton: string;
      greenDotPolicyMessage: string;
      depositLimits: {
        title: string;
        description: string;
        limits: [
          {
            time: string;
            deposits: string;
            amount: string;
          }
        ];
        warning: string;
        buttonText: string;
      };
      descriptionSteps: string[];
    };
    PNM: {
      description: string;
      descriptionSteps: string[];
      faqLinkText: string;
      faqModalTitle: string;
      buttonText: string;
    };
  };
  createCreditDebitCardSuccessMessage: {
    title: string;
    description1: string;
    description2: string;
  };
  createCreditDebitCardCreation: CreditDebitCardCreation;
  genericErrorMessage: {
    title: string;
    description: string;
    retryBtn: string;
    contactBtn: string;
  };
  noPaymentMethodModal: {
    title: string;
    description: string;
  };
  deleteCardModal: {
    title: string;
    description: string;
    confirmText: string;
    cancelText: string;
  };
  deleteCardModalSuccess: {
    message: string;
  };
  deleteCardModalError: {
    title: string;
    description: string;
    retryText: string;
    contactText: string;
  };
  paymentMethodUnderMaintenance: {
    message: string;
  };
};

export type AccountNumber = string;
export type FundingSources = Array<{ [key in string]: string }>;
export type PaypalActions = {
  disable: NullaryFn<void>;
  enable: NullaryFn<void>;
};

export type ErrorMessage = {
  isOpen: boolean;
  errorCode: string;
  type: "error" | "success" | "warning" | "info";
  title: string;
  description: string;
  actions: ButtonAction[];
  isRetry: boolean;
  retryCount: number;
  paypalRetryMethod?: NullaryFn<void>;
};

export interface MzmTimeoutMessage {
  default: {
    actions: string[];
    description: string;
    title: string;
    type: MessagePanelVariants;
  };
}

export interface MzmOverlayMessage {
  default: {
    text: string;
  };
  timeout: {
    text: string;
  };
  permissions: {
    text: string;
    action: string;
  };
}

export type Address = {
  streetNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  type: AddressSelection;
};

export type AddressSelection = "RESIDENTIAL" | "MAILING";

export type AddressConfirmationMessage = {
  title: string;
  description: string;
  type: MessagePanelVariants;
  actions: string[];
  customerService: string;
};

export type MessagePanelVariants = "success" | "error" | "warning" | "info";

export type FundsAmountInputChangingFrom =
  | "moneyPills"
  | "desktop"
  | "mobile"
  | "mobileAmountField"
  | "desktopAmountField";

enum AccountRolesEnum {
  USER = "USER",
  TALENT = "TALENT",
  WAGEROPS = "WAGEROPS",
  ADMIN = "ADMIN"
}

export type UserInfo = {
  accountNumber: string;
  emailAddress: string;
  firstName: string;
  homeAddress: Address;
  lastName: string;
  mailingAddress: Address;
  primaryPhone: string;
  profile: string;
  signalProviderId: string;
  status: string;
  transportId: string;
  userName: string;
  wagerStatus: number;
  accountRoles: Array<AccountRolesEnum>;
};

export interface Withdrawal {
  id: string;
  date: string;
  type: string;
  amount: number;
  onCancel: NullaryFn<void>;
}

export interface WithdrawCancelInformation {
  id: string;
  date: string;
  type: string;
  amount: number;
  isLoading: boolean;
  onCancelClose: NullaryFn<void>;
  onCancelWithdraw: NullaryFn<void>;
  hasError: boolean;
  errorMessage: string;
}

export interface WithdrawalsInfo {
  isCancelModalOpen: boolean;
  closeCancelWithdrawal: boolean;
  showPendingWithdrawalsV2: boolean;
  withdrawals: Withdrawal[];
}

export interface DesktopNavigationProps {
  event: React.MouseEvent<HTMLLIElement, MouseEvent>;
  paymentType: PaymentType;
  navigationType?: "deposit" | "withdraw";
  paymentId?: string;
  newPayment?: boolean;
  paymentTypeOptions?: PaymentTypeOption[] | null;
  dispatch?: Dispatch<ActionCreatorWithoutPayload<string>>;
  history?: {
    push: (a: string) => void;
  };
}

export interface SimpleErrorMessage {
  description: string;
  title: string;
  type: "success" | "error" | "warning" | "info";
  actions: ButtonAction[];
}

export type IsOtherPaymentMethodsModalOpen = boolean;

export interface RedirectOnFeeFreeArgs {
  dispatch: UnaryFn<
    PayloadAction<
      | PaymentTypeOption[]
      | string
      | DepositsPayload
      | SelectedPaymentMethodPayload
      | undefined
    >,
    void
  >;
  paymentMethod?: PaymentTypeOption;
  isQuickDeposit?: boolean;
  isDesktop?: boolean;
  history: History;
  setIsFeeInfoOpen?: React.Dispatch<SetStateAction<boolean>>;
  isMZMToggleOn: boolean;
  mainMethods: PaymentTypeItem[];
}

export interface PendingWithdrawToggle {
  isOpen: boolean;
}

export interface PendingWithdrawCancelContent {
  title: string;
}

export interface PendingWithdrawContent {
  cancelModalContent: PendingWithdrawCancelContent;
}

export interface PendingWithdrawVisibilityToggle {
  isOpen: boolean;
}
export interface Tag {
  type: string;
  color: string;
  text: string;
}
export interface PaymentTypeTag {
  paymentType: PaymentType;
  tags: Tag[];
}

export interface TagMapper {
  grey: "info";
  green: "optedin";
  yellow: "promo";
  orange: "warning";
  blue: "highlight";
}

export interface CreditDebitCardCreation {
  title: string;
  amountInput: {
    validations: {
      warningMinLimit: string;
      warningMaxLimit: string;
    };
  };
  cardNumber: {
    validations: {
      required: string;
      invalid: string;
    };
  };
  cvv: {
    validations: {
      required: string;
      invalid: string;
    };
  };
  expirationDateCard: {
    validations: {
      required: string;
      invalidMonth: string;
      invalidYear: string;
      invalidDate: string;
    };
  };
}

interface Error {
  [key: string | number]: string | string[];
}
interface Errors {
  [key: string | number]: Error;
}

interface CardDeclinedContent {
  [key: string]: Record<string, string | unknown>;
}

export interface CardDeclined {
  validErrorCodes: Array<string | number>;
  title: string;
  description: string;
  errors: Errors;
  content: CardDeclinedContent;
}

export interface ActiveCreatePm {
  activeCreatePM: PaymentType;
  isCreateModalOpen: boolean;
}

export interface MainMethods {
  mainMethods: PaymentTypeItem[];
}

interface Limits {
  withdrawalLimit: {
    max: number;
    min: number;
  };
  deposit: {
    max: number;
    min: number;
  };
}

export interface PawsLimits {
  [key: string]: Limits;
}

export interface FundsModalContent {
  isModalOpen: boolean;
  isMoneyPakModalOpen: boolean;
  isOtherPaymentDepositModalOpen: boolean;
  otherDepositsPaymentType: PaymentTypeEnum;
  isPayNearMeModalOpen: boolean;
  formData: FormData;
  fromCreation: boolean;
  onBack: unknown;
  isRedirectFromMZM: boolean;
}

export enum FundsModalFields {
  VALUE = "field-value",
  CVV = "field-cvv",
  CODE = "field-code"
}
