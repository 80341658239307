import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Store = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M2.71301 8.59024L4.01412 2.62555H19.9859L21.287 8.59024C21.6573 10.5681
      20.8475 11.7774 19.8363 12.0697C18.4347 12.4748 15.3822 12.3755 15.3822
      9.31671H14.9849C14.9849 10.3736 14.693 12.3755 12.0297
      12.3755H11.9703C9.30696 12.3755 9.0025 10.3736 9.0025
      9.31671H8.61782C8.61782 12.3755 5.56532 12.4748 4.16368 12.0697C3.15253
      11.7774 2.34266 10.5681 2.71301 8.59024Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M4.125 16.1245V21.3745H19.875V16.1245M4.01412 2.62555L2.71301
      8.59024C2.34266 10.5681 3.15253 11.7774 4.16368 12.0697C5.56532 12.4748
      8.61782 12.3755 8.61782 9.31671H9.0025C9.0025 10.3736 9.30696 12.3755
      11.9703 12.3755H12.0297C14.693 12.3755 14.9849 10.3736 14.9849
      9.31671H15.3822C15.3822 12.3755 18.4347 12.4748 19.8363 12.0697C20.8475
      11.7774 21.6573 10.5681 21.287 8.59024L19.9859 2.62555H4.01412Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
export default Store;
