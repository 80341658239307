import { useEffect, useRef } from "react";
import { debounce } from "lodash";
import type { UnaryFn } from "@tvg/ts-types/Functional";
import { usePrevious } from "./usePrevious";

const useDebouncedStringDiff = (
  currentValue: string,
  callback: UnaryFn<string, void>,
  hasPreloadedInputs: boolean
): void => {
  const previousInputValue: string | undefined = usePrevious(currentValue);
  const throttled = useRef(debounce((val) => callback(val), 750));

  useEffect(() => {
    if (
      hasPreloadedInputs &&
      typeof callback === "function" &&
      typeof currentValue === "string" &&
      typeof previousInputValue === "string" &&
      currentValue.toLowerCase() !== previousInputValue.toLowerCase()
    ) {
      throttled.current(currentValue);
    }
  }, [previousInputValue, currentValue, hasPreloadedInputs]);
};

export default useDebouncedStringDiff;
