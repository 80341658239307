export const otherPaymentsContent = {
  title: "Other Payment Methods",
  tabs: [
    {
      method: "CASH",
      title: "Cash Deposit",
      content: [
        {
          type: "text",
          content: "Cash Deposits may be made at TVG Deposits Centers."
        },
        {
          type: "text",
          content: "TVG Deposit Centers are located at the following locations:"
        },
        {
          type: "list",
          content: [
            "Keeneland, Lexington, KY",
            "Suffolk Downs, East Boston, MA",
            "Fairplex Park, Pomona, CA",
            "Los Alamitos Race course, Los Alamitos, CA",
            "Fairmount Park, Collinsville, IL",
            "Emerald Downs, Seattle, WA"
          ]
        },
        { type: "separator" },
        {
          type: "support",
          content: "For the operation hours of a Deposit Center in your area",
          linkText: "message our customer service"
        },
        {
          type: "text",
          content: "There is no maximum deposit limit when depositing cash."
        }
      ]
    },
    {
      method: "CHK",
      title: "Check or Money Order",
      content: [
        { type: "alert", content: "Remember, never send cash in the mail!" },
        {
          type: "text",
          content:
            "Check and Money Orders can be made payable to “TVG”. Please include your TVG Account number in the memo line when mailing to:"
        },
        { type: "text", content: "TVG \nPO. Box 22113 \nTusla, OK 74121" },
        { type: "separator" },
        {
          type: "text",
          content:
            "There is no maximum deposit limit. \nOvernight shipping is not accepted."
        },
        {
          type: "text",
          content: "Please allow 7-10 days for mail and processing time."
        }
      ]
    },
    {
      method: "WTRN",
      title: "Wire Transfers",
      content: [
        {
          type: "text",
          content:
            "TVG customers can transfer funds directly between your account and your TVG account via wire transfers. Wired funds that are received by TVG no later than 2:00pm PDT Mon-Fri will be available same day."
        },
        {
          type: "text",
          content:
            "Wires requested after this time will be available in your bank account the next business day"
        },
        {
          type: "text",
          content:
            "To make a wire transfer deposit or wire transfer withdrawal complete and submit the appropriate form as instructed. Please be aware that the minimum deposit amount you can make via wire transfer is $2,000."
        },
        {
          type: "text",
          content:
            "Please keep in mind that your bank may charge fees for sending and receiving wire transfers."
        },
        {
          type: "text",
          content:
            "These fees vary from bank to bank, so please verify the amount with your bank"
        },
        {
          type: "link",
          content: [
            {
              text: "Wire Transfer Deposit",
              url: "https://storage.googleapis.com/tvg-static-staging/static/cms_files/WireTransferDeposit.pdf"
            },
            {
              text: "Wire Transfer Withdrawal",
              url: "https://storage.googleapis.com/tvg-static-staging/static/cms_files/WireTransferWithdrawal.pdf"
            }
          ]
        }
      ]
    }
  ]
};

export const pawsContent = {
  moneyPills: [50, 100, 200, 300],
  paymentMethodSelectorModal: {
    title: "Select Payment Method",
    buttonText: "Add Payment Method"
  },
  availablePaymentMethodList: {
    title: "Deposit with a new payment method"
  },
  otherPaymentMethodsModal: {
    title: "Deposit with other payment methods"
  },
  accountsOnFile: {
    title: "Accounts on file",
    emptyTitle: "No saved payment methods",
    emptyDescription:
      "TVG offers you multiple ways to deposit \n pick one of them below."
  },
  paymentMessageModal: {
    cardLimit: {
      title: "5 Card limit reached",
      description:
        "You reached your 5 card limit, please remove one and try again or try another payment method.",
      primaryButton: "Got it"
    },
    error: {
      title: "Unknown Error",
      description:
        "Oops, something went wrong while validating your bank information. Please try again.",
      primaryButton: "Retry"
    }
  },
  createCreditDebitCardSuccessMessage: {
    title: "You are off to the races!",
    description1: "Card was successfully added to your account",
    description2:
      "\n You added ~added~ to your wallet.<nobr/>Your current balance is now ~balance~."
  },
  transactionDepositSuccessMessage: {
    title: "You are off to the races!",
    description1: "You added ~added~ to your wallet.",
    description2: "Your current balance is now ~balance~"
  },
  transactionDepositFundsHoldSuccessMessage: {
    title: "You are held at the gate!",
    description1: "Funds are still not available for wagering",
    description2:
      "Please note it will take 2-5 business days to clear your checking account."
  },
  depositFundsModal: {
    title: "Deposit Funds",
    warningMinAmount: "~amount~ MINIMUM DEPOSIT",
    warningMaxAmount: "~amount~ MAXIMUM DEPOSIT",
    warningCvvInput: "Insert 3 digit CVV found on your card",
    warningCvvInputRequired: "CVV is required"
  },
  transactionWithdrawSuccessMessage: {
    title: "Money on the way",
    description1:
      "Your withdrawal of ~removed~ has been successful. Allow 24 hours for this withdrawal to be processed.",
    description2: "\n"
  },
  MZMWithdrawSuccessMessage: {
    title: "Money on the way",
    description1:
      "Your withdrawal of ~removed~ has been successful. Allow 24 hours for this withdrawal to be processed.",
    description2:
      "\n Please note it will take <strong>2-5 business days</strong> to clear your checking account."
  },
  ACHWithdrawSuccessMessage: {
    title: "Money on the way",
    description1:
      "Your withdrawal of ~removed~ has been successful. Allow 24 hours for this withdrawal to be processed.",
    description2:
      "\n Please note it will take <strong>2-5 business days</strong> to clear your checking account."
  },
  CHKWithdrawSuccessMessage: {
    title: "Money on the way",
    description1:
      "Your withdrawal of ~removed~ has been successful. Allow 24 hours for this withdrawal to be processed.",
    description2:
      "\n Check requests are processed the following business day and mailed USPS first class. While USPS list 1 - 3 business days as their delivery time frame, please allow up to 10 business days to receive your check."
  },
  withdrawFundsModal: {
    title: "Withdraw Funds",
    description: "WITHDRAWABLE BALANCE ~withdrawableBalance~"
  },
  depositFeeModal: {
    title: "Want to deposit fee free?",
    description:
      "If you want to avoid fees, TVG has the right solution for you. Set up your eCheck today and start enjoying a fee free horse racing experience",
    buttonText: "Set up eCheck"
  },
  depositCvvModal: {
    title: "CVV code",
    description:
      "The 3-digit security code usually found on the back of your card.",
    buttonText: "Got It"
  },
  withdrawalBalanceModal: {
    balanceTitle: "Your Withdrawable Balance",
    noBalanceTitle: "No Withdrawable Balance",
    wBalanceDescription:
      "You currently have ~wBalance~ in the withdrawable balance. These are funds that can be withdrawn anytime.",
    pBalanceDescription:
      "Your playable balance is ~pBalance~. These funds can be used to bet on TVG anytime, but they can't be withdrawn without help from out Support team.",
    noBalanceDescription:
      "You don't currently have a withdrawable balance. These are funds that can be withdrawn anytime.",
    okBtnText: "Got it",
    supportBtnText: "Contact Support for a refund"
  },
  noPaymentMethodModal: {
    title: "No payment methods added",
    description:
      "Deposit with eCheck, Visa, MasterCard and many other payment methods",
    okText: "Got it",
    contactSupportText: "Contact Customer Service"
  },
  addPaymentMethodModal: {
    title: "No payment methods added",
    description:
      "Deposit with eCheck, Visa, MasterCard and many other payment methods",
    contactSupportText: "Contact Customer Service",
    buttonText: "Add Payment Method"
  },
  depositWithMethodModal: {
    title: "Deposit with"
  },
  deposit: {
    ACH: {
      disabledMessage: "Deposit is not available at the moment",
      disabledCardOnFileMessage: "No cards can be added at this time"
    },
    CC: {
      disabledMessage: "Deposit is not available at the moment",
      disabledCardOnFileMessage: "No cards can be added at this time",
      cvvEmpty: "CVV is required",
      cvvInvalid: "Insert 3 digit CVV found on your card",
      ccInfo: {
        title: "CVV code",
        description:
          "The 3-digit security code usually found on the back of your card.",
        buttonText: "Got it"
      },
      feeInfo: {
        title: "Want to deposit fee free?",
        description:
          "If you want to avoid fees, TVG has the right solution for you. Set up your eCheck today and start enjoying a fee free horse racing experience",
        buttonText: "Set up eCheck"
      }
    },
    MPAK: {
      disabledMessage: "Deposit is not available at the moment",
      disabledCardOnFileMessage: "No cards can be added at this time",
      locationText: "Find your closest MoneyPak location.",
      locationLink: "https://secure.attheregister.com/locations",
      limitsButton: "DEPOSIT LIMITS",
      greenDotPolicyMessage: "",
      depositLimits: {
        title: "Deposit Limits",
        description:
          "TVG customers are limited to the following number of MoneyPaks they can use to deposit funds and the following amounts.",
        limits: [
          {
            time: "Time",
            deposits: "# of deposits",
            amount: "Amount"
          },
          {
            time: "Rolling 24 hours",
            deposits: "4",
            amount: "$1,500"
          },
          {
            time: "Rolling 7 days",
            deposits: "7",
            amount: "$3,500"
          },
          {
            time: "Rolling 30 days",
            deposits: "20",
            amount: "$5,000"
          }
        ],
        warning:
          "Your remaining deposit is ~remainingValue~ failure to comply will result in account suspension",
        buttonText: "Got it"
      },
      descriptionSteps: [
        "1 - ENTER THE NUMBER ON THE BACK OF YOUR MONEYPAK.",
        "2 - AUTHORIZE YOUR DEPOSIT AND THE FUNDS WILL BE APPLIED"
      ]
    },
    PNM: {
      description: "Fund your account with a cash deposit at:",
      descriptionSteps: [
        "Tap below to get your PayNearMe PayCode.",
        "For more information, check out our ~faqLinkText~."
      ],
      faqLinkText: "PayNearMe FAQ",
      faqModalTitle: "PayNearMe FAQ",
      buttonText: "Get PayNearMe PayCode"
    }
  },
  depositMzmFeeModal: {
    title: "Set up Online Banking",
    description:
      "If you want to avoid fees, TVG has the right solution for you. Set up your Online Banking today and start enjoying a fee free horse racing experience",
    buttonText: "Want to deposit fee free?"
  },
  genericErrorMessage: {
    title: "Unknown Error",
    description:
      "Oops, something went wrong while validating your bank information. Please try again",
    retryBtn: "Retry",
    contactBtn: "Contact Customer Support"
  },
  deleteCardModal: {
    title: "Delete ~type~",
    description:
      "You are about to delete ~accountType~ ~accountDescription~, this action cannot be reverted.",
    confirmText: "Delete ~accountType~ ~accountDescription~",
    cancelText: "Cancel"
  },
  deleteCardModalSuccess: {
    message: "~type~ deleted"
  },
  deleteCardModalError: {
    title: "There was an error deleting your ~type~",
    description:
      "There was an error trying to delete your ~type~, please try again or contact customer service.",
    retryText: "Retry",
    contactText: "Contact Costumer Service"
  },
  wireTransferWithdrawContent: {
    form: {
      alert: {
        message:
          "Please ensure the information is accurate as incorrect information will delay your withdrawal",
        type: "informational"
      },
      routingNumber: {
        placeholder: "Wire Routing number (9 digits)",
        label: "Wire Routing number",
        validations: {
          invalidNumber: "This Wire Routing Number is not valid",
          invalidDigits: "Invalid Wire Routing Number, please use 9 digits",
          required: "Wire Routing Number is required"
        },
        tooltip:
          "This may not be the same as your ACH routing number, please contact your bank to confirm"
      },
      checkingAccountNumber: {
        placeholder: "Checking account number (6 - 17 digits)",
        label: "Checking account number",
        validations: {
          invalidDigits:
            "Invalid Checking Account Number, please use 6 - 17 digits",
          required: "Checking Account Number is required"
        }
      },
      nameOnAccount: {
        placeholder: "Name on account",
        label: "Name on account",
        validations: {
          required: "Name on Account is required"
        }
      },
      button: {
        variant: "primary",
        text: "Next Step"
      }
    },
    termsAndConditions: {
      title: "Terms and Conditions",
      content: [
        "By completing this form, you are authorizing TVG to remove funds from your TVG account, noted above, and wire said funds to the above bank account.",
        "Your bank may also charge you fees for sending and receiving wire transfers. These fees vary from bank to bank, so please verify the amount with your bank.",
        "To have your wire transfer processed on the same day, the request must\tbe received by TVG prior to 2:30pm Pacific Time Monday through Friday. If your request is received after that time it will be processed the next business day."
      ],
      cta: "By selecting add as payment method I accept",
      ctaClickable: "terms & conditions"
    },
    confirmation: {
      authorize: {
        text: "Authorize ~amount~ Withdrawal",
        variant: "betting"
      },
      cancel: {
        text: "Cancel",
        variant: "secondary"
      }
    }
  }
};

export const mzmTimeoutContent = {
  default: {
    type: "info",
    title: "Processing transaction...",
    description:
      "Your transaction is still in progress. keep an eye on your account summary page to check the transaction status.",
    actions: ["close"]
  }
};

export const mzmTimeoutLimit = 20000;

export const pendingWithdrawContent = {
  cancelModalContent: {
    title: "Pending Withdrawals"
  }
};

export const pawsAddressMessages = {
  CHK: {
    type: "info",
    title: "Confirm withdrawal address",
    description:
      "Please choose from your addresses on file where you wish TVG to send this check.",
    actions: ["confirm", "cancel"],
    customerService: "No longer your address?"
  },
  CCP: {
    type: "info",
    title: "Confirm Billing Address",
    description:
      "Cards must be in your name and the billing address must match your address on file.",
    actions: ["confirm", "cancel"],
    customerService: "No longer your address?"
  },
  default: {
    type: "info",
    title: "Confirm withdrawal address",
    description:
      "Please choose from your addresses on file where you wish TVG to send this check.",
    actions: ["confirm", "cancel"],
    customerService: "No longer your address?"
  }
};

export const creditDebitCardCreation = {
  title: "Deposit with new card",
  amountInput: {
    validations: {
      warningMinLimit: "~amount~ MINIMUM DEPOSIT",
      warningMaxLimit: "~amount~ MAXIMUM DEPOSIT"
    }
  },
  cardNumber: {
    validations: {
      required: "Debit or Credit Number is required",
      invalid: "Invalid Debit or Credit Number, please use 16 digits"
    }
  },
  cvv: {
    validations: {
      required: "CVV is required",
      invalid: "Insert 3 digit CVV found on your card"
    }
  },
  expirationDateCard: {
    validations: {
      required: "Month and Year are required",
      invalidMonth: "Invalid Month",
      invalidYear: "Invalid Year",
      invalidDate: "Invalid Date"
    }
  }
};
