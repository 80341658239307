import { createSlice } from "@reduxjs/toolkit";

export type State = {
  isModalOpen: boolean;
};

export const initialState: State = {
  isModalOpen: false
};

const otherPaymentMethodsSlice = createSlice({
  name: "paws",
  initialState,
  reducers: {
    openOtherPaymentMethodsModal(state) {
      state.isModalOpen = true;
    },
    closeOtherPaymentMethodsModal(state) {
      state.isModalOpen = false;
    }
  }
});

export const { openOtherPaymentMethodsModal, closeOtherPaymentMethodsModal } =
  otherPaymentMethodsSlice.actions;

export default otherPaymentMethodsSlice.reducer;
