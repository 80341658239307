import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const OptedIn = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M12.6518 2.62793H19.8728C20.7012 2.62793 21.3728 3.2995 21.3728
      4.12793V11.3489C21.3728 11.7664 21.1988 12.165 20.8927 12.4488L11.6956
      20.9766C11.1042 21.5249 10.1852 21.5076 9.61501 20.9374L3.06336
      14.3857C2.49314 13.8155 2.4758 12.8965 3.02409 12.3051L11.5519
      3.10805C11.8358 2.80192 12.2343 2.62793 12.6518 2.62793Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M9 10.5L12 13.5L18 7.5M19.8728 2.62793H12.6518C12.2343 2.62793 11.8358
      2.80192 11.5519 3.10805L3.02409 12.3051C2.4758 12.8965 2.49314 13.8155
      3.06336 14.3857L9.61501 20.9374C10.1852 21.5076 11.1042 21.5249 11.6956
      20.9766L20.8927 12.4488C21.1988 12.165 21.3728 11.7664 21.3728
      11.3489V4.12793C21.3728 3.2995 20.7012 2.62793 19.8728 2.62793Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
