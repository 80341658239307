import styled, { css } from "styled-components";
import { space, layout, compose } from "styled-system";
import { Theme } from "@tvg/design-system";

const styledUtils = compose(space, layout);

export const Container = styled.div<{
  theme: Theme;
}>`
  overflow: hidden;
  ${styledUtils};
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  ${({ theme }) => css`
    background-color: ${theme.colorTokens.background.layer};
    border-bottom-color: ${theme.colorTokens.border.default};
  `};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-4"]}`};
`;

export const VerticalParagraphLeft = styled.div<{ isCanceled?: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: left;
  ${({ isCanceled }) =>
    isCanceled &&
    css`
      text-decoration: line-through;
      text-decoration-color: ${({ theme }) =>
        theme.colorTokens.content.disabled};
    `}
`;

export const VerticalParagraphRight = styled.div`
  text-align: right;
`;

export const HideShowContainer = styled.button`
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  ${({ theme }) => css`
    background-color: ${theme.colorTokens.component.button.buttonLink.background
      .base};
  `};
`;
