import React, { useEffect, useState, useRef, CSSProperties, FC } from "react";
import type { SegmentedControlProps, SCTabSelectedProps } from "./types";
import { StyledComponent } from "./styled-components";
import { SegmentedControlPill, SegmentedControlTab } from "./components";
import { SCContext } from "./context";

const SegmentedControl: FC<SegmentedControlProps> = ({
  style,
  selectedValue,
  size,
  isDisabled = false,
  children,
  qaLabel,
  ...rest
}: SegmentedControlProps): JSX.Element => {
  const [segmentWidth, setSegmentWidth] = useState(0);
  const [tabsNbr, setTabsNbr] = useState(
    React.Children.toArray(children).length
  );
  const [selectedTab, setSelectedTab] = useState<SCTabSelectedProps>({
    index: 0,
    children: ""
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isDisabled) {
      let selectedTabChildren;
      let selectedTabEnd;
      const childrenElements = React.Children.toArray(children);
      const selectedTabIndex = childrenElements.findIndex((elem) => {
        if (
          React.isValidElement<{
            value: string;
            children: string | JSX.Element;
            end?: JSX.Element;
          }>(elem) &&
          elem.props.value === selectedValue
        ) {
          selectedTabChildren = elem.props.children;
          selectedTabEnd = elem.props.end;
          return true;
        }
        return false;
      });

      if (selectedTabIndex !== -1) {
        setSelectedTab({
          index: selectedTabIndex,
          children: selectedTabChildren || "",
          end: selectedTabEnd
        });
      }

      if (childrenElements.length !== tabsNbr) {
        setTabsNbr(childrenElements.length);
      }
    }
  }, [children, selectedValue]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const newSegmentWidth = tabsNbr ? width / tabsNbr : 0;
        if (newSegmentWidth !== segmentWidth) {
          setSegmentWidth(newSegmentWidth);
        }
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [tabsNbr, segmentWidth]);

  return (
    <StyledComponent
      data-qa-label={qaLabel}
      role="tablist"
      style={style as CSSProperties}
      size={size}
      isDisabled={isDisabled}
      ref={containerRef}
      {...rest}
    >
      <SCContext.Provider value={{ selectedValue, isDisabled }}>
        {children}
        <SegmentedControlPill
          segmentWidth={segmentWidth}
          selectedIndex={selectedTab.index}
          qaLabel={`${qaLabel}-pill`}
          isDisabled={isDisabled}
        />
      </SCContext.Provider>
    </StyledComponent>
  );
};

export { SegmentedControl, SegmentedControlTab };
export type { SegmentedControlProps };
