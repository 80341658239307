import { useEffect, useCallback } from "react";
import { Store } from "redux";
import { get, debounce } from "lodash";
import { useSelector } from "react-redux";
import formatCurrency from "@tvg/formatter/currency";
import {
  getDepositFundsModal,
  getSelectedPaymentMethod,
  getPawsContent,
  getPawsLimits
} from "@tvg/sh-lib-paws/redux/selectors";
import pawsDepositService from "@tvg/api/paws/deposit";
import {
  AccountNumber,
  NullaryFn,
  UnaryFn,
  ErrorMessage,
  PaymentTypeOption,
  PawsContent,
  FundsModalFields
} from "@tvg/wallet/src/types";
import { gtmAuthorizeDepositValidation } from "@tvg/wallet/src/gtm";
import { PaymentType } from "@tvg/ts-types/Payment";
import { PaymentTypesAvailable } from "@tvg/api/paws/types";
import { getAccountNumber } from "@urp/store-selectors";

export const useCommonDeposit = (
  value: string,
  cvv: string,
  setCVVError: UnaryFn<string, void>,
  errorMessage: ErrorMessage,
  onSubmitDeposit: NullaryFn<void>,
  showAmountWarning: boolean,
  setShowAmountWarning: UnaryFn<boolean, void>,
  setFeeValue: UnaryFn<string, void>,
  selectedField: string,
  isQuickDeposit: boolean | undefined = false,
  isCVVDisabled: boolean,
  setSelectedField: React.Dispatch<React.SetStateAction<FundsModalFields>>,
  setFeeCalculating: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // *************** SELECTORS ****************
  const accountId: AccountNumber = useSelector(getAccountNumber);

  const { id: selectedPaymentMethodId, paymentType }: PaymentTypeOption =
    useSelector(getSelectedPaymentMethod);

  let { minLimit = 0, maxLimit = 10000000 }: PaymentTypeOption = useSelector(
    getSelectedPaymentMethod
  );

  const limits = useSelector(getPawsLimits);

  minLimit =
    get(limits, `${paymentType}.deposit.min`, 0) > minLimit
      ? get(limits, `${paymentType}.deposit.min`, 0)
      : minLimit;

  maxLimit =
    get(limits, `${paymentType}.deposit.max`, 10000000) < maxLimit
      ? get(limits, `${paymentType}.deposit.max`, 10000000)
      : maxLimit;

  const content = useSelector<Store, PawsContent>(getPawsContent);

  const {
    fromCreation
  }: {
    fromCreation: boolean;
  } = useSelector(getDepositFundsModal);

  // *************** USE CALLBACKS ****************
  const debouncedFee = useCallback(
    debounce(async (val: string) => {
      try {
        if (val) {
          const {
            data: { feeTotalAmount }
          } = await pawsDepositService.getDepositFee(
            paymentType as PaymentTypesAvailable,
            {
              accountId,
              depositAmount: Number(val).toFixed(2)
            }
          );
          setFeeValue(feeTotalAmount as string);
        }
      } catch (err) {
        setFeeValue("");
      } finally {
        setFeeCalculating(false);
      }
    }, 2000),
    [paymentType]
  );

  // *************** USE EFFECTS ****************
  useEffect(() => {
    setCVVError("");

    const hasError = cvv.length !== 3;
    if (selectedField !== "field-cvv" || !hasError) return () => null;

    const timer = hasError ? 2000 : 0;
    const debounceCVVWarning = setTimeout(
      () =>
        setCVVError(
          cvv.length > 0
            ? content.deposit.CC.cvvInvalid
            : content.deposit.CC.cvvEmpty
        ),
      timer
    );

    return () => clearTimeout(debounceCVVWarning);
  }, [cvv]);

  useEffect(() => {
    if (errorMessage.isRetry) {
      if (
        typeof errorMessage.paypalRetryMethod === "function" &&
        paymentType === "PAY"
      ) {
        errorMessage.paypalRetryMethod();
      } else {
        onSubmitDeposit();
      }
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!showAmountWarning) return;

    const warningMessage =
      +value > +maxLimit
        ? `${formatCurrency(maxLimit)} MAXIMUM DEPOSIT`
        : `${formatCurrency(minLimit)} MINIMUM DEPOSIT`;

    gtmAuthorizeDepositValidation(
      fromCreation,
      paymentType,
      accountId,
      warningMessage
    );
  }, [showAmountWarning]);

  useEffect(() => {
    const validation = (+value > +maxLimit || +value < +minLimit) && !!value;
    const timer = validation ? 3000 : 0;
    const debounceWarning = setTimeout(
      () => setShowAmountWarning(validation),
      timer
    );

    if (!validation) {
      setFeeCalculating(true);
      debouncedFee(value);
    } else {
      debouncedFee.cancel();
    }

    if (!value) {
      setFeeValue("");
    }

    return () => clearTimeout(debounceWarning);
  }, [value, selectedPaymentMethodId]);

  useEffect(() => {
    if (isQuickDeposit) {
      if (
        paymentType === PaymentType.CREDIT_DEBIT_CARD &&
        value &&
        !isCVVDisabled
      ) {
        setSelectedField(FundsModalFields.CVV);
      } else {
        setSelectedField(FundsModalFields.VALUE);
      }
    }
  }, [isQuickDeposit, selectedPaymentMethodId]);
};
