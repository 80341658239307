// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  overflow-x: hidden;
  width: 100%;
  background-color: ${color("white", "100")};
  padding: 18px 0 18px 12px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
  margin: 0 -6px;
`;

const Pill = styled.div`
  width: auto;
  flex-grow: 1;
  border: 1px solid ${color("grey", "100")};
  border-radius: 4px;
  height: 54px;
  margin: 0 6px;
`;

export default class DepositLimitsMask extends PureComponent<*> {
  render() {
    return (
      <Container>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="330"
          height="12"
          viewBox="0 0 330 12"
        >
          <g fill="none" fillRule="evenodd">
            <rect width="330" height="12" className="pulse" />
          </g>
        </SVG>
        <Content>
          <Pill />
          <Pill />
          <Pill />
        </Content>
      </Container>
    );
  }
}
