import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const File = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M4.125 4.125V19.875C4.125 20.7034 4.79657 21.375 5.625
      21.375H18.375C19.2034 21.375 19.875 20.7034 19.875
      19.875V7.875H14.625V2.625H5.625C4.79657 2.625 4.125 3.29657 4.125 4.125Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M14.625 2.70509V7.875H19.7969M14.625 2.625H5.625C4.79657 2.625 4.125
      3.29657 4.125 4.125L4.125 19.875C4.125 20.7034 4.79657 21.375 5.625
      21.375H18.375C19.2034 21.375 19.875 20.7034 19.875 19.875V7.875L14.625
      2.625Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
