// @flow
import styled, { css } from "styled-components";

import {
  SlideFloatingBottomTransition,
  SlideBottomTransition,
  SlideTopTransition,
  SlideLeftTransition,
  SlideRightTransition,
  FadeTransition
} from "../../_static/Transitions";

const modalWidthByDevice = (device) =>
  device === "desktop"
    ? css`
        width: 1000px;
      `
    : css`
        @media (max-width: 320px) {
          width: 100%;
        }

        @media (min-width: 320px) {
          width: 345px;
        }

        @media (min-width: 1200px) {
          width: 378px;
        }

        @media (min-width: 1365px) {
          width: 414px;
        }
      `;

const BaseTransition = css`
  position: fixed;
  z-index: 1051;
  display: flex;
  flex-direction: column;
`;

const FullWidthMedia = css`
  ${(props) =>
    props.isFullWidth
      ? css`
          width: 100%;
        `
      : modalWidthByDevice(props.device)};
`;

const BottomFloating = css`
  right: ${(props) => `${props.offset.right}px`};
  left: ${(props) => `${props.offset.left}px`};
  top: ${(props) => (props.fixIosKeyboard ? "70%" : "50%")};
  width: auto;
  ${({ hasContentMaxHeight }) =>
    hasContentMaxHeight &&
    css`
      max-height: 80vh;
    `};
  ${({ hasContentMaxHeight, contentMaxHeight }) =>
    hasContentMaxHeight &&
    contentMaxHeight &&
    css`
      max-height: ${contentMaxHeight};
    `};
  ${({ hasContentMaxHeight, contentMaxHeight, paddings }) =>
    hasContentMaxHeight &&
    contentMaxHeight &&
    paddings &&
    css`
      padding-top: ${paddings};
      padding-bottom: ${paddings};
    `};
`;

const BottomPositioning = css`
  right: ${(props) => `${props.offset.right}px`};
  bottom: ${(props) => `${props.offset.bottom}px`};
  ${(props) =>
    !props.isFullHeight &&
    css`
      max-height: calc(100% - ${`${props.offset.top + props.offset.bottom}px`});
    `};
  ${(props) =>
    props.isFullHeight &&
    css`
      top: ${`${props.offset.top}px`};
    `};
`;
const LeftPositioning = css`
  ${(props) =>
    props.isFullHeight &&
    css`
      top: ${`${props.offset.top}px`};
    `};
  left: ${(props) => `${props.offset.left}px`};
  bottom: ${(props) => `${props.offset.bottom}px`};
`;

const RightPositioning = css`
  right: ${(props) => `${props.offset.right}px`};
  bottom: ${(props) => `${props.offset.bottom}px`};
  ${(props) =>
    props.isFullHeight &&
    css`
      top: ${`${props.offset.top}px`};
    `};
`;

const TopPositioning = css`
  right: ${(props) => `${props.offset.right}px`};
  top: ${(props) => `${props.offset.top}px`};
  ${(props) =>
    props.isFullHeight &&
    css`
      bottom: ${`${props.offset.bottom}px`};
    `};
`;

const FadePositioning = css`
  ${(props) =>
    !props.isFullWidth &&
    !props.isFluidWidth &&
    !props.hasContainerFixedWidth &&
    modalWidthByDevice(props.device)};
  ${(props) =>
    !props.isFullHeight &&
    !props.isFullWidth &&
    css`
      top: ${({ fixIosKeyboard }) => (fixIosKeyboard ? "70%" : "50%")};
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      max-height: calc(
        100vh - ${`${props.offset.top + props.offset.bottom}px`}
      );
    `};
  ${(props) =>
    props.isFullHeight &&
    props.isFullWidth &&
    css`
      top: ${`${props.offset.top}px`};
      left: ${`${props.offset.left}px`};
      right: ${`${props.offset.right}px`};
      bottom: ${`${props.offset.bottom}px`};
      transform: translate3d(0, 0, 0);
    `};
  ${(props) =>
    props.isFullWidth &&
    !props.isFullHeight &&
    css`
      top: 50%;
      left: ${`${props.offset.left}px`};
      right: ${`${props.offset.right}px`};
      transform: translate3d(0, -50%, 0);
    `};
  ${(props) =>
    !props.isFullWidth &&
    props.isFullHeight &&
    css`
      top: ${`${props.offset.top}px`};
      bottom: ${`${props.offset.bottom}px`};
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    `};
`;

export const FromBottomFloating = styled.div`
  ${BaseTransition};
  ${BottomFloating};
  ${SlideFloatingBottomTransition};
`;

export const FromBottom = styled.div`
  ${BaseTransition};
  ${BottomPositioning};
  ${FullWidthMedia};
  ${SlideBottomTransition};
`;

export const FromLeft = styled.div`
  ${BaseTransition};
  ${LeftPositioning};
  ${FullWidthMedia};
  ${SlideLeftTransition};
`;

export const FromRight = styled.div`
  ${BaseTransition};
  ${RightPositioning};
  ${FullWidthMedia};
  ${SlideRightTransition};
`;

export const FromTop = styled.div`
  ${BaseTransition};
  ${TopPositioning};
  ${FullWidthMedia};
  ${SlideTopTransition};
`;

export const FromFade = styled.div`
  ${BaseTransition};
  ${FadePositioning};
  ${FadeTransition};
`;
