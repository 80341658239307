import { buildColor } from "@tvg/design-system/src";
import styled from "styled-components";
import { StyledContainerProps } from "./types";

export const Container = styled.div<StyledContainerProps>`
  position: relative;
  border-radius: 4px;
  overflow: visible;
  opacity: ${({ isActive }) => !isActive && "0.4"};
  transition: opacity 0.1s;
`;

export const LoadingContainer = styled.div`
  z-index: 110;
  background-color: ${buildColor("yellow", "500")};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
