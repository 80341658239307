import React from "react";
import { Path } from "react-native-svg";

import { IconPathProps } from "../types";

const ChevronDown = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M19.5 9L12 16.5L4.5 9"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default ChevronDown;
