import React from "react";
import { IconPathProps } from "../../types";

export const DoubleChevronRight = ({
  lineColor,
  strokeWidth
}: IconPathProps) => (
  <path
    d="M12 4.5L19.5 12L12 19.5M4.5 4.5L12 12L4.5 19.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
