import { engageApptentiveEvent } from "../utils";

// when a user logs in to TVG
export const login = () => engageApptentiveEvent("Login");

// when user close the success deposit modal
export const closeDeposit = () => engageApptentiveEvent("Deposit");

// when user close the success withdrawal modal
export const closeWithdrawal = () => engageApptentiveEvent("Withdrawal");

// when user select Responsible Gaming
export const selectResponsibleGaming = () =>
  engageApptentiveEvent("Responsible Gaming");

// when user select Wallet
export const selectWallet = () => engageApptentiveEvent("Wallet is tapped");

// when user navigate to Home
export const navigateHome = () => engageApptentiveEvent("Home tapped");
