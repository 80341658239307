import styled from "styled-components";

import { space } from "styled-system";

export const Container = styled.div`
  width: 100%;
`;

export const StyledImage = styled.img`
  ${space}
`;

export const ErrorContainer = styled.div`
  flex-direction: row;
`;
