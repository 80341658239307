import styled, { css } from "styled-components";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import Icon from "@tvg/atomic-ui/_static/Icons";

export const Content = styled.div`
  max-width: 100%;
  flex: 1;
  border-bottom: 1px solid #d6e3f0;
  padding: 16px 0 16px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const TitleContainer = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  flex: 1;
`;

export const TitleText = styled.h3<{
  isCapitalize: boolean;
  textColor?: string;
}>`
  flex: 1;
  margin-left: 8px;
  line-height: 18px;
  color: ${({ textColor }) => textColor || buildColor("grey", "900")};
  font-family: ${fontNormal};
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ isCapitalize }) =>
    isCapitalize &&
    css`
      text-transform: capitalize;
    `};
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-top: 8px;

  svg {
    margin-right: 8px;
  }
`;

export const Description = styled.p`
  margin-left: 24px;
  line-height: 18px;
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 14px;
`;

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-bottom: 1px solid #d6e3f0;
`;

export const ArrowIcon = styled(Icon)<{ visible?: boolean }>`
  visibility: ${({ visible = true }) => (visible ? "visible" : "hidden")};
`;

export const Item = styled.div<{ isDisabled: boolean }>`
  display: flex;
  padding-left: 12px;
  background-color: ${
    buildColor(
      "white",
      "100"
    ) /* TODO: DS equivalent buildColor("white", "900") */
  };
  text-decoration: none;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${buildColor("blue", "000")};

      ${TitleText} {
        color: ${buildColor("blue", "300")};
        font-weight: 500;
      }

      ${Description} {
        color: ${buildColor("blue", "300")};
        font-weight: 400;
      }
    `}

  &:last-child {
    ${Content} {
      border: 0;
    }

    ${Arrow} {
      border: 0;
    }
  }
`;

export const FlagContainer = styled.div`
  display: flex;

  div:not(:first-child) {
    margin-left: 4px;
  }
`;
