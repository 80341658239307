import styled from "styled-components";

export const MessageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  background-color: ${({ theme }) =>
    theme.colorTokens.component.notification.negative.background};
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  word-wrap: break-word;
  line-height: 125%;
  align-items: flex-start;
`;
