// @flow
import React from "react";
import { CircularBackground, Bar } from "./styled-components";

type Props = {
  size?: string,
  borderColor?: string,
  backgroundColor?: string
};

const CircularLoader = ({ size, borderColor, backgroundColor }: Props) => (
  <CircularBackground size={size}>
    <Bar
      size={size}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    />
  </CircularBackground>
);

export default CircularLoader;
