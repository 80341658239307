import React, { useState, useEffect } from "react";
import { Store } from "redux";
import { get } from "lodash";
import pawsCreateService from "@tvg/api/paws/create";
import { useSelector, useDispatch } from "react-redux";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import { buildColor } from "@tvg/design-system";
import { closePayNearMeFundsModal } from "@tvg/sh-lib-paws/redux/slices/depositFundsModalSlice";
import {
  getDepositFundsModal,
  getPawsContent,
  getPNMContentFAQ
} from "@tvg/sh-lib-paws/redux/selectors";
import { matchDepositPropositions } from "@tvg/sh-lib-promos-onboarding/utils/matchDepositPropositions";
import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import PromosOnboarding from "@tvg/promos-onboarding";
import { fontCondensedNormal } from "@tvg/atomic-ui/_static/Typography";
import payNearMe from "@tvg/atomic-ui/_static/images/pay-near-me.png";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import { PaymentType } from "@tvg/ts-types/Payment";
import {
  SevenElevenStore,
  FamilyDollarStore
} from "@tvg/atomic-ui/_static/Icons/paws";
import { getAccountNumber } from "@urp/store-selectors";
import { PaymentTypesAvailable } from "@tvg/api/paws/types";
import SpinningWheel from "../../SpinningWheel";
import AlertMessagesModal from "../../AlertMessagesModal";
import Button from "../common/Button";
import { replaceStringTokenByComponent } from "../common/utils";
import { ButtonAction, PawsContent, ErrorMessage } from "../../../types";
import {
  Container,
  Main,
  LogoPayNearMe,
  PayNearMeDescription,
  PayNearMeInfos,
  PayNearMeIframe,
  PayNearMeLinkFAQ,
  PayNearMeFAQ,
  StoresWrapper,
  StoreContainer,
  StoreDescription,
  PayNearMeFooter
} from "./styled-components";
import { PayNearMeProps } from "../common/types";
import { isMobile } from "../../../utils";
import { handleCloseError, handleRetry } from "./methods/errorHandling";

const PayNearMeFundsModal = ({
  onCloseCallback,
  promosOnboarding
}: PayNearMeProps) => {
  const dispatch = useDispatch();
  const {
    isPayNearMeModalOpen
  }: {
    isPayNearMeModalOpen: boolean;
  } = useSelector(getDepositFundsModal);

  const paymentType = PaymentType.PAY_NEAR_ME;
  const accountId = useSelector<Store, string>(getAccountNumber);
  const content = useSelector<Store, PawsContent>(getPawsContent);
  const FAQContent = useSelector<Store, string>(getPNMContentFAQ);
  const pnmMessages = content.deposit.PNM;

  const [isLoading, setIsLoading] = useState(true);
  const [iframeURL, setIframeURL] = useState("");
  const [isFAQOpen, setIsFAQOpen] = useState(false);

  const errorMessageInitialState = {
    isOpen: false,
    errorCode: "",
    type: "error" as "success" | "error" | "warning",
    title: content.paymentMessageModal.error.title,
    description: content.paymentMessageModal.error.description,
    actions: [] as Array<ButtonAction>,
    isRetry: false,
    retryCount: 0
  };

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(
    errorMessageInitialState
  );

  const onClose = () => {
    dispatch(closePayNearMeFundsModal());
    onCloseCallback();
  };

  const getPayNearMeId = async () => {
    try {
      const response = await pawsCreateService.getPayNearMeId(
        paymentType as PaymentTypesAvailable,
        {
          accountId
        }
      );

      const { data } = response;

      if (data) {
        setIframeURL(data.orderTrackingUrl as string);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const response = await pawsCreateService.createPayNearMeId(
        paymentType as PaymentTypesAvailable,
        {
          accountId
        }
      );

      const { data } = response;

      if (data) {
        setIframeURL(data.orderTrackingUrl as string);
      }
    } catch (err) {
      const errorCode = get(err, "response.data.code", "");

      setErrorMessage((prevState) => ({
        ...prevState,
        isOpen: true,
        errorCode,
        isRetry: false
      }));

      setIsLoading(false);
    }
  };

  const linkFAQ = () => (
    <PayNearMeLinkFAQ onClick={() => setIsFAQOpen(true)}>
      {pnmMessages.faqLinkText}
    </PayNearMeLinkFAQ>
  );

  useEffect(() => {
    if (isPayNearMeModalOpen) {
      getPayNearMeId();
    } else {
      setIsLoading(true);
      setIframeURL("");
      setIsFAQOpen(false);
    }
  }, [isPayNearMeModalOpen]);

  useEffect(() => {
    if (errorMessage.isRetry) {
      onSubmit();
    }
  }, [errorMessage]);

  return (
    <>
      <ModalV2
        isOpen={isPayNearMeModalOpen}
        title={
          isFAQOpen
            ? pnmMessages.faqModalTitle
            : content.depositFundsModal.title
        }
        subtitleFontFamily={fontCondensedNormal}
        onClose={onClose}
        onOverlayClick={onClose}
        qaLabel="paynearme-funds-modal"
        animation={isMobile ? "bottom" : "fade"}
        offsetLeft={isMobile ? 0 : 12}
        offsetRight={isMobile ? 0 : 12}
        fixedWidth={isMobile ? "100%" : "375px"}
        isFullWidth={isMobile}
        offsetTop={55}
        isFullHeight={isMobile}
        hasRoundedCorners={!isMobile}
        isTitleCapitalized={false}
        isTitleCenter={true}
        useModalHeaderV3
        {...(isFAQOpen && { hasCloseButton: false })}
        {...(isFAQOpen && { onBack: () => setIsFAQOpen(false) })}
      >
        {() =>
          isFAQOpen ? (
            <Container isMobile={isMobile}>
              <PayNearMeFAQ dangerouslySetInnerHTML={{ __html: FAQContent }} />
            </Container>
          ) : (
            <Container
              isMobile={isMobile}
              hasPayNearMeIframe={!isLoading && !!iframeURL}
            >
              {isLoading && (
                <Main>
                  <SpinningWheel className="loader" />
                </Main>
              )}
              {iframeURL && (
                <PayNearMeIframe
                  src={iframeURL}
                  isLoading={isLoading}
                  onLoad={() => setIsLoading(false)}
                />
              )}
              {!isLoading && !iframeURL && (
                <>
                  <PromosOnboarding
                    placeToRender={StoryblokPlaceName.WALLET_DEPOSIT_TOP}
                    isShown={matchDepositPropositions(
                      promosOnboarding,
                      paymentType
                    )}
                  />
                  <Main>
                    <picture>
                      <source srcSet={payNearMe} />
                      <LogoPayNearMe src={payNearMe} alt="PayNearMe" />
                    </picture>
                    <PayNearMeDescription>
                      {pnmMessages.description}
                    </PayNearMeDescription>
                    <StoresWrapper>
                      <StoreContainer>
                        <SevenElevenStore />
                        <StoreDescription>7-Eleven</StoreDescription>
                      </StoreContainer>
                      <StoreContainer>
                        <FamilyDollarStore />
                        <StoreDescription>Family Dollar</StoreDescription>
                      </StoreContainer>
                    </StoresWrapper>
                  </Main>
                  <PayNearMeFooter>
                    <PayNearMeInfos isMobile={isMobile}>
                      {pnmMessages.descriptionSteps.map(
                        (step: string, index: number) => (
                          <p key={`description-${index + 1}`}>
                            {replaceStringTokenByComponent(
                              step,
                              "~faqLinkText~",
                              linkFAQ()
                            )}
                          </p>
                        )
                      )}
                    </PayNearMeInfos>

                    <Button
                      text={pnmMessages.buttonText}
                      onClick={onSubmit}
                      isLoading={isLoading}
                      hasError={false}
                      bgColor={buildColor("blue_accent", "500")}
                    />
                  </PayNearMeFooter>
                </>
              )}
            </Container>
          )
        }
      </ModalV2>

      {errorMessage.isOpen && (
        <AlertMessagesModal
          type={errorMessage.type || "error"}
          isOpen={errorMessage.isOpen}
          errorCode={errorMessage.errorCode}
          isCapiDefault
          isMobile={isMobile}
          onCloseMethod={handleCloseError(setErrorMessage)}
          onRetryMethod={handleRetry(setErrorMessage, errorMessage)}
          onContactMethod={() => openLiveChat()}
          retryCount={errorMessage.retryCount}
          actions={[
            {
              text: "Close",
              onClick: handleCloseError(setErrorMessage),
              isStretched: true,
              isUppercase: false,
              qaLabel: "close"
            }
          ]}
        />
      )}
    </>
  );
};

export default PayNearMeFundsModal;
