import styled, { css } from "styled-components";
import { Theme } from "styled-system";

export const BetCardFooter = styled.div.attrs({
  "data-qa-label": "myBets-betTicket-footer"
})<{
  theme: Theme;
}>`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 ${({ theme }) => theme.space["space-1"]};
  border-top: 1px solid ${({ theme }) => theme.colors.blue["100"]};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const FooterButton = styled.div<{
  theme: Theme;
  isCancelButton?: boolean;
  isDetailsButton?: boolean;
  isRepeatButton?: boolean;
  isWagerCancelable?: boolean;
}>`
  display: ${({ isDetailsButton, isWagerCancelable }) =>
    isDetailsButton && isWagerCancelable ? "unset" : "flex"};
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  ${({ isDetailsButton }) =>
    isDetailsButton &&
    css`
      margin-left: auto;
    `}

  ${({ isRepeatButton, isCancelButton }) =>
    (isRepeatButton || isCancelButton) &&
    css`
      padding: 0 ${({ theme }) => theme.space["space-3"]};
      margin-right: ${({ theme }) => theme.space["space-1"]};
    `};
`;

export const TooltipContainer = styled.div`
  margin-left: 4px;
`;
