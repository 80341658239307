import styled, { css } from "styled-components/native";
import { View, Text } from "react-native";
import { space, layout, compose } from "styled-system";
import { Paragraph } from "../typography";

const styledUtils = compose(space, layout);

export const StyledViewContainer = styled(View)`
  ${styledUtils};
  align-items: center;
`;

export const StyledParagraph = styled(Paragraph)`
  text-align: center;
  color: ${({ theme }) => theme.colors.grey[800]};
`;

export const StyledTextTitle = styled(Text)`
  text-align: center;
  ${({ theme }) => css`
    font-family: ${theme.fonts.bold};
    font-size: ${theme.fontSizes.m};
    color: ${theme.colors.grey[900]};
    margin-top: ${theme.space["space-6"]};
    margin-bottom: ${theme.space["space-3"]};
  `}
`;
