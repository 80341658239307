import React from "react";
import BaseSvg from "./baseSvg";
import {
  Header,
  AmountDisplay,
  PillsBox,
  Total,
  Row,
  Selector,
  Body,
  SelectorBox,
  Container
} from "./styled-components";

const ListRow = () => (
  <Row>
    <BaseSvg width="80" height="16" />
    <BaseSvg width="20" height="16" />
  </Row>
);

const DesktopDepositMask = () => (
  <Container>
    <Header>
      <BaseSvg width="160" height="16" />
      <BaseSvg width="120" height="16" />
    </Header>
    <Body>
      <AmountDisplay>
        <BaseSvg width="44" height="60" />
        <PillsBox>
          <BaseSvg width="20" height="16" />
          <BaseSvg width="20" height="16" />
          <BaseSvg width="20" height="16" />
        </PillsBox>
      </AmountDisplay>
      <Total>
        <ListRow />
        <ListRow />
        <ListRow />
      </Total>
      <SelectorBox>
        <BaseSvg width="120" height="16" />
        <Selector>
          <BaseSvg width="16" height="16" />
          <BaseSvg width="120" height="16" />
        </Selector>
      </SelectorBox>
      <BaseSvg width="100%" height="50" fillColor="green" />
    </Body>
  </Container>
);

export default DesktopDepositMask;
