import React from "react";
import { useDispatch, useSelector } from "react-redux";
import WalletComp from "@tvg/wallet";
import { usePromoOnboardingPoller } from "@tvg/sh-lib-promos-onboarding/hooks/usePromoOnboardingPoller";
import {
  getPromoOnboardingPollerTime,
  getPromosOnboardingToggle
} from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { getAccountNumber, getIsLogged } from "@urp/store-selectors";

const MicroApp = () => {
  const dispatch = useDispatch();
  const promoOnboardingToggle = useSelector(getPromosOnboardingToggle);
  const promoOnboardingPollerTime = useSelector(getPromoOnboardingPollerTime);
  const accountId = useSelector(getAccountNumber);
  const isLogged = useSelector(getIsLogged);

  usePromoOnboardingPoller(
    dispatch,
    promoOnboardingToggle,
    promoOnboardingPollerTime,
    isLogged,
    accountId
  );

  return <WalletComp />;
};

export default MicroApp;
