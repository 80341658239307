import styled from "styled-components";

export const CustomDropdown = styled.div`
  position: relative;
  width: 100%;
  min-width: 123px;
`;

export const Label = styled.span<{ isDisabled: boolean }>`
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-5"]}`};
  color: ${({ theme, isDisabled }) =>
    isDisabled
      ? theme.colorTokens.content.disabled
      : theme.colorTokens.content.default};
  &:hover {
    cursor: default;
  }
`;

export const SelectOptions = styled.div`
  width: max-content;
  position: absolute;
  top: 58px;
  left: 0;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  box-shadow: ${({ theme }) => theme.elevations.medium};
  border-radius: 4px;
  z-index: 1;
`;

export const SelectOption = styled.div`
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-4"]} 0 ${theme.space["space-4"]}`};
`;
