import styled from "styled-components/native";
import { View } from "react-native";
import { space } from "styled-system";

export const StyledImage = styled.Image(space);

export const Container = styled(View)`
  width: 100%;
`;

export const ErrorContainer = styled(View)`
  flex-direction: row;
`;
