import { ButtonAction } from "@tvg/wallet/src/types";
import { NullaryFn, UnaryFn, QuadFn } from "@tvg/ts-types/Functional";
import { DepositStep } from "@tvg/sh-lib-promos-onboarding/types/promoSteps";
import { Dispatch } from "react";

export interface Props {
  isOpen: boolean;
  onCloseCallback: NullaryFn<void>;
  maxLimit: number;
  minLimit: number;
  refetchAvailableMethods: NullaryFn<void>;
  promosOnboarding?: DepositStep;
  isUserInPromoOnboarding?: boolean;
  promoOnboardingStepsNumber?: number;
  promoOnboardingSteps?: Array<string>;
  promoOnboardingActions?: UnaryFn<Function, Array<ButtonAction>>;
  promoOnboardingCustomLabel?: UnaryFn<number, string>;
  userHasBetPlaced?: boolean;
}

export type Validations = {
  invalid?: string;
  required?: string;
  invalidDate?: string;
  invalidMonth?: string;
  invalidYear?: string;
};

export interface State {
  amount: string;
  cardType: string;
  cardNum: string;
  cardExp: string;
  cardCVV: string;
  isLoading: boolean;
  isFeeInfoOpen: boolean;
  showSuccessMessage: boolean;
  isCVVInfoOpen: boolean;
  isDepositHoldFundsType: boolean;
}

export type StateKeys = keyof State | "resetState" | "resetStateWithoutAmount";

export type Action = {
  type: StateKeys;
  payload?: {
    value?: string;
    status?: boolean;
  };
};

export type CardFieldType = "cardType" | "cardNum" | "cardExp" | "cardCVV";

export const ACTION_AMOUNT = "amount";
export const ACTION_CARD_TYPE = "cardType";
export const ACTION_CARD_NUM = "cardNum";
export const ACTION_CARD_EXP = "cardExp";
export const ACTION_CARD_CVV = "cardCVV";
export const ACTION_IS_LOADING = "isLoading";
export const ACTION_IS_FEE_INFO_OPEN = "isFeeInfoOpen";
export const ACTION_SHOW_SUCCESS_MESSAGE = "showSuccessMessage";
export const ACTION_IS_CVV_INFO_OPEN = "isCVVInfoOpen";
export const ACTION_IS_DEPOSIT_HOLD_FUNDS_TYPE = "isDepositHoldFundsType";
export const ACTION_RESET_STATE = "resetState";
export const ACTION_RESET_STATE_WITHOUT_AMOUNT = "resetStateWithoutAmount";

export type FieldType = "amount" | "number" | "expire" | "cvv";

export type CardFieldValidator = (
  limit: number,
  separator?: RegExp
) => FieldValidator;

export type FieldValidator = (value: string) => boolean;

export type FieldFormatter = (value: string) => string;

export interface FormReturn {
  formState: State;
  formDispatch: Dispatch<Action>;
  showAmountWarning: boolean;
  expErrMsg: string;
  numErrMsg: string;
  cvvErrMsg: string;
  hasError: boolean;
}

export interface ActiveFieldState {
  value: string;
  setState:
    | React.Dispatch<React.SetStateAction<string>>
    | ((value: string) => void);
  validator?: FieldValidator;
  formatter?: FieldFormatter;
}

export type FormDispatch = UnaryFn<Action, void>;

export type FeeValueGetter = QuadFn<string, string, number, number, void>;
