import { get } from "lodash";
import { getStoryblokComponent } from "@tvg/sh-lib-promos-onboarding/utils/getStoryblokComponent";
import { formatStoryblokProps } from "@tvg/sh-lib-promos-onboarding/helpers/formatStoryblokProps";
import {
  StoryblokStep,
  StoryblokComponentPropsFormatted
} from "@tvg/sh-lib-promos-onboarding/types/storyblok";
import { ComponentPreview } from "../types";

export const formatPreviewComponents = (steps: StoryblokStep[]) => {
  const componentsByStep: ComponentPreview[][] = [];

  steps.forEach((step, index) => {
    const componentsInCurrentStep = get(step, "messages", []);

    componentsInCurrentStep.forEach((currentComponent) => {
      const { component, ...rest } = get(
        currentComponent,
        "promo_component[0]",
        {}
      );

      const props = formatStoryblokProps(rest);

      const formattedComponent: StoryblokComponentPropsFormatted = {
        component,
        props
      };

      const currentRenderedComponent =
        getStoryblokComponent(formattedComponent);

      const renderPlaces = get(
        currentComponent,
        "render_place[0].places",
        []
      ).reduce(
        (places: string, currentPlace: string) =>
          `${places ? places + ", " : ""}${currentPlace}`,
        ""
      );

      if (!get(componentsByStep, index)) {
        componentsByStep[+index] = [];
      }

      componentsByStep[+index].push({
        component: currentRenderedComponent,
        renderPlaces
      });
    });
  });

  return componentsByStep;
};
