import React, { memo } from "react";
import { EventsState } from "../../../../hooks/useEvents";
import { Icon } from "../../../icon";
import { SpaceProps } from "../../../../theming/styledSystem";
import { AlertInlineStyleProps, AlertInlineVariants } from "../../types";
import {
  alertInlineIconVariants,
  alertToastIconVariants
} from "./styled-components";
import { getPressableColor } from "../../utils/getPressableColor";

export const AlertInlineIcon = memo(
  ({
    type = "floating",
    variant,
    isClose = false,
    events,
    icon,
    ...rest
  }: AlertInlineStyleProps &
    SpaceProps & { isClose?: boolean; events?: EventsState }) => {
    const { backgroundColor, lineColor, name } =
      type === "toast"
        ? alertToastIconVariants[variant as AlertInlineVariants]
        : alertInlineIconVariants[variant as AlertInlineVariants];

    return (
      <Icon
        size="s"
        name={isClose ? "close" : icon || name}
        backgroundColor={backgroundColor || "none"}
        lineColor={
          isClose ? getPressableColor(type, variant, events) : lineColor
        }
        qaLabel={`alert-inline-icon-${isClose ? "close" : name}`}
        {...rest}
      />
    );
  }
);

export default AlertInlineIcon;
