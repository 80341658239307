// @flow
import type { UpdateBalanceActionType } from "@tvg/shared-actions/UserActions";
import type {
  Withdrawal,
  WithdrawCancelInformation
} from "@tvg/atomic-ui/_templates/Withdrawals";

export type SetWithdrawalsAction = {
  type: "WITHDRAWALS_SET_WITHDRAWS",
  payload: {
    withdrawals: Withdrawal[]
  }
};

type OpenCancelWithdrawalAction = {
  type: "WITHDRAWALS_OPEN_CANCEL",
  payload: {
    cancelInformation: WithdrawCancelInformation
  }
};

type CloseCancelWithdrawalAction = {
  type: "WITHDRAWALS_CLOSE_CANCEL",
  payload: {
    successfulCancel: boolean
  }
};

type CancelWithdrawalErrorAction = {
  type: "WITHDRAWALS_CANCEL_ERROR",
  payload: {
    message: string,
    code: string
  }
};

type CancelWithdrawalLoadingAction = {
  type: "WITHDRAWALS_CANCEL_LOADING",
  payload: {
    isLoading: boolean
  }
};

type ResetMessagesAction = {
  type: "WITHDRAWALS_RESET_MESSAGES"
};

type ClearErrorCancellingWithdrawalAction = {
  type: "WITHDRAWALS_CLEAR_ERROR"
};

type SetPendingLoadingAction = {
  type: "WITHDRAWALS_FETCH_LOADING",
  payload: {
    isPendingLoading: boolean
  }
};

export type Actions =
  | SetWithdrawalsAction
  | OpenCancelWithdrawalAction
  | CloseCancelWithdrawalAction
  | CancelWithdrawalErrorAction
  | CancelWithdrawalLoadingAction
  | ResetMessagesAction
  | UpdateBalanceActionType
  | ClearErrorCancellingWithdrawalAction
  | SetPendingLoadingAction;

export const setWithdrawals = (
  withdrawals: Withdrawal[]
): SetWithdrawalsAction => ({
  type: "WITHDRAWALS_SET_WITHDRAWS",
  payload: { withdrawals }
});

export const openCancelWithdrawal = (
  cancelInformation: WithdrawCancelInformation
): OpenCancelWithdrawalAction => ({
  type: "WITHDRAWALS_OPEN_CANCEL",
  payload: { cancelInformation }
});

export const closeCancelWithdrawal = (
  successfulCancel: boolean
): CloseCancelWithdrawalAction => ({
  type: "WITHDRAWALS_CLOSE_CANCEL",
  payload: { successfulCancel }
});

export const errorCancellingWithdrawal = (
  message: string,
  code: string
): CancelWithdrawalErrorAction => ({
  type: "WITHDRAWALS_CANCEL_ERROR",
  payload: { message, code }
});

export const cancelWithdrawalLoading = (
  isLoading: boolean
): CancelWithdrawalLoadingAction => ({
  type: "WITHDRAWALS_CANCEL_LOADING",
  payload: { isLoading }
});

export const resetMessages = (): ResetMessagesAction => ({
  type: "WITHDRAWALS_RESET_MESSAGES"
});

export const clearErrorCancellingWithdrawal =
  (): ClearErrorCancellingWithdrawalAction => ({
    type: "WITHDRAWALS_CLEAR_ERROR"
  });

export const setPendingLoading = (
  isPendingLoading: boolean
): SetPendingLoadingAction => ({
  type: "WITHDRAWALS_FETCH_LOADING",
  payload: { isPendingLoading }
});
