import { useCallback, useEffect } from "react";
import mediator from "@tvg/mediator";
import { toggleModal } from "@tvg/wallet/src/components/PendingWithdrawals/utils";
import { Withdrawal, WithdrawalsInfo } from "@tvg/wallet/src/types";
import { useDispatch, useSelector } from "react-redux";
import { getWithdrawalsInformation } from "@tvg/sh-lib-paws/redux/selectors";
import withdrawalsService from "@tvg/withdrawals/src/service";
import { setWithdrawals } from "@tvg/withdrawals/src/actions";
import { getAccountNumber } from "@urp/store-selectors";

export const usePendingWithdrawsModalState = () => {
  const dispatch = useDispatch();
  const accountNumber: string = useSelector(getAccountNumber);
  const { withdrawals, showPendingWithdrawalsV2 }: WithdrawalsInfo =
    useSelector(getWithdrawalsInformation);

  useEffect(() => {
    mediator.base.dispatch({
      type: "PENDING_WITHDRAWALS_LENGTH",
      payload: { withdrawalsLength: withdrawals.length }
    });
  }, [withdrawals.length]);

  useEffect(() => {
    if (showPendingWithdrawalsV2 && accountNumber) {
      refetchPendingWithdrawals();

      mediator.base.unsubscribe("OPEN_PENDING_WITHDRAWALS_MODAL", () =>
        toggleModal(dispatch, true)()
      );
      mediator.base.subscribe("OPEN_PENDING_WITHDRAWALS_MODAL", () =>
        toggleModal(dispatch, true)()
      );

      mediator.base.unsubscribe(
        "REFETCH_PENDING_WITHDRAWALS_LENGTH",
        refetchPendingWithdrawals
      );
      mediator.base.subscribe(
        "REFETCH_PENDING_WITHDRAWALS_LENGTH",
        refetchPendingWithdrawals
      );
    }
    return () => {
      mediator.base.unsubscribe("OPEN_PENDING_WITHDRAWALS_MODAL", () =>
        toggleModal(dispatch, true)()
      );
      mediator.base.unsubscribe(
        "REFETCH_PENDING_WITHDRAWALS_LENGTH",
        refetchPendingWithdrawals
      );
    };
  }, [showPendingWithdrawalsV2, accountNumber]);

  const refetchPendingWithdrawals = useCallback(
    () =>
      withdrawalsService
        .getWithdrawals(accountNumber)
        .then((w: Withdrawal[]) => {
          mediator.base.dispatch({
            type: "PENDING_WITHDRAWALS_LENGTH",
            payload: { withdrawalsLength: w.length }
          });
          dispatch(setWithdrawals(w));
        }),
    [accountNumber]
  );
};
