import React, { useMemo } from "react";
import { ButtonAction } from "@tvg/wallet/src/types";
import { useSelector } from "react-redux";
import { getPawsContent } from "@tvg/sh-lib-paws/redux/selectors";
import InfoMessage from "../info";
import { WithdrawalBalanceModalProps } from "../../common/types";
import type { PlayableBalanceProps } from "./types";
import { getParsedDescription } from "../../common/utils";

const PlayableBalance = ({ pBalance, onClose }: PlayableBalanceProps) => {
  const { withdrawalBalanceModal }: WithdrawalBalanceModalProps =
    useSelector(getPawsContent);

  const actions: ButtonAction[] = useMemo(
    () => [
      {
        text: withdrawalBalanceModal.okBtnText,
        onClick: onClose,
        isStreched: true,
        isUppercase: false,
        qaLabel: "ok-btn"
      }
    ],
    []
  );

  const parsedDescription = useMemo(
    () =>
      getParsedDescription(
        withdrawalBalanceModal.pBalanceDescription,
        "pBalance",
        pBalance
      ),
    [pBalance]
  );

  const description: string[] = useMemo(
    () => [
      withdrawalBalanceModal.noBalanceDescription,
      "<br />",
      parsedDescription
    ],
    [parsedDescription]
  );

  const title: string = useMemo(
    () => withdrawalBalanceModal.noBalanceTitle,
    []
  );

  return (
    <InfoMessage
      actions={actions}
      description={description}
      title={title}
      onClose={onClose}
    />
  );
};

export default React.memo(PlayableBalance);
