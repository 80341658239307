import { Dispatch } from "redux";
import { get, isEmpty } from "lodash";
import mediator from "@tvg/mediator";
import { setSelectedPaymentMethod } from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import type { UnaryFn } from "@tvg/ts-types/Functional";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import React from "react";
import { OnNewMethodArgs, PaymentTypeOption, PaymentType } from "../../types";
import { checkCardLimit, desktopNavigation } from "../../utils";

export const clickAccountOnFile = (
  isExpired: boolean,
  isDisabled: boolean,
  payment: PaymentTypeOption,
  allPaymentMethodOptions: PaymentTypeOption[] | null,
  paymentType: PaymentType,
  setActiveOption: UnaryFn<string | null, void> = () => {},
  dispatch?: Dispatch,
  activeOption?: string | null
) => {
  if (!isExpired && !isDisabled) {
    if (activeOption !== payment.id) {
      if (activeOption) {
        const closedPaymentMethod = (allPaymentMethodOptions || []).filter(
          (option: PaymentTypeOption) =>
            option && !isEmpty(option) && option.id === activeOption
        );
        // user is changing payment methods, dispatch close gtm
        mediator.base.dispatch({
          type: "PAWS:DEPOSIT_WITHDRAW_OPTIONS_MODAL",
          payload: {
            paymentType: get(closedPaymentMethod, "[0].paymentType"),
            isOpen: false
          }
        });
      }

      mediator.base.dispatch({
        type: "PAWS:DEPOSIT_WITHDRAW_OPTIONS_MODAL",
        payload: {
          paymentType:
            paymentType !== PaymentTypeEnum.OTHER_PAYMENT
              ? paymentType
              : payment.paymentType,
          isOpen: true
        }
      });
    }

    if (dispatch) {
      dispatch(
        setSelectedPaymentMethod({
          id: payment.id,
          paymentType:
            paymentType !== PaymentTypeEnum.OTHER_PAYMENT
              ? paymentType
              : payment.paymentType,
          title: payment.title,
          subTitle: payment.description,
          description: "",
          depositsAvailable: payment.depositsAvailable,
          withdrawalsAvailable: payment.withdrawalsAvailable,
          minLimit: payment.minLimit,
          maxLimit: payment.maxLimit
        })
      );
    }
    setActiveOption(payment.id);
  }
};

export const clickAddPaymentMethod = (
  event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  paymentType: PaymentType,
  onNewPaymentMethod: UnaryFn<OnNewMethodArgs, void> = () => null,
  paymentTypeOptions: PaymentTypeOption[] | null,
  dispatch?: Dispatch
) => {
  // GTM PAWS-69
  mediator.base.dispatch({
    type: "PAWS:SELECT_PAYMENT_TYPE_OR_METHOD",
    payload: {
      isNewCard: true,
      paymentType
    }
  });

  onNewPaymentMethod({
    paymentType,
    toggleOffHandler: () => {
      desktopNavigation({
        event,
        paymentType,
        newPayment: true,
        paymentTypeOptions,
        dispatch
      });
    },
    checkLimit: () => checkCardLimit(paymentType, paymentTypeOptions, dispatch)
  });
};
