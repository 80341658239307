import React from "react";
import styled, { keyframes } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${buildColor("blue", "000")};
  }

  50% {
    fill: ${buildColor("blue", "100")};
  }

  100% {
    fill: ${buildColor("blue", "000")};
  }
`;

const SVG = styled.svg`
  display: block;

  .pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  flex: 1;
  width: 100%;
  background-color: ${buildColor("blue", "000")};
  overflow-x: hidden;
`;

const WalletManagementMask = () => (
  <Container>
    <SVG
      width="100%"
      height="600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="100%"
        height="48"
        transform="translate(0 192)"
        fill="white"
      />
      <rect
        x="12"
        y="208"
        width="180"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
      <g filter="url(#filter0_ii)">
        <rect
          width="100%"
          height="90"
          transform="translate(0 240)"
          fill="white"
        />
        <rect
          x="12"
          y="258"
          width="16"
          height="16"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="258"
          width="120"
          height="16"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="282"
          width="280"
          height="12"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="298"
          width="140"
          height="12"
          fill="#E9EFF5"
          className="pulse"
        />
      </g>
      <g filter="url(#filter1_ii)">
        <rect
          width="100%"
          height="90"
          transform="translate(0 330)"
          fill="white"
        />
        <rect
          x="12"
          y="348"
          width="16"
          height="16"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="348"
          width="120"
          height="16"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="372"
          width="280"
          height="12"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="388"
          width="140"
          height="12"
          fill="#E9EFF5"
          className="pulse"
        />
      </g>
      <g filter="url(#filter2_ii)">
        <rect
          width="100%"
          height="90"
          transform="translate(0 420)"
          fill="white"
        />
        <rect
          x="12"
          y="438"
          width="16"
          height="16"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="438"
          width="120"
          height="16"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="462"
          width="280"
          height="12"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="478"
          width="140"
          height="12"
          fill="#E9EFF5"
          className="pulse"
        />
      </g>
      <g filter="url(#filter3_i)">
        <rect
          width="100%"
          height="90"
          transform="translate(0 510)"
          fill="white"
        />
        <rect
          x="12"
          y="528"
          width="16"
          height="16"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="528"
          width="120"
          height="16"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="552"
          width="280"
          height="12"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="568"
          width="140"
          height="12"
          fill="#E9EFF5"
          className="pulse"
        />
      </g>
      <rect width="100%" height="48" fill="white" />
      <rect
        x="12"
        y="16"
        width="180"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
      <g filter="url(#filter4_ii)">
        <rect
          width="100%"
          height="68"
          transform="translate(0 48)"
          fill="white"
        />
        <rect
          x="12"
          y="66"
          width="16"
          height="16"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="66"
          width="120"
          height="16"
          fill="#E9EFF5"
          className="pulse"
        />
        <rect
          x="40"
          y="86"
          width="60"
          height="12"
          fill="#E9EFF5"
          className="pulse"
        />
      </g>
      <rect
        width="100%"
        height="68"
        transform="translate(0 116)"
        fill="white"
      />
      <rect
        x="12"
        y="134"
        width="16"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
      <rect
        x="40"
        y="134"
        width="120"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
      <rect
        x="40"
        y="154"
        width="60"
        height="12"
        fill="#E9EFF5"
        className="pulse"
      />
      <defs>
        <filter
          id="filter0_ii"
          x="0"
          y="240"
          width="100%"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="11" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow"
            result="effect2_innerShadow"
          />
        </filter>
        <filter
          id="filter1_ii"
          x="0"
          y="330"
          width="100%"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="11" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow"
            result="effect2_innerShadow"
          />
        </filter>
        <filter
          id="filter2_ii"
          x="0"
          y="420"
          width="100%"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="11" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow"
            result="effect2_innerShadow"
          />
        </filter>
        <filter
          id="filter3_i"
          x="0"
          y="510"
          width="100%"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter4_ii"
          x="0"
          y="48"
          width="100%"
          height="68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="11" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow"
            result="effect2_innerShadow"
          />
        </filter>
      </defs>
    </SVG>
  </Container>
);

export default WalletManagementMask;
