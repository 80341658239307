import React from "react";
import { IconPathProps } from "../../types";

export const SortAscending = ({ lineColor, strokeWidth }: IconPathProps) => (
  <path
    d="M6 21V4.57031M1.5 9L6 4.5L10.5 9M13.5 18.375H22.5M13.5 12H19.5M13.5
    5.625H16.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
