import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Wallet = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M21.375 8.62683H2.625V19.875H21.375V8.62683Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M21 4.125H2.625V8.85142M2.625 8.62683H21.375V19.875H2.625V8.62683Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <Path
      d="M18.0002 15.3694C18.0002 15.9907 17.4965 16.4944 16.8752
      16.4944C16.2539 16.4944 15.7502 15.9907 15.7502 15.3694C15.7502 14.7481
      16.2539 14.2444 16.8752 14.2444C17.4965 14.2444 18.0002 14.7481 18.0002
      15.3694Z"
      fill={lineColor}
      fillOpacity="1"
    />
  </>
);
export default Wallet;
