import { gql } from "@apollo/client";

export const GET_TRANSATION_STATUS = gql`
  subscription getTransationStatus($accountId: Int!, $uuid: String!) {
    transactionStatus: transactionStatus(accountId: $accountId, uuid: $uuid) {
      id: accountId
      accountId
      uuid
      balance
      status
      errorCode
      errorMessage
      currentStep
      steps
    }
  }
`;

export default GET_TRANSATION_STATUS;
