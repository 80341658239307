import { combineReducers, Reducer } from "redux";

import pawsReducer, {
  PawsState,
  initialPawsState
} from "@tvg/sh-lib-paws/redux/reducers";

import withdrawalsReducer, {
  initialState as initialWithdrawalsState
} from "@tvg/withdrawals/src/reducers";

import promosOnboardingReducer, {
  PromoOnboardingState,
  initialState as initialPromosOnboardingState
} from "@tvg/sh-lib-promos-onboarding/redux/reducers";

import { CapiState, UserState, WithdrawalsState } from "./types";

import capiReducer, {
  initialState as initialCapiState
} from "./App/capiReducer";

import userDataReducer, {
  initialState as initialUserState
} from "./App/userDataReducer";

export type State = {
  capi: CapiState;
  userData: UserState;
  paws: PawsState;
  withdrawals: WithdrawalsState;
  promosOnboarding: PromoOnboardingState;
};

export const initialState = {
  capi: initialCapiState,
  userData: initialUserState,
  paws: initialPawsState,
  withdrawals: initialWithdrawalsState,
  promosOnboarding: initialPromosOnboardingState
};

export default combineReducers({
  capi: capiReducer,
  userData: userDataReducer,
  paws: pawsReducer,
  withdrawals: withdrawalsReducer as Reducer<WithdrawalsState>,
  promosOnboarding: promosOnboardingReducer
});
