import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { noop } from "lodash";
import { getMazoomaToggle } from "@tvg/sh-lib-paws/redux/toggles";
import type { Props } from "./types";
import { AvailablePaymentContainer } from "./styled-components";
import { onFilter, onMap, onSort } from "./methods/utils";
import { renderEmptyState } from "./methods/renderer";

const AccountsOnFileList = ({
  accountsOnFile = [],
  emptyContainer = {
    title: "No saved payment methods",
    description:
      "TVG offers you multiple ways to deposit \n pick one of them below. "
  },
  onClick = noop,
  paymentTypesUnderMaintenance = []
}: Props) => {
  const isMZMToggleOn = useSelector(getMazoomaToggle);

  /**
   * Creates a copy of accountsOnFile, sorts it by last used, removes every Mazooma card if the toggle is off
   * and renders the items
   *
   * Function was memoized since we have 3 array operations and few dependencies
   */
  const renderList = useMemo(
    () =>
      [...accountsOnFile]
        .sort(onSort)
        .filter(onFilter(isMZMToggleOn))
        .map(onMap(paymentTypesUnderMaintenance, onClick)),
    [accountsOnFile, isMZMToggleOn]
  );

  return (
    <AvailablePaymentContainer>
      {accountsOnFile && accountsOnFile.length
        ? renderList
        : renderEmptyState(emptyContainer)}
    </AvailablePaymentContainer>
  );
};

export default memo(AccountsOnFileList);
