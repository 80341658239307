import React from "react";
import { Circle, G } from "react-native-svg";
import { animated, useSpring } from "@react-spring/native";
import { easeExpInOut } from "d3-ease";
import { useQaLabel } from "../../../../hooks";
import { SpinnerProps } from "../../types";

const AnimatedCircle = animated(G);

export const Spinner = ({ strokeWidth, color }: SpinnerProps) => {
  const circleTestProps = useQaLabel("circle");

  const { strokeDashoffset } = useSpring({
    from: { strokeDashoffset: 68 },
    to: { strokeDashoffset: 5 },
    config: { easing: easeExpInOut, duration: 1800 },
    loop: { reverse: true },
    reset: true
  });

  return (
    <AnimatedCircle
      strokeDasharray="68.7"
      strokeDashoffset={strokeDashoffset}
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="rotate(-90 12 12)"
      {...circleTestProps}
    >
      <Circle fill="transparent" cx="12" cy="12" r="11" />
    </AnimatedCircle>
  );
};

export default Spinner;
