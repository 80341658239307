import styled, { css } from "styled-components";
import { fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.button`
  border: none;
  outline: none;
  display: flex;
  align-items: flex-start;
  padding: 16px 12px;
  width: 100%;
  position: relative;

  &:active {
    background-color: ${buildColor("blue_accent", "000")};
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${buildColor("blue_accent", "000")};
        `
      : css`
          background-color: ${buildColor("white", "100")};
        `};
  ${({ device }) =>
    device === "desktop" &&
    css`
      &:hover {
        background-color: ${buildColor("blue_accent", "000")};
        cursor: pointer;
      }

      &:active {
        background-color: ${buildColor("blue_accent", "100")};
      }
    `};
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${buildColor("blue", "000")};
    `};
`;

export const HorizontalRule = styled.hr`
  height: 1px;
  margin: 0;
  border: none;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 12px;
  background-color: ${buildColor("blue", "100")};
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 18px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
  margin-right: 8px;
  flex: 1;
  overflow: hidden;
`;

const sharedTextStyles = css`
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

export const Title = styled.span`
  ${sharedTextStyles};
  display: inline;
  align-items: center;
  margin-bottom: 4px;
  ${({ disabled }) =>
    disabled
      ? css`
          color: ${buildColor("blue", "300")};
        `
      : css`
          color: ${buildColor("grey", "900")};
        `}

  ${({ shouldCapitalize }) =>
    shouldCapitalize &&
    css`
      text-transform: capitalize;
    `};
`;

export const Description = styled.span`
  ${sharedTextStyles};
  ${({ disabled }) =>
    disabled
      ? css`
          color: ${buildColor("blue", "300")};
        `
      : css`
          color: ${buildColor("grey", "800")};
        `}
`;

export const CheckIconContainer = styled.span`
  display: flex;
  align-self: center;
`;

export const TagsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;

  & > div:not(:last-child) {
    margin-right: 4px;
  }
`;
