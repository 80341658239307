import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  padding: 12px;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colorTokens.border.default}`};
  background-color: ${({ theme }) => theme.colors.white["900"]};
  min-height: 52px;
  min-width: 85px;
  max-width: 191px;
  max-height: 96px;
  width: fit-content;
  height: fit-content;
`;

export const RaceCellLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
