import React from "react";
import {
  closeCardDeclinedModal,
  setDeclinedAmountCount
} from "@tvg/sh-lib-paws/redux/slices/cardDeclinedModalSlice";
import { get } from "lodash";
import { batch } from "react-redux";
import { closeCreatePMModal } from "@tvg/sh-lib-paws/redux/slices/createPMModalSlice";
import { closeDepositFundsModal } from "@tvg/sh-lib-paws/redux/slices/depositFundsModalSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { goToWallet } from "@tvg/utils/routeRedirectUtils";
import { History } from "@tvg/ts-types/History";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import { toggleQuickDepositModal } from "@tvg/sh-lib-paws/redux/slices/quickDepositModalSlice";
import {
  checkCardLimit,
  getPaymentMethod,
  redirectToNewCard,
  desktopNavigation
} from "../../utils";
import {
  CreatePMToggles,
  PaymentType,
  PaymentTypeItem,
  PaymentTypeOption
} from "../../types";
import { gtmNavigateDepositWithdrawOptions } from "../../gtm";

export const onClose = (dispatch: Dispatch) => {
  dispatch(closeCardDeclinedModal());
};

export const onSubmit = (
  dispatch: Dispatch,
  history: History,
  selectedMethod: PaymentType,
  paymentTypeOptions: PaymentTypeOption[] | null,
  pmToggles: CreatePMToggles,
  isPaypalOnFile: boolean,
  accountsOnFile: PaymentTypeItem[],
  mainMethods: PaymentTypeItem[],
  event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  isQuickDeposit?: boolean,
  isDesktop?: boolean
) => {
  onClose(dispatch);
  batch(() => {
    dispatch(closeCreatePMModal());
    dispatch(toggleQuickDepositModal(false));
    dispatch(closeDepositFundsModal(true));
  });

  if (isQuickDeposit && history) {
    goToWallet(history, isDesktop);
  }

  const isCardLimitNotReached = checkCardLimit(
    selectedMethod,
    paymentTypeOptions,
    dispatch
  );

  if (!get(pmToggles, `${selectedMethod}`, false)) {
    gtmNavigateDepositWithdrawOptions("deposit", selectedMethod);
    const paymentId = get(paymentTypeOptions, "[0].id", "");
    desktopNavigation({
      event,
      paymentType: selectedMethod,
      navigationType: "deposit",
      paymentId,
      newPayment: false,
      dispatch,
      history
    });
    return;
  }

  if (isCardLimitNotReached) {
    setTimeout(() => {
      redirectToNewCard(
        selectedMethod,
        pmToggles,
        dispatch,
        getPaymentMethod(
          selectedMethod,
          isPaypalOnFile && selectedMethod === PaymentTypeEnum.PAYPAL
            ? accountsOnFile
            : mainMethods
        )
      );
      dispatch(setDeclinedAmountCount());
    }, 500);
  }
};
