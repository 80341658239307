import React, { FC } from "react";

export const UnitedArabEmirates: FC = () => (
  <>
    <g clipPath="url(#clip0_18106_3010)">
      <path d="M8.96731 0H0V20H8.96731V0Z" fill="#D22839" />
      <path d="M32 0H8.96732V6.66667H32V0Z" fill="#1BB152" />
      <path d="M32 6.66673H8.96729V13.2685H32V6.66673Z" fill="#F7FBFF" />
      <path d="M32 13.2684H8.96729V20H32V13.2684Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_18106_3010">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V18C32
          19.1046 31.1046 20 30 20H2C0.895431 20 0 19.1046 0 18V2Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </>
);
