import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDebounce } from "../../../hooks";
import { Layout, Placement } from "../types";

export interface GetCoordinates {
  triggerLayout: Layout;
  placement: Placement;
  windowWidth: number;
  windowHeight: number;
}

interface Coordinates {
  top: number;
  left?: number;
  right?: number;
}

const DEFAULT_COORDINATES: Coordinates = {
  left: 0,
  top: 0
};

const useGetCoordinates = ({
  triggerLayout,
  placement,
  windowWidth,
  windowHeight
}: GetCoordinates) => {
  const {
    x: triggerX = 0,
    y: triggerY = 0,
    width: triggerWidth,
    height: triggerHeight
  } = triggerLayout;

  const [coordinates, setCoordinates]: [
    Coordinates,
    Dispatch<SetStateAction<Coordinates>>
  ] = useState(DEFAULT_COORDINATES);

  const [isReady, setIsReady]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState(false as boolean);
  const debounce = useDebounce();

  const getHorizontalCoordinates = (contentWidth: number) => {
    if (triggerWidth >= contentWidth) {
      return triggerX + (triggerWidth - contentWidth) / 2;
    }
    return triggerX - contentWidth / 2 + triggerWidth / 2;
  };

  const getVerticalCoordinates = (contentHeight: number) => {
    if (triggerHeight >= contentHeight) {
      return triggerY + (triggerHeight - contentHeight) / 2;
    }
    return triggerY - contentHeight / 2 + triggerHeight / 2;
  };

  const updateCoordinates = (contentWidth: number, contentHeight: number) => {
    const newCoordinates: Coordinates = { top: triggerY };

    // Calculates the initial position based on placement
    switch (placement) {
      case "top-left":
        newCoordinates.top -= contentHeight;
        newCoordinates.left = triggerX;
        break;
      case "top-mid":
        newCoordinates.top -= contentHeight;
        newCoordinates.left = getHorizontalCoordinates(contentWidth);
        break;
      case "top-right":
        newCoordinates.top -= contentHeight;
        newCoordinates.left = triggerX + triggerWidth - contentWidth;
        break;
      case "left":
        newCoordinates.top = getVerticalCoordinates(contentHeight);
        newCoordinates.left = triggerX - contentWidth;
        break;
      case "right":
        newCoordinates.top = getVerticalCoordinates(contentHeight);
        newCoordinates.left = triggerX + triggerWidth;
        break;
      case "bottom-left":
        newCoordinates.top += triggerHeight;
        newCoordinates.left = triggerX;
        break;
      case "bottom-mid":
        newCoordinates.top += triggerHeight;
        newCoordinates.left = getHorizontalCoordinates(contentWidth);
        break;
      case "bottom-right":
        newCoordinates.top += triggerHeight;
        newCoordinates.left = triggerX + triggerWidth - contentWidth;
        break;
      case "drop-down":
        newCoordinates.top += triggerHeight;
        newCoordinates.left = triggerX;
        break;
      default:
        break;
    }

    // It verifies if the tooltip is going out of viewport

    // Adjusts the min margin to the left to avoid leaking on the left side
    if (newCoordinates.left && newCoordinates.left < 0) {
      newCoordinates.left = 10; // minimum margin on the left
    }

    // adjusts to not allow going further viewport width (cannot go out on the right side)
    if (
      newCoordinates.left &&
      newCoordinates.left + contentWidth > windowWidth
    ) {
      newCoordinates.left = windowWidth - contentWidth - 10; // minimum margin on the right
    }

    // Adjusts the min margin to the top to avoid leaking on the top
    if (newCoordinates.top < 0) {
      newCoordinates.top = 10; // minimum margin on the top
    }

    // adjusts to not allow going further viewport height (cannot go out on the bottom)
    if (newCoordinates.top + contentHeight > windowHeight) {
      newCoordinates.top = windowHeight - contentHeight - 10; // minimum margin at the bottom
    }

    setCoordinates(newCoordinates);
  };

  // use debounce since onLayout is triggered as the Content changes position
  // and we only want to set ready on the last position update
  useEffect(
    debounce(() => setIsReady(true), 32),
    [coordinates.left, coordinates.top]
  );

  useEffect(
    () => () => {
      setIsReady(false);
    },
    []
  );

  return {
    style: { ...coordinates },
    isReady,
    updateCoordinates
  };
};

export default useGetCoordinates;
