import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Announcements = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.625 10.8194V13.1806C2.625 14.2984 3.44554 15.2467 4.55169
      15.4073L5.625 15.5631L13.875 16.761L21.375 17.85V6.15001L4.55169
      8.59275C3.44554 8.75337 2.625 9.70165 2.625 10.8194Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M21.375 6.15001V17.85M21.375 6.15001V3M21.375 6.15001L4.55169
      8.59275C3.44554 8.75337 2.625 9.70165 2.625 10.8194V13.1806C2.625
      14.2984 3.44554 15.2467 4.55169 15.4073M21.375 17.85V21M21.375
      17.85L13.875 16.761M21.375 17.85L4.55169 15.4073M5.625
      15.5631V19.875H13.875V16.761M5.625 15.5631L4.55169 15.4073M5.625
      15.5631L13.875 16.761"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);

export default Announcements;
