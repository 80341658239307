import React from "react";
import { Container } from "./styled-components";
import { IconGroupProps } from "../../types";
import { Icon } from "../../../Icon";
import { IconProps } from "../../../Icon/types";
import Text from "../Text";
import { useColorTokens } from "../../../../hooks";
import { buildQALabel } from "../../utils";
import { CONTAINER, GROUP, ICON } from "../../constants";

const IconGroup = ({
  hasTalentPicksAvailable,
  hasPromosAvailable,
  isOptedIn,
  numberActiveBets,
  qaLabel = ""
}: IconGroupProps) => {
  const colorTokens = useColorTokens();

  const renderer: IconProps[] = [];

  // TODO: switch to colorTokens
  if (hasTalentPicksAvailable)
    renderer.push({
      name: "tvgPicks"
    } as IconProps);
  if (isOptedIn)
    renderer.push({
      name: "optedIn",
      lineColor: "content.positive",
      backgroundColor: "background.positiveSubtle"
    } as IconProps);
  else if (hasPromosAvailable)
    renderer.push({
      name: "promo",
      lineColor: "content.alert",
      backgroundColor: "background.alertSubtle"
    } as IconProps);

  if (numberActiveBets)
    renderer.push({
      name: "bets"
    } as IconProps);

  if (!renderer.length) return null;

  return (
    <Container data-qa-label={buildQALabel([qaLabel, ICON, GROUP, CONTAINER])}>
      {renderer.map((config) => (
        <Icon
          key={buildQALabel([qaLabel, ICON, GROUP, config.name])}
          qaLabel={buildQALabel([qaLabel, ICON, GROUP, config.name])}
          // @ts-ignore
          lineColor="content.subtle"
          {...config}
          size="s"
        />
      ))}
      {renderer.length < 3 && !!numberActiveBets && (
        <Text
          qaLabel={buildQALabel([qaLabel, ICON, GROUP])}
          color={colorTokens.content.neutral}
          fontFamily="condensedRegular"
        >
          {numberActiveBets}
        </Text>
      )}
    </Container>
  );
};

export default IconGroup;
