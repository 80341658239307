import { createElement } from "react";
import { Device } from "@tvg/ts-types/Device";
import PromoPreviewComponent from "../components/preview";
import PromoProgressComponent from "../components/progress";
import { InternalComponentNames } from "../types";
import {
  GenericProps,
  PreviewProps,
  ProgressProps
} from "../components/sharedComponentTypes";

export const getInternalComponent = (
  componentName: InternalComponentNames,
  device: Device = Device.MOBILE,
  options: Partial<GenericProps> = {}
) => {
  switch (componentName) {
    case InternalComponentNames.PROGRESS: {
      const { isDarkMode, isHomePageSection } = options as ProgressProps;
      return createElement(PromoProgressComponent, {
        isDarkMode: !!isDarkMode,
        device,
        isHomePageSection: !!isHomePageSection
      });
    }
    case InternalComponentNames.PREVIEW: {
      const { previewContentId } = options as PreviewProps;
      return createElement(PromoPreviewComponent, {
        previewContentId
      });
    }
    default: {
      return null;
    }
  }
};

export default getInternalComponent;
