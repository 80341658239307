import styled, { css } from "styled-components";
import { layout, position, space } from "styled-system";
import {
  WebScrollbarProps,
  OverflowProps,
  ScrollOrientation,
  ScrollContainerProps,
  ShadowWrapperProps
} from "./types";

const scrollStyle = ({ scrollOrientation, overflow }: OverflowProps) => {
  const orientation = {
    horizontal: css`
      overflow-x: ${overflow};
      overflow-y: hidden;
    `,
    vertical: css`
      overflow-y: ${overflow};
      overflow-x: hidden;
    `,
    both: css`
      overflow-x: ${overflow};
      overflow-y: ${overflow};
    `
  };
  return orientation[scrollOrientation as ScrollOrientation];
};

export const ScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
`;

export const ScrollbarContainer = styled.div<
  Pick<
    WebScrollbarProps,
    | "width"
    | "height"
    | "isDark"
    | "scrollOrientation"
    | "overflow"
    | "bg"
    | "scrollInternalPadding"
  > &
    ScrollContainerProps
>`
  background-color: ${({ bg }) => bg};
  ${space}
  padding-bottom:${({ scrollX, scrollInternalPadding }) =>
    scrollX ? `${scrollInternalPadding}px` : "0"};
  padding-right: ${({ scrollY, scrollInternalPadding }) =>
    scrollY ? `${scrollInternalPadding}px` : "0"};
  ${layout}
  ${({ scrollOrientation = "vertical", overflow = "auto" }) =>
    scrollStyle({ scrollOrientation, overflow })}
  ${position}
  
    /* width */
  
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: ${({ isDark }) =>
      isDark ? "rgba(255, 255, 255, 0.5)" : "rgba(56, 64, 72, 0.5)"};
    border-radius: 12px;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ isDark }) =>
      isDark ? "rgba(255, 255, 255, 0.8)" : "rgba(56, 64, 72, 0.8)"};
  }
`;

const shadowTop = css<ShadowWrapperProps>`
  &::before {
    content: "";
    box-shadow: inset 0 3px 3px rgba(17, 43, 68, 0.25);
    display: flex;
    height: 16px;
    left: -2px;
    pointer-events: none;
    position: absolute;
    width: calc(${({ width }) => width || "100%"} + 4px);
    z-index: 1000;
    overflow: hidden;
  }
`;

const shadowBottom = css<ShadowWrapperProps>`
  &::after {
    content: "";
    box-shadow: inset 0 -3px 3px rgba(17, 43, 68, 0.25);
    display: flex;
    height: 16px;
    left: -2px;
    margin-top: -16px;
    pointer-events: none;
    position: absolute;
    width: calc(${({ width }) => width || "100%"} + 4px);
    z-index: 1000;
  }
`;

export const ShadowWrapper = styled.div<ShadowWrapperProps>`
  ${space}
  ${layout}
  max-height: ${({ maxHeight }) => maxHeight};
  ${({ scrollPosition, scrollY }) =>
    (scrollPosition === "bottom" || scrollPosition === "mid") && scrollY
      ? shadowTop
      : ""}

  ${({ scrollPosition, scrollY }) =>
    (scrollPosition === "top" || scrollPosition === "mid") && scrollY
      ? shadowBottom
      : ""}
`;
