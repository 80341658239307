import styled from "styled-components";

export const Slider = styled.div<{ isDisabled?: boolean }>`
  position: absolute;
  border-radius: 68px;
  inset: 4px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%);
  background-color: ${({ isDisabled }) =>
    isDisabled
      ? "transparent"
      : "var(--fd-colors-component-selectionControl-background-base, #fff)"};
`;
