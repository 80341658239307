import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const BetsRefund = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M18.375 2.625H5.625C4.79657 2.625 4.125 3.29657 4.125 4.125V21.75L7.5
      20.25L9.75 21.75L12 20.25L14.25 21.75L16.5 20.25L19.875 21.75V4.125C19.875
      3.29657 19.2034 2.625 18.375 2.625Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M12 20.25L9.75 21.75L7.5 20.25L4.125 21.75V4.125C4.125 3.29657 4.79657
      2.625 5.625 2.625H18.375C19.2034 2.625 19.875 3.29657 19.875
      4.125V11.9585M7.5 8.625H12M7.5 13.125H13.5M18.375 14.25L14.625 18L18.375
      21.75M22.875 18H14.6953"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
export default BetsRefund;
