import React, { useMemo } from "react";
import { ButtonAction } from "@tvg/wallet/src/types";
import { useSelector } from "react-redux";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import { getPawsContent } from "@tvg/sh-lib-paws/redux/selectors";
import InfoMessage from "../info";
import { getParsedDescription } from "../../common/utils";
import type { BalanceProps } from "./types";
import type { WithdrawalBalanceModalProps } from "../../common/types";

const Balance = ({
  pBalance,
  onClose,
  isOpen,
  wBalance,
  onContact
}: BalanceProps) => {
  const { withdrawalBalanceModal }: WithdrawalBalanceModalProps =
    useSelector(getPawsContent);

  const actions: ButtonAction[] = useMemo(
    () => [
      {
        text: withdrawalBalanceModal.okBtnText,
        onClick: onClose,
        isStreched: true,
        isUppercase: false,
        qaLabel: "ok-btn"
      },
      {
        text: withdrawalBalanceModal.supportBtnText,
        onClick: onContact,
        isStreched: true,
        isUppercase: false,
        qaLabel: "contact-btn",
        type: "tertiary"
      }
    ],
    []
  );

  const parsedPBalance = useMemo(
    () =>
      getParsedDescription(
        withdrawalBalanceModal.pBalanceDescription,
        "pBalance",
        pBalance
      ),
    [pBalance]
  );

  const parsedWBalance = useMemo(
    () =>
      getParsedDescription(
        withdrawalBalanceModal.wBalanceDescription,
        "wBalance",
        wBalance
      ),
    [pBalance]
  );

  const description: string[] = useMemo(
    () => [parsedWBalance, "<br />", parsedPBalance],
    [parsedPBalance]
  );

  return (
    <ModalV2
      isOpen={isOpen}
      animation="fade"
      hasRoundedCorners
      isContentTransparent
      isFullHeight={false}
      hasHeader={false}
      isFullWidth={false}
      fixedWidth="351px"
      qaLabel="playable-balance-modal"
      onClose={onClose}
    >
      {() => (
        <InfoMessage
          actions={actions}
          description={description}
          title={withdrawalBalanceModal.balanceTitle}
          onClose={onClose}
        />
      )}
    </ModalV2>
  );
};

export default React.memo(Balance);
