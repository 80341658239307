import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "redux";
import { get } from "lodash";
import {
  getPawsContent,
  getAccountsOnFileMethods,
  getActivePMCreate,
  getPawsLimits,
  getMainMethods,
  getCreditDebitCardCreationMessage,
  getCardDeclinedContent,
  getCardDeclinedToggle,
  getIsFirstDepositDone
} from "@tvg/sh-lib-paws/redux/selectors";
import { getAccountNumber, getBalance } from "@urp/store-selectors";
import { SegmentedControl, SegmentedControlTab } from "@tvg/design-system/web";
import { getMazoomaToggle } from "@tvg/sh-lib-paws/redux/toggles";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import formatCurrency from "@tvg/formatter/currency";
import { useNavigate } from "@tvg/custom-hooks";
import {
  matchDepositPropositions,
  getCustomAmount
} from "@tvg/sh-lib-promos-onboarding/utils/matchDepositPropositions";
import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import PromosOnboarding from "@tvg/promos-onboarding";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import CustomKeyboard from "@tvg/atomic-ui/_molecule/CustomKeyboard";
import { CVV, Promo } from "@tvg/atomic-ui/_static/Icons/paws";
import MoneyPills from "@tvg/atomic-ui/_molecule/MoneyPills";
import { isDesktop, isMobile } from "../../../utils";
import { useFee, useForm } from "./hooks";
import InputValue from "../../FundsModal/common/InputValue";
import InputField from "../../FundsModal/common/InputField";
import Button from "../../FundsModal/common/Button";
import { getSelectionEvent } from "../../FundsModal/common/utils";
import Flag from "../../Flag";
import AlertMessagesModal from "../../AlertMessagesModal";
import MessagePanel from "../../MessagePanel";
import {
  PawsContent,
  ErrorMessage,
  ButtonAction,
  PaymentTypeOption,
  FundsAmountInputChangingFrom,
  CreditDebitCardCreation,
  CardDeclined,
  ActiveCreatePm,
  MainMethods,
  PawsLimits
} from "../../../types";
import { Props, ACTION_IS_CVV_INFO_OPEN } from "./types";
import {
  Container,
  ModalWrapper,
  ValueContainer,
  FlagsContainer,
  FormCard,
  Row,
  Col,
  ButtonContainer,
  Divider,
  PromosOnboardingFlagWrapper,
  CardTypeLabel
} from "./styled-components";
import { gtmDepositDefaultSelect } from "../../../gtm";
import {
  desktopEditCardExpiration,
  desktopEditCardNumber,
  getUpdateFieldOnFocus,
  handleOnClose,
  onCardTypeClick,
  onChangeValue,
  onFeeModalClick,
  onIconClick,
  onSubmitHandler
} from "./eventHandlers";
import {
  getState,
  handleAmountFieldSelection,
  handleCloseError,
  onAddPeriod,
  onClearValue,
  handleRetry,
  resetAllFields,
  removeVisitedField,
  addVisitedField,
  getMinValue,
  getMaxValue,
  getSuccessMessageActions,
  getCvvInfoActions,
  getErrorMessageActions,
  getSuccessMessageDescription,
  getSetupEcheckActions,
  getFlagWarningText
} from "./methods";
import { renderAddressConfirmationModal, renderSummary } from "./renderer";

const CreateCCPModal = ({
  isOpen,
  minLimit = 0,
  maxLimit = 10000000,
  onCloseCallback,
  refetchAvailableMethods,
  promosOnboarding,
  isUserInPromoOnboarding,
  promoOnboardingStepsNumber,
  promoOnboardingSteps,
  promoOnboardingActions,
  promoOnboardingCustomLabel = () => "",
  userHasBetPlaced
}: Props) => {
  // USE SELECTOR
  const accountId = useSelector<Store, string>(getAccountNumber);
  const userBalance = useSelector<Store, number>(getBalance);
  const contentPaws = useSelector<Store, PawsContent>(getPawsContent);
  const content = useSelector<Store, CreditDebitCardCreation>(
    getCreditDebitCardCreationMessage
  );
  const accountsOnFile = useSelector<Store, PaymentTypeOption[]>(
    getAccountsOnFileMethods
  );
  const { activeCreatePM } = useSelector<Store, ActiveCreatePm>(
    getActivePMCreate
  );
  const { mainMethods } = useSelector<Store, MainMethods>(getMainMethods);
  const limits = useSelector<Store, PawsLimits>(getPawsLimits);

  const isCardDeclinedToggleOn = useSelector<Store, boolean>(
    getCardDeclinedToggle
  );
  const cardDeclinedContent = useSelector<Store, CardDeclined>(
    getCardDeclinedContent
  );
  const isMZMToggleOn = useSelector<Store, boolean>(getMazoomaToggle);
  const isFirstDepositDone = useSelector<Store, boolean>(getIsFirstDepositDone);

  // VARIABLES
  const min = getMinValue(limits, minLimit);
  const max = getMaxValue(limits, maxLimit);
  const title = get(content, "title", "Deposit with new card");
  const errorMessageInitialState = {
    isOpen: false,
    errorCode: "",
    type: "error" as "success" | "error" | "warning",
    title: contentPaws.paymentMessageModal.error.title,
    description: contentPaws.paymentMessageModal.error.description,
    actions: [] as Array<ButtonAction>,
    isRetry: false,
    retryCount: 0
  };

  const extraModalProps = !isMobile
    ? {
        isFullHeight: false,
        animation: "fade",
        fixedWidth: "399px",
        hasRoundedCorners: true,
        isFluidWidth: true
      }
    : {
        animation: "bottom",
        hasRoundedCorners: false
      };

  const feeModalContent = isMZMToggleOn
    ? "depositMzmFeeModal"
    : "depositFeeModal";

  // USE STATE
  const [addressConfirmationOpen, setAddressConfirmationState] =
    useState(false);
  const [selectedField, setSelectedField] = useState("amount-field");
  const [visitedFields, setVisitedFields] = useState(new Set<string>());
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(
    errorMessageInitialState
  );

  // HOOKS
  const history = useNavigate();
  const dispatch = useDispatch();
  const [feeValue, getFeeValue] = useFee();
  const {
    formState: {
      amount,
      cardCVV,
      cardExp,
      cardNum,
      cardType,
      isLoading,
      isFeeInfoOpen,
      showSuccessMessage,
      isCVVInfoOpen
    },
    formDispatch,
    showAmountWarning,
    expErrMsg,
    numErrMsg,
    cvvErrMsg,
    hasError
  } = useForm(
    min,
    max,
    visitedFields,
    selectedField,
    removeVisitedField(setVisitedFields),
    {
      content: { ...contentPaws, ...content }
    }
  );

  const getStateByActiveField = useCallback(
    getState(
      formDispatch,
      accountId,
      min,
      max,
      amount,
      selectedField,
      cardNum,
      cardExp,
      cardCVV,
      getFeeValue
    ),
    [selectedField, amount, cardNum, cardExp, cardCVV, accountId, max, min]
  );

  useEffect(() => {
    if (!isOpen) {
      resetAllFields(
        setAddressConfirmationState,
        setSelectedField,
        setVisitedFields,
        formDispatch,
        setErrorMessage,
        errorMessageInitialState,
        true
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (errorMessage.isRetry) {
      onSubmit();
    }
  }, [errorMessage]);

  const onSubmit = useCallback(
    onSubmitHandler(
      showAmountWarning,
      formDispatch,
      dispatch,
      accountId,
      amount,
      cardExp,
      cardNum,
      cardCVV,
      cardType,
      refetchAvailableMethods,
      cardDeclinedContent,
      isCardDeclinedToggleOn,
      setErrorMessage,
      !isFirstDepositDone
    ),
    [
      showAmountWarning,
      formDispatch,
      dispatch,
      accountId,
      amount,
      cardExp,
      cardNum,
      cardCVV,
      cardType,
      refetchAvailableMethods,
      cardDeclinedContent,
      isCardDeclinedToggleOn,
      setErrorMessage
    ]
  );

  const promoOnboardingMatchDepositProp = matchDepositPropositions(
    promosOnboarding,
    cardType,
    +amount
  );

  const promoOnboardingCustomAmount =
    (promosOnboarding &&
      getCustomAmount(promosOnboarding, cardType, +amount)) ||
    0;

  return (
    <>
      <ModalV2
        isOpen={isOpen}
        title={title}
        qaLabel="create-ccp-modal"
        subtitle={`BALANCE ${formatCurrency(userBalance)}`}
        hasHeader={!showSuccessMessage}
        offsetLeft={0}
        offsetRight={0}
        offsetTop={44}
        onClose={handleOnClose(onCloseCallback, activeCreatePM, accountId)}
        onOverlayClick={onCloseCallback}
        isContentTransparent
        useModalHeaderV3
        isTitleCenter
        isFullWidth
        {...extraModalProps}
      >
        {() => (
          <ModalWrapper>
            {!showSuccessMessage && (
              <Container>
                <PromosOnboarding
                  placeToRender={StoryblokPlaceName.WALLET_DEPOSIT_TOP}
                  isShown={matchDepositPropositions(promosOnboarding, cardType)}
                />
                <ValueContainer>
                  <InputValue
                    isLoading={isLoading}
                    showAmountWarning={showAmountWarning}
                    value={amount}
                    isDesktop={isDesktop}
                    onChangeValue={onChangeValue(
                      isLoading,
                      getStateByActiveField()
                    )}
                    hasFocus={selectedField === "amount-field" && isOpen}
                    {...getSelectionEvent(
                      handleAmountFieldSelection(
                        setSelectedField,
                        addVisitedField(setVisitedFields)
                      )
                    )}
                  />
                  <FlagsContainer>
                    {!showAmountWarning && +feeValue > 0 && (
                      <Flag
                        qaLabel="fee-value"
                        className="flag"
                        text={`+ ${formatCurrency(+feeValue)} FEE`}
                        variant="fee"
                        showIcon={false}
                      />
                    )}
                    {showAmountWarning && (
                      <Flag
                        qaLabel="warning-value"
                        className="flag"
                        text={getFlagWarningText(amount, min, max, content)}
                      />
                    )}
                    {promosOnboarding && !!promoOnboardingCustomAmount && (
                      <PromosOnboardingFlagWrapper>
                        <PromosOnboarding
                          placeToRender={StoryblokPlaceName.WALLET_DEPOSIT_TAG}
                          customMessage={promoOnboardingCustomLabel(
                            promoOnboardingCustomAmount
                          )}
                          isShown={promoOnboardingMatchDepositProp}
                        />
                      </PromosOnboardingFlagWrapper>
                    )}
                  </FlagsContainer>
                </ValueContainer>
                {isDesktop && selectedField === "amount-field" && (
                  <MoneyPills
                    disabled={isLoading}
                    className="moneyPills"
                    onChangeValue={(keyValue: string) => {
                      onChangeValue(isLoading, getStateByActiveField())(
                        keyValue,
                        "moneyPills"
                      );
                      gtmDepositDefaultSelect(activeCreatePM, accountId);
                    }}
                  />
                )}
                <FormCard>
                  {isDesktop && <Divider />}
                  <Row>
                    <Col>
                      <CardTypeLabel data-qa-label="card-type-selector-label">
                        Card Type
                      </CardTypeLabel>
                      <SegmentedControl
                        selectedValue={cardType === "CC" ? "CC" : "DEB"}
                        qaLabel="card-type"
                        size="m"
                      >
                        <SegmentedControlTab
                          value="CC"
                          qaLabel="card-type-credit"
                          onClick={() => onCardTypeClick(formDispatch)("CC")}
                        >
                          Credit
                        </SegmentedControlTab>
                        <SegmentedControlTab
                          value="DEB"
                          qaLabel="card-type-debit"
                          onClick={() => onCardTypeClick(formDispatch)("DEB")}
                        >
                          Debit
                        </SegmentedControlTab>
                      </SegmentedControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputField
                        value={cardNum}
                        label="Debit or Credit Number"
                        onChangeValue={onChangeValue(
                          isLoading,
                          getStateByActiveField()
                        )}
                        isDisabled={isLoading}
                        placeholder="0000 0000 0000 0000"
                        className="card-number"
                        isDesktop={isDesktop}
                        hasFocus={selectedField === "number-field" && isOpen}
                        error={
                          numErrMsg && selectedField !== "number-field"
                            ? numErrMsg
                            : undefined
                        }
                        onFocus={getUpdateFieldOnFocus(
                          "number",
                          isLoading,
                          setSelectedField,
                          addVisitedField(setVisitedFields)
                        )}
                        onPasteHandler={desktopEditCardNumber(
                          cardNum,
                          isLoading,
                          formDispatch
                        )}
                        showLabelOnTop
                        maxValidLimit={20}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputField
                        value={cardExp}
                        label="Expiration Date"
                        onChangeValue={onChangeValue(
                          isLoading,
                          getStateByActiveField()
                        )}
                        isDisabled={isLoading}
                        placeholder="MM/YY"
                        className="card-expiration"
                        isDesktop={isDesktop}
                        hasFocus={selectedField === "expire-field" && isOpen}
                        error={
                          expErrMsg && selectedField !== "expire-field"
                            ? expErrMsg
                            : undefined
                        }
                        onFocus={getUpdateFieldOnFocus(
                          "expire",
                          isLoading,
                          setSelectedField,
                          addVisitedField(setVisitedFields)
                        )}
                        onPasteHandler={desktopEditCardExpiration(
                          cardExp,
                          isLoading,
                          formDispatch
                        )}
                        showLabelOnTop
                      />
                    </Col>
                    <Col>
                      <InputField
                        value={cardCVV}
                        label="CVV"
                        onChangeValue={onChangeValue(
                          isLoading,
                          getStateByActiveField()
                        )}
                        isDisabled={isLoading}
                        className="card-cvv"
                        isDesktop={isDesktop}
                        hasFocus={selectedField === "cvv-field" && isOpen}
                        error={
                          cvvErrMsg && selectedField !== "cvv-field"
                            ? cvvErrMsg
                            : undefined
                        }
                        icon="cvv"
                        placeholder="3 digits"
                        onClickIcon={onIconClick(formDispatch)}
                        onFocus={getUpdateFieldOnFocus(
                          "cvv",
                          isLoading,
                          setSelectedField,
                          addVisitedField(setVisitedFields)
                        )}
                        showLabelOnTop
                      />
                    </Col>
                  </Row>
                </FormCard>
                {!isLoading && !isDesktop && (
                  <CustomKeyboard
                    className="custom-keyboard"
                    onDelete={onClearValue(
                      selectedField,
                      getStateByActiveField()
                    )}
                    onPeriod={onAddPeriod(formDispatch, amount, selectedField)}
                    showMoneyPills={selectedField === "amount-field"}
                    onClickMoneyPills={() =>
                      gtmDepositDefaultSelect(activeCreatePM, accountId)
                    }
                    onChangeValue={(
                      keyValue: number,
                      from: FundsAmountInputChangingFrom
                    ) =>
                      onChangeValue(isLoading, getStateByActiveField())(
                        keyValue,
                        selectedField === "amount-field" && from === "mobile"
                          ? "mobileAmountField"
                          : from
                      )
                    }
                  />
                )}
                {isDesktop && renderSummary(amount, feeValue)}
                <ButtonContainer>
                  <Button
                    text={
                      +amount
                        ? `Authorize ${formatCurrency(
                            +amount + +feeValue
                          )} transaction`
                        : "Authorize transaction"
                    }
                    onClick={() => setAddressConfirmationState(true)}
                    isLoading={isLoading}
                    hasError={hasError}
                  />
                </ButtonContainer>
              </Container>
            )}
            {showSuccessMessage && (
              <MessagePanel
                title={contentPaws.createCreditDebitCardSuccessMessage.title}
                description={getSuccessMessageDescription(
                  contentPaws,
                  amount,
                  userBalance
                )}
                variant="success"
                actions={getSuccessMessageActions(onCloseCallback)}
                className="success-message"
                isUserInPromoOnboarding={isUserInPromoOnboarding}
                promoOnboardingStepsNumber={promoOnboardingStepsNumber}
                promoOnboardingSteps={promoOnboardingSteps}
                promoOnboardingActions={
                  promoOnboardingActions
                    ? promoOnboardingActions(onCloseCallback)
                    : []
                }
                bonusAmount={promoOnboardingCustomAmount}
                hasMatchingProposition={promoOnboardingMatchDepositProp}
                userHasBetPlaced={userHasBetPlaced}
              />
            )}
          </ModalWrapper>
        )}
      </ModalV2>
      {isCVVInfoOpen && (
        <AlertMessagesModal
          title={contentPaws.deposit.CC.ccInfo.title}
          description={contentPaws.deposit.CC.ccInfo.description}
          icon={<CVV width="62" height="48" />}
          isOpen={isCVVInfoOpen}
          onCloseMethod={() =>
            formDispatch({
              type: ACTION_IS_CVV_INFO_OPEN,
              payload: { status: false }
            })
          }
          isMobile={isMobile}
          actions={getCvvInfoActions(formDispatch)}
        />
      )}
      {errorMessage.isOpen && (
        <AlertMessagesModal
          type={errorMessage.type || "error"}
          isOpen={errorMessage.isOpen}
          errorCode={errorMessage.errorCode}
          isMobile={isMobile}
          onCloseMethod={handleCloseError(setErrorMessage)}
          onRetryMethod={handleRetry(setErrorMessage, errorMessage)}
          onContactMethod={() => openLiveChat()}
          retryCount={errorMessage.retryCount}
          actions={getErrorMessageActions(handleCloseError(setErrorMessage))}
        />
      )}
      <AlertMessagesModal
        title={contentPaws[`${feeModalContent}`].title}
        description={contentPaws[`${feeModalContent}`].description}
        icon={<Promo />}
        isOpen={isFeeInfoOpen}
        onCloseMethod={onFeeModalClick(formDispatch)}
        isMobile={isMobile}
        actions={getSetupEcheckActions(
          contentPaws[`${feeModalContent}`].buttonText,
          onCloseCallback,
          formDispatch,
          dispatch,
          accountsOnFile,
          isMZMToggleOn,
          mainMethods,
          history
        )}
      />

      {addressConfirmationOpen &&
        renderAddressConfirmationModal(
          addressConfirmationOpen,
          formDispatch,
          setAddressConfirmationState,
          setSelectedField,
          setVisitedFields,
          setErrorMessage,
          errorMessageInitialState,
          accountId,
          onSubmit
        )}
    </>
  );
};

export default CreateCCPModal;
