import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  FC
} from "react";

import { Context } from "../types";
import popperReducer, { INITIAL_STATE } from "./popperReducer";

const PopperContext = createContext<Context>([INITIAL_STATE, () => {}]);

const PopperProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(popperReducer, INITIAL_STATE);

  const context: Context = useMemo(() => [state, dispatch], [state, dispatch]);

  return (
    <PopperContext.Provider value={context}>{children}</PopperContext.Provider>
  );
};

export const usePopperContext = () => useContext(PopperContext);

export default PopperProvider;
