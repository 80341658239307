import tvgConf from "@tvg/conf";

const breakpoints = {
  desktop: {
    min: {
      sm: "(min-width: 920px)",
      md: "(min-width: 1024px)",
      lg: "(min-width: 1152px)",
      xl: "(min-width: 1255px)",
      xxl: "(min-width: 1364px)"
    },
    max: {
      sm: "(max-width: 919.98px)",
      md: "(max-width: 1023.98px)",
      lg: "(max-width: 1151.98px)",
      xl: "(max-width: 1254.98px)",
      xxl: "(max-width: 1363.98px)"
    }
  },
  tablet: {
    min: {
      sm:
        tvgConf().product === "tvg5" // preventing responsive components on desktop
          ? "(min-width: 1px)"
          : "(min-width: 768px)",
      md: "(min-width: 1194px)",
      lg: "(min-width: 1366px)"
    },
    max: {
      sm:
        tvgConf().product === "tvg5" // preventing responsive components on desktop
          ? "(max-width: 1px)"
          : "(max-width: 767.98px)",
      md: "(max-width: 1193.98px)",
      lg: "(max-width: 1365.98px)"
    }
  }
};

export default breakpoints;
