import React, { memo } from "react";
import { Store } from "redux";
import { useSelector } from "react-redux";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import { getPawsContent } from "@tvg/sh-lib-paws/redux/selectors";
import { useErrorMessageBuilder } from "@tvg/sh-lib-paws/hooks/useErrorMessageBuilder";
import { IconButton } from "@tvg/design-system";
import MessagePanel from "../MessagePanel";
import { PawsContent } from "../../types";
import { Container, CloseButton } from "./styled-components";
import type { Props } from "./types";

const AlertMessagesModal = ({
  isOpen,
  errorCode = "",
  qaLabel = "alert-messages-modal",
  closeButtonQaLabel = "modal-closeBtn",
  icon,
  type = "error",
  title = "",
  description = "",
  onRetryMethod,
  onCloseMethod,
  onContactMethod,
  onConfirmationMethod,
  onCancelMethod,
  actions,
  isMobile,
  retryCount,
  isCapiDefault = false
}: Props) => {
  const content = useSelector<Store, PawsContent>(getPawsContent);

  const messageData = useErrorMessageBuilder(
    errorCode,
    isCapiDefault,
    { title, description, actions, type },
    onRetryMethod,
    onCloseMethod,
    onContactMethod,
    onConfirmationMethod,
    onCancelMethod,
    retryCount
  );

  return (
    <ModalV2
      isOpen={isOpen}
      onClose={onCloseMethod}
      qaLabel={qaLabel}
      animation="fade"
      hasRoundedCorners
      isContentTransparent
      isFullHeight={type === "success"}
      hasHeader={false}
      isFullWidth={type === "success" && isMobile}
      fixedWidth={isMobile ? "100%" : "351px"}
    >
      {() => (
        <>
          {type !== "success" && (
            <CloseButton qaLabel={closeButtonQaLabel} onClick={onCloseMethod}>
              <IconButton
                variant="tertiary"
                size="s"
                iconName="close"
                qaLabel={`${closeButtonQaLabel}-Icon`}
                onPress={onCloseMethod || (() => null)}
              />
            </CloseButton>
          )}

          <Container data-qa-label="info-panel">
            <MessagePanel
              icon={icon}
              title={
                messageData.title || content.paymentMessageModal.error.title
              }
              description={`${
                messageData.description ||
                content.paymentMessageModal.error.description
              } ${errorCode ? `(Error ${errorCode})` : ""}`}
              variant={messageData.type}
              actions={messageData.actions}
              retryCount={retryCount}
            />
          </Container>
        </>
      )}
    </ModalV2>
  );
};

export default memo(AlertMessagesModal);
