import { Dispatch } from "redux";
import Poller from "@tvg/poller";
import { setUserPromosOnboarding } from "@tvg/sh-lib-promos-onboarding/utils/setUserPromosOnboarding";
import { resetStoryblokPromos } from "@tvg/sh-lib-promos-onboarding/redux/actions";
import { PromoEligible } from "../types/promos";

export const PromoOnboardingPoller = new Poller();

export const tryStopPromoOnboardingPoller = () => {
  if (PromoOnboardingPoller.isRunning()) {
    PromoOnboardingPoller.stop();
  }
};

export const startPromoOnboardingPoller = (
  dispatch: Dispatch,
  pollerTime: number,
  isLogged?: boolean,
  accountId?: string,
  promoEligible?: PromoEligible
) => {
  tryStopPromoOnboardingPoller();
  if (isLogged && accountId && promoEligible) {
    PromoOnboardingPoller.start(() => {
      setUserPromosOnboarding(dispatch, accountId, promoEligible);
    }, pollerTime * 1000); // to transform pollerTime in seconds
  } else {
    dispatch(resetStoryblokPromos());
  }
};
