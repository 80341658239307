import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Star = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <Path
    d="M12 2.92539L14.9438 8.8901L21.5262 9.84659L16.7631 14.4895L17.8875
    21.0453L12 17.9501L6.11248 21.0453L7.23689 14.4895L2.47379 9.84659L9.05624
    8.8901L12 2.92539Z"
    fill={backgroundColor}
    fillOpacity="1"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
export default Star;
