// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";

import buildColor from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${buildColor("blue", "100")};
  }
  
  50% {
    fill: ${buildColor("blue", "200")};
  }
  
  100% {
    fill: ${buildColor("blue", "100")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 720px;
  background: ${buildColor("white", "100")};

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export default class MyBetsMask extends PureComponent<*> {
  render() {
    return (
      <div data-qa-label="homepageMask">
        <SVG>
          <g>
            <rect
              fill={buildColor("white", "100")}
              height="100%"
              width="100%"
            />
            <rect fill={buildColor("blue", "000")} height="40" width="100%" />
            <rect
              className="pulse"
              width="150"
              height="8"
              x="8"
              y="16"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              fill={buildColor("blue", "200")}
              height="1"
              width="100%"
              y="40"
            />
            <rect
              className="pulse"
              width="40"
              height="8"
              x="8"
              y="64"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="176"
              height="8"
              x="64"
              y="64"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="152"
              height="8"
              x="8"
              y="96"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="56"
              height="8"
              x="8"
              y="116"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="72"
              height="8"
              x="8"
              y="136"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              fill={buildColor("blue", "200")}
              height="1"
              width="100%"
              y="168"
            />
            <rect
              className="pulse"
              width="40"
              height="8"
              x="8"
              y="193"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="128"
              height="8"
              x="64"
              y="193"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="168"
              height="8"
              x="8"
              y="225"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="64"
              height="8"
              x="8"
              y="245"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="40"
              height="8"
              x="8"
              y="265"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="40"
              height="8"
              x="8"
              y="285"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="136"
              height="8"
              x="8"
              y="317"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="64"
              height="8"
              x="8"
              y="337"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="80"
              height="8"
              x="8"
              y="357"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
            <rect
              className="pulse"
              width="40"
              height="8"
              x="8"
              y="377"
              rx="1"
              ry="1"
              fill={buildColor("blue", "100")}
            />
          </g>
        </SVG>
      </div>
    );
  }
}
