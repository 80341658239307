import React, { useContext, useEffect, forwardRef } from "react";
import { TouchableHighlight } from "react-native";
import { useQaLabel } from "../../hooks/useQaLabel";
import { PebbleProps, PebbleCompoundComponent } from "./types";
import { Tag } from "../tag";
import { PebbleGroup, GroupContext } from "./pebbleGroup";
import { useEvents } from "../../hooks/useEvents";
import {
  StyledViewTagContainer,
  StyledTouchableHighlight,
  StyledViewContainer,
  StyledViewIconCircle,
  StyledIcon,
  StyledTextTitle,
  StyledTextDescription
} from "./styled-components";

export const Pebble = forwardRef<typeof TouchableHighlight, PebbleProps>(
  (
    {
      iconName,
      title,
      description,
      tag,
      isSelected = false,
      qaLabel,
      value,
      ...rest
    },
    ref
  ) => {
    const viewTestProps = useQaLabel(qaLabel);
    const { events, setEvents, eventsHandlers } = useEvents({});
    const { selectedValue, onChange = () => {} } = useContext(GroupContext);

    useEffect(() => {
      setEvents({ ...events, isSelected });
    }, [isSelected]);

    useEffect(() => {
      setEvents({
        ...events,
        isSelected: isSelected || selectedValue === value
      });
    }, [selectedValue]);

    const pressHandler = () => {
      onChange(value);
    };

    const RenderTag = () => (
      <StyledViewTagContainer {...events}>
        {typeof tag === "string" ? (
          <Tag label={tag} variant="promo" size="s" />
        ) : (
          tag
        )}
      </StyledViewTagContainer>
    );

    return (
      <StyledTouchableHighlight
        // @ts-ignore
        ref={ref}
        {...rest}
        {...viewTestProps}
        {...eventsHandlers}
        {...events}
        onPress={pressHandler}
        accessibilityRole="button"
        underlayColor="none"
        onLongPress={pressHandler}
      >
        <StyledViewContainer {...events}>
          {!!tag && <RenderTag />}
          <StyledViewIconCircle {...events}>
            <StyledIcon
              name={iconName}
              size="m"
              $isHovered={events.isHovered}
              $isPressed={events.isPressed}
              $isSelected={events.isSelected}
            />
          </StyledViewIconCircle>
          <StyledTextTitle {...events}>{title}</StyledTextTitle>
          {description && (
            <StyledTextDescription {...events}>
              {description}
            </StyledTextDescription>
          )}
        </StyledViewContainer>
      </StyledTouchableHighlight>
    );
  }
) as PebbleCompoundComponent<typeof PebbleGroup>;

export { PebbleProps };
Pebble.Group = PebbleGroup;
export default Pebble;
