import { get, debounce } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { ValidationError } from "yup";
import pawsDepositService from "@tvg/api/paws/deposit";
import {
  State,
  Action,
  ACTION_AMOUNT,
  ACTION_CARD_CVV,
  ACTION_CARD_EXP,
  ACTION_CARD_NUM,
  ACTION_CARD_TYPE,
  ACTION_IS_LOADING,
  ACTION_IS_FEE_INFO_OPEN,
  ACTION_SHOW_SUCCESS_MESSAGE,
  ACTION_IS_CVV_INFO_OPEN,
  ACTION_IS_DEPOSIT_HOLD_FUNDS_TYPE,
  ACTION_RESET_STATE,
  ACTION_RESET_STATE_WITHOUT_AMOUNT
} from "../types";
import {
  isValidYear,
  isValidMonth,
  isPastMonth,
  cardNumberSchema,
  cardCVVSchema,
  cardDateSchema
} from "../schemaValidations";

export const initialState: State = {
  amount: "",
  cardType: "CC",
  cardNum: "",
  cardExp: "",
  cardCVV: "",
  isLoading: false,
  isFeeInfoOpen: false,
  showSuccessMessage: false,
  isCVVInfoOpen: false,
  isDepositHoldFundsType: false
};

export const getReducer = (
  state: State,
  { type, payload: { value = "", status = false } = {} }: Action
): State => {
  switch (type) {
    case ACTION_AMOUNT:
      return {
        ...state,
        amount: value
      };
    case ACTION_CARD_TYPE:
      return {
        ...state,
        cardType: value
      };
    case ACTION_CARD_NUM:
      return {
        ...state,
        cardNum: value
      };
    case ACTION_CARD_EXP:
      return {
        ...state,
        cardExp: value
      };
    case ACTION_CARD_CVV:
      return {
        ...state,
        cardCVV: value
      };
    case ACTION_IS_LOADING:
      return {
        ...state,
        isLoading: status
      };
    case ACTION_IS_FEE_INFO_OPEN:
      return {
        ...state,
        isFeeInfoOpen: status
      };
    case ACTION_SHOW_SUCCESS_MESSAGE:
      return {
        ...state,
        showSuccessMessage: status
      };
    case ACTION_IS_CVV_INFO_OPEN:
      return {
        ...state,
        isCVVInfoOpen: status
      };
    case ACTION_IS_DEPOSIT_HOLD_FUNDS_TYPE:
      return {
        ...state,
        isDepositHoldFundsType: status
      };
    case ACTION_RESET_STATE:
      return initialState;
    case ACTION_RESET_STATE_WITHOUT_AMOUNT:
      return { ...initialState, amount: state.amount };
    default:
      return state;
  }
};

export const getAmountWarning =
  (
    amount: string,
    maxLimit: number,
    minLimit: number,
    setAmountWarning: Dispatch<SetStateAction<boolean>>,
    showAmountWarning: boolean,
    selectedField: string
  ) =>
  () => {
    const validation = (+amount > +maxLimit || +amount < +minLimit) && !!amount;
    const timer = validation ? 3000 : 0;
    const debounceWarning = setTimeout(() => {
      setAmountWarning(validation);
      if (showAmountWarning && selectedField === "amount-field" && !amount) {
        setAmountWarning(false);
      }
    }, timer);

    return () => clearTimeout(debounceWarning);
  };

export const getNumErrMsg =
  (
    visitedFields: Set<string>,
    selectedField: string,
    content: object,
    cardNum: string,
    removeVisitedField: (field: string) => void
  ) =>
  () => {
    let errMessage: string = "";
    if (visitedFields.has("number-field") || selectedField === "number-field") {
      const schema = cardNumberSchema(
        get(content, "content.cardNumber.validations")
      );

      try {
        schema.validateSync(cardNum);
        removeVisitedField("number-field");
      } catch (err) {
        errMessage = get(err as ValidationError, "message");
      }
    }
    return errMessage;
  };

export const getCvvErrMsg =
  (
    visitedFields: Set<string>,
    selectedField: string,
    content: object,
    cardCVV: string,
    removeVisitedField: (field: string) => void
  ) =>
  () => {
    let errMessage = "";
    if (visitedFields.has("cvv-field") || selectedField === "cvv-field") {
      const schema = cardCVVSchema({
        required: get(
          content,
          "content.deposit.CC.cvvEmpty",
          "CVV is required"
        ),
        invalid: get(
          content,
          "content.deposit.CC.cvvInvalid",
          "Insert 3 digit CVV found on your card"
        )
      });
      try {
        schema.validateSync(cardCVV);
        removeVisitedField("cvv-field");
      } catch (err) {
        errMessage = get(err as ValidationError, "message");
      }
    }
    return errMessage;
  };

export const getExpErrMsg =
  (
    visitedFields: Set<string>,
    selectedField: string,
    content: object,
    cardExp: string,
    removeVisitedField: (field: string) => void
  ) =>
  () => {
    // TODO: verify if the date will come from the endpoint
    const [month, year] = cardExp.split("/");
    let errMessage: string = "";
    if (visitedFields.has("expire-field") || selectedField === "expire-field") {
      const schema = cardDateSchema(
        get(content, "content.expirationDateCard.validations")
      );
      try {
        schema.validateSync(cardExp);
        if (!isValidMonth(month)) {
          errMessage = get(
            content,
            "content.expirationDateCard.validations.invalidMonth",
            "Invalid Month"
          );
        } else if (!isValidYear(year)) {
          errMessage = get(
            content,
            "content.expirationDateCard.validations.invalidYear",
            "Invalid Year"
          );
        } else if (isPastMonth(month, year)) {
          errMessage = get(
            content,
            "content.expirationDateCard.validations.invalidDate",
            "Invalid Date"
          );
        } else {
          removeVisitedField("expire-field");
        }
      } catch (err) {
        errMessage = get(err as ValidationError, "message");
      }
    }
    return errMessage;
  };

export const getHasError =
  (
    cvvErrMsg: string,
    expErrMsg: string,
    numErrMsg: string,
    cardCVV: string,
    cardExp: string,
    cardNum: string,
    amount: string,
    showAmountWarning: boolean
  ) =>
  () =>
    !!cvvErrMsg ||
    !!expErrMsg ||
    !!numErrMsg ||
    !cardCVV ||
    !cardExp ||
    !cardNum ||
    !+amount ||
    showAmountWarning;

export const getFee = async (
  accountId: string,
  val: string,
  setFeeValue: Dispatch<SetStateAction<string>>
) => {
  try {
    if (val) {
      const {
        data: { feeTotalAmount }
      } = await pawsDepositService.getDepositFee("CCP", {
        accountId,
        depositAmount: Number(val).toFixed(2)
      });
      setFeeValue(feeTotalAmount as string);
    }
  } catch (err) {
    setFeeValue("");
  }
};

export const debouncedGetFee = debounce(getFee, 2000);

export const getFeeValue =
  (setFeeValue: Dispatch<SetStateAction<string>>) =>
  (accountId: string, val: string, minLimit: number, maxLimit: number) => {
    const outOfLimits = (+val > maxLimit || +val < minLimit) && !!val;
    if (!outOfLimits) {
      debouncedGetFee(accountId, val, setFeeValue);
    } else {
      debouncedGetFee.cancel();
    }

    if (!val) {
      setFeeValue("");
    }
  };
