import React from "react";
import { Path } from "react-native-svg";

import { IconPathProps } from "../types";

const Withdraw = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M12 8.40001C12 8.40001 12 4.80002 7.5 4.80002M7.5 4.80002C3 4.80002 3 
    8.40001 3 8.40001C3 9.60001 3 10.8 7.5 12C12 13.2 12 14.4 12 15.6C12 15.6 12 
    19.2 7.5 19.2M7.5 4.80002V1.5M7.5 19.2C3 19.2 3 15.6 3 15.6M7.5 
    19.2V22.5M21.75 11.625L18 15.375L14.25 11.625M18 7.5V15.293"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
export default Withdraw;
