import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Cup = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.37499 2.625H17.625L17.625 5.25C17.625 5.25 17.625 5.38466 17.618
      5.625C17.5889 6.62136 17.4393 9.43394 16.6695 11.9974C15.8997 14.5608
      14.5097 16.875 12 16.875C9.49024 16.875 8.10028 14.5608 7.33048
      11.9974C6.56066 9.43394 6.41105 6.62136 6.38197 5.625C6.37495 5.38466
      6.37495 5.25 6.37495 5.25L6.37499 2.625Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M12 16.875L12 21.375M12 16.875C9.49024 16.875 8.10028 14.5608 7.33048
      11.9974M12 16.875C14.5097 16.875 15.8997 14.5608 16.6695 11.9974M6.37495
      5.25L6.37499 2.625H17.625L17.625 5.25C17.625 5.25 17.625 5.38466 17.618
      5.625M6.37495 5.25C6.37495 5.25 6.37497 8.8156 7.33048 11.9974M6.37495
      5.25C6.37495 5.25 6.37495 5.38466 6.38197 5.625M12 21.375H7.49995M12
      21.375H16.5M6.38197 5.625H2.62495C2.62495 5.625 2.62495 11.8514 7.33048
      11.9974M6.38197 5.625C6.41105 6.62136 6.56066 9.43394 7.33048
      11.9974M16.6695 11.9974C21.375 11.8513 21.375 5.625 21.375
      5.625H17.618M16.6695 11.9974C17.4393 9.43394 17.5889 6.62136 17.618 5.625"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
