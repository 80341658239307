import styled from "styled-components";
import {
  fontMedium,
  fontCondensedNormal
} from "@tvg/atomic-ui/_static/Typography";
import { buildColor } from "@tvg/design-system/src/_static/colorPalette";

export const BalanceContainer = styled.dl`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-bottom: 16px;
`;

export const UserBalance = styled.dt`
  font-family: ${fontMedium};
  color: ${buildColor("grey", "900")};
  font-size: 20px;
  line-height: 125%;
  font-weight: 500;
  text-align: center;
`;

export const Description = styled.dd`
  text-transform: uppercase;
  font-family: ${fontCondensedNormal};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${buildColor("grey", "800")};
`;
