import React, { memo, useEffect } from "react";
import WithdrawFundsModal from "@tvg/wallet/src/components/FundsModal/Withdraw";
import { useDispatch, useSelector } from "react-redux";
import { getIsQuickWithdrawOpen } from "@tvg/sh-lib-paws/redux/selectors";
import useQuickWithdrawSetPM from "@tvg/sh-lib-paws/hooks/useQuickWithdrawSetPM";
import { usePendingWithdrawsModalState } from "@tvg/sh-lib-paws/hooks/usePendingWithdrawsModalState";
import { Location } from "react-router-dom";
import { useNavigate } from "@tvg/custom-hooks";
import { useLocation } from "react-router";

import { closeModal, gtmToggleQuickWithdraw, refetch } from "./utils";

export const QuickWithdrawModal = () => {
  const isOpen: boolean = useSelector(getIsQuickWithdrawOpen);
  const dispatch = useDispatch();
  const history = useNavigate();
  const location: Location = useLocation();

  usePendingWithdrawsModalState();

  const { refetchAvailableMethods, otherMethods } =
    useQuickWithdrawSetPM(isOpen);

  useEffect(gtmToggleQuickWithdraw(dispatch), []);

  return (
    <WithdrawFundsModal
      isQuickWithdraw
      onCloseCallback={closeModal(dispatch, history, location)}
      refetchAvailableMethods={refetch(refetchAvailableMethods)}
      isQuickWithdrawOpen={isOpen}
      otherMethods={otherMethods}
    />
  );
};

export default memo(QuickWithdrawModal);
