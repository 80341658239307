import { MapFeatureToggles, FeatureToggles, Messages } from "./types";

type FeaturesRequestSuccessAction = {
  type: "FEATURES_GET_SUCCESS";
  payload: {
    featureToggles: MapFeatureToggles | {};
  };
};

type FeaturesRequestFailAction = {
  type: "FEATURES_GET_FAIL";
  payload: Error;
  error: true;
};

type FeaturesChange = {
  type: "FEATURES_CHANGE";
  payload: {
    toggleName: string;
    newValue: boolean;
  };
};

type FeaturesResponseAction = {
  type: "FEATURES_RESPONSE";
  payload: {
    features: FeatureToggles | [];
  };
};

type MessagesRequestSuccessAction = {
  type: "MESSAGES_GET_SUCCESS";
  payload: {
    messages: Messages | false;
  };
};

type MessagesRequestFailAction = {
  type: "MESSAGES_GET_FAIL";
  payload: Error;
  error: true;
};

type SetAbTestingToggleAction = {
  type: "SET_ABTESTING_TOGGLE";
  payload: {
    featureName: string;
    enabled: boolean;
  };
};

export type CapiActions =
  | MessagesRequestFailAction
  | MessagesRequestSuccessAction
  | FeaturesRequestFailAction
  | FeaturesRequestSuccessAction
  | FeaturesResponseAction
  | FeaturesChange
  | SetAbTestingToggleAction;

export const successFeaturesRequest = (
  featureToggles: MapFeatureToggles | {}
): FeaturesRequestSuccessAction => ({
  type: "FEATURES_GET_SUCCESS",
  payload: {
    featureToggles
  }
});

export const failFeaturesRequest = (err: Error): FeaturesRequestFailAction => ({
  type: "FEATURES_GET_FAIL",
  payload: err,
  error: true
});

export const featuresResponse = (
  features: FeatureToggles
): FeaturesResponseAction => ({
  type: "FEATURES_RESPONSE",
  payload: {
    features
  }
});

export const successMessagesRequest = (
  messagesData: Messages | false
): MessagesRequestSuccessAction => ({
  type: "MESSAGES_GET_SUCCESS",
  payload: {
    messages: messagesData
  }
});

export const failMessagesRequest = (err: Error): MessagesRequestFailAction => ({
  type: "MESSAGES_GET_FAIL",
  payload: err,
  error: true
});

export const abTestingChange = (
  feature: string,
  enabled: boolean
): SetAbTestingToggleAction => ({
  type: "SET_ABTESTING_TOGGLE",
  payload: {
    featureName: feature,
    enabled
  }
});
