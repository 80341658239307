import { SetStateAction, Dispatch as ReactDispatch } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { closeDeleteCardModal } from "@tvg/sh-lib-paws/redux/slices/deleteCardModalSlice";
import service from "@tvg/api/paws/create";
import { capitalize } from "lodash";
import { ToastManager } from "@tvg/design-system";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import { PaymentTypesAvailable } from "@tvg/api/paws/types";
import { ButtonAction, PaymentType, UnaryFn } from "../../types";
import { gtmDeletePaymentMethodSuccess } from "../../gtm";

export const onClose = (
  dispatch: Dispatch,
  setIsLoading: ReactDispatch<SetStateAction<boolean>>
) => {
  setIsLoading(false);
  dispatch(closeDeleteCardModal());
};

export const onContact = () => {
  openLiveChat();
};

export const onConfirm = (
  paymentType: PaymentType,
  accountId: string,
  id: string,
  dispatch: Dispatch,
  refetchAvailableMethods: () => Promise<unknown>,
  setIsLoading: ReactDispatch<SetStateAction<boolean>>,
  setHasError: ReactDispatch<SetStateAction<boolean>>,
  successText: string,
  setIsAOFileOptionsModalOpen?: UnaryFn<boolean, void>
) => {
  setIsLoading(true);
  setHasError(false);

  const pmDeleteArg =
    paymentType === "MZM"
      ? { accountOnFileId: id, enabled: false }
      : { cardId: id };

  service
    .disablePayment(paymentType as PaymentTypesAvailable, {
      accountId,
      ...pmDeleteArg
    })
    .then(refetchAvailableMethods)
    .then(() => {
      gtmDeletePaymentMethodSuccess({ paymentType });
      dispatch(closeDeleteCardModal());
      ToastManager.show({
        message: capitalize(successText),
        variant: "success"
      });
      if (typeof setIsAOFileOptionsModalOpen === "function") {
        setIsAOFileOptionsModalOpen(false);
      }
    })
    .catch(() => {
      setHasError(true);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const getAction = (
  onRetry: () => void,
  setErrorRetryCount: ReactDispatch<SetStateAction<number>>,
  errorRetryCount: number,
  retryText: string,
  contactText: string
): ButtonAction =>
  errorRetryCount < 3
    ? {
        text: retryText,
        type: "primary",
        isUppercase: false,
        onClick: () => {
          setErrorRetryCount((origin) => origin + 1);
          onRetry();
        }
      }
    : {
        text: contactText,
        type: "tertiary",
        isUppercase: false,
        onClick: onContact
      };
