import styled from "styled-components";
import { margin } from "styled-system";

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  ${margin}
`;

export const StyledCheckbox = styled.input`
  accent-color: var(
    --fd-colors-component-selection-control-background-selected
  );
  appearance: none;
  background-color: var(
    --fd-colors-component-selection-control-background-base
  );
  border-radius: var(--fd-radii-border-radius-020);
  border: 1px solid var(--fd-colors-component-selection-control-border-base);
  box-shadow: var(--fd-shadows-elevations-bottom-low);
  cursor: pointer;
  height: 24px;
  margin: 0;
  min-height: 24px;
  min-width: 24px;
  padding: 0;
  transition:
    border 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  width: 24px;

  &:hover {
    background-color: var(
      --fd-colors-component-selection-control-background-hover
    );
    border: 1px solid var(--fd-colors-component-selection-control-border-hover);
    box-shadow: var(--fd-shadows-elevations-bottom-low);
  }

  &:checked {
    background-color: var(
      --fd-colors-component-selection-control-background-selected
    );
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 8L6 11.5L13.5 4' stroke='white' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid
      var(--fd-colors-component-selection-control-border-selected);
    box-shadow: none;
  }

  &:disabled {
    background-color: var(
      --fd-colors-component-selection-control-background-disabled
    );
    border: 1px solid var(--fd-colors-border-disabled);
    box-shadow: none;
    cursor: not-allowed;
  }

  &:checked:disabled {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 8L6 11.5L13.5 4' stroke='%23CED4DB' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    box-shadow: none;
  }

  &[data-invalid="true"] {
    border: 1px solid var(--fd-colors-system-warning-border-default);
    box-shadow: var(--fd-shadows-elevations-bottom-low);
  }
`;

export const StyledLabelContainer = styled.div`
  cursor: pointer;
  flex-shrink: 1;
`;

// TODO: Add the Typography component @Gregory
export const StyledLabel = styled.label<{ isDisabled: boolean }>`
  flex-shrink: 1;
  /* stylelint-disable-next-line */
  font-family: "Roboto-Regular", sans-serif;
  color: var(--fd-colors-content-default);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px;
`;
