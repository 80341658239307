import { string } from "yup";
import type { Validations } from "./types";

// Will not test a third party functions

export const requiredSchema = (validations: Validations) =>
  string().required(validations.required);

export const routingNumberSchema = (validations: Validations) =>
  string()
    .required(validations.required)
    .matches(/[0-9]{9}/, {
      message: validations.invalidDigits,
      excludeEmptyString: true
    });

export const accountNumberSchema = (validations: Validations) =>
  string()
    .required(validations.required)
    .matches(/[0-9]{6,17}/, {
      message: validations.invalidDigits,
      excludeEmptyString: true
    });

export const bankNameSchema = (validations: Validations) =>
  string().required(validations.required);

export const drivingLicenceSchema = (validations: Validations) =>
  string()
    .required(validations.required)
    .matches(/^[a-zA-Z0-9]+(-?[a-zA-Z0-9])*$/, {
      message: validations.regex,
      excludeEmptyString: true
    });

export const dlStateSchema = (validations: Validations) =>
  string().required(validations.required);
