import { get } from "lodash";
import {
  PromoOnboardingStep,
  PromoStepDescriptionKey,
  PromoStepDescriptions,
  PromoStepType,
  PromoStepWagerStatus
} from "@tvg/sh-lib-promos-onboarding/types/promoSteps";
import { formatStoryblokText } from "@tvg/sh-lib-promos-onboarding/helpers/formatStoryblokText";
import { PromoOnboarding } from "@tvg/sh-lib-promos-onboarding/types/promoOnboarding";
import { checkOnboardingPromoAvailable } from "@tvg/sh-lib-promos-onboarding/utils/checkOnboardingPromoAvailable";

const getPromoFinishedDescriptionsKey = (
  wagerStatus?: PromoStepWagerStatus
): PromoStepDescriptionKey => {
  switch (wagerStatus) {
    case PromoStepWagerStatus.WIN: {
      return PromoStepDescriptionKey.WIN;
    }
    case PromoStepWagerStatus.LOST: {
      return PromoStepDescriptionKey.LOSS;
    }
    default: {
      return PromoStepDescriptionKey.DEFAULT;
    }
  }
};

const getPromoAvailableDescriptionKey = (
  wagerStatus?: PromoStepWagerStatus
): PromoStepDescriptionKey =>
  wagerStatus && wagerStatus === PromoStepWagerStatus.PLACED
    ? PromoStepDescriptionKey.PROCESSING
    : PromoStepDescriptionKey.DEFAULT;

export const getPromoDescription = (
  messages: PromoStepDescriptions,
  promoOnboarding: PromoOnboarding,
  currentStep: PromoOnboardingStep
): string => {
  const defaultText = get(messages, "default", "");
  const promoSteps = get(promoOnboarding, "steps", {});
  const wagerStatus = get(promoSteps, `${PromoStepType.WAGER}.wagerStatus`);
  const promoAvailable = checkOnboardingPromoAvailable(promoSteps);
  const messageKey = promoAvailable
    ? getPromoAvailableDescriptionKey(wagerStatus)
    : getPromoFinishedDescriptionsKey(wagerStatus);
  const description = get(messages, messageKey, "");
  return formatStoryblokText(
    description || defaultText,
    promoOnboarding,
    currentStep
  );
};
