import React, { useContext } from "react";
import { ThemeProvider as ThemeProviderWeb } from "styled-components";
import { ThemeProvider } from "styled-components/native";
import { TvgConfContext } from "../../utils/tvgConfProvider";
import { fanduelColorTokens, tvgColorTokens } from "../themes/tokens";
import { DEFAULT_THEME_KEY, THEME_MAP } from "./constants";
import { Theme } from "../types";
import { TVGThemeProviderProps } from "./types";

/**
 * Until we remove react-native-web, we need to wrap the ThemeProvider from
 * styled-components with the ThemeProvider from styled-components/native
 * to pass the theme to the web components.
 */
export const TVGThemeProviderWeb: React.FC<TVGThemeProviderProps> = ({
  children,
  device,
  isFanduelTheme
}) => {
  const { device: deviceInContext } = useContext(TvgConfContext);
  const colorTokens = isFanduelTheme ? fanduelColorTokens : tvgColorTokens;
  const theme =
    (device && THEME_MAP[device]) ||
    (deviceInContext && THEME_MAP[deviceInContext]) ||
    THEME_MAP[DEFAULT_THEME_KEY];

  return (
    <ThemeProviderWeb theme={{ ...theme, colorTokens } as Theme}>
      <ThemeProvider theme={{ ...theme, colorTokens } as Theme}>
        {children}
      </ThemeProvider>
    </ThemeProviderWeb>
  );
};
