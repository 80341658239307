import styled from "styled-components";
import { Theme } from "styled-system";

export const Wrapper = styled.div<{
  theme: Theme;
}>`
  display: flex;
  height: 30px;
  padding: 0 ${({ theme }) => theme.space["space-3"]};
  border-top: 1px solid ${({ theme }) => theme.colors.green["200"]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.green["200"]};
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.green["100"]};
`;
