// @flow

import React, { PureComponent } from "react";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { tick, warning, close } from "../../_static/Icons/icons";
import Spinner from "../../_static/Spinners";

import { Overlay, Success, Error, Warning } from "./styled-components";

type FeedbackTypeEnum = "loading" | "success" | "error" | "warning";

type Props = {
  type: FeedbackTypeEnum,
  message: string,
  loadingIconColor: string,
  overlayBgColor: string
};

export default class FeedbackOverlay extends PureComponent<Props> {
  static defaultProps = {
    type: "loading",
    message: "",
    loadingIconColor: buildColor("white", "100"),
    overlayBgColor: buildColor("black", "70")
  };

  renderIcon() {
    switch (this.props.type) {
      case "success":
        return (
          <Success data-qa-label="feedback-successIcon">
            <Icon icon={tick} color={buildColor("white", "100")} size={50} />
          </Success>
        );
      case "error":
        return (
          <Error data-qa-label="feedback-errorIcon">
            <Icon icon={close} color={buildColor("white", "100")} size={50} />
          </Error>
        );
      case "warning":
        return (
          <Warning data-qa-label="feedback-warningIcon">
            <Icon icon={warning} color={buildColor("white", "100")} size={50} />
          </Warning>
        );
      case "loading":
      default:
        return (
          <Spinner
            width={32}
            height={32}
            color={this.props.loadingIconColor}
            data-qa-label="feedback-loadingIcon"
          />
        );
    }
  }

  render() {
    return (
      <Overlay
        overlayBgColor={this.props.overlayBgColor}
        data-qa-label="feedback"
      >
        {this.renderIcon()}
        <span data-qa-label={`feedback-${this.props.type}`}>
          {this.props.message}
        </span>
      </Overlay>
    );
  }
}
