export enum PaymentType {
  ALL = "ALL",
  ECHECK = "ACH",
  CASH = "CASH",
  CREDIT_CARD = "CC",
  CREDIT_DEBIT_CARD = "CCP",
  SIGNED_CREDIT_CARD = "CCS",
  CHECK = "CHK",
  DEBIT_CARD = "DEB",
  SIGNED_DEBIT_CARD = "DEBS",
  INSTANT_BET_CASH = "IBC",
  MONEY_PAK = "MPAK",
  PAYPAL = "PAY",
  PAY_NEAR_ME = "PNM",
  PAYPAL2 = "PPAL",
  SIGHTLINE = "SLP",
  SIGHTLINE2 = "SLN",
  CARD_WITHDRAWAL = "VIWD",
  COLLECTION_FEE = "CFee",
  COLLECTION = "Coll",
  CONTEST_WINNINGS = "CNTW",
  REMOTE = "RPPS",
  WIRE_TRANSFER = "WTRN",
  OTHER_PAYMENT = "OPM",
  MAZOOMA = "MZM"
}
