import React, { FC, memo } from "react";
import { useTheme } from "../../hooks";
import * as FlagPaths from "./components/FlagPaths";
import { Svg } from "./styled-components";
import type { FlagProps, Countries } from "./types";
import { IconSizeVariants } from "../../theme";

export const Flag: FC<FlagProps> = memo(
  ({ size, country, qaLabel, ...rest }) => {
    const { iconSizes } = useTheme();
    const { iconSize } = iconSizes[size as IconSizeVariants];
    const Flags = Object.keys(FlagPaths);
    const height = (Number(iconSize) * 20) / 32;
    const FlagContent = FlagPaths[country as Countries];

    return Flags.includes(country) ? (
      <Svg
        width={iconSize}
        height={height}
        viewBox="0 0 32 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-qa-label={qaLabel || country}
        {...rest}
      >
        <FlagContent />
      </Svg>
    ) : null;
  }
);
