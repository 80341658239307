import { useEffect, useState } from "react";
import { fetchPromosEligible } from "../utils/fetchPromosEligible";
import { PromoEligible } from "../types/promos";

export const usePromoEligible = (
  isLogged?: boolean,
  accountId?: string,
  promoOnboardingToggle?: boolean
) => {
  const [promoEligible, setPromoEligible] = useState<PromoEligible | null>(
    null
  );

  useEffect(() => {
    if (isLogged && accountId && promoOnboardingToggle) {
      fetchPromosEligible(accountId).then((promo) => {
        setPromoEligible(promo);
      });
    } else {
      // To clean the promo eligible state.
      setPromoEligible(null);
    }
  }, [isLogged, accountId, promoOnboardingToggle]);

  return promoEligible;
};
