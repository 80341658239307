import { History } from "@tvg/ts-types/History";
import { mathPercentageFormatted } from "@tvg/utils/mathUtils";
import { get, has } from "lodash";
import {
  PromoMappedSteps,
  PromoOnboardingStep,
  PromoStepDescriptions,
  PromoStepType,
  PromoStepWagerStatus
} from "@tvg/sh-lib-promos-onboarding/types/promoSteps";
import { PromoOnboarding } from "@tvg/sh-lib-promos-onboarding/types/promoOnboarding";

import tvgConf from "@tvg/conf";
import { PromoCurrentStatus } from "../types";
import { getCTA } from "../utils/getPromoCTA";
import { getPromoDescription } from "../utils/getPromoDescription";
import { getPromoStepsLabel } from "../utils/getPromoStepsLabel";
import { getCompletedSteps } from "../utils/getCompletedSteps";
import { defaultStatus } from "../constraints/promoCurrentStatus";

export const formatPromoCurrentStatus = (
  storyblokPromos: PromoOnboarding,
  history: History
): PromoCurrentStatus | null => {
  const isDesktop = tvgConf().device === "desktop";
  const promoSteps: PromoMappedSteps = get(
    storyblokPromos,
    "steps",
    {} as PromoMappedSteps
  );
  const hasPromoPayoutStep = has(promoSteps, `${PromoStepType.PAYOUT}.@type`);
  const currentStepType = hasPromoPayoutStep
    ? PromoStepType.PAYOUT
    : get(storyblokPromos, "currentStepType");
  const currentStep: PromoOnboardingStep | null = get(
    promoSteps,
    currentStepType,
    null
  );

  if (!currentStep) {
    return null;
  }

  const stepDescription: PromoStepDescriptions = get(
    currentStep,
    `description`,
    {} as PromoStepDescriptions
  );

  const stepIsFulfilled = get(currentStep, `isFulfilled`, false);
  const CTA = getCTA(
    currentStepType,
    stepIsFulfilled,
    currentStep,
    history,
    isDesktop
  );
  const wagerStatus = get(currentStep, "wagerStatus");
  const promoRedeem =
    wagerStatus && wagerStatus !== PromoStepWagerStatus.PLACED;
  const promoDescription = getPromoDescription(
    stepDescription,
    storyblokPromos,
    currentStep
  );
  const progressBarSteps = ["Register", ...getPromoStepsLabel(promoSteps)];
  const completedSteps = getCompletedSteps(
    get(storyblokPromos, "completedSteps", 0),
    promoRedeem ? PromoStepType.PAYOUT : currentStepType,
    stepIsFulfilled || promoRedeem
  );
  const promoTitle =
    currentStepType === PromoStepType.PAYOUT && stepIsFulfilled
      ? "Promotion Redeemed"
      : `${mathPercentageFormatted(
          progressBarSteps.length,
          completedSteps
        )} Complete`;
  const remainingDays = get(storyblokPromos, "remainingDays", 0);

  return {
    ...defaultStatus,
    ...CTA,
    promoDescription,
    progressBarSteps,
    completedSteps,
    promoTitle,
    remainingDays,
    showRemainingDays: !promoRedeem && currentStepType !== PromoStepType.PAYOUT
  };
};
export default formatPromoCurrentStatus;
