import { omit } from "lodash";
import {
  PromoMappedSteps,
  PromoOnboardingStep,
  PromoStepType
} from "../types/promoSteps";

export const formatSteps = (
  steps: Array<PromoOnboardingStep>
): PromoMappedSteps =>
  steps.reduce(
    (mappedSteps, promoStep) => ({
      ...mappedSteps,
      [promoStep.stepType || PromoStepType.UNKNOWN]: omit(promoStep, [
        "stepType"
      ])
    }),
    {} as PromoMappedSteps
  );

export default formatSteps;
