// @flow

import React, { PureComponent } from "react";
import type { Device } from "@tvg/types/Device";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "000")};
  }
  
  50% {
    fill: ${color("blue", "000")};
  }
  
  100% {
    fill: ${color("grey", "000")};
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: white;
`;

const SVG = styled.svg`
  display: block;
  height: 380px;
  width: 100%;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

type Props = {
  device: Device
};

export default class TopRacesMask extends PureComponent<Props> {
  static defaultProps = {
    device: "mobile"
  };

  render() {
    return (
      <Container>
        <SVG>
          {this.props.device !== "desktop" ? (
            <g fill="none" fillRule="evenodd">
              <rect width="1516" height="380" fill="#FFF" fillRule="nonzero" />
              <rect
                width="291"
                height="319"
                x="12.5"
                y="48.5"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#E9EFF5"
                rx="4"
              />
              <rect
                width="70"
                height="8"
                x="222"
                y="344"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="60"
                height="8"
                x="24"
                y="344"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="267" height="1" x="36" y="327" className="pulse" />
              <rect
                width="156"
                height="8"
                x="84"
                y="306"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="84"
                height="8"
                x="84"
                y="289"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="56" x="12" y="272" className="pulse" />
              <rect width="267" height="1" x="36" y="271" className="pulse" />
              <rect
                width="124"
                height="8"
                x="84"
                y="250"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="52"
                height="8"
                x="84"
                y="233"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="55" x="12" y="216" className="pulse" />
              <rect width="267" height="1" x="36" y="215" className="pulse" />
              <rect
                width="132"
                height="8"
                x="84"
                y="194"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="100"
                height="8"
                x="84"
                y="177"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="55" x="12" y="160" className="pulse" />
              <rect width="290" height="1" x="13" y="159" className="pulse" />
              <rect
                width="64"
                height="24"
                x="23"
                y="124"
                className="pulse"
                fillRule="nonzero"
                rx="2"
              />
              <rect
                width="98"
                height="8"
                x="24"
                y="82"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="20"
                height="8"
                x="24"
                y="64"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="291"
                height="319"
                x="312.5"
                y="48.5"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#E9EFF5"
                rx="4"
              />
              <rect
                width="70"
                height="8"
                x="522"
                y="344"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="60"
                height="8"
                x="324"
                y="344"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="267" height="1" x="336" y="327" className="pulse" />
              <rect
                width="156"
                height="8"
                x="384"
                y="306"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="84"
                height="8"
                x="384"
                y="289"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="56" x="312" y="272" className="pulse" />
              <rect width="267" height="1" x="336" y="271" className="pulse" />
              <rect
                width="124"
                height="8"
                x="384"
                y="250"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="52"
                height="8"
                x="384"
                y="233"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="55" x="312" y="216" className="pulse" />
              <rect width="267" height="1" x="336" y="215" className="pulse" />
              <rect
                width="132"
                height="8"
                x="384"
                y="194"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="100"
                height="8"
                x="384"
                y="177"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="55" x="312" y="160" className="pulse" />
              <rect width="290" height="1" x="313" y="159" className="pulse" />
              <rect
                width="64"
                height="24"
                x="323"
                y="124"
                className="pulse"
                fillRule="nonzero"
                rx="2"
              />
              <rect
                width="98"
                height="8"
                x="324"
                y="82"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="20"
                height="8"
                x="324"
                y="64"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="291"
                height="319"
                x="612.5"
                y="48.5"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#E9EFF5"
                rx="4"
              />
              <rect
                width="70"
                height="8"
                x="822"
                y="344"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="60"
                height="8"
                x="624"
                y="344"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="267" height="1" x="636" y="327" className="pulse" />
              <rect
                width="156"
                height="8"
                x="684"
                y="306"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="84"
                height="8"
                x="684"
                y="289"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="56" x="612" y="272" className="pulse" />
              <rect width="267" height="1" x="636" y="271" className="pulse" />
              <rect
                width="124"
                height="8"
                x="684"
                y="250"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="52"
                height="8"
                x="684"
                y="233"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="55" x="612" y="216" className="pulse" />
              <rect width="267" height="1" x="636" y="215" className="pulse" />
              <rect
                width="132"
                height="8"
                x="684"
                y="194"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="100"
                height="8"
                x="684"
                y="177"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="55" x="612" y="160" className="pulse" />
              <rect width="290" height="1" x="613" y="159" className="pulse" />
              <rect
                width="64"
                height="24"
                x="623"
                y="124"
                className="pulse"
                fillRule="nonzero"
                rx="2"
              />
              <rect
                width="98"
                height="8"
                x="624"
                y="82"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="20"
                height="8"
                x="624"
                y="64"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="291"
                height="319"
                x="912.5"
                y="48.5"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#E9EFF5"
                rx="4"
              />
              <rect
                width="70"
                height="8"
                x="1122"
                y="344"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="60"
                height="8"
                x="924"
                y="344"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="267" height="1" x="936" y="327" className="pulse" />
              <rect
                width="156"
                height="8"
                x="984"
                y="306"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="84"
                height="8"
                x="984"
                y="289"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="56" x="912" y="272" className="pulse" />
              <rect width="267" height="1" x="936" y="271" className="pulse" />
              <rect
                width="124"
                height="8"
                x="984"
                y="250"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="52"
                height="8"
                x="984"
                y="233"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="55" x="912" y="216" className="pulse" />
              <rect width="267" height="1" x="936" y="215" className="pulse" />
              <rect
                width="132"
                height="8"
                x="984"
                y="194"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="100"
                height="8"
                x="984"
                y="177"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="55" x="912" y="160" className="pulse" />
              <rect width="290" height="1" x="913" y="159" className="pulse" />
              <rect
                width="64"
                height="24"
                x="923"
                y="124"
                className="pulse"
                fillRule="nonzero"
                rx="2"
              />
              <rect
                width="98"
                height="8"
                x="924"
                y="82"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="20"
                height="8"
                x="924"
                y="64"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="291"
                height="319"
                x="1212.5"
                y="48.5"
                fill="#FFF"
                fillRule="nonzero"
                stroke="#E9EFF5"
                rx="4"
              />
              <rect
                width="70"
                height="8"
                x="1422"
                y="344"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="60"
                height="8"
                x="1224"
                y="344"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="267" height="1" x="1236" y="327" className="pulse" />
              <rect
                width="156"
                height="8"
                x="1284"
                y="306"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="84"
                height="8"
                x="1284"
                y="289"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="56" x="1212" y="272" className="pulse" />
              <rect width="267" height="1" x="1236" y="271" className="pulse" />
              <rect
                width="124"
                height="8"
                x="1284"
                y="250"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="52"
                height="8"
                x="1284"
                y="233"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="55" x="1212" y="216" className="pulse" />
              <rect width="267" height="1" x="1236" y="215" className="pulse" />
              <rect
                width="132"
                height="8"
                x="1284"
                y="194"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="100"
                height="8"
                x="1284"
                y="177"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect width="24" height="55" x="1212" y="160" className="pulse" />
              <rect width="290" height="1" x="1213" y="159" className="pulse" />
              <rect
                width="64"
                height="24"
                x="1223"
                y="124"
                className="pulse"
                fillRule="nonzero"
                rx="2"
              />
              <rect
                width="98"
                height="8"
                x="1224"
                y="82"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="20"
                height="8"
                x="1224"
                y="64"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="73"
                height="8"
                x="12"
                y="20"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
            </g>
          ) : (
            <g fill="none" fillRule="evenodd" transform="translate(-208 -542)">
              <rect width="1440" height="100%" fill="#E6E9EF" />
              <g transform="translate(208 542)">
                <g transform="translate(687 56)">
                  <rect
                    width="324"
                    height="319"
                    x=".5"
                    y=".5"
                    fill="#FFF"
                    fillRule="nonzero"
                    stroke="#D6E3F0"
                    rx="4"
                  />
                  <rect
                    width="70"
                    height="8"
                    x="244"
                    y="296"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="60"
                    height="8"
                    x="12"
                    y="296"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="84"
                    height="8"
                    x="72"
                    y="241"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="156"
                    height="8"
                    x="72"
                    y="258"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect width="24" height="56" y="224" fill="#E6E9EF" />
                  <rect
                    width="52"
                    height="8"
                    x="72"
                    y="185"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="124"
                    height="8"
                    x="72"
                    y="202"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect width="24" height="55" y="168" fill="#E6E9EF" />
                  <rect
                    width="100"
                    height="8"
                    x="72"
                    y="129"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="132"
                    height="8"
                    x="72"
                    y="146"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect width="24" height="55" y="112" fill="#E6E9EF" />
                  <rect width="325" height="1" y="111" fill="#D6E3F0" />
                  <rect width="325" height="1" y="279" fill="#D6E3F0" />
                  <rect width="301" height="1" x="24" y="167" fill="#D6E3F0" />
                  <rect width="301" height="1" x="24" y="223" fill="#D6E3F0" />
                  <rect
                    width="64"
                    height="24"
                    x="11"
                    y="76"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="2"
                  />
                  <rect
                    width="98"
                    height="8"
                    x="12"
                    y="34"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="20"
                    height="8"
                    x="12"
                    y="16"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                </g>
                <g transform="translate(349 56)">
                  <rect
                    width="325"
                    height="319"
                    x=".5"
                    y=".5"
                    fill="#FFF"
                    fillRule="nonzero"
                    stroke="#D6E3F0"
                    rx="4"
                  />
                  <rect
                    width="70"
                    height="8"
                    x="244"
                    y="296"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="60"
                    height="8"
                    x="12"
                    y="296"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="84"
                    height="8"
                    x="72"
                    y="241"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="156"
                    height="8"
                    x="72"
                    y="258"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect width="24" height="56" y="224" fill="#E6E9EF" />
                  <rect
                    width="52"
                    height="8"
                    x="72"
                    y="185"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="124"
                    height="8"
                    x="72"
                    y="202"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect width="24" height="55" y="168" fill="#E6E9EF" />
                  <rect
                    width="100"
                    height="8"
                    x="72"
                    y="129"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="132"
                    height="8"
                    x="72"
                    y="146"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect width="24" height="55" y="112" fill="#E6E9EF" />
                  <rect width="325" height="1" y="111" fill="#D6E3F0" />
                  <rect width="325" height="1" y="279" fill="#D6E3F0" />
                  <rect width="301" height="1" x="24" y="167" fill="#D6E3F0" />
                  <rect width="301" height="1" x="24" y="223" fill="#D6E3F0" />
                  <rect
                    width="64"
                    height="24"
                    x="11"
                    y="76"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="2"
                  />
                  <rect
                    width="98"
                    height="8"
                    x="12"
                    y="34"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="20"
                    height="8"
                    x="12"
                    y="16"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                </g>
                <g transform="translate(12 56)">
                  <rect
                    width="324"
                    height="319"
                    x=".5"
                    y=".5"
                    fill="#FFF"
                    fillRule="nonzero"
                    stroke="#D6E3F0"
                    rx="4"
                  />
                  <rect
                    width="70"
                    height="8"
                    x="244"
                    y="296"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="60"
                    height="8"
                    x="12"
                    y="296"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="84"
                    height="8"
                    x="72"
                    y="241"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="156"
                    height="8"
                    x="72"
                    y="258"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect width="24" height="56" y="224" fill="#E6E9EF" />
                  <rect
                    width="52"
                    height="8"
                    x="72"
                    y="185"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="124"
                    height="8"
                    x="72"
                    y="202"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect width="24" height="55" y="168" fill="#E6E9EF" />
                  <rect
                    width="100"
                    height="8"
                    x="72"
                    y="129"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="132"
                    height="8"
                    x="72"
                    y="146"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect width="24" height="55" y="112" fill="#E6E9EF" />
                  <rect width="325" height="1" y="111" fill="#D6E3F0" />
                  <rect width="325" height="1" y="279" fill="#D6E3F0" />
                  <rect width="301" height="1" x="24" y="167" fill="#D6E3F0" />
                  <rect width="301" height="1" x="24" y="223" fill="#D6E3F0" />
                  <rect
                    width="64"
                    height="24"
                    x="11"
                    y="76"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="2"
                  />
                  <rect
                    width="98"
                    height="8"
                    x="12"
                    y="34"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                  <rect
                    width="20"
                    height="8"
                    x="12"
                    y="16"
                    fill="#E6E9EF"
                    fillRule="nonzero"
                    className="pulse"
                    rx="1"
                  />
                </g>
                <rect
                  width="73"
                  height="8"
                  x="12"
                  y="24"
                  fill="#E6E9EF"
                  fillRule="nonzero"
                  className="pulse"
                  rx="1"
                />
              </g>
            </g>
          )}
        </SVG>
      </Container>
    );
  }
}
