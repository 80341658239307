import React from "react";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import formatCurrency from "@tvg/formatter/currency";
import { Icon } from "@tvg/design-system";
import { ModalSubtitleProps } from "../types";
import { ClickableHeader } from "../styled-components";
import { hasMorePlayableBalance } from "../../common/balanceUtils";
import { getDisabledColor } from "../../common/utils";

export const ModalSubtitle = ({
  withdrawalLimit,
  capiMaxWithdrawAmount,
  setShowInfoMessage,
  description,
  accountsOnFile,
  isLoading
}: ModalSubtitleProps) => {
  const text = replaceCAPIVariables(description, {
    withdrawableBalance: formatCurrency(withdrawalLimit)
  });

  if (
    !hasMorePlayableBalance(
      withdrawalLimit,
      capiMaxWithdrawAmount,
      accountsOnFile
    )
  )
    return text;

  return (
    <ClickableHeader
      onClick={() => {
        if (!isLoading) {
          setShowInfoMessage(true);
        }
      }}
      data-qa-label="clickable-header"
      isLoading={isLoading}
    >
      <Icon
        name="info"
        size="s"
        backgroundColor={getDisabledColor(isLoading, "200")}
        lineColor={getDisabledColor(isLoading, "600")}
      />
      {text}
    </ClickableHeader>
  );
};
