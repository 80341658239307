import React from "react";
import { Path } from "react-native-svg";

import { IconPathProps } from "../types";

const ArrowRight = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M2.25 12H20.9177M13.5 4.5L21 12L13.5 19.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default ArrowRight;
