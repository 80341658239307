import styled from "styled-components";
import { Theme } from "../../../src/theming/types";
import { colorPalette } from "./colorPalette";

export const ButtonsContainer = styled.div`
  display: flex;
  border: 2px solid
    ${({ theme }: { theme: Theme }) => colorPalette(theme).default.border};
  border-radius: 4px;
  overflow: hidden;
`;

export const NavButton = styled.button<{
  disabled: boolean;
}>`
  padding: ${({ theme }: { theme: Theme }) => theme.space["space-3"]};
  background-color: ${({ theme }: { theme: Theme }) =>
    colorPalette(theme).default.background};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  & svg path {
    stroke: ${({ theme }: { theme: Theme }) =>
      colorPalette(theme).default.line};
  }

  &:hover {
    background-color: ${({ theme }: { theme: Theme }) =>
      colorPalette(theme).hover.background};

    & svg path {
      stroke: ${({ theme }: { theme: Theme }) =>
        colorPalette(theme).hover.line};
    }
  }

  &:active {
    background-color: ${({ theme }: { theme: Theme }) =>
      colorPalette(theme).active.background};

    & svg path {
      stroke: ${({ theme }: { theme: Theme }) =>
        colorPalette(theme).active.line};
    }
  }

  &:disabled {
    background-color: ${({ theme }: { theme: Theme }) =>
      colorPalette(theme).disabled.background};

    & svg path {
      stroke: ${({ theme }: { theme: Theme }) =>
        colorPalette(theme).disabled.line};
    }
  }
`;
