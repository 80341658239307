import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const PaymentWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  height: 100%;
`;

export const PaymentTable = styled.div`
  max-width: 976px;
  margin: 12px auto;
  background-color: ${buildColor("blue_accent", "000")};
  border: 1px solid ${buildColor("blue", "100")};
  border-radius: 4px;
`;

export const PaymentTypeRow = styled.div`
  min-height: 110px;
  display: flex;
  margin-left: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid ${buildColor("blue", "100")};
  }
`;
