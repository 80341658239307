import { useReducer, useMemo, useEffect, useState } from "react";
import { FormReturn } from "../types";
import {
  getAmountWarning,
  getCvvErrMsg,
  getExpErrMsg,
  getHasError,
  getNumErrMsg,
  getReducer,
  initialState
} from "./methods";

const useForm = (
  minLimit: number,
  maxLimit: number,
  visitedFields: Set<string>,
  selectedField: string,
  removeVisitedField: (field: string) => void,
  content: object
): FormReturn => {
  const [formState, formDispatch] = useReducer(getReducer, initialState);
  const [showAmountWarning, setAmountWarning] = useState(false);

  const { amount, cardCVV, cardExp, cardNum } = formState;

  useEffect(
    getAmountWarning(
      amount,
      maxLimit,
      minLimit,
      setAmountWarning,
      showAmountWarning,
      selectedField
    ),
    [amount, maxLimit, minLimit, selectedField]
  );

  const numErrMsg = useMemo<string>(
    getNumErrMsg(
      visitedFields,
      selectedField,
      content,
      cardNum,
      removeVisitedField
    ),
    [cardNum, selectedField]
  );

  const cvvErrMsg = useMemo<string>(
    getCvvErrMsg(
      visitedFields,
      selectedField,
      content,
      cardCVV,
      removeVisitedField
    ),
    [cardCVV, selectedField]
  );

  const expErrMsg = useMemo<string>(
    getExpErrMsg(
      visitedFields,
      selectedField,
      content,
      cardExp,
      removeVisitedField
    ),
    [cardExp, selectedField]
  );

  const hasError = useMemo(
    getHasError(
      cvvErrMsg,
      expErrMsg,
      numErrMsg,
      cardCVV,
      cardExp,
      cardNum,
      amount,
      showAmountWarning
    ),
    [
      showAmountWarning,
      cardNum,
      cardExp,
      cardCVV,
      cvvErrMsg,
      expErrMsg,
      numErrMsg,
      amount
    ]
  );

  return {
    formState,
    formDispatch,
    showAmountWarning,
    expErrMsg,
    numErrMsg,
    cvvErrMsg,
    hasError
  };
};

export default useForm;
