import mediator from "@tvg/mediator";
import { isTvg5 } from "@tvg/utils/generalUtils";
import buildRaceUrl from "@tvg/formatter/url";
import { History } from "@tvg/ts-types/History";

export const genericRedirect = (
  history: History,
  route: string,
  isDesktop = false,
  event = ""
) => {
  const { location } = history;
  if (location && location.pathname === route) {
    return;
  }

  if (!isDesktop || isTvg5()) {
    history.push(route);
  } else {
    mediator.base.dispatch({
      type: "TVG4_NAVIGATION",
      payload: { route, event }
    });
  }
};

export const goToWallet = (history: History, isDesktop = false) => {
  genericRedirect(history, "/wallet", isDesktop, "Wallet");
};

export const goToTracksPage = (history: History, isDesktop = false) => {
  genericRedirect(history, "/races", isDesktop, "Races");
};

export const goToProgramPage = (
  history: History,
  trackCode: string,
  trackName: string,
  raceNumber: number,
  isGreyHound: boolean,
  isDesktop = false
) => {
  genericRedirect(
    history,
    buildRaceUrl(trackCode, trackName, raceNumber, isGreyHound),
    isDesktop,
    "Program Page"
  );
};

export const hasRoutePath = (path: string, config: Array<{ path: string }>) =>
  config.some((route) => {
    if (route.path === "*") {
      return false;
    }
    if (route.path === path) {
      return true;
    }

    const regexPattern = new RegExp(
      // eslint-disable-next-line no-useless-escape
      `^${route.path.replace(/:[^\/]+/g, "[^/]+")}$`
    );

    return regexPattern.test(path);
  });
