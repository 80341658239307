import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Video = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 22.8797C18.0087 22.8797 22.8797 18.0087 22.8797 12C22.8797 5.99133
    18.0087 1.12034 12 1.12034C5.99133 1.12034 1.12034 5.99133 1.12034
    12C1.12034 18.0087 5.99133 22.8797 12 22.8797ZM8.62501 17.2318V6.755L16.89
    12.0078L8.62501 17.2318Z"
    fill={backgroundColor}
    fillOpacity="1"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
