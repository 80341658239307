// @flow
import { noop } from "lodash";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import type { Device } from "@tvg/types/Device";
import useFakeInput from "./useFakeInput";

export const toggleBodyClass = (
  show: boolean,
  bodyPositionY: number,
  setBodyPositionY: UnaryFn<number, void>,
  device: Device
) => {
  const isNotDesktop = /mobile|tablet/.test(device);
  if (typeof window !== "undefined" && document.body) {
    if (show) {
      setBodyPositionY(window.pageYOffset);
      // $FlowFixMe
      document.body.style.top = `-${window.pageYOffset}px`;
    } else {
      window.scrollTo(0, bodyPositionY);
      // $FlowFixMe
      document.body.style.top = "";
    }
    return isNotDesktop && document.body.classList.toggle("modal", show);
  }

  return undefined;
};

export const closeModalWrapper = (
  callback: NullaryFn<mixed> = noop,
  {
    useFakeInput: propsUseFakeInput,
    device,
    isLastModal,
    setIsOpen,
    bodyPositionY,
    setBodyPositionY
  }: {
    useFakeInput: boolean,
    device: Device,
    isLastModal: boolean,
    setIsOpen: UnaryFn<boolean, void>,
    bodyPositionY: number,
    setBodyPositionY: UnaryFn<number, void>
  }
) => {
  if (propsUseFakeInput) {
    useFakeInput();
  }

  window.focus();
  setIsOpen(false);
  callback();

  if (isLastModal) {
    toggleBodyClass(false, bodyPositionY, setBodyPositionY, device);
  }
};
