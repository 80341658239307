import React from "react";
import formatCurrency from "@tvg/formatter/currency";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import { InfoAlertProps } from "../types";
import FullBalance from "../../messages/balance";
import { hasMorePlayableBalance } from "../../common/balanceUtils";

export const InfoAlert = ({
  showInfoMessage,
  withdrawalLimit,
  capiMaxWithdrawAmount,
  setShowInfoMessage,
  accountsOnFile
}: InfoAlertProps) => {
  if (
    !showInfoMessage ||
    !hasMorePlayableBalance(
      withdrawalLimit,
      capiMaxWithdrawAmount,
      accountsOnFile
    )
  )
    return null;

  const pBalance = formatCurrency(capiMaxWithdrawAmount);
  const wBalance = formatCurrency(withdrawalLimit);

  return (
    <FullBalance
      isOpen={showInfoMessage}
      onClose={() => setShowInfoMessage(false)}
      pBalance={pBalance}
      wBalance={wBalance}
      onContact={openLiveChat}
    />
  );
};
