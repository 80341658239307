import styled from "styled-components/native";
import { View } from "react-native";

import { compose, variant } from "../../theming/styledSystem";

import { TooltipContentProps } from "./types";
import { useQaLabel } from "../../hooks/useQaLabel";

const styles = compose(
  variant({
    prop: "variant",
    variants: {
      dark: {
        backgroundColor: "blue_accent.900"
      },
      light: {
        backgroundColor: "white.900"
      }
    }
  })
);

export const Content = styled(View).attrs<TooltipContentProps>((props) => ({
  ...(props.qaLabel && useQaLabel(props.qaLabel))
}))<TooltipContentProps>`
  ${styles};
  border-radius: ${({ theme }) => theme.radii.s};
  padding: ${({ theme }) => theme.space["space-3"]};
  margin: ${({ theme }) => theme.space["space-1"]};
  box-shadow: ${({ theme }) => theme.elevations.medium};
  justify-content: center;
`;
