import React from "react";
import styled, { keyframes } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

type Props = {
  stroke?: string;
  fill?: string;
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.span`
  animation: ${rotate} 2s linear infinite;
  display: inline-flex;
`;

const SpinningWheel = ({
  stroke = buildColor("green", "400"),
  fill = buildColor("green", "800")
}: Props) => (
  <Container>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="14.75" stroke={stroke} strokeWidth="2.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 15.9987C2.5 8.53747 8.51555 2.5 15.9225 2.5C19.8412 2.5 23.3675 4.18736 25.824 6.88495C26.2888 7.39539 27.0794 7.43237 27.5898 6.96756C28.1003 6.50275 28.1372 5.71216 27.6724 5.20172C24.7637 2.0075 20.5761 0 15.9225 0C7.12263 0 0 7.16899 0 15.9987C0 24.8285 7.12263 31.9975 15.9225 31.9975C20.5761 31.9975 24.7637 29.99 27.6724 26.7957C28.1372 26.2853 28.1003 25.4947 27.5898 25.0299C27.0794 24.5651 26.2888 24.6021 25.824 25.1125C23.3675 27.8101 19.8412 29.4975 15.9225 29.4975C8.51555 29.4975 2.5 23.46 2.5 15.9987Z"
        fill={fill}
      />
    </svg>
  </Container>
);

export default SpinningWheel;
