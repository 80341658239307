import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Result = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3743 8.99929C18.3743 12.5197 15.5204 15.3736 12 15.3736C8.47958
      15.3736 5.62571 12.5197 5.62571 8.99929C5.62571 5.47887 8.47958 2.625 12
      2.625C15.5204 2.625 18.3743 5.47887 18.3743 8.99929ZM12.0014
      10.9912C13.1007 10.9912 13.9919 10.1001 13.9919 9.00078C13.9919 7.90149
      13.1007 7.01033 12.0014 7.01033C10.9021 7.01033 10.011 7.90149 10.011
      9.00078C10.011 10.1001 10.9021 10.9912 12.0014 10.9912Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M10.125 15.0933V21.3667H13.875V15.0934M18.3743 8.99929C18.3743 12.5197
      15.5204 15.3736 12 15.3736C8.47958 15.3736 5.62571 12.5197 5.62571
      8.99929C5.62571 5.47887 8.47958 2.625 12 2.625C15.5204 2.625 18.3743
      5.47887 18.3743 8.99929ZM13.9919 9.00078C13.9919 10.1001 13.1007 10.9912
      12.0014 10.9912C10.9021 10.9912 10.011 10.1001 10.011 9.00078C10.011
      7.90149 10.9021 7.01033 12.0014 7.01033C13.1007 7.01033 13.9919 7.90149
      13.9919 9.00078Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);
