import React from "react";
import { get } from "lodash";
import {
  openDepositFundsModal,
  openMoneyPakFundsModal,
  openOtherPaymentDepositModal,
  openPayNearMeFundsModal
} from "@tvg/sh-lib-paws/redux/slices/depositFundsModalSlice";
import {
  openWithdrawFundsModal,
  setIsWireTransferFundsModalOpen
} from "@tvg/sh-lib-paws/redux/slices/withdrawFundsModalSlice";

import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import { Dispatch } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { gtmOtherPaymentMethodsOpenTab } from "../../gtm";
import { desktopNavigation } from "../../utils";
import type {
  CreatePMToggles,
  PaymentTypeOption,
  PaymentType,
  UnaryFn
} from "../../types";

export const onClickDeposit =
  (
    pmToggles: CreatePMToggles,
    otherPaymentType: string,
    payment: PaymentTypeOption,
    paymentType: PaymentType,
    dispatch: Dispatch,
    setActiveOption: UnaryFn<string | null, void> = () => {},
    history?: {
      push: (a: string) => void;
    }
  ) =>
  (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveOption(null);
    if (get(pmToggles, `${otherPaymentType}`, false)) {
      if (payment.paymentType === PaymentTypeEnum.MONEY_PAK) {
        dispatch(openMoneyPakFundsModal());
      } else if (
        payment.paymentType === PaymentTypeEnum.CHECK ||
        payment.paymentType === PaymentTypeEnum.WIRE_TRANSFER ||
        payment.paymentType === PaymentTypeEnum.CASH
      ) {
        dispatch(openOtherPaymentDepositModal(payment.paymentType));
        gtmOtherPaymentMethodsOpenTab({
          paymentType: payment.paymentType
        });
      } else if (payment.paymentType === PaymentTypeEnum.PAY_NEAR_ME) {
        dispatch(openPayNearMeFundsModal());
      } else {
        dispatch(openDepositFundsModal(null));
      }
    } else {
      desktopNavigation({
        event,
        paymentType,
        paymentId: payment.id,
        history
      });
    }
  };

export const onClickWithdraw =
  (
    isWireTransferAutomationToggleOn: boolean,
    pmToggles: CreatePMToggles,
    paymentType: PaymentType,
    payment: PaymentTypeOption,
    dispatch: Dispatch,
    setActiveOption: UnaryFn<string | null, void> = () => {},
    history?: {
      push: (a: string) => void;
    }
  ) =>
  (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveOption(null);
    if (get(pmToggles, `${paymentType}`, false)) {
      if (
        payment.paymentType === PaymentTypeEnum.CASH ||
        (!isWireTransferAutomationToggleOn &&
          payment.paymentType === PaymentTypeEnum.WIRE_TRANSFER)
      ) {
        dispatch(openOtherPaymentDepositModal(payment.paymentType));
        gtmOtherPaymentMethodsOpenTab({
          paymentType: payment.paymentType
        });
      } else if (
        isWireTransferAutomationToggleOn &&
        payment.paymentType === PaymentTypeEnum.WIRE_TRANSFER
      ) {
        batch(() => {
          dispatch(openWithdrawFundsModal());
          dispatch(setIsWireTransferFundsModalOpen(true));
        });
      } else {
        dispatch(openWithdrawFundsModal());
      }
    } else {
      desktopNavigation({
        event,
        paymentType,
        navigationType: "withdraw",
        paymentId: payment.id,
        history
      });
    }
  };
