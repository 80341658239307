import React from "react";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { question } from "@tvg/atomic-ui/_static/Icons/icons";
import { ExclamationFilled } from "@tvg/atomic-ui/_static/Icons/paws";
import { Props, Variant } from "./types";
import { Container, Text } from "./styled-components";

const handleIconVariants = (variant: Variant) => {
  switch (variant) {
    case "fee":
      return (
        <Icon
          icon={question}
          color={buildColor("blue_accent", "500")}
          size={16}
        />
      );
    default:
    case "warning":
      return <ExclamationFilled width="16" height="16" />;
  }
};

const Flag = ({
  text,
  textColor,
  bgColor,
  variant = "warning",
  className,
  qaLabel = "flag",
  onClick,
  size = "normal",
  showIcon = true
}: Props) => {
  const styles = {
    fee: {
      color: buildColor("blue_accent", "500"),
      backgroundColor: buildColor("blue_accent", "100")
    },
    warning: {
      color: buildColor("orange", "800"),
      backgroundColor: buildColor("orange", "000")
    }
  };

  return (
    <Container
      bgColor={bgColor || styles[variant || "warning"].backgroundColor}
      className={className}
      data-qa-label={qaLabel}
      {...(onClick && { onClick })}
      size={size}
    >
      {showIcon && handleIconVariants(variant)}
      <Text
        color={textColor || styles[variant || "warning"].color}
        data-qa-label={`${qaLabel}-text`}
        size={size}
      >
        {text}
      </Text>
    </Container>
  );
};

export default Flag;
