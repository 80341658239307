import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const AlertOff = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M4.56276 15.75H19.5628L18.7381 13.7711C18.2532 12.6074 18.0768 11.3428
      17.9821 10.0856C17.7829 7.44307 16.7427 3 12 3C7.18575 3 6.18667 7.57825
      6.00957 10.2041C5.9299 11.3854 5.77999 12.5741 5.35625 13.6797L4.56276
      15.75Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M1.5 1.5L22.5 22.5M18.7186 13.875L18.6438 13.6797C18.2201 12.5741
      18.0702 11.3854 17.9905 10.2041C17.8134 7.57826 16.8143 3 12 3C10.7662 3
      9.78302 3.30068 9 3.79213M6.72136 6.75C6.27862 7.89743 6.09156 9.11181
      6.01817 10.0833C5.9232 11.3404 5.74689 12.6074 5.26195 13.7711L4.43729
      15.75H9M9 15.75V16.5C9 18.1569 10.3431 19.5 12 19.5C13.6569 19.5 15
      18.1569 15 16.5L15 15.75H9Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
