import useTheme from "./useTheme";
import { Colors } from "../types";

/**
 * Get hexadecimal color from theme based on the token
 * @param token - e.g., "green.500"
 * @returns hexadecimal color - e.g., "#38AB4F"
 */
export const useColor = (token: string): string => {
  const { colors } = useTheme();
  const [color, variation] = token.split(".");
  const [, codeColor] = Object.entries(colors[color as keyof Colors]).filter(
    (val) => val[0] === variation
  )[0];
  return codeColor;
};
