import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system/src/_static/colorPalette";
import { fontRegular } from "@tvg/atomic-ui/_static/Typography";

export const AddressPickerContainer = styled.div`
  background-color: ${buildColor("white", "900")};
  border-bottom: 1px solid ${buildColor("blue", "100")};
  margin-bottom: 20px;
  margin-right: -12px;
`;

export const AddressContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  padding: 12px 12px 12px 0;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${buildColor("blue", "100")};
  ${({ isActive }: { isActive: boolean }) =>
    isActive &&
    css`
      background-color: ${buildColor("blue_accent", "000")};
    `}
`;

export const AddressInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddressInfoText = styled.span`
  font-family: ${fontRegular};
  font-size: 14px;
  line-height: 125%;
  font-weight: normal;
  color: ${buildColor("grey", "900")};
`;

export const CustomerSupportText = styled.span`
  font-family: ${fontRegular};
  font-size: 14px;
  line-height: 125%;
  font-weight: normal;
  color: ${buildColor("grey", "800")};
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid ${buildColor("blue", "100")};
`;

export const CustomerServiceButton = styled.div`
  margin-left: 4px;
  text-decoration: none;
  color: ${buildColor("blue_accent", "500")};
  &:hover {
    cursor: pointer;
  }
`;

export const AddressTitle = styled.h3`
  font-family: ${fontRegular};
  font-size: 14px;
  color: ${buildColor("grey", "900")};
  padding-bottom: 8px;
`;
