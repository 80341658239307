import styled, { css } from "styled-components/native";
import { View } from "react-native";

export const Container = styled(View)`
  flex-direction: row;
`;

export const ButtonGroup = styled(View)<{
  hasMaxWidth?: boolean;
  isSimpleBetButtons?: boolean;
}>`
  flex-direction: row;
  border-radius: ${({ theme }) => theme.radii.xs};
  box-shadow: ${({ theme }) => theme.elevations.low};
  overflow: hidden;
  ${({ hasMaxWidth, isSimpleBetButtons }) =>
    hasMaxWidth &&
    css`
      width: ${isSimpleBetButtons ? "151px" : "220px"};
    `}
`;
