import styled from "styled-components";

export const StyledStepBarContainer = styled.div`
  flex: 1;
  margin-left: 1px;
  margin-right: 1px;

  &:first-child {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  &:last-child {
    margin-right: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
`;

export const StyledStepBar = styled.div<{
  isCompleted?: boolean;
}>`
  height: 4px;
  border-radius: 0 4px 4px 0;
  background: ${({ isCompleted }) =>
    isCompleted
      ? "var(--fd-colors-system-positive-content-accent)"
      : "var(--fd-colors-component-data-input-background-base)"};
`;

export const StepParagraphContainer = styled.div`
  display: flex;
  justify-content: center;
`;
