import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "redux";
import formatCurrency from "@tvg/formatter/currency";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import Select from "@tvg/atomic-ui/_molecule/Select";
import { matchDepositPropositions } from "@tvg/sh-lib-promos-onboarding/utils/matchDepositPropositions";
import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import PromosOnboarding from "@tvg/promos-onboarding";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { Form } from "@unform/web";
import { get, debounce } from "lodash";
import {
  fontNormal,
  fontCondensedNormal
} from "@tvg/atomic-ui/_static/Typography";
import {
  getDepositFundsModal,
  getDepositBetcashUserAgreementHtml,
  getEcheckCreationMessages
} from "@tvg/sh-lib-paws/redux/selectors";
import { getBalance } from "@urp/store-selectors";
import { FormHandles } from "@unform/core";
import TermsAndConditions from "../../FundsModal/common/TermsAndConditions";
import CallToAction from "../../FundsModal/common/TermsAndConditions/callToAction";
import {
  ButtonSubmit,
  Container,
  IlustrationWrapper,
  LastInputsWrapper,
  TermsAndConditionsCTA,
  ModalWrapper,
  TermsAndConditionsContainer
} from "./styled-components";
import type {
  Props,
  ActiveInput,
  OpenFundsModalProps,
  ECheckCreationMessages
} from "./types";
import {
  states,
  getBankName,
  validateAndSubmit,
  handleFormValidation,
  handleClickOutsideForm,
  handleOnCloseModal,
  handleBlur
} from "./handlers";
import {
  accountNumberSchema,
  bankNameSchema,
  dlStateSchema,
  drivingLicenceSchema,
  routingNumberSchema
} from "./schemaValidations";
import { FormData } from "../../../types";
import { isMobile } from "../../../utils";
import FormInput from "./FormInput";
import { getIllustration } from "./methods";

const CreateACHModal = ({
  onClose,
  isOpen,
  method,
  promosOnboarding
}: Props) => {
  const formRef = useRef<FormHandles>(null);
  const formInnerContainerRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [isFormFilled, setFormFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsOpen, setTermsOpen] = useState(false);
  const [isValidRoutingNumber, setIsValidRoutingNumber] = useState(false);
  const [disableFormWhileTermsAreOpen, setDisableFormWhileTermsAreOpen] =
    useState(false);

  const {
    formData
  }: {
    formData: FormData;
  } = useSelector(getDepositFundsModal);

  const content: ECheckCreationMessages = useSelector(
    getEcheckCreationMessages
  );

  const userBalance = useSelector<Store, number>(getBalance);
  const termsAndConditionsContent: string = useSelector(
    getDepositBetcashUserAgreementHtml
  );
  const [activeInput, setActiveInput] = useState<ActiveInput>(null);

  const closeModalProps = {
    onOverlayClick: () =>
      handleOnCloseModal(
        onClose,
        isValidRoutingNumber ? setIsValidRoutingNumber : null
      ),
    onClose: () =>
      handleOnCloseModal(
        onClose,
        isValidRoutingNumber ? setIsValidRoutingNumber : null
      )
  };

  const termsAndConditionsModalProps = {
    title: get(content, "termsAndConditions.modalTitle"),
    onBack: () => setTermsOpen(false),
    subtitle: "",
    isTitleCenter: false
  };

  const eCheckModalProps = {
    isOpen,
    title: content.title,
    subtitle: `BALANCE ${formatCurrency(userBalance)}`,
    subtitleFontFamily: fontCondensedNormal,
    qaLabel: isTermsOpen ? "echeck-terms-modal" : "create-echeck-modal",
    offsetLeft: 0,
    offsetRight: 0,
    offsetTop: 55,
    isTitleCapitalized: false,
    isContentTransparent: true,
    useModalHeaderV3: true,
    isTitleCenter: true,
    isFullWidth: true,
    ...closeModalProps
  };

  const extraModalProps = !isMobile
    ? {
        isFullHeight: false,
        animation: "fade",
        fixedWidth: "399px",
        hasRoundedCorners: true,
        isFluidWidth: true
      }
    : {
        animation: "bottom",
        hasRoundedCorners: false
      };

  const formInputBaseProps = {
    formRef,
    setActiveInput
  };

  const modalProps = !isTermsOpen
    ? eCheckModalProps
    : { ...eCheckModalProps, ...termsAndConditionsModalProps };

  useEffect(() => {
    if (!isOpen) {
      setFormFilled(false);
      setIsLoading(false);
      setTermsOpen(false);
      setIsValidRoutingNumber(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (formData && formRef && formRef.current) {
      const formDataUsable: OpenFundsModalProps = {
        routingNumber: formData.bankRoutingNumber,
        accountNumber: formData.bankAccountNumber,
        bankName: formData.bankName,
        drivingLicence: formData.dlNumber,
        dlState: formData.dlState
      };
      // @ts-ignore
      formRef.current.setData(formDataUsable);
    }
  }, [isOpen, formData]);

  useEffect(() => {
    if (isOpen) {
      handleFormValidation(formRef, content, setFormFilled);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isTermsOpen) {
      if (formRef && formRef.current && activeInput) {
        const input = formRef.current.getFieldRef(activeInput);
        input.current.blur();
        setDisableFormWhileTermsAreOpen(true);
      }
    } else {
      setDisableFormWhileTermsAreOpen(false);
    }
  }, [isTermsOpen]);

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        !formInnerContainerRef.current ||
        formInnerContainerRef.current.contains(event.target as Node)
      ) {
        return;
      }
      handleClickOutsideForm(event);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [formInnerContainerRef, handleClickOutsideForm]);

  useEffect(() => {
    if (isTermsOpen && typeof window !== "undefined") {
      const element = document.querySelector('[data-qa-label="modal-content"]');
      if (element && element.scrollTo) {
        element.scrollTo(0, 0);
      }
    }
  }, [isTermsOpen]);

  const debouncedGetBankName = useCallback(
    debounce(
      // eslint-disable-next-line
      (value, formRef, setIsValidRoutingNumber, content, setFormFilled) => {
        getBankName(
          value,
          formRef,
          setIsValidRoutingNumber,
          content,
          setFormFilled
        );
      },
      300
    ),
    []
  );

  return (
    <ModalV2 {...modalProps} {...extraModalProps}>
      {() => (
        <ModalWrapper data-qa-label="create-ach-modal-wrapper">
          <PromosOnboarding
            placeToRender={StoryblokPlaceName.WALLET_DEPOSIT_TOP}
            isShown={matchDepositPropositions(
              promosOnboarding,
              get(method, "paymentType", "")
            )}
          />
          <Container
            isMobile={isMobile}
            data-qa-label="echeck-creation-container"
            isTermsOpen={isTermsOpen}
          >
            <div ref={formInnerContainerRef}>
              <IlustrationWrapper data-qa-label="echeck-ilustration">
                {getIllustration(activeInput)}
              </IlustrationWrapper>
              <fieldset disabled={disableFormWhileTermsAreOpen}>
                <Form
                  data-qa-label="echeck-form"
                  onSubmit={() =>
                    validateAndSubmit(
                      content,
                      formRef,
                      setIsLoading,
                      dispatch,
                      method
                    )
                  }
                  ref={formRef}
                >
                  <FormInput
                    name="routingNumber"
                    qaLabel="routing-number-echeck"
                    placeholder={content.routingNumber.placeholder}
                    label={content.routingNumber.label}
                    schema={routingNumberSchema(
                      content.routingNumber.validations
                    )}
                    customHandleChange={(value: string) => {
                      handleFormValidation(formRef, content, setFormFilled);
                      debouncedGetBankName(
                        value,
                        formRef,
                        setIsValidRoutingNumber,
                        content,
                        setFormFilled
                      );
                    }}
                    marginBottom={16}
                    maxlength={9}
                    isOnlyNumber
                    autoFocus
                    {...formInputBaseProps}
                  />
                  <FormInput
                    name="accountNumber"
                    qaLabel="account-number-echeck"
                    label={content.accountNumber.label}
                    placeholder={content.accountNumber.placeholder}
                    schema={accountNumberSchema(
                      content.accountNumber.validations
                    )}
                    customHandleChange={() =>
                      handleFormValidation(formRef, content, setFormFilled)
                    }
                    marginBottom={16}
                    maxlength={17}
                    isOnlyNumber
                    {...formInputBaseProps}
                  />
                  <FormInput
                    name="bankName"
                    qaLabel="bank-name-echeck"
                    label={content.bankName.label}
                    placeholder={content.bankName.placeholder}
                    schema={bankNameSchema(content.bankName.validations)}
                    customHandleChange={() =>
                      handleFormValidation(formRef, content, setFormFilled)
                    }
                    marginBottom={16}
                    isDisabled={isValidRoutingNumber}
                    {...formInputBaseProps}
                  />
                  <LastInputsWrapper>
                    <FormInput
                      name="drivingLicence"
                      qaLabel="driving-licence-echeck"
                      label={content.drivingLicence.label}
                      placeholder={content.drivingLicence.placeholder}
                      maxlength={35}
                      schema={drivingLicenceSchema(
                        content.drivingLicence.validations
                      )}
                      customHandleChange={() =>
                        handleFormValidation(formRef, content, setFormFilled)
                      }
                      {...formInputBaseProps}
                    />
                    <Select
                      name="dlState"
                      label={content.dlState.label}
                      qaLabel="dlState-echeck"
                      shouldGreyFirst
                      customHandleOnBlur={handleBlur(
                        "dlState",
                        dlStateSchema(content.dlState.validations),
                        formRef,
                        setActiveInput
                      )}
                      handleCustomChange={() => {
                        handleFormValidation(formRef, content, setFormFilled);
                        setActiveInput(null);
                      }}
                      placeholder={content.dlState.placeholder}
                      options={states}
                      clearErrorOnFocus
                    />
                  </LastInputsWrapper>
                  <ButtonSubmit
                    size="bigger"
                    hasRoundedCorners
                    isStretched
                    isUppercase={false}
                    hasShadow
                    isDisabled={!isFormFilled || isLoading}
                    isLoading={isLoading}
                    fontFamily={fontNormal}
                    qaLabel="eCheck-submit-btn"
                    loadingBorderColor={buildColor("blue_accent", 400)}
                    loadingBackgroundColor={buildColor("blue_accent", 500)}
                    removeOpacity={isLoading}
                  >
                    {content.ctaText}
                  </ButtonSubmit>
                </Form>
              </fieldset>
            </div>
            <TermsAndConditionsCTA data-qa-label="echeck-terms-text">
              <CallToAction
                text={content.termsAndConditions}
                setTermsOpen={setTermsOpen}
                qaLabel="ach-modal-terms-clickable-text"
              />
            </TermsAndConditionsCTA>
          </Container>

          {isTermsOpen && (
            <TermsAndConditionsContainer data-qa-label="ach-modal-terms-container">
              <TermsAndConditions
                termsAndConditionsContent={termsAndConditionsContent}
                qaLabel="echeck-modal-terms-text"
              />
            </TermsAndConditionsContainer>
          )}
        </ModalWrapper>
      )}
    </ModalV2>
  );
};

export default CreateACHModal;
