// @flow

import styled, { css } from "styled-components";
import { buildText } from "../../_atom/Text";
import DefaultButton from "../../_atom/Buttons/default";
import buildColor from "../../_static/ColorPalette";
import { fontNormal } from "../../_static/Typography";
import Icon from "../../_static/Icons";

export const PaginationWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => {
    let returnCSS = "";
    switch (props.theme) {
      case "tutorials":
        returnCSS = css`
          margin-top: 22px;

          & > button {
            min-width: 60px;
            text-transform: uppercase;
            background-color: ${buildColor("white", "0")};

            &:first-child {
              text-align: left;
            }

            svg {
              fill: ${buildColor("blue_accent", "500")};
            }
          }
        `;
        break;
      case "topRaces":
        returnCSS = css`
          max-width: 64px;
          border: 1px solid ${buildColor("blue", "100")};
          background-color: ${buildColor("blue_accent", "000")};
          border-radius: 4px;

          &:hover {
            cursor: pointer;
          }

          & > button {
            background-color: ${buildColor("white", "0")};
            width: 32px;
            height: 32px;
            padding: 8px;

            svg {
              fill: ${buildColor("blue_accent", "500")};
            }

            &:hover {
              background-color: ${buildColor("blue_accent", "100")};
            }
          }
        `;
        break;
      default:
        returnCSS = css`
          background-color: ${buildColor("white", "0")};
          padding: 8px 12px;
          padding-bottom: calc(constant(safe-area-inset-bottom) + 8px);
          padding-bottom: calc(env(safe-area-inset-bottom) + 8px);

          & > button {
            background-color: ${buildColor("white", "0")};
            min-width: 40px;
            height: 40px;

            &:first-child:active,
            :last-child:active {
              background-color: ${buildColor("blue", "100")};
            }
          }
        `;
        break;
    }
    return returnCSS;
  }};
`;

export const PaginationBullet = styled.span`
  background-color: ${(props) =>
    props.active
      ? buildColor("blue_accent", "500")
      : buildColor("blue_accent", "100")};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  padding: 0;
  margin-right: 8px;
`;

export const PagesWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const TopRacesButtonDefault = styled(DefaultButton)`
  border: none;
  margin: 0;
`;

export const NextWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: ${buildColor("blue_accent", "500")};
  font-weight: bold;
`;

export const NavigationWrapper = styled.span`
  min-width: 80px;
  display: flex;
  justify-content: ${({ alignRight }) =>
    alignRight ? "flex-end" : "flex-start"};
  align-items: center;
  font-size: 12px;
  line-height: 17px;
  ${({ isHidden }) =>
    isHidden &&
    css`
      visibility: hidden;
    `}
`;

export const WordWrapper = styled.span`
  font-family: ${fontNormal};
  padding: 0 8px;
`;

export const SelectorContainer = styled.div`
  width: 98px;
  height: 32px;
  overflow: hidden;
  flex: 0 0 auto;
  margin: 0 8px;
  border: 1px solid ${buildColor("blue", "100")};
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(17, 43, 68, 0.06), 0 1px 2px rgba(17, 43, 68, 0.08);
  border-radius: 4px;
  position: relative;
`;

export const LabelsWrapper = styled(
  buildText({
    tag: "span",
    fontSize: 14,
    color: buildColor("grey", "900"),
    ellipsis: true
  })
)`
  padding: 6px 32px 6px 8px;
  line-height: 18px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PageSelected = styled(
  buildText({
    tag: "span",
    fontSize: 14,
    color: buildColor("grey", "900")
  })
)`
  line-height: 19px;
  font-weight: 500;
  vertical-align: baseline;
`;

export const SelectIcon = styled(Icon)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
`;

export const SelectList = styled.select.attrs({
  "data-qa-label": "pageSelector"
})`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;
