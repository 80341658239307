import { useSelector } from "react-redux";
import { PaymentTypeOption, SimpleErrorMessage } from "@tvg/wallet/src/types";
import {
  getSelectedPaymentMethod,
  getAccountsOnFileMethods
} from "@tvg/sh-lib-paws/redux/selectors";
import {
  filterModalCommonActions,
  getCAPIMessageByType,
  getModalCommonActions
} from "@tvg/wallet/src/utils";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { NullaryFn } from "@tvg/ts-types/Functional";

export const useErrorMessageBuilder = (
  errorCode: string,
  isCapiDefault: boolean,
  defaultMessage: SimpleErrorMessage,
  onRetryMethod?: NullaryFn<void>,
  onCloseMethod?: NullaryFn<void>,
  onContactMethod?: NullaryFn<void>,
  onConfirmationMethod?: NullaryFn<void>,
  onCancelMethod?: NullaryFn<void>,
  retryCount: number = 0
) => {
  const { paymentType }: PaymentTypeOption = useSelector(
    getSelectedPaymentMethod
  );

  const capiMessageName = paymentType ? getCAPIMessageByType(paymentType) : "";

  const pawsErrorMessages = useSelector((store) =>
    parseCapiMessage(store, `capi.messages.${capiMessageName}`, {})
  );

  const accountsOnFile: PaymentTypeOption[] = useSelector(
    getAccountsOnFileMethods
  );

  const validErrorCode = isEmpty(pawsErrorMessages[errorCode])
    ? "default"
    : errorCode;

  const allActions = getModalCommonActions(
    onConfirmationMethod,
    onCloseMethod,
    onCancelMethod,
    onRetryMethod,
    onContactMethod,
    retryCount
  );

  const errorByCode = pawsErrorMessages[validErrorCode];
  const messageData = useMemo(
    () =>
      (errorCode || isCapiDefault) && !isEmpty(errorByCode)
        ? {
            title: errorByCode.title,
            description: errorByCode.description,
            type: errorByCode.type,
            actions: filterModalCommonActions(
              allActions,
              errorByCode.actions,
              retryCount
            )
          }
        : {
            ...defaultMessage
          },
    [errorByCode, isCapiDefault, errorCode, accountsOnFile]
  );

  return messageData;
};
