import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Settings = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <Path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.5 2.625H13.5L14.1218 5.11853L15.3653 5.63392L17.5684 4.31021L19.6897
    6.43153L18.3661 8.63467L18.8815 9.87823L21.375 10.5V13.5L18.8816
    14.1218L18.3661 15.3654L19.6898 17.5685L17.5685 19.6898L15.3653
    18.3662L14.1218 18.8816L13.5 21.375H10.5L9.87822 18.8815L8.63467
    18.3661L6.43149 19.6898L4.31017 17.5685L5.63392 15.3653L5.11853
    14.1218L2.625 13.5V10.5L5.11857 9.87826L5.63396 8.63477L4.31021
    6.43158L6.43153 4.31026L8.63473 5.63399L9.87824 5.11857L10.5 2.625ZM12
    15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431
    9 12C9 13.6569 10.3431 15 12 15Z"
    fill={backgroundColor}
    fillOpacity="1"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default Settings;
