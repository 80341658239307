import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const ErrorContainer = styled.div<{ minHeight?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  background-color: ${buildColor("white", "100")};
  padding: 0 12px;
  min-height: ${({ minHeight }) => minHeight};
  align-items: center;
`;
