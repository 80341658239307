import React, { useMemo } from "react";
import { get, sortBy, isEmpty } from "lodash";
import DesktopWalletMask from "@tvg/atomic-ui/_static/Masks/desktopWallet";
import {
  getPawsContent,
  getInfoPanelModal,
  getPawsACHAllowAddToggle
} from "@tvg/sh-lib-paws/redux/selectors";
import { Store } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { closeInfoPanelModal } from "@tvg/sh-lib-paws/redux/slices/infoPanelSlice";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import tvgConf from "@tvg/conf";
import { getMazoomaToggle } from "@tvg/sh-lib-paws/redux/toggles";
import parseCapiMessage from "@tvg/utils/capiUtils";
import type { UnaryFn, OnNewMethodArgs } from "../../types";
import {
  PaymentType,
  PaymentTypeInfo,
  PaymentTypeItem,
  PaymentTypeTag
} from "../../types";
import DesktopListItem from "../DesktopListItem";
import {
  PaymentTable,
  PaymentWrapper,
  PaymentTypeRow
} from "./styled-components";
import DesktopCardsOnFile from "../DesktopCardsOnFile";
import AlertMessagesModal from "../AlertMessagesModal";
import { canAddNewAccount, getTagsByPaymentType } from "../../utils";
import { mapAllPaymentOptions, buildOtherMethods } from "./utils";
import type { Props } from "./types";

const renderPaymentList = (
  methods: PaymentTypeItem[],
  allMethods: PaymentTypeItem[],
  methodsInfos: { [key in PaymentType]: PaymentTypeInfo },
  displayAddMethod: boolean,
  activeOption: string | null,
  setActiveOption: UnaryFn<string | null, void>,
  hasTooltips: boolean = false,
  onNewPaymentMethod: UnaryFn<OnNewMethodArgs, void>,
  showMZMPm: boolean = true,
  allowCreateACH: boolean = false,
  tags: PaymentTypeTag[] = []
) =>
  sortBy(methods, "priority")
    .filter(
      (item) =>
        !(
          (item.paymentType === PaymentTypeEnum.MAZOOMA && !showMZMPm) ||
          (item.paymentType === PaymentTypeEnum.ECHECK &&
            !allowCreateACH &&
            isEmpty(item.paymentTypeOptions))
        )
    )
    .map((method: PaymentTypeItem) => {
      const canDisplayAddMethod = displayAddMethod
        ? canAddNewAccount(
            method.paymentType,
            get(method, "paymentTypeOptions", []),
            { allowCreateACH }
          )
        : false;

      const isOPM = method.paymentType === PaymentTypeEnum.OTHER_PAYMENT;
      const isDepositsAvailable = !!(isOPM
        ? method.depositsEnabled
        : method.depositsAvailable);
      const isWithdrawalsAvailable = !!(isOPM
        ? method.withdrawalsEnabled
        : method.withdrawalsAvailable);
      const isUnderMaintenance = isOPM
        ? method.paymentTypeOptions.every((pm) => !pm.paymentTypeAvailable)
        : !method.paymentTypeAvailable;

      return (
        <PaymentTypeRow
          data-qa-label={
            isOPM
              ? "otherPaymentMethodsRow"
              : `paymentTypeRow-${method.paymentType}`
          }
          key={method.paymentType}
        >
          <DesktopListItem
            paymentType={method.paymentType}
            isUnderMaintenance={isUnderMaintenance}
            {...methodsInfos[method.paymentType]}
            isDisabled={!isDepositsAvailable}
            tags={getTagsByPaymentType(tags, method.paymentType)}
          />
          <DesktopCardsOnFile
            hasTooltips={hasTooltips}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            addMethodText={get(
              methodsInfos[method.paymentType],
              "addAccountText",
              ""
            )}
            method={method}
            onNewPaymentMethod={onNewPaymentMethod}
            allPaymentMethodOptions={mapAllPaymentOptions(allMethods)}
            displayAddMethod={
              !!(
                canDisplayAddMethod &&
                isDepositsAvailable &&
                method.paymentTypeAvailable
              )
            }
            disableAddPM={!isDepositsAvailable || !method.paymentTypeAvailable}
            disableChildrenMethods={
              (!isDepositsAvailable && !isWithdrawalsAvailable) ||
              (!isOPM && !method.paymentTypeAvailable)
            }
          />
        </PaymentTypeRow>
      );
    });

const DesktopWallet = ({
  activeOption,
  setActiveOption,
  isLoading,
  methods,
  methodsInfos,
  otherMethods,
  onNewPaymentMethod
}: Props) => {
  const dispatch = useDispatch();
  const otherPaymentMethods = buildOtherMethods(otherMethods, methodsInfos);

  const isInfoPanelModalOpen = useSelector(getInfoPanelModal);
  const pawsLabels = useSelector(getPawsContent);
  const isMZMToggleOn = useSelector(getMazoomaToggle);
  const allowCreateACH = useSelector<Store, boolean>(getPawsACHAllowAddToggle);
  const paymentTypeTags: PaymentTypeTag[] = useSelector((store) =>
    parseCapiMessage(store, "capi.messages.pawsPaymentTypeTags", {})
  );

  const cardLimitsLabels = get(pawsLabels, "paymentMessageModal.cardLimit", {});

  const allMethods = useMemo(
    () => [...methods, ...otherPaymentMethods],
    [methods, otherPaymentMethods]
  );

  return (
    <PaymentWrapper data-qa-label="walletWrapper">
      {isLoading ? (
        <DesktopWalletMask />
      ) : (
        <>
          <PaymentTable>
            {renderPaymentList(
              methods,
              allMethods,
              methodsInfos,
              true,
              activeOption,
              setActiveOption,
              true,
              onNewPaymentMethod,
              isMZMToggleOn,
              allowCreateACH,
              paymentTypeTags
            )}
            {otherPaymentMethods[0] &&
              otherPaymentMethods[0].paymentTypeOptions.length > 0 &&
              renderPaymentList(
                otherPaymentMethods,
                allMethods,
                methodsInfos,
                false,
                activeOption,
                setActiveOption,
                true,
                onNewPaymentMethod,
                isMZMToggleOn,
                allowCreateACH,
                paymentTypeTags
              )}
          </PaymentTable>
          <AlertMessagesModal
            isMobile={tvgConf().device === "mobile"}
            isOpen={isInfoPanelModalOpen as boolean}
            qaLabel="alert-messages"
            title={cardLimitsLabels.title}
            description={cardLimitsLabels.description}
            type="warning"
            onCloseMethod={() => dispatch(closeInfoPanelModal())}
            actions={[
              {
                text: cardLimitsLabels.primaryButton,
                onClick: () => dispatch(closeInfoPanelModal()),
                isStretched: true,
                isUppercase: false,
                qaLabel: "close"
              }
            ]}
          />
        </>
      )}
    </PaymentWrapper>
  );
};

export default DesktopWallet;
