import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Feedback = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M6 7.875H3.625C3.07272 7.875 2.625 8.32272 2.625 8.875L2.625
      16.4118C2.625 16.96 3.06942 17.4044 3.61765 17.4044V17.4044C4.16587
      17.4044 4.61029 17.8488 4.61029 18.3971V21.375L8.28799 17.6973C8.47553
      17.5098 8.72988 17.4044 8.9951 17.4044H15.125C15.6773 17.4044 16.125
      16.9567 16.125 16.4044V14.775"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <Path
      d="M6.375 3.625C6.375 3.07272 6.82272 2.625 7.375 2.625L20.375
      2.625C20.9273 2.625 21.375 3.07272 21.375 3.625V12.2132C21.375 12.7655
      20.9273 13.2132 20.375 13.2132H20.1691C19.6168 13.2132 19.1691 13.661
      19.1691 14.2132V17.625L15.0502 13.5061C14.8627 13.3186 14.6084 13.2132
      14.3431 13.2132L7.375 13.2132C6.82272 13.2132 6.375 12.7655 6.375
      12.2132L6.375 3.625Z"
      fill={backgroundColor}
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <Path
      d="M9.85217 6.93798C9.90606 6.77214 10.1407 6.77214 10.1946
      6.93798L10.3371 7.37654C10.3612 7.45071 10.4303 7.50092 10.5082
      7.50092H10.9694C11.1438 7.50092 11.2163 7.72405 11.0752 7.82654L10.7021
      8.09759C10.639 8.14343 10.6126 8.22467 10.6367 8.29884L10.7792
      8.73741C10.8331 8.90324 10.6433 9.04114 10.5022 8.93865L10.1292
      8.6676C10.0661 8.62177 9.98065 8.62177 9.91756 8.6676L9.5445
      8.93865C9.40343 9.04114 9.21362 8.90324 9.2675 8.73741L9.41 8.29884C9.4341
      8.22467 9.4077 8.14343 9.34461 8.09759L8.97155 7.82654C8.83048 7.72405
      8.90298 7.50092 9.07735 7.50092H9.53849C9.61647 7.50092 9.68558 7.45071
      9.70968 7.37654L9.85217 6.93798Z"
      fill="#5390ED"
    />
    <Path
      d="M13.7031 6.93798C13.7569 6.77214 13.9916 6.77214 14.0454
      6.93798L14.1879 7.37654C14.212 7.45071 14.2812 7.50092 14.3591
      7.50092H14.8203C14.9946 7.50092 15.0671 7.72405 14.9261 7.82654L14.553
      8.09759C14.4899 8.14343 14.4635 8.22467 14.4876 8.29884L14.6301
      8.73741C14.684 8.90324 14.4942 9.04114 14.3531 8.93865L13.9801
      8.6676C13.917 8.62177 13.8315 8.62177 13.7685 8.6676L13.3954
      8.93865C13.2543 9.04114 13.0645 8.90324 13.1184 8.73741L13.2609
      8.29884C13.285 8.22467 13.2586 8.14343 13.1955 8.09759L12.8224
      7.82654C12.6814 7.72405 12.7539 7.50092 12.9282 7.50092H13.3894C13.4674
      7.50092 13.5365 7.45071 13.5606 7.37654L13.7031 6.93798Z"
      fill="#5390ED"
    />
    <Path
      d="M17.5539 6.93798C17.6078 6.77214 17.8424 6.77214 17.8963
      6.93798L18.0388 7.37654C18.0629 7.45071 18.132 7.50092 18.21
      7.50092H18.6711C18.8455 7.50092 18.918 7.72405 18.7769 7.82654L18.4039
      8.09759C18.3408 8.14343 18.3144 8.22467 18.3385 8.29884L18.481
      8.73741C18.5348 8.90324 18.345 9.04114 18.204 8.93865L17.8309
      8.6676C17.7678 8.62177 17.6824 8.62177 17.6193 8.6676L17.2462
      8.93865C17.1052 9.04114 16.9154 8.90324 16.9692 8.73741L17.1117
      8.29884C17.1358 8.22467 17.1094 8.14343 17.0464 8.09759L16.6733
      7.82654C16.5322 7.72405 16.6047 7.50092 16.7791 7.50092H17.2402C17.3182
      7.50092 17.3873 7.45071 17.4114 7.37654L17.5539 6.93798Z"
      fill="#5390ED"
    />
  </>
);
export default Feedback;
