import React, { useRef, useState, useEffect } from "react";
import { Container, Field, Input } from "./styled-components";
import { handleChange, pasteHandler } from "./eventHandlers";
import { Props } from "./types";
import { renderError, renderIcon, renderLabel } from "./methods";

const InputField = ({
  value,
  onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.preventDefault(),
  isDisabled,
  className,
  hasFocus = false,
  isDesktop = false,
  label,
  onClick,
  onFocus,
  icon,
  onClickIcon,
  error,
  showLabelOnTop,
  placeholder = "",
  type = "normal",
  onPasteHandler,
  maxValidLimit
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [unformatedValue, setUnformatedValue] = useState("");
  const [setFixedRange, setFixedSelectionRange] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  useEffect(() => {
    if (unformatedValue && value) {
      if (
        !isDeleting &&
        !/^[0-9]$/.test(unformatedValue.charAt(start)) &&
        value.length > start
      ) {
        setStart(start + 1);
        setEnd(end + 1);
      }
    }
  }, [value, start, end]);

  useEffect(() => {
    if (inputRef?.current && maxValidLimit && hasFocus) {
      inputRef.current.setSelectionRange(start, end);
      setFixedSelectionRange(false);
    }
  }, [start, end, setFixedRange, hasFocus]);

  const shouldRenderIcon = !isDisabled && hasFocus && icon;

  const hasError = error && !isDisabled;

  return (
    <Container className={className}>
      <Field
        type={type}
        data-qa-label="value-container"
        isDisabled={isDisabled}
        hasFocus={hasFocus}
        {...(!hasFocus && onClick && { onClick })}
      >
        {label && renderLabel(showLabelOnTop, label, className)}
        <Input
          placeholder={placeholder}
          {...(onChangeValue && {
            onChange: handleChange(
              onChangeValue,
              setUnformatedValue,
              inputRef,
              maxValidLimit,
              setFixedSelectionRange,
              setIsDeleting,
              setStart,
              setEnd,
              value
            )
          })}
          {...(onPasteHandler && {
            onPaste: pasteHandler(onPasteHandler)
          })}
          {...(onFocus && { onFocus })}
          ref={inputRef}
          readOnly={!isDesktop || isDisabled}
          value={value}
          data-qa-label={`${className}-input`}
        />
        {shouldRenderIcon && renderIcon(hasFocus, onClickIcon, className, icon)}
      </Field>
      {hasError && renderError(error, className)}
    </Container>
  );
};

export default InputField;
