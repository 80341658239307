import React from "react";
import { buildColor } from "@tvg/design-system/src/_static/colorPalette";
import { Icon } from "@tvg/design-system/src/components/icon";
import { RadioButton } from "./styled-components";
import { NullaryFn, UnaryFn } from "../../../types";

type Props = {
  onClick: NullaryFn<void> | UnaryFn<React.MouseEvent<HTMLInputElement>, void>;
  isActive: boolean;
  qaLabel: string;
};

export const SelectButton = ({ onClick, isActive, qaLabel }: Props) => (
  <RadioButton
    data-qa-label={qaLabel}
    role="radio"
    isActive={isActive}
    onClick={onClick}
  >
    <Icon name="tick" lineColor={buildColor("white", "900")} size="s" />
  </RadioButton>
);
