import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const ECheck = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M1.125 4.125H22.875V19.875H1.125V4.125Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <path
      d="M5.2826 14.2631H12.4064M5.2826 9.72643L15.3517 9.72643M1.125
      4.125H22.875V19.875H1.125V4.125Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </>
);
