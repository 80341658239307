import React from "react";
import { useTheme } from "../../../theming";
import { Icon, IconNames } from "../../icon";
import LoadingSpinner from "../../loading";
import {
  ButtonRow,
  StyledText,
  getTertiaryColor,
  TERNARY_COLOR_CODE
} from "../styled-components";
import { ButtonIcon, ButtonProps, ButtonVariant } from "../types";

const getButtonIcon = (icon: ButtonIcon, color: string) =>
  React.isValidElement(icon) ? (
    icon
  ) : (
    <Icon
      name={icon as IconNames}
      size="s"
      lineColor={color}
      backgroundColor="transparent"
      qaLabel="button-icon"
    />
  );

export const ButtonContainer = ({
  iconPosition,
  variant,
  isLoading,
  size = "l",
  icon,
  children,
  tertiaryColor
}: ButtonProps) => {
  const { buttons, colors } = useTheme();
  const color =
    variant === "tertiary"
      ? getTertiaryColor(colors, tertiaryColor || TERNARY_COLOR_CODE)
      : buttons[variant as ButtonVariant]?.default.color;

  const bgColor =
    variant === "tertiary"
      ? getTertiaryColor(colors, tertiaryColor || TERNARY_COLOR_CODE, "press")
      : buttons[variant as ButtonVariant]?.loading?.background;

  return (
    <ButtonRow iconPosition={iconPosition}>
      {isLoading ? (
        <LoadingSpinner
          size={size || "l"}
          color={color || "white.900"}
          bgColor={bgColor || "white.200"}
        />
      ) : (
        <>
          {icon && getButtonIcon(icon, color || colors.white[900])}
          {React.isValidElement(children) ? (
            children
          ) : (
            <StyledText
              variant={variant}
              color={color as string}
              size={size}
              ml={
                children && icon && iconPosition === "start" ? "space-3" : "0px"
              }
              mr={
                children && icon && iconPosition === "end" ? "space-3" : "0px"
              }
            >
              {children}
            </StyledText>
          )}
        </>
      )}
    </ButtonRow>
  );
};
