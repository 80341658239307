import { get, isObject } from "lodash";
import { Store } from "redux";
import {
  AvailablePaymentMethodsInfos,
  WithdrawalsInfo,
  ActiveCreatePm,
  MainMethods
} from "@tvg/wallet/src/types";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { User } from "@tvg/ts-types/User";
import { initialState as walletInitialState } from "./slices/walletSlice";
import { initialState as paymentMethodSelectorInitialState } from "./slices/paymentMethodSelectorSlice";
import { initialState as otherPaymentMethodsSliceInitialState } from "./slices/otherPaymentMethodsSlice";
import { initialState as infoPanelInitialState } from "./slices/infoPanelSlice";
import { initialState as createPMModalInitialState } from "./slices/createPMModalSlice";
import { PawsState as PawsStoreState } from "./reducers";
import { initialState as depositFundsModalInitialState } from "./slices/depositFundsModalSlice";
import { initialState as withdrawFundsModalInitialState } from "./slices/withdrawFundsModalSlice";
import { initialState as cardDeclinedModalInitialState } from "./slices/cardDeclinedModalSlice";
import {
  otherPaymentsContent,
  pawsContent,
  mzmTimeoutContent,
  mzmTimeoutLimit,
  pawsAddressMessages,
  pendingWithdrawContent,
  creditDebitCardCreation
} from "./defaultValues";

export const getAccountsOnFileMethods = (store: PawsStoreState) =>
  get(store, "paws.wallet.accountsOnFile", walletInitialState.accountsOnFile);

export const getPaymentMethodSelectorModal = (store: PawsStoreState) =>
  get(
    store,
    "paws.paymentMethodSelector.isModalOpen",
    paymentMethodSelectorInitialState.isModalOpen
  );

export const getPaymentMethodSelectorType = (store: PawsStoreState) =>
  get(
    store,
    "paws.paymentMethodSelector.type",
    paymentMethodSelectorInitialState.type
  );

export const getSelectedPaymentMethod = (store: PawsStoreState) =>
  get(
    store,
    "paws.paymentMethodSelector.selectedMethod",
    paymentMethodSelectorInitialState.selectedMethod
  );

export const getOtherPaymentMethodsModal = (store: PawsStoreState) =>
  get(
    store,
    "paws.otherPaymentMethods.isModalOpen",
    otherPaymentMethodsSliceInitialState.isModalOpen
  );

export const getActivePMCreate = (store: PawsStoreState): ActiveCreatePm => ({
  activeCreatePM: get(
    store,
    "paws.createPMModal.activeCreatePM",
    createPMModalInitialState.activeCreatePM
  ),
  isCreateModalOpen: get(
    store,
    "paws.createPMModal.isModalOpen",
    createPMModalInitialState.isModalOpen
  )
});

export const getMainMethods = (store: PawsStoreState): MainMethods => ({
  mainMethods: get(
    store,
    "paws.createPMModal.mainMethods",
    createPMModalInitialState.mainMethods
  )
});

export const getInfoPanelModal = (store: PawsStoreState) =>
  get(store, "paws.infoPanel.isModalOpen", infoPanelInitialState.isModalOpen);

export const isDepositFundsModalOpen = (store: Store) =>
  get(store, "paws.depositFundsModal.isModalOpen", false);

export const getDepositFundsModal = (store: PawsStoreState) => ({
  isModalOpen: get(
    store,
    "paws.depositFundsModal.isModalOpen",
    depositFundsModalInitialState.isModalOpen
  ),
  isMoneyPakModalOpen: get(
    store,
    "paws.depositFundsModal.isMoneyPakModalOpen",
    depositFundsModalInitialState.isMoneyPakModalOpen
  ),
  isOtherPaymentDepositModalOpen: get(
    store,
    "paws.depositFundsModal.isOtherPaymentDepositModalOpen",
    depositFundsModalInitialState.isOtherPaymentDepositModalOpen
  ),
  otherDepositsPaymentType: get(
    store,
    "paws.depositFundsModal.otherDepositsPaymentType",
    depositFundsModalInitialState.otherDepositsPaymentType
  ),
  isPayNearMeModalOpen: get(
    store,
    "paws.depositFundsModal.isPayNearMeModalOpen",
    depositFundsModalInitialState.isPayNearMeModalOpen
  ),
  formData: get(store, "paws.depositFundsModal.formData"),
  fromCreation: get(store, "paws.depositFundsModal.fromCreation"),
  onBack: get(store, "paws.depositFundsModal.onBack"),
  isRedirectFromMZM: get(
    store,
    "paws.depositFundsModal.isRedirectFromMZM",
    false
  )
});

export const getWithdrawFundsModal = (store: PawsStoreState) => ({
  isModalOpen: get(
    store,
    "paws.withdrawFundsModal.isModalOpen",
    withdrawFundsModalInitialState.isModalOpen
  )
});

export const getPawsContent = (store: Store) =>
  parseCapiMessage(store, "capi.messages.pawsContent", pawsContent);

export const getCardDeclinedModal = (store: PawsStoreState) =>
  get(
    store,
    "paws.cardDeclinedModal.isModalOpen",
    cardDeclinedModalInitialState.isModalOpen
  );

export const getCardDeclinedModalAmount = (store: PawsStoreState) =>
  get(
    store,
    "paws.cardDeclinedModal.declinedAmount",
    cardDeclinedModalInitialState.declinedAmount
  );

export const getAddressConfirmationMessage = (store: PawsStoreState) =>
  parseCapiMessage(
    store,
    "capi.messages.pawsAddressMessages",
    pawsAddressMessages
  );

export const getUserAddress = (store: PawsStoreState) => ({
  homeAddress: (({
    address1,
    address2,
    city,
    state,
    streetNumber,
    zipCode
  }) => ({
    address1,
    address2,
    city,
    state,
    streetNumber,
    zipCode
  }))(get(store, "userData.user.homeAddress")),
  mailingAddress: (({
    address1,
    address2,
    city,
    state,
    streetNumber,
    zipCode
  }) => ({ address1, address2, city, state, streetNumber, zipCode }))(
    get(store, "userData.user.mailingAddress")
  )
});

export const getOtherPaymentsContent = (store: PawsStoreState) =>
  parseCapiMessage(
    store,
    "capi.messages.otherPaymentsDeposits",
    otherPaymentsContent
  );

export const getPNMContentFAQ = (store: Store) =>
  get(store, "capi.messages.pawsPNMFAQ");

export const getPawsLimits = (store: Store) => {
  const message = get(store, "capi.messages.pawsLimits", {});
  return isObject(message)
    ? message
    : parseCapiMessage(store, "capi.messages.pawsLimits", {});
};

export const getIsQuickWithdrawOpen = (store: Store) =>
  get(store, "paws.quickWithdraw.isOpen", false);

export const getIsDeleteCardModalOpen = (store: Store): boolean =>
  get(store, "paws.deleteCardModal.isOpen", false);

export const getDeleteCardModalPaymentMethod = (store: Store): boolean =>
  get(store, "paws.deleteCardModal.paymentMethod", false);

export const getPawsACHAllowAddToggle = (store: Store): boolean => {
  const pawsACHAllowAddToggle = get(
    store,
    "capi.featureToggles.pawsACHAllowAdd",
    false
  );
  if (get(store, "capi.featureToggles.enablePawsACHAllowAddAbTesting", false)) {
    return (
      pawsACHAllowAddToggle &&
      get(store, "capi.abTesting.pawsACHAllowAdd", false)
    );
  }
  return pawsACHAllowAddToggle;
};

export const getQuickDepositStateOpen = (store: Store) =>
  get(store, "paws.quickDeposit.isOpen");

export const getAvailablePaymentMethodsInfos = (
  store: Store
): AvailablePaymentMethodsInfos =>
  parseCapiMessage(store, "capi.messages.availablePaymentMethodsInfos", []);

export const getWithdrawalsInformation = (store: Store): WithdrawalsInfo => ({
  withdrawals: get(store, "withdrawals.withdrawals", []),
  closeCancelWithdrawal: get(
    store,
    "withdrawals.cancelInformation.onCancelClose",
    false
  ),
  isCancelModalOpen: get(store, "withdrawals.isCancelModalOpen", false),
  showPendingWithdrawalsV2: get(
    store,
    "capi.featureToggles.pendingWithdrawalsV2",
    false
  )
});

export const getCardDeclinedToggle = (store: Store) =>
  get(store, "capi.featureToggles.pawsCardDeclined", false);

export const getPendingWithdrawOpen = (store: Store): boolean =>
  get(store, "paws.pendingWithdraw", false);

export const getCardDeclinedContent = (store: Store) =>
  parseCapiMessage(store, "capi.messages.pawsCardDeclinedContent", {});

export const getMZMTimeoutContent = (store: Store) =>
  parseCapiMessage(
    store,
    "capi.messages.pawsMZMTimeoutContent",
    mzmTimeoutContent
  );

export const getMZMTimeoutLimit = (store: Store) =>
  parseCapiMessage(store, "capi.messages.pawsMZMTimeoutLimit", mzmTimeoutLimit);

export const getMZMTimeoutToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.pawsMZMTimeout", false);

export const getEcheckCreationMessages = (store: Store) =>
  parseCapiMessage(store, "capi.messages.echeckCreationMessages", {});

export const getDepositBetcashUserAgreementHtml = (store: Store) =>
  get(store, "capi.messages.depositBetcashUserAgreementHtml", "");

export const getPendingWithdrawContent = (store: Store) =>
  parseCapiMessage(
    store,
    "capi.messages.pendingWithdraws",
    pendingWithdrawContent
  );

export const getPawsSkipMZMErrorModals = (store: Store): boolean =>
  get(store, "capi.featureToggles.pawsSkipMZMErrorModals", false);

export const getPawsShowMZMSuccessModal = (store: Store): boolean =>
  get(store, "capi.featureToggles.pawsShowMZMSuccessModal", false);

export const getPawsErrorCodesToSkipMZMErrorModals = (store: Store): string[] =>
  parseCapiMessage(
    store,
    "capi.messages.pawsErrorCodesToSkipMZMErrorModals",
    []
  );

export const getCreditDebitCardCreationMessage = (store: Store) =>
  parseCapiMessage(
    store,
    "capi.messages.creditDebitCardCreation",
    creditDebitCardCreation
  );

export const getIsFirstDepositDone = (store: Store) =>
  get(store, "paws.wallet.isFirstDepositDone", false);

export const getUserData = (store: Store): User => get(store, "userData", {});

export const getPaymentTypesUnderMaintenance = (store: Store) =>
  get(store, "paws.wallet.paymentTypesUnderMaintenance", []);

export const getWireTransferAutomationToggle = (store: Store) =>
  get(store, "capi.featureToggles.pawsWireTransferWithdrawAutomation", false);

export const getWireTransferWithdrawModalOpen = (store: PawsStoreState) =>
  get(
    store,
    "paws.withdrawFundsModal.isWireTransferModalOpen",
    withdrawFundsModalInitialState.isWireTransferModalOpen
  );

export const getPawsMzmOverlayContent = (store: Store) =>
  parseCapiMessage(store, "capi.messages.pawsMzmOverlayContent", {
    default: {
      text: "Please don't close this tab, a Mazooma transaction is on the way."
    },
    timeout: {
      text: "(Overlay will close within seconds)"
    },
    permissions: {
      text: "To continue the transaction it is necessary to complete on the Mazooma website.",
      action: "Click here to continue"
    }
  });
