import React from "react";
import { Path, Defs, G, ClipPath, Rect } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Key = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <G clipPath="url(#clip0)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.30499 19.695C6.64674 22.0368 10.4383 22.0368 12.78 19.695C14.4622
        18.0128 14.9396 15.5901 14.205 13.485L21.375 6.31501L21.375
        2.62499L17.6937 2.62499L10.515 9.79501C8.41336 9.06793 5.98317 9.54184
        4.305 11.22C1.96325 13.5618 1.96324 17.3533 4.30499 19.695ZM8.53498
        16.59C9.15554 16.59 9.65998 16.0855 9.65998 15.465C9.65998 14.8444
        9.15554 14.34 8.53498 14.34C7.91443 14.34 7.40999 14.8444 7.40999
        15.465C7.40999 16.0855 7.91443 16.59 8.53498 16.59Z"
        fill={backgroundColor}
        fillOpacity="1"
      />
      <Path
        d="M16.021 8.99144L16.021 11.3793M18.5011 6.69585L18.5011 8.99144M4.305
        11.22C1.96325 13.5618 1.96324 17.3533 4.30499 19.695C6.64674 22.0368
        10.4383 22.0368 12.78 19.695C14.4622 18.0128 14.9396 15.5901 14.205
        13.485L21.375 6.31501L21.375 2.62499L17.6937 2.62499L10.515
        9.79501C8.41336 9.06793 5.98317 9.54184 4.305 11.22ZM9.65998
        15.465C9.65998 16.0855 9.15554 16.59 8.53498 16.59C7.91443 16.59
        7.40999 16.0855 7.40999 15.465C7.40999 14.8444 7.91443 14.34 8.53498
        14.34C9.15554 14.34 9.65998 14.8444 9.65998 15.465Z"
        stroke={lineColor}
        strokeWidth={strokeWidth}
      />
    </G>
    <Defs>
      <ClipPath id="clip0">
        <Rect width="24" height="24" fill="white" />
      </ClipPath>
    </Defs>
  </>
);

export default Key;
