import { noop } from "lodash";

export const ModalV2DefaultProps = {
  title: null,
  subtitle: null,
  titleType: "default",
  isOpen: false,
  onOpen: noop,
  onOpenAnimationEnd: noop,
  onClose: noop,
  onCloseAnimationEnd: noop,
  shouldRenderWhite: false,
  onBack: null,
  onOverlayClick: null,
  hasOverlay: true,
  isFullWidth: true,
  isFullHeight: true,
  isFluidWidth: false,
  hasRoundedCorners: true,
  animation: "right",
  offsetTop: 16,
  offsetBottom: 0,
  offsetLeft: 0,
  offsetRight: 0,
  hasVideoOffset: false,
  children: noop,
  qaLabel: null,
  modalTopChildren: noop,
  isLastModal: true,
  fixIosKeyboard: false,
  useFakeInput: false,
  hasHeader: true,
  device: "mobile",
  hasHeaderIcon: true,
  hasCloseButton: true,
  fixedWidth: "100%",
  myBetsStandaloneToggle: false,
  layerOffset: 0,
  hasContentMaxHeight: false,
  hasBiggerHeader: false,
  scrollableRef: { current: null },
  setScrollableRef: null,
  headerChildren: null,
  headerTitleChildren: null,
  onScrollFn: noop,
  isTitleCenter: false,
  isTitleCapitalized: true,
  useModalHeaderV3: false,
  showBottomShadow: true
};

export const ModalDefaultProps = {
  title: null,
  subtitle: null,
  titleType: "default",
  isOpen: false,
  onOpen: noop,
  onOpenAnimationEnd: noop,
  onClose: noop,
  onCloseAnimationEnd: noop,
  onBack: null,
  onOverlayClick: null,
  hasOverlay: true,
  isFullWidth: true,
  isFullHeight: true,
  isFluidWidth: false,
  hasRoundedCorners: true,
  animation: "right",
  offsetTop: 16,
  offsetBottom: 0,
  offsetLeft: 0,
  offsetRight: 0,
  hasVideoOffset: false,
  children: noop,
  modalTopChildren: noop,
  qaLabel: null,
  fixIosKeyboard: false,
  hasHeader: true,
  device: "mobile",
  isContentTransparent: false,
  hasCloseWithoutHeader: false,
  hasHeaderIcon: true,
  hasHeaderBorder: true,
  hasCloseButton: true,
  fixedWidth: "100%",
  myBetsStandaloneToggle: false,
  layerOffset: 0,
  hasContentMaxHeight: false,
  contentMaxHeight: 0,
  hasBiggerHeader: false,
  scrollableRef: { current: null },
  setScrollableRef: null,
  useModalHeaderV3: false,
  className: "",
  shouldRenderWhiteTitle: false,
  headerTitleChildren: null,
  onScrollFn: noop,
  isTitleCenter: false,
  isTitleCapitalized: true
};
