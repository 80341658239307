import { useEffect, useState } from "react";
import { History } from "@tvg/ts-types/History";
import { PromoOnboarding } from "@tvg/sh-lib-promos-onboarding/types/promoOnboarding";
import { PromoCurrentStatus } from "../types";
import { formatPromoCurrentStatus } from "../helpers/formatPromoCurrentStatus";

export const useCurrentProgress = (
  history: History,
  storyblokPromos: PromoOnboarding | null
) => {
  const [promoCurrentStatus, setPromoCurrentStatus] =
    useState<PromoCurrentStatus | null>(null);

  useEffect(() => {
    if (storyblokPromos) {
      setPromoCurrentStatus(formatPromoCurrentStatus(storyblokPromos, history));
    } else {
      setPromoCurrentStatus(null);
    }
  }, [storyblokPromos]);

  return promoCurrentStatus;
};
