import mediator from "@tvg/mediator";
import { isMobile } from "@tvg/sh-utils/mobileUtils";
import { UserInfo } from "@tvg/ts-types/User";
import {
  sendOpenFeedbackWidgetEvent,
  sendSubmitFeedbackWidgetEvent
} from "./amplitude";

export const createConversation = ({
  accountNumber,
  firstName,
  lastName,
  emailAddress,
  profile,
  homeAddress,
  primaryPhone
}: UserInfo) => {
  if (typeof window !== "undefined" && window.ApptentiveSDK) {
    const payload = {
      app_release: {
        version: "1.0.0"
      },
      person: {
        custom_data: {
          account_id: accountNumber,
          first_name: firstName,
          last_name: lastName,
          email: emailAddress,
          day_of_birthday: "",
          home_city: homeAddress ? homeAddress.state : "",
          gender: "",
          country: homeAddress ? homeAddress.country : "",
          phone: primaryPhone,
          full_home_address: "",
          key_account_yn: false,
          value_band: profile
        }
      },
      device: {
        custom_data: {
          flash: true,
          es6: false
        }
      }
    };
    window.ApptentiveSDK.createConversation(payload);
  }
};

export const engageApptentiveEvent = (eventName: string) => {
  if (isMobile()) {
    mediator.ios.dispatch({
      type: "ALCHEMER_EVENTS",
      payload: { alchemerEvent: eventName }
    });
  } else if (typeof window !== "undefined" && window.ApptentiveSDK) {
    window.ApptentiveSDK.engage(eventName);
  }
};

export const dispatchSubmited = () => {
  sendSubmitFeedbackWidgetEvent();
  document.removeEventListener("apptentive:survey:submit", dispatchSubmited);
};

export const trackBetaFeatureFeedback = () => {
  sendOpenFeedbackWidgetEvent();
  document.addEventListener("apptentive:survey:submit", dispatchSubmited);
};

export const closeSurveyAfterSubmit = () => {
  const closeButton = document.getElementsByClassName(
    "apptentive-button--primary"
  );

  const closeSurvey = () => {
    closeButton[0]?.removeEventListener("click", closeSurvey);
  };

  closeButton[0]?.addEventListener("click", closeSurvey);
};

export const hideMinimizeSurveyButton = () => {
  const alchemerMinimizeBtn = document?.getElementsByClassName(
    "apptentive-appbar__action--minimize"
  );

  if (document?.body && alchemerMinimizeBtn.length) {
    // @ts-ignore
    alchemerMinimizeBtn[0].style = "display:none";
  }
};
