import React from "react";
import { Circle, Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Refresh = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Circle
      cx="11.9999"
      cy="12.0042"
      r="9.37333"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M11.9606 21.375C6.80467 21.375 2.62494 17.1953 2.62494 12.0393C2.62494
      9.54156 3.60584 7.27292 5.20343 5.59758L8.09741 2.70361M12.0393
      2.63086C17.1953 2.63086 21.375 6.81059 21.375 11.9666C21.375 14.4607
      20.3969 16.7265 18.8033 18.4011L15.9022 21.3022M22.1249
      21.3825H15.5806V15.1601M1.87494 2.6299H8.41933V8.625"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
export default Refresh;
