import styled from "styled-components/native";
import { space, width } from "styled-system";
import { View } from "react-native";

export const StyledContainer = styled(View)`
  width: auto;
  flex-direction: row;
  ${width}
  ${space}
`;
