import React from "react";
import { openExternalApp } from "@tvg/utils/mediatorUtils";
import tvgConf from "@tvg/conf";
import {
  AddressContainer,
  AddressInfo,
  AddressTitle,
  AddressInfoText,
  AddressPickerContainer,
  CustomerServiceButton,
  CustomerSupportText
} from "./styled-components";
import { SelectButton } from "../Common/SelectButton";
import {
  gtmDepositAddressCustomerService,
  gtmWithdrawAddressCustomerService
} from "../../gtm";
import type { Address } from "../../types";
import type { Props } from "./types";
import { MessageType } from "../AddressConfirmationModal/types";

export const AddressSelector = ({
  customerService,
  addresses,
  active,
  onClick,
  paymentType,
  accountID,
  messageType
}: Props) => {
  const useBillingLayout = messageType === MessageType.BILLING;
  const tvgConfig = tvgConf().config();
  const supportUrl = tvgConfig.externalDomain.support;

  return (
    <>
      <AddressPickerContainer>
        {addresses.map(
          (address: Address) =>
            address && (
              <AddressContainer
                key={address.type}
                isActive={useBillingLayout ? false : address.type === active}
                onClick={() => onClick(address.type)}
                data-qa-label={`address-container-${address.type}`}
              >
                <AddressInfo>
                  {useBillingLayout && (
                    <AddressTitle data-qa-label="address-title">
                      Address Details
                    </AddressTitle>
                  )}
                  <AddressInfoText data-qa-label="address-info-primary">{`${address.streetNumber} ${address.address1}`}</AddressInfoText>
                  {address.address2 && (
                    <AddressInfoText data-qa-label="address-info-secondary">
                      {address.address2}
                    </AddressInfoText>
                  )}
                  <AddressInfoText data-qa-label="address-info-tertiary">{`${address.city}, ${address.state} ${address.zipCode}`}</AddressInfoText>
                </AddressInfo>
                {!useBillingLayout && (
                  <SelectButton
                    qaLabel={`radio-button-${address.type}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick(address.type);
                    }}
                    isActive={active === address.type}
                  />
                )}
              </AddressContainer>
            )
        )}
      </AddressPickerContainer>
      {customerService && (
        <CustomerSupportText data-qa-label="address-selector-customer-service">
          {customerService}
          <CustomerServiceButton
            data-qa-label="address-selector-customer-service-link"
            role="button"
            onClick={() => {
              if (messageType === MessageType.WITHDRAW) {
                gtmWithdrawAddressCustomerService({
                  paymentType,
                  accountID,
                  linkText: "Update your address"
                });
              } else {
                gtmDepositAddressCustomerService({
                  paymentType,
                  accountID,
                  linkText: "Update your address"
                });
              }
              openExternalApp(
                `${supportUrl}/s/articles/Changing-Your-Email-Address-And-Physical-Address`
              );
            }}
          >
            Update your address
          </CustomerServiceButton>
        </CustomerSupportText>
      )}
    </>
  );
};
