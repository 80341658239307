import styled, { css } from "styled-components";
import { fontMedium, fontNormal } from "@tvg/atomic-ui/_static/Typography";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const Content = styled.dl`
  flex: 1;
  margin-right: 20px;
`;

export const Title = styled.dt`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const TitleText = styled.h3<{ isDisabled: boolean }>`
  margin-left: 8px;
  line-height: 18px;
  color: ${({ isDisabled }) =>
    isDisabled ? buildColor("blue", "300") : buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-size: 14px;
  font-weight: 500;
`;

export const CardsIcons = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const Description = styled.p<{
  isDisabled: boolean;
  hasMarginBottom?: boolean;
}>`
  margin-left: 24px;
  line-height: 18px;
  color: ${({ isDisabled }) =>
    isDisabled ? buildColor("blue", "300") : buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 14px;
  ${({ hasMarginBottom }) =>
    hasMarginBottom &&
    css`
      margin-bottom: 14px;
    `}
`;

export const Item = styled.div`
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  border-right: 1px solid ${buildColor("blue", "100")};
`;

export const FlagContainer = styled.div`
  display: flex;
  width: fit-content;
  margin-left: 24px;
  margin-bottom: 8px;

  & > div:not(:first-child) {
    margin-left: 4px;
  }
`;

export const IconsContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 14px;
  margin-left: 24px;
`;
