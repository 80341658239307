import { capitalize, first, get } from "lodash";
import { WagerTypeCodesInvertedEnum } from "@tvg/ts-types/Wager";
import {
  DepositProposition,
  WagerProposition
} from "../types/promoPropositions";

export enum VariableModifier {
  MAX = "max",
  MIN = "min",
  NONE = ""
}

export const extractVariableModifier = (
  variable: string
): { variableName: string; modifier: VariableModifier } => {
  const modifierMatch = variable.match(/(.*)_(.*)/);

  if (modifierMatch) {
    return {
      variableName: modifierMatch[2],
      modifier: modifierMatch[1] as VariableModifier
    };
  }

  return {
    variableName: variable,
    modifier: VariableModifier.NONE
  };
};

export const getValueWithModifier = (
  variable: string,
  propositions: Array<DepositProposition | WagerProposition> = [],
  modifier: VariableModifier
) => {
  // The following logic applies to the following DEPOSIT STEP variables:
  // bonusAmount, bonusPercentage and minimumDepositAmount
  if (modifier !== VariableModifier.NONE) {
    const variableArray = propositions.reduce(
      (acc: Array<number>, proposition) => {
        const originalVar = get(proposition, variable);
        const newVar =
          typeof originalVar === "string"
            ? +originalVar.replace("%", "").trim()
            : originalVar;

        return typeof newVar === "number" ? [...acc, newVar] : acc;
      },
      []
    );

    if (modifier === VariableModifier.MAX) {
      return Math.max(...variableArray);
    }

    if (modifier === VariableModifier.MIN) {
      return Math.min(...variableArray);
    }
  }

  // The following logic applies to the following WAGER STEP variables:
  // raceDate, raceNumber, trackCode and wagerTypeCode
  if (propositions.length > 1) {
    if (variable === "wagerTypeCode") {
      return "Multiple Wager Types";
    }

    if (variable === "trackCode") {
      return "Multiple Tracks";
    }

    if (variable === "raceNumber") {
      return "Multiple Races";
    }

    if (variable === "raceDate") {
      return "Multiple Dates";
    }
  }

  const variableValue = get(first(propositions), variable);

  if (
    variable === "wagerTypeCode" &&
    variableValue in WagerTypeCodesInvertedEnum
  ) {
    const wagerTypeName: string = get(
      WagerTypeCodesInvertedEnum,
      variableValue
    );

    return capitalize(wagerTypeName);
  }

  if (variable === "trackCode" && variableValue === "ALL") {
    return "All Tracks";
  }

  if (variable === "raceNumber" && variableValue === 0) {
    return "All Races";
  }

  if (variable === "raceDate" && !variableValue) {
    return "Any Date";
  }

  return variableValue;
};

export const getValueFromProposition = (
  variable: string,
  propositions: Array<WagerProposition> | Array<DepositProposition>
) => {
  const { variableName, modifier } = extractVariableModifier(variable);

  return getValueWithModifier(variableName, propositions, modifier);
};
