import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Greyhounds = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <Path
    d="M8.875 9.22224C6.09722 10.6111 2.625 14.0833 2.625 14.0833L8.18056
    18.25L11.6528 14.0833C11.6528 14.0833 15.8757 14.4587 17.9028
    15.4722C19.0139 14.9167 21.375 15.4722 21.375 13.3889C17.2083 12
    15.9321 8.52779 13.7361 8.52779C12.3472 8.52779 10.9583 5.75002 9.56944
    5.75002L6.79167 9.22224H8.875Z"
    fill={backgroundColor}
    fillOpacity="1"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default Greyhounds;
