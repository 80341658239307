import React from "react";
import { IconPathProps } from "../../types";

export const ArrowUp = ({ lineColor, strokeWidth }: IconPathProps) => (
  <path
    d="M12 21.75V3.82723M4.5 11.25L12 3.75L19.5 11.25"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
