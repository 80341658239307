import React, { useEffect } from "react";
import { useQaLabel } from "../../../../hooks/useQaLabel";
import { useFirstRender } from "../../hooks/useFirstRender";
import { ModalPageContainerProps } from "./types";
import { StyledView } from "./styled-components";
import { useModalPagesContext } from "../../context/modalPagesContext";

export const ModalPageContainer = ({
  children,
  page: pageFromProps
}: ModalPageContainerProps) => {
  const isFirstRender = useFirstRender();

  const [
    { totalPages, activePage, nextPage, pageHeight, isFlexible },
    dispatch
  ] = useModalPagesContext();
  const viewTestProps = useQaLabel("modalContainer");

  const renderPages = () =>
    children.map((pageElement, index) =>
      // eslint-disable-next-line react/no-array-index-key
      React.cloneElement(pageElement, { page: index + 1, key: index + 1 })
    );

  useEffect(() => {
    dispatch({
      type: "INIT",
      payload: {
        totalPages: React.Children.count(children)
      }
    });
  }, [children]);

  useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        // set page without animation
        activePage:
          isFirstRender && pageFromProps ? pageFromProps : activePage || 1,
        isFirstRender
      }
    });

    // handle animations
    if (
      pageFromProps &&
      totalPages &&
      pageFromProps <= totalPages &&
      (pageFromProps !== activePage || pageFromProps !== nextPage)
    ) {
      dispatch({
        type: "START_CHANGE_PAGE",
        payload: {
          nextPage: pageFromProps
        }
      });
    }
  }, [pageFromProps, isFirstRender, totalPages]);

  return (
    <StyledView
      {...viewTestProps}
      pageHeight={pageHeight}
      isFlexible={isFlexible}
    >
      {renderPages()}
    </StyledView>
  );
};

export { ModalPageContainerProps };
