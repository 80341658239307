import { ColorPalette } from "./types";
import { Theme } from "../../../src/theming/types";

export const colorPalette = (theme: Theme): ColorPalette => ({
  default: {
    line: theme.colorTokens.component.button.tertiary.content.base,
    background: theme.colorTokens.background.surface,
    border: theme.colorTokens.component.button.tertiary.border.base
  },
  hover: {
    line: theme.colorTokens.component.button.tertiary.content.hover,
    background: theme.colorTokens.component.button.tertiary.background.hover
  },
  active: {
    line: theme.colorTokens.component.button.tertiary.content.active,
    background: theme.colorTokens.component.button.tertiary.background.active
  },
  disabled: {
    line: theme.colorTokens.component.button.tertiary.content.disabled,
    background: theme.colorTokens.component.button.tertiary.background.disabled
  }
});
