import {
  Error as ErrorIcon,
  Warning as WarningIcon,
  Success as SuccessIcon,
  InfoBig as InfoIcon
} from "@tvg/atomic-ui/_static/Icons/paws";
import React, { Dispatch, SetStateAction } from "react";
import { ButtonAction, MessagePanelVariants } from "../../types";

export const renderIcon = (variant: MessagePanelVariants) => {
  switch (variant) {
    case "warning":
      return <WarningIcon />;

    case "success":
      return <SuccessIcon />;

    case "info":
      return <InfoIcon />;
    case "error":
    default:
      return <ErrorIcon />;
  }
};

export const onClickAction = (
  hasBeenClicked: boolean,
  setHasBeenClicked: Dispatch<SetStateAction<boolean>>,
  rest: ButtonAction
) => {
  if (rest.qaLabel === "retry" && rest.type === "primary") {
    if (!hasBeenClicked && rest.onClick) {
      setHasBeenClicked(true);
      rest.onClick();
    }
  } else {
    rest.onClick();
  }
};
