import { PromoCurrentStatus } from "../types";
import { defaultCTA } from "./promoCTA";

export const defaultStatus: PromoCurrentStatus = {
  ...defaultCTA,
  promoDescription: "",
  progressBarIcon: "$",
  progressBarSteps: [],
  completedSteps: 0,
  promoTitle: "",
  remainingDays: 0,
  showRemainingDays: true
};
