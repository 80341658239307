import styled from "styled-components/native";
import { View } from "react-native";

export const Container = styled(View)<{ iconSize: string }>`
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  height: ${(props) => props.iconSize}px;
  width: ${(props) => props.iconSize}px;
`;
