module.exports = {
  APPTENTIVE_TOKENS: {
    IOS: {
      QA: {
        APP_KEY: "IOS-FANDUEL-RACING-DEVELOPMENT",
        APP_SIGNATURE: "b54c8aadfa24aa1dcb03dc8df0c725e7",
        APPTENTIVE_ID: "61ead8decdc4334729000151"
      },
      STAGING: {
        APP_KEY: "IOS-FANDUEL-RACING-BET-ON-HORSES",
        APP_SIGNATURE: "625f98c1c017603845ead137e69e1e0e",
        APPTENTIVE_ID: "61ead7d3cdc4334729000139"
      },
      PRODUCTION: {
        APP_KEY: "IOS-FANDUEL-RACING-BET-ON-HORSES",
        APP_SIGNATURE: "625f98c1c017603845ead137e69e1e0e",
        APPTENTIVE_ID: "61ead7d3cdc4334729000139"
      }
    },
    ANDROID: {
      QA: {
        APP_KEY: "ANDROID-FANDUEL-RACING-DEVELOPME",
        APP_SIGNATURE: "a5ef70979e53a2691f4977ff2c03dfc1",
        APPTENTIVE_ID: "61f056825a88276417000000"
      },
      STAGING: {
        APP_KEY: "ANDROID-FANDUEL-RACING-PRODUCTIO",
        APP_SIGNATURE: "b4eb937e986299560e802a801370b69d",
        APPTENTIVE_ID: "61f0566e5a88276441000002"
      },
      PRODUCTION: {
        APP_KEY: "ANDROID-FANDUEL-RACING-PRODUCTIO",
        APP_SIGNATURE: "b4eb937e986299560e802a801370b69d",
        APPTENTIVE_ID: "61f0566e5a88276441000002"
      }
    },
    WEB: {
      QA: {
        APPTENTIVE_ID: "6204200f48b9d649d700005d"
      },
      STAGING: {
        APPTENTIVE_ID: "62041fcb5a88277d4900018f"
      },
      PRODUCTION: {
        APPTENTIVE_ID: "62041fcb5a88277d4900018f"
      }
    }
  }
};
