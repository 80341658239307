import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Changes = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M10.9393 1.81583C11.5251 1.23004 12.4749 1.23005 13.0607
      1.81583L22.1842 10.9393C22.77 11.5251 22.77 12.4749 22.1842
      13.0607L13.0607 22.1842C12.4749 22.77 11.5251 22.77 10.9393
      22.1842L1.81583 13.0607C1.23004 12.4749 1.23004 11.5251 1.81583
      10.9393L10.9393 1.81583Z"
      fill={backgroundColor}
    />
    <path
      d="M12 13.5L12 6.75M12 17.25L12 15M1.81583 13.0607L10.9393
      22.1842C11.5251 22.77 12.4749 22.77 13.0607 22.1842L22.1842 13.0607C22.77
      12.4749 22.77 11.5251 22.1842 10.9393L13.0607 1.81583C12.4749 1.23005
      11.5251 1.23004 10.9393 1.81583L1.81583 10.9393C1.23004 11.5251 1.23004
      12.4749 1.81583 13.0607Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);
