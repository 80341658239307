import { Dispatch as ReactDispatch, SetStateAction } from "react";
import {
  FundsAmountInputChangingFrom,
  FundsModalFields
} from "../../../../types";
import { getNextValueFundsAmount } from "../../../../utils";
import { getStateByActiveField } from "./valueHandling";

export const clearValue = (value: string) => value.replace(/\D/g, "");

export const onChangeValue = (value: string) => {
  const initWithDot = value.charAt(0) === "." ? `0.` : value;
  const formatedValue = initWithDot.replace(",", ".");
  return RegExp("^$|^[0-9]{0,7}$|^[0-9]{1,7}[.][0-9]{0,2}$").test(formatedValue)
    ? formatedValue
    : false;
};

const onChangeCVV = (value: string) =>
  RegExp("^$|^[0-9]{0,3}$").test(value) ? value : false;

const onChangeMoneyPakCode = (value: string) => {
  const rawValue = clearValue(value);
  if (rawValue.length > 14) return false;

  return rawValue
    .replace(/(\d{3})(\d)/, "$1 - $2")
    .replace(/(\d{3})(\d)/, "$1 - $2")
    .replace(/(\d{4})(\d)/, "$1 - $2")
    .replace(/(-\d{4})$/, "$1");
};

export const getValidatedValueByField = (
  value: string,
  selectedField: FundsModalFields
) => {
  switch (selectedField) {
    case FundsModalFields.VALUE:
      return onChangeValue(value);
    case FundsModalFields.CVV:
      return onChangeCVV(value);
    case FundsModalFields.CODE:
      return onChangeMoneyPakCode(value);
    default:
      return onChangeValue(value);
  }
};

export const onMoneyPakChangeValue =
  (
    isLoading: boolean,
    setValue: ReactDispatch<SetStateAction<string>>,
    value: string
  ) =>
  // eslint-disable-next-line consistent-return
  (keyValue: number | string, from: FundsAmountInputChangingFrom) => {
    if (isLoading) {
      return false;
    }

    const nextValue = getNextValueFundsAmount(keyValue, from, value);
    const validatedValue = getValidatedValueByField(
      nextValue,
      FundsModalFields.CODE
    );
    if (validatedValue || validatedValue === "") {
      setValue(validatedValue);
    }
  };

export const onFundsChangeValue =
  (
    isLoading: boolean,
    selectedField: FundsModalFields,
    setValue: ReactDispatch<SetStateAction<string>>,
    setCVV: ReactDispatch<SetStateAction<string>>,
    value: string,
    cvv: string
  ) =>
  // eslint-disable-next-line consistent-return
  (keyValue: number | string, from: FundsAmountInputChangingFrom) => {
    if (isLoading) {
      return false;
    }
    const setStateMethod = getStateByActiveField(
      selectedField,
      setValue,
      setCVV,
      value,
      cvv
    );

    const nextValue = getNextValueFundsAmount(
      keyValue,
      from,
      setStateMethod.value
    );

    const validatedValue = getValidatedValueByField(nextValue, selectedField);
    if (validatedValue || validatedValue === "") {
      setStateMethod.setState(validatedValue);
    }
  };

export const onMoneypakClearValue =
  (
    isLoading: boolean,
    setValue: ReactDispatch<SetStateAction<string>>,
    value: string
  ) =>
  () => {
    // Self-import allowing to mock onMoneyPakChangeValue instance
    onMoneyPakChangeValue(
      isLoading,
      setValue,
      value
    )(clearValue(value).slice(0, -1), "desktop");
  };

export const onClearValue =
  (
    selectedField: FundsModalFields,
    setValue: ReactDispatch<SetStateAction<string>>,
    setCVV: ReactDispatch<SetStateAction<string>>,
    value: string,
    cvv: string
  ) =>
  () => {
    const setStateMethod = getStateByActiveField(
      selectedField,
      setValue,
      setCVV,
      value,
      cvv
    );
    setStateMethod.setState((prevValue: string) => prevValue.slice(0, -1));
  };
