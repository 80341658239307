import React from "react";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import { formatDateTohhmm } from "@tvg/formatter/dates";
import { MtpProps } from "./types";
import {
  MinutesText,
  RaceOff,
  RaceTime,
  Resulted,
  TextDescription
} from "./styled-components";
import { getMTPSufix } from "./utils";

const Mtp: React.FC<MtpProps> = ({
  status,
  mtp,
  postTime,
  isCollapsed = false,
  hasFullText = false,
  isInline = false,
  qaLabel = "mtp",
  ...props
}) => {
  const testProps = { "data-qa-label": qaLabel };

  if (status === RaceStatusEnum.RACE_OFF) {
    return (
      <RaceOff
        isCollapsed={isCollapsed}
        hasFullText={hasFullText}
        {...testProps}
        {...props}
      >
        {isCollapsed && !hasFullText ? "OFF" : "Race Off"}
      </RaceOff>
    );
  }

  if (status === RaceStatusEnum.RACE_OFFICIAL) {
    return !isCollapsed || hasFullText ? (
      <Resulted
        isCollapsed={isCollapsed}
        hasFullText={hasFullText}
        {...testProps}
        {...props}
      >
        Resulted
      </Resulted>
    ) : null;
  }

  return mtp > 59 ? (
    <RaceTime
      hasFullText={hasFullText}
      isCollapsed={isCollapsed}
      {...testProps}
      {...props}
    >
      {formatDateTohhmm(postTime)}
    </RaceTime>
  ) : (
    <MinutesText
      mtp={mtp}
      isCollapsed={isCollapsed}
      isInline={isInline}
      {...testProps}
      {...props}
    >
      {mtp > 9 || isCollapsed ? mtp : `0${mtp}`}
      {getMTPSufix(isCollapsed, hasFullText)}
      &nbsp;
      {!isCollapsed && (
        <TextDescription mtp={mtp} isInline={isInline} data-qa-label="mtp-text">
          {`Minute${mtp === 1 ? "" : "s"} till post`}
        </TextDescription>
      )}
    </MinutesText>
  );
};

export default Mtp;
export { MtpProps, Mtp };
