import React from "react";
import { Button, ProgressBar, Header, Paragraph } from "@tvg/design-system";
import { noop } from "lodash";
import { Device } from "@tvg/ts-types/Device";
import {
  PromoProgressContainer,
  InfoContainer,
  HeaderContainer,
  DaysContainer,
  DetailsContainer,
  ProgressBarContainer,
  ButtonContainer
} from "./styled-components";
import { Props } from "./types";

export const PromoProgress = ({
  qaLabel = "promoProgress",
  CTALabel = "",
  onCTAPress = noop,
  promoDescription = "",
  progressBarIcon = "$",
  progressBarSteps = [],
  completedSteps = 0,
  promoTitle = "",
  showRemainingDays = false,
  remainingDays = 0,
  CTAShow = true,
  isDarkMode = false,
  device = Device.MOBILE,
  isHomePageSection = false
}: Props) => (
  <PromoProgressContainer
    data-qa-label={qaLabel}
    isDarkMode={isDarkMode}
    isHomePageSection={isHomePageSection}
    device={device}
  >
    <InfoContainer device={device}>
      <HeaderContainer device={device}>
        <Header
          qaLabel="promoProgressTitle"
          tag="h2"
          color={isDarkMode ? "white.900" : "blue.600"}
          mb="space-1"
          fontFamily="bold"
        >
          {promoTitle}
        </Header>
      </HeaderContainer>
      {showRemainingDays && !!remainingDays && (
        <DaysContainer device={device}>
          <Paragraph
            qaLabel="promoProgressRemainDays"
            color={isDarkMode ? "blue_accent.200" : "blue_accent.500"}
            textTransform="uppercase"
            fontSize="xs"
            fontFamily="condensedRegular"
          >
            {`${remainingDays} Days left`}
          </Paragraph>
        </DaysContainer>
      )}
      <DetailsContainer>
        <Paragraph
          qaLabel="promoProgressDescription"
          color={isDarkMode ? "blue_accent.200" : "grey.800"}
          mb="space-1"
        >
          {promoDescription}
        </Paragraph>
      </DetailsContainer>
    </InfoContainer>
    <ProgressBarContainer device={device}>
      <ProgressBar
        completedSteps={completedSteps}
        icon={progressBarIcon}
        isDarkMode={isDarkMode}
      >
        {progressBarSteps.map((description) => (
          <ProgressBar.Step
            key={description}
            stepDescription={description}
            qaLabel={`progress-bar-step-${description.toLowerCase()}`}
          />
        ))}
      </ProgressBar>
    </ProgressBarContainer>
    {CTAShow && CTALabel && typeof onCTAPress === "function" && (
      <ButtonContainer device={device}>
        <Button
          qaLabel="promoProgressCTA"
          onPress={onCTAPress}
          variant="primary"
          isStretched
        >
          {CTALabel}
        </Button>
      </ButtonContainer>
    )}
  </PromoProgressContainer>
);

export default PromoProgress;
