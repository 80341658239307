import styled from "styled-components/native";
import { View } from "react-native";
import { PageHeight } from "../../context/types";

export const StyledView = styled(View)<PageHeight>`
  background-color: ${(props) => props.theme.colors.white[900]};
  width: 100%;
  height: ${({ isFlexible, pageHeight }) => (isFlexible ? pageHeight : "100%")};
  top: 0;
  left: 0;
`;
