const {
  STORYBLOK_TOKENS: STORYBLOK_TOKENS_STAGING
} = require("../../utils/fdr/storyblok");
const {
  APPTENTIVE_TOKENS: APPTENTIVE_TOKENS_STAGING
} = require("../../utils/fdr/apptentive");

module.exports = {
  domain: {
    desktop: "staging.racing.fanduel.com",
    mobile: "staging.racing.fanduel.com",
    talentPicks: "staging.racing.fanduel.com/talent-picks/",
    accountWallet: "account.racing.fanduel.com",
    equibase: "equibase-store.fanduel.com"
  },
  service_host: "service-staging.racing.fanduel.com",
  externalDomain: {
    FDTermsConditions: "https://fanduel.com/terms",
    FDPolicy: "https://fanduel.com/privacy",
    FDRLoginBridge: "https://fanduel.com/sso-auth/fdracing",
    FDRSignup:
      "https://www.fanduel.com/join?next=%2Fsso-auth%2Ffdracing%3Fredirect_uri%3D"
  },
  externalServices: {
    fanduel: "https://api.fanduel.com",
    cpp: "https://promos-api.racing-staging.fanduel.com"
  },
  clientId: "a301e728606047a5b6522727d495b3ed",
  brazeId: "e0b90c9d-dfd9-4809-a627-565a5cd7f3b2",
  storyblokToken: STORYBLOK_TOKENS_STAGING.STAGING,
  storyblokVersion: "draft",
  appTentiveIos: APPTENTIVE_TOKENS_STAGING.IOS.PRODUCTION,
  appTentiveAndroid: APPTENTIVE_TOKENS_STAGING.ANDROID.PRODUCTION,
  appTentiveWeb: APPTENTIVE_TOKENS_STAGING.WEB.PRODUCTION
};
