/* eslint-disable react/no-array-index-key */
import React, { forwardRef } from "react";
import { TableProps, RowProps, CellProps } from "./types";
import {
  TableWrapper,
  RowWrapper,
  HeadSectionWrapper,
  BodySectionWrapper,
  HeadCellWrapper,
  BodyCellWrapper
} from "./styled-components";

const Table = forwardRef<HTMLTableElement, TableProps>(
  (
    {
      children,
      headerShadow,
      headerSticky,
      theadData,
      qaLabel = "table",
      ...rest
    },
    ref
  ) => (
    <TableWrapper {...rest} ref={ref} data-qa-label={qaLabel}>
      {theadData && (
        <HeadSectionWrapper headerSticky={headerSticky}>
          <TableRow qaLabel={`${qaLabel}-thead-row`}>
            {theadData.map((item, index) => (
              <TableCell
                key={`table-thead-cell-${index}`}
                type="th"
                colSize={item.size || "auto"}
                addShadow={!!headerShadow}
              >
                {item.title}
              </TableCell>
            ))}
          </TableRow>
        </HeadSectionWrapper>
      )}
      {children && <BodySectionWrapper>{children}</BodySectionWrapper>}
    </TableWrapper>
  )
);

const TableRow = forwardRef<HTMLTableRowElement, RowProps>(
  ({ children, qaLabel = "table-tbody-row", ...rest }, ref) => (
    <RowWrapper {...rest} ref={ref} data-qa-label={qaLabel}>
      {children}
    </RowWrapper>
  )
);

const TableCell = forwardRef<HTMLTableCellElement, CellProps>(
  ({ children, type = "td", qaLabel = "table-cell", ...rest }, ref) => {
    if (type === "th") {
      return (
        <HeadCellWrapper
          {...rest}
          ref={ref}
          data-qa-label={`${qaLabel}-${type}`}
        >
          {children}
        </HeadCellWrapper>
      );
    }
    return (
      <BodyCellWrapper {...rest} ref={ref} data-qa-label={`${qaLabel}-${type}`}>
        {children}
      </BodyCellWrapper>
    );
  }
);

export default Object.assign(Table, {
  Row: TableRow,
  Cell: TableCell
});
