import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";

export const OtherPaymentsInfo = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  & > div:first-child {
    position: sticky;
    top: 0;
    left: 0;
    border-bottom: 1px solid ${buildColor("blue", "100")};
  }
`;

export const Tab = styled.li<{ isActive: boolean }>`
  background-color: ${buildColor("white", "900")};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: auto;
  flex-grow: 1;
  padding: 16px 12px;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background-color: ${buildColor("blue_accent", "500")};
      }
    `};
`;

export const OtherPaymentsContainer = styled.section<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  background-color: ${buildColor("white", "900")};
  padding: 12px;
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  flex-grow: 1;
  color: ${buildColor("grey", "900")};

  & ul {
    margin-left: 24px;
    list-style: initial;
  }

  & li:not(:last-child) {
    margin-bottom: 12px;
  }

  & p:first-child {
    margin-top: 8px;
  }

  & p {
    margin-bottom: 20px;
    white-space: pre-line;
  }
`;
