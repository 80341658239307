import React from "react";
import { IconPathProps } from "../../types";

export const TimeForm = ({ lineColor }: IconPathProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2
    24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM8.71289
    9.11572H11.9795V7.33594H3.29297V9.11572H6.51562V18H8.71289V9.11572ZM15.4365
    13.6421H19.6553V11.8696H15.4365V9.11572H20.1826V7.33594H13.2393V18H15.4365V13.6421Z"
    fill={lineColor}
  />
);
