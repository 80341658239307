import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system/src/_static/colorPalette";
import { cubic, generateTransition } from "@tvg/atomic-ui/_static/Transitions";

export const RadioButton = styled.div<{ isActive: boolean }>`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isActive }) =>
    !isActive
      ? css`
          border: 2px solid ${buildColor("blue", "100")};
        `
      : css`
          background-color: ${buildColor("blue_accent", "500")};
        `};
  & > div {
    will-change: transform;
    transition: ${generateTransition(cubic, "transform")};
    transform: ${({ isActive }) => (isActive ? "scale(1)" : "scale(0)")};
  }
`;
