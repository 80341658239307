import { Dispatch } from "redux";
import { attempt, throttle } from "lodash";
import { addDays, isAfter } from "date-fns";

import { resetStoryblokPromos, setStoryblokPromos } from "../redux/actions";
import { Actions } from "../redux/actions/types";
import { StoryblokDraftConfig } from "../types/storyblok";
import { fetchPromoAcquisition } from "./fetchPromoAcquisition";
import { getPromoOnboarding } from "./getPromoOnboarding";
import { getPromoFinishedDate } from "./getPromoFinishedDate";
import { PromoEligible } from "../types/promos";

export const setUserPromosOnboarding = throttle(
  (
    dispatch: Dispatch<Actions>,
    accountId: string,
    promoEligible: PromoEligible,
    draftConfig: StoryblokDraftConfig = {}
  ) =>
    attempt(async () => {
      const { draft, draftForceService } = draftConfig;
      const { rootId } = promoEligible;
      const promosAcquisition =
        draftForceService || (await fetchPromoAcquisition(accountId, rootId));
      const promoFinishedDate = promosAcquisition
        ? getPromoFinishedDate(promosAcquisition)
        : null;
      const todayDate = new Date(Date.now());
      const promoExpiredDate = promoFinishedDate
        ? addDays(new Date(promoFinishedDate), 1)
        : null;
      if (
        !promosAcquisition ||
        promosAcquisition.remainingDays <= 0 ||
        (promoExpiredDate && isAfter(todayDate, promoExpiredDate))
      ) {
        dispatch(resetStoryblokPromos());
      } else {
        const storyblokComponents = await getPromoOnboarding(
          promosAcquisition,
          rootId,
          draft
        );
        dispatch(setStoryblokPromos(storyblokComponents));
      }
    })
);
