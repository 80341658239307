import styled from "styled-components";
import Text from "../Text";

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const TrackName = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
