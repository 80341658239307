import styled, { css } from "styled-components/native";
import { View } from "react-native";
import { colors } from "../../../theming/themes/common/colors";
import { Header, Paragraph } from "../../typography";

export const StyledComponent = styled(View)<{
  hasRoundedCorners: boolean;
  hasBorderBottom?: boolean;
  hasShadow: boolean;
  hasSubtitle: boolean;
  isMobile: boolean;
  isDarkMode?: boolean;
}>`
  flex-direction: row;
  align-content: space-between;
  padding: ${({ isMobile, theme }) =>
    isMobile ? theme.space["space-3"] : "14px"};
  background-color: ${({ theme, isDarkMode }) =>
    isDarkMode
      ? theme.colorTokens.background.promotional
      : theme.colorTokens.content.onDark};
  border-bottom-color: ${colors.blue["100"]};
  ${({ hasBorderBottom }) =>
    hasBorderBottom ? "border-bottom-width: 1px;" : "border-bottom-width: 0px;"}
  ${({ hasShadow }) =>
    hasShadow && `box-shadow: 0 1px 3px ${colors.blue["100"]}`};
  ${({ hasSubtitle, isMobile }) =>
    isMobile
      ? `min-height: ${hasSubtitle ? "69px" : "48px"}`
      : `min-height: ${hasSubtitle ? "85px" : "64px"}`};
  ${({ theme, hasRoundedCorners }) =>
    hasRoundedCorners &&
    `
    border-top-left-radius: ${theme.radii.s};
    border-top-right-radius: ${theme.radii.s}`}
`;

export const StyledHeader = styled(Header)`
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: ${({ theme }) => theme.lineHeights.l};
  ${({ isDarkMode, theme }) =>
    isDarkMode && ` color: ${theme.colorTokens.content.onDark};`};
`;

const trackModalleftPadding = (isTrackRulesModal?: boolean) =>
  isTrackRulesModal ? "4px" : "0";

export const TextContainer = styled(View)<{
  isMobile: boolean;
  isPaddingLeft: boolean;
  isPaddingRight: boolean;
  withoutButton: boolean;
  titlePosition?: string;
  isTrackRulesModal?: boolean;
}>`
  flex-direction: column;
  ${({ titlePosition }) =>
    titlePosition &&
    css`
      align-items: ${titlePosition};
    `};
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${({
    isMobile,
    theme,
    isPaddingLeft,
    isPaddingRight,
    isTrackRulesModal
  }) =>
    isMobile
      ? `${theme.space["space-1"]}
      ${isPaddingRight ? theme.space["space-8"] : "0"}
      ${theme.space["space-1"]}
      ${
        isPaddingLeft && !isTrackRulesModal
          ? theme.space["space-8"]
          : trackModalleftPadding(isTrackRulesModal)
      }`
      : `${theme.space["space-2"]}
         ${isPaddingRight ? theme.space["space-8"] : "0"}
         ${theme.space["space-2"]}
         ${isPaddingLeft ? theme.space["space-8"] : "0"}`};
  ${({ withoutButton, isMobile, theme }) =>
    withoutButton &&
    `padding: ${isMobile ? theme.space["space-1"] : theme.space["space-2"]}`};
`;

export const DescriptionContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  padding-top: ${({ theme }) => theme.space["space-1"]};
`;

export const ButtonIconContainer = styled(View)`
  padding: 2px 0;
`;

export const ContainerLink = styled(View)`
  flex-shrink: 1;
`;

export const StyledParagraph = styled(Paragraph)`
  text-align: center;
`;

export const LeftElement = styled(View)`
  justify-content: center;
`;
