import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Platform,
  Keyboard,
  Animated,
  LayoutChangeEvent,
  StyleSheet,
  View,
  EmitterSubscription,
  GestureResponderEvent
} from "react-native";
import { useQaLabel } from "../../hooks/useQaLabel";
import { ToastMessageProps } from "./types";
import useToastAnimation from "./hooks/useToastAnimation";
import AlertInline from "../alertInline";
import { TvgConfContext } from "../../utils/tvgConfProvider";

export const durations = {
  SHORT: 5000,
  LONG: 10000
};

export const bottomOffsets = {
  SHORT: 20,
  TALL: 68
};

export const ToastMessage = ({
  isVisible = true,
  autoHide = true,
  onHide,
  delay = 0,
  duration,
  topOffset = 76,
  bottomOffset = bottomOffsets.SHORT,
  keyboardOffset = 15,
  position = "bottom",
  hasDismissButton = false,
  qaLabel = "toastMessage",
  isFromDSManager = false,
  isFullWidth = true,
  icon,
  onClick,
  ...rest
}: ToastMessageProps) => {
  const { device } = useContext(TvgConfContext);
  const toastDuration =
    duration ?? ((rest.title && durations.LONG) || durations.SHORT);

  const toastRef = useRef<View>(null);
  const { opacity, show, hide, setShowTimeout } = useToastAnimation(
    toastRef,
    toastDuration,
    autoHide,
    onHide
  );
  const [height, setHeight] = useState(60);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  useEffect(() => {
    let keyboardDidShowListener: EmitterSubscription;
    let keyboardDidHideListener: EmitterSubscription;

    if (Platform.OS === "ios") {
      keyboardDidShowListener = Keyboard.addListener(
        "keyboardDidShow",
        keyboardDidShow
      );
      keyboardDidHideListener = Keyboard.addListener(
        "keyboardDidHide",
        keyboardDidHide
      );
    }

    return () => {
      if (keyboardDidShowListener || keyboardDidHideListener) {
        keyboardDidShowListener.remove();
        keyboardDidHideListener.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      // @ts-ignore
      setShowTimeout(setTimeout(() => show(), delay));
    } else {
      hide();
    }
  }, [isVisible, isKeyboardVisible]);

  // @ts-ignore
  const keyboardDidShow = (event) => {
    setIsKeyboardVisible(true);
    setKeyboardHeight(event.endCoordinates?.height);
  };

  const keyboardDidHide = () => {
    setIsKeyboardVisible(false);
    setKeyboardHeight(0);
  };

  const onLayout = (e: LayoutChangeEvent) => {
    setHeight(e.nativeEvent.layout.height);
  };

  const offset =
    position === "bottom"
      ? (isKeyboardVisible && keyboardOffset + keyboardHeight) || bottomOffset
      : topOffset;
  const range = [height, -offset];
  const outputRange =
    position === "bottom" ? range : range.map((value) => -value);

  const translateY = opacity.interpolate({
    inputRange: [0, 1],
    outputRange
  });

  const dismissPressHandler = (e: GestureResponderEvent) => {
    hide();
    if (rest.onDismissPress) {
      rest.onDismissPress(e);
    }
  };

  const deviceStyles = StyleSheet.create({
    mobile: {
      justifyContent: "center",
      flex: 1,
      left: 0,
      right: 0,
      marginRight: 12,
      marginLeft: 12
    },
    tablet:
      Platform.OS === "web"
        ? {
            justifyContent: "center",
            left: 0,
            right: 0,
            margin: "auto",
            width: 351
          }
        : {
            width: 351,
            flex: 1,
            alignSelf: "center",
            justifyContent: "center"
          },
    desktop: {
      justifyContent: "center",
      left: 0,
      right: 0,
      margin: "auto",
      width: 351
    }
  } as const);

  const messagePosition = position === "bottom" ? { bottom: 0 } : { top: 0 };

  return (
    <Animated.View
      style={[
        { opacity, transform: [{ translateY }] },
        isFromDSManager ? styleForDsToastManager.base : style.base,
        deviceStyles[device],
        messagePosition
      ]}
      pointerEvents={isVisible ? "auto" : "none"}
      onLayout={onLayout}
      ref={toastRef}
      {...useQaLabel(qaLabel)}
    >
      <AlertInline
        type="toast"
        variant={rest.variant}
        title={rest.title}
        message={rest.message}
        actionLink={rest.actionLink}
        hasRoundedStyle={rest.hasRoundedStyle}
        onDismissPress={hasDismissButton ? dismissPressHandler : undefined}
        hasDismissAnimation={false}
        qaLabel={`${qaLabel}-message`}
        isFullWidth={isFullWidth}
        icon={icon}
        onClick={onClick}
      />
    </Animated.View>
  );
};

const style = StyleSheet.create({
  base: {
    // @ts-ignore
    position: Platform.OS === "web" ? "relative" : "absolute",
    zIndex: 99999
  }
});

const styleForDsToastManager = StyleSheet.create({
  base: {
    // @ts-ignore
    position: Platform.OS === "web" ? "fixed" : "absolute",
    zIndex: 99999
  }
});

export default ToastMessage;
