import styled from "styled-components";

export const WarningContainer = styled.div`
  display: inline-flex;
  position: relative;
  bottom: 90px;
  box-shadow: var(--fd-shadows-elevations-bottom-mid);
  background-color: var(--fd-colors-system-info-content-on-default);
  padding: var(--fd-space-space-3);
  border-radius: var(--fd-radii-border-radius-020);

  & p {
    margin-left: var(--fd-space-space-1);
    color: var(--fd-colors-system-warning-content-accent);
  }
`;
