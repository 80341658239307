import styled from "styled-components";
import {
  fontNormal,
  fontCondensedNormal,
  fontBold
} from "@tvg/atomic-ui/_static/Typography";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${
    buildColor(
      "white",
      "100"
    ) /* TODO: DS equivalent buildColor("white", "900") */
  };
  height: 104px;
  box-shadow: 0 2px 4px rgba(17, 43, 68, 0.06), 0 1px 2px rgba(17, 43, 68, 0.08);
`;

export const InfoContainer = styled.div`
  max-width: 976px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const WalletDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WalletInfo = styled.div`
  display: flex;
`;

export const Title = styled.h1`
  padding-bottom: 12px;
  font-family: ${fontBold};
  font-size: 28px;
  line-height: 33px;
  color: ${buildColor("blue", "900")};
`;

export const SubTitle = styled.span`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  & > span {
    margin-left: 8px;
    font-family: ${fontNormal};
    color: ${buildColor("grey", "800")};
  }
`;

export const Spent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 24px;
`;

export const Limits = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Value = styled.span`
  font-family: ${fontNormal};
  padding-bottom: 2px;
  color: ${buildColor("grey", "800")};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

export const Description = styled.span`
  text-transform: uppercase;
  font-family: ${fontCondensedNormal};
  font-size: 12px;
  line-height: 13px;
`;
