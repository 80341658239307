import React, { createContext, forwardRef } from "react";
import { View } from "react-native";
import { useQaLabel } from "../../../hooks/useQaLabel";
import { PebbleGroupProps, PebbleGroupContextProps } from "./types";
import { StyledViewContainer } from "./styled-components";

const initialState = { selectedValue: "", onChange: () => {} };

export const GroupContext =
  createContext<PebbleGroupContextProps>(initialState);

export const PebbleGroup = forwardRef<View, PebbleGroupProps>(
  ({ children, selectedValue, onChange, qaLabel, ...rest }, ref) => {
    const viewTestProps = useQaLabel(qaLabel);
    return (
      // @ts-ignore
      <StyledViewContainer ref={ref} {...rest} {...viewTestProps}>
        <GroupContext.Provider value={{ selectedValue, onChange }}>
          {children}
        </GroupContext.Provider>
      </StyledViewContainer>
    );
  }
);
