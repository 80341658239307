// @flow
import React from "react";
import Spinner from "../Spinners";
import { Overlay, Title } from "./styled-components";

type Props = {
  isRounded: boolean,
  isTransparent: boolean,
  customTop: number,
  qaLabel: string
};

const ProcessingOverlay = (props: Props) => (
  <Overlay
    data-qa-label={props.qaLabel}
    rounded={props.isRounded}
    isTransparent={props.isTransparent}
    top={props.customTop}
  >
    <Spinner qaLabel={`${props.qaLabel}Spinner`} />
    <Title qaLabel={`${props.qaLabel}Text`}>Processing</Title>
  </Overlay>
);

ProcessingOverlay.defaultProps = {
  isRounded: false,
  isTransparent: false,
  customTop: 0,
  qaLabel: "processingRequest"
};

export default ProcessingOverlay;
