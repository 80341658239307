import { Dispatch } from "@reduxjs/toolkit";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { Dispatch as ReactDispatch, SetStateAction } from "react";
import { toggleQuickWithdraw } from "@tvg/sh-lib-paws/redux/slices/quickWithdrawSlice";
import { closePaymentMethodSelectorModal } from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import { events as AlchemerEvents } from "@urp/alchemer";
import { gtmWithdrawClose } from "../../../../gtm";
import { ErrorMessage, PaymentType, PaymentTypeItem } from "../../../../types";
import {
  hasNoBalance,
  hasOnlyPlayableBalance
} from "../../common/balanceUtils";

export const shouldRenderHeader = (
  withdrawalLimit: number,
  capiMaxWithdrawAmount: number,
  showSuccessMessage: boolean,
  isLoading: boolean,
  enableDepositPage: boolean,
  accountsOnFile: PaymentTypeItem[]
) => {
  const noBalance = hasNoBalance(withdrawalLimit, capiMaxWithdrawAmount);
  const onlyPlayBalance = hasOnlyPlayableBalance(
    withdrawalLimit,
    capiMaxWithdrawAmount
  );

  return (
    (!showSuccessMessage || isLoading || enableDepositPage) &&
    !noBalance &&
    !onlyPlayBalance &&
    (accountsOnFile.length > 0 ||
      (accountsOnFile.length === 0 && withdrawalLimit > 0))
  );
};

export const onWithdrawClose =
  (
    paymentType: PaymentType,
    accountId: string,
    dispatch: Dispatch,
    onCloseCallback: NullaryFn<void>,
    errorMessageInitialState: ErrorMessage,
    paymentMethodSelectorModalOpen: boolean,
    setValue: ReactDispatch<SetStateAction<string>>,
    setShowSuccessMessage: ReactDispatch<SetStateAction<boolean>>,
    setEnableDepositPage: ReactDispatch<SetStateAction<boolean>>,
    setErrorMessage: ReactDispatch<SetStateAction<ErrorMessage>>
  ) =>
  () => {
    gtmWithdrawClose(paymentType, accountId);
    setValue("");
    setShowSuccessMessage(false);
    setEnableDepositPage(false);
    setErrorMessage(errorMessageInitialState);
    dispatch(toggleQuickWithdraw(false));
    onCloseCallback();

    if (paymentMethodSelectorModalOpen) {
      dispatch(closePaymentMethodSelectorModal());
    }

    AlchemerEvents.closeWithdrawal();
  };
