import React from "react";
import { Path } from "react-native-svg";

import { IconPathProps } from "../types";

const ArrowCollapseLeft = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M2.625 1.5L2.63081 11.9976L2.625 22.5M13.8903 4.5L6.39034 12L13.8903 
    19.5M6.46651 12H24"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default ArrowCollapseLeft;
