import styled from "styled-components";
import { titleMedium } from "@tvg/atomic-ui/_static/Typography";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const AvailablePaymentContainer = styled.div`
  padding-bottom: 8px;
`;

export const AvailablePaymentContainerHeader = styled.h2`
  color: ${buildColor("grey", "900")};
  font-size: 16px;
  background-color: #fff;
  padding: 16px 0 4px 12px;
  font-family: ${titleMedium};
  line-height: 19px;
`;

export const EmptyPaymentContainer = styled.div`
  text-align: center;
  background-color: ${buildColor("blue_accent", "000")};
  padding: 20px 12px;
  margin-bottom: -8px;
  border-top: solid 1px #d6e3f0;
`;
