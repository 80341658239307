import React from "react";
import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { getPulseAnimation } from "./styled-components";

const Container = styled.div`
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${buildColor("white", "100")};
  overflow-x: hidden;
  z-index: 101;
  border-radius: 0 0 4px 4px;
  flex: 1;

  .pulse {
    animation: ${getPulseAnimation()} 1.25s infinite;
  }
`;

const AmountAndFeesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
`;

const KeyboardAndSubmitWrapper = styled.div`
  padding-bottom: 16px;

  svg {
    height: 100%;
  }
`;

const KeyboardAndSubmit = styled.svg``;

const Amount = styled.svg``;

const Fee = styled.svg`
  margin-top: 12px;
`;

const DepositsMask = () => (
  <Container>
    <AmountAndFeesWrapper>
      <Amount
        width={44}
        height={60}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="#E9EFF5" d="M0 0h44v60H0z" className="pulse" />
      </Amount>
      <Fee
        width={94}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={94} height={24} rx={12} fill="#E9EFF5" className="pulse" />
      </Fee>
    </AmountAndFeesWrapper>
    <KeyboardAndSubmitWrapper>
      <KeyboardAndSubmit
        width="100%"
        height="359"
        viewBox="0 0 375 327"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={0.4} filter="url(#a)">
          <path
            d="M12 285a4 4 0 0 1 4-4h343a4 4 0 0 1 4 4v38a4 4 0 0 1-4 4H16a4 4 0 0 1-4-4v-38Z"
            fill="#38AB4F"
          />
        </g>
        <path fill="#fff" d="M0 57h94.937v52H0z" />
        <path fill="#E9EFF5" d="M39.469 75h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M0 109h94.937v52H0z" />
        <path fill="#E9EFF5" d="M39.469 127h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M0 161h94.937v52H0z" />
        <path fill="#E9EFF5" d="M39.469 179h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M0 213h94.937v52H0z" />
        <path fill="#E9EFF5" d="M39.469 231h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M94.936 57h94.937v52H94.937z" />
        <path fill="#E9EFF5" d="M134.405 75h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M94.936 109h94.937v52H94.937z" />
        <path fill="#E9EFF5" d="M134.405 127h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M94.936 161h94.937v52H94.937z" />
        <path fill="#E9EFF5" d="M134.405 179h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M94.936 213h94.937v52H94.937z" />
        <path fill="#E9EFF5" d="M134.405 231h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M189.873 57h94.937v52h-94.937z" />
        <path fill="#E9EFF5" d="M229.342 75h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M189.873 109h94.937v52h-94.937z" />
        <path fill="#E9EFF5" d="M229.342 127h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M189.873 161h94.937v52h-94.937z" />
        <path fill="#E9EFF5" d="M229.342 179h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M189.873 213h94.937v52h-94.937z" />
        <path fill="#E9EFF5" d="M229.342 231h16v16h-16z" className="pulse" />
        <path fill="#fff" d="M284.811 57h90.19v52h-90.19z" />
        <path fill="#E9EFF5" d="M299.905 75h60v16h-60z" className="pulse" />
        <path fill="#fff" d="M284.811 109h90.19v52h-90.19z" />
        <path fill="#E9EFF5" d="M299.905 127h60v16h-60z" className="pulse" />
        <path fill="#fff" d="M284.811 161h90.19v52h-90.19z" />
        <path fill="#E9EFF5" d="M299.905 179h60v16h-60z" className="pulse" />
        <path fill="#fff" d="M284.811 213h90.19v52h-90.19z" />
        <path fill="#E9EFF5" d="M299.905 231h60v16h-60z" className="pulse" />
        <rect x={12.5} y={0.5} width={350} height={40} rx={3.5} fill="#fff" />
        <path
          fill="#E9EFF5"
          d="M24 13h16v16H24zM52 13h120v16H52z"
          className="pulse"
        />
        <rect
          x={12.5}
          y={0.5}
          width={350}
          height={40}
          rx={3.5}
          stroke="#D6E3F0"
        />
        <defs>
          <filter
            id="a"
            x={9}
            y={279}
            width={357}
            height={52}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={1} />
            <feGaussianBlur stdDeviation={1.5} />
            <feColorMatrix values="0 0 0 0 0.0666667 0 0 0 0 0.168627 0 0 0 0 0.266667 0 0 0 0.12 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_12601_227283"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_12601_227283"
              result="shape"
            />
          </filter>
        </defs>
      </KeyboardAndSubmit>
    </KeyboardAndSubmitWrapper>
  </Container>
);

export default DepositsMask;
