import React, { forwardRef, memo, useContext } from "react";
import { View } from "react-native";
import { TvgConfContext } from "../../utils/tvgConfProvider";
import { Devices } from "../../theming/types";
import { useQaLabel } from "../../hooks/useQaLabel";
import { StyledContainer } from "./styled-components";
import {
  Step as ProgressBarStep,
  AchievementStep as ProgressAchievementStep
} from "./components";
import { PBContext } from "./context";
import {
  ProgressBarProps,
  ProgressBarStepProps,
  ProgressBarAchievementStepProps,
  ProgressBarCompoundComponent
} from "./types";

export const stylesFn = (device: Devices) => ({
  base: {
    marginLeft: 1,
    marginRight: 1
  },
  firstStep: {
    marginLeft: 0,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4
  },
  lastStep: {
    marginRight: 0,
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4
  },
  withIcon: {
    marginTop: device === "mobile" ? 10 : 14
  }
});

export const ProgressBar = memo(
  forwardRef<View, ProgressBarProps>(
    (
      {
        qaLabel = "progress-bar",
        completedSteps = 0,
        icon = null,
        isDarkMode = false,
        children,
        ...rest
      },
      ref
    ) => {
      const viewTestProps = useQaLabel(qaLabel);
      const { device } = useContext(TvgConfContext);
      const styles = stylesFn(device);
      const renderSteps = () =>
        children.map((stepElement, index) => {
          let style = {};
          if (index === 0) {
            style = styles.firstStep;
          } else if (index === children.length - 1 && !icon) {
            style = styles.lastStep;
          }
          if (icon) {
            style = {
              ...style,
              ...styles.withIcon
            };
          }

          const stepNumber = index + 1;

          return React.cloneElement(stepElement, {
            key: stepNumber,
            stepNumber,
            style: [styles.base, style],
            isDarkMode
          });
        });

      return (
        <StyledContainer ref={ref} {...rest} {...viewTestProps}>
          <PBContext.Provider value={{ completedSteps }}>
            {renderSteps()}
            {icon ? (
              <ProgressAchievementStep
                icon={icon}
                totalSteps={children.length}
                isDarkMode={isDarkMode}
              />
            ) : null}
          </PBContext.Provider>
        </StyledContainer>
      );
    }
  )
) as ProgressBarCompoundComponent<
  typeof ProgressBarStep,
  typeof ProgressAchievementStep
>;

ProgressBar.Step = ProgressBarStep;
ProgressBar.AchievementStep = ProgressAchievementStep;

export {
  ProgressBarProps,
  ProgressBarStepProps,
  ProgressBarAchievementStepProps
};
export default ProgressBar;
