import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { useLocation } from "react-router";
import { useNavigate } from "@tvg/custom-hooks";
import {
  getAvailablePaymentMethodsInfos,
  getQuickDepositStateOpen
} from "@tvg/sh-lib-paws/redux/selectors";
import DepositFundsModal from "@tvg/wallet/src/components/FundsModal/Deposit";
import CardDeclinedModal from "@tvg/wallet/src/components/CardDeclinedModal";

import { fetchAvailablePaymentMethods } from "@tvg/wallet/src/utils";

import { closeQuickDepositModal } from "./utils";
import { AvailablePaymentMethodsInfos } from "../../types";

const QuickDeposit = () => {
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const isOpen: boolean = useSelector(getQuickDepositStateOpen);
  const availablePaymentMethodsInfos: AvailablePaymentMethodsInfos =
    useSelector(getAvailablePaymentMethodsInfos);

  const refetchAvailableMethods = useCallback(
    async ({ accountNumber, setIsLoading, setHasError } = {}) => {
      if (!setIsLoading || !setHasError) {
        return null;
      }

      const response = await fetchAvailablePaymentMethods({
        accountNumber,
        availablePaymentMethodsInfos,
        dispatch,
        setIsLoading,
        setHasError
      });

      return response;
    },
    [JSON.stringify(availablePaymentMethodsInfos)]
  );

  return (
    <>
      <DepositFundsModal
        history={history}
        isQuickDeposit
        isQuickDepositOpen={isOpen}
        availablePaymentMethodsInfos={availablePaymentMethodsInfos}
        refetchAvailableMethods={refetchAvailableMethods}
        onCloseCallback={closeQuickDepositModal(dispatch, location, history)}
      />
      {get(location, "pathname") !== "/wallet" && (
        <CardDeclinedModal isQuickDeposit isDesktop />
      )}
    </>
  );
};

export default QuickDeposit;
