// @flow
import React from "react";
import {
  getPawsOnlyIcons,
  getPaymentMethodIconName
} from "@tvg/wallet/src/utils";
import type { NullaryFn } from "@tvg/types/Functional";
import { noop } from "lodash/fp";
import {
  ButtonContainer,
  TextContainer,
  ButtonTitle,
  ButtonSubTitle,
  ButtonDescription,
  ArrowIconContainer
} from "./styled-components";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { arrowRight } from "../../_static/Icons/icons";
import type { IconType } from "../../_static/Icons/Types.js.flow";

import * as allIcons from "../../_static/Icons/icons";

type Props = {
  /**
   * Left icon name
   */
  icon?: IconType,
  /**
   * Left icon color
   */
  iconColor?: string,
  /**
   * onClick action
   */
  onClick?: NullaryFn<void>,
  /**
   * For button links default is button
   */
  isLink?: boolean,
  /**
   * Url for when is link
   */
  url?: string,
  /**
   * Main button text
   */
  title?: string,
  /**
   * Text next to button title
   */
  subTitle?: string,
  /**
   * Extra text below button title
   */
  description?: string,
  /**
   * Rotate Arrow icon for the dropdown version
   */
  rotateArrow?: number,
  /**
   * PaymentType
   */
  paymentType?: string,
  className?: string,
  isDisabled?: boolean
};

const PaymentButton = ({
  icon,
  onClick = noop,
  isLink = false,
  url = "#",
  iconColor,
  title,
  subTitle,
  description,
  paymentType,
  rotateArrow = 0,
  isDisabled = false,
  className
}: Props) => {
  const buttonProps = isLink ? { to: url } : { as: "button", onClick };
  const paymentIcon = getPaymentMethodIconName(paymentType);

  const capitalizeText = (text) =>
    text
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  return (
    <ButtonContainer
      data-qa-label="payment-button"
      className={className}
      isDisabled={isDisabled}
      {...buttonProps}
    >
      {getPawsOnlyIcons(
        paymentIcon,
        isDisabled ? buildColor("grey", "800") : undefined
      ) || (
        <Icon
          icon={icon || allIcons[paymentIcon]}
          size={16}
          color={
            isDisabled
              ? buildColor("grey", "800")
              : iconColor || buildColor("blue_accent", "500")
          }
        />
      )}
      <TextContainer>
        <ButtonTitle isDisabled={isDisabled}>
          {title && capitalizeText(title)}
          {!!subTitle && <ButtonSubTitle>{subTitle}</ButtonSubTitle>}
        </ButtonTitle>
        {!!description && <ButtonDescription>{description}</ButtonDescription>}
      </TextContainer>

      {!isDisabled && (
        <ArrowIconContainer rotateArrow={rotateArrow}>
          <Icon
            icon={arrowRight}
            size={16}
            color={buildColor("blue_accent", "500")}
          />
        </ArrowIconContainer>
      )}
    </ButtonContainer>
  );
};

export default PaymentButton;
