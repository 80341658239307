import { createContext } from "react";
import type { PBContextProps } from "../types";

const initialState: PBContextProps = {
  completedSteps: 0
};

export const PBContext = createContext<PBContextProps>(initialState);

PBContext.displayName = "PBContext";
