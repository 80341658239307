import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const Calendar = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M2.625 4.875V8.625H21.375V4.875C21.375 3.63236 20.3676 2.625 19.125
      2.625H15.375H8.625H4.875C3.63236 2.625 2.625 3.63236 2.625 4.875Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M6 13.125H9M10.5 13.125H13.5M15 13.125H18M6 16.875H9M10.5
      16.875H13.5M8.625 0V2.625M8.625 6L8.625 2.625M15.375
      3.57628e-07V2.625M15.375 6V2.625M2.625 8.625V19.875C2.625 20.7034 3.29657
      21.375 4.125 21.375H19.875C20.7034 21.375 21.375 20.7034 21.375
      19.875V8.625M2.625 8.625V4.875C2.625 3.63236 3.63236 2.625 4.875
      2.625H8.625M2.625 8.625H21.375M21.375 8.625V4.875C21.375 3.63236 20.3676
      2.625 19.125 2.625H15.375M8.625 2.625H15.375"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);

export default Calendar;
