import styled, { css } from "styled-components/native";
import { margin } from "styled-system";
import { View, ScrollView } from "react-native";

export const StyledViewContainer = styled(View)<{ isFullHeight: boolean }>`
  position: relative;
  ${({ isFullHeight }) => isFullHeight && "flex-grow:1"};
  ${margin}
`;

export const ContentView = styled(ScrollView)<{ isFullHeight: boolean }>`
  ${({ isFullHeight }) => isFullHeight && "flex-grow:1"};
`;

export const TabHeaderContainer = styled(View)<{
  headerPadding?: string;
  headerWidth?: string;
}>`
  border-bottom-color: ${({ theme }) => theme.colors.blue[100]};
  border-bottom-width: 1px;
  width: ${({ headerWidth }) => headerWidth || `100%`};
  background-color: ${({ theme }) => theme.colors.white[900]};
  z-index: 1;
  ${({ headerPadding }) =>
    headerPadding &&
    css`
      padding: ${headerPadding};
    `}
`;
