import React, { forwardRef } from "react";
import { TouchableHighlight } from "react-native";

import { LinkCardProps, LinkCardCompoundComponent } from "./types";
import { LinkCardList } from "./linkCardList";
import { borderStyles } from "./linkCardList/styled-components";
import { LinkItem, LinkItemTitle } from "../linkItem";

export const LinkCard = forwardRef<typeof TouchableHighlight, LinkCardProps>(
  (props, ref) => (
    <LinkItem ref={ref} bg="blue_accent.000" {...borderStyles} {...props} />
  )
) as LinkCardCompoundComponent<typeof LinkCardList>;

export {
  LinkCardProps,
  LinkItemTitle as LinkCardTitle,
  borderStyles as linkCardBorderStyles
};
LinkCard.List = LinkCardList;
export default LinkCard;
