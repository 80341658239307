import { useEffect, useState, SetStateAction } from "react";
import { get } from "lodash";
import payService from "@tvg/api/pay";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { updateBalance } from "@tvg/shared-actions/UserActions";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { useIsomorphicLayoutEffect } from "@tvg/custom-hooks";
import {
  PaymentType,
  NullaryFn,
  UnaryFn,
  FundingSources,
  PaypalActions,
  AccountNumber,
  ErrorMessage
} from "@tvg/wallet/src/types";
import {
  gtmAuthorizeDepositSuccess,
  gtmAuthorizeDeposit,
  gtmDepositNewPaymentMethod,
  gtmAuthorizeDepositError
} from "@tvg/wallet/src/gtm";
import { getAccountNumber } from "@urp/store-selectors";

declare global {
  interface Window {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    paypal: any;
  }
}

let amount: string;
let FUNDING_SOURCES: FundingSources = [];

// @ts-ignore
if (typeof window !== "undefined" && window.paypal) {
  FUNDING_SOURCES = [window.paypal.FUNDING.PAYPAL];
}

export const setPaypalButton = (
  value: string,
  maxDepositAmount: number,
  minDepositAmount: number,
  paypalActions: PaypalActions | null,
  setPaypalActive: UnaryFn<boolean, void>
) => {
  if (paypalActions) {
    if (
      Number(value) <= Number(maxDepositAmount) &&
      Number(value) >= Number(minDepositAmount) &&
      Number(value) !== 0
    ) {
      paypalActions.enable();
      setPaypalActive(true);
    } else {
      paypalActions.disable();
      setPaypalActive(false);
    }
  }
};

export const getPaypalToken = async (
  setIsLoading: UnaryFn<boolean, void>,
  accountId: string,
  value: string,
  setErrorMessage: React.Dispatch<SetStateAction<ErrorMessage>>,
  fromCreation: boolean
) => {
  try {
    setIsLoading(true);
    const {
      data: { token }
    } = await payService.getToken(accountId, value);
    gtmAuthorizeDeposit(fromCreation, "PAY", accountId);
    return token;
  } catch (err) {
    const errorCode = get(err, "response.data.code", "");

    setErrorMessage((prevState) => ({
      ...prevState,
      isOpen: true,
      errorCode,
      isRetry: false
    }));

    setIsLoading(false);
  }

  return null;
};

export const finishPaypalDeposit = async (
  orderID: string,
  accountId: string,
  setShowSuccessMessage: UnaryFn<boolean, void>,
  setIsLoading: UnaryFn<boolean, void>,
  dispatch: Dispatch,
  setErrorMessage: React.Dispatch<SetStateAction<ErrorMessage>>,
  pawsErrorMessages: Object,
  fromCreation: boolean,
  paymentType: PaymentType,
  errorMessageInitialState: ErrorMessage,
  refetchAvailableMethods: NullaryFn<void>
) => {
  try {
    const { data } = await payService.finishDeposit(accountId, orderID);
    if (data) {
      gtmAuthorizeDepositSuccess(fromCreation, "PAY", accountId, amount);
      dispatch(updateBalance(data.balance));
      mediatorClassic.dispatch("BALANCE_UPDATE", data.balance);
      setShowSuccessMessage(true);
    }

    if (fromCreation) {
      gtmDepositNewPaymentMethod(paymentType);
      refetchAvailableMethods();
    }
  } catch (err) {
    const errorCode = get(err, "response.data.code", "");
    const errorByCode =
      get(pawsErrorMessages, `${errorCode}`) ||
      get(pawsErrorMessages, "default");

    gtmAuthorizeDepositError(
      fromCreation,
      paymentType,
      accountId,
      get(errorByCode, "description", errorMessageInitialState.description)
    );

    setErrorMessage((prevState) => ({
      ...prevState,
      isOpen: true,
      errorCode,
      isRetry: false,
      paypalRetryMethod: () =>
        finishPaypalDeposit(
          orderID,
          accountId,
          setShowSuccessMessage,
          setIsLoading,
          dispatch,
          setErrorMessage,
          pawsErrorMessages,
          fromCreation,
          paymentType,
          errorMessageInitialState,
          refetchAvailableMethods
        )
    }));
  }
  setIsLoading(false);
};

export const usePaypalDeposit = (
  paymentType: PaymentType,
  value: string,
  maxDepositAmount: number,
  minDepositAmount: number,
  isModalOpen: boolean,
  isLoading: boolean,
  setShowAmountWarning: UnaryFn<boolean, void>,
  setErrorMessage: React.Dispatch<SetStateAction<ErrorMessage>>,
  pawsErrorMessages: Object,
  errorMessageInitialState: ErrorMessage,
  setShowSuccessMessage: UnaryFn<boolean, void>,
  setIsLoading: UnaryFn<boolean, void>,
  dispatch: Dispatch,
  fromCreation: boolean,
  refetchAvailableMethods: NullaryFn<void>
) => {
  const [paypalActions, setPaypalActions] = useState<null | PaypalActions>(
    null
  );
  const [isPaypalButtonSet, setPaypalIntegration] = useState<boolean>(false);

  const [isPaypalActive, setPaypalActive] = useState<boolean>(false);

  const accountId: AccountNumber = useSelector(getAccountNumber);

  useEffect(() => {
    if (!isModalOpen || paymentType !== "PAY") setPaypalIntegration(false);
  }, [isModalOpen, paymentType]);

  useEffect(() => {
    if (paymentType === "PAY") {
      amount = Number(value).toFixed(2);
      setPaypalButton(
        amount,
        maxDepositAmount,
        minDepositAmount,
        paypalActions,
        setPaypalActive
      );
    }
  }, [value, paymentType]);

  useIsomorphicLayoutEffect(() => {
    if (
      isModalOpen &&
      !isLoading &&
      !isPaypalButtonSet &&
      paymentType === "PAY" &&
      typeof window !== "undefined" &&
      window.paypal
    ) {
      FUNDING_SOURCES.forEach((fundingSource) => {
        const button = window.paypal.Buttons({
          fundingSource,
          onInit: (_: unknown, actions: PaypalActions) => {
            setPaypalButton(
              amount,
              maxDepositAmount,
              minDepositAmount,
              actions,
              setPaypalActive
            );
            setPaypalActions(actions);
          },
          onClick() {
            if (Number(amount) < Number(minDepositAmount)) {
              setShowAmountWarning(true);
            }
          },
          onCancel: () => {
            setIsLoading(false);
          },
          onError: () => {
            setIsLoading(false);
          },
          createOrder: () =>
            getPaypalToken(
              setIsLoading,
              accountId,
              amount,
              setErrorMessage,
              fromCreation
            ),
          onApprove: ({ orderID }: { orderID: string }) =>
            finishPaypalDeposit(
              orderID,
              accountId,
              setShowSuccessMessage,
              setIsLoading,
              dispatch,
              setErrorMessage,
              pawsErrorMessages,
              fromCreation,
              paymentType,
              errorMessageInitialState,
              refetchAvailableMethods
            )
        });
        setPaypalIntegration(true);
        if (button.isEligible()) {
          // For some moments the paypal button is not yet rendered, this is a nail to wait until modal animation finishes so it finds paypal button
          setTimeout(() => {
            button.render("#paypal-button-container");
          }, 10);
        }
      });
    }
  });

  return isPaypalActive;
};
