import React from "react";
import { IconPathProps } from "../../types";

export const DoubleChevronDown = ({
  lineColor,
  strokeWidth
}: IconPathProps) => (
  <path
    d="M19.5 12L12 19.5L4.5 12M19.5 4.5L12 12L4.5 4.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
