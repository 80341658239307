import { get } from "lodash";
import {
  PromoMappedSteps,
  PromoStepType,
  PromoStepWagerStatus
} from "../types/promoSteps";

export const checkOnboardingPromoAvailable = (
  steps: PromoMappedSteps
): boolean => {
  const wagerStep = get(steps, PromoStepType.WAGER);
  const payoutStep = get(steps, `${PromoStepType.PAYOUT}.timestamp`);
  const wagerStatus = get(steps, `${PromoStepType.WAGER}.wagerStatus`);
  const depositDate = get(steps, `${PromoStepType.DEPOSIT}.depositDate`);

  return wagerStep
    ? !payoutStep && wagerStatus !== PromoStepWagerStatus.WIN
    : !payoutStep && !depositDate;
};
