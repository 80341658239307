import { get, omit } from "lodash";
import { StoryblokPlugin } from "../types/storyblok";
import { StoryblokComponentProps } from "../types/promoOnboardingComponents";

export const formatStoryblokProps = (componentProps: StoryblokComponentProps) =>
  Object.entries(omit(componentProps, ["component"])).reduce(
    (propsFormatted, [key, value]) => {
      if (
        typeof value === "object" &&
        get(value, "plugin") === StoryblokPlugin.TEXT
      ) {
        return {
          ...propsFormatted,
          [key]: get(value, "value", "")
        };
      }

      return {
        ...propsFormatted,
        [key]: value
      };
    },
    {}
  );
