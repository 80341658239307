import React, { forwardRef, useContext } from "react";
import { View } from "react-native";
import { useQaLabel } from "../../../../hooks/useQaLabel";
import { ProgressBarStepProps } from "../../types";
import { PBContext } from "../../context";
import {
  StyledStepBarContainer,
  StyledStepBar,
  StyledStepBarDescription
} from "./styled-components";

export const ProgressBarStep = forwardRef<View, ProgressBarStepProps>(
  (
    {
      qaLabel = "progress-bar-step",
      stepNumber = 1,
      stepDescription = null,
      isDarkMode = false,
      ...rest
    },
    ref
  ) => {
    const viewTestProps = useQaLabel(qaLabel);
    const { completedSteps } = useContext(PBContext);
    return (
      <StyledStepBarContainer ref={ref} {...rest} {...viewTestProps}>
        <StyledStepBar
          variant={isDarkMode ? "dark" : "light"}
          isCompleted={stepNumber <= completedSteps}
        />
        {stepDescription ? (
          <StyledStepBarDescription
            variant={isDarkMode ? "dark" : "light"}
            mt="space-1"
          >
            {stepDescription}
          </StyledStepBarDescription>
        ) : null}
      </StyledStepBarContainer>
    );
  }
);

export default ProgressBarStep;
