import { get } from "lodash";
import {
  StoryblokMessages,
  StoryblokMessagesFormatted
} from "../types/storyblok";
import {
  StoryblokComponentName,
  StoryblokComponentProps,
  StoryblokPlaceName
} from "../types/promoOnboardingComponents";
import { formatStoryblokProps } from "./formatStoryblokProps";

export const formatStoryblokMessages = (
  messages: Array<StoryblokMessages>
): StoryblokMessagesFormatted =>
  messages.reduce((mappingRender, message) => {
    const componentProps: StoryblokComponentProps = get(
      message,
      "promo_component[0]"
    );
    const componentName: StoryblokComponentName = get(
      componentProps,
      "component",
      StoryblokComponentName.UNKNOWN
    );
    const places: Array<StoryblokPlaceName> = get(
      message,
      "render_place[0].places",
      []
    );
    const props = formatStoryblokProps(componentProps);

    const placeToRender = places.reduce(
      (mappingPlaces, place) => ({
        ...mappingPlaces,
        [place]: {
          component: componentName,
          props
        }
      }),
      {}
    );
    return {
      ...mappingRender,
      ...placeToRender
    };
  }, {} as StoryblokMessagesFormatted);

export default formatStoryblokMessages;
