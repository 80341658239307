import React, { createContext, useEffect, useState } from "react";
import { LayoutChangeEvent } from "react-native";

import { getAccessibilityRole } from "../../utils/accessibility";
import { useQaLabel } from "../../hooks/useQaLabel";
import {
  SegmentedControlProps,
  SCContextProps,
  SCTabSelectedProps
} from "./types";
import { StyledComponent, SegmentsContainer } from "./styled-components";
import { SegmentedControlPill } from "./segmentedControlPill";

const initialState: SCContextProps = {
  selectedValue: "",
  onSelect: () => {},
  isDisabled: false
};

export const SCContext = createContext<SCContextProps>(initialState);
SCContext.displayName = "SCContext";

export const SegmentedControl = ({
  style,
  onChange,
  selectedValue,
  size,
  isDisabled = false,
  children,
  qaLabel,
  ...rest
}: SegmentedControlProps): JSX.Element => {
  const [segmentWidth, setSegmentWidth] = useState(0);
  const [tabsNbr, setTabsNbr] = useState(
    React.Children.toArray(children).length
  );
  const [selectedTab, setSelectedTab] = useState<SCTabSelectedProps>({
    index: 0,
    children: ""
  });

  useEffect(() => {
    let selectedTabChildren;
    let selectedTabEnd;
    const childrenElements = React.Children.toArray(children);
    const selectedTabIndex = childrenElements.findIndex((elem) => {
      if (
        React.isValidElement<{
          value: string;
          children: string | JSX.Element;
          end?: JSX.Element;
        }>(elem) &&
        elem.props.value === selectedValue
      ) {
        selectedTabChildren = elem.props.children;
        selectedTabEnd = elem.props.end;
        return true;
      }
      return false;
    });

    if (selectedTabIndex !== -1) {
      setSelectedTab({
        index: selectedTabIndex,
        children: selectedTabChildren || "",
        end: selectedTabEnd
      });
    }

    if (childrenElements.length !== tabsNbr) {
      setTabsNbr(childrenElements.length);
    }
  }, [children, selectedValue]);

  const handleChange = (value: string | number) => {
    // mocks iOS's nativeEvent
    const event = {
      nativeEvent: {
        value
      }
    };
    // @ts-ignore
    if (typeof onChange === "function") onChange(event);
  };

  const handleLayoutChange = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    const newSegmentWidth = tabsNbr ? width / tabsNbr : 0;
    if (newSegmentWidth !== segmentWidth) {
      setSegmentWidth(newSegmentWidth);
    }
  };

  return (
    <StyledComponent
      {...useQaLabel(qaLabel)}
      accessibilityRole={getAccessibilityRole("tablist")}
      style={style}
      size={size}
      isDisabled={isDisabled}
      onLayout={handleLayoutChange}
      {...rest}
    >
      <SegmentsContainer>
        <SCContext.Provider
          value={{ selectedValue, onSelect: handleChange, isDisabled }}
        >
          {children}
        </SCContext.Provider>
      </SegmentsContainer>
      {segmentWidth ? (
        <SegmentedControlPill
          segmentWidth={segmentWidth}
          selectedIndex={selectedTab.index}
          end={selectedTab.end}
          qaLabel={`${qaLabel}-pill`}
        >
          {selectedTab.children}
        </SegmentedControlPill>
      ) : null}
    </StyledComponent>
  );
};

export { SegmentedControlProps };
export default SegmentedControl;
