import styled, { css } from "styled-components/native";
import { margin } from "styled-system";
import { View } from "react-native";
import { MtpCellProps } from "./types";

export const Container = styled(View)<
  Pick<MtpCellProps, "withBackground" | "align"> & {
    bgColor: string;
    lineColor: string;
  }
>`
  background-color: ${({ withBackground, bgColor }) =>
    withBackground ? bgColor : "transparent"};
  border-style: solid;
  border-top-left-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 60px;
  max-width: 102px;
  width: max-content;
  padding: 10px 11px 10px 12px;
  ${({ align, lineColor, withBackground }) =>
    align === "left"
      ? css`
          border-right-color: ${lineColor};
          border-right-width: ${withBackground ? "1px" : 0};
        `
      : css`
          border-left-color: ${lineColor};
          border-left-width: ${withBackground ? "1px" : 0};
        `};
  ${margin}
`;
