// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  overflow-x: hidden;
  width: 100%;
  background-color: ${color("white", "100")};
  padding-left: 12px;
  padding-top: 10px;
`;

const Row = styled.div`
  width: 100%;
  height: 49px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
`;

const Divider = styled.hr`
  border: 1px solid ${color("grey", "200")};
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: auto;
  margin-inline-end: auto;
  border-top-style: none;
  border-top-width: 0;
`;

export default class FundingControlsMask extends PureComponent<*> {
  render() {
    return (
      <Container>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="116"
          height="12"
          viewBox="0 0 116 12"
        >
          <g fill="none" fillRule="evenodd">
            <rect width="116" height="12" className="pulse" />
          </g>
        </SVG>
        <Row>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="12"
            viewBox="0 0 56 12"
          >
            <g fill="none" fillRule="evenodd">
              <rect width="56" height="12" className="pulse" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="73"
            height="31"
            viewBox="0 0 73 31"
          >
            <g fill="none" fillRule="evenodd">
              <rect
                stroke={color("grey", "300")}
                width="73"
                height="31"
                rx="4"
              />
            </g>
          </SVG>
        </Row>
        <Divider />
        <Row>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="116"
            height="12"
            viewBox="0 0 116 12"
          >
            <g fill="none" fillRule="evenodd">
              <rect width="116" height="12" className="pulse" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="73"
            height="31"
            viewBox="0 0 73 31"
          >
            <g fill="none" fillRule="evenodd">
              <rect
                stroke={color("grey", "300")}
                width="73"
                height="31"
                rx="4"
              />
            </g>
          </SVG>
        </Row>
        <Divider />
        <Row>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="86"
            height="12"
            viewBox="0 0 86 12"
          >
            <g fill="none" fillRule="evenodd">
              <rect width="86" height="12" className="pulse" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="73"
            height="31"
            viewBox="0 0 73 31"
          >
            <g fill="none" fillRule="evenodd">
              <rect
                stroke={color("grey", "300")}
                width="73"
                height="31"
                rx="4"
              />
            </g>
          </SVG>
        </Row>
        <Divider />
        <Row>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="12"
            viewBox="0 0 46 12"
          >
            <g fill="none" fillRule="evenodd">
              <rect width="46" height="12" className="pulse" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="73"
            height="31"
            viewBox="0 0 73 31"
          >
            <g fill="none" fillRule="evenodd">
              <rect
                stroke={color("grey", "300")}
                width="73"
                height="31"
                rx="4"
              />
            </g>
          </SVG>
        </Row>
        <Divider />
      </Container>
    );
  }
}
