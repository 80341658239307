import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontNormal, fontMedium } from "@tvg/atomic-ui/_static/Typography";

export const Container = styled.div<{ showBorderBottom?: boolean }>`
  padding-top: 2px;
  border-top: 1px solid ${buildColor("blue", "100")};
  ${({ showBorderBottom }) =>
    showBorderBottom &&
    css`
      padding-bottom: 18px;
      border-bottom: 1px solid ${buildColor("blue", "100")};
    `}
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
`;

export const Value = styled.div<{ bold?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  line-height: 16px;
  color: ${({ bold }) =>
    bold ? buildColor("grey", "900") : buildColor("grey", "800")};
  font-family: ${({ bold }) => (bold ? fontMedium : fontNormal)};
  font-size: 14px;

  svg {
    margin-left: 4px;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;

  svg {
    display: block;
  }
`;
