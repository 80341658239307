import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Bank = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <path
      d="M2.625 21.375H21.375"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M6 12.3939V21.3676"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M11.9972 12.4056V21.3793"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M18 12.3939V21.3676"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M2.62768 8.62402L2.625 7.41016L11.998 2.625L21.375 7.41016L21.3711 8.625L2.62768 8.62402Z"
      fill={backgroundColor}
      fillOpacity="1"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
