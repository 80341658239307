export const RACE_CELL = "race-cell";
export const CONTAINER = "container";
export const BASE = "base";
export const SUMMARY = "summary";
export const DETAILS = "details";
export const TRACK_DETAIL = "track-detail";
export const ICON = "icon";
export const GROUP = "group";
export const TEXT = "text";
export const TRACK = "track-name";
export const RACE = "race-number";
export const FLAG = "flag";
