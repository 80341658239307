import type { UnaryFn } from "@tvg/ts-types/Functional";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import { ErrorMessage } from "../../../../types";
import { doLogout } from "../../common/utils";

export const handleCloseError =
  (
    setErrorMessage: UnaryFn<React.SetStateAction<ErrorMessage>, void>,
    errorMessage?: ErrorMessage
  ) =>
  () => {
    if (errorMessage && +errorMessage.errorCode === 112703) {
      doLogout();
      window.location.href = "/";
    } else {
      setErrorMessage((prevState) => ({
        ...prevState,
        isOpen: false,
        isRetry: false
      }));
    }
  };

export const handleRetry =
  (
    setErrorMessage: UnaryFn<React.SetStateAction<ErrorMessage>, void>,
    errorMessage: ErrorMessage
  ) =>
  () => {
    if (errorMessage.retryCount <= 2) {
      setErrorMessage((prevState) => ({
        ...prevState,
        retryCount: prevState.retryCount + 1,
        isOpen: false,
        isRetry: true
      }));
    } else {
      openLiveChat();
    }
  };
