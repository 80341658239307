import React from "react";
import { IconPathProps } from "../../types";

export const ArrowCollapseRight = ({
  lineColor,
  strokeWidth
}: IconPathProps) => (
  <path
    d="M21.375 22.5L21.3692 12.0024L21.375 1.5M10.1097 19.5L17.6097 12L10.1097
    4.5M17.5335 12L9.17939e-07 12"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
