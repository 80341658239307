import styled from "styled-components";
import { compose, space } from "styled-system";

const styledComponentUtils = compose(space);

export const Column = styled.div<{ addMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  ${styledComponentUtils};
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 18px;
`;
