import React from "react";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { buildColor, Icon, IconNames } from "@tvg/design-system";
import { Error, IconContainer, Label } from "./styled-components";

export const renderLabel = (
  showLabelOnTop: boolean | undefined,
  label: string,
  className: string | undefined
) => (
  <Label showAboveInput={showLabelOnTop} data-qa-label={`${className}-label`}>
    {label}
  </Label>
);

export const renderIcon = (
  hasFocus: boolean,
  onClickIcon: NullaryFn<void> | undefined,
  className: string | undefined,
  iconName: IconNames
) => (
  <IconContainer
    {...(hasFocus && onClickIcon && { onClick: onClickIcon })}
    data-qa-label={`${className}-icon`}
  >
    <Icon
      name={iconName}
      size="xl"
      lineColor={buildColor("blue_accent", "500")}
    />
  </IconContainer>
);

export const renderError = (error: string, className: string | undefined) => (
  <Error data-qa-label={className && `${className}-error`}>
    <Icon name="warning" size="s" lineColor={buildColor("orange", "600")} />{" "}
    {error}
  </Error>
);
