import { get } from "lodash";
import type { Config } from "./types";

export const attempt = (fn: () => unknown): unknown | Error => {
  let result;
  try {
    result = fn();
  } catch (e) {
    result = e;
  }
  return result;
};

export const isBrowser = (): boolean => typeof window !== "undefined";

export const isRN = (): boolean =>
  typeof navigator !== "undefined" && navigator.product === "ReactNative";

export const isDev = (): boolean =>
  (isBrowser() &&
    typeof get(window, "location.hostname.includes") === "function" &&
    window.location.hostname.includes("local")) ||
  (typeof window === "undefined" &&
    typeof process.env.ENVIRONMENT === "string" &&
    process.env.ENVIRONMENT === "dev");

export const getServiceHost = (envConfig: Config) =>
  isBrowser()
    ? `//${envConfig.service_host}`
    : `http://${envConfig.service_host}`;
