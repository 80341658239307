import React from "react";
import InputV2 from "@tvg/atomic-ui/_molecule/InputV2";
import { Props } from "./types";
import { handleBlur } from "../handlers";
import { ActiveInput } from "../types";

const FormInput = ({
  name,
  schema,
  formRef,
  setActiveInput,
  ...props
}: Props) => (
  <InputV2
    name={name}
    customHandleOnBlur={handleBlur(name, schema, formRef, setActiveInput)}
    onFocus={() => setActiveInput(name as ActiveInput)}
    clearErrorOnFocus
    {...props}
  />
);

export default FormInput;
