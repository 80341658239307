import React from "react";
import { CallToActionProps } from "./types";
import { CallToActionSpan, CallToActionClickable } from "./styled-components";

const CallToAction = ({ text, qaLabel, setTermsOpen }: CallToActionProps) => (
  <>
    <CallToActionSpan>{text.bottomText}</CallToActionSpan>
    <CallToActionClickable
      data-qa-label={qaLabel}
      role="button"
      onClick={() => {
        setTermsOpen(true);
      }}
    >
      {text.clickableText}
    </CallToActionClickable>
  </>
);

export default CallToAction;
