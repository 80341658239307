import React, { RefObject, useRef } from "react";

import { View } from "react-native";
import { PopperProps } from "./types";
import PopperProvider from "./context/popperProvider";
import { Content, Overlay, Trigger } from "./components";

const Popper = ({
  children,
  on,
  content,
  placement = "top-mid",
  isDisabled = false,
  layer = 1999,
  ...rest
}: PopperProps) => {
  const triggerRef: RefObject<View> = useRef(null);
  const contentRef: RefObject<View> = useRef(null);

  return (
    <PopperProvider>
      <Trigger ref={triggerRef} isDisabled={isDisabled} on={on}>
        {children}
      </Trigger>
      <Overlay
        triggerRef={triggerRef}
        contentRef={contentRef}
        on={on}
        zIndex={layer}
      >
        <Content ref={contentRef} on={on} placement={placement} {...rest}>
          {content}
        </Content>
      </Overlay>
    </PopperProvider>
  );
};

Popper.defaultProps = {
  placement: "top-mid"
};

export default Popper;
export { usePopper } from "./hooks";
export * from "./types";
