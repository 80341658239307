import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const TvgPicks = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M3.93367 19.4495L4.77323 16.6117C5.05601 15.6558 5.93399 15 6.93078
      15H14.0664C15.0646 15 15.9434 15.6576 16.225 16.6151L17.0593
      19.4518C17.3419 20.4125 16.6217 21.375 15.6203 21.375H5.37204C4.36963
      21.375 3.64929 20.4107 3.93367 19.4495Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M13.1297 9C13.1297 10.4523 11.9523 11.6297 10.5 11.6297C9.04768 11.6297
      7.87034 10.4523 7.87034 9C7.87034 7.54768 9.04768 6.37034 10.5
      6.37034C11.9523 6.37034 13.1297 7.54768 13.1297 9Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M12.0004 21.3747H5.37252C4.37011 21.3747 3.64977 20.4104 3.93415
      19.4492L4.77371 16.6114C5.05649 15.6556 5.93447 14.9997 6.93126
      14.9997H12.0005M14.2512 17.9997L17.2512 20.9997L23.2512 14.9997M22.8764
      11.9997V4.87473C22.8764 3.63209 21.8691 2.62473 20.6264
      2.62473H3.37644C2.1338 2.62473 1.12644 3.63209 1.12644
      4.87473V11.2497V19.1247C1.12644 20.3674 2.1338 21.3747 3.37644
      21.3747H10.5014M13.1297 9C13.1297 10.4523 11.9523 11.6297 10.5
      11.6297C9.04768 11.6297 7.87034 10.4523 7.87034 9C7.87034 7.54768
      9.04768 6.37034 10.5 6.37034C11.9523 6.37034 13.1297 7.54768 13.1297 9Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);

export default TvgPicks;
