module.exports = {
  domain: {
    desktop: "ia-staging.tvg.com",
    mobile: "ia-staging.tvg.com",
    talentPicks: "ia-staging.tvg.com/talent-picks/",
    equibase: "equibase-store.tvg.com"
  },
  recaptchaID: "6LeIYBcqAAAAAE2wDhSub9Ic8oeidAiiUt4gYM4a",
  recaptchaIdNative: "6LfRQloqAAAAABY9v2RydlB10Lfgb5ODmvygHy3G"
};
