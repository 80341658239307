import styled from "styled-components";
import { fontMedium } from "@tvg/atomic-ui/_static/Typography";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const PaymentMethods = styled.div`
  padding-top: 16px;
  background-color: ${
    buildColor(
      "white",
      "100"
    ) /* TODO: DS equivalent buildColor("white", "900") */
  };
  box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")},
    inset 0 1px 0 ${buildColor("blue", "100")};
`;

export const PaymentsList = styled.div`
  margin-top: 5px;
`;

export const Title = styled.h2`
  margin: 0 12px;
  line-height: 19px;
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-weight: 500;
  font-size: 16px;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
