import styled, { css } from "styled-components/native";
import { View } from "react-native";
import { space, color, compose } from "styled-system";
import { FilterCellsProps } from "./types";

const stylesFn = compose(space, color);

const containerSizes = {
  s: css`
    height: 25px;
  `,
  m: css`
    height: 32px;
  `,
  l: css`
    height: 44px;
  `
} as const;

const containerWidth = {
  stretch: css`
    width: 100%;
  `,
  maxContent: css`
    width: max-content;
  `
} as const;

export const FilterCellContainer = styled(View)<
  Pick<FilterCellsProps, "size" | "hasShadow" | "isStretch" | "hasWidth">
>`
  align-items: center;
  border-radius: 2px;
  ${({ hasShadow }) =>
    hasShadow &&
    css`
      box-shadow: ${({ theme }) => theme.elevations.low};
    `}
  border: 1px solid ${({ theme }) => theme.colorTokens.border.default};
  flex-direction: row;
  ${({ hasWidth, isStretch }) => {
    if (hasWidth && isStretch) return containerWidth.stretch;
    return hasWidth && !isStretch && containerWidth.maxContent;
  }}
  ${({ size }) => containerSizes[size as FilterCellsProps["size"]]};
  ${stylesFn}
`;
