// @flow
import { attempt, get, isError, replace, toPairs, escapeRegExp } from "lodash";

export const TOKEN_DELIMITER = "(?:~|#)";

export type CAPIVariable = {
  [key: string]: string | number
};

export const replaceCAPIVariables = (
  message: string,
  variables: CAPIVariable
) => {
  let modifiedMessage = message;
  toPairs(variables).forEach(([key, value]) => {
    // eslint-disable-next-line security/detect-non-literal-regexp
    modifiedMessage = replace(
      modifiedMessage,
      new RegExp(TOKEN_DELIMITER + escapeRegExp(key) + TOKEN_DELIMITER, "g"),
      value || value === 0 ? value : ""
    );
  });

  return modifiedMessage;
};

/*
 * Use this method to parse from CAPI a stringified JSON
 */
export const parseCAPIMessage = (
  store: { capi: any },
  capi: string,
  defaultValue: {} | any[] = {}
) => {
  const messagesJSON = get(store, `${capi}`, "");
  const messages = attempt(JSON.parse, [messagesJSON]);
  return isError(messages) ? defaultValue : messages;
};

export default parseCAPIMessage;
