import React from "react";
import { Path } from "react-native-svg";

import { IconPathProps } from "../types";

const DoubleChevronDown = ({ lineColor, strokeWidth }: IconPathProps) => (
  <Path
    d="M19.5 12L12 19.5L4.5 12M19.5 4.5L12 12L4.5 4.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);

export default DoubleChevronDown;
