import { Platform, View, Pressable } from "react-native";
import styled, { css } from "styled-components/native";

import { compose, space, color, border } from "../../theming/styledSystem";
import { Header } from "../typography";

const styledComponentUtils = compose(space, color, border);
export const StyledComponent = styled(Pressable).attrs<{
  isDisabled?: boolean;
  isHovered: boolean;
  isPressed: boolean;
}>(({ isDisabled, isHovered, isPressed }) => ({
  ...(Platform.OS === "web"
    ? {
        disabled: isDisabled
      }
    : {}),
  ...(isDisabled || isPressed || isHovered
    ? {
        backgroundColor:
          (isDisabled && "blue.000") ||
          (isPressed && "blue_accent.200") ||
          (isHovered && "blue_accent.100")
      }
    : {})
}))<{
  isDisabled: boolean;
  hasDividerLine: boolean;
}>`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  ${({ theme }) => css`
    padding: ${theme.space["space-5"]} ${theme.space["space-4"]};
    background-color: ${theme.colors.white["900"]};
  `};
  ${styledComponentUtils};
  ${({ isDisabled, hasDividerLine }) =>
    (isDisabled &&
      (Platform.OS === "web"
        ? css`
            opacity: 0.5;
            cursor: default;
          `
        : css`
            opacity: 0.5;
          `)) || // visual fix for focus when using tab navigation
    (hasDividerLine &&
      Platform.OS === "web" &&
      css`
        outline-offset: -2px;
      `)};
`;

export const ContentWrapper = styled(View)`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const StyledContent = styled(View)`
  flex: 1;
  justify-content: space-between;
`;

export const StyledHeader = styled(Header).attrs({ tag: "h3" })`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.s};
    line-height: ${theme.lineHeights.s};
  `}
`;

export const StyledDivider = styled(View)`
  ${({ theme }) => css`
    position: absolute;
    left: ${theme.space["space-4"]};
    right: 0;
    bottom: 0;
    height: 1px;
    background: ${theme.colors.blue[100]};
  `}
`;

export const IconContainer = styled(View)<{ iconSize?: string }>`
  height: ${({ iconSize }) => (iconSize && iconSize !== "s" ? "50%" : "100%")};
  justify-content: ${({ iconSize }) =>
    iconSize && iconSize !== "s" ? "center" : "flex-start"};
`;
