const CHAR_WIDTH_PX = 7;
const BADGE_PADDING_X = 12;
const BADGE_WIDTH_DEFAULT = 16;

export const calculateBadgeWidth = (counter: number, counterLimit?: number) => {
  const valueOfNotifications =
    counterLimit && counter > counterLimit ? `${counterLimit}+` : counter;
  const counterLength = String(valueOfNotifications).length;
  const width = CHAR_WIDTH_PX * counterLength;

  return counterLength === 1 ? BADGE_WIDTH_DEFAULT : width + BADGE_PADDING_X;
};
