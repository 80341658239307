import { RefObject } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { FormHandles } from "@unform/core";
import { object } from "yup";
import { RequiredStringSchema } from "yup/lib/string";
import { isEmpty, get } from "lodash";
import achService from "@tvg/api/ach";
import {
  setIsWireTransferFundsModalOpen,
  closeWithdrawFundsModal
} from "@tvg/sh-lib-paws/redux/slices/withdrawFundsModalSlice";

import { WireTransferWithdrawContent } from "../types";
import { UnaryFn } from "../../../../types";
import {
  routingNumberSchema,
  checkingAccountsNumberSchema,
  nameOnAccountSchema
} from "./wireTransferSchemaValidations";

const getWireTransferSchema = (content: WireTransferWithdrawContent) =>
  object().shape({
    routingNumber: routingNumberSchema(content.form.routingNumber.validations),
    checkingAccountNumber: checkingAccountsNumberSchema(
      content.form.checkingAccountNumber.validations
    ),
    nameOnAccount: nameOnAccountSchema(content.form.nameOnAccount.validations)
  });

export const handleWireTransferFormValidation = async (
  formRef: RefObject<FormHandles> | null,
  content: WireTransferWithdrawContent,
  setFormFilled: UnaryFn<boolean, void>
) => {
  if (
    typeof setFormFilled === "function" &&
    content &&
    !isEmpty(content) &&
    formRef &&
    formRef.current
  ) {
    try {
      const schema = getWireTransferSchema(content);

      await schema?.validate(formRef.current.getData(), {
        abortEarly: false
      });

      setFormFilled(true);
    } catch (err) {
      setFormFilled(false);
    }
  }
};

export const handleWireTransferFieldValidation = async (
  fieldName: string,
  value: string,
  schema: RequiredStringSchema<string | undefined, Record<string, unknown>>,
  formRef: RefObject<FormHandles> | null
) => {
  if (formRef && formRef.current) {
    try {
      await schema.validate(value, {
        abortEarly: true
      });
      formRef.current.setFieldError(fieldName, "");
    } catch (err) {
      formRef.current.setFieldError(
        fieldName,
        get(err, "errors[0]", "Please fill this field")
      );
    }
  }
};

export const validateAndSubmitWireTransferForm = async (
  content: WireTransferWithdrawContent,
  formRef: RefObject<FormHandles> | null,
  setIsLoading: UnaryFn<boolean, void>,
  dispatch: Dispatch
) => {
  setIsLoading(true);
  try {
    const schema = getWireTransferSchema(content);

    if (formRef && formRef.current) {
      const formData = formRef.current.getData();

      await schema?.validate(formData, {
        abortEarly: false
      });

      // Remove all previous errors
      formRef.current.setErrors({});

      dispatch(setIsWireTransferFundsModalOpen(false));
      setIsLoading(false);
    }
  } catch (err) {
    setIsLoading(false);
  }
};

export const handleWireTransferFieldBlur =
  (
    name: string,
    schema: RequiredStringSchema<string | undefined, Record<string, unknown>>,
    formRef: RefObject<FormHandles> | null
  ) =>
  (value: string) => {
    handleWireTransferFieldValidation(name, value, schema, formRef);
  };

export const handleClickOutsideWireTransferForm = (event: MouseEvent) =>
  event.preventDefault();

export const handleOnCloseWireTransferFormModal = (dispatch: Dispatch) => {
  batch(() => {
    dispatch(setIsWireTransferFundsModalOpen(false));
    dispatch(closeWithdrawFundsModal());
  });
};

export const validateWireRoutingNumber = async (
  routingNumber: string,
  formRef: RefObject<FormHandles> | null,
  content: WireTransferWithdrawContent,
  setIsRoutingNumberValid: UnaryFn<boolean, void>
) => {
  if (formRef && formRef.current) {
    try {
      if (routingNumber.length !== 9) {
        throw new Error("Routing number must have 9 digits");
      }

      // Service returns a bank name for a given routing number
      // We are using this service here only to check if the routing number is valid
      const { status } = await achService.getBank(routingNumber);
      if (status === 200) {
        formRef.current.setFieldError("routingNumber", "");
        setIsRoutingNumberValid(true);
      }
    } catch {
      formRef.current.setFieldError(
        "routingNumber",
        content.form.routingNumber.validations.invalidNumber
      );
      setIsRoutingNumberValid(false);
    }
  }

  return false;
};
