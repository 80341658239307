// @flow
import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.div`
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}
  display: flex;
  flex-direction: column;
`;

// the "transform" below is a small hack to solve a weird stacking context problem on ipad
export const MethodListContainer = styled.div`
  position: ${({ isDesktop }) => (isDesktop ? "absolute" : "relative")};
  height: 0;
  z-index: 101;
  transform: rotate(0);
`;

export const MethodListWrapper = styled.div`
  position: absolute;
  ${({ isDesktop }) =>
    isDesktop
      ? css`
          top: 60px;
          width: 414px;
          left: -30px;
        `
      : css`
          top: 5px;
          left: -12px;
          right: -12px;
          overflow: hidden;
        `};
  background-color: ${buildColor("white", "100")};
  border-radius: 4px;
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);
`;
