// @flow
import React from "react";
import type { NullaryFn } from "@tvg/types/Functional";
import type { Device } from "@tvg/types/Device";
import { noop } from "lodash/fp";
import {
  shouldCapitalizeTitle,
  getPawsOnlyIcons,
  tagMapper
} from "@tvg/wallet/src/utils";
import type {
  PaymentType,
  MultiSourceIcons,
  PaymentTypeTag
} from "@tvg/wallet/src/types";
import { Tag } from "@tvg/design-system";

import {
  Container,
  TextContainer,
  Title,
  Description,
  IconContainer,
  HorizontalRule,
  CheckIconContainer,
  TagsWrapper
} from "./styled-components";

import {
  MasterCardIcon,
  VisaIcon,
  CheckIcon,
  PaypalIcon,
  TickCircle,
  TickEmptyCircle
} from "../../_static/Icons/paws";

export type Variant = "mastercard" | "visa" | "check" | "paypal";

type Props = {
  /**
   * Pre-defined button variants
   */
  variant?: Variant,
  /**
   * Left icon name
   */
  iconName?: MultiSourceIcons,
  /**
   * onClick action
   */
  onClick?: NullaryFn<void>,
  /**
   * Main button text
   */
  title?: string,
  /**
   * Extra text below button title
   */
  description?: string,
  /**
   * Changes component styled to selected
   */
  isSelected?: boolean,
  /**
   * Type of device
   */
  device?: Device,
  /**
   * Different payment types enums
   */
  paymentType?: PaymentType,
  className?: string,
  qaLabel?: string,
  tags?: PaymentTypeTag[],
  pawsIsNewTag?: boolean,
  isUnderMaintenance?: boolean,
  underMaintenanceMessage?: string
};

const handleVariants = (variant) => {
  switch (variant) {
    case "paypal":
      return {
        icon: <PaypalIcon />,
        title: "Paypal"
      };
    case "visa":
      return {
        icon: <VisaIcon />,
        title: "Visa"
      };
    case "mastercard":
      return {
        icon: <MasterCardIcon />,
        title: "MasterCard"
      };
    default:
    case "check":
      return {
        icon: <CheckIcon />,
        title: "Check"
      };
  }
};

const PaymentSelector = ({
  iconName = "card",
  title,
  description,
  variant = "mastercard",
  isSelected = false,
  onClick = noop,
  device = "mobile",
  paymentType,
  className,
  qaLabel = "payment-selector",
  pawsIsNewTag,
  tags = [],
  isUnderMaintenance = false,
  underMaintenanceMessage
}: Props) => {
  const shouldCapitalize = shouldCapitalizeTitle(paymentType);
  const getTitle = () => title || handleVariants(variant).title;
  const isDisabled = isUnderMaintenance;
  return (
    <Container
      data-qa-label={qaLabel}
      isSelected={isSelected}
      onClick={onClick}
      device={device}
      className={className}
      disabled={isDisabled}
    >
      {iconName && getPawsOnlyIcons(iconName) ? (
        <IconContainer>{getPawsOnlyIcons(iconName)}</IconContainer>
      ) : (
        <IconContainer>{handleVariants(variant).icon}</IconContainer>
      )}
      <TextContainer>
        <Title shouldCapitalize={shouldCapitalize} disabled={isDisabled}>
          {shouldCapitalize ? getTitle().toLowerCase() : getTitle()}
        </Title>
        <TagsWrapper>
          {!isDisabled &&
            tags
              .filter(
                (tag) =>
                  tag.type !== "new" || (tag.type === "new" && pawsIsNewTag)
              )
              .map((tag, i) => (
                <Tag
                  key={`payment-selector-tag-${tag.type}-${tag.text}-${i + 1}`}
                  qaLabel={`payment-selector-tag-${tag.type}`}
                  variant={tagMapper[tag.color] || "info"}
                  label={tag.text}
                  size="s"
                />
              ))}
        </TagsWrapper>
        {!!description && (
          <Description disabled={isDisabled}>
            {isDisabled ? underMaintenanceMessage : description}
          </Description>
        )}
      </TextContainer>
      {!isDisabled && (
        <CheckIconContainer>
          {isSelected ? <TickCircle /> : <TickEmptyCircle />}
        </CheckIconContainer>
      )}
      <HorizontalRule />
    </Container>
  );
};

export default PaymentSelector;
