import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const CardPlus = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M11.625 5.625H1.125V10.1693L11.625 10.1693V5.625Z"
      fill={backgroundColor}
      fillOpacity="1"
    />
    <Path
      d="M1.125 10.1708V18.3765H22.875V12.0015M1.125
      10.1708V5.62647H11.625M1.125 10.1708H11.625M4.15997 14.2549H11.625M18
      4.5V9M18 9V13.5M18 9H22.5M18 9H13.5"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
export default CardPlus;
