module.exports = {
  domain: {
    desktop: "4njbets.us.betfair.com",
    mobile: "4njbets.us.betfair.com",
    talentPicks: "4njbets.us.betfair.com/talent-picks/",
    equibase: "equibase-store.us.betfair.com"
  },
  service_host: "service.us.betfair.com",
  recaptchaID: "6LdrNBgqAAAAAKKs_ZP23RQvTYPkSFdZQ9KK_j6K",
  recaptchaIdNative: "6LfSQloqAAAAAFKuurdHLnObQ-r2CGo0TuuYdNq-"
};
