import React from "react";
import { Path } from "react-native-svg";

import { IconPathWithBackgroundProps } from "../types";

const BetaProgram = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <>
    <Path
      d="M7.02039 21L20.37 21C20.9223 21 21.37 20.5523 21.37 20L21.37
      9.55244C21.37 8.7189 20.4102 8.25122 19.7537 8.76494L6.40408
      19.2125C5.65545 19.7984 6.06975 21 7.02039 21Z"
      fill={backgroundColor}
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
    <Path
      d="M16.9796 3H3.62998C3.0777 3 2.62998 3.44772 2.62998 4V14.4476C2.62998
      15.2811 3.58988 15.7488 4.24629 15.2351L17.5959 4.78751C18.3446 4.20161
      17.9303 3 16.9796 3Z"
      fill={backgroundColor}
      stroke={lineColor}
      strokeWidth={strokeWidth}
    />
  </>
);
export default BetaProgram;
