import { engageApptentiveEvent, hideMinimizeSurveyButton } from "../utils";

// when a user wants to give feedback on the New Program Page beta feature
export const showNewProgramPageFeedbackSurvey = () =>
  engageApptentiveEvent("Beta Program Page Feedback");

export const showExitNewFeedbackBetaProgramSurvey = () =>
  engageApptentiveEvent("New Feature Exit Beta");

// when a user wants to give feedback for the whole app
// ("Send Feedback" buttons on the Account Panel)
export const showSendFeedbackSurvey = () => {
  engageApptentiveEvent("Send Feedback");
  hideMinimizeSurveyButton();
};
