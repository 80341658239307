import styled from "styled-components/native";
import { Platform, Pressable, Text, View } from "react-native";
import { margin, flex } from "styled-system";
import { CheckboxProps } from "./types";

const setDefaultCursor = Platform.OS === "web" && `cursor: default;`;

export const StyledPressableContainer = styled(Pressable)<{
  isHovered: boolean;
  isDisabled: boolean;
  isInvalid: boolean;
  isChecked: boolean;
  isPressed: boolean;
}>`
  height: 24px;
  width: 24px;
  background-color: ${({ theme }) => theme.colors.white[900]};
  border-radius: ${({ theme }) => theme.radii.s};
  border: 1px solid ${({ theme }) => theme.colors.blue[200]};
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.elevations.low};
  ${setDefaultCursor}
  ${({ isHovered, isDisabled, theme }) =>
    isHovered &&
    `
    border: 2px solid ${
      isDisabled ? theme.colors.blue[100] : theme.colors.blue_accent[500]
    };
    box-shadow: ${theme.elevations.medium};
  `}

  ${({ isInvalid, isDisabled, theme }) =>
    isInvalid &&
    `
    border: 2px solid ${
      isDisabled ? theme.colors.blue[100] : theme.colors.orange[600]
    };
  `}

  ${({ isChecked, theme }) =>
    isChecked &&
    `
    background-color: ${theme.colors.blue_accent[500]};
    border: 2px solid ${theme.colors.blue_accent[500]}; 
    box-shadow: none;
  `}

  ${({ isPressed, theme }) =>
    isPressed &&
    `
    background-color: ${theme.colors.blue_accent[500]};
    border: 2px solid ${theme.colors.blue_accent[500]};
    box-shadow: none;
  `}

  ${({ isDisabled, isChecked, theme }) =>
    isDisabled &&
    `
    background-color: ${theme.colors.blue["000"]};
    border: ${isChecked ? "2px" : "1px"} solid ${theme.colors.blue[100]}; 
    box-shadow: none;
    ${setDefaultCursor}    
  `}
`;

export const StyledPressableLabelContainer = styled(Pressable)`
  ${setDefaultCursor}
  flex-shrink: 1;
`;

export const StyledTextLabel = styled(Text)<{ isDisabled: boolean }>`
  flex-shrink: 1;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.grey[900]};
  margin-left: ${({ theme }) => theme.space["space-3"]};
  margin-right: ${({ theme }) => theme.space["space-6"]};
  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    color: ${theme.colors.grey[600]};
  `}
`;

export const StyledViewContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space["space-4"]};
  ${margin}
`;
export const Checkbox = styled(Text).attrs<CheckboxProps>((props) => ({
  alignLabel: props.flex === "start" ? "start" : "end"
}))<CheckboxProps>`
  ${flex}
`;

Checkbox.defaultProps = {
  alignLabel: "center"
};
