import React, { useCallback, useRef, useEffect } from "react";
import { Animated, Pressable } from "react-native";
import useQaLabel from "../../hooks/useQaLabel";
import { Toggle, Slider, TextArea } from "./styled-components";
import { SwitchProps } from "./types";

const AnimatedSlider = Animated.createAnimatedComponent(Slider);

export const Switch = ({
  isOn,
  onPress,
  qaLabel,
  isDisabled = false
}: SwitchProps) => {
  const testProps = useQaLabel(qaLabel);
  const leftAnimatedValue = useRef(new Animated.Value(isOn ? 1 : 0)).current;

  const turnOnOff = (on: boolean) =>
    Animated.timing(leftAnimatedValue, {
      toValue: on ? 0 : 1,
      duration: 200,
      useNativeDriver: false
    }).start();

  useEffect(() => {
    requestAnimationFrame(() => {
      turnOnOff(!isOn);
    });
  }, [isOn]);

  const onPressSwitch = useCallback(() => {
    onPress();
    requestAnimationFrame(() => {
      turnOnOff(!!isOn);
    });
  }, [isOn, onPress]);

  const leftValue = leftAnimatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [4, 30]
  });

  return (
    <Pressable
      accessibilityRole="switch"
      accessibilityState={{ checked: isOn, disabled: isDisabled }}
      onPress={onPressSwitch}
      disabled={isDisabled}
      // eslint-disable-next-line
      style={{ width: 52 }}
    >
      <Toggle {...testProps} on={isOn} isDisabled={isDisabled}>
        <TextArea on={isOn} isDisabled={isDisabled}>
          {isOn ? "ON" : "OFF"}
        </TextArea>
        <AnimatedSlider
          // eslint-disable-next-line
          style={{
            position: "absolute",
            left: leftValue
          }}
        />
      </Toggle>
    </Pressable>
  );
};
