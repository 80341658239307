// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("blue", "000")};
  }
  
  50% {
    fill: ${color("blue", "100")};
  }
  
  100% {
    fill: ${color("blue", "000")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 48px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export default class AccountTransactionsHeaderMask extends PureComponent<*> {
  render() {
    return (
      <div data-qa-label="accountTransactionsHeaderMask">
        <SVG>
          <g>
            <rect width="100%" height="48" fill={color("white", "100")} />
            <rect
              className="pulse"
              x="12"
              y="12"
              width="112"
              height="24"
              rx="2"
              fill={color("blue", "000")}
            />
            <rect
              className="pulse"
              x="321"
              y="16"
              width="42"
              height="16"
              rx="2"
              fill={color("blue", "000")}
            />
            <rect
              className="pulse"
              x="301"
              y="16"
              width="16"
              height="16"
              rx="2"
              fill={color("blue", "000")}
            />
          </g>
        </SVG>
      </div>
    );
  }
}
