import React from "react";

import * as IconPaths from "../../_static/icons/icon-paths";

import { IconContentProps, IconNames } from "./types";

const IconContent = ({
  iconName,
  lineColor,
  backgroundColor,
  strokeWidth
}: IconContentProps) =>
  React.createElement(IconPaths[iconName as IconNames], {
    lineColor,
    backgroundColor,
    strokeWidth
  });

export default React.memo(IconContent);
