import tvgConf from "@tvg/conf";
import requester from "../requester";
import getProtocol from "../protocolSetter";
import type { DepositData, WithdrawData } from "./types";
import { serviceResponse } from "../types";

const achService: string = "service.ach";
const uwtService: string = "service.uwt";

const getDepositLimits = (accountId: string): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${tvgConf().config(
      achService
    )}/users/${accountId}/deposits/limits`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const getDepositFee = (
  accountId: string,
  depositAmount: string
): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${tvgConf().config(
      achService
    )}/users/${accountId}/deposits/fee?depositAmount=${depositAmount}`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const postDeposit = (
  accountId: string,
  achId: string,
  depositData: DepositData
): Promise<serviceResponse> =>
  requester()({
    method: "post",
    url: `${getProtocol()}${tvgConf().config(
      achService
    )}/users/${accountId}/bankAccount/${achId}/deposits`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true,
    data: depositData
  });

const postCreateDeposit = (
  accountId: string,
  depositData: DepositData
): Promise<serviceResponse> =>
  requester()({
    method: "post",
    url: `${getProtocol()}${tvgConf().config(
      achService
    )}/users/${accountId}/deposits`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true,
    data: depositData
  });

const getWithdrawLimits = (accountId: string): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${tvgConf().config(
      uwtService
    )}/users/${accountId}/withdrawal/balance`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const postWithdraw = (
  accountId: string,
  depositData: WithdrawData
): Promise<serviceResponse> =>
  requester()({
    method: "post",
    url: `${getProtocol()}${tvgConf().config(
      achService
    )}/users/${accountId}/withdrawals`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true,
    data: depositData
  });

const getBank = (bankRoutingNumber: string): Promise<serviceResponse> =>
  requester()({
    method: "get",
    url: `${getProtocol()}${tvgConf().config(
      achService
    )}/bank/${bankRoutingNumber}`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true
  });

const ACH_SERVICES = {
  getDepositLimits,
  getDepositFee,
  postDeposit,
  getWithdrawLimits,
  postWithdraw,
  getBank,
  postCreateDeposit
};

export default ACH_SERVICES;
