import React from "react";
import { IconPathProps } from "../../types";

export const Featured = ({ lineColor, strokeWidth }: IconPathProps) => (
  <>
    <path
      d="M18.1216 2.62585H5.87842C5.74035 2.62585 5.62842 2.73778 5.62842
      2.87585V20.9922C5.62842 21.1729 5.81412 21.2939 5.97937 21.2209L11.899
      18.6079C11.9634 18.5795 12.0366 18.5795 12.101 18.6079L18.0206
      21.2209C18.1859 21.2939 18.3716 21.1729 18.3716 20.9922V2.87585C18.3716
      2.73778 18.2596 2.62585 18.1216 2.62585Z"
      stroke={lineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M11.7622 8.23173C11.8371 8.0014 12.1629 8.0014 12.2378 8.23173L12.9542
      10.4366C12.9877 10.5397 13.0836 10.6094 13.1919 10.6094H15.5103C15.7525
      10.6094 15.8532 10.9193 15.6573 11.0616L13.7817 12.4244C13.694 12.488
      13.6574 12.6009 13.6908 12.7039L14.4073 14.9088C14.4821 15.1391 14.2185
      15.3306 14.0226 15.1883L12.1469 13.8256C12.0593 13.7619 11.9407 13.7619
      11.8531 13.8256L9.97744 15.1883C9.78151 15.3306 9.51789 15.1391 9.59273
      14.9088L10.3092 12.7039C10.3426 12.6009 10.306 12.488 10.2183
      12.4244L8.34272 11.0616C8.14679 10.9193 8.24749 10.6094 8.48967
      10.6094H10.8081C10.9164 10.6094 11.0123 10.5397 11.0458 10.4366L11.7622
      8.23173Z"
      fill={lineColor}
    />
  </>
);
