import * as accountWallet from "./accountWallet";
import * as betActivities from "./betActivities";
import * as interactions from "./interactions";
import * as featureActivities from "./featureActivities";
import * as raceActivities from "./raceActivities";
import * as navigationActivities from "./navigationActivities";

export const events = {
  ...accountWallet,
  ...betActivities,
  ...interactions,
  ...featureActivities,
  ...raceActivities,
  ...navigationActivities
};
