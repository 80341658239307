// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";

import { titleFont } from "../Typography";

import buildColor from "../ColorPalette";

const SVGContainer = styled.div`
  position: relative;
  font-family: ${titleFont};
  background: ${buildColor("white", "100")};
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 393px;

  text {
    fill: #c2c2c2;
    font-size: 12px;
  }
`;

const SVGAbsolute = styled(SVG)`
  position: absolute;
  top: 0;
  right: 8px;
  display: block;
  width: 107px;
  height: auto;
`;

export default class ResultsMask extends PureComponent<*> {
  render() {
    return (
      <SVGContainer data-qa-label="resultsMask">
        <SVG>
          <g fill="none" fillRule="evenodd">
            <rect width="100%" height="1" y="248" fill="#F0F3F6" />
            <rect width="100%" height="1" y="239" fill="#F0F3F6" />
            <rect width="100%" height="1" y="199" fill="#F7F8FA" />
            <rect width="100%" height="1" y="159" fill="#F7F8FA" />
            <rect width="100%" height="1" y="96" fill="#F7F8FA" />
            <rect width="100%" height="1" y="296" fill="#F0F3F6" />
            <rect width="100%" height="1" y="344" fill="#F0F3F6" />
            <rect width="100%" height="1" y="392" fill="#F0F3F6" />
            <rect width="100%" height="1" y="31" fill="#F0F3F6" />
            <rect width="100%" height="31" fill="#F7F8FA" />
          </g>
          <g fill="#f3f3f3" transform="translate(8 11)">
            <rect width="72" height="8" y="249" />
            <rect width="24" height="8" y="265" />
            <rect width="72" height="8" y="297" />
            <rect width="24" height="8" y="313" />
            <rect width="72" height="8" y="345" />
            <rect width="24" height="8" y="361" />
            <rect width="72" height="8" y="393" />
            <rect width="24" height="8" y="409" />
            <text>
              <tspan x="0" y="212">
                4TH
              </tspan>
            </text>
            <rect width="16" height="16" x="29" y="201" />
            <rect width="88" height="16" x="53" y="201" />
            <text>
              <tspan x="0" y="172">
                3RD
              </tspan>
            </text>
            <rect width="16" height="16" x="29" y="161" />
            <rect width="96" height="16" x="53" y="161" />
            <text>
              <tspan x="0" y="108">
                2ND
              </tspan>
            </text>
            <rect width="16" height="16" x="29" y="97" />
            <rect width="56" height="16" x="53" y="97" />
            <text>
              <tspan x="0" y="45">
                1ST
              </tspan>
            </text>
            <rect width="16" height="16" x="29" y="34" />
            <rect width="140" height="16" x="53" y="34" />
            <text>
              <tspan x="34.34" y="9">
                #
              </tspan>
            </text>
            <text>
              <tspan x=".23" y="9">
                POS
              </tspan>
            </text>
            <text>
              <tspan x="52.69" y="9">
                RUNNER
              </tspan>
            </text>
          </g>
        </SVG>
        <SVGAbsolute>
          <g fill="#f3f3f3" fillRule="evenodd" transform="translate(0 11)">
            <rect width="40" height="8" x="80" y="257" />
            <rect width="40" height="8" x="80" y="305" />
            <rect width="40" height="8" x="80" y="353" />
            <rect width="40" height="8" x="80" y="401" />
            <rect width="51" height="8" x="68" y="133" />
            <rect width="51" height="8" y="70" />
            <rect width="51" height="8" x="69" y="70" />
            <text>
              <tspan x=".21" y="11">
                WIN
              </tspan>
            </text>
            <text>
              <tspan x="68.27" y="11">
                PLACE
              </tspan>
            </text>
          </g>
        </SVGAbsolute>
      </SVGContainer>
    );
  }
}
