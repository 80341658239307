import {
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  lineHeightsShorter,
  lineHeightsTaller,
  tvgColorPalette as colors,
  space,
  breakpoints,
  radii,
  buttons,
  iconButtons,
  elevations,
  iconSizes
} from "./common";

import { tvgColorTokens as colorTokens } from "./tokens";

import { Theme, HeadingSizes } from "../types";

const name = "Mobile/Tablet";

const headingSizes: HeadingSizes = {
  h1: { fontSize: fontSizes.l, lineHeight: lineHeights.l },
  h2: { fontSize: fontSizes.m, lineHeight: lineHeights.m },
  h3: { fontSize: fontSizes.m, lineHeight: lineHeights.m }
};

export default {
  name,
  breakpoints,
  colors,
  colorTokens,
  space,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  lineHeightsShorter,
  lineHeightsTaller,
  headingSizes,
  radii,
  buttons,
  iconButtons,
  elevations,
  iconSizes
} as Theme;
