// @flow
import { format, parseISO } from "date-fns";
import uwt from "@tvg/api/uwt";
import type { UwtWithdrawal } from "@tvg/api/uwt/types";
import type { Withdrawal } from "@tvg/atomic-ui/_templates/Withdrawals";

const formatPendingWithdrawals = (
  uwtWithdrawals: UwtWithdrawal[]
): Withdrawal[] =>
  uwtWithdrawals.map((uwtW) => ({
    id: uwtW.withdrawalId,
    date: format(parseISO(uwtW.transactionDate), "MM-dd-yyyy"),
    type: uwtW.paymentType,
    amount: uwtW.amount,
    onCancel: () => {}
  }));

const getWithdrawals = (accountNumber: string) =>
  uwt
    .getPendingWithdrawals(accountNumber)
    .then((response) =>
      formatPendingWithdrawals(response.data.pendingWithdrawalsList)
    );

const cancelWithdrawal = (accountNumber: string, withdrawalId: string) =>
  uwt.cancelWithdrawal(accountNumber, withdrawalId);

export default {
  getWithdrawals,
  cancelWithdrawal
};
