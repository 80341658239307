import { useSelector } from "react-redux";
import { Store } from "redux";
import {
  getPromosOnboardingToggle,
  getStoryblokCurrentStep,
  getStoryblokPromoByPlace,
  getStoryblokPromos
} from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { usePromosOnboardingComponent } from "@tvg/sh-lib-promos-onboarding/hooks/usePromosOnboardingComponent";
import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import tvgConf from "@tvg/conf";
import getInternalComponent from "./utils/getInternalComponent";
import { InternalComponentNames, Props } from "./types";

const PromosOnboarding = ({
  placeToRender = StoryblokPlaceName.UNKNOWN,
  isInternalComponent = false,
  componentName = InternalComponentNames.UNKNOWN,
  isDarkMode = false,
  device,
  isShown = true,
  isHomePageSection = false,
  previewContentId = "",
  customMessage = ""
}: Partial<Props>) => {
  const storyblokPromo = useSelector((store: Store) =>
    placeToRender ? getStoryblokPromoByPlace(store, placeToRender) : null
  );
  const promoObject = useSelector((store: Store) => getStoryblokPromos(store));
  const promoCurrentStep = useSelector((store: Store) =>
    getStoryblokCurrentStep(store)
  );
  const promosOnboardingToggle = useSelector(getPromosOnboardingToggle);
  const promoOnboardingComponent = !isInternalComponent
    ? usePromosOnboardingComponent(
        storyblokPromo,
        promoObject,
        promoCurrentStep,
        customMessage
      )
    : null;

  if (!promosOnboardingToggle || !isShown) {
    return null;
  }

  return isInternalComponent
    ? getInternalComponent(componentName, device || tvgConf().device, {
        isDarkMode,
        isHomePageSection,
        previewContentId
      })
    : promoOnboardingComponent;
};

export default PromosOnboarding;
