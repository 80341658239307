import styled, { css } from "styled-components/native";
import { Text } from "react-native";
import { space, compose } from "../../theming/styledSystem";
import { MtpProps } from "./types";

const styledComponentUtils = compose(space);

const baseTextStyle = css<Pick<MtpProps, "isCollapsed">>`
  font-size: ${({ theme, isCollapsed }) =>
    theme.fontSizes[isCollapsed ? "s" : "2xl"]};
  line-height: ${({ theme, isCollapsed }) =>
    theme.lineHeights[isCollapsed ? "l" : "2xl"]};
`;

export const RaceOff = styled(Text)<
  Pick<MtpProps, "isCollapsed" | "hasFullText">
>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colorTokens.lhnHeader.content.positive};
  font-weight: ${({ theme, isCollapsed, hasFullText }) =>
    theme.fontWeights[isCollapsed && !hasFullText ? "normal" : "bold"]};
  ${baseTextStyle};
  ${styledComponentUtils};
`;

export const Resulted = styled(Text)<
  Pick<MtpProps, "isCollapsed" | "hasFullText">
>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colorTokens.lhnHeader.content.brandSecondary};
  font-weight: ${({ theme, isCollapsed, hasFullText }) =>
    theme.fontWeights[isCollapsed && !hasFullText ? "normal" : "bold"]};
  ${baseTextStyle};
  ${styledComponentUtils};
`;

export const RaceTime = styled(Text)<
  Pick<MtpProps, "isCollapsed" | "hasFullText">
>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colorTokens.lhnHeader.content.brandSecondary};
  ${({ theme, isCollapsed, hasFullText }) => {
    if (isCollapsed) {
      return css`
        ${!hasFullText &&
        css`
          width: min-content;
          text-align: center;
        `};
        font-size: ${theme.fontSizes.s};
      `;
    }

    return css`
      font-size: ${theme.fontSizes["2xl"]};
    `;
  }}
  font-weight: ${({ theme, hasFullText }) =>
    hasFullText ? theme.fontWeights.bold : theme.fontWeights.normal};
  line-height: ${({ theme, isCollapsed }) =>
    theme.lineHeights[isCollapsed ? "s" : "2xl"]};
  ${styledComponentUtils};
`;

export const MinutesText = styled(Text)<Pick<MtpProps, "mtp" | "isCollapsed">>`
  display: flex;
  align-items: center;
  color: ${({ theme, mtp }) =>
    mtp > 5
      ? theme.colorTokens.lhnHeader.content.brandSecondary
      : theme.colorTokens.lhnHeader.content.negative};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  ${baseTextStyle};
  ${styledComponentUtils};
  font-size: ${({ theme }) => theme.fontSizes["4xl"]};
`;

export const TextDescription = styled(Text)<Pick<MtpProps, "mtp">>`
  display: block;
  color: ${({ theme, mtp }) =>
    mtp > 5
      ? theme.colorTokens.lhnHeader.content.brandSecondary
      : theme.colorTokens.lhnHeader.content.negative};
  font-family: ${({ theme }) => theme.fonts.condensedRegular};
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 125%;
  width: 65px;
`;
