import styled, { css } from "styled-components";

export default styled.div`
  overflow: hidden;
  ${({ isDesktop }) =>
    isDesktop
      ? css`
          ${({ contextSA }) =>
            contextSA === "topraces"
              ? css`
                  margin: 12px 12px 0 12px;
                `
              : css`
                  margin: 0 12px;
                `};
        `
      : css`
          width: 100%;
        `};
`;
