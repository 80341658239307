import React from "react";
import { IconPathProps } from "../../types";

export const ArrowRight = ({ lineColor, strokeWidth }: IconPathProps) => (
  <path
    d="M2.25 12H20.9177M13.5 4.5L21 12L13.5 19.5"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
  />
);
