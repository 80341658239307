// @flow
import React from "react";
import { get } from "lodash";
import { getPawsContent } from "@tvg/sh-lib-paws/utils/content";
import { shallowEqual, useSelector } from "react-redux";
import type { UnaryFn } from "@tvg/types/Functional";
import { Container, Wrapper, Button } from "./styled-components";

type Props = {
  className?: string,
  onChangeValue: UnaryFn<number, void>,
  disabled?: boolean
};

const selectContentFromPaws = (state) => {
  const content = getPawsContent(state);
  const moneyPills = get(content, "moneyPills", ["50", "100", "200", "300"]);
  return {
    moneyPills
  };
};

const MoneyPills = ({ className, onChangeValue, disabled = false }: Props) => {
  const { moneyPills } = useSelector(selectContentFromPaws, shallowEqual);

  return (
    <Container className={className} data-qa-label="money-pills">
      <Wrapper disabled={disabled}>
        {moneyPills.map((moneyPill, index) => {
          return (
            <Button
              disabled={disabled}
              key={`money-pill-${index.toString()}`}
              onClick={() => onChangeValue(moneyPill)}
              data-qa-label={`money-pill-button-${index.toString()}`}
            >
              &#36;{moneyPill}
            </Button>
          );
        })}
      </Wrapper>
    </Container>
  );
};

export default MoneyPills;
