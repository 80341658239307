import { useEffect, useState, useRef, Dispatch, SetStateAction } from "react";
import { Animated, Platform } from "react-native";
import { easeBackOut, easeBackInOut } from "d3-ease";
import { ModalBaseProps, ModalCloseType } from "../types";

interface UseModalAnimationsProps
  extends Pick<
    ModalBaseProps,
    "onBack" | "onAfterClose" | "onClose" | "onOverlayClick" | "type"
  > {
  isModalOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  closeType: ModalCloseType;
  setCloseType: Dispatch<SetStateAction<ModalCloseType>>;
}

const useNativeDriver = Platform.OS !== "web";

export const useModalAnimations = ({
  isModalOpen,
  setModalIsOpen,
  onClose,
  closeType,
  setCloseType,
  onBack,
  onOverlayClick,
  type,
  onAfterClose
}: UseModalAnimationsProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const overlayOpacityAnimation = useRef(new Animated.Value(0)).current;
  const contentOpacityAnimation = useRef(new Animated.Value(0)).current;
  const lightboxContentAnimation = useRef(new Animated.Value(0.5)).current;
  const lightboxScaleAnimation = useRef(new Animated.Value(0.5)).current;
  const flexibleContentAnimation = useRef(new Animated.Value(0)).current;
  const fullscreenContentAnimation = useRef(new Animated.Value(0)).current;
  const fullWidthPageContentAnimation = useRef(new Animated.Value(0)).current;
  const animationController = useRef<Animated.CompositeAnimation | null>(null);

  const animationCallback = () => {
    setIsAnimating(false);
    animationController.current = null;
    if (isClosing) {
      setModalIsOpen(false);
      setIsClosing(false);

      if (typeof onAfterClose === "function") {
        onAfterClose(closeType);
      }

      switch (closeType) {
        case "onBack":
          if (typeof onBack === "function") {
            onBack();
          }
          break;
        case "onClose":
          if (typeof onClose === "function") {
            onClose();
          }
          break;
        case "onOverlayClick":
          if (typeof onOverlayClick === "function") {
            onOverlayClick();
          }
          break;
        default:
      }
      setCloseType("none");
    }
  };

  const getModalAnimation = (
    contentSequence: Array<Animated.CompositeAnimation> = []
  ): Animated.CompositeAnimation => {
    setIsAnimating(true);
    animationController.current = Animated.sequence(
      isClosing
        ? [
            ...contentSequence,
            Animated.timing(overlayOpacityAnimation, {
              toValue: isClosing ? 0 : 0.6,
              duration: 300,
              useNativeDriver
            })
          ]
        : [
            Animated.timing(overlayOpacityAnimation, {
              toValue: isClosing ? 0 : 0.6,
              duration: 300,
              useNativeDriver
            }),
            ...contentSequence
          ]
    );
    animationController.current.start(animationCallback);

    return animationController.current;
  };

  const getContentOpacityAnimation = ({
    duration,
    delay = 0
  }: {
    duration: number;
    delay?: number;
  }) =>
    Animated.timing(contentOpacityAnimation, {
      toValue: isClosing ? 0 : 1,
      duration,
      delay,
      useNativeDriver
    });

  // handle open and close with animations
  useEffect(() => {
    if (animationController.current) {
      animationController.current.stop();
    }
    if ((!isClosing && isModalOpen) || isClosing) {
      switch (type) {
        case "lightbox": {
          getModalAnimation([
            Animated.parallel([
              getContentOpacityAnimation({ duration: isClosing ? 200 : 300 }),
              Animated.timing(lightboxContentAnimation, {
                toValue: isClosing ? 0.5 : 1,
                duration: 300,
                delay: isClosing ? 100 : 0,
                useNativeDriver,
                easing: easeBackOut?.overshoot(0.8)
              }),
              Animated.timing(lightboxScaleAnimation, {
                toValue: isClosing ? 0 : 1,
                duration: 300,
                useNativeDriver,
                easing: easeBackInOut
              })
            ])
          ]);
          break;
        }
        case "fullscreen": {
          getModalAnimation([
            Animated.parallel(
              [
                getContentOpacityAnimation({
                  duration: 250,
                  delay: isClosing ? 0 : 150
                }),
                Animated.timing(fullscreenContentAnimation, {
                  toValue: isClosing ? 0 : 1,
                  duration: isClosing ? 500 : 400,
                  useNativeDriver,
                  easing: easeBackInOut?.overshoot(0.8)
                })
              ],
              { stopTogether: false }
            )
          ]);
          break;
        }
        case "modal-custom-height": {
          getModalAnimation([
            Animated.parallel(
              [
                Animated.timing(fullscreenContentAnimation, {
                  toValue: isClosing ? 0 : 1,
                  duration: isClosing ? 500 : 400,
                  useNativeDriver,
                  easing: easeBackInOut?.overshoot(0.8)
                })
              ],
              { stopTogether: false }
            )
          ]);
          break;
        }
        case "flexible": {
          getModalAnimation([
            Animated.parallel([
              getContentOpacityAnimation({ duration: 300 }),
              Animated.timing(flexibleContentAnimation, {
                toValue: isClosing ? 0 : 1,
                duration: 300,
                useNativeDriver,
                easing: easeBackInOut
              })
            ])
          ]);
          break;
        }
        case "full-width-page": {
          getModalAnimation([
            Animated.parallel(
              [
                getContentOpacityAnimation({
                  duration: 250,
                  delay: isClosing ? 0 : 150
                }),
                Animated.timing(fullWidthPageContentAnimation, {
                  toValue: isClosing ? 0 : 1,
                  duration: isClosing ? 500 : 400,
                  useNativeDriver,
                  easing: easeBackInOut?.overshoot(0.8)
                })
              ],
              { stopTogether: false }
            )
          ]);
          break;
        }
        default:
      }
    }
  }, [isClosing, isModalOpen]);

  return {
    isAnimating,
    isClosing,
    setIsClosing,
    overlayOpacityAnimation,
    contentOpacityAnimation,
    lightboxContentAnimation,
    lightboxScaleAnimation,
    flexibleContentAnimation,
    fullscreenContentAnimation,
    fullWidthPageContentAnimation
  };
};
