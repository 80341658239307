import { Radii } from "../../types";

export const breakpoints: Array<string> = [];

export const radii: Radii = {
  xs: "2px",
  s: "4px",
  m: "10px",
  l: "12px",
  xl: "24px",
  "2xl": "48px"
};

export * from "./iconSizes";
export * from "./space";
export * from "./fonts";
export * from "./buttons";
export * from "./elevations";
export * from "./tvgColorPalette";
export * from "./fanduelColorPalette";
