import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontMedium } from "@tvg/atomic-ui/_static/Typography";

export const Sign = styled.span<{
  isLoading: boolean;
  showAmountWarning: boolean;
}>`
  font-family: ${fontMedium};
  font-size: 24px;
  line-height: 28px;
  color: ${({ isLoading }) =>
    isLoading ? buildColor("grey", "900") : buildColor("blue_accent", "600")};
  ${({ showAmountWarning }) =>
    showAmountWarning &&
    css`
      color: ${buildColor("orange", "700")};
    `}
`;

const span = css<{
  isEmpty: boolean;
  isLoading: boolean;
  showAmountWarning: boolean;
}>`
  font-family: ${fontMedium};
  font-size: 52px;
  line-height: 61px;
  color: ${({ isEmpty, isLoading }) =>
    isLoading
      ? buildColor("grey", "900")
      : buildColor("blue_accent", isEmpty ? "300" : "600")};
  ${({ showAmountWarning }) =>
    showAmountWarning &&
    css`
      color: ${buildColor("orange", "700")};
    `}

  &::placeholder {
    color: ${({ isEmpty, isLoading }) =>
      isLoading
        ? buildColor("grey", "900")
        : buildColor("blue_accent", isEmpty ? "300" : "600")};
    opacity: 1;
  }
`;

export const Value = styled.span<{
  isEmpty: boolean;
  isLoading: boolean;
  showAmountWarning: boolean;
}>`
  ${span}
`;

export const HiddenSpan = styled.span`
  position: absolute;
  opacity: 0;
  z-index: -100;
  white-space: pre;
  font-family: ${fontMedium};
  font-size: 52px;
`;

export const Input = styled.input<{
  isEmpty: boolean;
  isLoading: boolean;
  showAmountWarning: boolean;
  inputWidth: number;
}>`
  width: ${({ inputWidth }) => `${inputWidth}px`};
  margin: 0 8px;
  outline: none;
  text-align: center;
  &:focus::placeholder {
    color: transparent;
  }

  ${span}
`;

export const Container = styled.div<{
  hasFocus: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${Value}, ${Input} {
    ${({ hasFocus }) =>
      !hasFocus &&
      css`
        color: ${buildColor("black", "100")};
      `}
  }

  ${Sign} {
    ${({ hasFocus }) =>
      !hasFocus &&
      css`
        color: ${buildColor("black", "100")};
      `}
  }
`;

export const DecimalsMask = styled.div<{ smallMask: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: ${({ smallMask }) => (smallMask ? "30px" : "60px")};
  z-index: 1;
  background-color: ${buildColor("white", "50")};
`;
